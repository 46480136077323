import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import IconButton from '@material-ui/core/IconButton';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import grey from '@material-ui/core/colors/grey';
import Divider from '@material-ui/core/Divider';
import clsx from 'clsx';
import { useHistory } from 'react-router';
import Skeleton from '@material-ui/lab/Skeleton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';

import { Post, User } from '../../core/graphql/types';
import urlHelpers from '../../core/helpers/url';
import userHelpers from '../../core/helpers/user';
import { formatDateTime } from '../../core/helpers/misc';
import { authStore } from '../../core/stores/auth';
import { appStore } from '../../core/stores/app';
import AthleteAvatar from './AthleteAvatar';
import ShareDialog from '../dialogs/ShareDialog';
import SocialButtons from './SocialButtons';
import globalStyles from '../../theme/globalStyles';
import { getAthleteProfileRoute, getPostRoute } from '../../core/helpers/route';
import { reportPost } from '../../core/services/flashRequestSvc';
import ReportPostDialog from '../dialogs/ReportPostDialog';
import AlphaText from '../ui/AlphaText';
import { fetchLikesByTarget, likePost } from '../../core/services/likeSvc';
import UserListDialog from '../dialogs/UserListDialog';
import { Link } from 'react-router-dom';
import { capitalize } from '../../core/helpers/misc';
import theme from '../../theme/theme';
import { PostType } from '../../API';
import colors from '../../core/helpers/colors';
import { sendGaEvent } from '../../core/services/gaSvc';
import VideoView from './postType/VideoView';
import PodcastView from './postType/PodcastView';
import BcastView from './postType/BcastView';
import { subscribeToUpdatePost } from '../../core/services/postsSvc';
import { updateRecord } from '../../graphql/utils';

const postNormalHeight = 280;
const postSmallHeight = 200;
const useGlobalStyles = makeStyles(globalStyles as any);
const useStyles = makeStyles((theme) => ({
  postElemRoot: {
    width: '100%',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  postElemRootPageHeader: {
    [theme.breakpoints.down('sm')]: {
      border: '0',
    },
  },
  headerWrapper: {
    position: 'relative',
    width: '100%',
    height: theme.typography.pxToRem(postNormalHeight),
  },
  headerWrapperSmall: {
    height: theme.typography.pxToRem(postSmallHeight),
  },
  headerWrapperPageHeader: {
    height: '65vh !important',
    maxHeight: '100%',
    [theme.breakpoints.down('md')]: {
      height: '66vh !important',
    },
    [theme.breakpoints.down('sm')]: {
      height: '33vh !important',
    },
  },
  postThumbWrapper: {
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    backgroundColor: '#E5E5E5',
    cursor: 'pointer',
  },
  postThumb: {
    display: 'block',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    filter: 'blur(6px)',
    '-webkit-filter': 'blur(6px)',
  },
  postVodHeadWrapper: {
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    backgroundColor: '#E5E5E5',
  },
  postPodcastHeadWrapper: {
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    backgroundColor: '#E5E5E5',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  postThumbVodWrapper: {
    height: '100%',
  },
  postVodThumb: {
    display: 'block',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  postVodThumbBlur: {
    filter: 'blur(12px)',
    '-webkit-filter': 'blur(12px)',
  },
  postVodPlayIcon: {
    color: '#fff',
    fontSize: theme.typography.pxToRem(56),
    cursor: 'pointer',
  },
  postVodProgressIcon: {
    color: '#fff',
    fontSize: theme.typography.pxToRem(56),
    cursor: 'pointer',
  },
  videoPlayer: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  lockIcon: {
    fontSize: theme.typography.pxToRem(16),
    marginTop: theme.typography.pxToRem(4),
    marginRight: theme.typography.pxToRem(2),
  },
  postLockOverlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '100%',
    height: '100%',
    color: '#ffffff',
    margin: 0,
  },
  postLockOverlayTitle: {
    textShadow: '#111111 0px 0px 6px',
    width: '70%',
    fontWeight: 700,
    '-webkit-font-smoothing': 'antialiased',
  },
  postLockOverlayButton: {
    padding: theme.spacing(1),
    fontSize: '14px',
    cursor: 'pointer',
    backgroundColor: '#ffffff',
    '&:hover': {
      backgroundColor: grey[200],
    },
  },
  previewButtonWrapper: {
    position: 'absolute',
    right: theme.spacing(2),
    bottom: theme.spacing(2),
  },
  previewButton: {
    padding: theme.spacing(0, 1, 0),
    cursor: 'pointer',
    textShadow: '#444444 0px 0px 6px',
    color: '#ffffff',
    borderColor: '#ffffff',
  },
  discountLabelWrapper: {
    position: 'absolute',
    left: 0,
    top: theme.spacing(1),
  },
  discountLabel: {
    color: '#ffffff',
    backgroundColor: colors.discount,
    padding: theme.spacing(0, 1, 0),
    fontSize: '18px',
  },
  linkTxt: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  shareIcon: {
    color: theme.palette.text.secondary,
  },
  shareButtons: {
    [theme.breakpoints.down('sm')]: {
      width: theme.typography.pxToRem(150),
    },
  },
  incompleteBg: {
    position: 'absolute',
    top: 0,
    lef: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#000000',
  },
  progressingIcon: {
    fontSize: theme.typography.pxToRem(56),
    color: theme.palette.primary.main,
  },
  videoDuration: {
    position: 'absolute',
    right: theme.spacing(2),
    bottom: theme.spacing(2),
    color: '#ffffff',
    backgroundColor: '#000000',
    borderRadius: theme.spacing(0.5),
    height: theme.spacing(3),
    fontSize: theme.typography.pxToRem(14),
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      '& > *': {
        lineHeight: 'auto',
      },
    },
    '& > *': {
      lineHeight: theme.typography.pxToRem(32),
    },
  },
  authorBox: {
    '& .MuiBox-root': {
      padding: theme.spacing(0.3),
    },
    '& .MuiBox-root:nth-child(1)': {
      border: '1px solid black',
      borderTopLeftRadius: '10px',
      borderBottomLeftRadius: '10px',
      borderColor: theme.palette.primary.main,
      '& a': {
        color: 'white',
        textDecoration: 'none',
      },
    },
    '& .MuiBox-root:nth-child(2)': {
      borderLeft: 'none',
      border: '1px solid black',
      borderTopRightRadius: '10px',
      borderBottomRightRadius: '10px',
      borderColor: theme.palette.primary.main,
    },
  },
}));

interface Props {
  post?: Post;
  author?: User;
  editPermission: boolean;
  viewPermission: boolean;
  isPageHeader?: boolean;
  showAuthorAvatar?: boolean;
  showPreviewDownloaderButton: boolean;
  onDeleteClick?: (post: Post) => void;
}

const PostElement: React.FC<Props> = ({
  post: postProp,
  author,
  editPermission,
  viewPermission,
  isPageHeader,
  showAuthorAvatar,
  showPreviewDownloaderButton,
  onDeleteClick,
}: Props) => {
  const globalClasses = useGlobalStyles();
  const classes = useStyles();
  const history = useHistory();
  const [authState] = useContext(authStore);
  const [, appDispatch] = useContext(appStore);
  const [post, setPost] = useState<Post | undefined>();
  const [likesAuthors, setLikesAuthors] = useState<User[] | undefined>();
  const [postLikesModalOpen, setpostLikesModalOpen] = useState(false);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [reportPostDialogOpen, setReportPostDialogOpen] = useState(false);
  const [moreMenuAnchorEl, setMoreMenuAnchorEl] = React.useState<null | HTMLElement>(null);

  useEffect(() => {
    let updatePostSubscrition: any;
    if (postProp) {
      setPost(postProp);

      updatePostSubscrition = subscribeToUpdatePost(postProp.id, (evt) => {
        const postUpdate = evt.value.data.onUpdatePostById;
        setPost(updateRecord(postProp, postUpdate));
      });
    }

    return () => {
      if (updatePostSubscrition) {
        updatePostSubscrition.unsubscribe();
      }
    };
  }, [postProp]);

  const shareTitle = author
    ? isPageHeader
      ? `Share ${author.givenName}'s post`
      : `Share ${author.givenName}'s posts`
    : '';

  const shareUrl =
    post && author
      ? isPageHeader
        ? urlHelpers.getPostShareUrl(post.id)
        : `${urlHelpers.getUserShareUrl(author)}?v=p`
      : '';

  const handlePreviewDownload = () => {
    if (post && post.vodId) {
      fetch(urlHelpers.getPostVodPreviewUrl(post.vodId))
        .then((response) => response.blob())
        .then((blob) => {
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = `${post?.name}_preview`;
          link.click();
        });
    }
  };
  const getAuthorProfileLink = (post: Post) => {
    if (!post.author?.nickname) {
      return `a/${post.author?.id}`;
    }
    return post.author?.nickname;
  };
  const handleSubscribeClick = async () => {
    if (author) {
      sendGaEvent(
        'user trying to subscribe',
        `athlete_post: ${post?.name}`,
        `an user clicked subscribe to athlete ${
          author?.givenName + ' | ' + author?.familyName + ' | ' + author?.email
        }`,
      );
      await userHelpers.goToAthleteCheckout(author, authState, history, post?.tierId);
    }
  };
  const handleTitleClick = () => {
    if (post) {
      if (!viewPermission) {
        handleSubscribeClick();
      } else {
        history.push(getPostRoute(post.id));
      }
    }
  };
  const handleAuthorClick = () => {
    if (author) {
      history.push(`${getAthleteProfileRoute(author)}?v=p`);
    }
  };
  const handleDeleteClick = () => {
    if (onDeleteClick && post) {
      onDeleteClick(post);
    }
  };
  const handleShareDialogClose = () => {
    setShareDialogOpen(false);
  };
  const handleMoreClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMoreMenuAnchorEl(event.currentTarget);
  };
  const handleMoreClose = () => {
    setMoreMenuAnchorEl(null);
  };
  const handleReportClick = () => {
    handleMoreClose();
    setReportPostDialogOpen(true);
  };
  const handleReportPost = async (reason: string) => {
    setReportPostDialogOpen(false);
    if (authState.user && post) {
      try {
        await reportPost(authState.user.userId, post.id, reason);
        appDispatch({
          type: 'showToast',
          toastTxt: 'Post reported. Our team will review your request as soon as possible.',
          toastSeverity: 'success',
        });
      } catch (e) {
        appDispatch({
          type: 'showToast',
          toastTxt: 'There`s been an error reporting the post',
          toastSeverity: 'error',
        });
      }
    }
  };
  const hanldeReportPostDialogClose = () => {
    setReportPostDialogOpen(false);
  };
  const handleLikeClick = async () => {
    if (authState.user && post) {
      likePost(authState.user.userId, post.id);
      // Update the post data assuming the request succeeded
      setPost((p) => {
        if (p) {
          return { ...p, liked: true, likes: (p.likes || 0) + 1 };
        }

        return p;
      });
    }
    1;
  };

  const handleLikesClick = async (postId: string) => {
    const likes: any[] = await fetchLikesByTarget(postId);
    setLikesAuthors(likes.map(({ user }) => user));
    setpostLikesModalOpen(!postLikesModalOpen);
  };

  const handleCommentsClick = (postId: string) => {
    if (post) {
      if (!viewPermission) {
        handleSubscribeClick();
      } else {
        history.push(getPostRoute(postId));
      }
    }
  };

  return (
    <Card
      className={clsx({
        [classes.postElemRoot]: true,
        [classes.postElemRootPageHeader]: isPageHeader,
      })}
      variant="outlined"
    >
      <UserListDialog
        users={likesAuthors}
        open={postLikesModalOpen}
        onCloseClick={() => {
          setpostLikesModalOpen(false);
        }}
      />
      {/* Post Header */}
      <div
        className={clsx({
          [classes.headerWrapper]: true,
          [classes.headerWrapperPageHeader]: isPageHeader,
        })}
      >
        {/* ---------------------- Post Content/Head -------------------------- */}
        {post && author && /*todo:remove:*/ post.vodId && (
          <>
            {post.type === PostType.video && (
              <VideoView
                post={post}
                authState={authState}
                globalClasses={globalClasses}
                classes={classes}
                viewPermission={viewPermission}
                handleSubscribeClick={handleSubscribeClick}
                setPost={setPost}
              />
            )}
            {post.type === PostType.podcast && (
              <PodcastView
                post={post}
                authState={authState}
                globalClasses={globalClasses}
                classes={classes}
                viewPermission={viewPermission}
                handleSubscribeClick={handleSubscribeClick}
                setPost={setPost}
              />
            )}
            {post.type === PostType.bcast && (
              <BcastView
                post={post}
                isPreview={false}
                viewPermission={viewPermission}
                authState={authState}
                globalClasses={globalClasses}
                classes={classes}
                handleSubscribeClick={handleSubscribeClick}
                setPost={setPost}
              />
            )}
          </>
        )}

        {/* Header skeleton */}
        {!post && <Skeleton variant="rect" width="100%" height="100%"></Skeleton>}
      </div>

      {/* ---------------------- Post info -------------------------- */}
      <Box p={2}>
        <Grid container alignItems="flex-start">
          {/* Athlete avatar */}
          {(isPageHeader || showAuthorAvatar) && (
            <Grid item>
              <Box mr={2}>
                <AthleteAvatar user={author} onClick={handleAuthorClick}></AthleteAvatar>
              </Box>
            </Grid>
          )}
          <Grid item xs>
            <Grid container justifyContent="space-between">
              {/* Timestamp */}
              <Grid item>
                {post && (
                  <Typography
                    className={isPageHeader ? '' : classes.linkTxt}
                    variant="caption"
                    gutterBottom
                    onClick={handleTitleClick}
                  >
                    {formatDateTime(new Date(post.createdAt))}
                  </Typography>
                )}
                {!post && <Skeleton width={50} height={20}></Skeleton>}
              </Grid>
              {/* Locked icon */}
              {!viewPermission && (
                <Grid item>
                  <Grid container alignItems="center">
                    <Grid item>
                      <LockOutlinedIcon className={classes.lockIcon} />
                    </Grid>
                    <Grid item>
                      <Typography variant="body2">Locked</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {/* More menu */}
              {viewPermission && (
                <Grid item>
                  <IconButton
                    aria-controls="post-more-menu"
                    aria-haspopup="true"
                    onClick={handleMoreClick}
                    size="small"
                    edge="end"
                  >
                    <MoreHorizIcon fontSize="small" />
                  </IconButton>
                  <Menu
                    id="post-more-menu"
                    anchorEl={moreMenuAnchorEl}
                    open={Boolean(moreMenuAnchorEl)}
                    keepMounted
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    onClose={handleMoreClose}
                  >
                    <MenuItem onClick={handleReportClick}>Report content</MenuItem>
                    {onDeleteClick && (
                      <span>
                        {post && editPermission && <MenuItem onClick={handleDeleteClick}>Delete post</MenuItem>}
                      </span>
                    )}
                  </Menu>
                </Grid>
              )}
            </Grid>

            {/* Author data */}
            <Grid container justifyContent="space-between">
              {/* Post Title/Name */}
              <Grid item>
                <Grid container alignItems="center">
                  <Grid item>
                    {post && (
                      <>
                        {post?.authorId !== authState.user?.userId && (
                          <Box className={classes.authorBox} alignItems="center" mb={2} mt={2} display="flex">
                            <Box style={{ backgroundColor: theme.palette.primary.main }} display="flex">
                              <Link to={`/${getAuthorProfileLink(post)}`}>
                                <Typography variant="caption">
                                  <b>
                                    @{post.author?.nickname || post.author?.name || post.author?.email?.split('@')[0]}
                                  </b>
                                </Typography>
                              </Link>
                            </Box>
                            <Box>
                              <Typography noWrap style={{ textOverflow: 'ellipsis' }} variant="caption">
                                {capitalize(post.author?.sport || '')}
                              </Typography>
                            </Box>
                          </Box>
                        )}
                        <>
                          <Typography
                            className={clsx({
                              [classes.linkTxt]: !isPageHeader,
                            })}
                            variant="h5"
                            onClick={handleTitleClick}
                          >
                            {post.name.toUpperCase()}
                          </Typography>
                          <AlphaText
                            text={post.description ? post.description : ''}
                            textLimit={200}
                            alphaHeight="20px"
                            alphaBottomSeparation="35px"
                          />
                        </>
                        {/* Reaction info (likes/comments) */}
                        <Grid container spacing={1} alignItems="center">
                          {/* Show like button only if logged in s*/}
                          {authState.user && viewPermission && (
                            <Grid item>
                              {post.liked && <FavoriteIcon />}
                              {!post.liked && (
                                <FavoriteBorderIcon style={{ cursor: 'pointer' }} onClick={handleLikeClick} />
                              )}
                            </Grid>
                          )}

                          {/* Number of likes */}
                          {post.likes && (
                            <Grid
                              onClick={() => {
                                handleLikesClick(post.id);
                              }}
                              style={{ cursor: 'pointer' }}
                              item
                            >
                              <Typography className={classes.linkTxt} variant="caption">
                                {`${post.likes} likes`}
                              </Typography>
                            </Grid>
                          )}
                          {post.comments && (
                            <Grid
                              onClick={() => {
                                handleCommentsClick(post.id);
                              }}
                              item
                            >
                              <Typography
                                className={classes.linkTxt}
                                variant="caption"
                              >{`${post.comments} comments`}</Typography>
                            </Grid>
                          )}
                        </Grid>
                      </>
                    )}
                    {!post && <Skeleton width={200} height={30}></Skeleton>}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* Share */}
            {viewPermission && (
              <>
                <Grid item>
                  <Box mt={1} mb={1}>
                    <Divider></Divider>
                  </Box>
                </Grid>
                <Grid item>
                  <Box mt={2}>
                    <Grid container alignItems="center">
                      <Grid item>
                        <Typography variant="body1">Share</Typography>
                      </Grid>
                      <Grid item md={10} sm={10} xs={8}>
                        <Box className={classes.shareButtons} ml={2}>
                          <SocialButtons
                            title={shareTitle}
                            justify="flex-start"
                            shareUrl={shareUrl}
                            spacing={0}
                            size={18}
                          ></SocialButtons>
                        </Box>
                      </Grid>
                      {authState.user?.userId === post?.authorId &&
                        showPreviewDownloaderButton &&
                        post?.type === PostType.video && (
                          <Grid item>
                            <IconButton onClick={handlePreviewDownload} color="primary">
                              <i style={{ fontSize: '20px' }} className="fas fa-download"></i>
                            </IconButton>
                          </Grid>
                        )}
                    </Grid>
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Box>

      {/* Share Dialog */}
      {shareDialogOpen && (
        <ShareDialog open={shareDialogOpen} title={shareTitle} shareUrl={shareUrl} onClose={handleShareDialogClose} />
      )}

      {/* Report Post */}
      {reportPostDialogOpen && (
        <ReportPostDialog
          open={reportPostDialogOpen}
          onReport={handleReportPost}
          onClose={hanldeReportPostDialogClose}
        />
      )}
    </Card>
  );
};

export default PostElement;
