import React from 'react';
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import Dialog from '@material-ui/core/Dialog/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { TransitionProps } from '@material-ui/core/transitions';
import Slide from '@material-ui/core/Slide';
import SubmitButton from '../ui/SubmitButton';

const useStyles = makeStyles(() =>
  createStyles({
    contentWrapper: {
      height: '100%',
    },
    content: {
      height: '100%',
    },
  }),
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

interface Props {
  open: boolean;
  onConfigurePaymentsClick: () => void;
}

const CreatePostBlockerDialog: React.FC<Props> = ({ open, onConfigurePaymentsClick }: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const handleConfigurePaymentsClick = () => {
    onConfigurePaymentsClick();
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      fullScreen={!isBigScreen}
      maxWidth="xs"
      fullWidth={true}
      aria-labelledby="form-dialog-title"
    >
      <DialogContent className={classes.contentWrapper}>
        <Box mt={6} mb={6}>
          <Grid className={classes.content} container direction="column" alignItems="center">
            <Grid item>
              <Typography variant="body1" align="center">
                You have to enable payments to create posts.
              </Typography>
            </Grid>
            <Grid item>
              <Box mt={4}>
                <SubmitButton onClick={handleConfigurePaymentsClick}>Configure payments</SubmitButton>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CreatePostBlockerDialog;
