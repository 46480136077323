import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import EditIcon from '@material-ui/icons/Edit';

import { AthleteFundingGoal, User } from '../../core/graphql/types';
import AthleteFundingGoals from '../../components/elements/AthleteFundingGoals';

const useStyles = makeStyles((theme) => ({
  fundingGoalsBlock: {
    width: '100%', // material-ui: +16px
    margin: 0, // materiail-ui: -8px
    height: 'fit-content',
  },
  curGoalAmount: {
    color: theme.palette.primary.main,
  },
}));

interface Props {
  athlete?: User;
  editable: boolean;
  isUserSubscribed?: boolean;
  onUserUpdated: (update: { fundingGoals: AthleteFundingGoal[] }) => void;
}

const FundingGoalsBlock: React.FC<Props> = ({ athlete, editable, onUserUpdated }: Props) => {
  const classes = useStyles();
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const handleEditClick = () => {
    setEditDialogOpen(true);
  };
  const handleFundingGoalsEditCancel = () => {
    setEditDialogOpen(false);
  };
  const handleFundingGoalsUpdated = (update: { fundingGoals: AthleteFundingGoal[] }) => {
    setEditDialogOpen(false);
    onUserUpdated(update);
  };

  return (
    <div className={classes.fundingGoalsBlock}>
      {/* Title */}
      <Typography variant="h4" align="left" gutterBottom>
        <b>Funding goals</b>
        {editable && (
          <IconButton color="primary" onClick={handleEditClick}>
            <EditIcon fontSize="small" />
          </IconButton>
        )}
      </Typography>

      {/* Funding goals */}
      {athlete && (
        <AthleteFundingGoals
          user={athlete}
          editDialogOpen={editDialogOpen}
          onUpdated={handleFundingGoalsUpdated}
          onCancel={handleFundingGoalsEditCancel}
        />
      )}
      {/* Divider */}
      <Box mt={3}>
        <Divider />
      </Box>
    </div>
  );
};

export default FundingGoalsBlock;
