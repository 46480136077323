import React, { useState, useEffect, useMemo, MouseEventHandler } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Zoom from '@material-ui/core/Zoom';
import clsx from 'clsx';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import Chip from '@material-ui/core/Chip';
import ErrorIcon from '@material-ui/icons/Error';

import { Post, PostVodStatus } from '../../../core/graphql/types';
import VideoPlayer from '../../ui/VideoPlayer';
import urlHelpers from '../../../core/helpers/url';
import PostLockOverlay from '../PostLockOverlay';
import videoSvc from '../../../core/services/videoSvc';
import { fetchPostVodToken } from '../../../core/services/postsSvc';
import { AuthState } from '../../../core/stores/auth';
import { secondsToStr } from '../../../core/helpers/misc';

interface Props {
  post: Post;
  authState: AuthState;
  globalClasses: any;
  classes: any;
  viewPermission: boolean;
  handleSubscribeClick: MouseEventHandler<HTMLDivElement>;
  setPost: React.Dispatch<React.SetStateAction<Post | undefined>>;
}

const VideoView: React.FC<Props> = (props: Props) => {
  const { post, globalClasses, classes, viewPermission, handleSubscribeClick, setPost, authState } = props;

  const [previewUrl, setPreviewUrl] = useState('');
  const [vodUrl, setVodUrl] = useState('');
  const [loadingVodToken, setLoadingVodToken] = useState(false);
  const [thumbUrl, setThumbUrl] = useState('');

  useEffect(() => {
    if (post) {
      if (!post.vodId && post.thumbS3Key) {
        setThumbUrl(urlHelpers.getMediaUrlFromS3Key(post.thumbS3Key, 'img'));
      } else if (post.vodId) {
        setThumbUrl(urlHelpers.getPostVodThumbUrl(post.vodId));
      }
    }
  }, [post]);

  const renderPreview = useMemo(() => {
    return (
      <Zoom in={Boolean(previewUrl)} timeout={{ enter: 500, exit: 500 }}>
        <div style={{ position: 'absolute', top: 0, width: '100%', height: '100%', background: '#000000' }}>
          <VideoPlayer
            className={classes.videoPlayer}
            videoUrl={previewUrl}
            autoplay={true}
            onVideoEnded={() => {
              setPreviewUrl('');
            }}
          />
        </div>
      </Zoom>
    );
  }, [previewUrl]);

  const handleWatchPreviewClick = () => {
    if (post && post.vodId) {
      setPreviewUrl(urlHelpers.getPostVodPreviewUrl(post.vodId));
    }
  };
  const handlePlayVodClick = async () => {
    if (post && post.vodId && !vodUrl) {
      if (post.vodToken) {
        // Test url
        // setVodUrl(
        //   'https://multiplatform-f.akamaihd.net/i/multi/will/bunny/big_buck_bunny_,640x360_400,640x360_700,640x360_1000,950x540_1500,.f4v.csmil/master.m3u8',
        // );
        setVodUrl(await videoSvc.getPostVodPlaylistManifest(post.vodId, post.vodToken));
      } else if (!loadingVodToken) {
        // If there is no vodToken, fetch it
        setLoadingVodToken(true);
        const { vodToken } = await fetchPostVodToken(post.id, Boolean(authState.user));
        setLoadingVodToken(false);

        setPost((p) => {
          if (p) {
            return { ...p, vodToken };
          }

          return p;
        });
        setVodUrl(await videoSvc.getPostVodPlaylistManifest(post.vodId, vodToken));
      }
    }
  };

  return (
    <div className={classes.postVodHeadWrapper}>
      {/* Processing Status */}
      {post.vodStatus === PostVodStatus.progressing && (
        <>
          <div className={classes.incompleteBg} />
          <div className={globalClasses.centerAbsolute}>
            <Box display="flex" justifyContent="center" width={1}>
              <img src="/static/images/loaders/peakz-logo-loader.gif" alt="loading..." height="50px" />
            </Box>
            <Box mt={1}>
              <Typography variant="body1" color="primary" align="center">
                Optimizing video...
              </Typography>
            </Box>
          </div>
        </>
      )}

      {/* Complete Status */}
      {post.vodStatus === PostVodStatus.complete && (
        <>
          {/* Blocker */}
          {!viewPermission && (
            <div className={classes.postThumbVodWrapper} onClick={handleSubscribeClick}>
              <img
                className={clsx({
                  [classes.postVodThumb]: true,
                  [classes.postVodThumbBlur]: true,
                })}
                src={thumbUrl}
                alt="header-img"
                width="100%"
                height="100%"
              />
              <PostLockOverlay post={post} classes={classes} onWatchPreviewClicked={handleWatchPreviewClick} />
            </div>
          )}

          {/* Preview */}
          {renderPreview}

          {/* Video is visible */}
          {viewPermission && (
            <Box style={{ height: '100%' }}>
              {/* Video Preview/Thumbnail (Before Click) */}
              {!vodUrl && (
                <div className={classes.postThumbVodWrapper}>
                  <img className={classes.postVodThumb} src={thumbUrl} />
                  <div className={globalClasses.centerAbsolute}>
                    <Zoom in={!loadingVodToken} timeout={1000}>
                      <PlayArrowIcon className={classes.postVodPlayIcon} onClick={handlePlayVodClick} />
                    </Zoom>
                  </div>
                  {post.vodMetadata && post.vodMetadata.durationInMs && (
                    <Chip
                      className={classes.videoDuration}
                      label={secondsToStr(post.vodMetadata.durationInMs / 1000)}
                    />
                  )}
                </div>
              )}
            </Box>
          )}
          {vodUrl && post.vodToken && (
            <VideoPlayer
              className={classes.videoPlayer}
              videoUrl={vodUrl}
              isVod={true}
              vodToken={post.vodToken}
              autoplay={Boolean(vodUrl)}
            />
          )}
        </>
      )}

      {/* Other Status */}
      {post.vodStatus !== PostVodStatus.complete && post.vodStatus !== PostVodStatus.progressing && (
        <>
          <div className={classes.incompleteBg} />
          <Chip
            className={globalClasses.centerAbsolute}
            variant="outlined"
            color="primary"
            icon={<ErrorIcon />}
            label={post.vodStatus?.toUpperCase()}
          />
        </>
      )}
    </div>
  );
};

export default VideoView;
