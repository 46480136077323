import React, { useState, useContext } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { v1 as uuidv1 } from 'uuid';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import { AthleteAchievement, AthleteGoal, User } from '../../core/graphql/types';
import GoalAchievementCard from './GoalAchievementCard';
import { updateUser } from '../../core/services/userSvc';
import GoalAchievementDialog, { AchievementGoalDialogData } from '../../containers/dialogs/GoalAchievementDialog';
import ActionConfirmationDialog from '../../components/dialogs/ActionConfirmationDialog';
import FormSubmitButton from '../../components/ui/FormSubmitButton';
import SportsIcon from '../../components/ui/SportsIcon';
import { appStore } from '../../core/stores/app';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      textAlign: 'left',
      border: 'solid 2px',
      boxShadow: 'none',
    },
    contentCell: {
      padding: theme.spacing(2, 0, 0),
      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
    },
    center: {
      textAlign: 'center',
    },
    goalsAchievements: {
      padding: theme.spacing(2, 0, 1),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0, 0, 2),
      },
    },
    goalAchCell: {
      padding: theme.spacing(1, 0, 1),
    },
  }),
);

interface Props {
  athlete?: User;
  editable: boolean;
  onUserUpdated: (update: { achievements?: AthleteAchievement[]; goals?: AthleteGoal[] }) => void;
}

const GoalsAndAchievementsBlock: React.FC<Props> = ({ athlete, editable, onUserUpdated }: Props) => {
  const classes = useStyles();
  const [, appDispatch] = useContext(appStore);
  const [itemDialogOpen, setItemDialogOpen] = useState<boolean>(false);
  const [editingItem, setEditingItem] = useState<AthleteAchievement | AthleteGoal | undefined>();
  const [curItemType, setCurItemType] = useState<'achievement' | 'goal' | undefined>();
  const [deletingItemIndex, setDeletingItemIndex] = useState<number | undefined>();
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState<boolean>(false);
  const [submittingDialog, setSubmittingDialog] = useState<boolean>(false);
  const handleAchievementSubmit = async (itemData: AchievementGoalDialogData) => {
    if (athlete && curItemType) {
      const prop = curItemType === 'achievement' ? 'achievements' : 'goals';
      let items;

      // If it has an ID, we're editing an achievement. Otherwise, we need to append the achievement
      if (itemData.id) {
        items = athlete[prop];
        const itemIndex = items?.findIndex((a: AthleteAchievement) => a.id === itemData.id);

        if (itemIndex != undefined && items && items[itemIndex]) {
          items[itemIndex] = { ...items[itemIndex], ...itemData };
        }
      } else {
        const item = { ...itemData, id: uuidv1() };

        items = (athlete[prop] || []).concat([item]);
      }

      setSubmittingDialog(true);
      const updateObj = { [prop]: items };
      await updateUser(athlete.id, updateObj, appDispatch);
      setSubmittingDialog(false);
      setItemDialogOpen(false);

      onUserUpdated({ [prop]: items });
    }
  };
  const handleItemEditClick = (itemType: 'achievement' | 'goal', index: number) => {
    const prop = itemType === 'achievement' ? 'achievements' : 'goals';
    const itemsArr = athlete && athlete[prop];

    if (itemsArr) {
      const item = itemsArr[index];

      setCurItemType(itemType);
      setEditingItem(item);
      setItemDialogOpen(true);
    }
  };
  const handleItemMoveUpClick = async (itemType: 'achievement' | 'goal', index: number) => {
    const prop = itemType === 'achievement' ? 'achievements' : 'goals';
    const items = athlete && athlete[prop];

    if (athlete && items) {
      const itemToMove = items[index];

      items[index] = items[index - 1];
      items[index - 1] = itemToMove;

      const updateObj = { [prop]: items };
      await updateUser(athlete.id, updateObj, appDispatch);
      onUserUpdated({ [prop]: items });
    }
  };
  const handleItemMoveDownClick = async (itemType: 'achievement' | 'goal', index: number) => {
    const prop = itemType === 'achievement' ? 'achievements' : 'goals';
    const items = athlete && athlete[prop];

    if (athlete && items) {
      const itemToMove = items[index];

      items[index] = items[index + 1];
      items[index + 1] = itemToMove;

      const updateObj = { [prop]: items };
      await updateUser(athlete.id, updateObj, appDispatch);
      onUserUpdated({ [prop]: items });
    }
  };
  const handleItemDeleteClick = (itemType: 'achievement' | 'goal', index: number) => {
    setCurItemType(itemType);
    setDeletingItemIndex(index);
    setConfirmationDialogOpen(true);
  };
  const handleAchievementDeleteConfirmation = async () => {
    const prop = curItemType === 'achievement' ? 'achievements' : 'goals';
    const items = athlete && athlete[prop];

    if (athlete && items && deletingItemIndex != undefined) {
      if (items) {
        items.splice(deletingItemIndex, 1);

        const updateObj = { [prop]: items };
        await updateUser(athlete.id, updateObj, appDispatch);
        onUserUpdated({ [prop]: items });
      }

      setDeletingItemIndex(undefined);
      setConfirmationDialogOpen(false);
    }
  };
  const handleAchievementCancel = () => {
    setItemDialogOpen(false);
  };
  const handleAchievementDeleteCancel = () => {
    setDeletingItemIndex(undefined);
    setConfirmationDialogOpen(false);
  };
  const handleAddItemClick = (itemType: 'achievement' | 'goal') => {
    setCurItemType(itemType);
    setEditingItem(undefined);
    setItemDialogOpen(true);
  };

  return (
    <>
      <Grid container>
        {/* Achievements */}
        <Grid className={`${classes.contentCell} ${classes.center}`} item xs={12}>
          <Box>
            <SportsIcon icon="icon-trophy" size={60} />
            <Typography variant="h4">
              <b>{editable ? 'My achievements' : 'Achievements'}</b>
            </Typography>
          </Box>

          <Grid className={classes.goalsAchievements} container direction="column">
            {athlete &&
              athlete.achievements &&
              athlete.achievements.map((achievement, i) => (
                <Grid className={classes.goalAchCell} key={achievement.id} item xs={12}>
                  <GoalAchievementCard
                    item={achievement}
                    editable={editable}
                    hideArrows={athlete.achievements && athlete.achievements.length === 1}
                    isFirstItem={i === 0}
                    isLastItem={athlete && athlete.achievements ? i === athlete.achievements.length - 1 : false}
                    onEditClick={handleItemEditClick.bind(null, 'achievement', i)}
                    onMoveUpClick={handleItemMoveUpClick.bind(null, 'achievement', i)}
                    onMoveDownClick={handleItemMoveDownClick.bind(null, 'achievement', i)}
                    onDeleteClick={handleItemDeleteClick.bind(null, 'achievement', i)}
                  />
                </Grid>
              ))}
            {athlete && (!athlete.achievements || athlete.achievements.length === 0) && !editable && (
              <Typography variant="body1">I will add my achievements soon</Typography>
            )}
            {editable && (
              <Grid className={classes.goalAchCell} item xs={12}>
                <FormSubmitButton
                  color="primary"
                  endIcon={<AddCircleOutlineIcon fontSize="large" />}
                  onClick={handleAddItemClick.bind(null, 'achievement')}
                >
                  Add your achievement
                </FormSubmitButton>
              </Grid>
            )}
          </Grid>
        </Grid>
        {/* Goals  */}
        <Grid className={`${classes.contentCell} ${classes.center}`} item xs={12}>
          <Box>
            <SportsIcon icon="icon-edit-property" size={60} />
            <Typography variant="h4">
              <b>{editable ? 'My goals' : 'Goals'}</b>
            </Typography>
          </Box>
          <Grid className={classes.goalsAchievements} container direction="column">
            {athlete &&
              athlete.goals &&
              athlete.goals.map((goal, i) => (
                <Grid className={classes.goalAchCell} key={goal.id} item xs={12}>
                  <GoalAchievementCard
                    item={goal}
                    editable={editable}
                    hideArrows={athlete.goals && athlete.goals.length === 1}
                    isFirstItem={i === 0}
                    isLastItem={athlete && athlete.goals ? i === athlete.goals.length - 1 : false}
                    onEditClick={handleItemEditClick.bind(null, 'goal', i)}
                    onMoveUpClick={handleItemMoveUpClick.bind(null, 'goal', i)}
                    onMoveDownClick={handleItemMoveDownClick.bind(null, 'goal', i)}
                    onDeleteClick={handleItemDeleteClick.bind(null, 'goal', i)}
                  />
                </Grid>
              ))}
            {athlete && (!athlete.goals || athlete.goals.length === 0) && !editable && (
              <Typography variant="body1">I will add my goals soon</Typography>
            )}
            {editable && (
              <Grid className={classes.goalAchCell} item xs={12}>
                <FormSubmitButton
                  color="primary"
                  endIcon={<AddCircleOutlineIcon fontSize="large" />}
                  onClick={handleAddItemClick.bind(null, 'goal')}
                >
                  Add your goal
                </FormSubmitButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>

      {/* Achievement/Goal Dialog */}
      {itemDialogOpen && athlete && (
        <GoalAchievementDialog
          item={editingItem}
          itemType={curItemType}
          open={itemDialogOpen}
          submitting={submittingDialog}
          onSubmit={handleAchievementSubmit}
          onCancel={handleAchievementCancel}
        ></GoalAchievementDialog>
      )}

      {/* Delete Achievement or Goal */}
      {confirmationDialogOpen && (
        <ActionConfirmationDialog
          open={confirmationDialogOpen}
          title={''}
          description={'Are you sure you want to delete the entry?'}
          onYesClick={handleAchievementDeleteConfirmation}
          onNoClick={handleAchievementDeleteCancel}
          onClose={handleAchievementDeleteCancel}
        />
      )}
    </>
  );
};

export default GoalsAndAchievementsBlock;
