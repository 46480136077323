import React from 'react';
import { Theme, withStyles } from '@material-ui/core/styles';
import TextAreaAutoSize from '@material-ui/core/TextareaAutosize';

const styles = (theme: Theme) => ({
  root: {
    width: '100%',
    marginTop: '8px',
    marginBottom: '4px',
    borderRadius: '4px',
    borderColor: 'rgba(0, 0, 0, 0.23)',
    padding: '15px',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    '&:hover': {
      borderColor: 'black',
    },
    '&:active,&:focus': {
      outline: 'none',
      borderWidth: '2px',
      borderColor: theme.palette.primary.main,
    },
  },
});

interface Props {
  maxRows?: number;
  minRows?: number;
  placeholder?: string;
  defaultValue?: string;
  onChange: any;
  classes: any;
  value?: string;
}

const StyledTextArea: React.FC<Props> = (content: Props) => {
  return (
    <TextAreaAutoSize
      style={{ resize: 'none' }}
      className={content.classes.root}
      minRows={content.minRows}
      maxRows={content.maxRows}
      placeholder={content.placeholder}
      defaultValue={content.defaultValue}
      value={content.value}
      onChange={content.onChange}
    />
  );
};

export default withStyles(styles)(StyledTextArea);
