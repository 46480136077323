import { API } from 'aws-amplify';
import {
  adminPushNotification as adminPushNotificationMutation,
  adminReviewPost as adminReviewPostMutation,
} from '../graphql/customMutations';
import { GRAPHQL_AUTH_MODE } from '../graphql/types';

const adminReviewPost = async (postId: string, reviewStatus: string, reason?: string) => {
  const res: any = await API.graphql({
    query: adminReviewPostMutation,
    variables: {
      postId: postId,
      reviewStatus: reviewStatus,
      reason: reason,
    },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  });
  return res.data;
};

const adminSendPushNotification = async (to: string, title: string, body: string, ids?: string[]) => {
  const res: any = await API.graphql({
    query: adminPushNotificationMutation,
    variables: {
      to: to,
      title: title,
      body: body,
      ids: ids,
    },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  });
  return res.data.adminPushNotification;
};

export { adminReviewPost, adminSendPushNotification };
