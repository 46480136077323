import React from 'react';
import CSS from 'csstype';
import { CircularProgress } from '@material-ui/core';

interface Props {
  // container style
  style?: CSS.Properties;
  // The color of the component. It supports those theme colors that make sense for this component.
  color?: 'primary' | 'secondary' | 'inherit';
  // The size of the circle.
  size?: number | string;
  // The thickness of the circle.
  thickness?: number;
}

const Spinner: React.FC<Props> = (props: Props) => {
  const style = props.style || {};
  style.display = style.display || 'flex';
  style.alignItems = style.alignItems || 'center';
  style.justifyContent = style.justifyContent || 'center';

  return (
    <div style={style}>
      <CircularProgress size={props.size || '1rem'} color={props.color || 'inherit'} thickness={props.thickness} />
    </div>
  );
};

export default Spinner;
