import React, { useCallback, useContext, useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import { useLocation, useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';

import { PeakzTheme } from '../../theme/theme';
import { fetchUser } from '../../core/services/userSvc';
import { User } from '../../core/graphql/types';
import { authStore } from '../../core/stores/auth';
import CompactPageLayout from '../../components/layouts/CompactPageLayout';
import SignupContainer from '../../containers/SignupContainer';
import Metadata from '../../core/misc/Metadata';
import LoginContainer from '../../containers/LoginContainer';
import cacheHelpers from '../../core/helpers/cache';
import domHelpers from '../../core/helpers/dom';
import PageTitle from '../../components/layouts/PageTitle';
import { getAthleteCheckoutRoute, userHomeRoute } from '../../core/helpers/route';

const useStyles = makeStyles((theme: PeakzTheme) => ({
  content: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  lockIcon: {
    color: theme.palette.text.secondary,
    verticalAlign: 'middle',
    marginTop: theme.typography.pxToRem(-4),
    marginRight: theme.typography.pxToRem(4),
  },
  submitButton: {
    fontSize: theme.typography.pxToRem(16),
    color: '#fff',
    height: theme.typography.pxToRem(48),
  },
  link: {
    cursor: 'pointer',
  },
}));

const signupTxt = 'Sign up';
const loginTxt = 'Log in';

const AuthPage: React.FC = () => {
  const userIdParam = (useParams<{ userId?: string }>().userId || '').toLowerCase();
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const theme = useTheme();
  const isSmallScreen = domHelpers.isSmallScreen(useMediaQuery, theme);
  const [authState] = useContext(authStore);
  const [athlete, setAthlete] = useState<User | null | undefined>();
  const [mode, setMode] = useState<'signup' | 'login'>(location.pathname.indexOf('/signup') >= 0 ? 'signup' : 'login');
  const [unmounted, setUnmounted] = useState(false);
  const loadUser = useCallback(
    async (userIdentifier: string) => {
      const user = userIdentifier ? await fetchUser(userIdentifier, '') : null;

      setAthlete(user);
    },
    [authState.user],
  );
  const handleLoginClick = () => {
    history.replace(`${location.pathname.replace('signup', 'login')}${location.search || ''}`);
  };
  const handleSignupClick = () => {
    history.replace(`${location.pathname.replace('login', 'signup')}${location.search || ''}`);
  };
  const handleAuthComplete = () => {
    if (athlete) {
      history.push(getAthleteCheckoutRoute(athlete));
    } else {
      history.push({
        pathname: userHomeRoute,
        search: window.location.search,
      });
    }
  };
  const handleFederatedAuthStart = () => {
    const cacheExpirationMs = Date.now() + 60 * 1000; // 60 seconds
    const authRedirectRoute = athlete ? getAthleteCheckoutRoute(athlete) : userHomeRoute;

    cacheHelpers.save(cacheHelpers.keys.authRedirectRoute, authRedirectRoute, cacheExpirationMs);
    if (window.location.search) {
      cacheHelpers.save(cacheHelpers.keys.authRedirectSearch, window.location.search, cacheExpirationMs);
    }
  };

  useEffect(() => {
    setMode(location.pathname.indexOf('/signup') >= 0 ? 'signup' : 'login');
  }, [location.pathname]);

  useEffect(() => {
    if (!unmounted) {
      loadUser(userIdParam);
    }

    return () => {
      setUnmounted(true);
    };
  }, [userIdParam, loadUser]);

  return (
    <CompactPageLayout
      titleChildren={
        <Box mt={1} width={1}>
          {/* If it's targeted /signup or /login */}
          {userIdParam && (
            <PageTitle>
              <b>
                {athlete
                  ? `${mode === 'signup' ? signupTxt : loginTxt} to become a fan of ${athlete.givenName}`
                  : athlete === null
                  ? mode === 'signup'
                    ? signupTxt
                    : loginTxt
                  : ''}
              </b>
            </PageTitle>
          )}
          {/* If it plain /signup or /login */}
          {!userIdParam && (
            <Typography variant="h6" align="center">
              <b>{mode === 'signup' ? signupTxt : loginTxt}</b>
            </Typography>
          )}
        </Box>
      }
    >
      {/* Metadata */}
      <Metadata title={`${mode === 'signup' ? signupTxt : loginTxt} | Peakz`} />

      {/* Current content (depending on mode) */}
      <Box mb={4}>
        {/* Signup or Login containers */}
        <Container className={classes.content} maxWidth="sm">
          <Box pl={isSmallScreen ? 0 : 3} pr={isSmallScreen ? 0 : 3}>
            {mode === 'signup' && (
              <SignupContainer
                onSignupComplete={handleAuthComplete}
                onFederatedSignupStart={handleFederatedAuthStart}
              />
            )}
            {mode === 'login' && (
              <LoginContainer onLoginComplete={handleAuthComplete} onFederatedLoginStart={handleFederatedAuthStart} />
            )}
          </Box>
        </Container>

        {/* Switch mode buttons */}
        <Box mt={2}>
          <Typography variant="body1" align="center">
            {mode === 'signup' && (
              <>
                Already have an account?{' '}
                <Link className={classes.link} variant="body1" onClick={handleLoginClick}>
                  {loginTxt}
                </Link>
              </>
            )}
            {mode === 'login' && (
              <>
                New to Peakz?{' '}
                <Link className={classes.link} variant="body1" onClick={handleSignupClick}>
                  {signupTxt}
                </Link>
              </>
            )}
          </Typography>
        </Box>
      </Box>
    </CompactPageLayout>
  );
};

export default AuthPage;
