import React, { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { getBcast } from '../graphql/queries';
import { MeetingSessionProvider, useMeetingSession } from '../meeting/providers/MeetingSessionProvider';
import { Bcast } from '../API';
import { MeetingProvider } from 'amazon-chime-sdk-component-library-react';
import { AttendeeInfo } from '../meeting/utils/attendee';
import MeetingView from '../meeting/MeetingView';
import { DeviceProvider } from '../meeting/providers/DeviceProvider';

// Page for accessing meetings by the broadcasting backend application.

type PageState =
  | 'loading' // reading bcast record
  | 'joining' // joining as 'bcaster' in progress
  | 'started' // successfuly joined the meeting, broadcast is started
  | 'ended' // meeting is ended
  | 'failed'; // an error occured on starting

const BroadcastPage: React.FC = () => {
  const [meetingId, setMeetingId] = useState<string | undefined>(undefined);
  const [pageState, setPageState] = useState<PageState>('loading');
  const [record, setRecord] = useState<Bcast | undefined>(undefined);
  const [attendee, setAttendee] = useState<AttendeeInfo | undefined>(undefined);

  const onError = (error: any) => {
    // an error occurred on starting
    // i sta cemo sad?
    // todo...
    console.log('Error', JSON.stringify(error));
    setPageState('failed');
  };

  const start = async () => {
    try {
      // parse URL
      const url = window.location.href;
      const regex = /.*broadcast\/(.*)\?bid=(.*)/;
      const match = url.match(regex);
      if (!match) {
        throw 'Invalid url';
      }
      const meetingId = match[1];
      const attendeeId = match[2];

      setMeetingId(meetingId);

      // fetch bcast record
      const response: any = await API.graphql(graphqlOperation(getBcast, { id: meetingId }));
      const record = response.data.getBcast;
      setRecord(record);
      // start joining
      setAttendee({
        id: attendeeId,
        type: 'bcaster',
        name: '',
      });
      setPageState('joining');
    } catch (error) {
      onError(error);
    }
  };

  useEffect(() => {
    start();
  }, []);

  const onActivate = (active: boolean) => {
    if (active) {
      setPageState('started');
    } else {
      setPageState('ended');
    }
  };

  // helper component to perform auto-joining
  // (must be a child component, because we need MettingSessionContext as a parent)
  const AutoJoin = () => {
    const { joinMeeting, startMeeting } = useMeetingSession();

    const joinAndStart = async () => {
      try {
        if (!attendee) {
          throw 'Internal error';
        }
        await joinMeeting(attendee, true);
        await startMeeting();
      } catch (error) {
        onError(error);
      }
    };

    useEffect(() => {
      joinAndStart();
    }, []);

    return <></>;
  };

  // helper component for debugging
  const Semaphore = (props: { color: string }) => {
    return (
      <div
        style={{
          position: 'fixed',
          height: '100%',
          width: '100%',
          backgroundColor: props.color,
        }}
      />
    );
  };

  // fix error on FireFox
  // https://issuemode.com/issues/aws/amazon-chime-sdk-component-library-react/19309108
  const meetingConfig = {
    simulcastEnabled: true,
  };

  return (
    <MeetingProvider {...meetingConfig}>
      <MeetingSessionProvider meetingId={meetingId} bcastRecord={record} onActivate={onActivate}>
        <DeviceProvider>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ flexGrow: 1, position: 'relative' }}>
              {pageState === 'loading' && <Semaphore color="yellow" />}
              {pageState === 'joining' && <AutoJoin />}
              {pageState === 'started' && <MeetingView />}
              {pageState === 'failed' && <Semaphore color="red" />}
            </div>
          </div>
        </DeviceProvider>
      </MeetingSessionProvider>
    </MeetingProvider>
  );
};

export default BroadcastPage;
