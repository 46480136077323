import React, { useContext, useEffect, useState } from 'react';
import { Twitter } from '@material-ui/icons';
import { Grid, Button, Box, Typography, useTheme, makeStyles, Fade, lighten, Zoom } from '@material-ui/core';
import { noop } from '@babel/types';

import { getTwitterAccessTokens, uploadPreviewToTwitter } from '../../core/services/social/twitterSvc';
import { appStore } from '../../core/stores/app';
import { fetchPostById, subscribeToUpdatePost } from '../../core/services/postsSvc';
import { Post } from '../../core/graphql/types';
import { capitalize } from '../../core/helpers/misc';
import urlHelpers from '../../core/helpers/url';
// import { getMediaPublishContainerID, publishVideo } from '../../core/services/social/instagramSvc';
import SocialConnectDialog from '../../components/dialogs/SocialConnectDialog';
import SpinnerBox from '../../components/ui/SpinnerBox';
import ShareLoader from './ShareLoader';
import VideoPlayer from '../../components/ui/VideoPlayer';

const buttons = [
  // {
  //   name: 'Instagram',
  //   color: '#E1306C',
  //   icon: <Instagram />,
  // },
  {
    name: 'Twitter',
    color: '#00acee',
    icon: <Twitter />,
  },
];

interface Props {
  onFinishClick: any;
  postId?: string;
}

const useStyles = makeStyles(() => ({
  root: {
    '& .Mui-disabled': {
      backgroundColor: `${lighten('#FFFFF', 0.7)} !important`,
      color: 'gray !important',
    },
    '& .video-js': {
      width: '100%',
      height: '100%',
    },
  },
  centeredGrid: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  videoPlayer: {
    position: 'relative',
    top: 0,
    right: 0,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
}));

const ShareAfterPost: React.FC<Props> = ({ onFinishClick, postId }: Props) => {
  const [appState, appDispatch] = useContext(appStore);
  const [showFacebookLogin, setShowFacebookLogin] = useState(false);
  const [showTwitterLogin, setShowTwitterLogin] = useState(false);
  const [connectDialogOpen, setConnectDialogOpen] = useState(false);
  const [previewReady, setPreviewReady] = useState(false);
  const [previewUrl, setPreviewUrl] = useState<string | undefined>();
  const [sharing, setSharing] = useState<boolean>(false);
  const [socialProvider, setSocialProvider] = useState<string | undefined>();
  const [shareCount, setShareCount] = useState<string[]>([]);
  const theme = useTheme();
  const classes = useStyles();
  const queryParams = new URLSearchParams(location.search);

  const showSocialConnectDialog = (provider: string) => {
    setShowTwitterLogin(provider === 'twitter');
    setShowFacebookLogin(provider === 'instagram');
    setConnectDialogOpen(true);
  };

  const handleTwitterAccess = async () => {
    const accessRes: any = await getTwitterAccessTokens(
      appState.user?.social?.twitterRequestToken,
      appState.user?.social?.twitterRequestSecret,
      queryParams.get('oauth_verifier'),
    );
    const jsonResponse = JSON.parse(accessRes.data.callTwitter);
    const accessParams = {
      oauthAccessToken: jsonResponse.oauthAccessToken,
      oauthAccessSecret: jsonResponse.oauthAccessSecret,
    };
    appDispatch({
      type: 'updateUser',
      userUpdateObj: {
        social: {
          ...(appState.user?.social || {}),
          twitterAccessToken: accessParams.oauthAccessToken,
          twitterAccessSecret: accessParams.oauthAccessSecret,
        },
      },
    });

    return accessParams;
  };

  const handleShareOnInstagram = () => {
    const hasInstagramCredentialsCondition =
      appState.user?.social?.fbAccessToken &&
      appState.user.social.instagramUserId &&
      appState.user.social.fbTargetPageId;

    return new Promise(async (resolve, reject) => {
      try {
        if (Boolean(hasInstagramCredentialsCondition)) {
          // const mediaId = await getMediaPublishContainerID(
          //   appState.user?.social?.instagramUserId,
          //   previewUrl,
          //   appState.user ? urlHelpers.getUserShareUrl(appState.user) : undefined,
          //   appState.user?.social?.fbAccessToken,
          // );
          // await publishVideo(appState.user?.social?.instagramUserId, mediaId, appState.user?.social?.fbAccessToken);
          // resolve({ success: true });
        } else {
          showSocialConnectDialog('instagram');
          setSharing(false);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  const handleShareOnTwitter = () => {
    const twitterRequestCondition =
      appState.user?.social?.twitterRequestSecret && appState.user?.social?.twitterRequestToken;

    const twitterAccessCondition =
      appState.user?.social?.twitterAccessToken && appState.user?.social?.twitterAccessSecret;

    return new Promise(async (resolve, reject) => {
      try {
        if (Boolean(twitterRequestCondition)) {
          //User have access tokens
          if (twitterAccessCondition) {
            const response = await uploadPreviewToTwitter(
              appState.user?.social?.twitterAccessToken,
              appState.user?.social?.twitterAccessSecret,
              previewUrl,
              appState.user ? urlHelpers.getUserShareUrl(appState.user) : undefined,
            );
            const data = JSON.parse(response.data.callTwitter);
            resolve(data);
          }
          //User doesn't have access tokens but comes from a redirection and is able to get them
          else if (Boolean(!twitterAccessCondition && queryParams.get('oauth_verifier'))) {
            const accessParams = await handleTwitterAccess();
            const response = await uploadPreviewToTwitter(
              accessParams.oauthAccessToken,
              accessParams.oauthAccessSecret,
              previewUrl,
              appState.user ? urlHelpers.getUserShareUrl(appState.user) : undefined,
            );
            const data = JSON.parse(response.data.callTwitter);
            resolve(data);
          }
        } else {
          showSocialConnectDialog('twitter');
          setSharing(false);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  const handleSharingClick = async (provider: string) => {
    setShowTwitterLogin(false);
    setShowFacebookLogin(false);
    setSharing(true);
    setSocialProvider(provider);

    let outcome: any;

    try {
      switch (provider) {
        case 'twitter':
          outcome = await handleShareOnTwitter();
          break;
        case 'instagram':
          outcome = await handleShareOnInstagram();
          break;
      }
    } catch (error) {
      showSocialConnectDialog(provider);
    }

    setSharing(false);
    if (outcome?.success) {
      appDispatch({
        type: 'showToast',
        toastTxt: `Post was succesfully shared to ${provider}`,
        toastSeverity: 'success',
      });
      setShareCount(shareCount.concat([provider]));
      shareCount.length > 0 ? onFinishClick() : noop();
    } else {
      appDispatch({
        type: 'showToast',
        toastTxt: `Something unexpected happened while sharing your post to ${provider}, try refreshing your credentials by login again`,
        toastSeverity: 'error',
      });
      showSocialConnectDialog(provider);
    }
  };

  const getLoader = (): [JSX.Element | undefined, string | undefined] => {
    const button = buttons.find((button) => {
      return button.name === capitalize(socialProvider || '');
    });

    return [button?.icon, button?.color];
  };

  useEffect(() => {
    (async () => {
      queryParams.get('instagramShared') !== null ? setShareCount(shareCount.concat('instagram')) : noop();

      const id = postId || queryParams.get('postId') || '';
      const post: Post = await fetchPostById(id);
      if (post?.vodMetadata?.hasPreview && post?.vodId) {
        setPreviewReady(true);
        setPreviewUrl(urlHelpers.getPostVodPreviewUrl(post.vodId));
        return;
      }
      const postUpdateSubscription = subscribeToUpdatePost(id, (evt) => {
        if (evt.value.data.onUpdatePostById?.vodMetadata?.hasPreview && evt.value.data.onUpdatePostById?.vodId) {
          setPreviewReady(true);
          setPreviewUrl(urlHelpers.getPostVodPreviewUrl(evt.value.data.onUpdatePostById?.vodId));
          postUpdateSubscription.unsubscribe();
        }
      });
    })();
  }, []);

  return (
    <Box className={classes.root} p={2} height={1} display="flex" flexDirection="center" alignItems="center">
      {sharing && <ShareLoader loaderIcon={getLoader()[0] || <span></span>} loaderColor={getLoader()[1] || ''} />}
      {!sharing && !previewReady && (
        <Box>
          <Box mb={2}>
            <Typography align="center" variant="body1">
              Your preview is being generated, please wait
            </Typography>
          </Box>
          <SpinnerBox />
        </Box>
      )}
      {!sharing && previewReady && (
        <Fade in={previewReady} timeout={1500}>
          <Box>
            <SocialConnectDialog
              showFacebookLogin={showFacebookLogin}
              showTwitterLogin={showTwitterLogin}
              instagramShared={shareCount.includes('instagram')}
              open={connectDialogOpen}
              postIdParam={postId}
              setOpen={setConnectDialogOpen}
            />
            <Box mb={5} mt={2}>
              <Typography
                variant="h4"
                align="center"
                dangerouslySetInnerHTML={{
                  __html: `Your post was uploaded to <span style='color: ${theme.palette.primary.main}; font-weight: bold'>Peakz</span>`,
                }}
              />
              <Box mt={2}>
                <Typography align="center" variant="h5">
                  Now it's time to share this awesome content
                </Typography>
              </Box>
            </Box>
            <Grid container>
              <Grid item className={classes.centeredGrid} xs={12}>
                <Zoom in={Boolean(previewUrl)} timeout={{ enter: 500, exit: 500 }}>
                  <div
                    style={{
                      top: 0,
                      width: '100%',
                      minHeight: '250px',
                      height: '100%',
                      background: '#000000',
                      maxWidth: theme.typography.pxToRem(500),
                    }}
                  >
                    <VideoPlayer className={classes.videoPlayer} videoUrl={previewUrl || ''} autoplay={true} />
                  </div>
                </Zoom>
              </Grid>
              <Grid style={{ marginTop: theme.spacing(3) }} className={classes.centeredGrid} item xs={12}>
                <Grid container className={classes.centeredGrid}>
                  {buttons.map((button, index) => {
                    return (
                      <Box m={1} mt={3} key={index}>
                        {button.name.toLowerCase() === 'instagram' && (
                          <Grid item xs={6}>
                            <Button
                              onClick={() => {
                                handleSharingClick(button.name.toLowerCase());
                              }}
                              disabled={shareCount.includes(button.name.toLowerCase())}
                              style={{ color: button.color, backgroundColor: 'white', minWidth: '150px' }}
                              endIcon={button.icon}
                              variant="outlined"
                            >
                              {button.name}
                            </Button>
                          </Grid>
                        )}
                        {button.name.toLowerCase() === 'twitter' && (
                          <Grid item xs={6}>
                            <Button
                              onClick={() => {
                                handleSharingClick(button.name.toLowerCase());
                              }}
                              disabled={shareCount.includes(button.name.toLowerCase())}
                              style={{ color: button.color, backgroundColor: 'white', minWidth: '150px' }}
                              endIcon={button.icon}
                              variant="outlined"
                            >
                              {button.name}
                            </Button>
                          </Grid>
                        )}
                      </Box>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
            <Box display="flex" justifyContent="center" mt={3}>
              <Button variant="contained" color="primary" onClick={onFinishClick}>
                Finish
              </Button>
            </Box>
          </Box>
        </Fade>
      )}
    </Box>
  );
};

export default ShareAfterPost;
