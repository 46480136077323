import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ReplayIcon from '@material-ui/icons/Replay';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory } from 'react-router-dom';

import { Post, User } from '../core/graphql/types';
import { deletePostById, fetchPostsByAuthor } from '../core/services/postsSvc';
import { authStore } from '../core/stores/auth';
import ActionConfirmationDialog from '../components/dialogs/ActionConfirmationDialog';
import PostElement from '../components/elements/PostElement';
import FormSubmitButton from '../components/ui/FormSubmitButton';

const useStyles = makeStyles((theme) => ({
  athletePostsRoot: {
    width: '100%', // material-ui: +16px
    margin: 0, // materiail-ui: -8px
    height: 'fit-content',
  },
  createPostButton: {
    fontSize: theme.typography.pxToRem(16),
  },
}));

interface Props {
  athlete?: User;
  editable: boolean;
  isUserSubscribed?: boolean;
}

const AthletePosts: React.FC<Props> = ({ athlete, editable, isUserSubscribed }: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const [authState] = useContext(authStore);
  const [posts, setPosts] = useState<Post[] | undefined>();
  const [postsNextToken, setPostsNextToken] = useState();
  const [loadingPosts, setLoadingPosts] = useState(false);
  const [selectedPost, setSelectedPost] = useState<Post | null>();
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const pageSize = 10;

  const handleAddItemClick = () => {
    history.push(`/create/post?o=${location.pathname}`);
  };

  const handlePostDeleteClick = (post: Post) => {
    setSelectedPost(post);
    setConfirmationDialogOpen(true);
  };

  const handleDeletePostConfirmation = async () => {
    if (selectedPost) {
      await deletePostById(selectedPost.id);
      const postIndex = (posts || []).findIndex((p) => p.id === selectedPost.id);

      if (postIndex >= 0) {
        setPosts((psts) => {
          const newPosts = [...(psts || [])];

          newPosts.splice(postIndex, 1);

          return newPosts;
        });
      }
    }

    setSelectedPost(null);
    setConfirmationDialogOpen(false);
  };

  const handleDeletePostCancel = () => {
    setSelectedPost(null);
    setConfirmationDialogOpen(false);
  };

  const handleLoadMore = async (reset = false) => {
    if (athlete) {
      setLoadingPosts(true);
      const res = await fetchPostsByAuthor(athlete.id, pageSize, postsNextToken, Boolean(authState.user));
      setLoadingPosts(false);

      if (res) {
        if (reset) {
          // Reset state
          setPosts(res.posts);
          setPostsNextToken(res.nextToken);
        } else {
          setPosts((psts) => [...(psts || []), ...(res || {}).posts]);
          setPostsNextToken(res.nextToken);
        }
      }
    }
  };

  useEffect(() => {
    if (isUserSubscribed !== undefined) {
      // Load posts
      handleLoadMore(true);
    }
  }, [isUserSubscribed]);

  return (
    <div className={classes.athletePostsRoot}>
      {/* Title */}
      <Typography variant="h4" align="left" gutterBottom>
        {editable && <b>My posts</b>}
        {!editable && <b>Recent posts by {athlete?.givenName}</b>}
      </Typography>
      {/* Posts */}
      <Box display="flex" justifyContent="center" mt={3}>
        <Grid container direction="column" spacing={2}>
          {/* Add new post button */}
          {editable && (
            <Grid item>
              <Box display="flex" justifyContent="center">
                <Button
                  className={classes.createPostButton}
                  color="primary"
                  fullWidth
                  endIcon={<AddCircleOutlineIcon fontSize="large" />}
                  onClick={handleAddItemClick}
                >
                  Create a new post
                </Button>
              </Box>
            </Grid>
          )}

          {/* Posts */}
          {athlete &&
            posts &&
            posts.length > 0 &&
            posts.map((post) => (
              <Grid key={post.id} item>
                <PostElement
                  showPreviewDownloaderButton={true}
                  author={athlete}
                  post={post}
                  editPermission={editable}
                  viewPermission={
                    !post.needsSubscription ||
                    editable ||
                    Boolean(isUserSubscribed) ||
                    authState.isAdmin ||
                    authState.isModerator ||
                    authState.isGuest
                  }
                  onDeleteClick={handlePostDeleteClick}
                />
              </Grid>
            ))}

          {/* Show when there are no posts for this athlete */}
          {posts && posts.length === 0 && (
            <Grid item>
              <Typography variant="body1" align="left">
                Nothing published yet.
              </Typography>
            </Grid>
          )}

          {/* Load more button */}
          {!loadingPosts && postsNextToken && (
            <Grid item>
              <Box display="flex" justifyContent="center">
                <FormSubmitButton
                  color="primary"
                  endIcon={<ReplayIcon fontSize="large" />}
                  onClick={handleLoadMore.bind(null, false)}
                >
                  Load more
                </FormSubmitButton>
              </Box>
            </Grid>
          )}

          {/* Spinner */}
          {loadingPosts && (
            <Grid item>
              <Box display="flex" justifyContent="center">
                <CircularProgress size={24} />
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>

      {/* Delete Post */}
      {confirmationDialogOpen && (
        <ActionConfirmationDialog
          open={confirmationDialogOpen}
          title={''}
          description={'Are you sure you want to delete this post?'}
          onYesClick={handleDeletePostConfirmation}
          onNoClick={handleDeletePostCancel}
          onClose={handleDeletePostCancel}
        />
      )}
    </div>
  );
};

export default AthletePosts;
