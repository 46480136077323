import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';
import InputAdornment from '@material-ui/core/InputAdornment';

import { searchAthletes } from '../../core/services/userSvc';
import { User } from '../../core/graphql/types';

interface Props {
  inputValue: string;
  onValueChange: (name: string) => void;
}

let timerInt: NodeJS.Timeout;
const AthleteAutocomplete: React.FC<Props> = ({ inputValue: inputValueParam, onValueChange }: Props) => {
  const [searching, setSearching] = React.useState(false);
  const [value, setValue] = React.useState<User | null>(null);
  const [inputValue, setInputValue] = React.useState(inputValueParam);
  const [options, setOptions] = React.useState<User[]>([]);
  const [open, setOpen] = React.useState(false);
  const [t] = useTranslation();

  React.useEffect(() => {
    let active = true;

    (async () => {
      if (timerInt) {
        clearTimeout(timerInt);
      }
      setSearching(true);
      timerInt = setTimeout(async () => {
        const res = await searchAthletes({
          name: {
            matchPhrasePrefix: inputValue,
          },
        });
        setSearching(false);

        if (active && res) {
          setOptions(res.athletes);
        }
      }, 300);
    })();

    if (inputValue === '') {
      setOpen(false);
    }

    return () => {
      active = false;
    };
  }, [inputValue]);

  React.useEffect(() => {
    if (!inputValueParam) {
      setValue(null);
    }
  }, [inputValueParam]);

  return (
    <Autocomplete
      id="athlete-search"
      getOptionLabel={(option) => option.name}
      options={options}
      open={open}
      onOpen={() => {
        if (inputValue) {
          setOpen(true);
        }
      }}
      onClose={() => {
        setOpen(false);
      }}
      autoComplete
      includeInputInList
      noOptionsText={!searching ? t('global.athlete_not_found') : '...'}
      value={value}
      getOptionSelected={(option, value) => {
        return option && value && option.id === value.id;
      }}
      onChange={(event: any, newValue: User | null) => {
        setValue(newValue);
        if (newValue) {
          onValueChange(newValue.name);
        } else {
          onValueChange('');
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          placeholder="Find athlete..."
          variant="outlined"
          {...params}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      )}
      renderOption={(option) => {
        const matches = match(option.name, inputValue);
        const parts = parse(option.name, matches);

        return (
          <Grid container alignItems="center">
            <Grid item>
              {parts.map((part: any, index: any) => (
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              ))}
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

export default AthleteAutocomplete;
