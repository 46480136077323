import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { useHistory } from 'react-router';
import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib/types';

import LoginForm, { LoginFormData } from './forms/LoginForm';
import { authStore, AuthUser } from '../core/stores/auth';
import { completeSignIn } from '../core/services/authSvc';
import { reportError } from '../core/helpers/core';
import { appStore } from '../core/stores/app';

interface Props {
  onLoginComplete: () => void;
  onFederatedLoginStart?: () => void;
}

const LoginContainer: React.FC<Props> = ({ onLoginComplete, onFederatedLoginStart }: Props) => {
  const history = useHistory();
  const [, authDispatch] = useContext(authStore);
  const [, appDispatch] = useContext(appStore);
  const [submitting, setSubmitting] = useState(false);
  const [errorTimeoutId, setErrorTimeoutId] = useState(-1);
  const [errorTxt, setErrorTxt] = useState('');

  const handleLoginClick = async (formData: LoginFormData) => {
    console.log(formData);
    setSubmitting(true);
    try {
      const authUser: AuthUser = await Auth.signIn(formData.email.trim(), formData.password);

      if (authUser.challengeName) {
        if (authUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
          history.push('/resetpassword');
        } else {
          setError(
            `There is the following challenge to login: ${authUser.challengeName}.`,
            setErrorTxt,
            errorTimeoutId,
            setErrorTimeoutId,
          );
        }

        return;
      }

      await completeSignIn(authUser, authDispatch, appDispatch);
      if (onLoginComplete) {
        onLoginComplete();
      }
    } catch (err: any) {
      console.log(err);
      reportError('signin', 'signin', err);
      if (err.code === 'UserNotConfirmedException') {
        // It's not possible anymore. User's are automatically confirmed
        setError(err.message, setErrorTxt, errorTimeoutId, setErrorTimeoutId);
      } else if (err.code === 'PasswordResetRequiredException') {
        // The error happens when the password is reset in the Cognito console
        // In this case you need to call forgotPassword to reset the password
        // Please check the Forgot Password part.
        setError(
          'It seems you need to reset your password. Please, click on "Forgot password?" to reset it.',
          setErrorTxt,
          errorTimeoutId,
          setErrorTimeoutId,
        );
      } else if (err.code === 'UserNotFoundException' || err.code === 'NotAuthorizedException') {
        // The error happens when the supplied username/email does not exist in the Cognito user pool
        setError('Incorrect email or password.', setErrorTxt, errorTimeoutId, setErrorTimeoutId);
      } else {
        setError(`Log in error with code ${err.code}.`, setErrorTxt, errorTimeoutId, setErrorTimeoutId);
      }
      setSubmitting(false);
    }
  };
  const handleGoogleLoginClick = async () => {
    setSubmitting(true);

    // Notify Federated login is about to start
    if (onFederatedLoginStart) {
      onFederatedLoginStart();
    }

    // Start federated sign in
    await Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google,
    });
  };
  const handleFacebookLoginClick = async () => {
    setSubmitting(true);

    // Notify Federated login is about to start
    if (onFederatedLoginStart) {
      onFederatedLoginStart();
    }

    // Start federated sign in
    await Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Facebook,
    });
  };
  const handleForgotPwdClick = () => {
    history.push('/forgotpassword');
  };

  return (
    <Paper variant="outlined">
      <Box p={2}>
        <LoginForm
          submitting={submitting}
          errorTxt={errorTxt}
          onLoginClick={handleLoginClick}
          onGoogleLoginClick={handleGoogleLoginClick}
          onFacebookLoginClick={handleFacebookLoginClick}
          onForgotPwdClick={handleForgotPwdClick}
        />
      </Box>
    </Paper>
  );
};

export default LoginContainer;

function setError(
  txt: string,
  setFormErrorTxt: Dispatch<SetStateAction<string>>,
  errorTimeoutId: number,
  setErrorTimeoutId: Dispatch<SetStateAction<number>>,
  closeDelay = 30000,
) {
  if (errorTimeoutId) {
    clearTimeout(errorTimeoutId);
  }

  if (txt) {
    errorTimeoutId = window.setTimeout(() => {
      setFormErrorTxt('');
    }, closeDelay);
  }

  setFormErrorTxt(txt);
  setErrorTimeoutId(errorTimeoutId);
}
