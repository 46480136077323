import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Paper from '@material-ui/core/Paper';

import { AthleteSponsor } from '../../core/graphql/types';
import urlHelpers from '../../core/helpers/url';

const useStyles = makeStyles((theme) => ({
  sponsorTile: {
    position: 'relative',
    height: '100px',
    textAlign: 'center',
    //padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      //padding: theme.spacing(1),
    },
    '& img': {
      position: 'absolute',
      maxWidth: '100%',
      maxHeight: '100%',
      display: 'inline-block' /* Instead of display: block; */,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },
  sponsorDeleteButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
}));

interface Props {
  sponsor: AthleteSponsor;
  editable: boolean;
  onDeleteClick: (sponsor: AthleteSponsor) => void;
}

const SponsorCard: React.FC<Props> = ({ sponsor, editable, onDeleteClick }: Props) => {
  const classes = useStyles();

  return (
    <Paper className={classes.sponsorTile} variant="outlined">
      <img src={urlHelpers.getMediaUrlFromS3Key(sponsor.imgS3Key, 'img')} />
      {editable && (
        <IconButton
          className={classes.sponsorDeleteButton}
          onClick={() => {
            onDeleteClick(sponsor);
          }}
          size="small"
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      )}
    </Paper>
  );
};

export default SponsorCard;
