import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { AntTab, AntTabs } from './AntTabs';
import Panel from './Panel';

const useStyles = makeStyles(() => ({
  panelGroupRoot: {
    width: '100%',
  },
}));

interface Props {
  tabs: string[];
  selectedTab: number;
  children: React.ReactNode;
  actions?: React.ReactNode;
  onSelectedTabChange: (val: number) => void;
}

const PanelGroup: React.FC<Props> = ({ tabs, selectedTab, children, actions, onSelectedTabChange }: Props) => {
  const classes = useStyles();
  const handleSelectedTabChange = (event: React.ChangeEvent<any>, newValue: number) => {
    onSelectedTabChange(newValue);
  };

  return (
    <div className={classes.panelGroupRoot}>
      {/* Tabs */}
      <Box mb={2}>
        <AntTabs
          value={selectedTab}
          variant="scrollable"
          scrollButtons="auto"
          onChange={handleSelectedTabChange}
          aria-label="ant example"
        >
          {tabs.map((t, i) => (
            <AntTab key={i} label={t} />
          ))}
        </AntTabs>
      </Box>
      {/* Content */}
      <Box mb={3}>
        <Panel title={String(tabs[selectedTab])}>{children}</Panel>
      </Box>
      {/* Actions */}
      {actions && (
        <Box display="flex" justifyContent="flex-start">
          {actions}
        </Box>
      )}
    </div>
  );
};

export default PanelGroup;
