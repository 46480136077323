// eslint-disable-next-line @typescript-eslint/no-empty-function
import { getData as getCountryList, getName } from 'country-list';
import { SelectData } from 'mui-rff';

export const countries: SelectData[] = getCountryList()
  .map((country) => ({
    label: country.name,
    value: country.code,
  }))
  .sort((a, b) => (a.label > b.label ? 1 : -1));
export const getCountryName = (code: string) => (code && getName(code)) || code;

export const getGifUrl = (gifId: string) => `https://i.giphy.com/media/${gifId}/giphy.webp`;

export const sportsObj: any = {
  american_football: { name: 'American football', icon: 'icon-sport' },
  athletics: { name: 'Athletics', icon: 'icon-athlete' },
  auto_racing: { name: 'Auto racing', icon: 'icon-racing-lap' },
  badminton: { name: 'Badminton', icon: 'icon-sport-net' },
  basketball: { name: 'Basketball', icon: 'icon-basketball' },
  boxing: { name: 'Boxing', icon: 'icon-boxing' },
  climbing: { name: 'Climbing', icon: '' },
  cricket: { name: 'Cricket', icon: '' },
  cycling: { name: 'Cycling', icon: 'icon-cycling-road' },
  dancing: { name: 'Dancing', icon: '' },
  football: { name: 'Football', icon: 'icon-football' },
  formula_racing: { name: 'Formula racing', icon: 'icon-racing-lap' },
  golf: { name: 'Golf', icon: '' },
  gymnastics: { name: 'Gymnastics', icon: 'icon-acrobatics' },
  handcycling: { name: 'Handcycling', icon: '' },
  hockey: { name: 'Hockey', icon: '' },
  horse_riding: { name: 'Horse riding', icon: '' },
  jiu_jitsu: { name: 'Jiu Jitsu', icon: 'icon-wrestling' },
  judo: { name: 'Judo', icon: 'icon-wrestling' },
  karate: { name: 'Karate', icon: 'icon-wrestling' },
  karting: { name: 'Karting', icon: 'icon-racing-lap' },
  kickboxing: { name: 'Kickboxing', icon: 'icon-boxing' },
  kitesurfing: { name: 'Kitesurfing', icon: '' },
  martial_arts: { name: 'Martial Arts', icon: 'icon-wrestling' },
  mma: { name: 'MMA', icon: 'icon-wrestling' },
  motorsports: { name: 'Motorsports', icon: 'icon-racing-lap' },
  paragliding: { name: 'Paragliding', icon: '' },
  rowing: { name: 'Rowing', icon: 'icon-rowing-2' },
  skiing: { name: 'Skiing', icon: 'icon-skiing' },
  snowboarding: { name: 'Snowboarding', icon: 'icon-snowboarding' },
  softball: { name: 'Softball', icon: 'icon-soccer-goal' },
  surfing: { name: 'Surfing', icon: 'icon-surf' },
  swimming: { name: 'Swimming', icon: 'icon-swimming' },
  table_tennis: { name: 'Table tennis', icon: 'icon-ping-pong' },
  tennis: { name: 'Tennis', icon: 'icon-tennis' },
  triathlon: { name: 'Triathlon', icon: 'icon-cycling-road' },
  volleybal: { name: 'Volleybal', icon: 'icon-sport-net' },
  wakeboarden: { name: 'Wakeboarden', icon: '' },
  waterskiing: { name: 'Waterskiing', icon: '' },
};
const sportsArr = Object.entries(sportsObj)
  .map((entry: any) => ({ value: entry[0], label: entry[1].name }))
  .sort((a, b) => (a.label > b.label ? 1 : -1))
  .concat([{ label: 'Different', value: 'different' }]);
export const sports: SelectData[] = sportsArr;
export const getSportName = (code: string): string => (sportsObj[code] && sportsObj[code].name) || code;
export const sportsGifsObj: any = {
  american_football: 'fu8htuZjAwr8BVulhu',
  athletics: 'jIvxmHtW8WPuUBkvDW',
  auto_racing: '2E1yf7GARXlzW',
  badminton: 'LoHprP1EItHyCNfue7',
  basketball: '2Fazg4XrLLmoGqwMg',
  boxing: 'i03O15D50joLS',
  climbing: '3o7TKoLSUWybe97uWA',
  cricket: 'jsTgk136sV71K',
  cycling: 'mEyIyoMltSiTFTynJ7',
  dancing: 'tsX3YMWYzDPjAARfeg',
  football: 'dKdtyye7l5f44',
  formula_racing: '2yrwFUDutWp7o5DIwP',
  golf: 'Moo8SpSUk6R4A',
  gymnastics: 'NsEU83HMPEM2ekYwCj',
  handcycling: 'RiLIDTXQQqpPi',
  hockey: 'xTiN0sRXRzMQ54AL7i',
  horse_riding: '1xmSRZoPEySNAOH6ki',
  jiu_jitsu: 'Vdu4ZSqcCw0AoQ8v7F',
  judo: 'FLSlPziifZsME',
  karate: 'xT0xeHDVBcAulhRJRK',
  karting: 'w6p6rsXc08xb3yq0uy',
  kickboxing: 'LWCPnMUt4Buxi',
  kitesurfing: '3o7abBFNEigJyBszNC',
  martial_arts: '7K1OQmbCnKAa4',
  mma: '26mkhJ5PqethpswzS',
  motorsports: 'YhMpDA2lO2VG0',
  paragliding: '3o7budg88yegivkPxC',
  rowing: 'N409BsIWarmh2',
  skiing: '3oEduSyEJzGEm60xR6',
  snowboarding: 'j2vOg563CFFb2pZyvO',
  softball: 'dt5517hyXHiEMjFaLE',
  surfing: 'dJUtqIcqeyMvK',
  swimming: 'TFXoCcEemsS1G',
  table_tennis: 'Udjzd1IMq4G1q',
  tennis: 'myWd3Omj7KToQ',
  triathlon: 'gjUWdxUJ4KwcyDRw9Z',
  volleybal: '2YlkSZbq2uJlWwLnJx',
  wakeboarden: 'fx0T21GIpbmkJKfbdx',
  waterskiing: 'e1HhuzUB3WQsE',
  other: '8OPhe13ksG50U0RnT3',
};
export const getSportIcon = (code: string): SportsIconType =>
  (sportsObj[code] && sportsObj[code].icon) || 'icon-player-shirt';

export const genders: SelectData[] = [
  {
    label: 'Male',
    value: 'male',
  },
  {
    label: 'Female',
    value: 'female',
  },
  {
    label: 'Prefer not to say',
    value: 'prefer_not_to_say',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

export const getGenderName = (gender: string) => {
  switch (gender) {
    case 'male':
      return 'Male';
    case 'female':
      return 'Female';
    default:
      return 'Prefer not to say';
  }
};

export type SportsIconType =
  | ''
  | 'icon-user-male-circle'
  | 'icon-hashtag-activity-feed'
  | 'icon-sumo'
  | 'icon-player-shirt'
  | 'icon-leaderboard'
  | 'icon-wallet'
  | 'icon-bank-card-euro'
  | 'icon-medal2-third-place'
  | 'icon-medal-second-place'
  | 'icon-battle-pokemon'
  | 'icon-medal2'
  | 'icon--scoreboard-'
  | 'icon-basketball'
  | 'icon-handball'
  | 'icon-volleyball-2'
  | 'icon-cycling-road'
  | 'icon-swimming'
  | 'icon-dumbbell'
  | 'icon-football2'
  | 'icon-trophy'
  | 'icon-volleyball'
  | 'icon-tennis'
  | 'icon-rowing-2'
  | 'icon-sneakers'
  | 'icon-hockey'
  | 'icon-acrobatics'
  | 'icon-basketball-2'
  | 'icon-weightlift'
  | 'icon-running'
  | 'icon-boxing'
  | 'icon-basketball-field'
  | 'icon-pole-vault'
  | 'icon-soccer-goal'
  | 'icon-teams'
  | 'icon-water-polo'
  | 'icon-exercise'
  | 'icon-world-cup'
  | 'icon-skiing'
  | 'icon-ping-pong'
  | 'icon-snowboarding'
  | 'icon-tennis-ball'
  | 'icon-sport-net'
  | 'icon-bowling'
  | 'icon-sport'
  | 'icon-apple-mail'
  | 'icon-place-marker'
  | 'icon-marker'
  | 'icon-apple-phone'
  | 'icon-login-rounded-right'
  | 'icon-onboarding'
  | 'icon-apple-contacts'
  | 'icon-mission-of-a-company'
  | 'icon-man-winner'
  | 'icon-push'
  | 'icon-strength'
  | 'icon-olympic-rings'
  | 'icon-olympic-torch'
  | 'icon-windsurfing'
  | 'icon-foul'
  | 'icon-whistle'
  | 'icon-strategy-board'
  | 'icon-surf'
  | 'icon-tournament-mode'
  | 'icon-rating'
  | 'icon-box-important'
  | 'icon-error'
  | 'icon-video'
  | 'icon-video-playlist'
  | 'icon-resume-button'
  | 'icon-circled-play'
  | 'icon-stop-circled'
  | 'icon-rewind-button-round'
  | 'icon-circled-pause'
  | 'icon-fast-forward-round'
  | 'icon-upload'
  | 'icon-download'
  | 'icon-hide'
  | 'icon-visible'
  | 'icon-tails'
  | 'icon-update-left-rotation'
  | 'icon-shortlist'
  | 'icon-personal-trainer'
  | 'icon-wrestling'
  | 'icon-fencing'
  | 'icon-flex-biceps'
  | 'icon-football'
  | 'icon-baseball-player'
  | 'icon-time-out'
  | 'icon-surfing'
  | 'icon-edit-user-male'
  | 'icon-edit-property'
  | 'icon-down-squared'
  | 'icon-send'
  | 'icon-sports-video-intersound'
  | 'icon-stopwatch'
  | 'icon-link'
  | 'icon-donate'
  | 'icon-user-female-circle'
  | 'icon-racing-lap'
  | 'icon-athlete';

export default {
  countries,
  sports,
  sportsGifsObj,
  getGifUrl,
  genders,
};
