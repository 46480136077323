import React, { useEffect, useState, useRef, useContext } from 'react';
import { makeRequired, makeValidate } from 'mui-rff';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Box, Paper, Container, Typography, useMediaQuery, capitalize, lighten } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';
import Fade from '@material-ui/core/Fade';
import { useParams, useHistory } from 'react-router-dom';
import { Form } from 'react-final-form';
import * as Yup from 'yup';
import Auth from '@aws-amplify/auth';

import { pwdMinLen, recaptchaSiteKeyV3 } from '../config';
import FormRow from '../components/ui/FormRow';
import FormTextField from '../components/ui/FormTextField';
import SubmitButton from '../components/ui/SubmitButton';
import AppHeaderPlaceHolder from '../components/layouts/AppHeaderPlaceHolder';
import { PeakzTheme } from '../theme/theme';
import domHelpers from '../core/helpers/dom';
import { appStore } from '../core/stores/app';
import ReCAPTCHA from 'react-google-recaptcha';
import { authStore, AuthUser } from '../core/stores/auth';
import { completeSignIn } from '../core/services/authSvc';
import { userHomeRoute } from '../core/helpers/route';
import { getOptimisedImgUrl } from '../core/helpers/url';
import ProgressiveImage from 'react-progressive-image';
import userHelpers from '../core/helpers/user';

interface SignUpFormData {
  name: string;
  email: string;
  password: string;
}

const formSchema = Yup.object().shape({
  password: Yup.string().min(pwdMinLen, `Password needs to be at least ${pwdMinLen} characters.`).required(),
});

const validate = makeValidate(formSchema);
const required = makeRequired(formSchema);

const useStyles = makeStyles((theme: PeakzTheme) => ({
  root: {
    height: '100vh',
    color: 'white',
    position: 'relative',
    overflowX: 'hidden',
    minHeight: theme.typography.pxToRem(600),
    '& img': {
      width: 'auto',
      height: '100%',
      position: 'absolute',
      top: '-9999px',
      left: '-9999px',
      right: '-9999px',
      bottom: '-9999px',
      margin: 'auto',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  formContainer: {
    width: '100%',
    backgroundColor: 'rgba(20, 20, 20, 0.7)',
    color: 'white',
    '& .MuiFormLabel-root': {
      color: 'white',
    },
    '& .MuiInputBase-root': {
      border: `1px solid ${lighten('#000000', 0.7)}`,
      backgroundColor: 'white',
    },
    '& .Mui-disabled': {
      backgroundColor: lighten('#000000', 0.9),
    },
  },
}));

const PremiumInvite: React.FC = () => {
  const classes = useStyles();
  const { name, email }: any = useParams();
  const recaptchaRef: any = useRef();
  const [authState, authDispatch] = useContext(authStore);
  const [, appDispatch] = useContext(appStore);
  const [submitting, setSubmitting] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const theme = useTheme();
  const history = useHistory();
  const isSmallScreen = domHelpers.isSmallScreen(useMediaQuery, theme);

  useEffect(() => {
    if (userHelpers.isLoggedIn(authState.user)) {
      history.push({
        pathname: userHomeRoute,
        search: window.location.search,
      });
    }
    const t = setTimeout(() => {
      setLoaded(true);
      clearTimeout(t);
    }, 500);
  }, []);

  const initialValues: SignUpFormData = {
    name: name,
    email: email,
    password: '',
  };

  const handleSignUpClick = async (signUpFormData: SignUpFormData) => {
    setSubmitting(true);
    appDispatch({
      type: 'hideToast',
    });
    try {
      setSubmitting(true);
      const recaptchaToken = await recaptchaRef.current.executeAsync();
      await Auth.signUp({
        username: signUpFormData.email.trim(),
        password: signUpFormData.password,
        attributes: {
          email: signUpFormData.email.trim(),
          given_name: name,
          name: name,
        },
        validationData: { recaptchaToken: recaptchaToken, recaptchaVersion: '3' },
      });

      const authUser: AuthUser = await Auth.signIn(signUpFormData.email.trim(), signUpFormData.password);
      await completeSignIn(authUser, authDispatch, appDispatch);
      history.push({
        pathname: userHomeRoute,
        search: window.location.search,
      });
    } catch (err: any) {
      if (
        err.code === 'UsernameExistsException' ||
        (err.code === 'UserLambdaValidationException' && err.message.indexOf('ERR-004') >= 0)
      ) {
        appDispatch({
          type: 'showToast',
          toastTxt: 'This email address already has a Peakz account.',
          toastSeverity: 'error',
        });
      } else if (
        err.code === 'UserLambdaValidationException' &&
        (err.message.indexOf('ERR-005') >= 0 || err.message.indexOf('ERR-006') >= 0)
      ) {
        appDispatch({
          type: 'showToast',
          toastTxt: 'Recaptcha validation error.',
          toastSeverity: 'error',
        });
      } else {
        appDispatch({
          type: 'showToast',
          toastTxt: err.message
            ? err.message.replace('PreSignUp failed with error ', '')
            : 'Oops...something went wrong :(',
          toastSeverity: 'error',
        });
      }
    }
    setSubmitting(false);
  };

  return (
    <Form
      initialValues={initialValues}
      validate={validate}
      onSubmit={handleSignUpClick}
      render={({ handleSubmit, invalid }) => (
        <Fade in={loaded} timeout={300}>
          <Box className={classes.root}>
            <ProgressiveImage
              src={getOptimisedImgUrl('landing-header', 'adsignup_background.png')}
              placeholder={getOptimisedImgUrl('landing-header', 'adsignup_background.png', 'prog')}
            >
              {(src: string) => (
                <img style={isSmallScreen ? { width: 'auto' } : { width: '100%' }} src={src} alt="Header image" />
              )}
            </ProgressiveImage>
            <Box
              position="absolute"
              style={{ boxShadow: 'rgba(0, 0, 0, .45) 0 0 0 10000px inset' }}
              width={1}
              height={1}
              p={isSmallScreen ? 0 : 4}
            >
              <Container maxWidth="sm" className={classes.container}>
                <AppHeaderPlaceHolder />
                <Box mt={1}>
                  <Typography variant="h6" align="center">
                    <b>
                      Welcome, <span style={{ color: theme.palette.primary.main }}>{capitalize(name)}</span>
                    </b>
                  </Typography>
                </Box>
                <Box mt={1} mb={4}>
                  <Typography variant={isSmallScreen ? 'body2' : 'body1'} align="center">
                    Congrats, this is the final step to join{' '}
                    <span style={{ color: theme.palette.primary.main }}>
                      <b>PEAKZ</b>
                    </span>
                    , the best sports content platform. Create your account and enjoy all the advantages.
                  </Typography>
                </Box>
                <Zoom in={loaded} timeout={200}>
                  <Paper className={classes.formContainer} variant="outlined">
                    <Box p={2}>
                      <form onSubmit={handleSubmit} noValidate={true} autoComplete="new-password">
                        <FormRow label="Password">
                          <FormTextField id="password" type="password" name="password" required={required.password} />
                        </FormRow>
                        <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={recaptchaSiteKeyV3} />
                        <Box>
                          <SubmitButton submitting={submitting} disabled={invalid} fullWidth>
                            Sign Up
                          </SubmitButton>
                        </Box>
                      </form>
                    </Box>
                  </Paper>
                </Zoom>
              </Container>
            </Box>
          </Box>
        </Fade>
      )}
    />
  );
};

export default PremiumInvite;
