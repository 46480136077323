import React from 'react';
import { Container, Typography, Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useTheme } from '@material-ui/core';
import { useMediaQuery } from '@material-ui/core';

import OverlayedHeaderText from '../components/ui/OverlayedHeaderText';
import TextSwitcher from '../components/ui/TextSwitcher';
import AmbassadorsCarousel from '../components/ui/AmbassadorsCarousel';
import FAQSection from '../components/elements/FAQSection';
import NewBlogsLayout from '../components/elements/NewBlogsLayout';
import { letterSpacing } from '../theme/theme';
import AppHeaderPlaceHolder from '../components/layouts/AppHeaderPlaceHolder';
import domHelpers from '../core/helpers/dom';
import config from '../config';
import { getOptimisedImgUrl } from '../core/helpers/url';
import { useTranslation } from 'react-i18next';
// import PreviewsCarousel, { FeaturedPreviews } from '../components/ui/PreviewsCarousel';
// import { getLandingPreviews } from '../core/services/contentfulSvc';
// import { getContentfulRandomArr } from '../core/helpers/misc';

const FAQData = [
  {
    title: 'Why should I become fan of an athlete?',
    info: 'Do you want to see exclusive content from your favorite athlete? You can sign up for free and subscribe as a fan. You will get access to all the exclusive content from the athlete and at the same time support the athlete in his or her journey, how awesome is that!',
    CTA: '',
    CTALink: '',
  },
  {
    title: 'How do I become a fan?',
    info: 'Did you find an athlete you want to follow? Create a peakz account in a minute, go to the athlete  you want to follow and click the ‘’join now’’ button,. from here on the website will guide you through the process and in no time you will get access to all the exclusive content of your favorite athlete. ',
    CTA: '',
    CTALink: '',
  },
  {
    title: 'Is there a free trial?',
    info: 'To protect the content of the athletes we do not have a free trial but, you can subscribe and unsubscribe at any time you want. ',
    CTA: '',
    CTALink: '',
  },
  {
    title: 'Why shouldn’t I just keep following the athlete on Instagram / Tik Tok?',
    info: 'At Peakz we want to give athletes a fair part of the content they’re creating. At the same time we want to give fans the content they want. So if you want exclusive behind the scenes content? Then Peakz is the place to be. <br><br>Think of preparing for their match, full training sessions and after match celebrations.',
    CTA: '',
    CTALink: '',
  },
  {
    title: 'Can be fan of more than one athlete? ',
    info: 'Yes you can, there is no limit to the number of athletes you can follow. ',
    CTA: '',
    CTALink: '',
  },
  {
    title: 'In what language are the videos of the athletes?',
    info: 'Regarding the language, you can expect the athlete to create videos in the same language as their introduction video is in, but every athlete decided it themselves.',
    CTA: '',
    CTALink: '',
  },
  {
    title: 'How do I get a refund?  ',
    info: 'Refunds are dealt with on an individual level. Please mail to <a href="mailto:info@peakz.com">info@peakz.com</a> and explain the problem to us. Our support team will happily help you with your problems. ',
    CTA: '',
    CTALink: '',
  },
];

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    '& a': {
      textDecoration: 'none',
    },
  },
}));

const FanPage: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = domHelpers.isSmallScreen(useMediaQuery, theme);
  // const [previewsCarouselContent, setPreviewsCarouselContent] = useState<FeaturedPreviews[]>([]);
  const [t] = useTranslation();

  // useEffect(() => {
  //   (async () => {
  //     setPreviewsCarouselContent(getContentfulRandomArr(await getLandingPreviews(), 5));
  //   })();
  // }, []);

  const headerContent = {
    progressiveArr: [
      getOptimisedImgUrl('landing-header', 'fans_header.png'),
      getOptimisedImgUrl('landing-header', 'fans_header.png', 'prog'),
    ],
    headerMinusTitle: t('landing.for_fans.header.title'),
    headerSubtitle: t('landing.for_fans.header.subtitle'),
    CTAText: t('landing.for_fans.header.button'),
    CTALink: '/signup',
    subtitleTitle: t('landing.for_fans.subheader.title'),
    subtitleSub: t('landing.for_fans.subheader.description'),
    CTA2Text: undefined,
    CTA2Link: undefined,
  };

  const cards = [
    {
      src: `${config.assetBaseUrl}/static/images/landing-header/HowToCardsBlack.png`,
      text: t('landing.for_fans.how_it_works.cards.card1'),
    },
    {
      src: `${config.assetBaseUrl}/static/images/landing-header/HowToCardsOrange.png`,
      text: t('landing.for_fans.how_it_works.cards.card2'),
    },
    {
      src: `${config.assetBaseUrl}/static/images/landing-header/HowToCardsRed.png`,
      text: t('landing.for_fans.how_it_works.cards.card3'),
    },
  ];

  const switcherData = [
    {
      title: t('landing.for_fans.text_switcher.titles.first'),
      slug: t('landing.for_fans.text_switcher.labels.first'),
      text: t('landing.for_fans.text_switcher.descriptions.first'),
    },
    {
      title: t('landing.for_fans.text_switcher.titles.second'),
      slug: t('landing.for_fans.text_switcher.labels.second'),
      text: t('landing.for_fans.text_switcher.descriptions.second'),
    },
    {
      title: t('landing.for_fans.text_switcher.titles.third'),
      slug: t('landing.for_fans.text_switcher.labels.third'),
      text: t('landing.for_fans.text_switcher.descriptions.third'),
    },
  ];

  return (
    <div className={classes.root}>
      <AppHeaderPlaceHolder />

      {/* Header + subtext */}
      <OverlayedHeaderText content={headerContent} />

      {/* Text option switcher */}
      <TextSwitcher background="black" content={switcherData} buttonText={t('global.create_channel')} buttonLink="/" />

      {/* CarouselBlock */}
      <AmbassadorsCarousel title={t('landing.for_fans.precarousel.title')} />

      {/* Targets block */}
      <Container>
        <Box p={isSmallScreen ? 3 : 10}>
          <Typography align="center" variant="h4" style={{ letterSpacing: letterSpacing.minimal }}>
            <b>{t('landing.for_fans.how_it_works.title')}</b>
          </Typography>
          <Box mt={5}>
            <Grid spacing={isSmallScreen ? 2 : 5} container>
              {cards.map((card, index) => {
                return (
                  <Grid sm={12} md={4} key={index} item>
                    <Box display="flex" flexDirection="column" alignItems="center">
                      <Box>
                        <img width="100%" src={card.src} />
                      </Box>
                      <Box mt={1} mb={3}>
                        <Typography style={{ letterSpacing: letterSpacing.minimal }} variant="body1" align="center">
                          <b>{card.text}</b>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Box>
      </Container>

      {/* FAQ Block */}
      <FAQSection FAQData={FAQData} />

      {/* Free content carousel */}
      {/* <PreviewsCarousel items={previewsCarouselContent} /> */}

      {/* Blogs */}
      <NewBlogsLayout />
    </div>
  );
};

export default FanPage;
