import React, { useContext } from 'react';

import BasicPageLayout from '../../components/layouts/BasicPageLayout';
import { appStore } from '../../core/stores/app';
import AthletePaymentsAccount from '../../containers/AthletePaymentsAccount';

const AthletePaymentsPage: React.FC = () => {
  const [appState] = useContext(appStore);

  return (
    <BasicPageLayout
      title="Payments"
      maxWidth={appState.sideMenuVisible ? 'lg' : 'md'}
      showTitle={!appState.sideMenuVisible}
    >
      <AthletePaymentsAccount />
    </BasicPageLayout>
  );
};

export default AthletePaymentsPage;
