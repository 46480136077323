import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { Link as LinkIcon } from '@material-ui/icons';
import copy from 'copy-to-clipboard';
import React, { useContext, useState } from 'react';
import { appStore } from '../../core/stores/app';

interface Props {
  copyOpts: any;
}

const CopyTooltipOverlay: React.FC<Props> = ({ copyOpts }: Props) => {
  const [menuAnchor, setMenuAnchor] = useState<any>(undefined);
  const [, appDispatch] = useContext(appStore);

  const handleClose = () => {
    setMenuAnchor(null);
  };

  const handleCopy = (link: string) => {
    copy(link);
    appDispatch({
      type: 'showToast',
      toastTxt: 'Copied!',
      toastSeverity: 'success',
    });
    handleClose();
  };

  return (
    <IconButton
      color="primary"
      style={{
        position: 'absolute',
        margin: '10px',
        padding: '5px',
        opacity: 0.6,
        backgroundColor: 'black',
        zIndex: 999,
      }}
    >
      <LinkIcon
        onClick={(e) => {
          setMenuAnchor(e.currentTarget);
        }}
      />
      <Menu
        getContentAnchorEl={null}
        keepMounted
        id="basic-menu"
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={handleClose}
      >
        {Object.keys(copyOpts).map((key, index) => {
          return (
            <MenuItem
              onClick={() => {
                handleCopy(copyOpts[key]);
              }}
              key={index}
            >
              Copy {key}
            </MenuItem>
          );
        })}
      </Menu>
    </IconButton>
  );
};

export default CopyTooltipOverlay;
