import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppHeaderPlaceHolder from '../../components/layouts/AppHeaderPlaceHolder';
import OverlayedHeaderText from '../../components/ui/OverlayedHeaderText';
import WhyBoxes from './WhyBoxes';
import TeamBlock from './TeamBlock';
import NewBlogsLayout from '../../components/elements/NewBlogsLayout';
import { getOptimisedImgUrl } from '../../core/helpers/url';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  root: {
    '& a': {
      textDecoration: 'none',
    },
  },
}));

const AboutPage: React.FC = () => {
  const classes = useStyles();
  const [t] = useTranslation();

  const headerContent = {
    progressiveArr: [
      getOptimisedImgUrl('landing-header', 'about_header.png'),
      getOptimisedImgUrl('landing-header', 'about_header.png', 'prog'),
    ],
    headerTitle: t('landing.about_peakz.title').toUpperCase(),
    headerMinusTitle: t('landing.about_peakz.header.title'),
    headerSubtitle: t('landing.about_peakz.header.subtitle'),
    CTAText: t('global.create_channel'),
    CTALink: '/signup',
    subtitleTitle: t('landing.about_peakz.subheader.title'),
    subtitleSub: `${t('landing.about_peakz.subheader.description')} </br></br> ${t(
      'landing.about_peakz.subheader.description2',
    )}`,
  };

  return (
    <div className={classes.root}>
      <AppHeaderPlaceHolder />
      {/* Header */}
      <OverlayedHeaderText content={headerContent} />

      {/* Why blocks text */}
      <WhyBoxes />

      {/* Team members block */}
      <TeamBlock />

      {/* Blogs */}
      <NewBlogsLayout />
    </div>
  );
};

export default AboutPage;
