import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { athletesRoute, academyRoute, fansRoute, aboutRoute } from '../../core/helpers/route';

const useStyles = makeStyles((theme) => ({
  root: {
    opacity: 'inherit',
    ...theme.mixins.toolbar,
  },
}));

const AppHeaderPlaceHolder: React.FC<{ innerRef?: any }> = ({ innerRef }: { innerRef?: any; className?: any }) => {
  const classes = useStyles();
  const useWhiteBg = !['/', athletesRoute, academyRoute, fansRoute, '/policies', aboutRoute].includes(
    location.pathname,
  );

  return <div className={classes.root} style={{ backgroundColor: useWhiteBg ? 'white' : 'black' }} ref={innerRef} />;
};

export default AppHeaderPlaceHolder;
