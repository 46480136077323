import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Carousel from 'react-elastic-carousel';
import Box from '@material-ui/core/Box';
import { Container, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import { letterSpacing } from '../../theme/theme';
import clsx from 'clsx';
import CTAButton from './CTAButton';
import { Link } from 'react-router-dom';
import domHelpers from '../../core/helpers/dom';
import { PeakzTheme } from '../../theme/theme';
import { Athletes } from '../../core/contentful/types';
import { CONTENTFUL_MODELS, fetchContentByType } from '../../core/services/contentfulSvc';
import * as log from 'loglevel';
import WarningMessage from './WarningMessage';
import { getContentfulAssetURL, getContentfulRandomArr } from '../../core/helpers/misc';
import globalStyles from '../../theme/globalStyles';

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 1, itemsToScroll: 1 },
  { width: 768, itemsToShow: 2 },
  { width: 900, itemsToShow: 3 },
  { width: 1200, itemsToShow: 3 },
];

const useStyles = makeStyles((theme: PeakzTheme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    '&': globalStyles(theme).styledCarouselArrowsAndDots,
  },
  imgStyles: {
    width: '200px',
    borderRadius: '100%',
    height: '200px',
    transition: 'transform .5s',
  },
  overSized: {
    transform: 'scale(1.2)',
  },
  carouselBox: {
    minHeight: theme.typography.pxToRem(470),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    position: 'relative',
    width: '100%',
  },
}));

interface Props {
  title?: string;
  subtitle?: string;
  testData?: Athletes[];
}

const AmbassadorsCarousel: React.FC<Props> = ({ title, subtitle }: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [items, setItems] = useState<Athletes[]>();
  const [fetchErr, setFetchErr] = useState<boolean>(false);
  const isSmallScreen = domHelpers.isSmallScreen(useMediaQuery, theme);
  const [currIndex, setCurrIndex] = useState(0);

  useEffect(() => {
    (async () => {
      try {
        const resp = await fetchContentByType(CONTENTFUL_MODELS.ATHLETES, 'forCarousel', true);
        //7 random athletes for the carousel
        setItems(getContentfulRandomArr(resp.data.athletesCollection.items, 7));
      } catch (err) {
        log.error(err);
        setFetchErr(true);
      }
    })();
  }, []);

  return (
    <Box p={isSmallScreen ? 3 : 10} className={classes.root}>
      {fetchErr && (
        <WarningMessage
          icon={<ErrorOutlineIcon color="primary" />}
          text="There was a problem retrieving the success stories, please try again later..."
        />
      )}
      {!fetchErr && items && (
        <Container style={isSmallScreen ? { paddingLeft: 0, paddingRight: 0 } : {}}>
          {/* Checks if there are title or subtitle props (for the section) */}
          {title && (
            <Box>
              <Box>
                <Typography align="center" variant="h4" style={{ letterSpacing: letterSpacing.minimal }}>
                  <b>{title}</b>
                </Typography>
              </Box>
              {subtitle && (
                <Box pl={isSmallScreen ? 5 : 0} pr={isSmallScreen ? 5 : 0} mt={5}>
                  <Typography align={isSmallScreen ? 'justify' : 'center'} variant="h5">
                    {subtitle}
                  </Typography>
                </Box>
              )}
            </Box>
          )}
          <Box mt={5}>
            <Carousel
              onChange={(cur) => {
                setCurrIndex(cur.index);
              }}
              itemPadding={[30, 30, 30, 30]}
              isRTL={false}
              breakPoints={breakPoints}
            >
              {items?.map((item, index) => (
                <Box className={classes.carouselBox} key={`Peakz_athelete_carousel_img_${index}`}>
                  <Typography
                    align="center"
                    variant="body2"
                    style={{ letterSpacing: letterSpacing.tiny, position: 'absolute', top: '0' }}
                  >
                    <b>"{item.description}"</b>
                  </Typography>
                  <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
                    <img
                      data-testid="athleteImg"
                      className={clsx({
                        [classes.imgStyles]: true,
                        // Checks if the next index exists and applies an scale animation if it does
                        [classes.overSized]:
                          !isSmallScreen && items.indexOf(items[currIndex + 1]) !== -1 && index == currIndex + 1,
                      })}
                      src={getContentfulAssetURL(item, 'image', 50)}
                    />
                    <Box mt={5} display="flex" flexDirection="column" alignItems="center">
                      <Typography style={{ letterSpacing: letterSpacing.tiny }} variant="caption">
                        {item.name}
                      </Typography>
                      <Typography
                        style={{ letterSpacing: letterSpacing.tiny, color: lighten('#000', 0.6) }}
                        variant="caption"
                      >
                        {item.oneliner}
                      </Typography>
                      <Box mt={3}>
                        <Link to={item.url}>
                          <CTAButton variant="contained" color="primary" size="large">
                            <b>SUBSCRIBE</b>
                          </CTAButton>
                        </Link>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Carousel>
          </Box>
        </Container>
      )}
    </Box>
  );
};

export default AmbassadorsCarousel;
