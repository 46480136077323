/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onUpdatePostById = /* GraphQL */ `
  subscription OnUpdatePostById($id: ID!) {
    onUpdatePostById(id: $id) {
      id
      authorId
      name
      description
      needsSubscription
      type
      tierId
      vodId
      vodStatus
      vodMetadata {
        durationInMs
        orgWidth
        orgHeight
        hasPreview
      }
      bcastMetadata {
        previewImageS3Key
        time
        duration
        guests {
          id
          name
        }
        allowQuestions
        status
        bcastUrl
        recordingS3Key
      }
      thumbS3Key
      likes
      comments
      liked
      reviewStatus
      feedId
      createdAt
      updatedAt
      author {
        id
        name
        givenName
        familyName
        gender
        birthdate
        country
        sport
        profileImgS3Key
        coverImgS3Key
        introVideoS3Key
        introVideoThumbS3Key
        introVideoUrl
        mission
        instagramUrl
        facebookUrl
        youtubeUrl
        goals {
          id
          name
          level
          description
          videoUrl
          link
        }
        achievements {
          id
          name
          level
          description
          link
          videoUrl
          date
        }
        fundingGoals {
          id
          amount
          currency
          description
        }
        sponsors {
          id
          imgS3Key
        }
        emailValidated
        validationEmailSentAt
        potentialAthlete
        currency
        themeId
        role
        nickname
        stripePayoutsEnabled
        payoutCurrency
        stats {
          numUniqueViews
          numScouts
          paidByScouts
          numPosts
        }
        features {
          posts
        }
        stripeCustomerId
        ivsChannelArn
        createdAt
        updatedAt
        owner
        email
        stripeActionRequired
        stripeAccountId
        social {
          fbUserId
          fbAccessToken
          fbTargetPageId
          twitterRequestToken
          twitterRequestSecret
          twitterAccessToken
          twitterAccessSecret
          instagramUserId
        }
        firebaseTokens
      }
      tier {
        id
        userId
        name
        currency
        unitAmount
        discountType
        discount
        discountMonths
        couponId
        createdAt
        updatedAt
      }
    }
  }
`;
export const onAthleteCreation = /* GraphQL */ `
  subscription OnAthleteCreation {
    onAthleteCreation {
      id
      name
      givenName
      familyName
      gender
      birthdate
      country
      sport
      profileImgS3Key
      coverImgS3Key
      introVideoS3Key
      introVideoThumbS3Key
      introVideoUrl
      mission
      instagramUrl
      facebookUrl
      youtubeUrl
      goals {
        id
        name
        level
        description
        videoUrl
        link
      }
      achievements {
        id
        name
        level
        description
        link
        videoUrl
        date
      }
      fundingGoals {
        id
        amount
        currency
        description
      }
      sponsors {
        id
        imgS3Key
      }
      emailValidated
      validationEmailSentAt
      potentialAthlete
      currency
      themeId
      role
      nickname
      stripePayoutsEnabled
      payoutCurrency
      stats {
        numUniqueViews
        numScouts
        paidByScouts
        numPosts
      }
      features {
        posts
      }
      stripeCustomerId
      ivsChannelArn
      createdAt
      updatedAt
      owner
      email
      stripeActionRequired
      stripeAccountId
      social {
        fbUserId
        fbAccessToken
        fbTargetPageId
        twitterRequestToken
        twitterRequestSecret
        twitterAccessToken
        twitterAccessSecret
        instagramUserId
      }
      firebaseTokens
    }
  }
`;
export const onCreateBcast = /* GraphQL */ `
  subscription OnCreateBcast {
    onCreateBcast {
      id
      userId
      title
      description
      tierId
      needsSubscription
      thumbnailImageS3Key
      previewImageS3Key
      time
      duration
      guests {
        id
        name
      }
      allowQuestions
      status
      bcastUrl
      recordingS3Key
      prepareArgs
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateBcast = /* GraphQL */ `
  subscription OnUpdateBcast {
    onUpdateBcast {
      id
      userId
      title
      description
      thumbnailImageS3Key
      previewImageS3Key
      time
      duration
      guests
      allowQuestions
      status
      bcastUrl
      recordingS3Key
    }
  }
`;
export const onDeleteBcast = /* GraphQL */ `
  subscription OnDeleteBcast {
    onDeleteBcast {
      id
      userId
      title
      description
      tierId
      needsSubscription
      thumbnailImageS3Key
      previewImageS3Key
      time
      duration
      guests {
        id
        name
      }
      allowQuestions
      status
      bcastUrl
      recordingS3Key
      prepareArgs
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePost = /* GraphQL */ `
  subscription OnCreatePost {
    onCreatePost {
      id
      authorId
      name
      description
      needsSubscription
      type
      tierId
      vodId
      vodStatus
      vodMetadata {
        durationInMs
        orgWidth
        orgHeight
        hasPreview
      }
      bcastMetadata {
        previewImageS3Key
        time
        duration
        guests {
          id
          name
        }
        allowQuestions
        status
        bcastUrl
        recordingS3Key
      }
      thumbS3Key
      likes
      comments
      liked
      reviewStatus
      feedId
      createdAt
      updatedAt
      author {
        id
        name
        givenName
        familyName
        gender
        birthdate
        country
        sport
        profileImgS3Key
        coverImgS3Key
        introVideoS3Key
        introVideoThumbS3Key
        introVideoUrl
        mission
        instagramUrl
        facebookUrl
        youtubeUrl
        goals {
          id
          name
          level
          description
          videoUrl
          link
        }
        achievements {
          id
          name
          level
          description
          link
          videoUrl
          date
        }
        fundingGoals {
          id
          amount
          currency
          description
        }
        sponsors {
          id
          imgS3Key
        }
        emailValidated
        validationEmailSentAt
        potentialAthlete
        currency
        themeId
        role
        nickname
        stripePayoutsEnabled
        payoutCurrency
        stats {
          numUniqueViews
          numScouts
          paidByScouts
          numPosts
        }
        features {
          posts
        }
        stripeCustomerId
        ivsChannelArn
        createdAt
        updatedAt
        owner
        email
        stripeActionRequired
        stripeAccountId
        social {
          fbUserId
          fbAccessToken
          fbTargetPageId
          twitterRequestToken
          twitterRequestSecret
          twitterAccessToken
          twitterAccessSecret
          instagramUserId
        }
        firebaseTokens
      }
      tier {
        id
        userId
        name
        currency
        unitAmount
        discountType
        discount
        discountMonths
        couponId
        createdAt
        updatedAt
      }
    }
  }
`;
export const onUpdatePost = /* GraphQL */ `
  subscription OnUpdatePost {
    onUpdatePost {
      id
      authorId
      name
      description
      needsSubscription
      type
      tierId
      vodId
      vodStatus
      vodMetadata {
        durationInMs
        orgWidth
        orgHeight
        hasPreview
      }
      bcastMetadata {
        previewImageS3Key
        time
        duration
        guests {
          id
          name
        }
        allowQuestions
        status
        bcastUrl
        recordingS3Key
      }
      thumbS3Key
      likes
      comments
      liked
      reviewStatus
      feedId
      createdAt
      updatedAt
      author {
        id
        name
        givenName
        familyName
        gender
        birthdate
        country
        sport
        profileImgS3Key
        coverImgS3Key
        introVideoS3Key
        introVideoThumbS3Key
        introVideoUrl
        mission
        instagramUrl
        facebookUrl
        youtubeUrl
        goals {
          id
          name
          level
          description
          videoUrl
          link
        }
        achievements {
          id
          name
          level
          description
          link
          videoUrl
          date
        }
        fundingGoals {
          id
          amount
          currency
          description
        }
        sponsors {
          id
          imgS3Key
        }
        emailValidated
        validationEmailSentAt
        potentialAthlete
        currency
        themeId
        role
        nickname
        stripePayoutsEnabled
        payoutCurrency
        stats {
          numUniqueViews
          numScouts
          paidByScouts
          numPosts
        }
        features {
          posts
        }
        stripeCustomerId
        ivsChannelArn
        createdAt
        updatedAt
        owner
        email
        stripeActionRequired
        stripeAccountId
        social {
          fbUserId
          fbAccessToken
          fbTargetPageId
          twitterRequestToken
          twitterRequestSecret
          twitterAccessToken
          twitterAccessSecret
          instagramUserId
        }
        firebaseTokens
      }
      tier {
        id
        userId
        name
        currency
        unitAmount
        discountType
        discount
        discountMonths
        couponId
        createdAt
        updatedAt
      }
    }
  }
`;
export const onDeletePost = /* GraphQL */ `
  subscription OnDeletePost {
    onDeletePost {
      id
      authorId
      name
      description
      needsSubscription
      type
      tierId
      vodId
      vodStatus
      vodMetadata {
        durationInMs
        orgWidth
        orgHeight
        hasPreview
      }
      bcastMetadata {
        previewImageS3Key
        time
        duration
        guests {
          id
          name
        }
        allowQuestions
        status
        bcastUrl
        recordingS3Key
      }
      thumbS3Key
      likes
      comments
      liked
      reviewStatus
      feedId
      createdAt
      updatedAt
      author {
        id
        name
        givenName
        familyName
        gender
        birthdate
        country
        sport
        profileImgS3Key
        coverImgS3Key
        introVideoS3Key
        introVideoThumbS3Key
        introVideoUrl
        mission
        instagramUrl
        facebookUrl
        youtubeUrl
        goals {
          id
          name
          level
          description
          videoUrl
          link
        }
        achievements {
          id
          name
          level
          description
          link
          videoUrl
          date
        }
        fundingGoals {
          id
          amount
          currency
          description
        }
        sponsors {
          id
          imgS3Key
        }
        emailValidated
        validationEmailSentAt
        potentialAthlete
        currency
        themeId
        role
        nickname
        stripePayoutsEnabled
        payoutCurrency
        stats {
          numUniqueViews
          numScouts
          paidByScouts
          numPosts
        }
        features {
          posts
        }
        stripeCustomerId
        ivsChannelArn
        createdAt
        updatedAt
        owner
        email
        stripeActionRequired
        stripeAccountId
        social {
          fbUserId
          fbAccessToken
          fbTargetPageId
          twitterRequestToken
          twitterRequestSecret
          twitterAccessToken
          twitterAccessSecret
          instagramUserId
        }
        firebaseTokens
      }
      tier {
        id
        userId
        name
        currency
        unitAmount
        discountType
        discount
        discountMonths
        couponId
        createdAt
        updatedAt
      }
    }
  }
`;
export const onCreateAthleteSubscription = /* GraphQL */ `
  subscription OnCreateAthleteSubscription($subscriberId: String) {
    onCreateAthleteSubscription(subscriberId: $subscriberId) {
      subscriberId
      athleteId
      tierId
      currency
      unitAmount
      paidToAthlete
      status
      cancelAtPeriodEnd
      currentPeriodEnd
      expiresAt
      discountType
      discount
      discountRemainingMonths
      couponId
      taxRateId
      updatedAt
      createdAt
      stripeSubscriptionId
      stripePriceId
      subscriber {
        id
        name
        givenName
        familyName
        gender
        birthdate
        country
        sport
        profileImgS3Key
        coverImgS3Key
        introVideoS3Key
        introVideoThumbS3Key
        introVideoUrl
        mission
        instagramUrl
        facebookUrl
        youtubeUrl
        goals {
          id
          name
          level
          description
          videoUrl
          link
        }
        achievements {
          id
          name
          level
          description
          link
          videoUrl
          date
        }
        fundingGoals {
          id
          amount
          currency
          description
        }
        sponsors {
          id
          imgS3Key
        }
        emailValidated
        validationEmailSentAt
        potentialAthlete
        currency
        themeId
        role
        nickname
        stripePayoutsEnabled
        payoutCurrency
        stats {
          numUniqueViews
          numScouts
          paidByScouts
          numPosts
        }
        features {
          posts
        }
        stripeCustomerId
        ivsChannelArn
        createdAt
        updatedAt
        owner
        email
        stripeActionRequired
        stripeAccountId
        social {
          fbUserId
          fbAccessToken
          fbTargetPageId
          twitterRequestToken
          twitterRequestSecret
          twitterAccessToken
          twitterAccessSecret
          instagramUserId
        }
        firebaseTokens
      }
      athlete {
        id
        name
        givenName
        familyName
        gender
        birthdate
        country
        sport
        profileImgS3Key
        coverImgS3Key
        introVideoS3Key
        introVideoThumbS3Key
        introVideoUrl
        mission
        instagramUrl
        facebookUrl
        youtubeUrl
        goals {
          id
          name
          level
          description
          videoUrl
          link
        }
        achievements {
          id
          name
          level
          description
          link
          videoUrl
          date
        }
        fundingGoals {
          id
          amount
          currency
          description
        }
        sponsors {
          id
          imgS3Key
        }
        emailValidated
        validationEmailSentAt
        potentialAthlete
        currency
        themeId
        role
        nickname
        stripePayoutsEnabled
        payoutCurrency
        stats {
          numUniqueViews
          numScouts
          paidByScouts
          numPosts
        }
        features {
          posts
        }
        stripeCustomerId
        ivsChannelArn
        createdAt
        updatedAt
        owner
        email
        stripeActionRequired
        stripeAccountId
        social {
          fbUserId
          fbAccessToken
          fbTargetPageId
          twitterRequestToken
          twitterRequestSecret
          twitterAccessToken
          twitterAccessSecret
          instagramUserId
        }
        firebaseTokens
      }
      tier {
        id
        userId
        name
        currency
        unitAmount
        discountType
        discount
        discountMonths
        couponId
        createdAt
        updatedAt
      }
    }
  }
`;
export const onUpdateAthleteSubscription = /* GraphQL */ `
  subscription OnUpdateAthleteSubscription($subscriberId: String) {
    onUpdateAthleteSubscription(subscriberId: $subscriberId) {
      subscriberId
      athleteId
      tierId
      currency
      unitAmount
      paidToAthlete
      status
      cancelAtPeriodEnd
      currentPeriodEnd
      expiresAt
      discountType
      discount
      discountRemainingMonths
      couponId
      taxRateId
      updatedAt
      createdAt
      stripeSubscriptionId
      stripePriceId
      subscriber {
        id
        name
        givenName
        familyName
        gender
        birthdate
        country
        sport
        profileImgS3Key
        coverImgS3Key
        introVideoS3Key
        introVideoThumbS3Key
        introVideoUrl
        mission
        instagramUrl
        facebookUrl
        youtubeUrl
        goals {
          id
          name
          level
          description
          videoUrl
          link
        }
        achievements {
          id
          name
          level
          description
          link
          videoUrl
          date
        }
        fundingGoals {
          id
          amount
          currency
          description
        }
        sponsors {
          id
          imgS3Key
        }
        emailValidated
        validationEmailSentAt
        potentialAthlete
        currency
        themeId
        role
        nickname
        stripePayoutsEnabled
        payoutCurrency
        stats {
          numUniqueViews
          numScouts
          paidByScouts
          numPosts
        }
        features {
          posts
        }
        stripeCustomerId
        ivsChannelArn
        createdAt
        updatedAt
        owner
        email
        stripeActionRequired
        stripeAccountId
        social {
          fbUserId
          fbAccessToken
          fbTargetPageId
          twitterRequestToken
          twitterRequestSecret
          twitterAccessToken
          twitterAccessSecret
          instagramUserId
        }
        firebaseTokens
      }
      athlete {
        id
        name
        givenName
        familyName
        gender
        birthdate
        country
        sport
        profileImgS3Key
        coverImgS3Key
        introVideoS3Key
        introVideoThumbS3Key
        introVideoUrl
        mission
        instagramUrl
        facebookUrl
        youtubeUrl
        goals {
          id
          name
          level
          description
          videoUrl
          link
        }
        achievements {
          id
          name
          level
          description
          link
          videoUrl
          date
        }
        fundingGoals {
          id
          amount
          currency
          description
        }
        sponsors {
          id
          imgS3Key
        }
        emailValidated
        validationEmailSentAt
        potentialAthlete
        currency
        themeId
        role
        nickname
        stripePayoutsEnabled
        payoutCurrency
        stats {
          numUniqueViews
          numScouts
          paidByScouts
          numPosts
        }
        features {
          posts
        }
        stripeCustomerId
        ivsChannelArn
        createdAt
        updatedAt
        owner
        email
        stripeActionRequired
        stripeAccountId
        social {
          fbUserId
          fbAccessToken
          fbTargetPageId
          twitterRequestToken
          twitterRequestSecret
          twitterAccessToken
          twitterAccessSecret
          instagramUserId
        }
        firebaseTokens
      }
      tier {
        id
        userId
        name
        currency
        unitAmount
        discountType
        discount
        discountMonths
        couponId
        createdAt
        updatedAt
      }
    }
  }
`;
export const onDeleteAthleteSubscription = /* GraphQL */ `
  subscription OnDeleteAthleteSubscription($subscriberId: String) {
    onDeleteAthleteSubscription(subscriberId: $subscriberId) {
      subscriberId
      athleteId
      tierId
      currency
      unitAmount
      paidToAthlete
      status
      cancelAtPeriodEnd
      currentPeriodEnd
      expiresAt
      discountType
      discount
      discountRemainingMonths
      couponId
      taxRateId
      updatedAt
      createdAt
      stripeSubscriptionId
      stripePriceId
      subscriber {
        id
        name
        givenName
        familyName
        gender
        birthdate
        country
        sport
        profileImgS3Key
        coverImgS3Key
        introVideoS3Key
        introVideoThumbS3Key
        introVideoUrl
        mission
        instagramUrl
        facebookUrl
        youtubeUrl
        goals {
          id
          name
          level
          description
          videoUrl
          link
        }
        achievements {
          id
          name
          level
          description
          link
          videoUrl
          date
        }
        fundingGoals {
          id
          amount
          currency
          description
        }
        sponsors {
          id
          imgS3Key
        }
        emailValidated
        validationEmailSentAt
        potentialAthlete
        currency
        themeId
        role
        nickname
        stripePayoutsEnabled
        payoutCurrency
        stats {
          numUniqueViews
          numScouts
          paidByScouts
          numPosts
        }
        features {
          posts
        }
        stripeCustomerId
        ivsChannelArn
        createdAt
        updatedAt
        owner
        email
        stripeActionRequired
        stripeAccountId
        social {
          fbUserId
          fbAccessToken
          fbTargetPageId
          twitterRequestToken
          twitterRequestSecret
          twitterAccessToken
          twitterAccessSecret
          instagramUserId
        }
        firebaseTokens
      }
      athlete {
        id
        name
        givenName
        familyName
        gender
        birthdate
        country
        sport
        profileImgS3Key
        coverImgS3Key
        introVideoS3Key
        introVideoThumbS3Key
        introVideoUrl
        mission
        instagramUrl
        facebookUrl
        youtubeUrl
        goals {
          id
          name
          level
          description
          videoUrl
          link
        }
        achievements {
          id
          name
          level
          description
          link
          videoUrl
          date
        }
        fundingGoals {
          id
          amount
          currency
          description
        }
        sponsors {
          id
          imgS3Key
        }
        emailValidated
        validationEmailSentAt
        potentialAthlete
        currency
        themeId
        role
        nickname
        stripePayoutsEnabled
        payoutCurrency
        stats {
          numUniqueViews
          numScouts
          paidByScouts
          numPosts
        }
        features {
          posts
        }
        stripeCustomerId
        ivsChannelArn
        createdAt
        updatedAt
        owner
        email
        stripeActionRequired
        stripeAccountId
        social {
          fbUserId
          fbAccessToken
          fbTargetPageId
          twitterRequestToken
          twitterRequestSecret
          twitterAccessToken
          twitterAccessSecret
          instagramUserId
        }
        firebaseTokens
      }
      tier {
        id
        userId
        name
        currency
        unitAmount
        discountType
        discount
        discountMonths
        couponId
        createdAt
        updatedAt
      }
    }
  }
`;
export const onCreateFlashRequest = /* GraphQL */ `
  subscription OnCreateFlashRequest($userId: String) {
    onCreateFlashRequest(userId: $userId) {
      userId
      request
      targetId
      payload
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateFlashRequest = /* GraphQL */ `
  subscription OnUpdateFlashRequest($userId: String) {
    onUpdateFlashRequest(userId: $userId) {
      userId
      request
      targetId
      payload
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteFlashRequest = /* GraphQL */ `
  subscription OnDeleteFlashRequest($userId: String) {
    onDeleteFlashRequest(userId: $userId) {
      userId
      request
      targetId
      payload
      createdAt
      updatedAt
    }
  }
`;
export const onCreateLike = /* GraphQL */ `
  subscription OnCreateLike($userId: String) {
    onCreateLike(userId: $userId) {
      userId
      targetId
      targetType
      createdAt
      updatedAt
      user {
        id
        name
        givenName
        familyName
        gender
        birthdate
        country
        sport
        profileImgS3Key
        coverImgS3Key
        introVideoS3Key
        introVideoThumbS3Key
        introVideoUrl
        mission
        instagramUrl
        facebookUrl
        youtubeUrl
        goals {
          id
          name
          level
          description
          videoUrl
          link
        }
        achievements {
          id
          name
          level
          description
          link
          videoUrl
          date
        }
        fundingGoals {
          id
          amount
          currency
          description
        }
        sponsors {
          id
          imgS3Key
        }
        emailValidated
        validationEmailSentAt
        potentialAthlete
        currency
        themeId
        role
        nickname
        stripePayoutsEnabled
        payoutCurrency
        stats {
          numUniqueViews
          numScouts
          paidByScouts
          numPosts
        }
        features {
          posts
        }
        stripeCustomerId
        ivsChannelArn
        createdAt
        updatedAt
        owner
        email
        stripeActionRequired
        stripeAccountId
        social {
          fbUserId
          fbAccessToken
          fbTargetPageId
          twitterRequestToken
          twitterRequestSecret
          twitterAccessToken
          twitterAccessSecret
          instagramUserId
        }
        firebaseTokens
      }
    }
  }
`;
export const onUpdateLike = /* GraphQL */ `
  subscription OnUpdateLike($userId: String) {
    onUpdateLike(userId: $userId) {
      userId
      targetId
      targetType
      createdAt
      updatedAt
      user {
        id
        name
        givenName
        familyName
        gender
        birthdate
        country
        sport
        profileImgS3Key
        coverImgS3Key
        introVideoS3Key
        introVideoThumbS3Key
        introVideoUrl
        mission
        instagramUrl
        facebookUrl
        youtubeUrl
        goals {
          id
          name
          level
          description
          videoUrl
          link
        }
        achievements {
          id
          name
          level
          description
          link
          videoUrl
          date
        }
        fundingGoals {
          id
          amount
          currency
          description
        }
        sponsors {
          id
          imgS3Key
        }
        emailValidated
        validationEmailSentAt
        potentialAthlete
        currency
        themeId
        role
        nickname
        stripePayoutsEnabled
        payoutCurrency
        stats {
          numUniqueViews
          numScouts
          paidByScouts
          numPosts
        }
        features {
          posts
        }
        stripeCustomerId
        ivsChannelArn
        createdAt
        updatedAt
        owner
        email
        stripeActionRequired
        stripeAccountId
        social {
          fbUserId
          fbAccessToken
          fbTargetPageId
          twitterRequestToken
          twitterRequestSecret
          twitterAccessToken
          twitterAccessSecret
          instagramUserId
        }
        firebaseTokens
      }
    }
  }
`;
export const onDeleteLike = /* GraphQL */ `
  subscription OnDeleteLike($userId: String) {
    onDeleteLike(userId: $userId) {
      userId
      targetId
      targetType
      createdAt
      updatedAt
      user {
        id
        name
        givenName
        familyName
        gender
        birthdate
        country
        sport
        profileImgS3Key
        coverImgS3Key
        introVideoS3Key
        introVideoThumbS3Key
        introVideoUrl
        mission
        instagramUrl
        facebookUrl
        youtubeUrl
        goals {
          id
          name
          level
          description
          videoUrl
          link
        }
        achievements {
          id
          name
          level
          description
          link
          videoUrl
          date
        }
        fundingGoals {
          id
          amount
          currency
          description
        }
        sponsors {
          id
          imgS3Key
        }
        emailValidated
        validationEmailSentAt
        potentialAthlete
        currency
        themeId
        role
        nickname
        stripePayoutsEnabled
        payoutCurrency
        stats {
          numUniqueViews
          numScouts
          paidByScouts
          numPosts
        }
        features {
          posts
        }
        stripeCustomerId
        ivsChannelArn
        createdAt
        updatedAt
        owner
        email
        stripeActionRequired
        stripeAccountId
        social {
          fbUserId
          fbAccessToken
          fbTargetPageId
          twitterRequestToken
          twitterRequestSecret
          twitterAccessToken
          twitterAccessSecret
          instagramUserId
        }
        firebaseTokens
      }
    }
  }
`;
export const onCreateMigrations = /* GraphQL */ `
  subscription OnCreateMigrations {
    onCreateMigrations {
      name
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMigrations = /* GraphQL */ `
  subscription OnUpdateMigrations {
    onUpdateMigrations {
      name
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteMigrations = /* GraphQL */ `
  subscription OnDeleteMigrations {
    onDeleteMigrations {
      name
      createdAt
      updatedAt
    }
  }
`;
