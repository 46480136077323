import React, { useContext, useState } from 'react';
import { makeRequired, makeValidate } from 'mui-rff';
import * as Yup from 'yup';
import { Form } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import PaperFormElem from '../../components/ui/PaperFormElem';
import PaperForm from '../../components/ui/PaperForm';
import SubmitButton from '../../components/ui/SubmitButton';
import PaperFormActions from '../../components/ui/PaperFormActions';
import { countryToFlag, removeNullAttributes } from '../../core/helpers/misc';
import { User } from '../../core/graphql/types';
import { updateUser } from '../../core/services/userSvc';
import { countries } from '../../core/helpers/selectOptions';
import FormAutocomplete from '../../components/ui/FormAutocomplete';
import { appStore } from '../../core/stores/app';

const useStyles = makeStyles((theme) => ({
  countryOption: {
    fontSize: 15,
    '& > span': {
      marginRight: theme.spacing(1),
      fontSize: 18,
    },
  },
  coverImageWrapper: {
    position: 'relative',
    height: '200px',
  },
}));

const formSchema = Yup.object().shape({
  country: Yup.string(),
});

const validate = makeValidate(formSchema);
const required = makeRequired(formSchema);

export interface AccountBillingFormData {
  country?: string;
}

interface Props {
  user?: User;
  showSport?: boolean;
  showCoverImage?: boolean;
  onUserUpdated?: (update: AccountBillingFormData) => void;
}

const AccountBilling: React.FC<Props> = ({ user, onUserUpdated }: Props) => {
  const classes = useStyles();
  const [, appDispatch] = useContext(appStore);
  const subscription = { pristine: true, invalid: true };
  const [submitting, setSubmitting] = useState(false);
  const initialValues = removeNullAttributes({
    country: user?.country,
  });
  const formId = 'athlete-basics-form';
  const handleSubmitClick = async (values: AccountBillingFormData) => {
    if (user) {
      setSubmitting(true);
      await updateUser(user.id, values, appDispatch);
      setSubmitting(false);

      if (onUserUpdated) {
        onUserUpdated(values);
      }
    }
  };

  return (
    <>
      <Form
        onSubmit={handleSubmitClick}
        initialValues={initialValues}
        subscription={subscription}
        validate={validate}
        key={subscription as any}
        render={({ form, handleSubmit, pristine, invalid }) => (
          <form id={formId} onSubmit={handleSubmit} noValidate={true} autoComplete="new-password">
            <PaperForm
              actions={
                <PaperFormActions>
                  <Grid container spacing={1} justifyContent="flex-end">
                    <Grid item>
                      <SubmitButton
                        disabled={invalid || submitting || pristine}
                        submitting={false}
                        color="default"
                        type="button" // to avoid the form being submitted
                        onClick={form.reset}
                      >
                        Cancel {pristine ? 'y' : 'n'}
                      </SubmitButton>
                    </Grid>
                    <Grid item>
                      <SubmitButton disabled={invalid || submitting || pristine} submitting={submitting}>
                        Save changes
                      </SubmitButton>
                    </Grid>
                  </Grid>
                </PaperFormActions>
              }
            >
              {/* Country */}
              <PaperFormElem title="Country">
                <FormAutocomplete
                  id="country"
                  name="country"
                  margin="none"
                  required={required.country}
                  disabled={Boolean(submitting)}
                  options={countries}
                  getOptionValue={(option: any) => option.value}
                  getOptionLabel={(option: any) => option.label}
                  renderOption={(option: any) => (
                    <div className={classes.countryOption}>
                      <span>{countryToFlag(String(option.value))}</span>
                      {option.label}
                    </div>
                  )}
                />
              </PaperFormElem>
            </PaperForm>
          </form>
        )}
      />
    </>
  );
};

export default AccountBilling;
