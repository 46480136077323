import React from 'react';
import { showErrorOnBlur, TextField } from 'mui-rff';
import { makeStyles } from '@material-ui/core/styles';

import globalStyles from '../../theme/globalStyles';
import { TEXT_FIELD_TYPE } from 'mui-rff/dist/TextField';

const useStyles = makeStyles((theme) => ({
  formTextField: { ...globalStyles(theme).formTextField },
}));

interface Props {
  id: string;
  name: string;
  type?: TEXT_FIELD_TYPE;
  required: boolean;
  margin?: 'none' | 'dense' | 'normal';
  InputProps?: any;
  [key: string]: any;
}
const FormTextField: React.FC<Props> = ({ id, name, required, margin = 'dense', InputProps = {}, ...props }: Props) => {
  const classes = useStyles();

  return (
    <TextField
      id={id}
      name={name}
      type={props.type ?? 'text'}
      variant="outlined"
      fullWidth
      required={required}
      margin={margin}
      size="medium"
      showError={showErrorOnBlur}
      InputProps={{ ...InputProps, classes: { input: classes.formTextField } }}
      {...props}
    />
  );
};

export default FormTextField;
