import React from 'react';
import Zoom from '@material-ui/core/Zoom';
import Typography from '@material-ui/core/Typography';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Tooltip from '@material-ui/core/Tooltip';

const InfoIcon: React.FC<{ text: string }> = ({ text }: { text: string }) => {
  return (
    <Tooltip title={<Typography variant="body2">{text}</Typography>} placement="top" TransitionComponent={Zoom}>
      <HelpOutlineIcon fontSize="small" />
    </Tooltip>
  );
};

export default InfoIcon;
