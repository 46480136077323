import React, { useEffect, useRef } from 'react';
import Hls from 'hls.js';
import Plyr from 'plyr';
import { Avatar, Box, makeStyles } from '@material-ui/core';
import VideoPlayer from './VideoPlayer';

interface Props {
  cover?: string;
  url: any;
  isHLS: boolean;
  isPreview?: boolean;
}

const AudioPlayer: React.FC<Props> = ({ cover, url, isHLS, isPreview = false }: Props) => {
  const audioPlayerRef = useRef<HTMLAudioElement | null>(null);

  const useStyles = makeStyles((theme) => ({
    root: {
      '& .plyr__controls, & .vjs-control-bar': {
        backgroundColor: `${isPreview ? '' : 'rgba(255,255,255,0.7) !important'}`,
        borderRadius: '20px !important',
      },
      '& .plyr__progress__buffer': {
        backgroundColor: `${theme.palette.primary.main} !important`,
      },
      '&  button:hover, button:focus, button:active, button:visited, button:focus-visible, button:target, button:focus-within':
        {
          backgroundColor: `${Hls.isSupported() ? theme.palette.primary.main : ''} !important`,
          color: 'white !important',
        },
      '& input': {
        color: `${Hls.isSupported() ? theme.palette.primary.main : ''} !important`,
      },
      '& .plyr__control[aria-expanded="true"], .plyr__control[aria-checked="true"]:before': {
        backgroundColor: `${theme.palette.primary.main} !important`,
      },
      '& .vjs-control-bar': {
        height: '40px',
        display: 'flex !important',
        visisblity: 'visible !important',
        opacity: '1 !important',
      },
      '& .vjs-play-control, .vjs-current-time, .vjs-mute-control, .vjs-remaining-time': {
        display: 'flex !important',
        alignItems: 'center !important',
        justifyItems: 'center !important',
      },
      '& .vjs-play-control': {
        marginLeft: '20px !important',
        marginTop: '4px !important',
      },
      '& .vjs-mute-control, .vjs-volume-control': {
        marginTop: '4px !important',
      },
      '&  .vjs-picture-in-picture-control, .vjs-fullscreen-control': {
        display: 'none',
      },
    },
  }));
  const classes = useStyles();
  useEffect(() => {
    const hls = new Hls();
    if (Hls.isSupported()) {
      if (audioPlayerRef && audioPlayerRef.current) {
        if (isHLS) {
          hls.loadSource(url);
          hls.attachMedia(audioPlayerRef.current);
        } else {
          audioPlayerRef.current.src = url;
        }
        new Plyr(audioPlayerRef.current);
        Plyr.setup(audioPlayerRef.current);
      }
    }
  }, [audioPlayerRef]);

  return (
    <Box className={classes.root} display="flex" alignItems="center" justifyItems="center" flexDirection="column">
      {cover && <Avatar />}
      <Box className="player__data__container">
        {Hls.isSupported() && <audio autoPlay preload="true" ref={audioPlayerRef} controls />}
        {!Hls.isSupported() && <VideoPlayer makeAudioPlayer={true} isVod={true} videoUrl={url} />}
      </Box>
    </Box>
  );
};

export default AudioPlayer;
