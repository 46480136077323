import React, { useState, useContext } from 'react';
import { Box } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import ChangeCurrencyDialog from './dialogs/ChangeCurrencyDialog';
import { appStore } from '../core/stores/app';

interface Props {
  useWhiteBg?: boolean;
}

const CurrencyConfigurator: React.FC<Props> = ({ useWhiteBg = true }: Props) => {
  const [appState] = useContext(appStore);
  const [currencyDialogOpen, setCurrencyDialogOpen] = useState(false);
  const handleClick = () => {
    setCurrencyDialogOpen(true);
  };
  const handleCurrencyChangeSubmit = async () => {
    setCurrencyDialogOpen(false);
  };
  const handleCurrencyChangeCancel = () => {
    setCurrencyDialogOpen(false);
  };

  return (
    <Paper
      variant="outlined"
      style={{
        cursor: 'pointer',
        backgroundColor: useWhiteBg ? 'white' : 'black',
        color: useWhiteBg ? 'black' : 'white',
        borderColor: useWhiteBg ? 'black' : 'white',
      }}
    >
      <Box p={1} onClick={handleClick}>
        {appState.userCurrencyConfig && (
          <Typography variant="body1" align="center">
            currency: {appState.userCurrencyConfig.currencyCode.toUpperCase()}
          </Typography>
        )}
      </Box>
      {/* Currency Dialog */}
      {currencyDialogOpen && appState.userCurrencyConfig && (
        <ChangeCurrencyDialog
          open={currencyDialogOpen}
          initCurrency={appState.userCurrencyConfig.currencyCode}
          onSubmitted={handleCurrencyChangeSubmit}
          onCancel={handleCurrencyChangeCancel}
        />
      )}
    </Paper>
  );
};

export default CurrencyConfigurator;
