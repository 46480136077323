import React, { useState, useReducer, useEffect, useContext } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { listBcastsByUserId } from '../../../graphql/queries';
import { useHistory } from 'react-router-dom';
import { getMeetingRoute, getBcastRoute } from '../../../core/helpers/route';
import { onCreateBcast, onUpdateBcast, onDeleteBcast } from '../../../graphql/subscriptions';
import { Bcast, BcastStatus } from '../../../API';
import { authStore } from '../../../core/stores/auth';
//import { useAuthenticator } from '@aws-amplify/ui-react';
import { updateRecord } from '../../../graphql/utils';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { Divider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { baseUrl } from '../../../config';
import { useMediaQuery } from '@material-ui/core';
import domHelpers from '../../../core/helpers/dom';
import Button from '@material-ui/core/Button';
import moment from 'moment-timezone';
import { deleteBcast } from '../../../graphql/mutations';
import ActionConfirmationDialog from '../../dialogs/ActionConfirmationDialog';

const CloseTooltip = withStyles({
  tooltipPlacementBottom: {
    margin: '8px 0',
  },
})(Tooltip);

const useStyles = makeStyles((theme) => ({
  rectangle: {
    width: '32px',
    height: '32px',
    margin: '2px 8px 9.5px 0',
    padding: '4px',
    borderRadius: '2px',
    backgroundColor: '#f2a742',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    width: '12px',
  },
  textAlignRight: {
    textAlign: 'right',
  },
  button: {
    color: '#fff',
    fontSize: theme.typography.pxToRem(16),
    '&::after': {
      animation: '$sheen 0.8s 0.3s 2 forwards;',
      animationTimingFunction: 'ease',
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      background: 'linear-gradient(to bottom, transparent, rgba(255,255,255,0.5) 50%, transparent)',
      transform: 'rotateZ(60deg) translate(-2em, 400%)',
    },
    overflowX: 'hidden',
    overflowY: 'hidden',
  },
}));

const BcastList: React.FC = () => {
  const theme = useTheme();
  const classes = useStyles();
  const isSmallScreen = domHelpers.isSmallScreen(useMediaQuery, theme);
  const history = useHistory();
  const [authState] = useContext(authStore);
  const [deleteBcastConfirmationDialogOpen, setDeleteBcastConfirmationDialogOpen] = useState(false);
  const [selectedBcastId, setSelectedBcastId] = useState('');

  const reducer = (records: Bcast[], action: any) => {
    switch (action.type) {
      case 'fetched':
        return action.records;
      case 'created':
        return [action.record, ...records];
      case 'updated':
        return records.map((record) => (record.id === action.record.id ? updateRecord(record, action.record) : record));
      case 'deleted':
        return records.filter((record) => record.id !== action.record.id);
      default:
        throw new Error();
    }
  };
  const [records, dispatch] = useReducer(reducer, []);
  const currentUserId = authState.user ? authState.user.userId : '';

  const executeMutation = async (mutation: any, id: string) => {
    const input = { id: id };
    await API.graphql(graphqlOperation(mutation, { input }));
  };

  useEffect(() => {
    // todo: error handling

    const fetchRecords = async () => {
      const result: any = await API.graphql(
        graphqlOperation(listBcastsByUserId, {
          userId: currentUserId,
          sortDirection: 'DESC',
        }),
      );
      const records = result.data.listBcastsByUserId.items;
      dispatch({ type: 'fetched', records: records });
    };
    fetchRecords();
    const createSubscription = (
      API.graphql(
        graphqlOperation(onCreateBcast, {
          filter: { userId: { eq: currentUserId } },
        }),
      ) as any
    ).subscribe({
      next: (data: any) => {
        const {
          value: {
            data: { onCreateBcast: record },
          },
        } = data;
        dispatch({ type: 'created', record: record });
      },
    });
    const updateSubscription = (
      API.graphql(
        graphqlOperation(onUpdateBcast, {
          filter: { userId: { eq: currentUserId } },
        }),
      ) as any
    ).subscribe({
      next: (data: any) => {
        const {
          value: {
            data: { onUpdateBcast: record },
          },
        } = data;
        dispatch({ type: 'updated', record: record });
      },
    });
    const deleteSubscription = (
      API.graphql(
        graphqlOperation(onDeleteBcast, {
          filter: { userId: { eq: currentUserId } },
        }),
      ) as any
    ).subscribe({
      next: (data: any) => {
        const {
          value: {
            data: { onDeleteBcast: record },
          },
        } = data;
        dispatch({ type: 'deleted', record: record });
      },
    });

    return () => {
      createSubscription?.unsubscribe();
      updateSubscription?.unsubscribe();
      deleteSubscription?.unsubscribe();
    };
  }, []);
  const handleEditClick = (id: string) => {
    history.push(getBcastRoute(id));
  };

  const handleDuplicateClick = (id: string) => {
    history.push(getBcastRoute(id) + '?duplicate');
  };

  const handleStartClick = (id: string) => {
    const url = getMeetingRoute(id);
    history.push(url);
  };
  const handleDeleteClick = (id: string) => {
    setSelectedBcastId(id);
    setDeleteBcastConfirmationDialogOpen(true);
  };
  const handleDeleteBcastConfirmation = async () => {
    setDeleteBcastConfirmationDialogOpen(false);
    if (selectedBcastId !== '') {
      const id = selectedBcastId;
      setSelectedBcastId('');
      executeMutation(deleteBcast, id);
    }
  };
  const handleDeleteBcastCancel = () => {
    setDeleteBcastConfirmationDialogOpen(false);
  };

  if (records.length === 0) {
    return (
      <>
        <Grid container justifyContent="space-between">
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Broadcasts
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box mt={1} mb={1}>
              <Divider></Divider>
            </Box>
          </Grid>
          <Grid item xs={12}>
            No scheduled broadcasts
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box mt={1} mb={1}>
            <Divider></Divider>
          </Box>
        </Grid>
      </>
    );
  } else {
    return (
      <>
        <Grid container justifyContent="space-between">
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Broadcasts
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box mt={1} mb={1}>
              <Divider></Divider>
            </Box>
          </Grid>
          {records.map((bcast: Bcast, index: number) => (
            <React.Fragment key={'bcastRow' + (bcast.id ? bcast.id : index.toString())}>
              <Grid item xs={12}>
                <Grid container justifyContent="space-between" spacing={1}>
                  {!isSmallScreen && (
                    <>
                      <Grid item md={1}>
                        <div className={classes.rectangle}>
                          <img src={baseUrl + '/static/images/bcast/camera-8@3x.png'} width={'16px'} />
                        </div>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <span style={{ fontWeight: 'bold' }}>{bcast.title}</span>
                        <br />
                        {new Date(bcast.time).toLocaleDateString()} {new Date(bcast.time).toLocaleTimeString()}
                      </Grid>
                      <Grid item xs={12} md={2}>
                        {bcast.status === BcastStatus.scheduled && (
                          <CloseTooltip placement="bottom" title="Start broadcast" aria-label="Start broadcast" arrow>
                            <Button
                              className={classes.button}
                              onClick={() => handleStartClick(bcast.id)}
                              variant="contained"
                              color="primary"
                              fullWidth
                            >
                              Start
                            </Button>
                          </CloseTooltip>
                        )}
                      </Grid>
                      <Grid item xs={12} md={2} className={classes.textAlignRight}>
                        <span style={{ fontWeight: 'bold' }}>{bcast.status}</span>
                      </Grid>
                    </>
                  )}
                  {isSmallScreen && (
                    <>
                      <Grid item xs={12} md={4}>
                        <span style={{ fontWeight: 'bold' }}>{bcast.title}</span>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Grid container>
                          <Grid item xs={4}>
                            Date:
                          </Grid>
                          <Grid item xs={8} className={classes.textAlignRight}>
                            {moment(bcast.time).format('DD-MM-YYYY')}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Grid container>
                          <Grid item xs={4}>
                            Time:
                          </Grid>
                          <Grid item xs={8} className={classes.textAlignRight}>
                            {moment(bcast.time).format('HH:mm')}-
                            {moment(bcast.time).add(bcast.duration).format('HH:mm')}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Grid container>
                          <Grid item xs={4}>
                            Status:
                          </Grid>
                          <Grid item xs={8} className={classes.textAlignRight}>
                            <span style={{ fontWeight: 'bold' }}>{bcast.status}</span>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={2}>
                        {bcast.status === BcastStatus.scheduled && (
                          <CloseTooltip placement="bottom" title="Start broadcast" aria-label="Start broadcast" arrow>
                            <Button
                              className={classes.button}
                              onClick={() => handleStartClick(bcast.id)}
                              variant="contained"
                              color="primary"
                              fullWidth
                            >
                              Start
                            </Button>
                          </CloseTooltip>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <Box mt={1} mb={1}>
                          <Divider></Divider>
                        </Box>
                      </Grid>
                    </>
                  )}

                  <Grid item xs={12} md={3} className={classes.textAlignRight}>
                    <CloseTooltip placement="bottom" title="Edit broadcast" aria-label="Edit broadcast" arrow>
                      <IconButton size="medium" onClick={() => handleEditClick(bcast.id)}>
                        <img className={classes.icon} src={baseUrl + '/static/images/bcast/edit-6@3x.png'} />
                      </IconButton>
                    </CloseTooltip>
                    <CloseTooltip placement="bottom" title="Duplicate broadcast" aria-label="Duplicate broadcast" arrow>
                      <IconButton size="medium" onClick={() => handleDuplicateClick(bcast.id)}>
                        <img className={classes.icon} src={baseUrl + '/static/images/bcast/copy-7@3x.png'} />
                      </IconButton>
                    </CloseTooltip>
                    <CloseTooltip placement="bottom" title="Delete broadcast" aria-label="Delete broadcast" arrow>
                      <IconButton size="medium" onClick={() => handleDeleteClick(bcast.id)}>
                        <img className={classes.icon} src={baseUrl + '/static/images/bcast/delete-5@3x.png'} />
                      </IconButton>
                    </CloseTooltip>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Box mt={1} mb={1}>
                  <Divider></Divider>
                </Box>
              </Grid>
            </React.Fragment>
          ))}
          {deleteBcastConfirmationDialogOpen && (
            <ActionConfirmationDialog
              open={deleteBcastConfirmationDialogOpen}
              title={''}
              description={'Are you sure you want to delete this broadcast?'}
              onYesClick={handleDeleteBcastConfirmation}
              onNoClick={handleDeleteBcastCancel}
              onClose={handleDeleteBcastCancel}
            />
          )}
        </Grid>
      </>
    );
  }
};

export default BcastList;
