import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import AppHeaderPlaceHolder from './AppHeaderPlaceHolder';
import PageTitle from './PageTitle';
import { PeakzTheme } from '../../theme/theme';
import globalStyles from '../../theme/globalStyles';

const useStyles = makeStyles((theme: PeakzTheme) => ({
  compactPageLayoutRoot: { ...globalStyles(theme).pageRoot },
  compactPageLayoutContent: { ...globalStyles(theme).pageContentCompact },
}));

interface Props {
  title?: string;
  titleChildren?: React.ReactNode;
  maxWidth?: 'sm' | 'md' | 'lg';
  children: React.ReactNode;
}

const CompactPageLayout: React.FC<Props> = ({ title, titleChildren, maxWidth = 'lg', children }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.compactPageLayoutRoot}>
      <AppHeaderPlaceHolder />
      <Container className={classes.compactPageLayoutContent} maxWidth={maxWidth}>
        {/* Page Title */}
        {(title || titleChildren) && <PageTitle text={title}>{titleChildren}</PageTitle>}

        {/* Content */}
        {children}
      </Container>
    </div>
  );
};

export default CompactPageLayout;
