import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { API, graphqlOperation } from 'aws-amplify';
import { getBcast } from '../graphql/queries';
import Timer from '../meeting/components/Timer';
import ReminderForm from '../meeting/ReminderForm';
import JoinForm from '../meeting/JoinForm';
import { MeetingSessionProvider } from '../meeting/providers/MeetingSessionProvider';
import { Bcast } from '../API';
import { MeetingProvider } from 'amazon-chime-sdk-component-library-react';
import SelectDevices from '../meeting/SelectDevices';
import MeetingView from '../meeting/MeetingView';
import { secondsToDateTime } from '../meeting/utils/ics';
import { DeviceProvider, DeviceConsumer } from '../meeting/providers/DeviceProvider';
import Config from '../meeting/utils/config';
import Metadata from '../core/misc/Metadata';
import urlHelpers from '../core/helpers/url';

// Page for accessing meetings by host, guests and moderators
// (bcaster accesses meeting through BroadcastPage)

type PageRoute = 'loading' | 'too-early' | 'join' | 'select-devices' | 'in-meeting';

const MeetingPage: React.FC = () => {
  const { id }: any = useParams();
  const [record, setRecord] = useState<Bcast>();

  const [pageRoute, setPageRoute] = useState<PageRoute>('loading');

  ////////////
  // todo: error handling

  const fetchRecord = async (id: string) => {
    const response: any = await API.graphql(graphqlOperation(getBcast, { id: id }));
    const record = response.data.getBcast;
    setRecord(record);
    const distance = secondsToDateTime(record.time);
    if (distance > Config.BCAST_REMINDER_INTERVAL) {
      setPageRoute('too-early');
      // auto-refresh page 30 minutes before the scheduled time
      const distance = secondsToDateTime(record.time);
      const interval = distance - Config.BCAST_REMINDER_INTERVAL;
      setTimeout(() => {
        fetchRecord(id);
      }, interval * 1000);
    } else {
      setPageRoute('join');
    }
  };

  useEffect(() => {
    fetchRecord(id);
  }, []);

  const onJoin = () => {
    setPageRoute('select-devices');
  };

  const onActivate = (active: boolean) => {
    if (active) {
      setPageRoute('in-meeting');
    } else {
      setPageRoute('join');
    }
  };

  const getTumbnailUrl = () =>
    record && record.thumbnailImageS3Key
      ? urlHelpers.getMediaUrlFromS3Key(record.thumbnailImageS3Key, 'img')
      : undefined;

  return (
    <>
      {id && record && (
        <>
          <Metadata
            title={`LIVE STREAM: ${record.title}`}
            description={record.description || undefined}
            thumbnailUrl={getTumbnailUrl()}
            whatsappThumbnailUrl={getTumbnailUrl() || undefined}
          />
          <MeetingProvider>
            <MeetingSessionProvider meetingId={id} bcastRecord={record} onJoin={onJoin} onActivate={onActivate}>
              <DeviceProvider>
                <DeviceConsumer>
                  {({ deviceType, displayOrientation }) => {
                    const showHeader = deviceType !== 'mobile' || displayOrientation === 'portrait';
                    const headerHeight = showHeader ? '4rem' : '0px';
                    return (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          position: 'fixed',
                          height: '100%',
                          width: '100%',
                          zIndex: 1, // milose, obrisi kad resis zasebnu rutu za meeting page!
                        }}
                      >
                        {showHeader && (
                          // todo: standard header
                          <div
                            style={{
                              padding: '1rem',
                              height: headerHeight,
                              backgroundColor: Config.BARS_COLOR,
                            }}
                          >
                            <h2 style={{ color: 'orange', margin: 0 }}>PEÄKZ</h2>
                          </div>
                        )}
                        {showHeader && record && <Timer time={record.time} />}
                        <div
                          style={{
                            height: `calc(100% - ${showHeader ? headerHeight : '0px'})`,
                            width: '100%',
                            padding: 0,
                            backgroundColor: Config.BACKGROUND_COLOR,
                            overflowY: 'auto',
                            flexGrow: 1,
                            position: 'relative',
                          }}
                        >
                          {pageRoute === 'too-early' && <ReminderForm />}
                          {pageRoute === 'join' && <JoinForm />}
                          {pageRoute === 'select-devices' && <SelectDevices />}
                          {pageRoute === 'in-meeting' && <MeetingView />}
                        </div>
                      </div>
                    );
                  }}
                </DeviceConsumer>
              </DeviceProvider>
            </MeetingSessionProvider>
          </MeetingProvider>
        </>
      )}
    </>
  );
};

export default MeetingPage;
