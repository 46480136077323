import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Chip from '@material-ui/core/Chip';
import clsx from 'clsx';
import green from '@material-ui/core/colors/green';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import LaunchIcon from '@material-ui/icons/Launch';

import { BalanceTransaction, PaymentsAccount, Payout, User } from '../core/graphql/types';
import { fetchUserById } from '../core/services/userSvc';
import { authStore } from '../core/stores/auth';
import { appStore } from '../core/stores/app';
import { formatAmount } from '../core/helpers/currency';
import paymentsSvc from '../core/services/stripeSvc';
import PaymentsSetup from './PaymentsSetup';
import { formatDateTimeShort } from '../core/helpers/misc';
import { AntTab, AntTabs } from '../components/ui/AntTabs';
import SpinnerBox from '../components/ui/SpinnerBox';
import AthletePaymentsAccountSummary from './AthletePaymentsAccountSummary';
import AthletePaymentsAccountDetails from './AthletePaymentsAccountDetails';
import AthletePaymentsAccountRemainingDetails from './AthletePaymentsAccountRemainingDetails';
import SubmitButton from '../components/ui/SubmitButton';

const successColor = green['A100'];
const notFoundErrorMsg = 'It seems you are trying to access a private page';
const useStyles = makeStyles(() => ({
  statusChip: {
    height: '25px',
    marginTop: '10px',
    marginBottom: '10px',
  },
  successChip: {
    backgroundColor: successColor,
  },
  alertMessage: {
    width: '100%',
  },
}));

interface RowProps {
  title: string;
  icon?: React.ReactNode;
  children: React.ReactNode;
  showDividers?: boolean;
  loading?: boolean;
}
const Row: React.FC<RowProps> = ({ title, icon, children, showDividers = true, loading }: RowProps) => {
  return (
    <Grid item xs={12}>
      <Grid container alignItems="center" spacing={1}>
        {icon && <Grid item>{icon}</Grid>}
        <Grid item>
          <Typography variant="h6">
            {title}
            {loading && (
              <>
                <span> </span>
                <CircularProgress size={18} />
              </>
            )}
          </Typography>
        </Grid>
      </Grid>
      <Box mt={showDividers ? 1 : 0}>
        {showDividers && <Divider />}
        <Box mt={1} mb={1}>
          {children}
        </Box>
        {showDividers && <Divider />}
      </Box>
    </Grid>
  );
};

const AthletePaymentsAccount: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const [authState] = useContext(authStore);
  const [, appDispatch] = useContext(appStore);
  const [user, setUser] = useState<User | undefined>();
  const [activitySelectedTab, setActivitySelectedTab] = useState(0);
  const [availableBalance, setAvailableBalance] = useState<{ amount: number; currency: string }[] | null>(null);
  const [pendingBalance, setPendingBalance] = useState<{ amount: number; currency: string }[] | null>(null);
  const [paymentsAccount, setPaymentsAccount] = useState<PaymentsAccount | null>(null);
  const [payments, setPayments] = useState<BalanceTransaction[] | null>(null);
  const [payouts, setPayouts] = useState<Payout[] | null>(null);
  const [loadingActivity, setLoadingActivity] = useState(false);
  const loadUser = async (userId: string) => {
    const user = await fetchUserById(userId, 'full');

    if (user) {
      setUser(user);
    } else {
      history.push({
        pathname: '/notfound',
        state: {
          message: notFoundErrorMsg,
        },
      });
    }
  };
  const loadActivity = (tabIndex: number) => {
    if (tabIndex === 0) {
      setLoadingActivity(true);
      paymentsSvc.getMyBalanceTransactions('payment').then((balanceTransactionsList) => {
        setLoadingActivity(false);
        setPayments(balanceTransactionsList.data);
      });
    } else {
      setLoadingActivity(true);
      paymentsSvc.getMyPayouts().then((payoutsList) => {
        setLoadingActivity(false);
        setPayouts(payoutsList.data);
      });
    }
  };
  const handleUserUpdated = (updateObj: { stripeAccountId?: string }) => {
    setUser((user) => {
      if (user) {
        return { ...user, ...updateObj };
      }
    });
  };
  const handleActivityTabChange = (event: React.ChangeEvent<any>, newValue: number) => {
    setActivitySelectedTab(newValue);
    loadActivity(newValue);
  };
  const handleSettingsClick = async () => {
    const currentPageUrl = `${window.location.origin}${window.location.pathname}`;
    const refreshUrl = currentPageUrl;
    const returnUrl = currentPageUrl;

    appDispatch({ type: 'showBackdrop' });

    window.location.href = await paymentsSvc.getPaymentsAccountLink(refreshUrl, returnUrl);
  };

  useEffect(() => {
    try {
      (async function () {
        if (authState.user) {
          loadUser(authState.user.userId);
        }
      })();
    } catch (e) {
      history.push({
        pathname: '/notfound',
        state: {
          message: notFoundErrorMsg,
        },
      });
    }
  }, [authState.user]);

  useEffect(() => {
    if (user) {
      if (user.stripeAccountId) {
        paymentsSvc.getMyPaymentsAccount().then((account) => {
          setPaymentsAccount(account);
        });

        if (user.stripePayoutsEnabled) {
          paymentsSvc.getMyBalance().then((balance) => {
            setAvailableBalance(balance.available);
            setPendingBalance(balance.pending);
          });

          loadActivity(activitySelectedTab);
        }
      }
    }
  }, [user]);

  return (
    <div>
      {/* Account in progress or complete */}
      {user && user.stripeAccountId && (
        <Grid container spacing={4}>
          {!paymentsAccount && (
            <Grid item xs={12}>
              <SpinnerBox />
            </Grid>
          )}
          {/* Row: Account Summary */}
          {paymentsAccount && (
            <Grid item xs={12}>
              <AthletePaymentsAccountSummary paymentsAccount={paymentsAccount} />
            </Grid>
          )}

          {/* Row: Balance */}
          {user.stripePayoutsEnabled && (availableBalance || pendingBalance) && (
            <Row title="Balance">
              <Grid container>
                {/* - Available balance (Available to pay out)
                        Funds that are available to be transferred or paid out, whether automatically by Stripe or explicitly via the [Transfers API](https://stripe.com/docs/api#transfers) or [Payouts API](https://stripe.com/docs/api#payouts). The available balance for each currency and payment type can be found in the `source_types` property.
                  */}
                {availableBalance && (
                  <Grid item xs={12} sm={3}>
                    <Typography variant="body2" color="textSecondary">
                      Available to pay out
                    </Typography>
                    {availableBalance.map(({ amount, currency }, index) => (
                      <Typography key={index} variant="body1">
                        {formatAmount(amount, currency)}
                      </Typography>
                    ))}
                  </Grid>
                )}

                {/* - Pending balance (Available soon)
                        Funds that are not yet available in the balance, due to the 7-day rolling pay cycle. The pending balance for each currency, and for each payment type, can be found in the `source_types` property.
                  */}
                {pendingBalance && (
                  <Grid item xs={12} sm={3}>
                    <Typography variant="body2" color="textSecondary">
                      Available soon
                    </Typography>
                    {pendingBalance.map(({ amount, currency }, index) => (
                      <Typography key={index} variant="body1">
                        {formatAmount(amount, currency)}
                      </Typography>
                    ))}
                  </Grid>
                )}
              </Grid>
            </Row>
          )}

          {/* Row: Details */}
          {paymentsAccount && (
            <Row title="Details">
              <AthletePaymentsAccountDetails paymentsAccount={paymentsAccount} />
            </Row>
          )}

          {/* Row: Activity */}
          {user.stripePayoutsEnabled && (payments || payouts) && (
            <Row title="Last activity" showDividers={false} loading={loadingActivity}>
              <AntTabs
                value={activitySelectedTab}
                variant="scrollable"
                scrollButtons="auto"
                onChange={handleActivityTabChange}
                aria-label="ant example"
              >
                {['Payments', 'Payouts'].map((t, i) => (
                  <AntTab key={i} label={t} />
                ))}
              </AntTabs>
              <Box mt={2} width={1}>
                {activitySelectedTab === 0 && (
                  <>
                    {!payments && <SpinnerBox />}
                    {payments && payments.length === 0 && <Typography variant="body2">No payments yet</Typography>}
                    {payments && payments.length > 0 && (
                      <TableContainer>
                        <Table padding="none">
                          <TableHead>
                            <TableRow>
                              <TableCell align="left">Amount</TableCell>
                              <TableCell align="left">Status</TableCell>
                              <TableCell align="right">Date</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {payments.map((trx) => (
                              <TableRow key={trx.id}>
                                <TableCell component="th" scope="row">
                                  {formatAmount(trx.amount, trx.currency)}
                                </TableCell>
                                <TableCell align="left">
                                  <Chip
                                    className={clsx({
                                      [classes.statusChip]: true,
                                      [classes.successChip]: trx.status === 'available',
                                    })}
                                    label={trx.status.toUpperCase()}
                                  />
                                </TableCell>
                                <TableCell align="right">{formatDateTimeShort(new Date(trx.created * 1000))}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  </>
                )}
                {activitySelectedTab === 1 && (
                  <>
                    {!payouts && <SpinnerBox />}
                    {payouts && payouts.length === 0 && <Typography variant="body2">No payouts yet</Typography>}
                    {payouts && payouts.length > 0 && (
                      <TableContainer>
                        <Table padding="none">
                          <TableHead>
                            <TableRow>
                              <TableCell>Amount</TableCell>
                              <TableCell align="left">Status</TableCell>
                              <TableCell align="left">Description</TableCell>
                              <TableCell align="right">Date</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {payouts.map((trx) => (
                              <TableRow key={trx.id}>
                                <TableCell component="th" scope="row">
                                  {formatAmount(trx.amount, trx.currency)}
                                </TableCell>
                                <TableCell align="left">
                                  <Chip
                                    className={clsx({
                                      [classes.statusChip]: true,
                                      [classes.successChip]: trx.status === 'paid',
                                    })}
                                    label={trx.status.toUpperCase()}
                                  />
                                </TableCell>
                                <TableCell align="left">{trx.description}</TableCell>
                                <TableCell align="right">{formatDateTimeShort(new Date(trx.created * 1000))}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  </>
                )}
              </Box>
            </Row>
          )}
          {paymentsAccount && !paymentsAccount.payouts_enabled && (
            <Grid item xs={12}>
              <Alert
                severity="warning"
                classes={{
                  message: classes.alertMessage,
                }}
              >
                <AlertTitle>Your payments account is not complete yet</AlertTitle>
                {paymentsAccount && (
                  <Box mt={2}>
                    <AthletePaymentsAccountRemainingDetails paymentsAccount={paymentsAccount} type="currently_due" />
                    <AthletePaymentsAccountRemainingDetails
                      paymentsAccount={paymentsAccount}
                      type="pending_verification"
                    />
                  </Box>
                )}
                <Box width={1} display={'flex'} justifyContent="center" mt={2}>
                  <SubmitButton
                    submitting={false}
                    color="primary"
                    variant="contained"
                    size="small"
                    fullWidth
                    endIcon={<LaunchIcon />}
                    onClick={handleSettingsClick}
                  >
                    CONTINUE
                  </SubmitButton>
                </Box>
              </Alert>
            </Grid>
          )}
        </Grid>
      )}
      {/* Configuration */}
      {user && !user.stripeAccountId && <PaymentsSetup user={user} onUserUpdated={handleUserUpdated} />}
    </div>
  );
};

export default AthletePaymentsAccount;
