import { Box, Button, Container, Grid, Slider, TextField, useMediaQuery, useTheme, Zoom } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import React, { ChangeEvent, useEffect, useState } from 'react';
import domHelpers from '../../core/helpers/dom';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import videojs, { VideoJsPlayer } from 'video.js';

interface Props {
  videoRef: any;
  dropzoneReset: boolean;
  onChange: (start: string, end: string) => any;
}

interface VideoListeners {
  videoLoadedDataListenerFunction: any | undefined;
  videoPauseFunction: any | undefined;
}

let previewTimer: any = null;
const videoListeners: VideoListeners = {
  videoLoadedDataListenerFunction: undefined,
  videoPauseFunction: undefined,
};
const defaultSliderValues = [0, 100];

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(2),
    flexDirection: 'column',
    justifyItems: 'center',
    '& .MuiInputBase-root': {
      maxWidth: '100px',
      height: '30px',
      margin: theme.spacing(1),
      marginTop: 0,
    },
    '& p': {
      margin: 0,
    },
  },
  slideContainer: {
    width: '90%',
    position: 'absolute',
  },
  thumbnailsContainer: {
    width: '90%',
    height: '54px',
    overflowX: 'hidden',
    overflowY: 'hidden',
    columnCount: 8,
    columnGap: 0,
    [theme.breakpoints.down('sm')]: {
      columnCount: 5,
    },
    [theme.breakpoints.down('xs')]: {
      columnCount: 3,
    },
    '& div': {
      display: 'flex',
      justifyContent: 'center',
      height: '54px',
      backgroundColor: 'black',
    },
  },
  thumbnailsOverlay: {
    width: '90%',
    height: '55px',
    boxShadow: 'inset 0 0 50px #000000',
  },
}));

const PreviewCropper: React.FC<Props> = ({ videoRef, dropzoneReset, onChange }: Props) => {
  const [start, setStart] = useState<string>('00:00:00');
  const [end, setEnd] = useState<string>('00:15:00');
  // Keep the reference in the state to make it accessible
  const [originalVideo, setOriginalVideo] = useState<VideoJsPlayer | null>(null);
  const [previewPlaying, setPreviewPlaying] = useState(false);
  const [sliderValues, setSliderValues] = useState({
    start: 0,
    end: 15,
  });
  const classes = useStyles();
  const isExtraSmallScreen = domHelpers.isXtraSmallScreen(useMediaQuery, useTheme());

  const removeVideoListeners = (video: VideoJsPlayer | null) => {
    if (video) {
      video.off('loadeddata', videoListeners.videoLoadedDataListenerFunction);
      video.off('pause', videoListeners.videoPauseFunction);
    }
  };

  const getFormattedVideoTime = (value: number) => {
    return moment.utc(value * 1000).format('HH:mm:ss:SS');
  };

  const replaceSliderValues = (start: number, end: number) => {
    const newSliderValues = {
      start: start,
      end: end,
    };
    setStart(getFormattedVideoTime(newSliderValues.start).substring(0, 8));
    setEnd(getFormattedVideoTime(newSliderValues.end).substring(0, 8));
    setSliderValues(newSliderValues);
  };

  const handlePlayPreviewClick = () => {
    if (originalVideo) {
      if (!previewPlaying) {
        originalVideo.currentTime(sliderValues.start);
        originalVideo.play();
        setPreviewPlaying(true);
        previewTimer = setTimeout(() => {
          if (previewTimer !== null) {
            originalVideo.pause();
          }
        }, 16 * 1000);
        return;
      }
      originalVideo.pause();
      setPreviewPlaying(false);
    }
  };

  const reset = () => {
    if (previewTimer) {
      clearTimeout(previewTimer);
      previewTimer = null;
    }

    setPreviewPlaying(false);
    removeVideoListeners(originalVideo);
  };

  const handleSliderChange = (_: ChangeEvent<any>, value: number | number[]) => {
    const limits: number[] = value as number[];
    const min: number = limits[0];
    if (originalVideo) {
      originalVideo.pause();
      if (sliderValues.start !== min) {
        originalVideo.currentTime(min);
      }
      if (min + 15 > Math.round(originalVideo.duration())) {
        return;
      }
      replaceSliderValues(min, min + 15);
    }
  };

  const onVideoLoaded = (video: VideoJsPlayer | null) => {
    if (video) {
      if (originalVideo && video.duration() !== originalVideo.duration()) {
        replaceSliderValues(0, 15);
      }
      setOriginalVideo(video);
    }
  };

  useEffect(() => {
    if (videoRef.current) {
      let video: VideoJsPlayer | null = null;
      video = videojs(videoRef.current);

      video.autoplay(true);
      video.isFullscreen(false);

      videoListeners.videoLoadedDataListenerFunction = onVideoLoaded.bind(null, video);
      video.on('loadeddata', videoListeners.videoLoadedDataListenerFunction);

      videoListeners.videoPauseFunction = () => {
        setPreviewPlaying(false);
        if (previewTimer) {
          clearTimeout(previewTimer);
          previewTimer = null;
        }
      };
      video.on('pause', videoListeners.videoPauseFunction);
    }
  }, [videoRef]);

  useEffect(() => {
    if (dropzoneReset) {
      onChange(
        getFormattedVideoTime(sliderValues.start),
        getFormattedVideoTime(sliderValues.end == 0 ? sliderValues.start + 15 : sliderValues.end),
      );
      reset();
    }
  }, [dropzoneReset]);

  return (
    <Zoom in={!dropzoneReset || (originalVideo && originalVideo.duration() < 30) || false} timeout={500}>
      <Box
        style={
          dropzoneReset || (originalVideo && originalVideo.duration() < 30) ? { display: 'none' } : { display: 'flex' }
        }
        className={classes.root}
      >
        <Box display="flex" justifyContent="center" m={2}>
          <Container disableGutters={isExtraSmallScreen} maxWidth="xs">
            <Grid container>
              <Grid style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} xs={6} item>
                <p>Start</p>
                <TextField value={start} disabled={true} variant="outlined" />
              </Grid>
              <Grid style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} xs={6} item>
                <p>End</p>
                <TextField value={end} disabled={true} variant="outlined" />
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box position="relative" width={1} display="flex" justifyContent="center">
          {originalVideo && (
            <div className={classes.slideContainer}>
              <Slider
                max={originalVideo.duration()}
                value={[sliderValues.start, sliderValues.end]}
                onChange={handleSliderChange}
                defaultValue={defaultSliderValues}
              />
            </div>
          )}
        </Box>
        <Box width={1} display="flex" justifyContent="center" mt={5}>
          <Button
            onClick={handlePlayPreviewClick}
            disabled={!originalVideo}
            variant="contained"
            color="primary"
            startIcon={<PlayCircleFilledIcon />}
          >
            {`${previewPlaying ? 'Stop' : 'Play'} the preview`}
          </Button>
        </Box>
      </Box>
    </Zoom>
  );
};

export default PreviewCropper;
