/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const upgradeToAthlete = /* GraphQL */ `
  mutation UpgradeToAthlete {
    upgradeToAthlete {
      id
      name
      givenName
      familyName
      gender
      birthdate
      country
      sport
      profileImgS3Key
      coverImgS3Key
      introVideoS3Key
      introVideoThumbS3Key
      introVideoUrl
      mission
      instagramUrl
      facebookUrl
      youtubeUrl
      goals {
        id
        name
        level
        description
        videoUrl
        link
      }
      achievements {
        id
        name
        level
        description
        link
        videoUrl
        date
      }
      fundingGoals {
        id
        amount
        currency
        description
      }
      sponsors {
        id
        imgS3Key
      }
      emailValidated
      validationEmailSentAt
      potentialAthlete
      currency
      themeId
      role
      nickname
      stripePayoutsEnabled
      payoutCurrency
      stats {
        numUniqueViews
        numScouts
        paidByScouts
        numPosts
      }
      features {
        posts
      }
      stripeCustomerId
      ivsChannelArn
      createdAt
      updatedAt
      owner
      email
      stripeActionRequired
      stripeAccountId
      social {
        fbUserId
        fbAccessToken
        fbTargetPageId
        twitterRequestToken
        twitterRequestSecret
        twitterAccessToken
        twitterAccessSecret
        instagramUserId
      }
      firebaseTokens
    }
  }
`;
export const updateUserNickname = /* GraphQL */ `
  mutation UpdateUserNickname($nickname: String!) {
    updateUserNickname(nickname: $nickname)
  }
`;
export const sendEmailValidationEmail = /* GraphQL */ `
  mutation SendEmailValidationEmail {
    sendEmailValidationEmail
  }
`;
export const validateEmail = /* GraphQL */ `
  mutation ValidateEmail($token: String!) {
    validateEmail(token: $token) {
      value
      error
    }
  }
`;
export const createPaymentsAccount = /* GraphQL */ `
  mutation CreatePaymentsAccount {
    createPaymentsAccount
  }
`;
export const attachPaymentMethod = /* GraphQL */ `
  mutation AttachPaymentMethod($paymentMethodId: String!) {
    attachPaymentMethod(paymentMethodId: $paymentMethodId) {
      id
      type
      billing_details {
        address {
          city
          country
          line1
          line2
          postal_code
          state
        }
        email
        name
        phone
      }
      card {
        brand
        country
        exp_month
        exp_year
        last4
      }
      created
      customer
      is_default
    }
  }
`;
export const detachPaymentMethod = /* GraphQL */ `
  mutation DetachPaymentMethod($paymentMethodId: String!) {
    detachPaymentMethod(paymentMethodId: $paymentMethodId) {
      id
      type
      billing_details {
        address {
          city
          country
          line1
          line2
          postal_code
          state
        }
        email
        name
        phone
      }
      card {
        brand
        country
        exp_month
        exp_year
        last4
      }
      created
      customer
      is_default
    }
  }
`;
export const subscribeToAthlete = /* GraphQL */ `
  mutation SubscribeToAthlete(
    $athleteId: String!
    $tierId: String!
    $currency: String!
    $taxRateId: String
  ) {
    subscribeToAthlete(
      athleteId: $athleteId
      tierId: $tierId
      currency: $currency
      taxRateId: $taxRateId
    )
  }
`;
export const cancelSubscription = /* GraphQL */ `
  mutation CancelSubscription($athleteId: String!) {
    cancelSubscription(athleteId: $athleteId)
  }
`;
export const createTier = /* GraphQL */ `
  mutation CreateTier($name: String!, $currency: String!, $unitAmount: Int!) {
    createTier(name: $name, currency: $currency, unitAmount: $unitAmount) {
      id
      userId
      name
      currency
      unitAmount
      discountType
      discount
      discountMonths
      couponId
      createdAt
      updatedAt
    }
  }
`;
export const adminReviewPost = /* GraphQL */ `
  mutation AdminReviewPost(
    $postId: String!
    $reviewStatus: String!
    $reason: String
  ) {
    adminReviewPost(
      postId: $postId
      reviewStatus: $reviewStatus
      reason: $reason
    )
  }
`;
export const adminPushNotification = /* GraphQL */ `
  mutation AdminPushNotification(
    $to: String!
    $ids: [String]
    $title: String!
    $body: String!
  ) {
    adminPushNotification(to: $to, ids: $ids, title: $title, body: $body)
  }
`;
export const callTwitter = /* GraphQL */ `
  mutation CallTwitter(
    $action: String!
    $previewUrl: String
    $token: String
    $secret: String
    $oauthVerifier: String
    $athleteProfileUrl: String
    $cbUrl: String
  ) {
    callTwitter(
      action: $action
      previewUrl: $previewUrl
      token: $token
      secret: $secret
      oauthVerifier: $oauthVerifier
      athleteProfileUrl: $athleteProfileUrl
      cbUrl: $cbUrl
    )
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $parentId: String!
    $parentType: String!
    $message: String!
    $recaptchaKey: String!
  ) {
    createComment(
      parentId: $parentId
      parentType: $parentType
      message: $message
      recaptchaKey: $recaptchaKey
    ) {
      id
      authorId
      parentId
      parentType
      lastComment {
        id
        authorId
        parentId
        parentType
        lastComment {
          id
          authorId
          parentId
          parentType
          message
          likes
          comments
          liked
          createdAt
          updatedAt
        }
        message
        likes
        comments
        liked
        createdAt
        updatedAt
        author {
          id
          name
          givenName
          familyName
          gender
          birthdate
          country
          sport
          profileImgS3Key
          coverImgS3Key
          introVideoS3Key
          introVideoThumbS3Key
          introVideoUrl
          mission
          instagramUrl
          facebookUrl
          youtubeUrl
          emailValidated
          validationEmailSentAt
          potentialAthlete
          currency
          themeId
          role
          nickname
          stripePayoutsEnabled
          payoutCurrency
          stripeCustomerId
          ivsChannelArn
          createdAt
          updatedAt
          owner
          email
          stripeActionRequired
          stripeAccountId
          firebaseTokens
        }
      }
      message
      likes
      comments
      liked
      createdAt
      updatedAt
      author {
        id
        name
        givenName
        familyName
        gender
        birthdate
        country
        sport
        profileImgS3Key
        coverImgS3Key
        introVideoS3Key
        introVideoThumbS3Key
        introVideoUrl
        mission
        instagramUrl
        facebookUrl
        youtubeUrl
        goals {
          id
          name
          level
          description
          videoUrl
          link
        }
        achievements {
          id
          name
          level
          description
          link
          videoUrl
          date
        }
        fundingGoals {
          id
          amount
          currency
          description
        }
        sponsors {
          id
          imgS3Key
        }
        emailValidated
        validationEmailSentAt
        potentialAthlete
        currency
        themeId
        role
        nickname
        stripePayoutsEnabled
        payoutCurrency
        stats {
          numUniqueViews
          numScouts
          paidByScouts
          numPosts
        }
        features {
          posts
        }
        stripeCustomerId
        ivsChannelArn
        createdAt
        updatedAt
        owner
        email
        stripeActionRequired
        stripeAccountId
        social {
          fbUserId
          fbAccessToken
          fbTargetPageId
          twitterRequestToken
          twitterRequestSecret
          twitterAccessToken
          twitterAccessSecret
          instagramUserId
        }
        firebaseTokens
      }
    }
  }
`;
export const createBcast = /* GraphQL */ `
  mutation CreateBcast($input: CreateBcastInput!) {
    createBcast(input: $input) {
      id
      userId
      title
      description
      tierId
      needsSubscription
      thumbnailImageS3Key
      previewImageS3Key
      time
      duration
      guests {
        id
        name
      }
      allowQuestions
      status
      bcastUrl
      recordingS3Key
      prepareArgs
      createdAt
      updatedAt
    }
  }
`;
export const updateBcast = /* GraphQL */ `
  mutation UpdateBcast($input: UpdateBcastInput!) {
    updateBcast(input: $input) {
      id
      userId
      title
      description
      thumbnailImageS3Key
      previewImageS3Key
      time
      duration
      guests
      allowQuestions
      status
      bcastUrl
      recordingS3Key
    }
  }
`;
export const deleteBcast = /* GraphQL */ `
  mutation DeleteBcast($input: DeleteBcastInput!) {
    deleteBcast(input: $input) {
      id
      userId
      title
      description
      tierId
      needsSubscription
      thumbnailImageS3Key
      previewImageS3Key
      time
      duration
      guests {
        id
        name
      }
      allowQuestions
      status
      bcastUrl
      recordingS3Key
      prepareArgs
      createdAt
      updatedAt
    }
  }
`;
export const joinMeeting = /* GraphQL */ `
  mutation JoinMeeting($input: JoinMeetingInput!) {
    joinMeeting(input: $input) {
      meetingResponse
      attendeeResponse
    }
  }
`;
export const leaveMeeting = /* GraphQL */ `
  mutation LeaveMeeting($input: MeetingCmdInput!) {
    leaveMeeting(input: $input) {
      status
    }
  }
`;
export const endMeeting = /* GraphQL */ `
  mutation EndMeeting($input: MeetingCmdInput!) {
    endMeeting(input: $input) {
      status
    }
  }
`;
export const prepareBcast = /* GraphQL */ `
  mutation PrepareBcast($input: MeetingCmdInput!) {
    prepareBcast(input: $input) {
      status
    }
  }
`;
export const startBcast = /* GraphQL */ `
  mutation StartBcast($input: MeetingCmdInput!) {
    startBcast(input: $input) {
      status
    }
  }
`;
export const stopBcast = /* GraphQL */ `
  mutation StopBcast($input: MeetingCmdInput!) {
    stopBcast(input: $input) {
      status
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      name
      givenName
      familyName
      gender
      birthdate
      country
      sport
      profileImgS3Key
      coverImgS3Key
      introVideoS3Key
      introVideoThumbS3Key
      introVideoUrl
      mission
      instagramUrl
      facebookUrl
      youtubeUrl
      goals {
        id
        name
        level
        description
        videoUrl
        link
      }
      achievements {
        id
        name
        level
        description
        link
        videoUrl
        date
      }
      fundingGoals {
        id
        amount
        currency
        description
      }
      sponsors {
        id
        imgS3Key
      }
      emailValidated
      validationEmailSentAt
      potentialAthlete
      currency
      themeId
      role
      nickname
      stripePayoutsEnabled
      payoutCurrency
      stats {
        numUniqueViews
        numScouts
        paidByScouts
        numPosts
      }
      features {
        posts
      }
      stripeCustomerId
      ivsChannelArn
      createdAt
      updatedAt
      owner
      email
      stripeActionRequired
      stripeAccountId
      social {
        fbUserId
        fbAccessToken
        fbTargetPageId
        twitterRequestToken
        twitterRequestSecret
        twitterAccessToken
        twitterAccessSecret
        instagramUserId
      }
      firebaseTokens
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      name
      givenName
      familyName
      gender
      birthdate
      country
      sport
      profileImgS3Key
      coverImgS3Key
      introVideoS3Key
      introVideoThumbS3Key
      introVideoUrl
      mission
      instagramUrl
      facebookUrl
      youtubeUrl
      goals {
        id
        name
        level
        description
        videoUrl
        link
      }
      achievements {
        id
        name
        level
        description
        link
        videoUrl
        date
      }
      fundingGoals {
        id
        amount
        currency
        description
      }
      sponsors {
        id
        imgS3Key
      }
      emailValidated
      validationEmailSentAt
      potentialAthlete
      currency
      themeId
      role
      nickname
      stripePayoutsEnabled
      payoutCurrency
      stats {
        numUniqueViews
        numScouts
        paidByScouts
        numPosts
      }
      features {
        posts
      }
      stripeCustomerId
      ivsChannelArn
      createdAt
      updatedAt
      owner
      email
      stripeActionRequired
      stripeAccountId
      social {
        fbUserId
        fbAccessToken
        fbTargetPageId
        twitterRequestToken
        twitterRequestSecret
        twitterAccessToken
        twitterAccessSecret
        instagramUserId
      }
      firebaseTokens
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      name
      givenName
      familyName
      gender
      birthdate
      country
      sport
      profileImgS3Key
      coverImgS3Key
      introVideoS3Key
      introVideoThumbS3Key
      introVideoUrl
      mission
      instagramUrl
      facebookUrl
      youtubeUrl
      goals {
        id
        name
        level
        description
        videoUrl
        link
      }
      achievements {
        id
        name
        level
        description
        link
        videoUrl
        date
      }
      fundingGoals {
        id
        amount
        currency
        description
      }
      sponsors {
        id
        imgS3Key
      }
      emailValidated
      validationEmailSentAt
      potentialAthlete
      currency
      themeId
      role
      nickname
      stripePayoutsEnabled
      payoutCurrency
      stats {
        numUniqueViews
        numScouts
        paidByScouts
        numPosts
      }
      features {
        posts
      }
      stripeCustomerId
      ivsChannelArn
      createdAt
      updatedAt
      owner
      email
      stripeActionRequired
      stripeAccountId
      social {
        fbUserId
        fbAccessToken
        fbTargetPageId
        twitterRequestToken
        twitterRequestSecret
        twitterAccessToken
        twitterAccessSecret
        instagramUserId
      }
      firebaseTokens
    }
  }
`;
export const createPost = /* GraphQL */ `
  mutation CreatePost(
    $input: CreatePostInput!
    $condition: ModelPostConditionInput
  ) {
    createPost(input: $input, condition: $condition) {
      id
      authorId
      name
      description
      needsSubscription
      type
      tierId
      vodId
      vodStatus
      vodMetadata {
        durationInMs
        orgWidth
        orgHeight
        hasPreview
      }
      bcastMetadata {
        previewImageS3Key
        time
        duration
        guests {
          id
          name
        }
        allowQuestions
        status
        bcastUrl
        recordingS3Key
      }
      thumbS3Key
      likes
      comments
      liked
      reviewStatus
      feedId
      createdAt
      updatedAt
      author {
        id
        name
        givenName
        familyName
        gender
        birthdate
        country
        sport
        profileImgS3Key
        coverImgS3Key
        introVideoS3Key
        introVideoThumbS3Key
        introVideoUrl
        mission
        instagramUrl
        facebookUrl
        youtubeUrl
        goals {
          id
          name
          level
          description
          videoUrl
          link
        }
        achievements {
          id
          name
          level
          description
          link
          videoUrl
          date
        }
        fundingGoals {
          id
          amount
          currency
          description
        }
        sponsors {
          id
          imgS3Key
        }
        emailValidated
        validationEmailSentAt
        potentialAthlete
        currency
        themeId
        role
        nickname
        stripePayoutsEnabled
        payoutCurrency
        stats {
          numUniqueViews
          numScouts
          paidByScouts
          numPosts
        }
        features {
          posts
        }
        stripeCustomerId
        ivsChannelArn
        createdAt
        updatedAt
        owner
        email
        stripeActionRequired
        stripeAccountId
        social {
          fbUserId
          fbAccessToken
          fbTargetPageId
          twitterRequestToken
          twitterRequestSecret
          twitterAccessToken
          twitterAccessSecret
          instagramUserId
        }
        firebaseTokens
      }
      tier {
        id
        userId
        name
        currency
        unitAmount
        discountType
        discount
        discountMonths
        couponId
        createdAt
        updatedAt
      }
    }
  }
`;
export const updatePost = /* GraphQL */ `
  mutation UpdatePost(
    $input: UpdatePostInput!
    $condition: ModelPostConditionInput
  ) {
    updatePost(input: $input, condition: $condition) {
      id
      authorId
      name
      description
      needsSubscription
      type
      tierId
      vodId
      vodStatus
      vodMetadata {
        durationInMs
        orgWidth
        orgHeight
        hasPreview
      }
      bcastMetadata {
        previewImageS3Key
        time
        duration
        guests {
          id
          name
        }
        allowQuestions
        status
        bcastUrl
        recordingS3Key
      }
      thumbS3Key
      likes
      comments
      liked
      reviewStatus
      feedId
      createdAt
      updatedAt
      author {
        id
        name
        givenName
        familyName
        gender
        birthdate
        country
        sport
        profileImgS3Key
        coverImgS3Key
        introVideoS3Key
        introVideoThumbS3Key
        introVideoUrl
        mission
        instagramUrl
        facebookUrl
        youtubeUrl
        goals {
          id
          name
          level
          description
          videoUrl
          link
        }
        achievements {
          id
          name
          level
          description
          link
          videoUrl
          date
        }
        fundingGoals {
          id
          amount
          currency
          description
        }
        sponsors {
          id
          imgS3Key
        }
        emailValidated
        validationEmailSentAt
        potentialAthlete
        currency
        themeId
        role
        nickname
        stripePayoutsEnabled
        payoutCurrency
        stats {
          numUniqueViews
          numScouts
          paidByScouts
          numPosts
        }
        features {
          posts
        }
        stripeCustomerId
        ivsChannelArn
        createdAt
        updatedAt
        owner
        email
        stripeActionRequired
        stripeAccountId
        social {
          fbUserId
          fbAccessToken
          fbTargetPageId
          twitterRequestToken
          twitterRequestSecret
          twitterAccessToken
          twitterAccessSecret
          instagramUserId
        }
        firebaseTokens
      }
      tier {
        id
        userId
        name
        currency
        unitAmount
        discountType
        discount
        discountMonths
        couponId
        createdAt
        updatedAt
      }
    }
  }
`;
export const deletePost = /* GraphQL */ `
  mutation DeletePost(
    $input: DeletePostInput!
    $condition: ModelPostConditionInput
  ) {
    deletePost(input: $input, condition: $condition) {
      id
      authorId
      name
      description
      needsSubscription
      type
      tierId
      vodId
      vodStatus
      vodMetadata {
        durationInMs
        orgWidth
        orgHeight
        hasPreview
      }
      bcastMetadata {
        previewImageS3Key
        time
        duration
        guests {
          id
          name
        }
        allowQuestions
        status
        bcastUrl
        recordingS3Key
      }
      thumbS3Key
      likes
      comments
      liked
      reviewStatus
      feedId
      createdAt
      updatedAt
      author {
        id
        name
        givenName
        familyName
        gender
        birthdate
        country
        sport
        profileImgS3Key
        coverImgS3Key
        introVideoS3Key
        introVideoThumbS3Key
        introVideoUrl
        mission
        instagramUrl
        facebookUrl
        youtubeUrl
        goals {
          id
          name
          level
          description
          videoUrl
          link
        }
        achievements {
          id
          name
          level
          description
          link
          videoUrl
          date
        }
        fundingGoals {
          id
          amount
          currency
          description
        }
        sponsors {
          id
          imgS3Key
        }
        emailValidated
        validationEmailSentAt
        potentialAthlete
        currency
        themeId
        role
        nickname
        stripePayoutsEnabled
        payoutCurrency
        stats {
          numUniqueViews
          numScouts
          paidByScouts
          numPosts
        }
        features {
          posts
        }
        stripeCustomerId
        ivsChannelArn
        createdAt
        updatedAt
        owner
        email
        stripeActionRequired
        stripeAccountId
        social {
          fbUserId
          fbAccessToken
          fbTargetPageId
          twitterRequestToken
          twitterRequestSecret
          twitterAccessToken
          twitterAccessSecret
          instagramUserId
        }
        firebaseTokens
      }
      tier {
        id
        userId
        name
        currency
        unitAmount
        discountType
        discount
        discountMonths
        couponId
        createdAt
        updatedAt
      }
    }
  }
`;
export const createAthleteSubscription = /* GraphQL */ `
  mutation CreateAthleteSubscription(
    $input: CreateAthleteSubscriptionInput!
    $condition: ModelAthleteSubscriptionConditionInput
  ) {
    createAthleteSubscription(input: $input, condition: $condition) {
      subscriberId
      athleteId
      tierId
      currency
      unitAmount
      paidToAthlete
      status
      cancelAtPeriodEnd
      currentPeriodEnd
      expiresAt
      discountType
      discount
      discountRemainingMonths
      couponId
      taxRateId
      updatedAt
      createdAt
      stripeSubscriptionId
      stripePriceId
      subscriber {
        id
        name
        givenName
        familyName
        gender
        birthdate
        country
        sport
        profileImgS3Key
        coverImgS3Key
        introVideoS3Key
        introVideoThumbS3Key
        introVideoUrl
        mission
        instagramUrl
        facebookUrl
        youtubeUrl
        goals {
          id
          name
          level
          description
          videoUrl
          link
        }
        achievements {
          id
          name
          level
          description
          link
          videoUrl
          date
        }
        fundingGoals {
          id
          amount
          currency
          description
        }
        sponsors {
          id
          imgS3Key
        }
        emailValidated
        validationEmailSentAt
        potentialAthlete
        currency
        themeId
        role
        nickname
        stripePayoutsEnabled
        payoutCurrency
        stats {
          numUniqueViews
          numScouts
          paidByScouts
          numPosts
        }
        features {
          posts
        }
        stripeCustomerId
        ivsChannelArn
        createdAt
        updatedAt
        owner
        email
        stripeActionRequired
        stripeAccountId
        social {
          fbUserId
          fbAccessToken
          fbTargetPageId
          twitterRequestToken
          twitterRequestSecret
          twitterAccessToken
          twitterAccessSecret
          instagramUserId
        }
        firebaseTokens
      }
      athlete {
        id
        name
        givenName
        familyName
        gender
        birthdate
        country
        sport
        profileImgS3Key
        coverImgS3Key
        introVideoS3Key
        introVideoThumbS3Key
        introVideoUrl
        mission
        instagramUrl
        facebookUrl
        youtubeUrl
        goals {
          id
          name
          level
          description
          videoUrl
          link
        }
        achievements {
          id
          name
          level
          description
          link
          videoUrl
          date
        }
        fundingGoals {
          id
          amount
          currency
          description
        }
        sponsors {
          id
          imgS3Key
        }
        emailValidated
        validationEmailSentAt
        potentialAthlete
        currency
        themeId
        role
        nickname
        stripePayoutsEnabled
        payoutCurrency
        stats {
          numUniqueViews
          numScouts
          paidByScouts
          numPosts
        }
        features {
          posts
        }
        stripeCustomerId
        ivsChannelArn
        createdAt
        updatedAt
        owner
        email
        stripeActionRequired
        stripeAccountId
        social {
          fbUserId
          fbAccessToken
          fbTargetPageId
          twitterRequestToken
          twitterRequestSecret
          twitterAccessToken
          twitterAccessSecret
          instagramUserId
        }
        firebaseTokens
      }
      tier {
        id
        userId
        name
        currency
        unitAmount
        discountType
        discount
        discountMonths
        couponId
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateAthleteSubscription = /* GraphQL */ `
  mutation UpdateAthleteSubscription(
    $input: UpdateAthleteSubscriptionInput!
    $condition: ModelAthleteSubscriptionConditionInput
  ) {
    updateAthleteSubscription(input: $input, condition: $condition) {
      subscriberId
      athleteId
      tierId
      currency
      unitAmount
      paidToAthlete
      status
      cancelAtPeriodEnd
      currentPeriodEnd
      expiresAt
      discountType
      discount
      discountRemainingMonths
      couponId
      taxRateId
      updatedAt
      createdAt
      stripeSubscriptionId
      stripePriceId
      subscriber {
        id
        name
        givenName
        familyName
        gender
        birthdate
        country
        sport
        profileImgS3Key
        coverImgS3Key
        introVideoS3Key
        introVideoThumbS3Key
        introVideoUrl
        mission
        instagramUrl
        facebookUrl
        youtubeUrl
        goals {
          id
          name
          level
          description
          videoUrl
          link
        }
        achievements {
          id
          name
          level
          description
          link
          videoUrl
          date
        }
        fundingGoals {
          id
          amount
          currency
          description
        }
        sponsors {
          id
          imgS3Key
        }
        emailValidated
        validationEmailSentAt
        potentialAthlete
        currency
        themeId
        role
        nickname
        stripePayoutsEnabled
        payoutCurrency
        stats {
          numUniqueViews
          numScouts
          paidByScouts
          numPosts
        }
        features {
          posts
        }
        stripeCustomerId
        ivsChannelArn
        createdAt
        updatedAt
        owner
        email
        stripeActionRequired
        stripeAccountId
        social {
          fbUserId
          fbAccessToken
          fbTargetPageId
          twitterRequestToken
          twitterRequestSecret
          twitterAccessToken
          twitterAccessSecret
          instagramUserId
        }
        firebaseTokens
      }
      athlete {
        id
        name
        givenName
        familyName
        gender
        birthdate
        country
        sport
        profileImgS3Key
        coverImgS3Key
        introVideoS3Key
        introVideoThumbS3Key
        introVideoUrl
        mission
        instagramUrl
        facebookUrl
        youtubeUrl
        goals {
          id
          name
          level
          description
          videoUrl
          link
        }
        achievements {
          id
          name
          level
          description
          link
          videoUrl
          date
        }
        fundingGoals {
          id
          amount
          currency
          description
        }
        sponsors {
          id
          imgS3Key
        }
        emailValidated
        validationEmailSentAt
        potentialAthlete
        currency
        themeId
        role
        nickname
        stripePayoutsEnabled
        payoutCurrency
        stats {
          numUniqueViews
          numScouts
          paidByScouts
          numPosts
        }
        features {
          posts
        }
        stripeCustomerId
        ivsChannelArn
        createdAt
        updatedAt
        owner
        email
        stripeActionRequired
        stripeAccountId
        social {
          fbUserId
          fbAccessToken
          fbTargetPageId
          twitterRequestToken
          twitterRequestSecret
          twitterAccessToken
          twitterAccessSecret
          instagramUserId
        }
        firebaseTokens
      }
      tier {
        id
        userId
        name
        currency
        unitAmount
        discountType
        discount
        discountMonths
        couponId
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteAthleteSubscription = /* GraphQL */ `
  mutation DeleteAthleteSubscription(
    $input: DeleteAthleteSubscriptionInput!
    $condition: ModelAthleteSubscriptionConditionInput
  ) {
    deleteAthleteSubscription(input: $input, condition: $condition) {
      subscriberId
      athleteId
      tierId
      currency
      unitAmount
      paidToAthlete
      status
      cancelAtPeriodEnd
      currentPeriodEnd
      expiresAt
      discountType
      discount
      discountRemainingMonths
      couponId
      taxRateId
      updatedAt
      createdAt
      stripeSubscriptionId
      stripePriceId
      subscriber {
        id
        name
        givenName
        familyName
        gender
        birthdate
        country
        sport
        profileImgS3Key
        coverImgS3Key
        introVideoS3Key
        introVideoThumbS3Key
        introVideoUrl
        mission
        instagramUrl
        facebookUrl
        youtubeUrl
        goals {
          id
          name
          level
          description
          videoUrl
          link
        }
        achievements {
          id
          name
          level
          description
          link
          videoUrl
          date
        }
        fundingGoals {
          id
          amount
          currency
          description
        }
        sponsors {
          id
          imgS3Key
        }
        emailValidated
        validationEmailSentAt
        potentialAthlete
        currency
        themeId
        role
        nickname
        stripePayoutsEnabled
        payoutCurrency
        stats {
          numUniqueViews
          numScouts
          paidByScouts
          numPosts
        }
        features {
          posts
        }
        stripeCustomerId
        ivsChannelArn
        createdAt
        updatedAt
        owner
        email
        stripeActionRequired
        stripeAccountId
        social {
          fbUserId
          fbAccessToken
          fbTargetPageId
          twitterRequestToken
          twitterRequestSecret
          twitterAccessToken
          twitterAccessSecret
          instagramUserId
        }
        firebaseTokens
      }
      athlete {
        id
        name
        givenName
        familyName
        gender
        birthdate
        country
        sport
        profileImgS3Key
        coverImgS3Key
        introVideoS3Key
        introVideoThumbS3Key
        introVideoUrl
        mission
        instagramUrl
        facebookUrl
        youtubeUrl
        goals {
          id
          name
          level
          description
          videoUrl
          link
        }
        achievements {
          id
          name
          level
          description
          link
          videoUrl
          date
        }
        fundingGoals {
          id
          amount
          currency
          description
        }
        sponsors {
          id
          imgS3Key
        }
        emailValidated
        validationEmailSentAt
        potentialAthlete
        currency
        themeId
        role
        nickname
        stripePayoutsEnabled
        payoutCurrency
        stats {
          numUniqueViews
          numScouts
          paidByScouts
          numPosts
        }
        features {
          posts
        }
        stripeCustomerId
        ivsChannelArn
        createdAt
        updatedAt
        owner
        email
        stripeActionRequired
        stripeAccountId
        social {
          fbUserId
          fbAccessToken
          fbTargetPageId
          twitterRequestToken
          twitterRequestSecret
          twitterAccessToken
          twitterAccessSecret
          instagramUserId
        }
        firebaseTokens
      }
      tier {
        id
        userId
        name
        currency
        unitAmount
        discountType
        discount
        discountMonths
        couponId
        createdAt
        updatedAt
      }
    }
  }
`;
export const createFlashRequest = /* GraphQL */ `
  mutation CreateFlashRequest(
    $input: CreateFlashRequestInput!
    $condition: ModelFlashRequestConditionInput
  ) {
    createFlashRequest(input: $input, condition: $condition) {
      userId
      request
      targetId
      payload
      createdAt
      updatedAt
    }
  }
`;
export const updateFlashRequest = /* GraphQL */ `
  mutation UpdateFlashRequest(
    $input: UpdateFlashRequestInput!
    $condition: ModelFlashRequestConditionInput
  ) {
    updateFlashRequest(input: $input, condition: $condition) {
      userId
      request
      targetId
      payload
      createdAt
      updatedAt
    }
  }
`;
export const deleteFlashRequest = /* GraphQL */ `
  mutation DeleteFlashRequest(
    $input: DeleteFlashRequestInput!
    $condition: ModelFlashRequestConditionInput
  ) {
    deleteFlashRequest(input: $input, condition: $condition) {
      userId
      request
      targetId
      payload
      createdAt
      updatedAt
    }
  }
`;
export const createLike = /* GraphQL */ `
  mutation CreateLike(
    $input: CreateLikeInput!
    $condition: ModelLikeConditionInput
  ) {
    createLike(input: $input, condition: $condition) {
      userId
      targetId
      targetType
      createdAt
      updatedAt
      user {
        id
        name
        givenName
        familyName
        gender
        birthdate
        country
        sport
        profileImgS3Key
        coverImgS3Key
        introVideoS3Key
        introVideoThumbS3Key
        introVideoUrl
        mission
        instagramUrl
        facebookUrl
        youtubeUrl
        goals {
          id
          name
          level
          description
          videoUrl
          link
        }
        achievements {
          id
          name
          level
          description
          link
          videoUrl
          date
        }
        fundingGoals {
          id
          amount
          currency
          description
        }
        sponsors {
          id
          imgS3Key
        }
        emailValidated
        validationEmailSentAt
        potentialAthlete
        currency
        themeId
        role
        nickname
        stripePayoutsEnabled
        payoutCurrency
        stats {
          numUniqueViews
          numScouts
          paidByScouts
          numPosts
        }
        features {
          posts
        }
        stripeCustomerId
        ivsChannelArn
        createdAt
        updatedAt
        owner
        email
        stripeActionRequired
        stripeAccountId
        social {
          fbUserId
          fbAccessToken
          fbTargetPageId
          twitterRequestToken
          twitterRequestSecret
          twitterAccessToken
          twitterAccessSecret
          instagramUserId
        }
        firebaseTokens
      }
    }
  }
`;
export const updateLike = /* GraphQL */ `
  mutation UpdateLike(
    $input: UpdateLikeInput!
    $condition: ModelLikeConditionInput
  ) {
    updateLike(input: $input, condition: $condition) {
      userId
      targetId
      targetType
      createdAt
      updatedAt
      user {
        id
        name
        givenName
        familyName
        gender
        birthdate
        country
        sport
        profileImgS3Key
        coverImgS3Key
        introVideoS3Key
        introVideoThumbS3Key
        introVideoUrl
        mission
        instagramUrl
        facebookUrl
        youtubeUrl
        goals {
          id
          name
          level
          description
          videoUrl
          link
        }
        achievements {
          id
          name
          level
          description
          link
          videoUrl
          date
        }
        fundingGoals {
          id
          amount
          currency
          description
        }
        sponsors {
          id
          imgS3Key
        }
        emailValidated
        validationEmailSentAt
        potentialAthlete
        currency
        themeId
        role
        nickname
        stripePayoutsEnabled
        payoutCurrency
        stats {
          numUniqueViews
          numScouts
          paidByScouts
          numPosts
        }
        features {
          posts
        }
        stripeCustomerId
        ivsChannelArn
        createdAt
        updatedAt
        owner
        email
        stripeActionRequired
        stripeAccountId
        social {
          fbUserId
          fbAccessToken
          fbTargetPageId
          twitterRequestToken
          twitterRequestSecret
          twitterAccessToken
          twitterAccessSecret
          instagramUserId
        }
        firebaseTokens
      }
    }
  }
`;
export const deleteLike = /* GraphQL */ `
  mutation DeleteLike(
    $input: DeleteLikeInput!
    $condition: ModelLikeConditionInput
  ) {
    deleteLike(input: $input, condition: $condition) {
      userId
      targetId
      targetType
      createdAt
      updatedAt
      user {
        id
        name
        givenName
        familyName
        gender
        birthdate
        country
        sport
        profileImgS3Key
        coverImgS3Key
        introVideoS3Key
        introVideoThumbS3Key
        introVideoUrl
        mission
        instagramUrl
        facebookUrl
        youtubeUrl
        goals {
          id
          name
          level
          description
          videoUrl
          link
        }
        achievements {
          id
          name
          level
          description
          link
          videoUrl
          date
        }
        fundingGoals {
          id
          amount
          currency
          description
        }
        sponsors {
          id
          imgS3Key
        }
        emailValidated
        validationEmailSentAt
        potentialAthlete
        currency
        themeId
        role
        nickname
        stripePayoutsEnabled
        payoutCurrency
        stats {
          numUniqueViews
          numScouts
          paidByScouts
          numPosts
        }
        features {
          posts
        }
        stripeCustomerId
        ivsChannelArn
        createdAt
        updatedAt
        owner
        email
        stripeActionRequired
        stripeAccountId
        social {
          fbUserId
          fbAccessToken
          fbTargetPageId
          twitterRequestToken
          twitterRequestSecret
          twitterAccessToken
          twitterAccessSecret
          instagramUserId
        }
        firebaseTokens
      }
    }
  }
`;
export const createMigrations = /* GraphQL */ `
  mutation CreateMigrations(
    $input: CreateMigrationsInput!
    $condition: ModelMigrationsConditionInput
  ) {
    createMigrations(input: $input, condition: $condition) {
      name
      createdAt
      updatedAt
    }
  }
`;
export const updateMigrations = /* GraphQL */ `
  mutation UpdateMigrations(
    $input: UpdateMigrationsInput!
    $condition: ModelMigrationsConditionInput
  ) {
    updateMigrations(input: $input, condition: $condition) {
      name
      createdAt
      updatedAt
    }
  }
`;
export const deleteMigrations = /* GraphQL */ `
  mutation DeleteMigrations(
    $input: DeleteMigrationsInput!
    $condition: ModelMigrationsConditionInput
  ) {
    deleteMigrations(input: $input, condition: $condition) {
      name
      createdAt
      updatedAt
    }
  }
`;
