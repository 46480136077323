/*
 * Using mui-rff
 * Check following link for more info: https://github.com/lookfirst/mui-rff
 * */

import React, { useEffect, useState, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import * as Yup from 'yup';
import { makeValidate, Radios, TextField } from 'mui-rff';
import { Form, FormSpy } from 'react-final-form';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import Dialog from '@material-ui/core/Dialog/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import InputAdornment from '@material-ui/core/InputAdornment';
import Box from '@material-ui/core/Box';
import getSymbolFromCurrency from 'currency-symbol-map';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { Link as RouterLink } from 'react-router-dom';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

import { CurrenciesConfig, Tier, User } from '../../core/graphql/types';
import { formatAmount, getCurrencyFactor, getCurrencyPrecission, toAmountMinUnit } from '../../core/helpers/currency';
import SubmitButton from '../../components/ui/SubmitButton';
import { createTier } from '../../core/services/subscriptionsSvc';
import { convertToCurrency } from '../../core/services/currencySvc';
import { appStore } from '../../core/stores/app';
import CurrencySelector from '../CurrencySelector';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  content: {
    width: '100%',
    height: '100%',
  },
  submitBtnWrapper: {
    position: 'relative',
  },
  submitBtn: {
    margin: theme.spacing(2, 0, 1),
    height: 50,
  },
  submitBtnProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  charCounter: {
    position: 'absolute',
    right: theme.spacing(1),
    bottom: theme.spacing(1 / 2),
  },
  countryOption: {
    fontSize: 15,
    '& > span': {
      marginRight: theme.spacing(1),
      fontSize: 18,
    },
  },
  radios: {
    '& .MuiTypography-root': {
      fontSize: '20px',
    },
  },
  textInput: {
    maxWidth: '150px',
    '& input': {
      fontSize: '22px',
      textAlign: 'center',
      width: '100px',
    },
    '& fieldset': {
      borderWidth: 'medium !important',
    },
  },
  textInputCurrency: {
    '& p': {
      fontSize: '22px',
      fontWeight: theme.typography.fontWeightBold,
    },
  },
}));

interface CreateTierDialogProps {
  open: boolean;
  athlete: User;
  currency: string;
  onTierCreated: (tier: Tier) => void;
}

export interface CreateTierDialogData {
  amount: string;
  altAmount?: string;
}

const minAmountEur = 150;
const CreateTierDialog: React.FC<CreateTierDialogProps> = ({
  open,
  athlete,
  currency,
  onTierCreated,
}: CreateTierDialogProps) => {
  const classes = useStyles();
  const [appState] = useContext(appStore);
  const [submitting, setSubmitting] = useState(false);
  const [currencySymbol, setCurrencySymbol] = useState('');
  const [amountOptions, setAmountOptions] = useState<{ label: string; auxLabel: string; value: string }[]>([]);
  const [amount, setAmount] = useState(0);
  const [isAltAmount, setIsAltAmount] = useState(false);
  const [isAmountValid, setIsAmountValid] = useState(false);
  const [minAmount, setMinAmount] = useState(minAmountEur);
  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const formId = 'payment-dialog-form';
  const subscription = { pristine: true, invalid: true };
  const fieldsMargin = 'dense';
  const formSchema = Yup.object().shape({
    amount: Yup.string(),
    altAmount: Yup.string().test('altAmount', '', (val) => {
      try {
        return !val || parseFloat(val) * 100 >= minAmount;
      } catch (e) {}

      return false;
    }),
  });
  const validate = makeValidate(formSchema);
  const handleSubmitClick = async (values: CreateTierDialogData) => {
    let amount;

    if (values.amount === '-') {
      amount = parseFloat(values.altAmount || minAmountEur.toString()) * 100;
    } else {
      amount = parseInt(values.amount || minAmountEur.toString(), 10);
    }

    if (amount && currency) {
      setSubmitting(true);
      const tier = await createTier(`${athlete.name} STANDARD`, currency, amount);
      setSubmitting(false);
      onTierCreated(tier);
    }
  };
  const handleFormChange = ({ values }: any) => {
    // Avoid re-render loop
    setTimeout(() => {
      let theAmount;

      // If there is a currency change, reset all
      if (values.amount === '-') {
        setIsAltAmount(true);
        theAmount = values.altAmount ? toAmountMinUnit(values.altAmount, currency) : 0;
      } else {
        setIsAltAmount(false);
        theAmount = parseInt(values.amount, 10);
      }
      setAmount(theAmount);

      // Manually manage the error message because it will show out of the form field
      if (theAmount < minAmount) {
        setIsAmountValid(false);
      } else {
        setIsAmountValid(true);
      }
    }, 0);
  };

  useEffect(() => {
    if (appState.currenciesConfig && currency) {
      setAmountOptions(getAmountOptions(appState.currenciesConfig, currency));
      setCurrencySymbol(getSymbolFromCurrency(currency));
      setMinAmount(convertToCurrency(appState.currenciesConfig, minAmountEur, 'eur', currency));
    }
  }, [appState.currenciesConfig, currency]);

  return (
    <Form
      onSubmit={handleSubmitClick}
      initialValues={{ currency }}
      subscription={subscription}
      validate={validate}
      key={subscription as any}
      render={(
        { handleSubmit }, // , pristine, invalid
      ) => (
        <form
          id={formId}
          className={classes.form}
          onSubmit={handleSubmit}
          noValidate={true}
          autoComplete="new-password"
        >
          <FormSpy subscription={{ values: true }} onChange={handleFormChange} />
          <Dialog
            open={open}
            maxWidth="xs"
            fullWidth={true}
            fullScreen={!isBigScreen}
            aria-labelledby="form-dialog-name"
          >
            <DialogTitle>Set the price for your subscriptions</DialogTitle>
            <DialogContent>
              <Typography variant="body1" paragraph>
                This is the amount that your fans will pay every month when they subscribe to your channel.
              </Typography>
              <Grid className={classes.content} container spacing={2}>
                {/* Amount */}
                <Grid item xs={12}>
                  <Box className={classes.radios} display="flex" justifyContent="center">
                    <Radios name="amount" data={amountOptions} />
                  </Box>
                </Grid>
                {/* Alt Amount */}
                {isAltAmount && (
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="center">
                      <TextField
                        className={classes.textInput}
                        id="altAmount"
                        name="altAmount"
                        type="text"
                        variant="outlined"
                        disabled={submitting}
                        margin={fieldsMargin}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment className={classes.textInputCurrency} position="start">
                              {currencySymbol}
                            </InputAdornment>
                          ),
                        }}
                        fullWidth={false}
                        autoFocus
                        onInput={(e: any) => {
                          const maxLen = 7; // In currencies with 0 precission
                          let finalVal = e.target.value
                            .slice(0, maxLen - getCurrencyPrecission(currency)) // Limit length
                            .replace(/,/g, '.'); // Replace , -> .
                          const valParts = finalVal.split('.');

                          // Remove anything after a possible second '.' ('.' inclusive)
                          if (valParts.length !== 1) {
                            finalVal = `${valParts[0]}.${valParts[1]}`;
                          }

                          e.target.value = finalVal;
                        }}
                      />
                    </Box>
                    {Boolean(amount) && !isAmountValid && (
                      <Typography align="center" variant="body2">
                        {`The price of the subscription must always be ${formatAmount(
                          minAmount,
                          currency,
                        )} or higher. Please try again.`}
                      </Typography>
                    )}
                  </Grid>
                )}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Box width={1}>
                <Box display="flex" mb={1} justifyContent="flex-end" pr={1}>
                  <FormControl>
                    <CurrencySelector showToast={false} />
                    <FormHelperText>Select your currency</FormHelperText>
                  </FormControl>
                </Box>
                <SubmitButton form={formId} fullWidth disabled={!amount || !isAmountValid} submitting={submitting}>
                  Continue
                </SubmitButton>
                {isAltAmount && (
                  <>
                    <Box mt={1}>
                      {appState.currenciesConfig && (
                        <Typography variant="caption">
                          {`(*) For microprocessing charges - prices under ${formatAmount(
                            convertToCurrency(appState.currenciesConfig, minAmountEur, 'eur', currency),
                            currency,
                          )} - click `}
                          <RouterLink to={'/blogs/33udzmgjh49ANMU4i9srz8'} target="_blank">
                            here
                          </RouterLink>
                          .
                        </Typography>
                      )}
                    </Box>
                    <Box>
                      <Typography variant="caption">
                        (**) Want to check your pricing logic? Check our best practice guide{' '}
                        <a href={'https://peakz.io/en/policy/'} target="_blank" rel="noreferrer">
                          here
                        </a>
                        .
                      </Typography>
                    </Box>
                  </>
                )}
              </Box>
            </DialogActions>
          </Dialog>
        </form>
      )}
    />
  );
};

function getAmountOptions(
  currenciesConfig: CurrenciesConfig,
  currency: string,
): { label: string; auxLabel: string; value: string }[] {
  const val1 = convertToCurrency(currenciesConfig, 350, 'eur', currency);
  const val2 = convertToCurrency(currenciesConfig, 500, 'eur', currency);
  const val3 = convertToCurrency(currenciesConfig, 1000, 'eur', currency);

  return [
    {
      label: formatAmount(val1, currency),
      auxLabel: (val1 / getCurrencyFactor(currency)).toFixed(2),
      value: val1.toString(),
    },
    {
      label: formatAmount(val2, currency),
      auxLabel: (val2 / getCurrencyFactor(currency)).toFixed(2),
      value: val2.toString(),
    },
    {
      label: formatAmount(val3, currency),
      auxLabel: (val3 / getCurrencyFactor(currency)).toFixed(2),
      value: val3.toString(),
    },
    { label: 'Other amount', auxLabel: '', value: '-' },
  ];
}
export default CreateTierDialog;
