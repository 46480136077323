// config:
const Config = {
  BACKGROUND_COLOR: 'rgb(46, 47, 52)',
  BARS_COLOR: 'black',
  MAX_SHARE_ITEMS: 1,
  MAX_VIDEO_ITEMS: 4,
  THUMBNAIL_WIDTH: '15%',
  THUMBNAIL_HEIGHT: '25%',
  TILE_BORDER: '1px solid transparent',
  CONTROLS_SIZE: '4rem',
  BROADCAST_WIDTH: '1920px',
  BROADCAST_HEIGHT: '1080px',

  // video capture resolution
  // (the same as aws-chime-sdk's defaultVideoWidth and defaultVideoHeight in
  // https://github.com/aws/amazon-chime-sdk-js/blob/main/src/devicecontroller/DefaultDeviceController.ts)
  VIDEO_CAPTURE_WIDTH: 960,
  VIDEO_CAPTURE_HEIGHT: 540,

  // screen capture resolution
  // I came to these number by playing with YouTube quality
  // (480p is a compromise: 1) we don't need super resolutions for watching matches, 2) yet it is played
  // on mobiles without much chopping
  CONTENT_SHARE_CONSTRAINTS: {
    width: { ideal: 854 },
    height: { ideal: 480 },
    frameRate: { ideal: 15 },
  },

  // Meeting form: when it will let attendants to join the meeting (if earlier, it offers adding callendar event)
  BCAST_REMINDER_INTERVAL: 30 * 60, // 30 minutes before

  // Meeting view: how many seconds from clicking on "Start" button to begin streaming
  // (depends on how fast broadcaster app on EC2 einstance starts the streaming)
  FILM_COUNTDOWN_SECONDS: 5,

  // Broadcast view: How many seconds from turning on "live" status to begin streaming
  // (it should be equal to the streaming latency)
  LIVE_COUNTDOWN_SECONDS: 5,
};

export default Config;
