import { Box, Divider, Grid, IconButton, TextField } from '@material-ui/core';
import { AddCircleOutline, Delete, Link } from '@material-ui/icons';
import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { getMeetingRoute } from '../../../core/helpers/route';
import { Bcast, BcastGuest } from '../../../API';
import CharacterCounter from '../../ui/CharacterCounter';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import ActionConfirmationDialog from '../../dialogs/ActionConfirmationDialog';

interface Props {
  record: Bcast;
  onChange: (items: BcastGuest[]) => void;
}

const CloseTooltip = withStyles({
  tooltipPlacementBottom: {
    margin: '8px 0',
  },
})(Tooltip);

const GuestList: React.FC<Props> = (props: Props) => {
  const items = props.record.guests; // todo: error handling
  const [name, setName] = useState('');
  const [createGuestLinkDialogOpen, setCreateGuestLinkDialogOpen] = useState(false);
  const [deleteGuestConfirmationDialogOpen, setDeleteGuestConfirmationDialogOpen] = useState(false);
  const [selectedGuestId, setSelectedGuestId] = useState('');

  const setItems = (items: BcastGuest[]) => {
    props.onChange?.(items);
  };
  const handleAdd = (event: React.FormEvent) => {
    event.preventDefault();
    if (name.trim().length === 0) {
      return;
    }
    setItems([
      ...items,
      {
        __typename: 'BcastGuest',
        id: uuidv4(),
        name: name.trim(),
      },
    ]);
    setName('');
  };
  // const handleDelete = (id: string) => {
  //   setItems(items.filter((item) => item.id !== id));
  // };
  // const handleShare = (url: string) => {
  //   // body={
  //   //     `You are invited as a guest to my broadcast.\n` +
  //   //     `Broadcast will take place at ${props.record.time}.\n` +
  //   //     `Please open the link below five minutes before to join.\n` +
  //   //     `You can use the samo link to add calendar remainder.\n\n`
  //   // }
  //   alert(url);
  // };
  // generates invitation URL for a guest
  const composeInvitationUrl = (id: string) => {
    const url = getMeetingRoute(props.record.id, false);
    return `${url}?gid=${id}`;
  };

  const handleCreateGuestLinkClick = (id: string) => {
    setSelectedGuestId(id);
    setCreateGuestLinkDialogOpen(true);
  };

  const handleCreateGuestLinkConfirmation = async () => {
    setCreateGuestLinkDialogOpen(false);
    if (selectedGuestId !== '') {
      const id = selectedGuestId;
      const shareUrl = composeInvitationUrl(id);

      try {
        await navigator.clipboard.writeText(shareUrl);
      } catch (err) {
        alert("Can't copy!");
      }
      setSelectedGuestId('');
    }
  };

  const handleCreateGuestLinkDialogCancel = () => {
    setCreateGuestLinkDialogOpen(false);
  };

  const handleDeleteGuestClick = (id: string) => {
    setSelectedGuestId(id);
    setDeleteGuestConfirmationDialogOpen(true);
  };

  const handleDeleteGuestConfirmation = async () => {
    setDeleteGuestConfirmationDialogOpen(false);
    if (selectedGuestId !== '') {
      const id = selectedGuestId;
      setItems(items.filter((item) => item.id !== id));
      setSelectedGuestId('');
    }
  };

  const handleDeleteGuestDialogCancel = () => {
    setDeleteGuestConfirmationDialogOpen(false);
  };

  return (
    <>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={12}>
          <Grid container justifyContent="space-between">
            <Grid item xs={9}>
              <TextField
                placeholder="Participant name"
                variant="outlined"
                fullWidth
                margin="dense"
                autoFocus={true}
                InputProps={{
                  inputProps: {
                    onChange: (event: any) => {
                      setName(event.target.value);
                    },
                  },
                }}
                value={name}
              />
              <CharacterCounter chars={(name || '').length} max={50} />
            </Grid>
            <Grid item xs={3}>
              <CloseTooltip placement="bottom" title="Participant name" aria-label="Participant name" arrow>
                <IconButton size="medium" onClick={handleAdd}>
                  <AddCircleOutline fontSize="medium" />
                </IconButton>
              </CloseTooltip>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box mt={1} mb={1}>
            <Divider></Divider>
          </Box>
        </Grid>
      </Grid>
      {items.length === 0 ? (
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={12}>
            No guests, I will broadcast alone.
          </Grid>
        </Grid>
      ) : (
        items.map((item) => {
          //const shareUrl = composeInvitationUrl(item.id);
          //const title = `Invitation: ${props.record.title}`;
          return (
            <Grid container alignItems="center" justifyContent="center" key={'guestRow' + item.id}>
              <Grid item xs={12}>
                <Grid container justifyContent="space-between">
                  <Grid item xs={9} md={8}>
                    <span style={{ fontWeight: 'bold' }}>{item.name}</span>
                  </Grid>
                  <Grid item xs={3} md={2}>
                    <CloseTooltip
                      placement="bottom"
                      title="Generate link for participant"
                      aria-label="Generate link for participant"
                      arrow
                    >
                      <IconButton size="small" onClick={() => handleCreateGuestLinkClick(item.id)}>
                        <Link fontSize="medium" />
                      </IconButton>
                    </CloseTooltip>
                    <CloseTooltip placement="bottom" title="Remove participant" aria-label="Remove participant" arrow>
                      <IconButton size="small" onClick={() => handleDeleteGuestClick(item.id)}>
                        <Delete fontSize="medium" />
                      </IconButton>
                    </CloseTooltip>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Box mt={1} mb={1}>
                  <Divider></Divider>
                </Box>
              </Grid>
              {deleteGuestConfirmationDialogOpen && (
                <ActionConfirmationDialog
                  open={deleteGuestConfirmationDialogOpen}
                  title={''}
                  description={'Are you sure you want to delete this guest?'}
                  onYesClick={handleDeleteGuestConfirmation}
                  onNoClick={handleDeleteGuestDialogCancel}
                  onClose={handleDeleteGuestDialogCancel}
                />
              )}
              {createGuestLinkDialogOpen && (
                <ActionConfirmationDialog
                  open={createGuestLinkDialogOpen}
                  title={''}
                  description={'Share the link with your guest via any app.'}
                  confirmationText={'Copy link to clipboard'}
                  cancelationText={'Close'}
                  onYesClick={handleCreateGuestLinkConfirmation}
                  onNoClick={handleCreateGuestLinkDialogCancel}
                  onClose={handleCreateGuestLinkDialogCancel}
                />
              )}
            </Grid>
          );
        })
      )}
    </>
  );
};

export default GuestList;
