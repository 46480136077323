import React, { useEffect, useState, useContext, useRef } from 'react';
import { Box, Button, useTheme, Typography, Divider } from '@material-ui/core';
import ReCAPTCHA from 'react-google-recaptcha';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ReplayIcon from '@material-ui/icons/Replay';

import { recaptchaSiteKeyV3 } from '../../config';
import StyledTextArea from '../../components/ui/StyledTextArea';
import FormRow from '../../components/ui/FormRow';
import { fetchCommentsByParent, createComment } from '../../core/services/commentSvc';
import { CommentParentType, ModelSortDirection } from '../../API';
import { Comment } from '../../core/graphql/types';
import CommentBox from './CommentBox';
import { authStore } from '../../core/stores/auth';
import { appStore } from '../../core/stores/app';
import SpinnerBox from '../../components/ui/SpinnerBox';
import WarningMessage from '../../components/ui/WarningMessage';
import { moderationFilter } from '../../App';
import FormSubmitButton from '../../components/ui/FormSubmitButton';
import SubmitButton from '../../components/ui/SubmitButton';

interface Props {
  postId: string;
  postAuthorId: string;
  contentLoaded: boolean;
}

interface DetectedProfanityState {
  text: string;
  location?: 'comment' | 'reply';
}

const initialDetectedProfanityState: DetectedProfanityState = {
  text: 'Your comment contains inappropiate language',
};
const LIMIT = 5;

const PostCommentSection: React.FC<Props> = ({ postId, postAuthorId, contentLoaded }: Props) => {
  const [commentTxt, setCommentTxt] = useState('');
  const [replyTxt, setReplyTxt] = useState('');
  const [comments, setComments] = useState<Comment[] | undefined>(undefined);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [fetchCommentsError, setFetchCommentsError] = useState<string | undefined>('');
  const [loadingComments, setLoadingComments] = useState(false);
  const [nextToken, setNextToken] = useState<string | undefined>(undefined);
  const [detectedProfanity, setDetectedProfanity] = useState<DetectedProfanityState>(initialDetectedProfanityState);
  const [submitting, setSubmitting] = useState<'comment' | 'reply' | undefined>();
  const [replyIndex, setReplyIndex] = useState(-1);
  const [authState] = useContext(authStore);
  const [, appDispatch] = useContext(appStore);
  const theme = useTheme();

  const recaptchaRef: any = useRef();

  const handleComment = async () => {
    setSubmitting('comment');
    if (moderationFilter && !moderationFilter.isProfane(commentTxt)) {
      try {
        const recaptchaToken = await recaptchaRef.current.executeAsync();
        const newComment: Comment = await createComment(postId, CommentParentType.post, commentTxt, recaptchaToken);
        setComments((comments) => [newComment, ...(comments ? comments : [])]);
        setCommentTxt('');
      } catch (error) {
        console.error(error);
        appDispatch({
          type: 'showToast',
          toastTxt: 'There was a problem commenting this post, please try again later',
          toastSeverity: 'error',
        });
      }
      setDetectedProfanity({ ...detectedProfanity, location: undefined });
      setSubmitting(undefined);
      return;
    }
    setDetectedProfanity({ ...detectedProfanity, location: 'comment' });
    setSubmitting(undefined);
  };

  const handleCommentReply = async (parentId: string) => {
    setSubmitting('reply');
    if (!moderationFilter?.isProfane(replyTxt) || false) {
      try {
        const recaptchaToken = await recaptchaRef.current.executeAsync();
        const newReply: Comment = await createComment(parentId, CommentParentType.comment, replyTxt, recaptchaToken);

        const commentsCopy = comments ? comments.slice() : [];
        commentsCopy[replyIndex].lastComment = newReply;
        setComments(commentsCopy);
        setReplyTxt('');
        setReplyIndex(-1);
      } catch (error) {
        console.error(error);
        appDispatch({
          type: 'showToast',
          toastTxt: 'There was a problem commenting this post, please try again later',
          toastSeverity: 'error',
        });
      }
      setDetectedProfanity({ ...detectedProfanity, location: undefined });
      setSubmitting(undefined);
      return;
    }
    setDetectedProfanity({ ...detectedProfanity, location: 'reply' });
    setSubmitting(undefined);
  };

  const loadComments = async () => {
    setLoadingComments(true);
    try {
      const res = await fetchCommentsByParent(postId, ModelSortDirection.DESC, LIMIT, nextToken);
      console.log(res);
      isFirstLoad
        ? setComments(res.listCommentsByParent.items)
        : setComments(comments?.concat(res.listCommentsByParent.items));
      setNextToken(res.listCommentsByParent.nextToken);
    } catch (err) {
      setComments(undefined);
      console.error(err);
      setFetchCommentsError('There was a problem trying to retrieve the comments of this post, please try again...');
    }
    setLoadingComments(false);
  };

  useEffect(() => {
    //Comments are first loaded when the post is ready to avoid unnecesary re-renders
    if (contentLoaded) {
      loadComments();
      setIsFirstLoad(false);
    }
  }, [contentLoaded]);

  return (
    <Box p={5} mt={5}>
      <Box mb={3}>
        {/* Secion title */}
        <Typography>{comments && comments.length > 0 ? `Comments - (${comments?.length})` : 'Comments'}</Typography>
        <Divider style={{ marginTop: theme.typography.pxToRem(5), marginBottom: theme.typography.pxToRem(5) }} />
      </Box>
      {!fetchCommentsError && comments?.length === 0 && authState.user?.userId === postAuthorId && (
        <Typography>There are no comments for this post</Typography>
      )}
      <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={recaptchaSiteKeyV3} />
      {/* Write a comment area */}
      {contentLoaded && !fetchCommentsError && authState.user?.userId !== postAuthorId && (
        <FormRow label={comments?.length == 0 ? 'Be the first writing a comment' : 'Write a comment'}>
          <StyledTextArea
            minRows={5}
            maxRows={15}
            onChange={(event: any) => {
              setCommentTxt(event.target.value);
            }}
            value={commentTxt}
          />
          {detectedProfanity.location && detectedProfanity.location === 'comment' && (
            <WarningMessage
              type="field"
              icon={<ErrorOutlineIcon style={{ color: 'red' }} />}
              text={detectedProfanity.text}
            />
          )}
          <Box display="flex" justifyContent="center" flexDirection="column">
            <SubmitButton
              submitting={submitting === 'comment'}
              disabled={commentTxt.length == 0}
              onClick={handleComment}
              style={{ alignSelf: 'flex-end' }}
              variant="contained"
              color="primary"
            >
              Post comment
            </SubmitButton>
          </Box>
        </FormRow>
      )}
      {/* Comments */}
      {comments && postId && !fetchCommentsError && (
        <Box mt={5}>
          {comments?.map((comment, index) => {
            return (
              <React.Fragment key={index}>
                <CommentBox commentParam={comment} />
                {comment.lastComment && <CommentBox commentParam={comment.lastComment} />}
                {/* Reply comment area */}
                {replyIndex === index && (
                  <FormRow label="">
                    <StyledTextArea
                      minRows={1}
                      maxRows={15}
                      onChange={(event: any) => {
                        setReplyTxt(event.target.value);
                      }}
                      value={replyTxt}
                    />
                    {detectedProfanity && detectedProfanity.location && (
                      <WarningMessage
                        type="field"
                        icon={<ErrorOutlineIcon style={{ color: 'red' }} />}
                        text={detectedProfanity.text}
                      />
                    )}
                    <Box display="flex" justifyContent="center" flexDirection="column">
                      <SubmitButton
                        submitting={submitting === 'reply'}
                        disabled={replyTxt.length == 0}
                        onClick={() => {
                          handleCommentReply(comment.id || '');
                        }}
                        style={{ alignSelf: 'flex-end' }}
                        variant="contained"
                        color="primary"
                      >
                        {`Reply to ${comment.author?.name}`}
                      </SubmitButton>
                    </Box>
                  </FormRow>
                )}
                <Box ml={3} p={1} display="flex" justifyContent="center" flexDirection="column">
                  {postAuthorId === authState.user?.userId &&
                    !comment.lastComment &&
                    comment.parentType !== CommentParentType.comment &&
                    replyIndex !== index && (
                      <Button
                        style={{
                          alignSelf: 'flex-start',
                          backgroundColor: 'transparent',
                          color: theme.palette.primary.main,
                        }}
                        size="small"
                        variant="outlined"
                        onClick={() => {
                          setReplyIndex(index);
                        }}
                      >
                        Reply
                      </Button>
                    )}
                </Box>
              </React.Fragment>
            );
          })}
        </Box>
      )}
      {/* Load more button */}
      {!loadingComments && nextToken && (
        <Box display="flex" justifyContent="center">
          <FormSubmitButton color="primary" endIcon={<ReplayIcon fontSize="large" />} onClick={loadComments}>
            Load more
          </FormSubmitButton>
        </Box>
      )}
      {!isFirstLoad && loadingComments && <SpinnerBox />}
      {fetchCommentsError && <WarningMessage icon={<ErrorOutlineIcon color="primary" />} text={fetchCommentsError} />}
    </Box>
  );
};

export default PostCommentSection;
