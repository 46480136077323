import React, { useContext, useEffect, useRef, useState, Dispatch } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Link, useLocation } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import queryString from 'query-string';
import Container from '@material-ui/core/Container';
import MenuIcon from '@material-ui/icons/Menu';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Drawer from '@material-ui/core/Drawer';
import grey from '@material-ui/core/colors/grey';
import clsx from 'clsx';
import { darken, lighten } from '@material-ui/core/styles/colorManipulator';
import CloseIcon from '@material-ui/icons/Close';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Chip from '@material-ui/core/Chip';
import HomeIcon from '@material-ui/icons/Home';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import PaymentIcon from '@material-ui/icons/Payment';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import TuneIcon from '@material-ui/icons/Tune';
import WebIcon from '@material-ui/icons/Web';

import AthleteSearch from '../elements/AthleteSearch';
import { letterSpacing } from '../../theme/theme';
import urlHelpers, { getPathnameRoot } from '../../core/helpers/url';
import { capitalize } from '../../core/helpers/misc';
import domHelpers from '../../core/helpers/dom';
import { signOut } from '../../core/services/authSvc';
import { AuthState, authStore } from '../../core/stores/auth';
import { AppAction, appStore } from '../../core/stores/app';
import { gaEventActions, gaEventCategories, sendGaEvent } from '../../core/services/gaSvc';
import ActionConfirmationDialog from '../dialogs/ActionConfirmationDialog';
import {
  athletesRoute,
  becomeAthleteRoute,
  loginRoute,
  membershipsRoute,
  userSettingsRoute,
  signupRoute,
  userHomeRoute,
  athleteProfileEditRoute,
  athleteChannelRoute,
  athletePaymentsRoute,
  getAthleteProfileRouteByNickname,
  getAthleteProfileRouteById,
  fansRoute,
  academyRoute,
  aboutRoute,
} from '../../core/helpers/route';
import { getAthleteProfileRoute } from '../../core/helpers/route';
import { User, userRoles } from '../../core/graphql/types';
import { useTranslation } from 'react-i18next';

import { updateUser } from '../../core/services/userSvc';
import config from '../../config';

// const useStyles = makeStyles((theme) => ({
//   appHeaderRoot: {
//     opacity: 'inherit',
//     backgroundColor: 'white',
//     zIndex: theme.zIndex.drawer + 1,
//     '& a': {
//       textDecoration: 'none',
//     },
//   },
//   appHeaderWhite: {
//     backgroundColor: 'white',
//     borderBottom: `1px solid ${grey[300]}`,
//   },
//   appHeaderBlack: {
//     backgroundColor: 'black',
//   },
//   toolbarMobile: {
//     borderBottom: `1px solid ${theme.palette.divider}`,
//   },
//   toolbarContent: {
//     ...theme.mixins.toolbar,
//   },
//   loginButton: {
//     color: 'white',
//     letterSpacing: letterSpacing.minimal,
//     border: `solid 2px ${darken(theme.palette.primary.main, 1.0)}`,
//     '&:hover': {
//       border: `solid 2px ${darken(theme.palette.primary.main, 0.1)}`,
//     },
//   },
//   logo: {
//     display: 'block',
//     height: theme.typography.pxToRem(24),
//     filter: 'invert(63%) sepia(48%) saturate(632%) hue-rotate(350deg) brightness(105%) contrast(90%)',
//   },
//   mobileMenuLogo: {
//     height: theme.typography.pxToRem(18),
//   },
//   athleteSearchWrapper: {
//     [theme.breakpoints.down('md')]: {
//       paddingLeft: theme.spacing(2),
//       paddingRight: theme.spacing(2),
//       paddingBottom: theme.spacing(1),
//     },
//   },
//   menuIcon: {
//     padding: 0,
//   },
//   drawerPaper: {
//     height: '100%',
//   },
//   drawerList: {
//     paddingTop: 0,
//     paddingBottom: 0,
//   },
//   drawerListItem: {
//     height: theme.typography.pxToRem(62),
//     cursor: 'pointer',
//     paddingLeft: 0,
//     paddingRight: 0,
//   },
//   hoverableLink: {
//     letterSpacing: letterSpacing.minimal,
//     marginLeft: theme.typography.pxToRem(5),
//     marginRight: theme.typography.pxToRem(5),
//     '&:hover': {
//       color: `${lighten(theme.palette.primary.main, 0.1)} !important`,
//     },
//   },
//   hideLink: {
//     display: 'none',
//   },
//   athletesInputStyles: {
//     height: theme.typography.pxToRem(35),
//     borderRadius: theme.typography.pxToRem(9999),
//     color: 'black',
//     backgroundColor: 'white',
//     '&:hover': {
//       backgroundColor: 'white',
//     },
//   },
// }));

interface ConfirmationDialogConfig {
  title: string;
  description: string;
  hide?: (user: User) => boolean;
  action?: (user: User, appDispatch: Dispatch<AppAction>) => void;
}
export interface NavigationMenuItem {
  id: string;
  title: string;
  confirmationDialog?: ConfirmationDialogConfig;
  getRoute: (authState?: AuthState) => string;
  icon?: React.ReactNode;
  hasDivider?: boolean;
}

const fanNavigationItems: NavigationMenuItem[] = [
  {
    id: getPathnameRoot(becomeAthleteRoute),
    title: 'Create your channel',
    confirmationDialog: {
      title: 'Are you a top athlete?',
      description:
        'On Peakz you can create a Peakz Channel if you are an athlete going for the top in your sports. Are you?',
      hide: (user: User) => {
        // Do not show if the user already answered
        return (user.role !== userRoles.athlete && user.potentialAthlete === true) || user.potentialAthlete === false;
      },
      action: async (user: User, appDispatch: Dispatch<AppAction>) => {
        await updateUser(user.id, { potentialAthlete: true }, appDispatch, false);
      },
    },
    getRoute: () => becomeAthleteRoute,
    icon: <i className="fas fa-running" style={{ fontSize: '28px' }}></i>,
    hasDivider: true,
  },
  {
    id: getPathnameRoot(userHomeRoute),
    title: 'Home',
    getRoute: () => userHomeRoute,
    icon: <HomeIcon />,
  },
  {
    id: getPathnameRoot(membershipsRoute),
    title: 'Memberships',
    getRoute: () => membershipsRoute,
    icon: <CardMembershipIcon />,
    hasDivider: false,
  },
  {
    id: getPathnameRoot(userSettingsRoute),
    title: 'Settings',
    getRoute: () => userSettingsRoute,
    icon: <SettingsIcon />,
    hasDivider: false,
  },
];
export const athleteNavigationItems: NavigationMenuItem[] = [
  {
    id: getPathnameRoot(userHomeRoute),
    title: 'Home',
    getRoute: () => userHomeRoute,
    icon: <HomeIcon />,
    hasDivider: true,
  },
  {
    id: getPathnameRoot(athleteChannelRoute),
    title: 'Edit channel',
    getRoute: () => athleteChannelRoute,
    icon: <TuneIcon />,
    hasDivider: false,
  },
  {
    id: 'athleteProfile',
    title: 'Channel preview',
    getRoute: (authState?: AuthState) => {
      if (authState) {
        if (authState.userNickname) {
          return getAthleteProfileRouteByNickname(authState.userNickname);
        } else if (authState.user) {
          return getAthleteProfileRouteById(authState.user.userId);
        }
      }
      return '/home';
    },
    icon: <WebIcon />,
  },
  {
    id: getPathnameRoot(athleteProfileEditRoute),
    title: 'Profile',
    getRoute: () => athleteProfileEditRoute,
    icon: <AssignmentIndIcon />,
    hasDivider: false,
  },
  {
    id: getPathnameRoot(athletePaymentsRoute),
    title: 'Income',
    getRoute: () => athletePaymentsRoute,
    icon: <PaymentIcon />,
    hasDivider: false,
  },
  {
    id: getPathnameRoot(membershipsRoute),
    title: 'Memberships',
    getRoute: () => membershipsRoute,
    icon: <CardMembershipIcon />,
    hasDivider: false,
  },
  {
    id: getPathnameRoot(userSettingsRoute),
    title: 'Settings',
    getRoute: () => userSettingsRoute,
    icon: <SettingsIcon />,
    hasDivider: false,
  },
];

const PrivatePageMenuItem: React.FC<{ item: NavigationMenuItem }> = ({ item }: { item: NavigationMenuItem }) => {
  return (
    <>
      <ListItemIcon>{item.icon}</ListItemIcon>
      <ListItemText primary={item.title} />
    </>
  );
};

interface Props {
  width?: string; // injected by 'withWidth'
  toggleDark?: boolean;
}
const AppHeader: React.FC<Props> = ({ width, toggleDark }: Props) => {
  const theme = useTheme();
  const isSmallScreen = domHelpers.isSmallScreen(useMediaQuery, theme);
  const [, setSearchValue] = useState<User | null>(null);
  const history = useHistory();
  const location = useLocation();
  const [t] = useTranslation();
  //const useWhiteBg = false;
  const useWhiteBg = !['/', athletesRoute, academyRoute, fansRoute, '/policies', aboutRoute].includes(
    location.pathname,
  );

  const backgroundColorForBlackBackground = toggleDark ? '#424242' : '#000';
  const backgroundColorForWhiteBackground = toggleDark ? '#424242' : '#fff';
  // const colorForBlackBackground = toggleDark ? '#fff' : '#fff';
  // const colorForWhiteBackground = toggleDark ? '#fff' : '#000';

  const useStyles = makeStyles((theme) => ({
    appHeaderRoot: {
      opacity: 'inherit',
      backgroundColor: useWhiteBg ? 'white' : 'black',
      zIndex: theme.zIndex.drawer + 1,
      '& a': {
        textDecoration: 'none',
      },
    },
    appHeaderWhite: {
      backgroundColor: backgroundColorForWhiteBackground,
      borderBottom: `1px solid ${grey[300]}`,
    },
    appHeaderBlack: {
      backgroundColor: backgroundColorForBlackBackground,
    },
    toolbarMobile: {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    toolbarContent: {
      ...theme.mixins.toolbar,
    },
    loginButton: {
      color: 'white',
      letterSpacing: letterSpacing.minimal,
      border: `solid 2px ${darken(theme.palette.primary.main, 1.0)}`,
      '&:hover': {
        border: `solid 2px ${darken(theme.palette.primary.main, 0.1)}`,
      },
    },
    logo: {
      display: 'block',
      height: theme.typography.pxToRem(24),
      filter: 'invert(63%) sepia(48%) saturate(632%) hue-rotate(350deg) brightness(105%) contrast(90%)',
    },
    mobileMenuLogo: {
      height: theme.typography.pxToRem(18),
    },
    athleteSearchWrapper: {
      [theme.breakpoints.down('md')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(1),
      },
    },
    menuIcon: {
      padding: 0,
    },
    drawerPaper: {
      height: '100%',
    },
    drawerList: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    drawerListItem: {
      height: theme.typography.pxToRem(62),
      cursor: 'pointer',
      paddingLeft: 0,
      paddingRight: 0,
    },
    hoverableLink: {
      letterSpacing: letterSpacing.minimal,
      marginLeft: theme.typography.pxToRem(5),
      marginRight: theme.typography.pxToRem(5),
      '&:hover': {
        color: `${lighten(theme.palette.primary.main, 0.1)} !important`,
      },
    },
    hideLink: {
      display: 'none',
    },
    athletesInputStyles: {
      height: theme.typography.pxToRem(35),
      borderRadius: theme.typography.pxToRem(9999),
      color: 'black',
      backgroundColor: 'white',
      '&:hover': {
        backgroundColor: 'white',
      },
    },
  }));

  const classes = useStyles();
  const [authState, authDispatch] = useContext(authStore);
  const [appState, appDispatch] = useContext(appStore);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [initialized, setInitialized] = useState(false);
  const [privatePages, setPrivatePages] = useState<NavigationMenuItem[]>([]);
  const [logoutConfirmationDialogOpen, setLogoutConfirmationDialogOpen] = useState(false);
  const [confirmationDialogConfig, setConfirmationDialogConfig] = useState<ConfirmationDialogConfig | null>(null);
  const rootRef = useRef({ offsetHeight: 0 });
  const menuButtonRef = useRef(null);
  const publicPages = [
    { label: t('landing.for_athletes.title').toUpperCase(), route: athletesRoute },
    {
      label: t('landing.for_fans.title').toUpperCase(),
      route: fansRoute,
      hide: useMediaQuery(theme.breakpoints.down(1230)),
    },
    { label: t('landing.peakz_academy.title').toUpperCase(), route: academyRoute },
    {
      label: t('landing.about_peakz.title').toUpperCase(),
      route: aboutRoute,
      hide: useMediaQuery(theme.breakpoints.down(1451)),
    },
  ];
  const hideSearchBar = useMediaQuery(theme.breakpoints.down(1103));
  const isLogged = authState.user && authState.isSignedUp;
  const menuId = 'athlete-menu';
  let buttonsSize: 'small' | 'medium' | 'large';
  const handleMenuOpen = () => {
    setMenuAnchorEl(menuButtonRef.current);
    setDrawerOpen(true);
  };
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    setDrawerOpen(false);
  };
  const handleSignInClick = () => {
    const qsObj: any = queryString.parse(window.location.search);

    history.push({
      pathname: loginRoute,
      search: urlHelpers.objectToQueryString(qsObj),
    });
    handleMenuClose();
  };
  const handleCreateProfileClick = () => {
    sendGaEvent(gaEventCategories.onboarding, gaEventActions.click, 'HEADER - CREATE PROFILE');
    history.push({
      pathname: signupRoute,
    });
    handleMenuClose();
  };
  const handleLogoutClick = async () => {
    setMenuAnchorEl(null);
    setLogoutConfirmationDialogOpen(true);
  };
  const handleLogoutConfirmation = async () => {
    setLogoutConfirmationDialogOpen(false);
    handleMenuClose();
    // history.push('/');
    await signOut(authDispatch);
    window.location.replace(config.newHomeUrl);
  };
  const handleLogoutCancel = () => {
    setLogoutConfirmationDialogOpen(false);
  };
  const handleMenuPageClick = ({ route }: { route: string }) => {
    history.push(route);
    handleMenuClose();
  };
  const handleConfirmationDialogConfirm = () => {
    if (confirmationDialogConfig && confirmationDialogConfig.action && appState.user) {
      confirmationDialogConfig.action(appState.user, appDispatch);
    }
    setConfirmationDialogConfig(null);
  };
  const handleConfirmationDialogCancel = () => {
    setConfirmationDialogConfig(null);
  };
  const handleUserSearchSelect = (user: User) => {
    setSearchValue(user);
    history.push(getAthleteProfileRoute(user));
  };

  // Load user
  useEffect(() => {
    (async () => {
      if (authState.user) {
        setPrivatePages(authState.isAthlete ? athleteNavigationItems : fanNavigationItems);
        setInitialized(true);
        return;
      }
    })();
  }, [authState.user]);

  // responsive buttons size
  switch (width) {
    case 'xs':
      buttonsSize = 'small';
      break;
    case 'md':
      buttonsSize = 'medium';
      break;
    default:
      buttonsSize = 'large';
  }

  return (
    <>
      <AppBar
        className={clsx({
          [classes.appHeaderRoot]: true,
          [classes.appHeaderWhite]: useWhiteBg,
          [classes.appHeaderBlack]: !useWhiteBg,
        })}
        position="fixed"
        color="inherit"
        elevation={0}
        ref={rootRef}
      >
        {/*component={Container}*/}
        <Toolbar>
          <Grid className={classes.toolbarContent} container justifyContent="space-between" alignItems="center">
            {/* Left AppBar block */}
            <Grid item xs>
              <Grid container alignItems="center" spacing={1}>
                {/* Logo */}
                <Grid item>
                  <Link to={isLogged ? userHomeRoute : '/'}>
                    <img className={classes.logo} src="/static/images/logos/peakz_logo.svg" alt="logo" />
                  </Link>
                </Grid>

                {/* Public Pages - Desktop */}
                {authState.initialized && !isLogged && !isSmallScreen && (
                  <Grid item>
                    <Box ml={2} width={1}>
                      <Grid container spacing={1}>
                        {publicPages.map((p, i) => (
                          <Grid style={p.hide ? { display: 'none' } : {}} key={i} item>
                            {/* <Link to={p.route}> */}
                            <a href={config.newHomeUrl + p.route}>
                              <b
                                style={
                                  '/' + window.location.href.split('/')[3] === p.route
                                    ? { color: theme.palette.primary.main }
                                    : useWhiteBg
                                    ? { color: theme.palette.primary.main }
                                    : { color: darken(theme.palette.primary.main, 0.15) }
                                }
                                className={classes.hoverableLink}
                              >
                                {p.label}
                              </b>
                            </a>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Grid>

            {/* Right AppBar block */}
            {authState.initialized && (
              <>
                <Grid item xs ref={menuButtonRef}>
                  {/* Mobile */}
                  {isSmallScreen && (
                    <Grid container justifyContent="flex-end" alignItems="center" spacing={isSmallScreen ? 0 : 2}>
                      {authState.isAthlete && (
                        <Grid item>
                          <Chip
                            color="primary"
                            variant="outlined"
                            size={isSmallScreen ? 'small' : 'medium'}
                            label="athlete"
                          ></Chip>
                        </Grid>
                      )}
                      <Grid item>
                        <IconButton
                          aria-label="show more"
                          aria-controls={menuId}
                          aria-haspopup="true"
                          edge="end"
                          color="inherit"
                          disableTouchRipple
                          onClick={handleMenuOpen}
                        >
                          {/* <MenuIcon style={useWhiteBg ? { color: 'black' } : { color: 'white' }} color="inherit" /> */}
                          <MenuIcon color="inherit" />
                        </IconButton>
                      </Grid>
                    </Grid>
                  )}

                  {/* Desktop */}
                  {!isSmallScreen && (
                    <Grid container justifyContent="flex-end" alignItems="center" spacing={isSmallScreen ? 0 : 2}>
                      {/* Public */}
                      <Grid style={hideSearchBar ? { display: 'none' } : {}} item>
                        <AthleteSearch
                          styles={classes.athletesInputStyles}
                          value={null}
                          inverted={false}
                          placeHolder={t('global.find_athlete')}
                          onSelect={handleUserSearchSelect}
                        />
                      </Grid>
                      {!isLogged && (
                        <>
                          <Grid item>
                            <Button
                              style={useWhiteBg ? { display: 'none' } : {}}
                              className={classes.loginButton}
                              color="primary"
                              size={buttonsSize}
                              onClick={handleSignInClick}
                            >
                              <b>{t('global.login')}</b>
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              variant="contained"
                              color="primary"
                              size={buttonsSize}
                              onClick={handleCreateProfileClick}
                            >
                              <b>{t('global.create_channel')}</b>
                            </Button>
                          </Grid>
                        </>
                      )}

                      {/* Private */}
                      {isLogged && (
                        <>
                          {authState.isAthlete && (
                            <Grid item>
                              <Chip
                                color="primary"
                                variant="outlined"
                                size={isSmallScreen ? 'small' : 'medium'}
                                label="athlete"
                              ></Chip>
                            </Grid>
                          )}
                          {/* User name */}
                          <Grid style={!useWhiteBg ? { color: 'white' } : {}} item>
                            <Typography color="inherit" variant="body1">
                              {initialized ? appState.user?.name || authState.user?.attributes['email'] : ''}
                            </Typography>
                          </Grid>
                          {/* Menu */}
                          <Grid style={!useWhiteBg ? { color: 'white' } : {}} item>
                            <IconButton
                              aria-label="account of current user"
                              aria-controls={menuId}
                              aria-haspopup="true"
                              className={classes.menuIcon}
                              onClick={handleMenuOpen}
                              color="inherit"
                            >
                              {authState.isAthlete ? (
                                <AccountCircleIcon fontSize="large" />
                              ) : (
                                <AccountBoxIcon fontSize="large" />
                              )}
                            </IconButton>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  )}
                </Grid>
              </>
            )}
          </Grid>
        </Toolbar>
      </AppBar>

      {/* Logged in User Menu */}
      {isLogged && !isSmallScreen && (
        <Menu
          id={menuId}
          anchorEl={menuAnchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={Boolean(menuAnchorEl)}
          keepMounted
          onClose={handleMenuClose}
        >
          <div>
            {/* Private Pages */}
            {privatePages.map((item, i) => (
              <React.Fragment key={i}>
                <MenuItem
                  key={i}
                  onClick={() => {
                    if (
                      appState.user &&
                      item.confirmationDialog &&
                      (!item.confirmationDialog.hide || !item.confirmationDialog.hide(appState.user))
                    ) {
                      setConfirmationDialogConfig({
                        ...item.confirmationDialog,
                        action: async () => {
                          if (appState.user && item.confirmationDialog?.action) {
                            await item.confirmationDialog?.action(appState.user, appDispatch);
                          }
                          history.push(item.getRoute(authState));
                        },
                      });
                    } else {
                      history.push(item.getRoute(authState));
                    }

                    handleMenuClose();
                  }}
                >
                  <PrivatePageMenuItem item={item} />
                </MenuItem>
                {item.hasDivider && <Divider />}
              </React.Fragment>
            ))}

            {/* Logout */}
            <Divider />
            <MenuItem onClick={handleLogoutClick}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary="Log out" />
            </MenuItem>
          </div>
        </Menu>
      )}

      {/* ---------------------------------- Mobile Menu  ------------------------------------ */}
      {isSmallScreen && (
        <Drawer
          anchor="top"
          open={drawerOpen}
          transitionDuration={0}
          PaperProps={{
            classes: {
              root: classes.drawerPaper,
            },
          }}
          onClose={handleMenuClose}
        >
          {/* Drawer Header */}
          <Toolbar className={classes.toolbarMobile} component={Container}>
            {/* Drawer Header */}
            <Grid className={classes.toolbarContent} container justifyContent="space-between" alignItems="center">
              {/* Logo */}
              <Grid item>
                <Link to="/">
                  <img className={classes.logo} src="/static/images/logos/peakz_logo.svg" alt="logo" />
                </Link>
              </Grid>
              {/* Close Button */}
              <Grid item>
                <IconButton edge="end" onClick={handleMenuClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Toolbar>

          {/* Drawer Content */}
          <Container>
            {/* Private Content */}
            {isLogged && (
              <List className={classes.drawerList}>
                {/* Pages */}
                {privatePages.map((item, i) => (
                  <React.Fragment key={i}>
                    <ListItem
                      className={classes.drawerListItem}
                      onClick={() => {
                        history.push(item.getRoute(authState));

                        handleMenuClose();
                      }}
                    >
                      <PrivatePageMenuItem item={item} />
                    </ListItem>
                    {item.hasDivider && <Divider />}
                  </React.Fragment>
                ))}
                {/* Extra Items */}
                <ListItem key={'extra-1'} className={classes.drawerListItem} onClick={handleLogoutClick}>
                  <ListItemIcon>
                    <ExitToAppIcon />
                  </ListItemIcon>
                  <ListItemText primary="Log out" />
                </ListItem>
                <Divider key={'divider-last'} />
              </List>
            )}

            {/* Public Content */}
            {!isLogged && (
              <List className={classes.drawerList}>
                {/* Pages */}
                {publicPages.map((p, i) => (
                  <React.Fragment key={i}>
                    <ListItem className={classes.drawerListItem} onClick={handleMenuPageClick.bind(null, p)}>
                      <ListItemText primary={capitalize(p.label)} />
                    </ListItem>
                    <Divider key={'extra-0'} />
                  </React.Fragment>
                ))}

                {/* Extra Items */}
                <ListItem key={'extra-1'} className={classes.drawerListItem} onClick={handleSignInClick}>
                  <ListItemText primary="Log in" />
                </ListItem>
                <Divider />
                <ListItem key={'extra-2'} className={classes.drawerListItem} onClick={handleCreateProfileClick}>
                  <ListItemText primary="Create profile" />
                </ListItem>
                <Divider key={'extra-3'} />
              </List>
            )}
          </Container>
        </Drawer>
      )}

      {/* Logout confirmation */}
      {logoutConfirmationDialogOpen && (
        <ActionConfirmationDialog
          open={logoutConfirmationDialogOpen}
          title={''}
          description={'Are you sure you want to log out?'}
          onYesClick={handleLogoutConfirmation}
          onNoClick={handleLogoutCancel}
          onClose={handleLogoutCancel}
        />
      )}

      {/* Become an athlete confirmation */}
      {confirmationDialogConfig && (
        <ActionConfirmationDialog
          open={Boolean(confirmationDialogConfig)}
          title={confirmationDialogConfig.title}
          description={confirmationDialogConfig.description}
          descriptionAlign="left"
          onYesClick={handleConfirmationDialogConfirm}
          onNoClick={handleConfirmationDialogCancel}
          onClose={handleConfirmationDialogCancel}
        />
      )}
    </>
  );
};

export default AppHeader;
