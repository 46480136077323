import { Container, Box, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import { useTheme } from '@material-ui/core';
import { useMediaQuery } from '@material-ui/core';
import clsx from 'clsx';

import CTAButton from '../ui/CTAButton';
import domHelpers from '../../core/helpers/dom';
import { PeakzTheme, letterSpacing } from '../../theme/theme';

const useStyles = makeStyles((theme: PeakzTheme) => ({
  root: {
    minHeight: '100vh',
    height: 'auto',
    backgroundColor: 'black',
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    '& hr:last-child': {
      display: 'none',
    },
    '& a': {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightBold,
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    padding: theme.spacing(10, 0, 10, 0),
  },
  questionsBox: {
    marginLeft: '10%',
    marginRight: '10%',
    '& .MuiTypography-root': {
      letterSpacing: letterSpacing.minimal,
      paddingTop: theme.typography.pxToRem(30),
      color: theme.palette.primary.main,
    },
    '& hr': {
      marginTop: theme.typography.pxToRem(30),
    },
  },
  faqSection: {
    maxHeight: theme.typography.pxToRem(0),
    height: '0px',
    transition: 'all 1s ease-in',
    opacity: 0,
  },
  faqSectionOpen: {
    maxHeight: theme.typography.pxToRem(800),
    height: 'auto',
    opacity: 1,
  },
  faqSectionClosed: {
    height: theme.typography.pxToRem(0),
    overflow: 'hidden',
  },
}));

interface Props {
  FAQData: data[];
}

interface data {
  title: string;
  info: string;
  CTA: string | undefined;
  CTALink: string | undefined;
}

const FAQSection: React.FC<Props> = ({ FAQData }: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = domHelpers.isSmallScreen(useMediaQuery, theme);
  const [sectionIndex, setSectionIndex] = useState(-1);

  return (
    <Box className={classes.root}>
      <Container>
        <Box>
          <Typography style={{ letterSpacing: letterSpacing.wide }} align="center" variant="h4">
            <b>FAQ</b>
          </Typography>
          <Box className={classes.questionsBox} mt={5}>
            {FAQData.map((section, index) => {
              return (
                <React.Fragment key={`Peakz_FAQ_${section.title}`}>
                  <div
                    data-testid="faqTitle"
                    onClick={() => {
                      sectionIndex === index ? setSectionIndex(-1) : setSectionIndex(index);
                    }}
                  >
                    <Typography style={{ cursor: 'pointer' }} variant="h5">
                      {section.title}
                    </Typography>
                  </div>
                  <Box
                    data-testid="faqContent"
                    className={clsx({
                      [classes.faqSection]: true,
                      [classes.faqSectionOpen]: sectionIndex === index,
                      [classes.faqSectionClosed]: sectionIndex !== index,
                    })}
                  >
                    <Typography
                      dangerouslySetInnerHTML={{ __html: section.info }}
                      variant={isSmallScreen ? 'body2' : 'body1'}
                      style={{ color: 'white' }}
                    ></Typography>
                    {section.CTA && (
                      <Box display="flex" justifyContent="center" mt={5}>
                        <Link to={section.CTALink || ''} rel="noopener">
                          <CTAButton variant="contained" color="primary" size="large">
                            <b>{section.CTA}</b>
                          </CTAButton>
                        </Link>
                      </Box>
                    )}
                  </Box>
                  <hr />
                </React.Fragment>
              );
            })}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default FAQSection;
