import React, { useContext, useState, useEffect } from 'react';
import { makeRequired, makeValidate } from 'mui-rff';
import * as Yup from 'yup';
import { Form } from 'react-final-form';
import Grid from '@material-ui/core/Grid';

import PaperFormElem from '../components/ui/PaperFormElem';
import PaperForm from '../components/ui/PaperForm';
import SubmitButton from '../components/ui/SubmitButton';
import PaperFormActions from '../components/ui/PaperFormActions';
import FormTextField from '../components/ui/FormTextField';
import { removeNullAttributes } from '../core/helpers/misc';
import { User } from '../core/graphql/types';
import { updateUser } from '../core/services/userSvc';
import { appStore } from '../core/stores/app';
import { getAthleteProfileCompletion, UserProfileMissingAttribs } from '../core/helpers/user';

const formSchema = Yup.object().shape({
  instagramUrl: Yup.string().test('instagram', 'It must be an Instagram account', (val) => {
    try {
      return !val || val.startsWith('https://www.instagram.com/');
    } catch (e) {}

    return false;
  }),
  facebookUrl: Yup.string().test('facebook', 'It must be a Facebook account', (val) => {
    try {
      return !val || val.startsWith('https://www.facebook.com/');
    } catch (e) {}
    return false;
  }),
  youtubeUrl: Yup.string().test('youtube', 'It must be a YouTube channel', (val) => {
    try {
      return !val || val.startsWith('https://www.youtube.com/channel') || val.startsWith('https://youtube.be/');
    } catch (e) {}
    return false;
  }),
});

const validate = makeValidate(formSchema);
const required = makeRequired(formSchema);

export interface AthleteChannelSocialFormData {
  instagramUrl?: string;
  facebookUrl?: string;
  youtubeUrl?: string;
}

export interface AthleteChannelSocialUpdate extends AthleteChannelSocialFormData {
  instagramUrl?: string;
  facebookUrl?: string;
  youtubeUrl?: string;
}

interface Props {
  user: User;
  onUserUpdated?: (update: AthleteChannelSocialUpdate) => void;
}

const AthleteChannelSocial: React.FC<Props> = ({ user, onUserUpdated }: Props) => {
  const [, appDispatch] = useContext(appStore);
  const [submitting, setSubmitting] = useState(false);
  const initialValues = removeNullAttributes({
    instagramUrl: user?.instagramUrl,
    facebookUrl: user?.facebookUrl,
    youtubeUrl: user?.youtubeUrl,
  });
  const subscription = { pristine: true, invalid: true };
  const formId = 'account-channel-social-form';
  const [accountMissingAttribs, setAccountMissingAttribs] = useState<UserProfileMissingAttribs>({});
  const handleSubmitClick = async (values: AthleteChannelSocialFormData) => {
    const updateObj: any = { ...values };

    // Ensure empty strings are submitted
    Object.keys(initialValues).forEach((attrib) => {
      if (!updateObj.hasOwnProperty(attrib)) {
        updateObj[attrib] = '';
      }
    });

    if (updateObj) {
      setSubmitting(true);
      await updateUser(user.id, updateObj, appDispatch);
      setSubmitting(false);

      if (onUserUpdated) {
        onUserUpdated(updateObj);
      }
    }
  };

  useEffect(() => {
    const profileCompletion = getAthleteProfileCompletion(user);

    setAccountMissingAttribs(profileCompletion.missingAttribs);
  }, [user]);

  return (
    <Form
      onSubmit={handleSubmitClick}
      initialValues={initialValues}
      subscription={subscription}
      validate={validate}
      key={subscription as any}
      render={({ form, handleSubmit, pristine, invalid }) => (
        <form id={formId} onSubmit={handleSubmit} noValidate={true} autoComplete="new-password">
          <PaperForm
            actions={
              <PaperFormActions>
                <Grid container spacing={1} justifyContent="flex-end">
                  <Grid item>
                    <SubmitButton
                      disabled={invalid || submitting || pristine}
                      submitting={false}
                      color="default"
                      type="button" // to avoid the form being submitted
                      onClick={form.reset}
                    >
                      Cancel
                    </SubmitButton>
                  </Grid>
                  <Grid item>
                    <SubmitButton disabled={invalid || submitting || pristine} submitting={submitting}>
                      Save changes
                    </SubmitButton>
                  </Grid>
                </Grid>
              </PaperFormActions>
            }
          >
            {/* Instagram Url */}
            <PaperFormElem
              title="Instagram url"
              required={required.instagramUrl}
              missing={Boolean(accountMissingAttribs['instagramUrl'])}
            >
              <FormTextField
                id="instagramUrl"
                name="instagramUrl"
                autoFocus
                required={required.instagramUrl}
                disabled={submitting}
                margin="none"
              />
            </PaperFormElem>

            {/* Facebook Url */}
            <PaperFormElem
              title="Facebook url"
              required={required.facebookUrl}
              missing={Boolean(accountMissingAttribs['facebookUrl'])}
            >
              <FormTextField
                id="facebookUrl"
                name="facebookUrl"
                required={required.facebookUrl}
                disabled={submitting}
                margin="none"
              />
            </PaperFormElem>

            {/* Youtube Link */}
            <PaperFormElem
              title="YouTube link"
              required={required.youtubeUrl}
              missing={Boolean(accountMissingAttribs['youtubeUrl'])}
            >
              <FormTextField
                id="youtubeUrl"
                name="youtubeUrl"
                required={required.youtubeUrl}
                disabled={submitting}
                margin="none"
              />
            </PaperFormElem>
          </PaperForm>
        </form>
      )}
    />
  );
};

export default AthleteChannelSocial;
