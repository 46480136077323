/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput!, $condition: ModelUserConditionInput) {
    updateUser(input: $input, condition: $condition) {
      id
      name
      givenName
      familyName
      role
      gender
      birthdate
      country
      sport
      profileImgS3Key
      introVideoS3Key
      introVideoThumbS3Key
      introVideoUrl
      mission
      themeId
      instagramUrl
      facebookUrl
      youtubeUrl
      goals {
        id
        name
        level
        description
        link
      }
      achievements {
        id
        name
        level
        description
        link
        date
      }
      fundingGoals {
        id
        amount
        currency
        description
      }
      social {
        twitterRequestToken
        twitterRequestSecret
        twitterAccessToken
        twitterAccessSecret
        fbUserId
        fbAccessToken
      }
      potentialAthlete
      createdAt
      updatedAt
    }
  }
`;

export const createPost = /* GraphQL */ `
  mutation CreatePost($input: CreatePostInput!, $condition: ModelPostConditionInput) {
    createPost(input: $input, condition: $condition) {
      id
      authorId
      name
      description
      needsSubscription
      type
      tierId
      tier {
        userId
        name
        currency
        unitAmount
        createdAt
      }
      createdAt
      updatedAt
    }
  }
`;

export const deletePost = /* GraphQL */ `
  mutation DeletePost($input: DeletePostInput!, $condition: ModelPostConditionInput) {
    deletePost(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createFlashRequest = /* GraphQL */ `
  mutation CreateFlashRequest($input: CreateFlashRequestInput!, $condition: ModelFlashRequestConditionInput) {
    createFlashRequest(input: $input, condition: $condition) {
      createdAt
    }
  }
`;

export const createLike = /* GraphQL */ `
  mutation CreateLike($input: CreateLikeInput!, $condition: ModelLikeConditionInput) {
    createLike(input: $input, condition: $condition) {
      createdAt
    }
  }
`;

export const attachPaymentMethod = /* GraphQL */ `
  mutation AttachPaymentMethod($paymentMethodId: String!) {
    attachPaymentMethod(paymentMethodId: $paymentMethodId) {
      id
      type
      billing_details {
        address {
          city
          country
          line1
          line2
          postal_code
          state
        }
        email
        name
        phone
      }
      card {
        brand
        country
        exp_month
        exp_year
        last4
      }
      is_default
      created
      customer
    }
  }
`;

export const detachPaymentMethod = /* GraphQL */ `
  mutation DetachPaymentMethod($paymentMethodId: String!) {
    detachPaymentMethod(paymentMethodId: $paymentMethodId) {
      id
      type
      billing_details {
        address {
          city
          country
          line1
          line2
          postal_code
          state
        }
        email
        name
        phone
      }
      card {
        brand
        country
        exp_month
        exp_year
        last4
      }
      created
      customer
    }
  }
`;

export const subscribeToAthlete = /* GraphQL */ `
  mutation SubscribeToAthlete($athleteId: String!, $tierId: String!, $currency: String!, $taxRateId: String) {
    subscribeToAthlete(athleteId: $athleteId, tierId: $tierId, currency: $currency, taxRateId: $taxRateId)
  }
`;

export const cancelSubscription = /* GraphQL */ `
  mutation CancelSubscription($athleteId: String!) {
    cancelSubscription(athleteId: $athleteId)
  }
`;

export const createTier = /* GraphQL */ `
  mutation CreateTier($name: String!, $currency: String!, $unitAmount: Int!) {
    createTier(name: $name, currency: $currency, unitAmount: $unitAmount) {
      id
      userId
      name
      currency
      unitAmount
      createdAt
    }
  }
`;

export const upgradeToAthlete = /* GraphQL */ `
  mutation UpgradeToAthlete {
    upgradeToAthlete {
      name
      givenName
      familyName
      country
      id
      profileImgS3Key
      nickname
    }
  }
`;

export const updateUserNickname = /* GraphQL */ `
  mutation UpdateUserNickname($nickname: String!) {
    updateUserNickname(nickname: $nickname)
  }
`;

export const createPaymentsAccount = /* GraphQL */ `
  mutation CreatePaymentsAccount {
    createPaymentsAccount
  }
`;

export const sendEmailValidationEmail = /* GraphQL */ `
  mutation SendEmailValidationEmail {
    sendEmailValidationEmail
  }
`;

export const validateEmail = /* GraphQL */ `
  mutation ValidateEmail($token: String!) {
    validateEmail(token: $token) {
      value
      error
    }
  }
`;

export const adminReviewPost = /* GraphQL */ `
  mutation AdminReviewPost($postId: String!, $reviewStatus: String!, $reason: String) {
    adminReviewPost(postId: $postId, reviewStatus: $reviewStatus, reason: $reason)
  }
`;

export const adminPushNotification = /* GraphQL */ `
  mutation AdminPushNotification($to: String!, $ids: [String], $title: String!, $body: String!) {
    adminPushNotification(to: $to, ids: $ids, title: $title, body: $body)
  }
`;
