import React from 'react';
import { KeyboardDatePicker } from 'mui-rff';
import { makeStyles } from '@material-ui/core/styles';
import DateFnsAdapter from '@date-io/date-fns';

import globalStyles from '../../theme/globalStyles';

const useStyles = makeStyles((theme) => ({
  formDatePicker: { ...globalStyles(theme).formDatePicker },
}));

interface Props {
  id: string;
  name: string;
  required: boolean;
  margin?: 'none' | 'dense' | 'normal';
  [key: string]: any;
}
const FormDatePicker: React.FC<Props> = ({ id, name, required, margin = 'dense', ...props }: Props) => {
  const classes = useStyles();

  return (
    <KeyboardDatePicker
      id={id}
      name={name}
      className={classes.formDatePicker}
      disableToolbar={false}
      required={required}
      margin={margin}
      format="dd - MM - yyyy"
      dateFunsUtils={DateFnsAdapter}
      inputVariant="outlined"
      {...props}
    />
  );
};

export default FormDatePicker;
