import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import { useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CTAButton from '../../components/ui/CTAButton';
import { Link } from 'react-router-dom';
import { getRealHeight, letterSpacing } from '../../theme/theme';
import domHelpers from '../../core/helpers/dom';
import ProgressiveImage from 'react-progressive-image';
import { getOptimisedImgUrl } from '../../core/helpers/url';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    color: 'white',
    backgroundColor: 'black',
    height: getRealHeight('100vh'),
    width: '100%',
    paddingLeft: '0',
    marginLeft: '0',
  },
  gridContainer: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  gridItem: {
    display: 'flex',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down(1027)]: {
      display: 'none',
    },
  },
  halfCovering: {
    height: '50%',
  },
  overlay: {
    position: 'absolute',
    height: '100%',
    boxShadow: 'rgba(0, 0, 0, .75) 0 0 0 10000px inset',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
  },
  letterSpaced: {
    letterSpacing: letterSpacing.wide,
    [theme.breakpoints.down('sm')]: {
      letterSpacing: letterSpacing.tiny,
    },
  },
  containImg: {
    width: '100%',
    height: '100%',
  },
}));

const HeaderBlock: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(1026));
  const [t] = useTranslation();
  const isSmallScreen = domHelpers.isSmallScreen(useMediaQuery, theme);

  return (
    <div className={classes.root}>
      <Grid container className={classes.gridContainer}>
        <ProgressiveImage
          src={getOptimisedImgUrl('landing-header', 'mobile_home_header.png')}
          placeholder={getOptimisedImgUrl('landing-header', 'mobile_home_header.png', 'prog')}
        >
          {(src: string) => (
            <img
              style={isTablet ? { width: '100%', height: '100%' } : { display: 'none' }}
              src={src}
              alt="Header image"
            />
          )}
        </ProgressiveImage>
        <Grid item md={4} className={classes.gridItem}>
          <Grid direction="column" container className={classes.gridContainer}>
            <Grid item style={{ height: '60%' }} className={classes.gridItem}>
              <ProgressiveImage
                src={getOptimisedImgUrl('landing-header', 'home_desktop_header.png')}
                placeholder={getOptimisedImgUrl('landing-header', 'home_desktop_header.png', 'prog')}
              >
                {(src: string) => <img src={src} className={classes.containImg} alt="Header image" />}
              </ProgressiveImage>
            </Grid>
            <Grid item style={{ height: '40%' }} className={classes.gridItem}>
              <ProgressiveImage
                src={getOptimisedImgUrl('landing-header', 'home_desktop_header2.png')}
                placeholder={getOptimisedImgUrl('landing-header', 'home_desktop_header2.png', 'prog')}
              >
                {(src: string) => <img src={src} className={classes.containImg} alt="Header image" />}
              </ProgressiveImage>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={4} className={classes.gridItem}>
          <ProgressiveImage
            src={getOptimisedImgUrl('landing-header', 'home_desktop_header3.png')}
            placeholder={getOptimisedImgUrl('landing-header', 'home_desktop_header3.png', 'prog')}
          >
            {(src: string) => <img src={src} className={classes.containImg} alt="Header image" />}
          </ProgressiveImage>
        </Grid>
        <Grid item md={4} className={classes.gridItem}>
          <Grid direction="column" container className={classes.gridContainer}>
            <Grid item className={`${classes.halfCovering} ${classes.gridItem}`}>
              <ProgressiveImage
                src={getOptimisedImgUrl('landing-header', 'home_desktop_header4.png')}
                placeholder={getOptimisedImgUrl('landing-header', 'home_desktop_header4.png', 'prog')}
              >
                {(src: string) => <img src={src} className={classes.containImg} alt="Header image" />}
              </ProgressiveImage>
            </Grid>
            <Grid item className={`${classes.halfCovering} ${classes.gridItem}`}>
              <ProgressiveImage
                src={getOptimisedImgUrl('landing-header', 'home_desktop_header5.png')}
                placeholder={getOptimisedImgUrl('landing-header', 'home_desktop_header5.png', 'prog')}
              >
                {(src: string) => <img src={src} className={classes.containImg} alt="Header image" />}
              </ProgressiveImage>
            </Grid>
          </Grid>
        </Grid>
        <Box width={1} className={classes.overlay}>
          <Typography
            variant="h3"
            style={isSmallScreen ? { width: '90%' } : { width: '65%' }}
            className={classes.letterSpaced}
          >
            <b>{t('landing.home.header.title').toUpperCase()}</b>
          </Typography>
          <br />
          <Typography
            style={isSmallScreen ? { width: '90%', fontWeight: 'normal' } : { width: '65%' }}
            variant="h6"
            className={classes.letterSpaced}
            dangerouslySetInnerHTML={{ __html: t('landing.home.header.subtitle') }}
          ></Typography>
          <Box mt={5}>
            <Link to="/signup" rel="noopener">
              <CTAButton variant="contained" color="primary" size="large">
                <b>{t('global.create_channel')}</b>
              </CTAButton>
            </Link>
          </Box>
        </Box>
      </Grid>
    </div>
  );
};

export default HeaderBlock;
