import React from 'react';
import FreeOrPaidPostSelector from '../FreeOrPaidPostSelector';

interface Props {
  setPostNeedsSubscription: any;
}

const PostStep1: React.FC<Props> = ({ setPostNeedsSubscription }: Props) => {
  return <FreeOrPaidPostSelector onPostMonetizationSelect={setPostNeedsSubscription} />;
};

export default PostStep1;
