import React, { createContext, Dispatch, useReducer } from 'react';
import * as log from 'loglevel';
import { CognitoUser } from '@aws-amplify/auth';

import noop from '../helpers/noop';
import { isSignedUp } from '../helpers/user';

interface UserSession {
  idToken: {
    jwtToken: string;
    payload: {
      email: string;
      gender: number;
      birthdate: string;
      'custom:role': string;
      'cognito:username': string;
      'custom:signup_phase': string;
      'custom:sport': string;
      identities?: any[];
    };
  };
  refreshToken: {
    token: string;
  };
  accessToken: {
    jwtToken: string;
    payload: any;
  };
}
export interface UserAttributes {
  'custom:country': string;
  'custom:signup_phase': string;
  'custom:role': string;
  'custom:sport': string;
  'custom:user_id': string;
  gender: string;
  birthdate: string;
  email: string;
  identities: string;
  name: string;
  given_name: string;
  family_name: string;
  picture: string;
  sub: string;
  email_verified: boolean;
}
export interface AuthUser extends CognitoUser {
  userId: string;
  username: string;
  attributes: UserAttributes;
  signInUserSession: UserSession;
}
export interface UserInfo {
  userId: string;
  userIdentityId: string;
  id: string; // = userIdentityId
  username: string;
  attributes: UserAttributes;
}

export interface AuthState {
  initialized: boolean;
  user: AuthUser | null;
  userInfo: UserInfo | null;
  userNickname?: string;
  isSignedUp: boolean;
  isAthlete: boolean;
  isAdmin: boolean;
  isModerator: boolean;
  isGuest: boolean;
}
export interface AuthAction {
  type: string;
  user?: AuthUser;
  userInfo?: UserInfo;
  userNickname?: string;
}

const initialState: AuthState = {
  initialized: false,
  user: null,
  userInfo: null,
  isSignedUp: false,
  isAthlete: false,
  isAdmin: false,
  isModerator: false,
  isGuest: false,
};
const authStore = createContext<[AuthState, Dispatch<AuthAction>]>([initialState, noop]);
const { Provider } = authStore;
const reducer = (state: any, action: any) => {
  log.debug('auth action', action.type, action);

  switch (action.type) {
    case 'initializing':
      return { ...state, initialized: false };
    case 'initialized':
      return { ...state, initialized: true };
    case 'setUser':
      let user;
      let userInfo;

      if (action.user && action.userInfo) {
        const userId = action.user.attributes['custom:user_id'] || action.user.username; // users from 2020-10-30 will have 'custom:user_id'

        // Extend auth entities
        user = {
          ...action.user,
          userId: userId,
        };

        userInfo = {
          ...action.userInfo,
          userId: userId,
          userIdentityId: action.userInfo.id, // map id -> userIdentityId for clarity
        };
        log.debug('user (new)', user);
        log.debug('userInfo (new)', userInfo);
      }

      return {
        ...state,
        user: user,
        userInfo: userInfo,
        userNickname: action.userNickname,
        isSignedUp: user && isSignedUp(user),
        // AthUser is an athlete if it belongs to the "athletes" user group
        isAthlete: Boolean(
          user &&
            user.signInUserSession.idToken.payload['cognito:groups'] &&
            user.signInUserSession.idToken.payload['cognito:groups'].indexOf('athletes') >= 0,
        ),
        isAdmin: Boolean(
          user &&
            user.signInUserSession.idToken.payload['cognito:groups'] &&
            user.signInUserSession.idToken.payload['cognito:groups'].indexOf('admins') >= 0,
        ),
        isModerator: Boolean(
          user &&
            user.signInUserSession.idToken.payload['cognito:groups'] &&
            user.signInUserSession.idToken.payload['cognito:groups'].indexOf('moderators') >= 0,
        ),
        isGuest: Boolean(
          user &&
            user.signInUserSession.idToken.payload['cognito:groups'] &&
            user.signInUserSession.idToken.payload['cognito:groups'].indexOf('guests') >= 0,
        ),
        initialized: true,
      };
    case 'setUserNickname':
      return {
        ...state,
        userNickname: action.userNickname,
      };
    default:
      return state;
  }
};

const AuthStateProvider = ({ children }: { children: React.ReactNode }): any => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <Provider value={[state, dispatch]}>{children}</Provider>;
};

export { authStore, AuthStateProvider };
