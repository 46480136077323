function convertDateTime(datetime: string) {
  let dt = datetime.split('-').join('');
  dt = dt.split(':').join('');
  return dt;
}

function convertDuration(duration: string) {
  const secs = Date.parse(`1970-01-01T${duration}Z`) / 1000;
  const hours = Math.trunc(secs / 3600);
  const mins = (secs % 3600) / 60;
  return `PT${hours}H${mins}M`;
}

function generateTimestamp() {
  const now = new Date();
  now.setMilliseconds(0);
  let dt = now.toISOString();
  dt = dt.replace('.000', '');
  return dt;
}

// creates a .ics file object suitable for download
// note: don't forget do dletete it after usage
export function makeIcsFile(
  id: string,
  time: string,
  duration: string | null | undefined,
  title: string,
  description: string | null | undefined,
  url: string,
  reminderInterval: number,
) {
  const now = new Date();
  now.setMilliseconds(0);
  if (description) description = description.replace(/(?:\\[rn]|[\r\n])/g, '\\n');
  const ics =
    'BEGIN:VCALENDAR\r\n' +
    'VERSION:2.0\r\n' +
    'PRODID:peakz.com/dev\r\n' +
    'CALSCALE:GREGORIAN\r\n' +
    'BEGIN:VEVENT\r\n' +
    `UID:${id}\r\n` +
    `DTSTART:${convertDateTime(time)}\r\n` +
    (duration ? `DURATION:${convertDuration(duration)}\r\n` : '') +
    `DTSTAMP:${convertDateTime(generateTimestamp())}\r\n` +
    `SUMMARY:${title}\r\n` +
    (description ? `DESCRIPTION:${description}\r\n` : '') +
    `LOCATION:${url}\r\n` +
    (reminderInterval
      ? 'BEGIN:VALARM\r\n' +
        `UID:A1-${id}\r\n` +
        `TRIGGER:-PT${Math.trunc(reminderInterval / 60)}M\r\n` +
        'DESCRIPTION:Reminder\r\n' +
        'ACTION:DISPLAY\r\n' +
        'END:VALARM\r\n'
      : '') +
    'END:VEVENT\r\n' +
    'END:VCALENDAR';
  return ics;
}

// return amount of seconds to the scheduled time (which is a ISO 8601)
export const secondsToDateTime = (dt: string): number => {
  // const now = new Date();
  // const msecs1 = Date.parse(now);
  const msecs1 = Date.now();
  const msecs2 = Date.parse(dt);
  return (msecs2 - msecs1) / 1000;
};
