import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useTheme } from '@material-ui/core/styles';
import domHelpers from '../../core/helpers/dom';
import { Box, useMediaQuery } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { letterSpacing, PeakzTheme } from '../../theme/theme';
import { appStore } from '../../core/stores/app';
import { athletesSideMenuWidth } from './AthletesSideMenu';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { athletesRoute, academyRoute, fansRoute, aboutRoute } from '../../core/helpers/route';
import { useLocation } from 'react-router';
import { authStore } from '../../core/stores/auth';
import config from '../../config';
import LanguageSelector from '../elements/LanguageSelector';
import { useTranslation } from 'react-i18next';
import { capitalize } from '../../core/helpers/misc';
import CurrencyConfigurator from '../../containers/CurrencyConfigurator';
/*
const useStyles = makeStyles((theme: PeakzTheme) => ({
  root: {
    //backgroundColor: '#fff',
    //color: 'black',
    width: '100%',
    padding: theme.spacing(0, 5, 5),
    borderTop: `1px solid ${theme.palette.divider}`,
    '& .MuiTypography-root': {
      letterSpacing: letterSpacing.minimal,
    },
    '& a': {
      color: 'rgb(168, 168, 168)',
      textDecoration: 'none',
      '&:hover': {
        color: 'gray',
      },
    },
  },
  rootHasSideMenu: {
    paddingLeft: athletesSideMenuWidth,
  },
  whiteBackground: {
    backgroundColor: 'white',
    color: 'black',
    '& a': {
      color: '#4F4D4C',
      textDecoration: 'none',
      '&:hover': {
        color: 'black',
      },
    },
  },
  blackBackground: {
    backgroundColor: 'black',
    color: 'white',
    '& a': {
      color: '#4F4D4C',
      textDecoration: 'none',
      '&:hover': {
        color: 'gray',
      },
    },
  },
  downLine: {
    position: 'absolute',
    bottom: '0',
    right: '0',
    '& span': {
      margin: theme.typography.pxToRem(8),
    },
    '& p': {
      fontSize: theme.typography.pxToRem(12),
    },
    [theme.breakpoints.between('xs', 'md')]: {
      bottom: '-41px',
    },
  },
  alignFooterSections: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
}));
*/
interface Props {
  toggleDark?: boolean;
}

const AppFooter: React.FC<Props> = (Props) => {
  const backgroundColorForBlackBackground = Props.toggleDark ? '#424242' : '#000';
  const backgroundColorForWhiteBackground = Props.toggleDark ? '#424242' : '#fff';
  const colorForBlackBackground = Props.toggleDark ? '#fff' : '#fff';
  const colorForWhiteBackground = Props.toggleDark ? '#fff' : '#000';

  const anchorColorForBlackBackground = Props.toggleDark ? '#lightgray' : '#gray';
  const anchorColorForWhiteBackground = Props.toggleDark ? '#gray' : '#gray';

  const useStyles = makeStyles((theme: PeakzTheme) => ({
    root: {
      //backgroundColor: '#fff',
      //color: 'black',
      width: '100%',
      padding: theme.spacing(0, 5, 5),
      borderTop: `0px solid ${theme.palette.divider}`,
      '& .MuiTypography-root': {
        letterSpacing: letterSpacing.minimal,
      },
      '& a': {
        color: 'rgb(168, 168, 168)',
        textDecoration: 'none',
        '&:hover': {
          color: 'gray',
        },
      },
    },
    rootHasSideMenu: {
      paddingLeft: athletesSideMenuWidth,
    },
    whiteBackground: {
      backgroundColor: backgroundColorForWhiteBackground,
      color: colorForWhiteBackground,
      '& a': {
        color: anchorColorForWhiteBackground,
        textDecoration: 'none',
        '&:hover': {
          color: 'black',
        },
      },
    },
    blackBackground: {
      backgroundColor: backgroundColorForBlackBackground,
      color: colorForBlackBackground,
      '& a': {
        color: anchorColorForBlackBackground,
        textDecoration: 'none',
        '&:hover': {
          color: 'gray',
        },
      },
    },
    downLine: {
      position: 'absolute',
      bottom: '0',
      right: '0',
      '& span': {
        margin: theme.typography.pxToRem(8),
      },
      '& p': {
        fontSize: theme.typography.pxToRem(12),
      },
      [theme.breakpoints.between('xs', 'md')]: {
        bottom: '-41px',
      },
    },
    alignFooterSections: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
    },
  }));
  const classes = useStyles();
  const [authState] = useContext(authStore);
  const [t] = useTranslation();
  const theme = useTheme();
  const [appState] = useContext(appStore);
  const location = useLocation();
  const isSmallScreen = domHelpers.isSmallScreen(useMediaQuery, theme);
  //const useWhiteBg = false;
  const useWhiteBg = !['/', athletesRoute, academyRoute, fansRoute, '/policies', aboutRoute].includes(
    location.pathname,
  );
  console.log(useWhiteBg);
  const isExtraSmallScreen = domHelpers.isXtraSmallScreen(useMediaQuery, theme);

  const publicPages = [
    {
      label: capitalize(t('landing.for_fans.title')),
      route: fansRoute,
      show: useMediaQuery(theme.breakpoints.down(1230)),
    },
    {
      label: capitalize(t('appfooter.aboutsection.title')),
      route: aboutRoute,
      show: useMediaQuery(theme.breakpoints.down(1451)),
    },
  ];

  const footerLinks = {
    about: [
      {
        label: capitalize(t('landing.about_peakz.title')),
        link: '/about',
      },
      {
        label: capitalize(t('landing.peakz_academy.title')),
        link: '/academy',
      },
    ],
    athletes: [
      {
        label: 'Create channel',
        link: '/',
      },
      {
        label: 'How do I earn',
        link: '/',
      },
      {
        label: 'Community',
        link: '/',
      },
      {
        label: 'Only for athletes',
        link: '/',
      },
      {
        label: 'Success Stories',
        link: '/',
      },
      {
        label: 'Worldwide athletes',
        link: '/',
      },
      {
        label: '5 minutes a day',
        link: '/',
      },
    ],
    fans: [
      {
        label: 'Create channel',
        link: '/',
      },
      {
        label: 'How do I earn',
        link: '/',
      },
      {
        label: 'Scouting Athletes',
        link: '/',
      },
      {
        label: 'Why Peakz',
        link: '/',
      },
    ],
    news: [
      {
        label: 'Latest Blogs',
        link: '/',
      },
      {
        label: 'General Updates',
        link: '/',
      },
      {
        label: 'Peakz Academy',
        link: '/',
      },
    ],
  };

  return (
    // Root container
    <footer
      className={clsx({
        [classes.root]: true,
        [classes.whiteBackground]: useWhiteBg,
        [classes.blackBackground]: !useWhiteBg,
        [classes.rootHasSideMenu]: appState.sideMenuVisible && !isSmallScreen,
      })}
    >
      {/* Superior grid */}
      <Grid container>
        {/* Left footer container */}
        <Grid md={authState.isAthlete && useWhiteBg ? 4 : 3} sm={12} xs={12} item>
          <Grid alignItems="center" container direction="column" justifyContent="center">
            <Box
              p={authState.isAthlete && useWhiteBg && !isExtraSmallScreen ? 5 : 0}
              display="flex"
              justifyContent="center"
            >
              <Grid style={{ flexDirection: 'column', alignItems: 'center' }} item>
                <img
                  width={isExtraSmallScreen ? '190px' : '200px'}
                  height={isExtraSmallScreen ? '190px' : '200px'}
                  src={
                    useWhiteBg
                      ? `${config.assetBaseUrl}/static/images/logos/Icon_logo_orange.png`
                      : `${config.assetBaseUrl}/static/images/logos/Icon_logo_white.png`
                  }
                  alt="Peakz logo"
                />
              </Grid>
            </Box>
            <Grid style={{ alignItems: 'center' }} item>
              <Grid justifyContent="center" direction="row" container spacing={isSmallScreen ? 3 : 5}>
                <Grid md={4} item>
                  <Link rel="noopener" target="_blank" to={{ pathname: 'https://www.instagram.com/peakztv/' }}>
                    <img
                      width="40px"
                      src={
                        useWhiteBg && !Props.toggleDark
                          ? '/static/images/landing-header/footerinstagramblack.png'
                          : '/static/images/landing-header/instagramicon.png'
                      }
                      alt="Instagram"
                    />
                  </Link>
                </Grid>
                <Grid md={4} item>
                  <Link rel="noopener" target="_blank" to={{ pathname: 'https://www.facebook.com/PEAKZ2020/' }}>
                    <img
                      width="40px"
                      src={
                        useWhiteBg && !Props.toggleDark
                          ? '/static/images/landing-header/footerfbblack.png'
                          : '/static/images/landing-header/facebookicon.png'
                      }
                      alt="Facebook"
                    />
                  </Link>
                </Grid>
                <Grid md={4} item>
                  <Link rel="noopener" target="_blank" to={{ pathname: 'https://www.linkedin.com/company/peakz/' }}>
                    <img
                      width="40px"
                      src={
                        useWhiteBg && !Props.toggleDark
                          ? '/static/images/landing-header/footerlinkedinblack.png'
                          : '/static/images/landing-header/linkedinicon.png'
                      }
                      alt="LinkedIn"
                    />
                  </Link>
                </Grid>
              </Grid>
            </Grid>
            <Grid style={{ flexDirection: 'column' }} item>
              <Box mt={3}>
                <Typography align="center">{t('appfooter.logosubtitle').toUpperCase()}</Typography>
                <br />
                <Typography align="center">A.J.ERNSTRAAT 199</Typography>
                <Typography align="center">1083 GV AMSTERDAM </Typography>
                <Typography align="center">THE NETHERLANDS</Typography>
                <br />
                <Typography align="center">+31 (0)20 - 7652800</Typography>
                <Typography align="center">INFO@PEAKZ.COM</Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        {/* Right footer container */}
        <Grid
          md={authState.isAthlete && useWhiteBg ? 8 : 9}
          sm={12}
          xs={12}
          item
          style={{ paddingTop: theme.typography.pxToRem(50), position: 'relative' }}
        >
          <Box mb={isExtraSmallScreen ? 0 : 10}>
            <Grid spacing={isSmallScreen ? 0 : 10} justifyContent="flex-end" container>
              <Grid className={isSmallScreen ? classes.alignFooterSections : ''} md={4} sm={12} xs={12} item>
                <Box mt={5}>
                  <Typography style={{ letterSpacing: letterSpacing.minimal }}>
                    <b>{t('appfooter.aboutsection.title').toUpperCase()}</b>
                  </Typography>
                </Box>
                <Box mt={2}>
                  {footerLinks.about.map((data) => {
                    return (
                      // <Link key={data.label.replace(' ', '_')} to={data.link}>
                      <a key={data.label.replace(' ', '_')} href={config.newHomeUrl + data.link}>
                        <Typography>{data.label}</Typography>
                      </a>
                    );
                  })}
                </Box>
              </Grid>
              {publicPages[1].show && !isSmallScreen && !useWhiteBg && (
                <Grid className={isSmallScreen ? classes.alignFooterSections : ''} md={4} sm={12} xs={12} item>
                  <Box mt={5}>
                    <Typography style={{ letterSpacing: letterSpacing.minimal }}>
                      <b>PAGES</b>
                    </Typography>
                  </Box>
                  <Box mt={2}>
                    {publicPages.map((data) => {
                      return (
                        // <Link
                        <a
                          style={data.show ? { display: 'block' } : { display: 'none' }}
                          key={data.label.replace(' ', '_')}
                          // to={data.route}
                          href={config.newHomeUrl + data.route}
                        >
                          <Typography>{data.label}</Typography>
                        </a>
                      );
                    })}
                  </Box>
                </Grid>
              )}
              {/* <Grid className={isSmallScreen ? classes.alignFooterSections : ''} md={2} sm={6} xs={12} item>
                  <Box mt={5}>
                    <Typography style={{ letterSpacing: letterSpacing.minimal }}>
                      <b>FOR ATHLETES</b>
                    </Typography>
                  </Box>
                  <Box mt={2}>
                    {footerLinks.athletes.map((data, _) => {
                      return (
                        <Link key={data.label.replace(' ', '_')} to={data.link}>
                          <Typography>{data.label}</Typography>
                        </Link>
                      );
                    })}
                  </Box>
                </Grid> */}
              {/* <Grid className={isSmallScreen ? classes.alignFooterSections : ''} md={2} sm={6} xs={12} item>
                  <Box mt={5}>
                    <Typography style={{ letterSpacing: letterSpacing.minimal }}>
                      <b>FOR FANS</b>
                    </Typography>
                  </Box>
                  <Box mt={2}>
                    {footerLinks.fans.map((data, _) => {
                      return (
                        <Link key={data.label.replace(' ', '_')} to={data.link}>
                          <Typography>{data.label}</Typography>
                        </Link>
                      );
                    })}
                  </Box>
                </Grid> */}
              {/* <Grid className={isSmallScreen ? classes.alignFooterSections : ''} md={3} sm={6} xs={12} item>
                  <Box mt={5}>
                    <Typography style={{ letterSpacing: letterSpacing.minimal }}>
                      <b>NEWS</b>
                    </Typography>
                  </Box>
                  <Box mt={2}>
                    {footerLinks.news.map((data, _) => {
                      return (
                        <Link key={data.label.replace(' ', '_')} to={data.link}>
                          <Typography>{data.label}</Typography>
                        </Link>
                      );
                    })}
                  </Box>
                </Grid> */}
            </Grid>
          </Box>
          {/* Footer down line */}
          <Box
            display={isExtraSmallScreen ? 'none' : 'flex'}
            justifyContent={isSmallScreen ? 'center' : 'flex-end'}
            width={1}
            className={classes.downLine}
            mb={2}
          >
            <Box mt={2} display="flex" flexDirection="column" alignItems={isSmallScreen ? 'center' : 'flex-end'}>
              <Box display="flex">
                <Typography>
                  {/* <Link target="_blank" to="/docs/privacy-policy"> */}
                  <a target="_blank" href={config.newHomeUrl + '/docs/privacy-policy'} rel="noreferrer">
                    {t('appfooter.downline.privacy').toUpperCase()}
                  </a>
                  <span>|</span>
                </Typography>
                <Typography>
                  {/* <Link target="_blank" to="/docs/security-policy"> */}
                  <a target="_blank" href={config.newHomeUrl + '/docs/security-policy'} rel="noreferrer">
                    {t('appfooter.downline.policy').toUpperCase()}
                  </a>
                  <span>|</span>
                </Typography>
                <Typography>
                  {/* <Link target="_blank" to="/docs/terms-of-use"> */}
                  <a target="_blank" href={config.newHomeUrl + '/docs/terms-of-use'} rel="noreferrer">
                    {t('appfooter.downline.disclaimer').toUpperCase()}
                  </a>
                  <span>|</span>
                </Typography>
                <Typography>
                  {/* <Link target="_blank" to="/docs/cookie-statement"> */}
                  <a target="_blank" href={config.newHomeUrl + '/docs/cookie-statement'} rel="noreferrer">
                    {t('appfooter.downline.cookies').toUpperCase()}
                  </a>
                </Typography>
              </Box>
              <Box>
                <LanguageSelector justifyContent="flex-end" />
              </Box>
              <Box mt={0.5}>
                <CurrencyConfigurator useWhiteBg={useWhiteBg} />
              </Box>
            </Box>
          </Box>
          <Box
            mb={isSmallScreen ? 5 : 0}
            alignItems="center"
            flexDirection="column"
            display={isExtraSmallScreen ? 'flex' : 'none'}
          >
            <Box mt={5}>
              <Typography style={{ letterSpacing: letterSpacing.minimal }}>
                <b>LEGAL</b>
              </Typography>
            </Box>
            <Box mt={2}>
              {/* <Link target="_blank" to="/docs/privacy-policy"> */}
              <a target="_blank" href={config.newHomeUrl + '/docs/privacy-policy'} rel="noreferrer">
                <Typography> {t('appfooter.downline.privacy').toUpperCase()}</Typography>
              </a>
            </Box>
            <Box>
              {/* <Link target="_blank" to="/docs/security-policy"> */}
              <a target="_blank" href={config.newHomeUrl + '/docs/security-policy'} rel="noreferrer">
                <Typography> {t('appfooter.downline.policy').toUpperCase()}</Typography>
              </a>
            </Box>
            <Box>
              {/* <Link target="_blank" to="/docs/terms-of-use"> */}
              <a target="_blank" href={config.newHomeUrl + '/docs/terms-of-use'} rel="noreferrer">
                <Typography> {t('appfooter.downline.disclaimer').toUpperCase()}</Typography>
              </a>
            </Box>
            <Box>
              {/* <Link target="_blank" to={`/docs/cookie-statement`}> */}
              <a target="_blank" href={config.newHomeUrl + '/docs/cookie-statement'} rel="noreferrer">
                <Typography> {t('appfooter.downline.cookies').toUpperCase()}</Typography>
              </a>
            </Box>
            {/* Language selector */}
            <Box mt={5}>
              <LanguageSelector justifyContent="center" />
            </Box>
            <Box mt={1}>
              <CurrencyConfigurator useWhiteBg={useWhiteBg} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </footer>
  );
};

export default AppFooter;
