import React, { useContext, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import { useTranslation } from 'react-i18next';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Confetti from 'react-dom-confetti';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import BasicPageLayout from '../../components/layouts/BasicPageLayout';
import domHelpers from '../../core/helpers/dom';
import { User } from '../../core/graphql/types';
import { formatAmount } from '../../core/helpers/currency';
import CircularProgressWithLabel from '../../components/ui/CircularProgressWithLabel';
import { getAthleteProfileCompletion, getPayoutCurrency, UserProfileMissingAttribs } from '../../core/helpers/user';
import DashboardItem from '../../components/ui/DashboardItem';
import DashboardCounter from '../../components/ui/DashboardCounter';
import cacheHelpers from '../../core/helpers/cache';
import AthleteSearch from '../../components/elements/AthleteSearch';
import { createPostRoute, getAthleteProfileRoute, getBcastRoute } from '../../core/helpers/route';

import EmailValidator from '../../containers/EmailValidator';
import { appStore } from '../../core/stores/app';
import { convertToCurrency } from '../../core/services/currencySvc';
import BcastList from '../../components/elements/bcast/BcastList';
import { disableBroadcast } from '../../config';

const useStyles = makeStyles((theme) => ({
  createPostButton: {
    color: '#fff',
    fontSize: theme.typography.pxToRem(16),
    '&::after': {
      animation: '$sheen 0.8s 0.3s 2 forwards;',
      animationTimingFunction: 'ease',
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      background: 'linear-gradient(to bottom, transparent, rgba(255,255,255,0.5) 50%, transparent)',
      transform: 'rotateZ(60deg) translate(-2em, 400%)',
    },
    overflowX: 'hidden',
    overflowY: 'hidden',
  },
  '@keyframes sheen': {
    '100%': {
      transform: 'rotateZ(60deg) translate(0, -400%)',
    },
  },
}));

const confettiConfig = {
  angle: 90,
  spread: 180,
  startVelocity: 80,
  elementCount: 80,
  dragFriction: 0.2,
  duration: 6000,
  stagger: 0,
  width: '15px',
  height: '15px',
  perspective: '500px',
  colors: ['#f2a742', '#6FC1E4', '#78ff44', '#ff718d', '#fdff6a'],
};

const AthleteDashboardPage: React.FC = () => {
  const classes = useStyles();
  const [t] = useTranslation();
  const history = useHistory();
  const [appState] = useContext(appStore);
  const theme = useTheme();
  const isXtraSmallScreen = domHelpers.isXtraSmallScreen(useMediaQuery, theme);
  const [accountCompletionPct, setAccountCompletionPct] = useState(0);
  const [accountMissingAttribs, setAccountMissingAttribs] = useState<UserProfileMissingAttribs>({});
  const [sortedMissingAttribNames, setSortedMissingAttribNames] = useState<string[]>([]);
  const [missingFieldsAnchorEl, setMissingFieldsAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [isConfettiVisible, setIsConfettiVisible] = useState<boolean>(false);
  const [athleteSearchValue, setAthleteSearchValue] = useState<User | null>(null);
  const menuId = 'menu-id';
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMissingFieldsAnchorEl(event.currentTarget);
  };
  const handleMissingFieldsMenuClose = () => {
    setMissingFieldsAnchorEl(null);
  };
  const handleMissingFieldClick = ({ route }: { name: string; route: string }) => {
    setMissingFieldsAnchorEl(null);
    history.push(route);
  };
  const handleAthleteSearchSelect = (user: User) => {
    setAthleteSearchValue(user);
    history.push(getAthleteProfileRoute(user));
  };
  const handleCreatePostClick = () => {
    history.push(createPostRoute);
  };

  const handleCreateBcastClick = () => {
    if (disableBroadcast) return;
    console.log('create bcast');
    history.push(getBcastRoute('create'));
  };

  useEffect(() => {
    (async () => {
      if (appState.user) {
        const profileCompletion = getAthleteProfileCompletion(appState.user);

        setAccountCompletionPct(profileCompletion.completionPct);
        setAccountMissingAttribs(profileCompletion.missingAttribs);
        setSortedMissingAttribNames(
          Object.keys(profileCompletion.missingAttribs).sort(
            (attr1: string, attr2: string) =>
              profileCompletion.missingAttribs[attr1].pos - profileCompletion.missingAttribs[attr2].pos,
          ),
        );

        const showConfetti = cacheHelpers.load(cacheHelpers.keys.showConfetti);
        if (showConfetti) {
          setIsConfettiVisible(true);
          cacheHelpers.remove(cacheHelpers.keys.showConfetti);
        }
      }
    })();
  }, [appState.user]);

  return (
    <BasicPageLayout>
      <Box
        style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', pointerEvents: 'none' }}
        display="flex"
        justifyContent="center"
        width={1}
      >
        <Confetti active={isConfettiVisible} config={confettiConfig} />
      </Box>
      <Grid container spacing={2} direction="row-reverse">
        <Grid item xs={12} sm={4}>
          <Grid container spacing={2}>
            {/* Profile completion */}
            <Grid item xs={12}>
              <DashboardItem>
                <Grid container alignItems="center" justifyContent="center">
                  <Grid item xs={12} md={6}>
                    <Box width={1} display="flex" justifyContent="center">
                      <CircularProgressWithLabel
                        value={accountCompletionPct}
                        size={isXtraSmallScreen ? 70 : 90}
                        //thickness={22}
                        thickness={5}
                        textColor="textPrimary"
                        textVariant="h5"
                      />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box mt={1}>
                      <Typography variant="body1" align="center">
                        Your profile is not complete
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="center">
                      <Button aria-describedby={menuId} color="primary" onClick={handleClick}>
                        What's missing?
                      </Button>
                      <Menu
                        id={menuId}
                        anchorEl={missingFieldsAnchorEl}
                        keepMounted
                        open={Boolean(missingFieldsAnchorEl)}
                        onClose={handleMissingFieldsMenuClose}
                      >
                        {sortedMissingAttribNames.map((attribName) => (
                          <MenuItem
                            key={attribName}
                            onClick={handleMissingFieldClick.bind(null, accountMissingAttribs[attribName])}
                          >
                            {t(`models.user.${attribName}`)}
                          </MenuItem>
                        ))}
                      </Menu>
                    </Box>
                  </Grid>
                </Grid>
              </DashboardItem>
            </Grid>
            {/* Validate email */}
            <Grid item xs={12}>
              <DashboardItem>
                <Typography variant="h6" gutterBottom>
                  Email validation
                </Typography>
                <EmailValidator />
              </DashboardItem>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Grid container spacing={2} alignItems="stretch" direction="row">
            {/* Earnings */}
            <Grid item xs={6}>
              <DashboardCounter
                count={
                  appState.user && appState.currenciesConfig
                    ? formatAmount(
                        convertToCurrency(
                          appState.currenciesConfig,
                          appState.user.stats?.paidByScouts || 0,
                          'eur', // user.paidByScouts is always in EUR
                          getPayoutCurrency(appState.user, appState.currenciesConfig),
                        ),
                        getPayoutCurrency(appState.user, appState.currenciesConfig),
                      )
                    : '...'
                }
                label="per month"
              ></DashboardCounter>
            </Grid>
            {/* Scouts */}
            <Grid item xs={6}>
              <DashboardCounter
                count={(appState.user?.stats?.numScouts || 0).toString()}
                label="Fans"
              ></DashboardCounter>
            </Grid>
            {/* Create post */}
            <Grid item xs={12} md={6}>
              <DashboardItem>
                <Typography variant="h6" gutterBottom>
                  Create a post
                </Typography>
                <Button
                  className={classes.createPostButton}
                  variant="contained"
                  color="primary"
                  fullWidth
                  endIcon={<AddCircleOutlineIcon fontSize="large" />}
                  onClick={handleCreatePostClick}
                >
                  Create post
                </Button>
              </DashboardItem>
            </Grid>
            {/* Create bcast */}
            <Grid item xs={12} md={6}>
              <DashboardItem>
                <Typography variant="h6" gutterBottom>
                  Create a broadcast
                </Typography>
                <Button
                  className={classes.createPostButton}
                  variant="contained"
                  color="primary"
                  fullWidth
                  endIcon={<AddCircleOutlineIcon fontSize="large" />}
                  onClick={handleCreateBcastClick}
                  disabled={disableBroadcast}
                >
                  Create broadcast
                </Button>
              </DashboardItem>
            </Grid>
            {/* Search athletes */}
            <Grid item xs={12}>
              <DashboardItem>
                <AthleteSearch
                  value={athleteSearchValue}
                  inverted={false}
                  placeHolder="Scout athletes"
                  onSelect={handleAthleteSearchSelect}
                />
              </DashboardItem>
            </Grid>
            {/* Bcast list */}
            <Grid item xs={12}>
              <DashboardItem>
                <BcastList />
              </DashboardItem>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </BasicPageLayout>
  );
};

export default AthleteDashboardPage;
