/*
 * Using mui-rff
 * Check following link for more info: https://github.com/lookfirst/mui-rff
 * */

import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import * as Yup from 'yup';
import { makeRequired, makeValidate } from 'mui-rff';
import { Form } from 'react-final-form';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import Dialog from '@material-ui/core/Dialog/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import { removeNullAttributes } from '../../core/helpers/misc';
import { User } from '../../core/graphql/types';
import DialogTitle from '../../components/ui/DialogTitle';
import FormRow from '../../components/ui/FormRow';
import FormTextField from '../../components/ui/FormTextField';
import globalStyles from '../../theme/globalStyles';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submitBtnWrapper: {
    position: 'relative',
  },
  submitBtn: {
    margin: theme.spacing(2, 0, 1),
    height: 50,
  },
  submitBtnProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  charCounter: {
    position: 'absolute',
    right: theme.spacing(1),
    bottom: theme.spacing(1 / 2),
  },
  countryOption: {
    fontSize: 15,
    '& > span': {
      marginRight: theme.spacing(1),
      fontSize: 18,
    },
  },
  formTextField: { ...globalStyles(theme).formTextField },
  formSelect: { ...globalStyles(theme).formSelect },
  formAutocomplete: { ...globalStyles(theme).formAutocomplete },
  formDatePicker: { ...globalStyles(theme).formDatePicker },
}));

interface UserNameDialogProps {
  open: boolean;
  user?: User;
  title?: string;
  submitting?: boolean;
  showNickname?: boolean;
  showSocial?: boolean;
  showReachTop?: boolean;
  onSubmit: (data: UserNameDialogData) => void;
  onCancel: () => void;
}

export interface UserNameDialogData {
  givenName: string;
  familyName: string;
}

const formSchema = Yup.object().shape({
  givenName: Yup.string().required('Name is a required field.'),
  familyName: Yup.string().required('Last name is a required field.'),
});

const validate = makeValidate(formSchema);
const required = makeRequired(formSchema);

const UserNameDialog: React.FC<UserNameDialogProps> = ({
  open,
  user,
  title,
  submitting,
  onSubmit,
  onCancel,
}: UserNameDialogProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const formId = 'athlete-info-dialog-form';
  const subscription = { pristine: true, invalid: true };
  const initialValues = removeNullAttributes({
    givenName: user?.givenName,
    familyName: user?.familyName,
  });
  const handleSubmitClick = (values: UserNameDialogData) => {
    onSubmit(values);
  };
  const handleCancelClick = () => {
    onCancel();
  };

  return (
    <Form
      onSubmit={handleSubmitClick}
      initialValues={initialValues}
      subscription={subscription}
      validate={validate}
      key={subscription as any}
      render={({ handleSubmit, pristine, invalid }) => (
        <form
          id={formId}
          className={classes.form}
          onSubmit={handleSubmit}
          noValidate={true}
          autoComplete="new-password"
        >
          <Dialog
            open={open}
            maxWidth="sm"
            fullWidth={true}
            fullScreen={!isBigScreen}
            aria-labelledby="form-dialog-name"
          >
            <DialogTitle id="form-dialog-name" onClose={handleCancelClick}>
              {''}
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6">{title || 'Personal profile'}</Typography>
                  <Box mb={2}>
                    <Divider />
                  </Box>
                </Grid>

                {/* Given name */}
                <Grid item xs={12} sm={6}>
                  <FormRow label="First name">
                    <FormTextField
                      id="givenName"
                      name="givenName"
                      autoFocus
                      required={required.givenName}
                      disabled={submitting}
                    />
                  </FormRow>
                </Grid>
                {/* Family name */}
                <Grid item xs={12} sm={6}>
                  <FormRow label="Last name">
                    <FormTextField
                      id="familyName"
                      name="familyName"
                      required={required.familyName}
                      disabled={submitting}
                    />
                  </FormRow>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancelClick}>Cancel</Button>
              <Button
                form={formId}
                color="primary"
                type="submit"
                disabled={invalid || submitting || pristine}
                endIcon={submitting ? <CircularProgress size={24} /> : <ArrowForwardIcon fontSize="large" />}
              >
                Save changes
              </Button>
            </DialogActions>
          </Dialog>
        </form>
      )}
    />
  );
};

export default UserNameDialog;
