import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { AthleteFundingGoal, User } from '../core/graphql/types';
import AthleteFundingGoals from '../components/elements/AthleteFundingGoals';

export interface AthleteChannelFundingUpdate {
  fundingGoals?: AthleteFundingGoal[];
}

interface Props {
  user: User;
  onUserUpdated?: (update: AthleteChannelFundingUpdate) => void;
}

const AthleteChannelFunding: React.FC<Props> = ({ user, onUserUpdated }: Props) => {
  const [fundingGoalsDialogOpen, setFundingGoalsDialogOpen] = useState(false);
  const handleFundingGoalsEditClick = () => {
    setFundingGoalsDialogOpen(true);
  };
  const handleFundingGoalsEditCancel = () => {
    setFundingGoalsDialogOpen(false);
  };
  const handleFundingGoalsUpdated = (update: { fundingGoals: AthleteFundingGoal[] }) => {
    setFundingGoalsDialogOpen(false);

    if (onUserUpdated) {
      onUserUpdated(update);
    }
  };

  return (
    <div>
      <Box mb={2}>
        <Typography variant="h4" align="left" gutterBottom>
          <b>Funding goals</b>
        </Typography>
        <Typography variant="body1" align="left">
          Give your fans insights in how their payments are used for your athletic growth.
        </Typography>
      </Box>
      <AthleteFundingGoals
        user={user}
        editDialogOpen={fundingGoalsDialogOpen}
        showEditButton
        onEditClick={handleFundingGoalsEditClick}
        onUpdated={handleFundingGoalsUpdated}
        onCancel={handleFundingGoalsEditCancel}
      />
    </div>
  );
};

export default AthleteChannelFunding;
