import { v1 as uuidv1 } from 'uuid';
import { Storage } from 'aws-amplify';

import { User } from '../graphql/types';
import { awsVideoExports, baseUrl, imgBaseUrl, videoBaseUrl } from '../../config';
import { getAthleteProfileRoute, getPostRoute } from './route';
import config from '../../config';

const getMediaUrlFromSignedS3Url = (key: any, type: 'img' | 'video'): string => {
  const url = new URL(key.toString());

  return `${type === 'img' ? imgBaseUrl : videoBaseUrl}${url.pathname}${url.search}`;
};

const getMediaUrlFromS3Key = (key: string, type: 'img' | 'video'): string => {
  if (key.startsWith('public/') || key.startsWith('protected/')) {
    return `${type === 'img' ? imgBaseUrl : videoBaseUrl}/${key}`;
  } else {
    // for backwards compatibility
    return `${type === 'img' ? imgBaseUrl : videoBaseUrl}/public/${key}`;
  }
};

const getPostVodThumbUrl = (vodId: string): string => {
  return `https://${awsVideoExports.awsOutputVideo}/t/${vodId}Thumbnail.0000001.jpg`;
};

const getPostVodPreviewUrl = (vodId: string): string => {
  return `https://${awsVideoExports.awsOutputVideo}/p/${vodId}H.264.mp4`;
};

const getSignedS3Url = async (key: string, identityId: string): Promise<string> => {
  const cleanKey = key.startsWith('/') ? key.substr(1) : key;
  const level = cleanKey.startsWith('protected') ? 'protected' : 'public'; // Protected and Public only
  let s3Key = cleanKey.replace(`${level}/`, '');

  if (level === 'protected') {
    s3Key = s3Key.replace(`${identityId}/`, '');
  }

  const config: { level: 'protected' | 'public' | 'private' | undefined; identityId?: string } = { level: level };
  if (identityId) {
    config.identityId = identityId;
  }

  return (await Storage.get(s3Key, config)).toString();
};

const getCurrentUrl = (loc: { pathname: string; search: string }): string => {
  return `${window.location.origin}${loc.pathname}${loc.search}`;
};

const getPostShareUrl = (postId: string): string => {
  return `${baseUrl}${getPostRoute(postId)}`;
};

const getUserShareUrl = (user: User): string => {
  return `${baseUrl}${getAthleteProfileRoute(user)}`;
};

const getYoutubeVideoThumbUrl = (videoId: string): string => {
  return `http://i.ytimg.com/vi/${videoId}/hqdefault.jpg`;
};

// ----- Misc -----
const createUserProfileImgS3Key = (): string => {
  //const fileExtension = file ? file.type.split('/').pop() : 'jpg';
  const fileExtension = 'jpg';

  return `profile/avatar_${uuidv1()}.${fileExtension}`;
};
const createUserCoverImgS3Key = (): string => {
  //const fileExtension = file ? file.type.split('/').pop() : 'jpg';
  const fileExtension = 'jpg';

  return `profile/cover_${uuidv1()}.${fileExtension}`;
};
const createUserSponsorImgS3Key = (): string => {
  const fileExtension = 'jpg';

  return `profile/sponsor_${uuidv1()}.${fileExtension}`;
};
const createPostThumbS3Key = (): string => {
  const fileExtension = 'jpg';

  return `vod/${uuidv1()}_thumb.${fileExtension}`;
};

const getAthleteIntroVideoPath = (): { s3BasePath: string; fileNamePrefix: string } => {
  return { s3BasePath: 'videos/profile', fileNamePrefix: '' };
};

const objectToQueryString = (obj: any): string => {
  return Object.keys(obj)
    .map((key) => key + '=' + obj[key])
    .join('&');
};

export const getPathnameRoot = (pathname: string) => {
  return pathname.split('/')[1];
};

export const getOptimisedImgUrl = (folder: string, fileName: string, type?: 'prog' | 'comp'): string => {
  const prepend = type !== 'prog' ? 'compressed' : 'progressive-placeholders';
  return `${config.assetBaseUrl}/static/images/${folder}/transform/${prepend}/${fileName}`;
};

// Uploads images to be used on Broadcast promotion page
// Returns a key of the uploaded image that is written to the bcast record as is, for example:
// 'protected/eu-west-1:15220f3b-a1ec-4997-8289-50a5bbd88b6c/bcast/thumbnail/57b02d20-0c64-11ed-bee1-9d4053d0314f.jpg'
// Later, when the image is displayed, call function getMediaUrlFromS3Key() to compose full (public) url. For example,
// given the key above, it will return: 'http://challenge-cdn-dev.peakz.com/protected/eu-west-1:2db529bf-bbe6-4e4c-8458-89770cc24655/bcast/thumbnail/57b02d20-0c64-11ed-bee1-9d4053d0314f.jpg'
const createBcastImgS3Key = (type: 'thumbnail' | 'preview'): string => {
  const fileExtension = 'jpg';
  return `bcast/${type}/${uuidv1()}.${fileExtension}`;
};

export default {
  // ----- Urls ------
  getMediaUrlFromSignedS3Url,
  getPostVodThumbUrl,
  getPostVodPreviewUrl,
  getSignedS3Url,
  getCurrentUrl,
  getPostShareUrl,
  getUserShareUrl,
  getYoutubeVideoThumbUrl,
  // ----- Misc ------
  createUserProfileImgS3Key,
  createUserCoverImgS3Key,
  createUserSponsorImgS3Key,
  createPostThumbS3Key,
  getAthleteIntroVideoPath,
  getMediaUrlFromS3Key,
  objectToQueryString,
  getPathnameRoot,
  getOptimisedImgUrl,
  createBcastImgS3Key,
};
