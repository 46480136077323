import React from 'react';
// import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
//import theme from './theme';

function withRoot(Component: any) {
  function WithRoot(props: any) {
    return (
      // <ThemeProvider theme={theme}>
      <div>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <Component {...props} />
      </div>
      // </ThemeProvider>
    );
  }

  return WithRoot;
}

export default withRoot;
