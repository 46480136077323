import React, { useCallback, useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import Chip from '@material-ui/core/Chip';
import clsx from 'clsx';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { cancelSubscription, fetchSubscriptions } from '../../core/services/subscriptionsSvc';
import ActionConfirmationDialog from '../../components/dialogs/ActionConfirmationDialog';
import { formatDateTime } from '../../core/helpers/misc';
import PanelContent from '../../components/ui/PanelContent';
import { AthleteSubscription, SubscriptionStatus } from '../../core/graphql/types';
import { getAthleteProfileRoute } from '../../core/helpers/route';
import { authStore } from '../../core/stores/auth';

const useStyles = makeStyles(() => ({
  itemsList: {
    width: '100%',
  },
  itemPreview: {
    width: '100%',
  },
  evenListItem: {
    backgroundColor: '#fafafa',
  },
  chipSuccess: {
    backgroundColor: '#cbf4c9',
  },
}));

export interface Props {
  active: boolean;
}

const SubscriptionsPanel: React.FC<Props> = ({ active }: Props) => {
  const classes = useStyles();
  const [authState] = useContext(authStore);
  const [subscriptions, setSubscriptions] = useState<AthleteSubscription[] | undefined>();
  const [subscriptionToDelete, setSubscriptionToDelete] = useState<AthleteSubscription | null>(null);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const loadSubscriptions = useCallback(async (userId: string) => {
    setLoading(true);
    const subscriptionsRes = await fetchSubscriptions(userId);

    if (subscriptionsRes) {
      setSubscriptions(subscriptionsRes);
    }

    // Resolve athletes from the subscriptions and add them to the Payment subscriptions
    setSubscriptions(subscriptionsRes);

    setLoading(false);
  }, []);
  const [subscriptionsMenuAnchorElems, setSubscriptionsMenuAnchorElems] = React.useState<{
    [key: string]: HTMLElement;
  }>({});

  const handleSubscriptionMenuClick = (subscriptionId: string, event: React.MouseEvent<HTMLButtonElement>) => {
    setSubscriptionsMenuAnchorElems((anchors) => ({ ...anchors, [subscriptionId]: event.currentTarget }));
  };
  const handleCloseSubscriptionsMenu = () => {
    setSubscriptionsMenuAnchorElems({});
  };
  const handleCancelSubscriptionClick = (subscription: AthleteSubscription) => {
    handleCloseSubscriptionsMenu();
    setSubscriptionToDelete(subscription);
  };
  const handleCancelSubscriptionConfirmation = async () => {
    if (subscriptionToDelete && subscriptionToDelete.athleteId) {
      setSubmitting(true);
      await cancelSubscription(subscriptionToDelete.athleteId);
      setSubscriptions((subs) => {
        return (subs || []).map((s) => {
          if (s.athleteId === subscriptionToDelete.athleteId) {
            return { ...s, cancelAtPeriodEnd: true };
          }
          return s;
        });
      });
      setSubmitting(false);
    }
    setSubscriptionToDelete(null);
  };
  const handleCancelSubscriptionCancel = () => {
    setSubscriptionToDelete(null);
  };

  useEffect(() => {
    if (authState.user && active && !subscriptions && !loading) {
      loadSubscriptions(authState.user.userId);
    }
  }, [authState, active, loadSubscriptions]);

  return (
    <>
      {active && (
        <PanelContent loading={loading}>
          {subscriptions && subscriptions.length === 0 && (
            <Typography variant="body1">You have no memberships right now.</Typography>
          )}
          {subscriptions && subscriptions.length > 0 && (
            <List className={classes.itemsList} component="nav" dense={false}>
              {subscriptions.map((subscription: AthleteSubscription, i) => (
                <ListItem
                  key={subscription.athleteId}
                  className={clsx({
                    [classes.evenListItem]: i % 2 === 0,
                  })}
                >
                  <ListItemIcon>
                    <CardMembershipIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <>
                        {subscription.athlete ? (
                          <a href={getAthleteProfileRoute(subscription.athlete)}>{subscription.athlete?.name}</a>
                        ) : (
                          subscription.tier?.name
                        )}
                      </>
                    }
                    secondary={
                      subscription.status === SubscriptionStatus.canceled
                        ? `Cancelled on ${formatDateTime(new Date(subscription.updatedAt))}`
                        : `${subscription.cancelAtPeriodEnd ? 'Cancels' : 'Renews'} ${
                            subscription.currentPeriodEnd
                              ? formatDateTime(new Date(subscription.currentPeriodEnd * 1000))
                              : ' the last day of the month'
                          }`
                    }
                  />
                  <ListItemSecondaryAction>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item>
                        <Chip
                          className={clsx({
                            [classes.chipSuccess]:
                              subscription.status === SubscriptionStatus.active && !subscription.cancelAtPeriodEnd,
                          })}
                          label={subscription.cancelAtPeriodEnd ? 'canceled' : subscription.status}
                        />
                      </Grid>
                      <Grid item>
                        <IconButton
                          aria-label="more"
                          aria-controls="subscriptions-menu"
                          aria-haspopup="true"
                          onClick={handleSubscriptionMenuClick.bind(null, subscription.athleteId)}
                        >
                          <MoreHorizIcon />
                        </IconButton>
                        <Menu
                          id="subscriptions-menu"
                          anchorEl={subscriptionsMenuAnchorElems[subscription.athleteId]}
                          keepMounted
                          getContentAnchorEl={null}
                          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                          open={Boolean(subscriptionsMenuAnchorElems[subscription.athleteId])}
                          onClose={handleCloseSubscriptionsMenu}
                        >
                          <MenuItem
                            disabled={
                              subscription.cancelAtPeriodEnd || subscription.status === SubscriptionStatus.canceled
                            }
                            onClick={handleCancelSubscriptionClick.bind(null, subscription)}
                          >
                            <ListItemIcon>
                              <DeleteIcon />
                            </ListItemIcon>
                            <Typography variant="body1">Cancel subscription</Typography>
                          </MenuItem>
                        </Menu>
                      </Grid>
                    </Grid>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          )}
        </PanelContent>
      )}

      {/* Delete subscription dialog */}
      {subscriptionToDelete && (
        <ActionConfirmationDialog
          open={Boolean(subscriptionToDelete)}
          title={'Cancel subscription'}
          description="Are you sure you want to cancel this subscription?"
          submitting={submitting}
          onYesClick={handleCancelSubscriptionConfirmation}
          onNoClick={handleCancelSubscriptionCancel}
          onClose={handleCancelSubscriptionCancel}
        >
          <Paper className={classes.itemPreview} variant="outlined">
            <Box p={2}>
              <Typography variant="body1">{subscriptionToDelete.athlete?.name}</Typography>
            </Box>
          </Paper>
        </ActionConfirmationDialog>
      )}
    </>
  );
};

export default SubscriptionsPanel;
