import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import NewBlogsLayout from '../components/elements/NewBlogsLayout';
import FAQSection from '../components/elements/FAQSection';
import TextSwitcher from '../components/ui/TextSwitcher';
import AmbassadorsCarousel from '../components/ui/AmbassadorsCarousel';
import OverlayedHeaderText from '../components/ui/OverlayedHeaderText';
import AppHeaderPlaceHolder from '../components/layouts/AppHeaderPlaceHolder';
import { getOptimisedImgUrl } from '../core/helpers/url';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  athletesPageRoot: {
    width: '100%',
    '& a': {
      textDecoration: 'none',
    },
  },
}));

const FAQData = [
  {
    title: "I've never heard of Peakz. Why should I start my channel?",
    info: 'Peakz has just started, so maybe that’s why you didn’t know our name before. But now you do. And that’s a good thing. At Peakz you can create a steady income in only 15 minutes a day by starting your own channel. All you have to do is invite your fans to follow you at Peakz. Once they will, you’ll discover how Peakz can work for you. ',
    CTA: 'Start your channel',
    CTALink: '/signup',
  },
  {
    title: 'How do I create a steady income through Peakz?',
    info: 'You offer your fans a monthly subscription. Once they are fans you give them access to your channel where you post your exclusive content. No worries, we understand you are not a content creator. In the <a href="/academy">Peakz Academy</a> you’ll find all the insights to create great content in only 15 minutes a day. Content where your fans happily pay their monthly subscription for. ',
    CTA: '',
    CTALink: '',
  },
  {
    title: 'Does it only cost me 15 minutes a day?',
    info: 'We know that your main focus is your sports. That’s why Peakz makes it possible to create an income with only 15 minutes a day. That’s after you set up your channel. That cost you about 5 to 15 minutes. Mind you, the bare minimum for your channel is 15 minutes a day. The more time you spend on the right content, the more income you’ll create through your Peakz channel. Want to increase your income? Check out the <a href="/academy">Peakz Academy</a> for the best practices.',
    CTA: '',
    CTALink: '',
  },
  {
    title: 'How much will Peakz cost me?',
    info: 'Peakz is free to use and will stay free. It won’t cost you any money, you just gain money from it. You’ll receive a whopping 85% of the subscriptions of your fans. The remaining 15% goes to Peakz to cover the costs for payment, development of the platform and support. So we only benefit when you benefit.',
    CTA: '',
    CTALink: '',
  },
];

const AthletesPage: React.FC = () => {
  const classes = useStyles();
  const [t] = useTranslation();

  const headerContent = {
    progressiveArr: [
      getOptimisedImgUrl('landing-header', 'athletes_header.png'),
      getOptimisedImgUrl('landing-header', 'athletes_header.png', 'prog'),
    ],
    headerMinusTitle: t('landing.for_athletes.header.title'),
    headerSubtitle: t('landing.for_athletes.header.subtitle'),
    CTAText: 'Create your channel',
    CTALink: '/signup',
    subtitleTitle: t('landing.for_athletes.subheader.title'),
    subtitleSub: t('landing.for_athletes.subheader.description'),
    CTA2Text: 'Create your channel',
    CTA2Link: '/signup',
  };

  const switcherData = [
    {
      title: t('landing.for_athletes.text_switcher.titles.first'),
      slug: t('landing.for_athletes.text_switcher.labels.first'),
      text: t('landing.for_athletes.text_switcher.descriptions.first'),
    },
    {
      title: t('landing.for_athletes.text_switcher.titles.second'),
      slug: t('landing.for_athletes.text_switcher.labels.second'),
      text: t('landing.for_athletes.text_switcher.descriptions.second'),
    },
    {
      title: t('landing.for_athletes.text_switcher.titles.third'),
      slug: t('landing.for_athletes.text_switcher.labels.third'),
      text: t('landing.for_athletes.text_switcher.descriptions.third'),
    },
  ];

  return (
    <div className={classes.athletesPageRoot}>
      <AppHeaderPlaceHolder />

      {/* Header */}
      <OverlayedHeaderText content={headerContent} />

      {/* TextsSwitch block */}
      <TextSwitcher background="black" content={switcherData} buttonText="Start your channel" buttonLink="/signup" />

      {/* CarouselBlock */}
      <AmbassadorsCarousel
        title={t('landing.for_athletes.precarousel.title')}
        subtitle={t('landing.for_athletes.precarousel.description')}
      />

      {/* FAQ */}
      <FAQSection FAQData={FAQData} />

      {/* Blogs */}
      <NewBlogsLayout />
    </div>
  );
};

export default AthletesPage;
