import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Toolbar from '@material-ui/core/Toolbar';
import WebIcon from '@material-ui/icons/Web';
import SettingsIcon from '@material-ui/icons/Settings';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Tooltip from '@material-ui/core/Tooltip';
import Fab from '@material-ui/core/Fab';
import { useHistory, useLocation } from 'react-router-dom';

import { appStore } from '../../core/stores/app';
import { createPostRoute, getAthleteProfileRoute, userSettingsRoute } from '../../core/helpers/route';
import { authStore } from '../../core/stores/auth';
import { athleteNavigationItems, NavigationMenuItem } from './AppHeader';
import { updateUser } from '../../core/services/userSvc';
import UserNameDialog, { UserNameDialogData } from '../../containers/dialogs/UserNameDialog';
import EditableUserBasics from '../../containers/EditableUserBasics';

export const athletesSideMenuWidth = 240;
const useStyles = makeStyles((theme) => ({
  sideMenuRoot: {
    width: athletesSideMenuWidth,
    flexShrink: 0,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  drawerPaper: {
    width: athletesSideMenuWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  actionItem: {
    '&:hover .MuiTypography-root': {
      opacity: 1,
    },
    '& .MuiTypography-root': {
      opacity: 0,
    },
  },
  actionIcon: {
    boxShadow: 'none',
  },
  tooltip: {
    margin: 0,
  },
}));

const CloseTooltip = withStyles({
  tooltipPlacementBottom: {
    margin: '8px 0',
  },
})(Tooltip);

interface Props {
  title?: string;
}

export const actions = {
  profile: 'profile',
  createPost: 'createPost',
  settings: 'settings',
};
const AthletesSideMenu: React.FC<Props> = ({}: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const { pathname } = useLocation();
  const [appState, appDispatch] = useContext(appStore);
  const [authState] = useContext(authStore);
  const [submitting, setSubmitting] = useState(false);
  const [currentMenuItemId, setCurrentMenuItemId] = useState('');
  const [userNameDialogOpen, setUserNameDialogOpen] = useState(false);
  const handleActionClick = (action: string) => {
    if (appState.user) {
      switch (action) {
        case actions.profile:
          history.push(getAthleteProfileRoute(appState.user));
          break;
        case actions.createPost:
          history.push(createPostRoute);
          break;
        case actions.settings:
          history.push(userSettingsRoute);
          break;
      }
    }
  };
  const handleMenuItemClick = (item: NavigationMenuItem) => {
    setCurrentMenuItemId(item.id);
    history.push(item.getRoute(authState));
  };
  const handleUserNameSubmit = async (data: UserNameDialogData) => {
    if (appState.user) {
      const updateObj = { ...data, name: `${data.givenName} ${data.familyName}` };

      setSubmitting(true);
      await updateUser(appState.user.id, updateObj, appDispatch);
      setSubmitting(false);
      setUserNameDialogOpen(false);
    }
  };
  const handleUserNameCancel = () => {
    setUserNameDialogOpen(false);
  };

  useEffect(() => {
    const pathRoot = pathname.split('/')[1];
    const menuItem = athleteNavigationItems.find((item) => item.id === pathRoot);

    if (menuItem) {
      setCurrentMenuItemId(menuItem.id);
    } else {
      setCurrentMenuItemId(athleteNavigationItems[0].id);
    }
  }, [pathname]);

  return (
    <Drawer
      className={classes.sideMenuRoot}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Toolbar />
      <div className={classes.drawerContainer}>
        <Box width={1} mt={3} mb={3}>
          <EditableUserBasics user={appState.user} />
          {/* Actions */}
          <Box width={1} mt={2} pl={2} pr={2}>
            <Grid container justifyContent="space-around">
              <Grid className={classes.actionItem} item>
                <CloseTooltip placement="bottom" title="Channel preview" aria-label="Channel preview" arrow>
                  <Fab
                    size="small"
                    className={classes.actionIcon}
                    onClick={handleActionClick.bind(null, actions.profile)}
                  >
                    <WebIcon />
                  </Fab>
                </CloseTooltip>
              </Grid>
              <Grid className={classes.actionItem} item>
                <CloseTooltip placement="bottom" title="Create post" aria-label="Create post" arrow>
                  <Fab
                    size="small"
                    className={classes.actionIcon}
                    onClick={handleActionClick.bind(null, actions.createPost)}
                  >
                    <AddCircleOutlineIcon />
                  </Fab>
                </CloseTooltip>
              </Grid>
              <Grid className={classes.actionItem} item>
                <CloseTooltip placement="bottom" title="Settings" aria-label="Settings" arrow>
                  <Fab
                    size="small"
                    className={classes.actionIcon}
                    onClick={handleActionClick.bind(null, actions.settings)}
                  >
                    <SettingsIcon />
                  </Fab>
                </CloseTooltip>
                {/*<Box width={1} display="flex" justifyContent="center">*/}
                {/*  <IconButton>*/}
                {/*    <SettingsIcon />*/}
                {/*  </IconButton>*/}
                {/*</Box>*/}
                {/*<Box width={1} mt={-0.5}>*/}
                {/*  <Typography variant="caption">Settings</Typography>*/}
                {/*</Box>*/}
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Divider />
        <List disablePadding>
          {athleteNavigationItems.map((item) => (
            <div key={item.id}>
              <ListItem button selected={item.id === currentMenuItemId} onClick={handleMenuItemClick.bind(null, item)}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.title} />
              </ListItem>
              {item.hasDivider && <Divider />}
            </div>
          ))}
        </List>
      </div>

      {/* Athlete Info Dialog */}
      {userNameDialogOpen && appState.user && (
        <UserNameDialog
          open={userNameDialogOpen}
          user={appState.user}
          submitting={submitting}
          onSubmit={handleUserNameSubmit}
          onCancel={handleUserNameCancel}
        />
      )}
    </Drawer>
  );
};

export default AthletesSideMenu;
