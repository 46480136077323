// Amazon IVS Player SDK Wrapper component
// It is used for playing broadcasting streams and broadcasting recorder VOD file.
// Todo: Replace existing Carlo's component (added small changes).
// Todo: ? Need to discard Arthuro's commit: https://github.com/Peakz-Scoutfunding/peakz-web/commit/f41fe3e648d9de563f9498b5903e34cd1216cdf3 ?

import { CircularProgress, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PlayerError from './PlayerError';

// try several times to start playing live stream (if IVS channel is not
// ready yet, IVS component immediately throws error)
// there is one second pause between each try
const LIVE_STREAM_RETRIES = 5;

// @ts-ignore
const IVSPackage = window.IVSPlayer;

interface Props {
  playbackUrl: string;
  isLiveStream: boolean;
}

const heightBreakPoints = ['300px', '250px', '190px'];

const useStyles = makeStyles((theme) => ({
  video: {
    transition: 'all .2s',
    width: '100%',
    height: '100%',
  },
  emptyHeightBreakPoints: {
    padding: theme.spacing(1),
    height: heightBreakPoints[0],
    [theme.breakpoints.down('sm')]: {
      height: heightBreakPoints[1],
    },
    [theme.breakpoints.down('xs')]: {
      height: heightBreakPoints[2],
    },
  },
}));

const IVSPlayer: React.FC<Props> = (props: Props) => {
  const playbackUrl = props.playbackUrl || '';
  const isLiveStream = props.isLiveStream;

  const classes = useStyles();
  const videoRef = React.useRef(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (!IVSPackage.isPlayerSupported) {
      setLoading(false);
      setError('IVS Player is not supported in this browser');
      return;
    }

    let retry = 0;

    const player = IVSPackage.create();
    const PlayerState = IVSPackage.PlayerState;
    const PlayerEventType = IVSPackage.PlayerEventType;
    player.addEventListener(PlayerState.READY, () => {
      setLoading(false);
      player.attachHTMLVideoElement(videoRef.current);
      player.play();
    });
    player.addEventListener(PlayerState.PLAYING, () => {
      // todo: unmute() can't be done automatically
      // https://developer.chrome.com/blog/autoplay/
      player.setMuted(false);
    });
    player.addEventListener(PlayerState.ENDED, () => {
      console.log('ENDED');
    });

    player.addEventListener(PlayerEventType.ERROR, (err: any) => {
      if (retry < LIVE_STREAM_RETRIES) {
        retry = retry + 1;
        console.log('Stream playing retry #', retry);
        setTimeout(() => {
          player.load(playbackUrl);
        }, 1000);
        return;
      }
      setLoading(false);
      setError(JSON.stringify(err));
    });

    player.load(playbackUrl);

    return () => {
      if (player) {
        player.delete();
      }
    };
  }, []);

  // on Safari, position of video zooming controls is top left,
  // the same where we want our "LIVE" mark, so move it right on Safari:
  const [isSafari, setIsSafari] = useState(false);

  useEffect(() => {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    setIsSafari(isSafari);
  }, []);

  return (
    <div
      style={{
        position: 'absolute',
        width: '100%',
        height: '100%',
      }}
    >
      {loading && !error && (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'black',
          }}
        >
          <CircularProgress style={{ color: 'white' }} />
        </div>
      )}
      {!loading && !error && (
        <>
          {isLiveStream && (
            <div
              style={{
                position: 'absolute',
                top: '1rem',
                left: isSafari ? 'unset' : '1rem',
                right: isSafari ? '1rem' : 'unset',
                padding: '0.3rem 0.5rem',
                borderRadius: '0.15rem',
                backgroundColor: 'red',
                color: 'white',
                textShadow: '0 0 3px black',
                fontWeight: 'bold',
                paddingLeft: '0.5rem',
                paddingRight: '0.5rem',
                zIndex: 100,
              }}
            >
              LIVE
            </div>
          )}
          <video
            className={classes.video}
            ref={videoRef}
            controls
            autoPlay
            muted
            playsInline
            style={{ objectFit: 'cover' }}
          />
        </>
      )}
      {error && <PlayerError text={isLiveStream ? "Can't Play Stream" : "Can't Play Video"} error={error} />}
    </div>
  );
};

export default IVSPlayer;
