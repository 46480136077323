import { API } from 'aws-amplify';
import { getViewerCurrency, getCurrenciesConfig as getCurrenciesConfigQry } from '../graphql/customQueries';
import { GRAPHQL_AUTH_MODE, CurrenciesConfig } from '../graphql/types';

let currencyConfig: CurrenciesConfig;
export const getCurrenciesConfig = async (): Promise<CurrenciesConfig> => {
  // No need to do this request more than once
  if (!currencyConfig) {
    const res: any = await API.graphql({
      query: getCurrenciesConfigQry,
      variables: {},
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });

    currencyConfig = res.data.getCurrenciesConfig;
  }

  return currencyConfig;
};

export const getClientCurrency = async (): Promise<string> => {
  const res: any = await API.graphql({
    query: getViewerCurrency,
    variables: {},
    authMode: GRAPHQL_AUTH_MODE.API_KEY,
  });

  return res.data.getViewerCountry || '';
};

export const convertToCurrency = (
  currenciesConfig: CurrenciesConfig,
  value: number,
  srcCcyCode: string,
  dstCcyCode: string,
) => {
  if (srcCcyCode === dstCcyCode) {
    return value;
  }

  console.log(value, srcCcyCode, dstCcyCode);
  const bufferPct = 0.045;
  const srcCcyIdx = currenciesConfig.currencies.findIndex((c) => c.code === srcCcyCode);
  const dstCcyIdx = currenciesConfig.currencies.findIndex((c) => c.code === dstCcyCode);

  if (srcCcyIdx >= 0 && dstCcyIdx >= 0) {
    const conversionRatio = currenciesConfig.conversionsTable[srcCcyIdx][dstCcyIdx];

    if (conversionRatio) {
      const roundCents = ['nok', 'dkk', 'sek'].includes(dstCcyCode) ? 100 : 50;
      let convertedValue = value * conversionRatio;

      convertedValue = Math.ceil((convertedValue + convertedValue * bufferPct) / roundCents) * roundCents;

      return convertedValue;
    }
  }

  return -1;
};

export default {
  // get
  getCurrenciesConfig,
  getClientCurrency,
};
