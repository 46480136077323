import {
  unstable_createMuiStrictModeTheme as createMuiTheme, // It avoids "findDOMNode is deprecated in StrictMode warning". material-ui v5 will solve it
  responsiveFontSizes,
  Theme,
} from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import { Palette } from '@material-ui/core/styles/createPalette';

const fontFamiliyHeadlines = 'Montserrat, Helvetica, Arial';
const fontFamily = 'Roboto, Helvetica, Arial';
//const fontFamiliyHeadlines = 'ActivGrotesk, sans-serif, Arial';
//const fontFamily = 'ActivGrotesk, sans-serif, Arial';
const themePalette = {
  spacing: 8,
  primary: {
    light: '#f3b853', // '#fc6747',
    main: '#f2a742', // '#eb5636',
    dark: '#e19631', // '#da4525',
    // contrastText: '#fff',
  },
  secondary: {
    light: '#97cfe5',
    main: '#6FC1E4',
    dark: '#29b2e8',
    // contrastText: '#fff',
  },
  error: {
    light: 'rgb(204, 50, 63)',
    main: 'rgb(204, 50, 63)',
    dark: 'rgb(204, 50, 63)',
  },
  text: {
    primary: 'rgb(36, 30, 18)',
    secondary: 'rgb(112, 108, 100)',
  },
  background: {
    default: '#ffffff',
  },
  peakz: {
    gray: '#f2f2f2',
    pale: '#fdf1e1',
  },
};
const themeTypography = {
  fontFamily: fontFamily,
  fontSize: 14,
  color: '#0c0c0c',
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 800,
};

export interface PeakzPalette extends Palette {
  peakz: any;
}
export interface PeakzTheme extends Theme {
  palette: PeakzPalette;
}

const baseTheme = createMuiTheme({
  palette: themePalette,
  typography: themeTypography,
  overrides: {
    MuiTypography: {
      h1: {
        fontFamily: fontFamiliyHeadlines,
      },
      h2: {
        fontFamily: fontFamiliyHeadlines,
        fontWeight: themeTypography.fontWeightBold,
      },
      h3: {
        fontFamily: fontFamiliyHeadlines,
        fontWeight: themeTypography.fontWeightBold,
        '@media (max-width:960px)': {
          fontSize: '1.4rem',
        },
      },
      h4: {
        fontFamily: fontFamiliyHeadlines,
        '@media (max-width:960px)': {
          fontSize: '1.3rem',
        },
      },
      h5: {
        fontFamily: fontFamiliyHeadlines,
        fontSize: '1.3rem !important',
        '@media (max-width:960px)': {
          fontSize: '0.9rem !important',
        },
      },
      h6: {
        fontFamily: fontFamiliyHeadlines,
        '@media (max-width:960px)': {
          fontSize: '1.1rem',
        },
      },
      body1: {},
      body2: {},
    },
    MuiStepIcon: {
      text: {
        fill: '#ffffff',
      },
    },
    MuiAvatar: {
      colorDefault: {
        backgroundColor: '#e0e0e0',
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
        borderRadius: '9999px',
      },
      containedPrimary: {
        color: '#ffffff',
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: '14px',
      },
    },
    MuiDialogActions: {
      root: {
        padding: '16px 24px',
      },
    },
  },
  props: {
    MuiButton: {
      disableElevation: true,
    },
  },
});

const theme = {
  ...baseTheme,
  palette: {
    ...baseTheme.palette,
    background: {
      ...baseTheme.palette.background,
      //default: baseTheme.palette.common.white,
      placeholder: grey[300],
    },
  },
  typography: {
    ...baseTheme.typography,
    // used in AppHeader
    /*h1: {
      ...rawTheme.typography.h1,
      //fontSize: rawTheme.typography.pxToRem(36),
      fontWeight: rawTheme.typography.fontWeightBold,
      letterSpacing: 'normal',
      lineHeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      [rawTheme.breakpoints.up('md')]: {
        //fontSize: rawTheme.typography.pxToRem(48),
        lineHeight: 'normal',
      },
      // textAlign: 'center',
      // textTransform: 'uppercase',
    },
    h2: {
      ...rawTheme.typography.h2,
      //fontSize: rawTheme.typography.pxToRem(28),
      fontWeight: rawTheme.typography.fontWeightBold,
      letterSpacing: 'normal',
      lineHeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      //[rawTheme.breakpoints.up('md')]: {
      //  fontSize: rawTheme.typography.pxToRem(34),
      //},
    },
    h3: {
      ...rawTheme.typography.h3,
      // Like H1
      fontSize: rawTheme.typography.pxToRem(36),
      fontWeight: rawTheme.typography.fontWeightBold,
      letterSpacing: 'normal',
      lineHeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      //[rawTheme.breakpoints.up('md')]: {
      //  fontSize: rawTheme.typography.pxToRem(48),
      //},
    },
    h4: {
      ...rawTheme.typography.h4,
      //fontSize: rawTheme.typography.pxToRem(28),
      fontWeight: rawTheme.typography.fontWeightMedium,
      letterSpacing: 'normal',
      lineHeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      //[rawTheme.breakpoints.up('md')]: {
      //  fontSize: rawTheme.typography.pxToRem(34),
      //},
    },
    h5: {
      ...rawTheme.typography.h5,
      //fontSize: rawTheme.typography.pxToRem(20),
      fontWeight: rawTheme.typography.fontWeightMedium,
      letterSpacing: 'normal',
      lineHeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      //[rawTheme.breakpoints.up('md')]: {
      //  fontSize: rawTheme.typography.pxToRem(24),
      //},
    },
    h6: {
      ...rawTheme.typography.h6,
      ...fontHeader,
      //fontSize: rawTheme.typography.pxToRem(18),
      //[rawTheme.breakpoints.up('md')]: {
      //  fontSize: rawTheme.typography.pxToRem(20),
      //},
    },
    subtitle1: {
      ...rawTheme.typography.subtitle1,
      fontSize: rawTheme.typography.pxToRem(18),
      lineHeight: rawTheme.typography.pxToRem(14),
    },*/
  },
};

export const letterSpacing = {
  wide: theme.typography.pxToRem(10),
  minimal: theme.typography.pxToRem(3),
  tiny: theme.typography.pxToRem(1),
};

export const getRealHeight = (desired: string) => {
  return `calc(${desired} - ${theme.typography.pxToRem(60)})`;
};

export default responsiveFontSizes(theme);
