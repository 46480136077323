export default function registerPWASW() {
  let swUrl = `./pwaSW.js`;
  if (navigator && navigator.serviceWorker) {
    navigator.serviceWorker
      .register(swUrl)
      .then((registration) => {
        console.log('PWA Service worker registration succesful, scope is:', registration.scope);
      })
      .catch((error) => {
        console.warn('PWA Service worker registration failed, error:', error);
      });
  }
}
