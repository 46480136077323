import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';

import { appStore } from '../stores/app';
import { authStore } from '../stores/auth';
import { getPathnameRoot } from '../helpers/url';
import { athleteNavigationItems } from '../../components/layouts/AppHeader';
import domHelpers from '../helpers/dom';

const dashboardPages = athleteNavigationItems.map((item) => item.id);

export default function DashboardModeChecker() {
  const { pathname } = useLocation();
  const [appState, appDispatch] = useContext(appStore);
  const [authState] = useContext(authStore);
  const theme = useTheme();
  const isSmallScreen = domHelpers.isSmallScreen(useMediaQuery, theme); // calculated once

  useEffect(() => {
    const pathRoot = getPathnameRoot(pathname);
    const sideMenuVisible = authState.isAthlete && dashboardPages.indexOf(pathRoot) >= 0 && !isSmallScreen;

    if (appState.sideMenuVisible !== sideMenuVisible) {
      appDispatch({ type: sideMenuVisible ? 'sideMenuOn' : 'sideMenuOff' });
    }
  }, [pathname, authState.isAthlete]);

  return null;
}
