/*
 * Using mui-rff
 * Check following link for more info: https://github.com/lookfirst/mui-rff
 * */

import React, { useContext, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import * as Yup from 'yup';
import { makeRequired, makeValidate } from 'mui-rff';
import { Form } from 'react-final-form';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import TextField from '@material-ui/core/TextField';

import { countryToFlag } from '../../core/helpers/misc';
import { countries } from '../../core/helpers/selectOptions';
import FormRow from '../../components/ui/FormRow';
import globalStyles from '../../theme/globalStyles';
import FormAutocomplete from '../../components/ui/FormAutocomplete';
import SubmitButton from '../../components/ui/SubmitButton';
import { updateUser } from '../../core/services/userSvc';
import { appStore } from '../../core/stores/app';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submitBtnWrapper: {
    position: 'relative',
  },
  submitBtn: {
    margin: theme.spacing(2, 0, 1),
    height: 50,
  },
  submitBtnProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  charCounter: {
    position: 'absolute',
    right: theme.spacing(1),
    bottom: theme.spacing(1 / 2),
  },
  countryOption: {
    fontSize: 15,
    '& > span': {
      marginRight: theme.spacing(1),
      fontSize: 18,
    },
  },
  formTextField: { ...globalStyles(theme).formTextField },
  formSelect: { ...globalStyles(theme).formSelect },
  formAutocomplete: { ...globalStyles(theme).formAutocomplete },
  formDatePicker: { ...globalStyles(theme).formDatePicker },
}));

interface UserCountryDialogProps {
  open: boolean;
  onSubmitted: (country: string) => void;
}

export interface UserCountryDialogData {
  country: string;
}

const formSchema = Yup.object().shape({
  country: Yup.string().required('Country is a required field.'),
});

const validate = makeValidate(formSchema);
const required = makeRequired(formSchema);

const UserCountryDialog: React.FC<UserCountryDialogProps> = ({ open, onSubmitted }: UserCountryDialogProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const [appState, appDispatch] = useContext(appStore);
  const isBigScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const [submitting, setSubmitting] = useState(false);
  const formId = 'athlete-info-dialog-form';
  const subscription = { pristine: true, invalid: true };
  const initialValues = {
    country: '',
  };
  const handleSubmitClick = async (values: UserCountryDialogData) => {
    if (appState.user) {
      setSubmitting(true);
      await updateUser(appState.user.id, values, appDispatch);
      setSubmitting(false);
      onSubmitted(values.country);
    }
  };

  return (
    <Form
      onSubmit={handleSubmitClick}
      initialValues={initialValues}
      subscription={subscription}
      validate={validate}
      key={subscription as any}
      render={({ handleSubmit, pristine, invalid }) => (
        <form
          id={formId}
          className={classes.form}
          onSubmit={handleSubmit}
          noValidate={true}
          autoComplete="new-password"
        >
          <Dialog open={open} maxWidth="xs" fullWidth={true} fullScreen={!isBigScreen}>
            <DialogTitle>What's your country of residence?</DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                {/* Country */}
                <Grid item xs={12}>
                  <FormRow label="">
                    <FormAutocomplete
                      id="country"
                      name="country"
                      required={required.country}
                      disabled={Boolean(submitting)}
                      options={countries}
                      getOptionValue={(option: any) => option.value}
                      getOptionLabel={(option: any) => option.label}
                      renderInput={(params: any) => (
                        <TextField
                          autoComplete="new-password"
                          {...params}
                          label="Select a country"
                          variant="outlined"
                        />
                      )}
                      renderOption={(option: any) => (
                        <div className={classes.countryOption}>
                          <span>{countryToFlag(String(option.value))}</span>
                          {option.label}
                        </div>
                      )}
                    />
                  </FormRow>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <SubmitButton
                form={formId}
                disabled={invalid || submitting || pristine}
                fullWidth={true}
                submitting={submitting}
              >
                Continue
              </SubmitButton>
            </DialogActions>
          </Dialog>
        </form>
      )}
    />
  );
};

export default UserCountryDialog;
