import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

interface Props {
  children: React.ReactNode;
}

const PaperFormActions: React.FC<Props> = ({ children }: Props) => {
  return (
    <Grid item style={{ width: '100%' }}>
      <Box width={1} display="flex" justifyContent="flex-end">
        {children}
      </Box>
    </Grid>
  );
};

export default PaperFormActions;
