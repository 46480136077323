import { API } from 'aws-amplify';
import { LikeTargetType } from '../../API';
import { createLike } from '../graphql/customMutations';
import { listLikesByTarget } from '../graphql/customQueries';

import { GRAPHQL_AUTH_MODE } from '../graphql/types';

const fetchLikesByTarget = async (targetId: string, limit?: 20) => {
  const res: any = await API.graphql({
    query: listLikesByTarget,
    variables: {
      targetId: targetId,
      limit: limit,
    },
    authMode: GRAPHQL_AUTH_MODE.API_KEY,
  });

  return res?.data.listLikesByTarget.items;
};

const likePost = async (userId: string, postId: string) => {
  const res: any = await API.graphql({
    query: createLike,
    variables: {
      input: {
        userId: userId,
        targetId: postId,
        targetType: LikeTargetType.post,
      },
    },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  });

  return res?.data;
};

const likeComment = async (userId: string, commentId: string) => {
  const res: any = await API.graphql({
    query: createLike,
    variables: {
      input: {
        userId: userId,
        targetId: commentId,
        targetType: LikeTargetType.comment,
      },
    },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  });
  return res?.data;
};

export { fetchLikesByTarget, likePost, likeComment };
