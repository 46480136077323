import React, { useState, useContext } from 'react';
import { useTheme } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import Dialog from '@material-ui/core/Dialog/Dialog';
import Box from '@material-ui/core/Box';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import { useStripe } from '@stripe/react-stripe-js';

import { attachPaymentMethod } from '../../core/services/subscriptionsSvc';
import { PaymentMethod } from '../../API';
import CreditCardForm from '../forms/CreditCardForm';
import DialogTitle from '../../components/ui/DialogTitle';
import { appStore } from '../../core/stores/app';

interface Props {
  open: boolean;
  onSubmitted: (paymentMethod: PaymentMethod) => void;
  onClose: () => void;
}

const AddCreditCardDialog: React.FC<Props> = ({ open, onSubmitted, onClose }: Props) => {
  const stripe = useStripe();
  const theme = useTheme();
  const [, appDispatch] = useContext(appStore);
  const isBigScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const [submitting, setSubmitting] = useState(false);
  const handleClose = () => {
    onClose();
  };
  const handleSubmitClick = async (name: string, cardNumberElement: any) => {
    // Pass the Element directly to other Stripe.js methods:
    // e.g. createToken - https://stripe.com/docs/js/tokens_sources/create_token?type=cardElement
    //const tokenRes = await stripe.createToken(cardNumberElement);
    //console.log(tokenRes);
    // or createPaymentMethod - https://stripe.com/docs/js/payment_intents/create_payment_method
    if (stripe) {
      try {
        setSubmitting(true);
        const { paymentMethod, error } = await stripe.createPaymentMethod({
          type: 'card',
          card: cardNumberElement,
          billing_details: {
            name: name,
            // address: {
            //   postal_code: postal,
            // },
          },
        });

        if (error) {
          console.log(error);
          appDispatch({
            type: 'showToast',
            toastTxt: error.message,
            toastSeverity: 'error',
          });
        } else if (paymentMethod) {
          const attachedPaymentMethod = await attachPaymentMethod(paymentMethod.id);

          onSubmitted(attachedPaymentMethod);
        }
      } catch (err) {
        console.log(err);
        appDispatch({
          type: 'showToast',
          toastTxt: 'This credit card is not valid',
          toastSeverity: 'error',
        });
      } finally {
        setSubmitting(false);
      }
    }
  };

  return (
    <Dialog open={open} fullScreen={!isBigScreen} maxWidth="xs" fullWidth={true} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title" onClose={handleClose}>
        {' '}
      </DialogTitle>
      <DialogContent>
        <Box mb={2}>
          <CreditCardForm submitting={submitting} onSubmitClick={handleSubmitClick} />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddCreditCardDialog;
