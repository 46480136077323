import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
// import grey from '@material-ui/core/colors/grey';

interface Props {
  title: string;
  required?: boolean;
  missing?: boolean;
  children: React.ReactNode;
}

const PaperFormElem: React.FC<Props> = ({ title, required, missing, children }: Props) => {
  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Grid item style={{ width: '100%' }}>
      <Grid container spacing={isBigScreen ? 2 : 1}>
        <Grid item xs={12} sm={3}>
          <Grid container alignItems="center" spacing={1}>
            {missing && (
              <Grid item>
                <ArrowForwardIcon color="primary" fontSize="small" />
              </Grid>
            )}
            <Grid item>
              <Typography variant="body1">
                <b>{required ? `${title} *` : title}</b>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={9}>
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PaperFormElem;
