import React from 'react';
import NewBlogsLayout from '../../components/elements/NewBlogsLayout';
import { Container, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { PeakzTheme } from '../../theme/theme';
import { Typography } from '@material-ui/core';
import { Box } from '@material-ui/core';
import CTAButton from '../../components/ui/CTAButton';
import { Link } from 'react-router-dom';
import domHelpers from '../../core/helpers/dom';
import { useTranslation } from 'react-i18next';

const BlogsSection: React.FC = () => {
  const theme: PeakzTheme = useTheme();
  const [t] = useTranslation();
  const isSmallScreen = domHelpers.isSmallScreen(useMediaQuery, theme);
  return (
    <React.Fragment>
      <Container>
        <Box p={isSmallScreen ? 3 : 10}>
          <Typography variant="h4" align="center">
            <b>{t('landing.home.preblog_section.title')}</b>
          </Typography>
          <Box mt={3}>
            <Typography style={{ color: '#464646' }} variant="h5" align={isSmallScreen ? 'justify' : 'center'}>
              {t('landing.home.preblog_section.description')}
            </Typography>
            <Box display="flex" justifyContent="center" mt={5}>
              <Link rel="noopener" to="/fans">
                <CTAButton variant="contained" color="primary" size="large">
                  <b>{t('global.read_more')}</b>
                </CTAButton>
              </Link>
            </Box>
          </Box>
        </Box>
      </Container>
      <NewBlogsLayout />
    </React.Fragment>
  );
};

export default BlogsSection;
