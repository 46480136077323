export type AttendeeType = 'host' | 'guest' | 'moderator' | 'bcaster';

// generates chime's ExternalUserId
// it embeds all info about the attendee needed in this simple app
// this way we avoid using a db table for sharing this runtime info
// among participants
export type AttendeeInfo = {
  id: string; // host id | guest uuid from bcast record
  type: AttendeeType; // host | guest | ...
  name: string; // display name
};

export const ATTENDEEE_ID_DELIMITER = '|';

export const generateExternalAttendeeId = (info: AttendeeInfo): string => {
  const types = new Map<AttendeeType, string>([
    ['host', 'h'],
    ['guest', 'g'],
    ['moderator', 'm'],
    ['bcaster', 'b'],
  ]);
  const type = types.get(info.type);
  if (!type) {
    throw new Error('Invalid attendee type');
  }
  const fields = [info.id, type, info.name];
  return fields.join(ATTENDEEE_ID_DELIMITER);
};

export const parseExternalAttendeeId = (id: string): AttendeeInfo => {
  const fields = id.split(ATTENDEEE_ID_DELIMITER);
  if (fields.length != 3) {
    throw new Error('Invalid external attendee id.');
  }
  const types = new Map<string, AttendeeType>([
    ['h', 'host'],
    ['g', 'guest'],
    ['m', 'moderator'],
    ['b', 'bcaster'],
  ]);
  const type = types.get(fields[1]);
  if (!type) {
    throw new Error('invalid attendee type');
  }
  return {
    id: fields[0],
    type: type,
    name: fields[2],
  };
};

export const getAttendeeCallback = async (chimeAttendeeId: string, externalUserId?: string) => {
  if (externalUserId) {
    try {
      const info = parseExternalAttendeeId(externalUserId);
      return info.name;
    } catch {}
  }
  return '?';
};
