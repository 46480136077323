/*
 * Using mui-rff
 * Check following link for more info: https://github.com/lookfirst/mui-rff
 * */

import React from 'react';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import * as Yup from 'yup';
import { makeRequired, makeValidate } from 'mui-rff';
import { Form } from 'react-final-form';
import Box from '@material-ui/core/Box';

import TextDivider from '../../components/ui/TextDivider';
import SubmitButton from '../../components/ui/SubmitButton';
import GoogleButton from '../../components/ui/GoogleButton';
import FacebookButton from '../../components/ui/FacebookButton';
import globalStyles from '../../theme/globalStyles';
import FormRow from '../../components/ui/FormRow';
import { pwdMinLen } from '../../config';
import FormTextField from '../../components/ui/FormTextField';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  grid: {
    width: '100%',
    margin: 0,
  },
  formTextField: { ...globalStyles(theme).formTextField },
}));

interface LoginFormProps {
  submitting: boolean;
  errorTxt: string;
  onLoginClick: (data: LoginFormData) => void;
  onForgotPwdClick: () => void;
  onGoogleLoginClick: () => void;
  onFacebookLoginClick: () => void;
}

export interface LoginFormData {
  email: string;
  password: string;
}

const formSchema = Yup.object().shape({
  email: Yup.string().email('Please enter a valid email.').required('Please enter a valid email.'),
  password: Yup.string().min(pwdMinLen, `Password needs to be at least ${pwdMinLen} characters.`),
});

const validate = makeValidate(formSchema);
const required = makeRequired(formSchema);

const LoginForm: React.FC<LoginFormProps> = ({
  submitting,
  errorTxt,
  onForgotPwdClick,
  onGoogleLoginClick,
  onFacebookLoginClick,
  onLoginClick,
}: LoginFormProps) => {
  const classes = useStyles();
  const initialValues: LoginFormData = {
    email: '',
    password: '',
  };
  const subscription = { invalid: true };
  const handleLoginClick = (values: LoginFormData) => {
    onLoginClick(values);
  };
  const handleForgotPwdClick = () => {
    onForgotPwdClick();
  };
  const handleGoogleLoginClick = () => {
    onGoogleLoginClick();
  };
  const handleFacebookLoginClick = () => {
    onFacebookLoginClick();
  };

  return (
    <Form
      key={subscription as any}
      initialValues={initialValues}
      subscription={subscription}
      validate={validate}
      onSubmit={handleLoginClick}
      render={({ handleSubmit, invalid }) => (
        <form className={classes.form} onSubmit={handleSubmit} noValidate={true} autoComplete="new-password">
          <Grid className={classes.grid} container direction="column" spacing={1}>
            {/* Login Grid */}
            <Grid item xs>
              <FormRow label="Email">
                <FormTextField
                  id="email"
                  name="email"
                  type="text"
                  required={required.email}
                  autoComplete="email"
                  disabled={submitting}
                />
              </FormRow>

              <FormRow label="Password">
                <FormTextField
                  id="password"
                  name="password"
                  type="password"
                  required={required.password}
                  autoComplete="current-password"
                  disabled={submitting}
                />
              </FormRow>

              {/* Forgot password */}
              <Box display="flex" justifyContent="flex-end" mt={3}>
                <Link href="#" variant="body1" onClick={handleForgotPwdClick}>
                  Forgot password?
                </Link>
              </Box>

              {/* Submit button */}
              <Box mt={2}>
                {/* Error Text */}
                {errorTxt && (
                  <Box mb={1}>
                    <Typography variant="body1" align="center" color="error" gutterBottom>
                      {errorTxt}
                    </Typography>
                  </Box>
                )}
                <SubmitButton submitting={submitting} disabled={invalid || submitting} fullWidth>
                  Log in
                </SubmitButton>
              </Box>
            </Grid>

            {/* Divider */}
            <Grid item xs>
              <Box mt={3} mb={3}>
                <TextDivider text="or" />
              </Box>
            </Grid>

            {/* Google Sign In Button */}
            <Grid item xs>
              <GoogleButton disabled={submitting} onClick={handleGoogleLoginClick}>
                Continue with Google
              </GoogleButton>
            </Grid>

            {/* Facebook Sign In Button */}
            <Grid item xs>
              <FacebookButton disabled={submitting} onClick={handleFacebookLoginClick}>
                Continue with Facebook
              </FacebookButton>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

export default LoginForm;
