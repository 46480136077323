import React, { useState, useContext } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import Dialog from '@material-ui/core/Dialog/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import Cropper from 'react-easy-crop';
import Slider from '@material-ui/core/Slider';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import { Point } from 'react-easy-crop/types';
import Grid from '@material-ui/core/Grid';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { getCroppedImg, toBase64 } from '../../core/helpers/image';
import urlHelpers from '../../core/helpers/url';
import { authStore } from '../../core/stores/auth';
import DialogTitle from '../../components/ui/DialogTitle';
import VideoImageDropZone from '../../components/ui/VideoImageDropZone';
import FormSubmitButton from '../../components/ui/FormSubmitButton';

const useStyles = makeStyles(() => ({
  editorWrapper: {
    position: 'relative',
    width: '100%',
    height: 300,
  },
}));

interface State {
  img?: string; // img url or base64 data
  crop: Point;
  zoom: number;
  aspect: number;
  croppedAreaPixels?: any;
}

const initialState: State = {
  crop: { x: 0, y: 0 },
  zoom: 1,
  aspect: 1,
};
const zoomInc = 0.25;

interface Props {
  title: string;
  open: boolean;
  imgUrl?: string;
  aspect?: number;
  outputWidth?: number;
  outputHeight?: number;
  submitting?: boolean;
  onSubmit: (preview: string) => void;
  onCancel: () => void;
  onClose: () => void;
}

const maxImgSize = 15 * 1000000; // 15Mb

const ImageCroppingDialog: React.FC<Props> = ({
  title,
  open,
  imgUrl,
  aspect,
  outputWidth,
  outputHeight,
  submitting,
  onSubmit,
  onCancel,
  onClose,
}: Props) => {
  const classes = useStyles();
  const [state, setState] = useState({ ...initialState, img: imgUrl, aspect: aspect || 1 });
  const [croppingImg, setCroppingImg] = useState(false);
  const [authState] = useContext(authStore);
  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const handleClose = () => {
    onClose();
  };
  const handleSubmitClick = async () => {
    if (state.img && authState.userInfo) {
      let croppedImg;

      setCroppingImg(true);
      if (state.img.indexOf('https://') === 0) {
        // Remote image: Url needs to be signed in order to avoid CORS issues with Cloudfront/S3
        const url = new URL(state.img);
        const signedUrl = await urlHelpers.getSignedS3Url(url.pathname, authState.userInfo.userIdentityId);
        const signedImgUrl = urlHelpers.getMediaUrlFromSignedS3Url(signedUrl, 'img');

        croppedImg = await getCroppedImg(signedImgUrl, state.croppedAreaPixels, outputWidth, outputHeight);
      } else {
        // Local image
        croppedImg = await getCroppedImg(state.img, state.croppedAreaPixels, outputWidth, outputHeight);
      }
      setCroppingImg(false);

      if (croppedImg) {
        onSubmit(croppedImg);
      }
    }
  };
  const handleCancelClick = () => {
    onCancel();
  };
  const handleCropChange = (crop: Point) => {
    setState((state) => ({ ...state, crop: crop }));
  };
  const handleCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
    setState((state) => ({ ...state, croppedAreaPixels: croppedAreaPixels }));
  };
  const handleZoomChange = (zoom: number | number[]) => {
    if (!Array.isArray(zoom)) {
      setState((state) => ({ ...state, zoom: zoom }));
    }
  };
  const handleZoonIn = () => {
    setState((state) => ({ ...state, zoom: Math.min(state.zoom + zoomInc, 5) }));
  };
  const handleZoonOut = () => {
    setState((state) => ({ ...state, zoom: Math.max(state.zoom - zoomInc, 0) }));
  };
  const handleFileDragAndDropChange = async (files: any) => {
    if (files && files.length > 0) {
      const imageBase64 = await toBase64(files[0]);

      setState((state: State) => ({ ...state, img: imageBase64 }));
    }
  };
  const handleFileDragAndDropRejected = (): string => {
    alert('File is too big!');

    return 'Rejected';
  };
  const handleClearImgClick = () => {
    setState((state) => ({ ...state, img: undefined }));
  };

  return (
    <Dialog open={open} fullScreen={!isBigScreen} maxWidth="sm" fullWidth={true} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title" onClose={handleClose}>
        {title}
      </DialogTitle>
      <DialogContent>
        <Grid style={{ height: '100%' }} container direction="column" justifyContent="center">
          <Grid item>
            {!state.img && (
              <VideoImageDropZone
                maxFileSizeBytes={maxImgSize}
                acceptedFileTypes={['image/*']}
                onRejected={handleFileDragAndDropRejected}
                onChange={handleFileDragAndDropChange}
              />
            )}
            {state.img && (
              <>
                {/* Image Editor */}
                <div className={classes.editorWrapper}>
                  <Cropper
                    image={state.img}
                    crop={state.crop}
                    zoom={Number(state.zoom)}
                    aspect={state.aspect}
                    minZoom={0}
                    maxZoom={5}
                    restrictPosition={false}
                    onCropChange={handleCropChange}
                    onCropComplete={handleCropComplete}
                    onZoomChange={handleZoomChange}
                    showGrid={false}
                  />
                </div>
                {/* Remove image button */}
                <Box display="flex" justifyContent="flex-end" mt={0.5}>
                  <Button startIcon={<DeleteIcon />} onClick={handleClearImgClick}>
                    Remove
                  </Button>
                </Box>
                {/* Controls */}
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <IconButton edge="start" onClick={handleZoonOut}>
                      <ZoomOutIcon fontSize="large" />
                    </IconButton>
                  </Grid>
                  <Grid item xs>
                    <Slider
                      value={state.zoom}
                      min={0}
                      max={5}
                      step={0.01}
                      aria-labelledby="Zoom"
                      onChange={(e, zoom) => handleZoomChange(zoom)}
                    />
                  </Grid>
                  <Grid item>
                    <IconButton edge="end" onClick={handleZoonIn}>
                      <ZoomInIcon fontSize="large" />
                    </IconButton>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <FormSubmitButton onClick={handleCancelClick}>Cancel</FormSubmitButton>
        <FormSubmitButton
          color="primary"
          onClick={handleSubmitClick}
          disabled={!state.img || submitting || croppingImg}
          endIcon={submitting || croppingImg ? <CircularProgress size={24} /> : <ArrowForwardIcon fontSize="large" />}
        >
          Save
        </FormSubmitButton>
      </DialogActions>
    </Dialog>
  );
};

export default ImageCroppingDialog;
