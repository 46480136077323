import { Container, Grid, Box, makeStyles, Typography } from '@material-ui/core';
import { useTheme, useMediaQuery } from '@material-ui/core';
import React from 'react';
import domHelpers from '../../core/helpers/dom';
import config from '../../config';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  rootBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '100vh',
    height: 'auto',
  },
  boxWrapper: {
    minHeight: '25vh',
    height: 'auto',
    padding: theme.typography.pxToRem(40),
    [theme.breakpoints.down('sm')]: {
      padding: theme.typography.pxToRem(0),
    },
  },
  verticalCenter: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down(769)]: {
      justifyContent: 'center',
      width: '70%',
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'unset',
      width: '100%',
    },
  },
  imgStyle: {
    width: '100%',
    maxWidth: '400px',
    padding: theme.spacing(3, 3, 3, 3),
  },
}));

const WhyBoxes: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isExtraSmallScreen = domHelpers.isXtraSmallScreen(useMediaQuery, theme);
  const [t] = useTranslation();

  const boxesContent = [
    {
      imgSrc: `${config.assetBaseUrl}/static/images/landing-header/whybox1.png`,
      text: t('landing.about_peakz.why_boxes.first'),
    },
    {
      imgSrc: `${config.assetBaseUrl}/static/images/landing-header/whybox2.png`,
      text: t('landing.about_peakz.why_boxes.second'),
    },
    {
      imgSrc: `${config.assetBaseUrl}/static/images/landing-header/whybox3.png`,
      text: t('landing.about_peakz.why_boxes.third'),
    },
  ];
  return (
    <Container>
      <Box className={classes.rootBox} p={isExtraSmallScreen ? 3 : 5} pt={0}>
        {boxesContent.map((content, index) => {
          if (index % 2 === 0)
            return (
              <Grid justifyContent="center" spacing={5} className={classes.boxWrapper} key={index} container>
                <Grid md={4} item>
                  <img className={classes.imgStyle} src={content.imgSrc} />
                </Grid>
                <Grid className={classes.verticalCenter} md={8} item>
                  <Typography variant="h5">
                    <b>{content.text}</b>
                  </Typography>
                </Grid>
              </Grid>
            );
          return (
            <Grid
              justifyContent="center"
              style={{ flexWrap: 'wrap-reverse' }}
              spacing={5}
              className={classes.boxWrapper}
              key={index}
              container
            >
              <Grid md={8} className={classes.verticalCenter} item>
                <Typography variant="h5">
                  <b>{content.text}</b>
                </Typography>
              </Grid>
              <Grid md={4} item>
                <img className={classes.imgStyle} src={content.imgSrc} />
              </Grid>
            </Grid>
          );
        })}
      </Box>
    </Container>
  );
};

export default WhyBoxes;
