import React, { useState, useContext, useEffect, useMemo } from 'react';
import { makeRequired, makeValidate } from 'mui-rff';
import * as Yup from 'yup';
import { Form, FormSpy, Field } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { FormHelperText } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import ClearIcon from '@material-ui/icons/Clear';
import clsx from 'clsx';
import { Alert } from '@material-ui/lab';

import PaperFormElem from '../components/ui/PaperFormElem';
import PaperForm from '../components/ui/PaperForm';
import SubmitButton from '../components/ui/SubmitButton';
import PaperFormActions from '../components/ui/PaperFormActions';
import FormTextField from '../components/ui/FormTextField';
import { extractYoutubeVideoID, mbToBytes, micknameRegex, removeNullAttributes } from '../core/helpers/misc';
import { User } from '../core/graphql/types';
import userSvc, { updateUser } from '../core/services/userSvc';
import { appStore } from '../core/stores/app';
import globalStyles from '../theme/globalStyles';
import VideoImageDropZone from '../components/ui/VideoImageDropZone';
import VideoPlayer from '../components/ui/VideoPlayer';
import urlHelpers from '../core/helpers/url';
import { uploadVideoToS3 } from '../core/services/videoSvc';
import { authStore } from '../core/stores/auth';
import { getAthleteProfileCompletion, UserProfileMissingAttribs } from '../core/helpers/user';

const introVideoHeight = 250;
const useGlobalStyles = makeStyles(globalStyles as any);
const useStyles = makeStyles((theme) => ({
  countryOption: {
    fontSize: 15,
    '& > span': {
      marginRight: theme.spacing(1),
      fontSize: 18,
    },
  },
  introVideoSelectorWrapper: {
    width: '100%',
    marginTop: theme.spacing(-1),
  },
  fullWithItem: {
    maxWidth: '100%',
  },
  introVideoCont: {
    width: '100%',
  },
  introVideoDropzonePaper: {
    height: theme.typography.pxToRem(introVideoHeight),
  },
  introVideoWrapper: {
    height: theme.typography.pxToRem(introVideoHeight),
  },
  blackBg: {
    backgroundColor: '#000000',
  },
  videoPlayer: {
    maxHeight: theme.typography.pxToRem(introVideoHeight),
    maxWidth: '100%',
    width: '100%',
  },
  introVideoThumb: {
    width: '100%',
    height: theme.typography.pxToRem(introVideoHeight),
    objectFit: 'cover',
  },
}));

// const minMissionLen = 100;
const maxMissionLen = 250;
const maxMBIntroVIdeo = 500;
const maxIntroVideoSizeBytes = mbToBytes(maxMBIntroVIdeo); // in Bytes (1Mb = 1048576 Bytes)
const maxIntroVideoDuration = undefined; // 20; // seconds

const formSchema = Yup.object().shape({
  nickname: Yup.string()
    .ensure()
    .test('pattern', 'Wrong nickname', (val) => val.match(micknameRegex)),
  mission: Yup.string(),
  // .test('mission', `Your story has to be at least ${minMissionLen} characters. `, (val) => {
  //   return val && val.length >= minMissionLen;
  // })
  // .test('mission', `Your story can have max ${maxMissionLen} characters. `, (val) => {
  //   return val && val.length <= maxMissionLen;
  // }),
  introVideoUrl: Yup.string().test('introvideo', 'It must be a YouTube video', (val) => {
    if (val) {
      try {
        const youtubeVideoId = extractYoutubeVideoID(val);
        return Boolean(youtubeVideoId);
      } catch (e) {}
      return false;
    }

    return true;
  }),
});

const validate = makeValidate(formSchema);
const required = makeRequired(formSchema);

export interface AthleteChannelBasicsFormData {
  nickname?: string;
  mission?: string;
  introVideoUrl?: string;
}

export interface AthleteChannelBasicsUpdate extends AthleteChannelBasicsFormData {
  introVideoS3Key?: string;
  introVideoThumbS3Key?: string;
}

interface Props {
  user: User;
  onUserUpdated?: (update: AthleteChannelBasicsUpdate) => void;
}

const AthleteChannelBasics: React.FC<Props> = ({ user, onUserUpdated }: Props) => {
  const globalClasses = useGlobalStyles();
  const classes = useStyles();
  const [, appDispatch] = useContext(appStore);
  const [authState] = useContext(authStore);
  const [submitting, setSubmitting] = useState(false);
  const [mission, setMission] = useState('');
  const [introVideoFile, setIntroVideoFile] = useState<File | null>(null);
  const [introVideoFileUrl, setIntroVideoFileUrl] = useState('');
  const [introVideoChanged, setIntroVideoChanged] = useState(false);
  const [, setIntroVideoThumbUrl] = useState('');
  const [accountMissingAttribs, setAccountMissingAttribs] = useState<UserProfileMissingAttribs>({});
  const [fileRefectionAlert, setFileRejectionAlert] = useState(undefined);
  const initialValues = removeNullAttributes({
    nickname: user?.nickname,
    mission: user?.mission,
    introVideoUrl: user?.introVideoUrl,
  });
  const subscription = { pristine: true, invalid: true };
  const formId = 'account-channel-basics-form';
  const renderVideo = useMemo(() => {
    if (introVideoFileUrl) {
      return <VideoPlayer className={classes.videoPlayer} videoUrl={introVideoFileUrl} />;
    }
    return <></>;
  }, [introVideoFileUrl]);
  const handleIntroFileSelected = (files: File[]) => {
    if (!introVideoFile && files.length > 0) {
      const file = files[0];
      setIntroVideoFile(file);
      setFileRejectionAlert(undefined);
      setIntroVideoChanged(true);
    }
  };
  const handleIntroFileRejected = (rejections: any) => {
    const fileRejection = rejections[0];
    if (fileRejection) {
      if ('code' in fileRejection.errors[0]) {
        const code = fileRejection.errors[0].code;
        if (code === 'file-too-large') {
          setFileRejectionAlert(`File cannot be more than ${maxMBIntroVIdeo} mb` as any);
          return;
        }
        setFileRejectionAlert(fileRejection.errors[0].message);
      }
    }
  };
  const handleFormChange = ({ values }: { values: AthleteChannelBasicsFormData }) => {
    // Avoid React render loop
    setTimeout(() => {
      setMission(values.mission || '');

      // Render the thumbnail of the intro video URL
      if (values.introVideoUrl) {
        try {
          const youtubeVideoId = extractYoutubeVideoID(values.introVideoUrl);

          if (youtubeVideoId) {
            setIntroVideoThumbUrl(urlHelpers.getYoutubeVideoThumbUrl(youtubeVideoId));
          }
        } catch (err) {
          setIntroVideoThumbUrl('');
        }
      } else {
        setIntroVideoThumbUrl('');
      }
    }, 0);
  };
  const handleReplaceIntroFileClick = () => {
    setIntroVideoFile(null);
    setIntroVideoFileUrl('');
  };
  const handleSubmitClick = async (values: AthleteChannelBasicsFormData) => {
    const { nickname, ...updateObj }: any = { ...values };
    const introVideoUrl = '';
    let introVideoS3Key = '';

    if (updateObj) {
      setSubmitting(true);

      try {
        // Update the nickname first
        if (nickname && nickname !== initialValues.nickname) {
          const nicknameUpdated = await userSvc.updateMyNickname(nickname);

          if (!nicknameUpdated) {
            appDispatch({
              type: 'showToast',
              toastTxt: 'URL already taken',
              toastSeverity: 'error',
            });
            setSubmitting(false);
            return;
          }
        }

        if (introVideoFile && authState.userInfo) {
          // If there is an intro video, upload it
          const path = urlHelpers.getAthleteIntroVideoPath();

          introVideoS3Key = await uploadVideoToS3(
            introVideoFile,
            'protected',
            path.s3BasePath,
            path.fileNamePrefix,
            {
              refTag: 'athleteintrovideo',
              refOwner: authState.userInfo.userId,
              refOwnerIdentityId: authState.userInfo.userIdentityId,
            },
            () => {
              // videoUploadProgress: number
              // setState((state) => ({ ...state, videoUploadProgress: videoUploadProgress }));
            },
          );

          setIntroVideoFile(null);
        }

        // Prepare update object
        const userUpdateData = {
          ...updateObj,
        };

        // Update only if changed
        if (introVideoUrl) {
          userUpdateData.introVideoUrl = introVideoUrl;
          // Reset only if introVideoUrl is provided
          userUpdateData.introVideoS3Key = '';
        } else if (introVideoS3Key) {
          userUpdateData.introVideoS3Key = introVideoS3Key;
          // Reset only if introVideoS3Key is provided
          userUpdateData.introVideoUrl = '';
        }

        // Reset before updating user so the save button is disabled
        setIntroVideoChanged(false);

        // Notice nickname is not provided here
        await updateUser(user.id, userUpdateData, appDispatch);

        if (onUserUpdated) {
          // Notice nickname is added here
          onUserUpdated({ ...userUpdateData, nickname: nickname || initialValues.nickname });
        }
      } catch (err) {
        appDispatch({
          type: 'showToast',
          toastTxt: 'Something went wrong, please contact Peakz Team.',
          toastSeverity: 'error',
        });
      } finally {
        setSubmitting(false);
      }
    }
  };

  useEffect(() => {
    setIntroVideoFileUrl(introVideoFile ? URL.createObjectURL(introVideoFile) : '');
  }, [introVideoFile]);

  useEffect(() => {
    const profileCompletion = getAthleteProfileCompletion(user);

    setIntroVideoFileUrl(user.introVideoS3Key ? urlHelpers.getMediaUrlFromS3Key(user.introVideoS3Key, 'video') : '');
    setAccountMissingAttribs(profileCompletion.missingAttribs);
  }, [user]);

  return (
    <Form
      onSubmit={handleSubmitClick}
      initialValues={initialValues}
      subscription={subscription}
      validate={validate}
      key={subscription as any}
      render={({ form, handleSubmit, pristine, invalid }) => (
        <form id={formId} onSubmit={handleSubmit} noValidate={true} autoComplete="new-password">
          <FormSpy subscription={{ values: true }} onChange={handleFormChange} />
          <PaperForm
            actions={
              <PaperFormActions>
                <Grid container spacing={1} justifyContent="flex-end">
                  <Grid item>
                    <SubmitButton
                      disabled={invalid || submitting || pristine}
                      submitting={false}
                      color="default"
                      type="button" // to avoid the form being submitted
                      onClick={form.reset}
                    >
                      Cancel
                    </SubmitButton>
                  </Grid>
                  <Grid item>
                    <SubmitButton
                      disabled={submitting || ((invalid || pristine) && !introVideoChanged)}
                      submitting={submitting}
                    >
                      Save changes
                    </SubmitButton>
                  </Grid>
                </Grid>
              </PaperFormActions>
            }
          >
            {/* About Me */}
            <PaperFormElem
              title="About me"
              required={required.mission}
              missing={Boolean(accountMissingAttribs['mission'])}
            >
              <FormTextField
                id="mission"
                name="mission"
                required={required.mission}
                disabled={submitting}
                margin="none"
                multiline={true}
                rows={4}
                width="92%"
                InputProps={{
                  endAdornment: (
                    <Typography className={globalClasses.charCounter} variant="caption" color="textSecondary">
                      {mission ? `${mission.length}/${maxMissionLen}` : ''}
                    </Typography>
                  ),
                }}
              />
            </PaperFormElem>
            {/* Intro video */}
            <PaperFormElem
              title="Introduction video"
              required={required.introVideoUrl}
              missing={Boolean(accountMissingAttribs['introVideoUrl'])}
            >
              <FormControl className={classes.introVideoSelectorWrapper} component="fieldset">
                <Box width={1}>
                  {!introVideoFileUrl && (
                    <Field
                      name="introVideoS3Key"
                      render={() => {
                        return (
                          <Paper className={classes.introVideoDropzonePaper} variant="outlined">
                            <VideoImageDropZone
                              fontSize={'sm'}
                              hideButton={false}
                              maxVideoDuration={maxIntroVideoDuration}
                              maxFileSizeBytes={maxIntroVideoSizeBytes}
                              onChange={handleIntroFileSelected}
                              onRejected={handleIntroFileRejected}
                            />
                          </Paper>
                        );
                      }}
                    ></Field>
                  )}
                  {introVideoFileUrl && (
                    <>
                      <Box
                        className={clsx({
                          [classes.introVideoWrapper]: true,
                          [classes.blackBg]: true,
                        })}
                      >
                        {renderVideo}
                      </Box>
                      <Box mt={1} width={1} display="flex" justifyContent="flex-end">
                        <Button startIcon={<ClearIcon />} disabled={submitting} onClick={handleReplaceIntroFileClick}>
                          Choose another file
                        </Button>
                      </Box>
                    </>
                  )}
                  {fileRefectionAlert && <Alert severity="error">{fileRefectionAlert}</Alert>}
                </Box>
              </FormControl>
            </PaperFormElem>
            {/* Nickname */}
            <PaperFormElem
              title="Nickname"
              required={required.nickname}
              missing={Boolean(accountMissingAttribs['nickname'])}
            >
              <FormTextField
                id="nickname"
                name="nickname"
                required={required.nickname}
                disabled={submitting}
                margin="none"
                InputProps={{
                  startAdornment: <InputAdornment position="start">peakz.com/</InputAdornment>,
                }}
              />
              <FormHelperText>Your personal Peakz page</FormHelperText>
            </PaperFormElem>
          </PaperForm>
        </form>
      )}
    />
  );
};

export default AthleteChannelBasics;
