import React, { useContext, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router';
import MailIcon from '@material-ui/icons/Mail';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { formatDistance } from 'date-fns';

import { appStore } from '../core/stores/app';
import SubmitButton from '../components/ui/SubmitButton';
import userSvc from '../core/services/userSvc';
import { successColorStrong } from '../theme/globalStyles';

const EmailValidator: React.FC = () => {
  const history = useHistory();
  const [appState, appDispatch] = useContext(appStore);
  const [submitting, setSubmitting] = useState(false);
  const handleValidateEmailClick = async () => {
    if (appState.user) {
      setSubmitting(true);
      const res = await userSvc.sendEmailValidationEmail();
      setSubmitting(false);

      if (res) {
        appDispatch({ type: 'updateUser', userUpdateObj: { validationEmailSentAt: new Date().toISOString() } });
        appDispatch({
          type: 'showToast',
          toastTxt: `A confirmation email has been sent to <b>${appState.user.email}</b>`,
          toastSeverity: 'success',
        });
      } else {
        appDispatch({
          type: 'showToast',
          toastTxt: `A confirmation email has already been sent to you${
            appState.user.validationEmailSentAt
              ? ` ${formatDistance(new Date(appState.user.validationEmailSentAt), new Date(), { addSuffix: true })}`
              : ''
          }. Please check your inbox or spam.`,
          toastSeverity: 'warning',
        });
      }
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get('t');

    // Validate email if there is a token
    if (token) {
      (async function () {
        const res = await userSvc.validateEmail(token);

        if (res.value) {
          appDispatch({ type: 'updateUser', userUpdateObj: { emailValidated: true } });
          appDispatch({
            type: 'showToast',
            toastTxt: 'Great, your email address has been confirmed!',
            toastSeverity: 'success',
          });
        } else {
          appDispatch({
            type: 'showToast',
            toastTxt: 'Confirmation email is expired, please click on the button to send a new email.',
            toastSeverity: 'warning',
            toastAction: (
              <React.Fragment>
                <Button variant="contained" size="small" color="primary" onClick={handleValidateEmailClick}>
                  Send email
                </Button>
              </React.Fragment>
            ),
          });
        }

        // Remove the token from the search
        searchParams.delete('t');
        history.replace({
          search: searchParams.toString(),
        });
      })();
    }
  }, [appState.user]);

  return (
    <div>
      {appState.user && (
        <>
          {!appState.user.emailValidated && (
            <>
              {!appState.user.validationEmailSentAt && (
                <Typography variant="body1">
                  Your e-mail address isn’t verified yet. Click on the button to receive a confirmation mail.
                </Typography>
              )}
              {appState.user.validationEmailSentAt && (
                <Typography variant="body1">
                  We’ve sent you a confirmation email. Please check your inbox and spam!
                </Typography>
              )}
              <Box mt={2} display="flex" justifyContent="center">
                <SubmitButton
                  submitting={submitting}
                  disabled={submitting}
                  originalSize
                  startIcon={<MailIcon />}
                  onClick={handleValidateEmailClick}
                >
                  {appState.user.validationEmailSentAt ? 'Send another email' : 'Send email'}
                </SubmitButton>
              </Box>
            </>
          )}
          {appState.user.emailValidated && (
            <Grid container spacing={1}>
              <Grid item>
                <CheckCircleIcon style={{ color: successColorStrong }} />
              </Grid>
              <Grid item>
                <Typography variant="body1">Your email address has been confirmed!</Typography>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </div>
  );
};

export default EmailValidator;
