/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { API, Analytics } from 'aws-amplify';
import Axios from 'axios';

import AppHeaderPlaceHolder from '../components/layouts/AppHeaderPlaceHolder';
import { authStore } from '../core/stores/auth';
import PageRow from '../components/layouts/PageRow';
import { fetchUserById, updateUser } from '../core/services/userSvc';
import { GRAPHQL_AUTH_MODE } from '../core/graphql/types';
import { createAthleteSubscription, updateAthleteSubscription } from '../graphql/mutations';
import { appStore } from '../core/stores/app';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    '& .html5-video-player': {
      backgroundColor: '#fff',
    },
  },
}));

const FILE_CHUNK_SIZE = 10_000_000;

const TestPage: React.FC = () => {
  const classes = useStyles();
  const [authState] = useContext(authStore);
  const [, appDispatch] = useContext(appStore);
  const [localVideoFile, setLocalVideoFile] = useState<File | null>(null);
  const handleFileDragAndDropRejected = (): string => {
    alert('File is too big!');

    return 'Rejected';
  };
  const handleFileDragAndDropChange = (files: any) => {
    setLocalVideoFile(files[0]);
  };
  const handleTest = async () => {
    console.log('handleTest');
    if (localVideoFile && authState.userInfo) {
      try {
        // Single part upload
        //const url =
        ('https://peakz-challengesdev-dev.s3-accelerate.amazonaws.com/private/short_video-uploaded.mp4?AWSAccessKeyId=AKIATWN3V3EHMIF7SP5K&Content-Type=video%2Fmp4&Expires=1617539275&Signature=1EYexAJQz9BEvLN5dmowYhhjvMs%3D&x-amz-meta-a=1&x-amz-meta-b=The%20b');
        // await uploadFile(localVideoFile, url);

        console.log('File', localVideoFile);

        const partsCount = Math.floor(localVideoFile.size / FILE_CHUNK_SIZE) + 1;
        const { urls, uploadId, fileName } = await getVideoUploadUrls(partsCount);

        if (urls) {
          const fileBuffer = await file2Buffer(localVideoFile);
          const parts = await uploadParts(fileBuffer, urls);

          await completeUpload(uploadId, fileName, parts);
          console.log('Upload comlete');
        }

        //console.time('uploadVideoToS3Acc');
        // const s3VideoKeyAcc = await uploadVideoToS3(
        //   localVideoFile,
        //   'protected',
        //   'test',
        //   '',
        //   {
        //     refEntity: 'test',
        //     refOwner: authState.userInfo.userId,
        //     refOwnerIdentityId: authState.userInfo.userIdentityId,
        //   },
        //   (videoUploadProgress: number) => {
        //     console.log('accelerated', videoUploadProgress);
        //   },
        // );
        // console.log('s3VideoKeyAcc', s3VideoKeyAcc);
        // console.timeEnd('uploadVideoToS3Acc');
      } catch (err) {
        //  Empty
      }
    }
  };
  const myId = authState.user?.userId || '';
  const otherId = 'a1fcb79e-9ff2-4aad-acf2-695b8748fd1e';
  const handleUpdateUserClick = async () => {
    try {
      await updateUser(myId, { name: 'pepas' }, appDispatch);
      console.log('ok');
    } catch (err) {
      console.log('error');
    }
  };
  const handleGetUserClick = async () => {
    try {
      await fetchUserById(myId, 'basic');
      console.log('ok');
    } catch (err) {
      console.log('error');
    }

    try {
      await fetchUserById(myId, 'full');
      console.log('ok');
    } catch (err) {
      console.log('error');
    }

    try {
      await fetchUserById(otherId, 'basic');
      console.log('ok');
    } catch (err) {
      console.log('error');
    }

    try {
      await fetchUserById(otherId, 'full');
      console.log('error');
    } catch (err) {
      console.log('ok');
    }
  };
  const handleCreateSubscription = async () => {
    try {
      await API.graphql({
        query: createAthleteSubscription,
        variables: {
          input: {
            subscriberId: '1',
            athleteId: '2',
            //stripeSubscriptionId?: string | null,
            //stripePriceId?: string | null,
            //status?: SubscriptionStatus | null,
            expiresAt: new Date().toISOString(),
            //createdAt: string | null,
          },
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });
      console.log('error');
    } catch (err) {
      console.log('ok');
    }
  };
  const handleUpdateSubscription = async () => {
    try {
      await API.graphql({
        query: updateAthleteSubscription,
        variables: {
          input: {
            subscriberId: '9418f830-9cff-11eb-983a-25b1e185de2b',
            athleteId: '21a69f20-7ab1-11eb-bffe-e3a0391620d5',
            status: 'incomplete',
          },
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });
      console.log('error');
    } catch (err) {
      console.log('ok');
    }
  };
  const handlePinpointResetAll = async () => {
    if (authState.user) {
      // Create or update endpoint
      await Analytics.updateEndpoint({
        address: authState.user.attributes.email,
        attributes: {
          isAthlete: ['-'],
          emailVerified: ['-'],
          profileBasics: ['-'],
          profileChannel: ['-'],
          stripeStatus: ['-'],
        },
        channelType: 'EMAIL', // Endpoint of type: email (it could be phone number)
        optOut: 'NONE',
        userAttributes: {
          userId: [authState.user.userId],
        },
        userId: authState.user.attributes.email,
      });
    }
  };
  const handlePinpointSignUp = async () => {
    if (authState.user) {
      console.log('handlePinpointSignUp');
      // Create or update endpoint
      await Analytics.updateEndpoint({
        address: authState.user.attributes.email,
        attributes: {
          isAthlete: ['No'],
        },
        channelType: 'EMAIL', // Endpoint of type: email (it could be phone number)
        optOut: 'NONE',
        userAttributes: {
          UserId: [authState.user.userId],
          Interests: ['football', 'basketball', 'AWS'],
          FirstName: ['Pepito grillo'],
        },
        userId: authState.user.attributes.email,
      });
      // Send event
      await Analytics.record({ name: 'SignUp' } /*, { a: 1, b: '2' }*/);
    }
  };
  const handlePinpointAthleteSignUp = async () => {
    if (authState.user) {
      // Create or update endpoint
      await Analytics.updateEndpoint({
        address: authState.user.attributes.email,
        attributes: {
          isAthlete: ['Yes'],
        },
        channelType: 'EMAIL', // Endpoint of type: email (it could be phone number)
        optOut: 'NONE',
        userAttributes: {
          UserId: [authState.user.userId],
          FirstName: ['Pepito grillo'],
        },
        userId: authState.user.attributes.email,
      });
      // Send event
      await Analytics.record({ name: 'AthleteOnboard' });
    }
  };
  const handlePinpointVerifyEmail = async () => {
    if (authState.user) {
      // Create or update endpoint
      await Analytics.updateEndpoint({
        address: authState.user.attributes.email,
        attributes: {
          emailVerified: ['Yes'],
        },
        channelType: 'EMAIL', // Endpoint of type: email (it could be phone number)
        optOut: 'NONE',
        userAttributes: {
          userId: [authState.user.userId],
        },
        userId: authState.user.attributes.email,
      });
      // Send event
      await Analytics.record({ name: 'VerifyEmail' });
    }
  };
  const handlePinpointAthleteWithBasics = async () => {
    if (authState.user) {
      // Create or update endpoint
      await Analytics.updateEndpoint({
        address: authState.user.attributes.email,
        attributes: {
          profileBasics: ['Yes'],
        },
        channelType: 'EMAIL', // Endpoint of type: email (it could be phone number)
        optOut: 'NONE',
        userAttributes: {
          userId: [authState.user.userId],
        },
        userId: authState.user.attributes.email,
      });
    }
  };
  const handlePinpointAthleteWithChannel = async () => {
    if (authState.user) {
      // Create or update endpoint
      await Analytics.updateEndpoint({
        address: authState.user.attributes.email,
        attributes: {
          profileChannel: ['Yes'],
        },
        channelType: 'EMAIL', // Endpoint of type: email (it could be phone number)
        optOut: 'NONE',
        userAttributes: {
          userId: [authState.user.userId],
        },
        userId: authState.user.attributes.email,
      });
    }
  };
  const handlePinpointAthleteStartsStripe = async () => {
    if (authState.user) {
      // Create or update endpoint
      await Analytics.updateEndpoint({
        address: authState.user.attributes.email,
        attributes: {
          stripeStatus: ['Started'],
        },
        channelType: 'EMAIL', // Endpoint of type: email (it could be phone number)
        optOut: 'NONE',
        userAttributes: {
          userId: [authState.user.userId],
        },
        userId: authState.user.attributes.email,
      });
    }
  };
  const handlePinpointAthleteCompletesStripe = async () => {
    if (authState.user) {
      // Create or update endpoint
      await Analytics.updateEndpoint({
        address: authState.user.attributes.email,
        attributes: {
          stripeStatus: ['Completed'],
        },
        channelType: 'EMAIL', // Endpoint of type: email (it could be phone number)
        optOut: 'NONE',
        userAttributes: {
          userId: [authState.user.userId],
        },
        userId: authState.user.attributes.email,
      });
    }
  };
  const handlePinpointNoPosts = async () => {
    if (authState.user) {
      // Create or update endpoint
      await Analytics.updateEndpoint({
        address: authState.user.attributes.email,
        attributes: {
          numPosts: ['cero'],
        },
        channelType: 'EMAIL', // Endpoint of type: email (it could be phone number)
        optOut: 'NONE',
        userAttributes: {
          userId: [authState.user.userId],
        },
        userId: authState.user.attributes.email,
      });
    }
  };
  const handlePinpoint3Posts = async () => {
    if (authState.user) {
      // Create or update endpoint
      await Analytics.updateEndpoint({
        address: authState.user.attributes.email,
        attributes: {
          numPosts: ['3'],
        },
        channelType: 'EMAIL', // Endpoint of type: email (it could be phone number)
        optOut: 'NONE',
        userAttributes: {
          userId: [authState.user.userId],
        },
        userId: authState.user.attributes.email,
      });
    }
  };
  const handlePinpointmore3Posts = async () => {
    if (authState.user) {
      // Create or update endpoint
      await Analytics.updateEndpoint({
        address: authState.user.attributes.email,
        attributes: {
          numPosts: ['more_than_3'],
        },
        channelType: 'EMAIL', // Endpoint of type: email (it could be phone number)
        optOut: 'NONE',
        userAttributes: {
          userId: [authState.user.userId],
        },
        userId: authState.user.attributes.email,
      });
    }
  };
  const handlePinpoint10Posts = async () => {
    if (authState.user) {
      // Create or update endpoint
      await Analytics.updateEndpoint({
        address: authState.user.attributes.email,
        attributes: {
          numPosts: ['10_or_more'],
        },
        channelType: 'EMAIL', // Endpoint of type: email (it could be phone number)
        optOut: 'NONE',
        userAttributes: {
          userId: [authState.user.userId],
        },
        userId: authState.user.attributes.email,
      });
    }
  };
  const handleRunAllClick = async () => {
    await handleUpdateUserClick();
    await handleGetUserClick();
    await handleCreateSubscription();
    await handleUpdateSubscription();
    // Pinpoint
    await handlePinpointSignUp();
  };
  const handleDoItClick = async () => {
    const uploadUrls = await getVideoUploadUrls(1);
    console.log(uploadUrls);
  };
  return (
    <Grid component="main" className={classes.root} container>
      {/* Row 1 */}
      <PageRow>
        <AppHeaderPlaceHolder />
        {/*<Grid container>*/}
        {/*  <Grid item xs={12}>*/}
        {/*    {!localVideoFile && (*/}
        {/*      <DropZone*/}
        {/*        maxFileSizeBytes={100000000000000}*/}
        {/*        onChange={handleFileDragAndDropChange}*/}
        {/*        onRejected={handleFileDragAndDropRejected}*/}
        {/*      />*/}
        {/*    )}*/}
        {/*    {localVideoFile && (*/}
        {/*      <Box display="flex" justifyContent="center">*/}
        {/*        <Button onClick={handleTest}>Upload</Button>*/}
        {/*      </Box>*/}
        {/*    )}*/}
        {/*  </Grid>*/}
        {/*</Grid>*/}

        {/*<Grid item xs={12}>*/}
        {/*  <Box mt={2} mb={2} width={1}>*/}
        {/*    <Divider />*/}
        {/*  </Box>*/}
        {/*</Grid>*/}

        {/*<Grid item xs={12}>*/}
        {/*  <Button onClick={handleUpdateUserClick}>Update user</Button>*/}
        {/*</Grid>*/}
        {/*<Grid item xs={12}>*/}
        {/*  <Button onClick={handleGetUserClick}>Get user</Button>*/}
        {/*</Grid>*/}
        {/*<Grid item xs={12}>*/}
        {/*  <Button onClick={handleCreateSubscription}>Create subscription</Button>*/}
        {/*</Grid>*/}
        {/*<Grid item xs={12}>*/}
        {/*  <Button onClick={handleUpdateSubscription}>Update subscription</Button>*/}
        {/*</Grid>*/}
        <Grid item xs={12}>
          <Box mt={2} mb={2} width={1}>
            <Typography variant="h5">Pinpoint</Typography>
            <Divider />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Button onClick={handlePinpointResetAll}>Reset all</Button>
        </Grid>
        <Grid item xs={12}>
          <Button onClick={handlePinpointSignUp}>Signup</Button>
        </Grid>
        <Grid item xs={12}>
          <Button onClick={handlePinpointAthleteSignUp}>Athlete signup</Button>
        </Grid>
        <Grid item xs={12}>
          <Button onClick={handlePinpointVerifyEmail}>Verify email</Button>
        </Grid>
        <Grid item xs={12}>
          <Button onClick={handlePinpointAthleteWithBasics}>Athlete with basics</Button>
        </Grid>
        <Grid item xs={12}>
          <Button onClick={handlePinpointAthleteWithChannel}>Athlete with channel</Button>
        </Grid>
        <Grid item xs={12}>
          <Button onClick={handlePinpointAthleteStartsStripe}>Athlete starts Stripe</Button>
        </Grid>
        <Grid item xs={12}>
          <Button onClick={handlePinpointAthleteCompletesStripe}>Athlete completes Stripe</Button>
        </Grid>
        <Grid item xs={12}>
          <Button onClick={handlePinpointNoPosts}>No posts</Button>
        </Grid>
        <Grid item xs={12}>
          <Button onClick={handlePinpoint3Posts}>3 Posts</Button>
        </Grid>
        <Grid item xs={12}>
          <Button onClick={handlePinpointmore3Posts}>more than 3 Posts</Button>
        </Grid>
        <Grid item xs={12}>
          <Button onClick={handlePinpoint10Posts}>10 or more Posts</Button>
        </Grid>

        <Grid item xs={12}>
          <Box mt={2} mb={2} width={1}>
            <Divider />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Button color="primary" onClick={handleRunAllClick}>
            Run all
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Box mt={2} mb={2} width={1}>
            <Divider />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Button onClick={handleDoItClick}>Upload Signed Multi-part</Button>
        </Grid>
      </PageRow>
    </Grid>
  );
};

export default TestPage;

async function getVideoUploadUrls(partsCount: number) {
  console.log('getVideoUploadUrls', partsCount);
  const res = await API.get('athletesrestapi', '/api/video/upload-urls', {
    queryStringParameters: {
      parts: partsCount,
    },
  });

  if (res.success) {
    return res.data;
  }

  return null;
}

async function uploadFile(file: any, url: any) {
  console.log('uploading ' + file.name);
  const response = await fetch(url, {
    method: 'PUT',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': file.type,
    },
    body: file,
  });
  console.log('upload status: ' + response.status);
}

interface Part {
  ETag: string;
  PartNumber: number;
}

async function uploadParts(file: Buffer, urls: Record<number, string>): Promise<Part[]> {
  const axios = Axios.create();
  delete axios.defaults.headers.put['Content-Type'];

  const keys = Object.keys(urls);
  const promises: Promise<any>[] = [];

  for (const indexStr of keys) {
    const index = parseInt(indexStr);
    const start = index * FILE_CHUNK_SIZE;
    const end = (index + 1) * FILE_CHUNK_SIZE;
    const blob = index < keys.length ? file.slice(start, end) : file.slice(start);

    promises.push(axios.put(urls[index], blob));
  }

  // Upload all parts in parallel
  const resParts = await Promise.all(promises);

  return resParts.map((part, index) => ({
    ETag: (part as any).headers.etag,
    PartNumber: index + 1,
  }));
}

async function completeUpload(uploadId: string, fileName: string, parts: Part[]) {
  console.log('completeUpload', uploadId, fileName, parts);
  const res = await API.post('athletesrestapi', '/api/video/upload-complete', {
    body: {
      uploadId: uploadId,
      fileName: fileName,
      parts: parts,
    },
  });

  if (res.success) {
    return res.data;
  }

  return null;
}

function file2Buffer(file: File): Promise<Buffer> {
  return new Promise(function (resolve, reject) {
    const reader = new FileReader();
    const readFile = function () {
      const buffer = reader.result as Buffer;

      resolve(buffer);
    };

    reader.addEventListener('load', readFile);
    reader.readAsArrayBuffer(file);
  });
}

/***
const AWS = require('aws-sdk')

const s3 = new AWS.S3({ useAcceslerateEndpoint: true })

async function createPresigned() {
  const region = 'eu-west-1';
  const bucket = 'peakz-challengesdev-dev';
  const key = 'private/short_video-uploaded.mp4';
  const signedUrlExpireSeconds = 60 * 5

  const url = await s3.getSignedUrl('putObject', {
    Bucket: bucket,
    Key: key,
    ContentType: 'video/mp4',
    //Expires: signedUrlExpireSeconds,
    Metadata: {
      a: '1', // Client can't change it
      b: 'The b',// Client can't change it
    }
  });

  console.log(url);
}
**/
