import { Box, Button, Grid, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import React, { useContext } from 'react';
import { appStore } from '../../core/stores/app';
import { Close as CloseIcon } from '@material-ui/icons';
import { FiShare as IOSShareIcon } from 'react-icons/fi';
import config from '../../config';

const useStyles = makeStyles(() => ({
  '@keyframes slideIn': {
    '100%': {
      transform: 'translateX(0%)',
    },
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    position: 'fixed',
    bottom: 0,
    zIndex: 10001,
    transform: 'translateX(-100%)',
    animation: '$slideIn 1s 1 linear',
    animationFillMode: 'forwards',
    '-webkit-animation': '$slideIn 3s 1 linear',
    '-webkit-animation-fill-mode': 'forwards',
  },
  appBannerIconWrapper: {
    width: '40px',
    height: '40px',
    backgroundColor: '#F4F4F4',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
  },
}));

interface Props {
  theme: 'dark' | 'white';
}

const AppInstallBanner: React.FC<Props> = ({ theme }: Props) => {
  const classes = useStyles();

  const [appState, appDispatch] = useContext(appStore);

  const handleDismiss = () => {
    appDispatch({
      type: 'setInstallBannerDismissed',
      installBannerDismissed: true,
    });
    const hubspotIframe: HTMLElement | null = document.querySelector('body #hubspot-messages-iframe-container');
    if (hubspotIframe) {
      hubspotIframe.style.marginBottom = '0px';
    }
  };

  const getBannerMessage = () => {
    const genericInstallPrompt = (
      <Box style={{ padding: '5px' }} width="100%" display="flex">
        <Typography style={{ color: `${theme === 'dark' ? 'white' : 'black'}` }} variant="caption">
          Install the app by adding this website to the homescreen{' '}
        </Typography>
      </Box>
    );

    if (appState.launchInfo?.isMobileiOS) {
      return (
        <Box style={{ padding: '5px' }} width="100%" display="flex">
          <Typography style={{ color: `${theme === 'dark' ? 'white' : 'black'}` }} variant="caption">
            Open in safari, tap this icon <IOSShareIcon /> and add to homescreen
          </Typography>
        </Box>
      );
    } else if (!appState.launchInfo?.isMobileiOS && !appState.installPrompt) {
      return genericInstallPrompt;
    } else {
      return genericInstallPrompt;
    }
  };

  const handleDownload = async () => {
    appState.installPrompt.prompt();
    const { outcome } = await appState.installPrompt.userChoice;
    if (outcome === 'accepted') {
      appDispatch({
        type: 'setInstallBannerDismissed',
        installBannerDismissed: true,
      });
      const launchInfo = Object.assign({}, appState.launchInfo);
      launchInfo.isStandalone = true;
      appDispatch({
        type: 'setLaunchInfo',
        launchInfo: launchInfo,
      });
    }
  };

  return (
    <Box className={classes.root} style={{ backgroundColor: `${theme === 'dark' ? 'black' : 'white'}` }}>
      {!appState.installBannerDismissed && (
        <div style={{ position: 'relative', padding: '10px' }}>
          <IconButton onClick={handleDismiss} style={{ position: 'absolute', top: '-7px', left: '-5px' }}>
            <CloseIcon style={{ fontSize: '12px', color: `${theme === 'dark' ? 'white' : 'black'}` }} />
          </IconButton>
          <Grid container>
            <Grid xs={appState.installPrompt && !appState.launchInfo?.isMobileiOS ? 8 : 12} item>
              <Grid style={{ marginLeft: '5px' }} spacing={2} container>
                <Grid
                  style={{ display: 'flex', justifyContent: 'center' }}
                  xs={appState.installPrompt && !appState.launchInfo?.isMobileiOS ? 3 : 2}
                  sm={1}
                  item
                >
                  <div className={classes.appBannerIconWrapper}>
                    <img width="40px" height="40px" src={`${config.baseUrl}/static/images/logo192.png`} alt="" />
                  </div>
                </Grid>
                <Grid
                  style={{ display: 'flex', alignItems: 'center' }}
                  xs={appState.installPrompt && !appState.launchInfo?.isMobileiOS ? 8 : 10}
                  sm={10}
                  item
                >
                  {getBannerMessage()}
                </Grid>
              </Grid>
            </Grid>
            {appState.installPrompt && !appState.launchInfo?.isMobileiOS && (
              <Grid style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} xs={4} item>
                <Button
                  onClick={handleDownload}
                  style={{ borderRadius: '0', height: '30px' }}
                  variant="contained"
                  size="small"
                  color="primary"
                >
                  Download
                </Button>
              </Grid>
            )}
          </Grid>
        </div>
      )}
    </Box>
  );
};

export default AppInstallBanner;
