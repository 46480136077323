import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import * as log from 'loglevel';

import { PeakzTheme } from '../../theme/theme';
import HoverableContainer from '../../components/elements/HoverableContainer';
import AmbassadorsCarousel from '../../components/ui/AmbassadorsCarousel';
import { CONTENTFUL_MODELS, fetchContentByType } from '../../core/services/contentfulSvc';
import { Athletes } from '../../core/contentful/types';
import domHelpers from '../../core/helpers/dom';

import SpinnerBox from '../../components/ui/SpinnerBox';
import WarningMessage from '../../components/ui/WarningMessage';
import { getContentfulAssetURL, getContentfulRandomArr } from '../../core/helpers/misc';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  storiesTopContainer: {
    backgroundColor: 'black',
    height: 'auto',
    minHeight: '100vh',
    color: 'white',
  },
  gridItem: {
    height: '60vh',
    minHeight: '500px',
    position: 'relative',
  },
}));

const namePrepend = `<b style="color:rgba(63,146,141,255)">JOIN</b>`;

const OurStories: React.FC = () => {
  const classes = useStyles();
  const [desktopAthletes, setDesktopAthletes] = useState<Athletes[]>();
  const theme: PeakzTheme = useTheme();
  const [hoveredState, setHoveredState] = useState(-1);
  const [fetchErr, setFetchErr] = useState<boolean>(false);
  const isSmallScreen = domHelpers.isSmallScreen(useMediaQuery, theme);
  const [t] = useTranslation();

  useEffect(() => {
    (async () => {
      try {
        const resp = await fetchContentByType(CONTENTFUL_MODELS.ATHLETES, 'forCarousel', false);

        //3 random athletes for the homepage rectangles
        setDesktopAthletes(getContentfulRandomArr(resp.data.athletesCollection.items, 3));
      } catch (err) {
        log.error(err);
        setFetchErr(true);
      }
    })();
  }, []);

  return (
    <Box style={{ height: 'auto' }}>
      <Container>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          style={{ minHeight: '25vh' }}
          p={isSmallScreen ? 3 : 10}
        >
          <Box>
            <Typography align={isSmallScreen ? 'justify' : 'center'} variant="h5">
              {t('landing.home.header.description.part1')}
            </Typography>
          </Box>
          <Box mt={5}>
            <Typography align={isSmallScreen ? 'justify' : 'center'} variant="h5">
              {t('landing.home.header.description.part2')}
            </Typography>
          </Box>
          {/* {isSmallScreen && (
            <Box mt={5}>
              <video style={{ backgroundColor: 'black' }} width="100%" height="100%" src=""></video>
            </Box>
          )} */}
        </Box>
      </Container>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        p={isSmallScreen ? 0 : 10}
        className={classes.storiesTopContainer}
      >
        <Typography style={isSmallScreen ? { display: 'none' } : {}} variant="h4" align="center">
          <b>{desktopAthletes ? t('landing.home.our_stories.title') : ''}</b>
        </Typography>
        <Box mt={5}>
          {fetchErr && !isSmallScreen && (
            <WarningMessage
              icon={<ErrorOutlineIcon color="primary" />}
              text="There was a problem retrieving the success stories, please try again later..."
            />
          )}
          {desktopAthletes && !isSmallScreen && (
            <Grid justifyContent="center" container>
              {!fetchErr && !desktopAthletes && (
                <SpinnerBox marginTop={0} styles={{ height: '100%', width: '100%' }} size={100} />
              )}
              {desktopAthletes?.map((data, index) => {
                return (
                  <Grid
                    style={{ margin: theme.typography.pxToRem(25) }}
                    onMouseEnter={() => {
                      setHoveredState(index);
                    }}
                    onMouseLeave={() => {
                      setHoveredState(-1);
                    }}
                    className={classes.gridItem}
                    key={`Peakz_success_story_${index}`}
                    xs={12}
                    md={3}
                    item
                  >
                    <HoverableContainer
                      title={`${namePrepend} ${data.name.toUpperCase()}`}
                      description={data.description}
                      slug="ON PEAKZ"
                      img={getContentfulAssetURL(data, 'image', 100)}
                      hovered={hoveredState === index ? true : false}
                      link={data.url}
                      buttonText="SUBSCRIBE"
                      small={false}
                    />
                  </Grid>
                );
              })}
            </Grid>
          )}
          {isSmallScreen && <AmbassadorsCarousel />}
        </Box>
      </Box>
    </Box>
  );
};

export default OurStories;
