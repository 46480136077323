import React, { useContext, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import { useLocation, useParams } from 'react-router-dom';
import { useHistory } from 'react-router';

import BasicPageLayout from '../../components/layouts/BasicPageLayout';
import { User } from '../../core/graphql/types';
import { fetchUserById } from '../../core/services/userSvc';
import { authStore } from '../../core/stores/auth';
import { AntTab, AntTabs } from '../../components/ui/AntTabs';
import SportsSection, { SportsSectionUpdate } from './SportsSection';
import { appStore } from '../../core/stores/app';
import AthleteProfileBasics, { AthleteProfileBasicsUpdate } from '../../containers/AthleteProfileBasics';
import SpinnerBox from '../../components/ui/SpinnerBox';

const defaultSectionId = 'basics';
const sections = [
  { id: 'basics', title: 'Personal' },
  { id: 'sports', title: 'Sports' },
];
const notFoundErrorMsg = 'It seems you are trying to access a private page';

const AthleteProfileEditPage: React.FC = () => {
  const { sectionId = defaultSectionId }: { sectionId: string } = useParams();
  const location = useLocation();
  const history = useHistory();
  const [authState] = useContext(authStore);
  const [appState] = useContext(appStore);
  const [selectedTab, setSelectedTab] = useState(0);
  const [user, setUser] = useState<User | undefined>();
  const loadUser = async (userId: string) => {
    const user = await fetchUserById(userId, 'full');

    if (user) {
      setUser(user);
    } else {
      history.push({
        pathname: '/notfound',
        state: {
          message: notFoundErrorMsg,
        },
      });
    }
  };
  const handleSelectedTabChange = (event: React.ChangeEvent<any>, newValue: number) => {
    if (newValue < sections.length) {
      const nextSection = sections[newValue].id || defaultSectionId;
      const pathRoot = location.pathname.split('/')[1];

      history.push({
        pathname: `/${pathRoot}/${nextSection}`,
      });
    }
  };
  const handleUserUpdated = (updateObj: AthleteProfileBasicsUpdate | SportsSectionUpdate) => {
    setUser((user) => {
      if (user) {
        return { ...user, ...updateObj } as any;
      }
    });
  };

  useEffect(() => {
    const sectionIndex = sections.findIndex((s) => s.id === sectionId);

    setSelectedTab(sectionIndex >= 0 ? sectionIndex : sections.findIndex((s) => s.id === defaultSectionId));
  }, [sectionId]);

  useEffect(() => {
    try {
      (async function () {
        if (authState.user) {
          loadUser(authState.user.userId);
        }
      })();
    } catch (e) {
      history.push({
        pathname: '/notfound',
        state: {
          message: notFoundErrorMsg,
        },
      });
    }
  }, [authState.user]);

  return (
    <BasicPageLayout
      title="Profile Setup"
      maxWidth={appState.sideMenuVisible ? 'lg' : 'md'}
      showTitle={!appState.sideMenuVisible}
    >
      {/* Tab menu */}
      <Box mb={2}>
        <AntTabs
          value={selectedTab}
          variant="scrollable"
          scrollButtons="auto"
          onChange={handleSelectedTabChange}
          aria-label="ant example"
        >
          {sections.map((s, i) => (
            <AntTab key={i} label={s.title} />
          ))}
        </AntTabs>
      </Box>

      {/* Content */}
      <Box>
        {!user && <SpinnerBox />}
        {user && (
          <>
            {/* Basics */}
            {sections[selectedTab].id === 'basics' && (
              <AthleteProfileBasics user={user} showSport={false} onUserUpdated={handleUserUpdated} />
            )}
            {/* Sports */}
            {sections[selectedTab].id === 'sports' && <SportsSection user={user} onUserUpdated={handleUserUpdated} />}
          </>
        )}
      </Box>
    </BasicPageLayout>
  );
};

export default AthleteProfileEditPage;
