export const fetchBlogsCollection = `
query($withCat: Boolean!){
  blogsCollection(where:{category_exists: $withCat}){
    items{
      sys{
        id
      }
      title,
      thumbnail{
        url
      },
      date,
      author{
        name,
      }
      shortDescription,
      category{
        name
      }
      academyBgColor{
        hexColorCode,
        colorName
      }
    }
  }
}`;

export const getBlogById = `
query($id: String!){
  blogs(id: $id){
    content{
      json,
      links{
        assets{
              block {
                sys {
                  id
                }
                url
                title
                width
                height
                description
              }
        }
      }
    }
}
}`;

export const fetchAthletesCollection = `
query($forCarousel: Boolean!){
  athletesCollection(where:{forCarousel: $forCarousel}){
    items{
      sys{
        id
      }
      name,
      description,
      url,
      image{
        url
      }
      forCarousel,
      oneliner,
    }
  }
}
`;

export const fetchLandingPreviewsCollection = `
query{
  landingPreviewsCollection{
    items{
      thumbnailUrl
      url
      title
    }
  }
}`;
