import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Select } from 'mui-rff';

import globalStyles from '../../theme/globalStyles';

const useStyles = makeStyles((theme) => ({
  formSelect: { ...globalStyles(theme).formSelect },
}));

interface Props {
  id: string;
  name: string;
  required: boolean;
  margin?: 'none' | 'dense' | 'normal';
  disabled: boolean;
  [key: string]: any;
}
const FormSelect: React.FC<Props> = ({ id, name, required, disabled, margin = 'dense', ...props }: Props) => {
  const classes = useStyles();

  return (
    <Select
      id={id}
      name={name}
      className={classes.formSelect}
      inputLabelProps={{
        disabled: disabled,
      }}
      variant="outlined"
      formControlProps={{
        margin: margin,
        size: 'medium',
      }}
      required={required}
      {...props}
    />
  );
};

export default FormSelect;
