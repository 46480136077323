import React from 'react';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

interface Props {
  size?: number;
  styles?: { height: string; width: string };
  marginTop?: number;
}

const SpinnerBox: React.FC<Props> = ({ size = 36, styles, marginTop }: Props) => (
  <Box
    style={styles}
    mt={marginTop !== undefined ? marginTop : 4}
    display="flex"
    alignItems="center"
    justifyContent="center"
  >
    <CircularProgress size={size} />
  </Box>
);

export default SpinnerBox;
