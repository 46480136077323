import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib/types';

import SignupForm, { SignupFormData } from './forms/SignupForm';
import { authStore, AuthUser } from '../core/stores/auth';
import { completeSignIn } from '../core/services/authSvc';
import { appStore } from '../core/stores/app';

interface Props {
  onSignupComplete: () => void;
  onFederatedSignupStart?: () => void;
}

const SignupContainer: React.FC<Props> = ({ onSignupComplete, onFederatedSignupStart }: Props) => {
  const location = useLocation();
  const [, authDispatch] = useContext(authStore);
  const [, appDispatch] = useContext(appStore);
  const [submitting, setSubmitting] = useState(false);
  const [submitType, setSubmitType] = useState<'cognito' | 'google' | 'facebook'>('cognito');
  const [errorTxt, setErrorTxt] = useState('');
  const [errorTxtPosition, setErrorTxtPosition] = useState<'top' | 'bottom'>('bottom');
  const [errorTimeoutId, setErrorTimeoutId] = useState(-1);
  const [unmounted, setUnmounted] = useState(false);

  const handleSignupClick = async (formData: SignupFormData) => {
    setSubmitting(true);
    setSubmitType('cognito');
    try {
      // Signup user
      await Auth.signUp({
        username: formData.email.trim(),
        password: formData.password,
        attributes: {
          email: formData.email.trim(),
          given_name: formData.name,
          name: formData.name,
        },
        validationData: { recaptchaToken: formData.recaptchaToken },
      });

      // Signin the user
      const authUser: AuthUser = await Auth.signIn(formData.email.trim(), formData.password);

      // Send verification email
      // await Auth.verifyCurrentUserAttribute('email');
      // To verify attribute with the code
      //await Auth.verifyCurrentUserAttributeSubmit('email', 'the_verification_code');

      // Trigger App sign in
      await completeSignIn(authUser, authDispatch, appDispatch);
      if (onSignupComplete) {
        onSignupComplete();
      }
    } catch (err: any) {
      console.log(err);
      if (
        err.code === 'UsernameExistsException' ||
        (err.code === 'UserLambdaValidationException' && err.message.indexOf('ERR-004') >= 0)
      ) {
        setError(
          'This email address already has a Peakz account.',
          setErrorTxt,
          setErrorTxtPosition,
          errorTimeoutId,
          setErrorTimeoutId,
        );
      } else if (
        err.code === 'UserLambdaValidationException' &&
        (err.message.indexOf('ERR-005') >= 0 || err.message.indexOf('ERR-006') >= 0)
      ) {
        setError('Recaptcha validation error', setErrorTxt, setErrorTxtPosition, errorTimeoutId, setErrorTimeoutId);
      } else {
        setError(
          err.message ? err.message.replace('PreSignUp failed with error ', '') : 'Oops...something went wrong :(',
          setErrorTxt,
          setErrorTxtPosition,
          errorTimeoutId,
          setErrorTimeoutId,
        );
      }
    }
    setSubmitting(false);
  };
  const handleGoogleSignInClick = async () => {
    setSubmitting(true);
    setSubmitType('google');

    // Notify Federated login is about to start
    if (onFederatedSignupStart) {
      onFederatedSignupStart();
    }

    // Start federated sign in
    await Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google,
    });
  };
  const handleFacebookSignInClick = async () => {
    setSubmitting(true);
    setSubmitType('facebook');

    // Notify Federated login is about to start
    if (onFederatedSignupStart) {
      onFederatedSignupStart();
    }

    // Start federated sign in
    await Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Facebook,
    });
  };

  useEffect(() => {
    if (!unmounted) {
      const searchParams = new URLSearchParams(window.location.search);
      const errorDesc = searchParams.get('error_description');

      // Added by Cognito in case of PreSignUp error
      if (errorDesc && errorDesc.startsWith('PreSignUp')) {
        setErrorTxtPosition('top');
        setError(
          'This email address already has a Peakz account.',
          setErrorTxt,
          setErrorTxtPosition,
          errorTimeoutId,
          setErrorTimeoutId,
        );
      }
    }

    return () => {
      setUnmounted(true);
    };
  }, [location.search]);

  return (
    <Paper variant="outlined">
      <Box p={2}>
        <SignupForm
          submitting={submitting}
          submitType={submitType}
          errorTxt={errorTxt}
          errorTxtPosition={errorTxtPosition}
          onSignupClick={handleSignupClick}
          onGoogleSignInClick={handleGoogleSignInClick}
          onFacebookSignInClick={handleFacebookSignInClick}
        />
      </Box>
    </Paper>
  );
};

export default SignupContainer;

function setError(
  txt: string,
  setFormErrorTxt: Dispatch<SetStateAction<string>>,
  setErrorTxtPosition: Dispatch<SetStateAction<'top' | 'bottom'>>,
  errorTimeoutId: number,
  setErrorTimeoutId: Dispatch<SetStateAction<number>>,
  closeDelay = 30000,
) {
  if (errorTimeoutId) {
    clearTimeout(errorTimeoutId);
  }

  if (txt) {
    errorTimeoutId = window.setTimeout(() => {
      // Restore defaults
      setFormErrorTxt('');
      setErrorTxtPosition('bottom');
    }, closeDelay);
  }

  setFormErrorTxt(txt);
  setErrorTimeoutId(errorTimeoutId);
}
