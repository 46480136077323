import React from 'react';
import { Box, makeStyles, Dialog, Typography } from '@material-ui/core';
import DialogTitle from '../ui/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import domHelpers from '../../core/helpers/dom';
import { PeakzTheme } from '../../theme/theme';
import FacebookConnect from '../elements/FacebookConnect';
import TwitterConnect from '../elements/TwitterConnect';

const useStyles = makeStyles((theme: PeakzTheme) => ({
  root: {
    position: 'relative',
    '& .MuiBackdrop-root': {
      [theme.breakpoints.down('xs')]: {
        backgroundColor: 'white',
      },
    },
    '& .MuiDialog-container .MuiPaper-root': {
      maxHeight: theme.typography.pxToRem(360),
      [theme.breakpoints.down('xs')]: {
        maxHeight: '100%',
        height: '100%',
        boxShadow: 'none',
      },
    },
    '& .MuiPaper-root': {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        margin: 0,
        overflowY: 'hidden',
      },
    },
  },
  socialConnectButton: {
    borderRadius: theme.typography.pxToRem(20),
    height: theme.typography.pxToRem(35),
    border: 'none',
    minWidth: theme.typography.pxToRem(140),
    color: 'white',
  },
}));

interface Props {
  showTwitterLogin: boolean;
  showFacebookLogin: boolean;
  instagramShared: boolean;
  postIdParam?: string;
  open: boolean;
  setOpen: any;
}

const SocialConnectDialog: React.FC<Props> = ({
  showTwitterLogin,
  showFacebookLogin,
  instagramShared,
  postIdParam,
  open,
  setOpen,
}: Props) => {
  const theme = useTheme();
  const classes = useStyles();
  const isExtraSmallScreen = domHelpers.isXtraSmallScreen(useMediaQuery, theme);
  const isSmallScreen = domHelpers.isSmallScreen(useMediaQuery, theme);

  return (
    <Dialog className={classes.root} fullWidth={true} maxWidth={isExtraSmallScreen ? undefined : 'xs'} open={open}>
      <DialogTitle
        onClose={() => {
          setOpen(false);
        }}
        id="user-list-dialog-title"
      >
        {`Connect with ${showTwitterLogin ? 'Twitter' : 'Facebook'}`}
      </DialogTitle>
      <Box p={isSmallScreen ? 2 : 3}>
        {showFacebookLogin && (
          <Box>
            <FacebookConnect />
          </Box>
        )}
        {showTwitterLogin && (
          <Box mt={showFacebookLogin ? 2 : 0}>
            <TwitterConnect instagramShared={instagramShared} postIdParam={postIdParam} />
          </Box>
        )}
        <Box mt={2}>
          <Typography variant="caption">
            <i>After linking your account you may close this dialog and proceed to share your post</i>
          </Typography>
        </Box>
      </Box>
    </Dialog>
  );
};

export default SocialConnectDialog;
