import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { PaymentsAccount } from '../core/graphql/types';
import { countryToFlag } from '../core/helpers/misc';
import { getCountryName } from '../core/helpers/selectOptions';

interface InfoItemProps {
  title: string;
  children: React.ReactNode;
}
const InfoItem: React.FC<InfoItemProps> = ({ title, children }: InfoItemProps) => {
  return (
    <Grid item xs={12}>
      <Grid container>
        <Grid item xs={6} sm={3}>
          <Typography variant="body1" color="textSecondary">
            {title}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={3}>
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
};

interface Props {
  paymentsAccount: PaymentsAccount;
}
const AthletePaymentsAccountDetails: React.FC<Props> = ({ paymentsAccount }: Props) => {
  return (
    <Grid container spacing={1}>
      {paymentsAccount.business_profile && paymentsAccount.business_profile.url && (
        <InfoItem title="Business url">
          <Typography variant="body1">
            <a href={paymentsAccount.business_profile.url}>{paymentsAccount.business_profile.url}</a>
          </Typography>
        </InfoItem>
      )}
      <InfoItem title="Email">
        <Typography variant="body1">{paymentsAccount.email}</Typography>
      </InfoItem>
      <InfoItem title="Country">
        <Typography variant="body1">
          {getCountryName(paymentsAccount.country)} {countryToFlag(paymentsAccount.country)}
        </Typography>
      </InfoItem>
      <InfoItem title="Currency">
        <Typography variant="body1">{paymentsAccount.default_currency.toUpperCase()}</Typography>
      </InfoItem>
    </Grid>
  );
};

export default AthletePaymentsAccountDetails;
