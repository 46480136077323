import React, { useContext, useState } from 'react';
import { makeRequired } from 'mui-rff';
import * as Yup from 'yup';
import Switch from '@material-ui/core/Switch';

import PaperFormElem from '../../components/ui/PaperFormElem';
import PaperForm from '../../components/ui/PaperForm';
import FormRow from '../../components/ui/FormRow';
import { User } from '../../core/graphql/types';
import { updateUser } from '../../core/services/userSvc';
import { appStore } from '../../core/stores/app';

const formSchema = Yup.object().shape({
  toggleDark: Yup.boolean(),
});

const required = makeRequired(formSchema);

export interface AccountThemeIdFormData {
  toggleDark?: boolean;
}

interface Props {
  user?: User;
  toggleDark?: boolean;
  settoggleDark?: any;
  onUserUpdated?: (update: any) => void;
}

const General: React.FC<Props> = ({ user, onUserUpdated, toggleDark, settoggleDark }) => {
  const [, appDispatch] = useContext(appStore);

  const [submitting, setSubmitting] = useState(false);
  const formId = 'choose-mode-form';

  const handleModeChange = async () => {
    const newValues = {
      themeId: 'light',
    };
    if (!toggleDark) {
      newValues.themeId = 'dark';
    }

    settoggleDark(!toggleDark);

    if (user) {
      setSubmitting(true);
      await updateUser(user.id, newValues, appDispatch);
      setSubmitting(false);

      if (onUserUpdated) {
        onUserUpdated(newValues);
      }
    }
  };

  return (
    <form id={formId}>
      <PaperForm>
        {/* Choose theme */}
        <PaperFormElem title="Dark mode" required={required.givenName}>
          <FormRow label="Use dark mode">
            <Switch
              checked={toggleDark}
              onChange={handleModeChange}
              name="toggleDark"
              color="default"
              disabled={submitting}
            />
          </FormRow>
        </PaperFormElem>
      </PaperForm>
    </form>
  );
};

export default General;
