import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core';

import AppHeaderPlaceHolder from '../components/layouts/AppHeaderPlaceHolder';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: { width: '100%', minHeight: '20vh', padding: theme.spacing(3) },
  contentWrapper: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    height: '100%',
  },
  content: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    height: '100%',
  },
  img: {
    animation: `$levitate 3000ms ${theme.transitions.easing.easeInOut}`,
    animationIterationCount: 'infinite',
  },
  '@keyframes levitate': {
    '0%': {
      transform: 'translateY(3%)',
    },
    '50%': {
      transform: 'translateY(0%)',
    },
    '100%': {
      transform: 'translateY(3%)',
    },
  },
}));

interface LocationState {
  message?: string;
}

const NotFoundPage: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [t] = useTranslation();
  const { state: locationState }: { state: LocationState } = useLocation();

  return (
    <div className={classes.root}>
      <Container className={classes.contentWrapper} maxWidth="lg">
        <Grid className={classes.content} container justifyContent="center" alignItems="center" direction="column">
          <Grid item>
            <AppHeaderPlaceHolder />
            <img
              className={classes.img}
              style={{ maxWidth: theme.typography.pxToRem(500), width: '100%' }}
              src="/static/images/notfound.svg"
            />
          </Grid>
          <Grid item>
            <Typography variant="h4">
              <br /> {locationState && locationState.message ? locationState.message : t('not_found_page.text')}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default NotFoundPage;
