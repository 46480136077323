import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    color: '#fff',
    fontSize: theme.typography.pxToRem(16),
    height: theme.typography.pxToRem(42),
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.pxToRem(14),
      height: theme.typography.pxToRem(32),
    },
  },
  rootOutlined: {
    color: theme.palette.primary.main,
  },
  whiteSpinner: {
    //marginLeft: theme.spacing(1),
    color: '#fff',
  },
  primarySpinner: {
    //marginLeft: theme.spacing(1),
    color: theme.palette.primary.main,
  },
}));

interface Props {
  submitting?: boolean;
  type?: 'submit' | 'button';
  color?: 'inherit' | 'primary' | 'secondary' | 'default';
  children: React.ReactNode;
  variant?: 'outlined' | 'contained';
  originalSize?: boolean;
  [key: string]: any;
}

const SubmitButton: React.FC<Props> = ({
  submitting = false,
  type = 'submit',
  color = 'primary',
  children,
  ...props
}: Props) => {
  const classes = useStyles();
  const { originalSize, ...theProps } = props;

  return (
    <Button
      className={clsx({
        [classes.root]: !originalSize,
        [classes.rootOutlined]: props.variant === 'outlined',
      })}
      type={type}
      variant="contained"
      color={color}
      endIcon={
        submitting ? (
          <CircularProgress
            className={clsx({
              [classes.primarySpinner]: props['variant'] && props['variant'] !== 'contained',
              [classes.whiteSpinner]: !props['variant'] || props['variant'] === 'contained',
            })}
            size={28}
            thickness={4}
          />
        ) : (
          ''
        )
      }
      {...theProps}
    >
      {children}
    </Button>
  );
};

export default SubmitButton;
