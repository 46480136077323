import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
//import { makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import { authStore } from '../../../core/stores/auth';
import BasicPageLayout from '../../../components/layouts/BasicPageLayout';
import SubmitButton from '../../../components/ui/SubmitButton';
import PaperFormActions from '../../../components/ui/PaperFormActions';
import AthleteSearch from '../../../components/elements/AthleteSearch';
import { User } from '../../../core/graphql/types';
import { adminDashboardRoute } from '../../../core/helpers/route';
import { adminSendPushNotification } from '../../../core/services/adminSvc';
import { appStore } from '../../../core/stores/app';

export const pageTitle = 'Push Notifications';
const targetTypes = {
  allFans: 'all_fans',
  allAthletes: 'all_athletes',
  athleteSubscribers: 'all_subscribers',
  athleteList: 'some_athletes',
};

const PushNotificationsDashboard: React.FC = () => {
  const [authState] = useContext(authStore);
  const [, appDispatch] = useContext(appStore);
  const history = useHistory();
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [targetType, setTargetUsersType] = useState(targetTypes.allFans);
  const [selectedAthlete, setSelectedAthlete] = useState<User | null>(null);
  const [targetUsers, setTargetUsers] = useState<User[]>([]);
  const [submitting, setSubmitting] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const handleTitleChange = (e: any) => {
    setTitle(e.target.value);
  };

  const handleTextChange = (e: any) => {
    setBody(e.target.value);
  };
  const handleTargetTypeChange = (e: any) => {
    setTargetUsersType(e.target.value);
  };
  const handleAthleteSearchSelect = (user: User) => {
    setSelectedAthlete(user);
  };
  const handleAddAthleteClick = () => {
    if (selectedAthlete) {
      setTargetUsers((t: User[]) => {
        if (!t.find((u) => u.id === selectedAthlete.id)) {
          return [...t, selectedAthlete];
        }
        return [...t];
      });
      setSelectedAthlete(null);
    }
  };
  const handleRemoveTargetUserClick = (user: User) => {
    setTargetUsers((t: User[]) => {
      const itemIndex = t.findIndex((u) => u.id === user.id);

      if (itemIndex >= 0) {
        return [...t.slice(0, itemIndex), ...t.slice(itemIndex + 1)];
      }

      return [...t];
    });
  };
  const handleSubmitClick = async () => {
    setSubmitting(true);
    const ids = [];
    if (targetType === targetTypes.athleteList || targetType === targetTypes.athleteSubscribers) {
      for (const target of targetUsers) {
        ids.push(target.id);
      }
    }
    try {
      const res: any = await adminSendPushNotification(targetType, title, body, ids);
      const { successfulNotifications } = JSON.parse(res);
      appDispatch({
        type: 'showToast',
        toastTxt: `${successfulNotifications} notifications were sent`,
      });
    } catch (e) {
      appDispatch({
        type: 'showToast',
        toastTxt: 'Notifications not sent, please try again later',
        toastSeverity: 'error',
      });
    }
    setSubmitting(false);
  };

  // Check logged user
  useEffect(() => {
    if (authState.user) {
      // Page restricted to: admins and moderators
      if (!authState.isAdmin && !authState.isModerator) {
        history.push('/home');
        return;
      }
    }
  }, [authState.user]);

  // Check logged user
  useEffect(() => {
    setSubmitDisabled(
      !(
        Boolean(body) &&
        Boolean(title) &&
        Boolean(targetType) &&
        (targetType === targetTypes.allFans ||
          targetType === targetTypes.allAthletes ||
          ((targetType === targetTypes.athleteSubscribers || targetType === targetTypes.athleteList) &&
            targetUsers.length > 0))
      ),
    );
  }, [body, targetType, targetUsers]);

  return (
    <BasicPageLayout
      title={pageTitle}
      maxWidth="md"
      showTitle={true}
      breadCrumbs={[{ title: 'Admin', route: adminDashboardRoute }, { title: pageTitle }]}
    >
      <Paper variant="outlined">
        {/* Target */}
        <Box p={3}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={3}>
              <Typography variant="body1">
                <b>Message content</b>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Paper variant="outlined" square>
                <Box p={2}>
                  <TextField
                    placeholder="Type here the title..."
                    label="Title"
                    variant="outlined"
                    fullWidth
                    onChange={handleTitleChange}
                  />
                </Box>
                <Box p={2}>
                  <TextField
                    placeholder="Type here the text of the message..."
                    label="Message"
                    variant="outlined"
                    multiline
                    minRows={4}
                    maxRows={4}
                    fullWidth
                    onChange={handleTextChange}
                  />
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>
        {/* Target Type */}
        <Box p={3}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={3}>
              <Typography variant="body1">
                <b>Message target</b>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Paper variant="outlined" square>
                <Box p={2}>
                  <RadioGroup
                    aria-label="target-type"
                    defaultValue={targetType}
                    name="radio-buttons-group"
                    value={targetType}
                    onChange={handleTargetTypeChange}
                  >
                    <FormControlLabel value={targetTypes.allFans} control={<Radio />} label="All Fans" />
                    <FormControlLabel value={targetTypes.allAthletes} control={<Radio />} label="All Athletes" />
                    <FormControlLabel
                      value={targetTypes.athleteSubscribers}
                      control={<Radio />}
                      label="Athlete Subscribers"
                    />
                    <FormControlLabel value={targetTypes.athleteList} control={<Radio />} label="Athlete List" />
                  </RadioGroup>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>
        {/* Target */}
        {[targetTypes.athleteSubscribers, targetTypes.athleteList].includes(targetType) && (
          <Box p={3}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={3}>
                <Typography variant="body1">
                  <b>Target users</b>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <Paper variant="outlined" square>
                  <Box p={2}>
                    <Grid container alignItems="center">
                      <Grid item xs={12}>
                        <AthleteSearch
                          value={selectedAthlete}
                          inverted={false}
                          placeHolder={'Find Athlete...'}
                          onSelect={handleAthleteSearchSelect}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Box display="flex" justifyContent="center" mt={2}>
                          <Button
                            disabled={!selectedAthlete}
                            endIcon={<ArrowDownwardIcon fontSize="large" />}
                            onClick={handleAddAthleteClick}
                          >
                            Add Athlete
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                    {targetUsers.length > 0 && (
                      <Box mt={2}>
                        <Paper variant="outlined" square>
                          <List dense={true}>
                            {targetUsers.map((u: User) => (
                              <ListItem key={u.id}>
                                <ListItemText primary={u.name} />
                                <ListItemSecondaryAction>
                                  <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    onClick={handleRemoveTargetUserClick.bind(null, u)}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </ListItemSecondaryAction>
                              </ListItem>
                            ))}{' '}
                          </List>
                        </Paper>
                      </Box>
                    )}
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        )}
        <Divider />
        <Box p={2}>
          <PaperFormActions>
            <Grid container spacing={1} justifyContent="flex-end">
              <Grid item>
                <SubmitButton disabled={submitDisabled} submitting={submitting} onClick={handleSubmitClick}>
                  Push message
                </SubmitButton>
              </Grid>
            </Grid>
          </PaperFormActions>
        </Box>
      </Paper>
    </BasicPageLayout>
  );
};

export default PushNotificationsDashboard;
