import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Container, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import CTAButton from './CTAButton';
import { Link } from 'react-router-dom';
import { PeakzTheme } from '../../theme/theme';
import { lighten } from '@material-ui/core/styles/colorManipulator';

interface SwitcherContent {
  title: string;
  slug: string;
  text: string;
}

interface Props {
  content: SwitcherContent[];
  background: string;
  buttonText: string;
  buttonLink: string;
}

const useStyles = makeStyles((theme) => ({
  gridSectionItem: {
    margin: theme.typography.pxToRem(10),
    cursor: 'pointer',
  },
  highlighted: {
    borderLeft: '4px solid',
    borderColor: theme.palette.primary.light,
    [theme.breakpoints.down('sm')]: {
      width: theme.typography.pxToRem(22),
      borderBottom: '4px solid',
      borderColor: theme.palette.primary.light + '!important',
    },
  },
  greyed: {
    borderLeft: `4px solid ${lighten('#000', 0.6)}`,
    color: lighten('#000', 0.7),
    [theme.breakpoints.down('sm')]: {
      width: theme.typography.pxToRem(22),
      borderBottom: `4px solid ${lighten('#000', 0.6)}`,
    },
  },
}));

const TextSwitcher: React.FC<Props> = ({ content, background, buttonText, buttonLink }: Props) => {
  const classes = useStyles();
  const theme: PeakzTheme = useTheme();
  const [sectionIndex, setSectionIndex] = useState(0);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const getHighlightClass = (index: number) => {
    if (sectionIndex !== index) {
      return classes.greyed;
    }

    return classes.highlighted;
  };

  return (
    <div style={{ backgroundColor: `${background}` }}>
      <Container style={background === 'white' ? { color: 'black' } : { color: 'white' }}>
        <Box p={isSmallScreen ? 3 : 10}>
          <Typography data-testid="title" variant="h4" align="center">
            <b>{content[sectionIndex].title}</b>
          </Typography>
          <Box mt={5}>
            <Grid justifyContent="center" wrap="wrap-reverse" container direction="row">
              <Grid item md={2}>
                <Grid
                  style={isSmallScreen ? { marginTop: theme.typography.pxToRem(40) } : {}}
                  container
                  direction={isSmallScreen ? 'row' : 'column'}
                >
                  {content.map((data: { slug: string; text: string }, index: number) => {
                    return (
                      <Grid
                        onClick={() => {
                          setSectionIndex(index);
                        }}
                        className={classes.gridSectionItem}
                        key={`Peakz_highlights_${index}`}
                        item
                      >
                        <Typography
                          data-testid="label"
                          style={{ paddingLeft: theme.typography.pxToRem(10) }}
                          className={getHighlightClass(index)}
                        >
                          <b>{!isSmallScreen ? data.slug : ''}</b>
                        </Typography>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
              <Grid item md={9}>
                <Typography data-testid="text" align={isSmallScreen ? 'center' : 'left'} variant="h5">
                  {content[sectionIndex].text}
                </Typography>
              </Grid>
            </Grid>
            <Box mt={5} display="flex" justifyContent="center">
              <Link to={buttonLink}>
                <CTAButton variant="contained" color="primary" size="large">
                  {buttonText}
                </CTAButton>
              </Link>
            </Box>
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default TextSwitcher;
