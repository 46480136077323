import { Theme } from '@material-ui/core/styles';

const scrollToTop = (behavior?: 'smooth' | 'auto') => {
  setTimeout(() => {
    window.scrollTo({
      top: 0,
      behavior: behavior,
    });
  }, 0);
};

function scrollToElem(elem: any, ref?: any, immediate = false, incY = 0) {
  setTimeout(
    () => {
      try {
        const nextTop = (ref ? elem.current.offsetTop - ref.current.offsetHeight : elem.current.offsetTop) + incY;

        window.scrollTo({
          top: nextTop,
          behavior: 'smooth',
        });
      } catch (err) {
        scrollToElem(elem, ref, immediate);
      }
    },
    immediate ? 0 : 100,
  );
}

export const isSmallScreen = (useMediaQuery: (breakpoint: string) => boolean, theme: Theme) => {
  return useMediaQuery(theme.breakpoints.down('sm'));
};
export const isXtraSmallScreen = (useMediaQuery: (breakpoint: string) => boolean, theme: Theme) => {
  return useMediaQuery(theme.breakpoints.down('xs'));
};

export default {
  scrollToTop,
  scrollToElem,
  isSmallScreen,
  isXtraSmallScreen,
};
