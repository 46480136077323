import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Button from '@material-ui/core/Button';

import { PeakzTheme } from '../../theme/theme';
import StripeTextField from '../../components/ui/StripeTexField';

const useStyles = makeStyles((theme: PeakzTheme) => ({
  submitButton: {
    fontSize: theme.typography.pxToRem(16),
    color: '#fff',
    height: theme.typography.pxToRem(48),
  },
  lockIcon: {
    color: theme.palette.text.secondary,
    verticalAlign: 'middle',
  },
}));

interface Props {
  submitting: boolean;
  submitButtonText?: string;
  onSubmitClick: (name: string, cardNumberElement: any) => void;
}

const CreditCardForm: React.FC<Props> = ({ submitting, submitButtonText, onSubmitClick }: Props) => {
  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();
  const [formFieldsValid, setFormFieldsValid] = useState<any>({
    name: false,
    cardNumber: false,
    expiration: false,
    cvc: false,
  });
  const [formValues, setFormValues] = useState<any>();
  const [isFormValid, setIsFormValid] = useState(false);
  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (elements && stripe) {
      // Use elements.getElement to get a reference to the mounted Element.
      const cardNumberElement = elements.getElement(CardNumberElement);

      if (cardNumberElement) {
        onSubmitClick(formValues['name'] || 'Unknown', cardNumberElement);
      }
    }
  };
  const handleFieldChange = (name: string, value?: string, isValid?: boolean) => {
    if (isValid !== undefined) {
      setFormFieldsValid((ff: any) => ({ ...ff, [name]: isValid }));
    }

    setFormValues((ff: any) => ({ ...ff, [name]: value }));
  };

  useEffect(() => {
    const isValid = Boolean(
      Object.values(formFieldsValid).reduce((acc, currentValue) => {
        return acc && currentValue;
      }, true),
    );

    setIsFormValid(isValid);
  }, [formFieldsValid]);

  return (
    <form onSubmit={handleSubmit} noValidate={true} autoComplete="new-password">
      <Box mb={2}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            {/* Name on Card */}
            <StripeTextField
              label="Name on card"
              name="name"
              component={'input'}
              autoFocus={true}
              defaultErrorMessage="Name on card is required"
              disabled={submitting}
              onChange={handleFieldChange}
            />
          </Grid>
          <Grid item xs={12}>
            {/* Card number */}
            <StripeTextField
              label="Card Number"
              name="cardNumber"
              component={CardNumberElement}
              icon={<LockOutlinedIcon className={classes.lockIcon} fontSize="small" />}
              disabled={submitting}
              onChange={handleFieldChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="space-around" spacing={4}>
              {/* Expiration */}
              <Grid item xs={6}>
                <StripeTextField
                  label="Expiration"
                  name="expiration"
                  component={CardExpiryElement}
                  disabled={submitting}
                  onChange={handleFieldChange}
                />
              </Grid>
              {/* CVC */}
              <Grid item xs={6}>
                <StripeTextField
                  label="CVC"
                  name="cvc"
                  component={CardCvcElement}
                  disabled={submitting}
                  onChange={handleFieldChange}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box mt={3} width={1}>
        <Button
          className={classes.submitButton}
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          endIcon={submitting ? <CircularProgress size={24} /> : ''}
          disabled={!isFormValid || submitting}
        >
          {`${submitButtonText || 'Submit'}`}
        </Button>
      </Box>
    </form>
  );
};

export default CreditCardForm;
