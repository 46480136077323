import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

export default withStyles((theme) => ({
  root: {
    borderRadius: theme.typography.pxToRem(9999),
    fontWeight: 500,
    fontFamily: theme.typography.fontFamily,
    boxShadow: 'none',
    width: theme.typography.pxToRem(200),
    minWidth: theme.typography.pxToRem(120),
    height: theme.typography.pxToRem(56),
    fontSize: theme.typography.pxToRem(16),
    maxWidth: '100%',
    '&:active, &:focus': {
      boxShadow: 'none',
    },
  },
}))(Button);
