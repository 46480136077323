const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    const crossOrigin = 'anonymous';

    image.crossOrigin = crossOrigin;
    image.onload = function () {
      resolve(image);
    };
    image.addEventListener('error', (error) => {
      console.log('error', error);
      reject(error);
    });
    image.setAttribute('crossOrigin', crossOrigin); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

const createImageFromBase64 = (src: string, style?: string): Promise<HTMLImageElement> =>
  new Promise((resolve) => {
    const image = new Image();

    image.onload = function () {
      resolve(image);
    };
    if (style) {
      image.setAttribute('style', style);
    }
    image.src = src;
  });

export function getRadianAngle(degreeValue: number) {
  return (degreeValue * Math.PI) / 180;
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 *
 * @returns A JPEG image in base64
 */
export async function getCroppedImg(
  imageSrc: string,
  pixelCrop: any,
  maxOutputWidth?: number,
  maxOutputHeight?: number,
): Promise<string | null> {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');
  const maxSize = Math.max(image.width, image.height);
  const safeArea = maxSize;

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea;
  canvas.height = safeArea;
  console.log(safeArea);
  if (ctx) {
    // translate canvas context to a central location on image to allow rotating around the center.
    //ctx.translate(safeArea / 2, safeArea / 2);
    //ctx.rotate(getRadianAngle(rotation));
    //ctx.translate(-safeArea / 2, -safeArea / 2);

    // draw rotated image and store data.
    ctx.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5);
    const data = ctx.getImageData(0, 0, safeArea, safeArea);

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // paste generated rotate image with correct offsets for x,y crop values.
    ctx.putImageData(
      data,
      Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
      Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y),
    );

    // resize resulting image if needed
    if ((maxOutputWidth && canvas.width > maxOutputWidth) || (maxOutputHeight && canvas.height > maxOutputHeight)) {
      const resizedCanvas = document.createElement('canvas');
      const resizedContext = resizedCanvas.getContext('2d');

      if (maxOutputWidth) {
        resizedCanvas.width = Math.min(canvas.width, maxOutputWidth);
      }
      if (maxOutputHeight) {
        resizedCanvas.height = Math.min(canvas.height, maxOutputHeight);
      }

      if (resizedContext) {
        resizedContext.drawImage(canvas, 0, 0, resizedCanvas.width, resizedCanvas.height);

        // As Resized Base64 string
        return resizedCanvas.toDataURL('image/png');
      }
    }

    // As Base64 string
    return canvas.toDataURL('image/png');

    // As a blob
    /*return new Promise((resolve) => {
      canvas.toBlob((file) => {
        resolve(URL.createObjectURL(file));
      }, 'image/jpeg');
    });*/
  }

  return null;
}

export const toBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export const toBase64FromUrl = (url: string): Promise<string> =>
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.onload = function () {
      const reader = new FileReader();
      reader.readAsDataURL(xhr.response);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    };
    xhr.send();
  });

export const blurryBase64Img = async (base64: string) => {
  const img: any = await createImageFromBase64(base64);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  canvas.width = img.width;
  canvas.height = img.height;

  if (ctx) {
    // BROWSER_FEATURE: Not supported by safari
    // Pollyfill added in index.html (<script src="https://cdn.jsdelivr.net/npm/context-filter-polyfill@0.2.4/dist/index.min.js"></script>)
    ctx.filter = 'blur(6px)';
    ctx.drawImage(img, 0, 0);

    return canvas.toDataURL();
  }

  return '';
};

export default {
  createImageFromBase64,
  getCroppedImg,
  toBase64,
  toBase64FromUrl,
  blurryBase64Img,
};
