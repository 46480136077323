import React, { useState } from 'react';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import SelectableCards, { SelectableData } from '../../components/ui/SelectableCards';

export enum POST_MONETIZATION {
  paid = 0,
  free = 1,
}

interface Props {
  onPostMonetizationSelect: any;
}

const data: SelectableData[] = [
  {
    title: 'Free content',
    icon: <MoneyOffIcon />,
    value: POST_MONETIZATION.free,
  },
  {
    title: 'Paid content',
    icon: <AttachMoneyIcon />,
    value: POST_MONETIZATION.paid,
  },
];

const FreeOrPaidPostSelector: React.FC<Props> = ({ onPostMonetizationSelect }: Props) => {
  const [postMonetization, setPostMonetization] = useState<POST_MONETIZATION>(POST_MONETIZATION.paid);

  const handlePostMonetizationSelection = (monetization: POST_MONETIZATION) => {
    setPostMonetization(monetization);
    onPostMonetizationSelect(monetization === POST_MONETIZATION.paid);
  };

  return (
    <SelectableCards
      title="Tell us if this content is paid or free"
      data={data}
      selectedItemValue={postMonetization}
      onCardClick={handlePostMonetizationSelection}
    />
  );
};

export default FreeOrPaidPostSelector;
