import React from 'react';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  height100: {
    height: '100%',
  },
  dashboardItem: {
    backgroundColor: theme.palette.grey[100],
  },
}));

const DashboardItem: React.FC<{ children: React.ReactNode | React.ReactNode[] }> = ({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}) => {
  const classes = useStyles();

  return (
    <Paper className={classes.height100}>
      <Box className={classes.height100} width={1} p={2}>
        {children}
      </Box>
    </Paper>
  );
};

export default DashboardItem;
