import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { Box, makeStyles } from '@material-ui/core';
import AppHeaderPlaceHolder from '../components/layouts/AppHeaderPlaceHolder';

const acceptedDocNames = ['privacy-policy', 'terms-of-use', 'security-policy', 'cookie-statement'];

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(24),
    paddingRight: theme.spacing(24),
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(12),
      paddingRight: theme.spacing(12),
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    '& a': {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
}));

const DocsPage: React.FC = () => {
  const params: any = useParams();
  const history = useHistory();
  const [content, setContent] = useState();
  const classes = useStyles();

  const fetchContent = async () => {
    const response: any = await fetch(`../static/docs/${params.docId}.md`);
    const markdown = await response.text();
    console.log(markdown);
    setContent(markdown);
  };

  useEffect(() => {
    if (acceptedDocNames.includes(params.docId)) {
      fetchContent();
    } else {
      history.push({
        pathname: '/nodocfound',
        state: {
          message: "The doc you're looking for doesn't exists",
        },
      });
    }
  }, []);

  return (
    <Box>
      <AppHeaderPlaceHolder />
      <ReactMarkdown className={classes.root}>{content || ''}</ReactMarkdown>);
    </Box>
  );
};

export default DocsPage;
