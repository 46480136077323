/*
 * Using mui-rff
 * Check following link for more info: https://github.com/lookfirst/mui-rff
 * */

import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import * as Yup from 'yup';
import { CheckboxData, Checkboxes, makeRequired, makeValidate, showErrorOnBlur, TextField } from 'mui-rff';
import { Form, FormSpy } from 'react-final-form';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import Dialog from '@material-ui/core/Dialog/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';

import { countryToFlag, micknameRegex, removeNullAttributes } from '../../core/helpers/misc';
import { User, userRoles } from '../../core/graphql/types';
import { countries, genders, sports, sportsObj } from '../../core/helpers/selectOptions';
import DialogTitle from '../../components/ui/DialogTitle';
import FormRow from '../../components/ui/FormRow';
import FormTextField from '../../components/ui/FormTextField';
import globalStyles from '../../theme/globalStyles';
import FormDatePicker from '../../components/ui/FormDatePicker';
import FormSelect from '../../components/ui/FormSelect';
import FormAutocomplete from '../../components/ui/FormAutocomplete';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submitBtnWrapper: {
    position: 'relative',
  },
  submitBtn: {
    margin: theme.spacing(2, 0, 1),
    height: 50,
  },
  submitBtnProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  charCounter: {
    position: 'absolute',
    right: theme.spacing(1),
    bottom: theme.spacing(1 / 2),
  },
  countryOption: {
    fontSize: 15,
    '& > span': {
      marginRight: theme.spacing(1),
      fontSize: 18,
    },
  },
  formTextField: { ...globalStyles(theme).formTextField },
  formSelect: { ...globalStyles(theme).formSelect },
  formAutocomplete: { ...globalStyles(theme).formAutocomplete },
  formDatePicker: { ...globalStyles(theme).formDatePicker },
}));

interface AthleteInfoDialogProps {
  open: boolean;
  user?: User;
  title?: string;
  submitting?: boolean;
  showNickname?: boolean;
  showSocial?: boolean;
  showReachTop?: boolean;
  onSubmit: (data: AthleteInfoDialogData) => void;
  onCancel: () => void;
}

export interface AthleteInfoDialogData {
  nickname?: string;
  givenName: string;
  familyName: string;
  gender: string;
  birthdate: string | null;
  country: string;
  sport: string;
  altSport?: string;
  reachTop?: boolean;
  instagramUrl: string;
  facebookUrl: string;
  youtubeUrl: string;
}

const formSchema = Yup.object().shape({
  nickname: Yup.string()
    .ensure()
    .test('pattern', 'Wrong username', (val) => val.match(micknameRegex)),
  givenName: Yup.string().required('Name is a required field.'),
  familyName: Yup.string().required('Last name is a required field.'),
  gender: Yup.string().required('Gender is a required field.'),
  birthdate: Yup.string()
    .required('Date of birth is a required field.')
    .test('len', 'This is not a valid date', (val) => {
      return val !== 'Invalid Date';
    })
    .nullable(true),
  country: Yup.string().required('Country is a required field.'),

  sport: Yup.string().required('Sport is a required field.'),
  altSport: Yup.string().when('sport', {
    is: 'different',
    then: (fieldSchema: any) => fieldSchema.required('You have to specify your sport'),
  }),
  reachTop: Yup.boolean().test('reachTop', 'It must be checked.', (val) => {
    return val;
  }),
  instagramUrl: Yup.string().test('instagram', 'It must be an Instagram account', (val) => {
    try {
      return !val || val.startsWith('https://www.instagram.com/');
    } catch (e) {}

    return false;
  }),
  facebookUrl: Yup.string().test('facebook', 'It must be a Facebook account', (val) => {
    try {
      return !val || val.startsWith('https://www.facebook.com/');
    } catch (e) {}
    return false;
  }),
  youtubeUrl: Yup.string().test('youtube', 'It must be a YouTube channel', (val) => {
    try {
      return !val || val.startsWith('https://www.youtube.com/channel') || val.startsWith('https://youtube.be/');
    } catch (e) {}
    return false;
  }),
});

const validate = makeValidate(formSchema);
const required = makeRequired(formSchema);
const checkboxData: CheckboxData[] = [{ label: "I'm going for the top in my sports", value: 'topInSports' }];

const AthleteInfoDialog: React.FC<AthleteInfoDialogProps> = ({
  open,
  user,
  title,
  submitting,
  showNickname = false,
  showSocial = true,
  showReachTop = false,
  onSubmit,
  onCancel,
}: AthleteInfoDialogProps) => {
  const classes = useStyles();
  const [altSportSelected, setAltSportSelected] = useState<boolean>(false);
  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const formId = 'athlete-info-dialog-form';
  const subscription = { pristine: true, invalid: true };
  const today = new Date();
  const initialValues = removeNullAttributes({
    nickname: user?.nickname,
    givenName: user?.givenName,
    familyName: user?.familyName,
    gender: user?.gender,
    birthdate: user?.birthdate,
    sport: user?.sport,
    country: user?.country,
    reachTop: user?.role === userRoles.athlete,
    instagramUrl: user?.instagramUrl,
    facebookUrl: user?.facebookUrl,
    youtubeUrl: user?.youtubeUrl,
  });
  const birthdateProps = initialValues.familyName && !initialValues.birthdate ? { autoFocus: true } : {};
  const handleFormChange = ({ values }: any) => {
    const isAltSport = values.sport === 'different';

    console.log(values);

    setAltSportSelected(isAltSport);
  };
  const handleSubmitClick = (values: AthleteInfoDialogData) => {
    const { altSport, ...valuesToSubmit } = values;

    // It should not be sent to the server
    delete valuesToSubmit.reachTop;

    if (valuesToSubmit.sport === 'different') {
      if (altSport) {
        valuesToSubmit.sport = altSport;
        onSubmit(valuesToSubmit);
      }
      return;
    }

    onSubmit(valuesToSubmit);
  };
  const handleCancelClick = () => {
    onCancel();
  };

  // If the sport is not listed
  if (initialValues.sport && !sportsObj[initialValues.sport]) {
    initialValues.altSport = initialValues.sport;
    initialValues.sport = 'different';
  }

  return (
    <Form
      onSubmit={handleSubmitClick}
      initialValues={initialValues}
      subscription={subscription}
      validate={validate}
      key={subscription as any}
      render={({ handleSubmit, pristine, invalid }) => (
        <form
          id={formId}
          className={classes.form}
          onSubmit={handleSubmit}
          noValidate={true}
          autoComplete="new-password"
        >
          <FormSpy subscription={{ values: true }} onChange={handleFormChange} />
          <Dialog
            open={open}
            maxWidth="sm"
            fullWidth={true}
            fullScreen={!isBigScreen}
            aria-labelledby="form-dialog-name"
          >
            <DialogTitle id="form-dialog-name" onClose={handleCancelClick}>
              {''}
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6">{title || 'Personal profile'}</Typography>
                  <Box mb={2}>
                    <Divider />
                  </Box>
                </Grid>
                {/* Nickname */}
                {showNickname && (
                  <Grid item xs={12}>
                    <FormRow label="Your Peakz channel link">
                      <TextField
                        id="nickname"
                        name="nickname"
                        variant="outlined"
                        autoFocus
                        required={required.nickname}
                        disabled={submitting}
                        showError={showErrorOnBlur}
                        fullWidth
                        margin="dense"
                        size="medium"
                        InputProps={{
                          classes: { input: classes.formTextField },
                          startAdornment: <InputAdornment position="start">peakz.com/</InputAdornment>,
                        }}
                      />
                    </FormRow>
                  </Grid>
                )}

                {/* Given name */}
                <Grid item xs={12} sm={6}>
                  <FormRow label="First name">
                    <FormTextField
                      id="givenName"
                      name="givenName"
                      autoFocus={!showNickname}
                      required={required.givenName}
                      disabled={submitting}
                    />
                  </FormRow>
                </Grid>
                {/* Family name */}
                <Grid item xs={12} sm={6}>
                  <FormRow label="Last name">
                    <FormTextField
                      id="familyName"
                      name="familyName"
                      required={required.familyName}
                      disabled={submitting}
                    />
                  </FormRow>
                </Grid>
                {/* Gender */}
                <Grid item xs={12} sm={6}>
                  <FormRow label="Gender">
                    <FormSelect
                      id="gender"
                      name="gender"
                      disabled={Boolean(submitting)}
                      required={required.gender}
                      data={genders}
                      multiple={false}
                    />
                  </FormRow>
                </Grid>
                {/* Date of birth */}
                <Grid item xs={12} sm={6}>
                  <FormRow label="Date of birth">
                    <FormDatePicker
                      id="birthdate"
                      name="birthdate"
                      maxDate={today}
                      initialFocusedDate={new Date(today.getFullYear() - 18, today.getMonth(), today.getDate())}
                      disabled={submitting}
                      required={required.birthdate}
                      {...birthdateProps}
                    />
                  </FormRow>
                </Grid>

                {/* Country */}
                <Grid item xs={12} sm={6}>
                  <FormRow label="Country">
                    <FormAutocomplete
                      id="country"
                      name="country"
                      required={required.country}
                      disabled={Boolean(submitting)}
                      options={countries}
                      getOptionValue={(option: any) => option.value}
                      getOptionLabel={(option: any) => option.label}
                      renderOption={(option: any) => (
                        <div className={classes.countryOption}>
                          <span>{countryToFlag(String(option.value))}</span>
                          {option.label}
                        </div>
                      )}
                    />
                  </FormRow>
                </Grid>
                {/* Sport */}
                <Grid item xs={12} sm={6}>
                  <FormRow label="Sport">
                    <FormAutocomplete
                      id="sport"
                      name="sport"
                      required={required.sport}
                      disabled={Boolean(submitting)}
                      options={sports}
                      getOptionValue={(option: any) => option.value}
                      getOptionLabel={(option: any) => option.label}
                      renderOption={(option: any) => <div>{option.label}</div>}
                    />
                  </FormRow>
                  {/* AltSport */}
                  {altSportSelected && (
                    <Box mt={1}>
                      <FormRow label="What's your sport?">
                        <FormTextField id="altSport" name="altSport" required={required.altSport} />
                      </FormRow>
                    </Box>
                  )}
                </Grid>

                {showReachTop && !submitting && (
                  <Grid item xs={12}>
                    <Checkboxes name="reachTop" required={required.reachTop} data={checkboxData} />
                  </Grid>
                )}

                {showSocial && (
                  <>
                    <Grid item xs={12}>
                      <Typography variant="h6">Social Information</Typography>
                      <Divider />
                    </Grid>

                    {/* Instagram Url */}
                    <Grid item xs={12}>
                      <FormRow label="Instagram link">
                        <FormTextField id="instagramUrl" name="instagramUrl" required={required.instagramUrl} />
                      </FormRow>
                    </Grid>

                    {/* Facebook Url */}
                    <Grid item xs={12}>
                      <FormRow label="Facebook link">
                        <FormTextField id="facebookUrl" name="facebookUrl" required={required.facebookUrl} />
                      </FormRow>
                    </Grid>

                    {/* Youtube Url */}
                    <Grid item xs={12}>
                      <FormRow label="YouTube link">
                        <FormTextField id="youtubeUrl" name="youtubeUrl" required={required.youtubeUrl} />
                      </FormRow>
                    </Grid>
                  </>
                )}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancelClick}>Cancel</Button>
              <Button
                form={formId}
                color="primary"
                type="submit"
                disabled={invalid || submitting || pristine}
                endIcon={submitting ? <CircularProgress size={24} /> : <ArrowForwardIcon fontSize="large" />}
              >
                Save changes
              </Button>
            </DialogActions>
          </Dialog>
        </form>
      )}
    />
  );
};

export default AthleteInfoDialog;
