import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Container, useMediaQuery } from '@material-ui/core';
import { getRealHeight, letterSpacing, PeakzTheme } from '../../theme/theme';
import { Link } from 'react-router-dom';
import CTAButton from './CTAButton';
import { useTheme } from '@material-ui/core';
import domHelpers from '../../core/helpers/dom';
import ProgressiveImage from 'react-progressive-image';

const useStyles = makeStyles((theme: PeakzTheme) => ({
  header: {
    position: 'relative',
    color: 'white',
    backgroundColor: '#000000',
    width: '100%',
    overflow: 'hidden',
    height: getRealHeight('100vh'),
    minHeight: theme.typography.pxToRem(400),
    [theme.breakpoints.down('sm')]: {
      minHeight: getRealHeight('100vh'),
      '& img': {
        height: '100%',
      },
    },
  },
  imgStyles: {
    width: 'auto',
    height: '100%',
    position: 'absolute',
    top: '-9999px',
    left: '-9999px',
    right: '-9999px',
    bottom: '-9999px',
    margin: 'auto',
    minHeight: theme.typography.pxToRem(1500),
  },
  overlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    boxShadow: 'rgba(0, 0, 0, .75) 0 0 0 10000px inset',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& .MuiTypography-root': {
      fontWeight: 'normal',
      letterSpacing: letterSpacing.minimal,
      [theme.breakpoints.down('sm')]: {
        letterSpacing: letterSpacing.tiny,
      },
    },
  },
  subheader: {
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    minHeight: '45vh',
    '& .MuiBox-root:first-child': {
      textAlign: 'center',
    },
    [theme.breakpoints.down(1026)]: {
      minHeight: '25vh',
    },
  },
}));

interface Props {
  content: {
    progressiveArr: string[];
    headerTitle?: string;
    headerMinusTitle?: string;
    headerSubtitle: string;
    CTAText?: string;
    CTALink: string;
    subtitleTitle?: string;
    subtitleSub?: string;
    CTA2Text?: string;
    CTA2Link?: string;
  };
}

const OverlayedHeaderText: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const withSub = props.content.subtitleSub;
  const theme = useTheme();
  const isSmallScreen = domHelpers.isSmallScreen(useMediaQuery, theme);

  return (
    <div>
      <div className={classes.header}>
        <ProgressiveImage src={props.content.progressiveArr[0]} placeholder={props.content.progressiveArr[1]}>
          {(src: string) => <img src={src} className={classes.imgStyles} alt="Header image" />}
        </ProgressiveImage>
        <Box className={classes.overlay}>
          {props.content.headerTitle && (
            <Typography align="center" variant="h3">
              <b>{props.content.headerTitle}</b>
            </Typography>
          )}
          {props.content.headerMinusTitle && (
            <Box style={isSmallScreen ? { width: '90%' } : {}} mt={2}>
              <Typography
                dangerouslySetInnerHTML={{ __html: props.content.headerMinusTitle }}
                align="center"
                variant="h4"
              ></Typography>
            </Box>
          )}
          <Box style={isSmallScreen ? { width: '90%' } : {}} mt={2}>
            <Typography
              dangerouslySetInnerHTML={{ __html: props.content.headerSubtitle }}
              align="center"
              variant="h6"
            ></Typography>
          </Box>
          {props.content.CTAText && (
            <Box mt={5}>
              <Link to={props.content.CTALink} rel="noopener">
                <CTAButton variant="contained" color="primary" size="large">
                  <b>{props.content.CTAText}</b>
                </CTAButton>
              </Link>
            </Box>
          )}
        </Box>
      </div>
      {withSub && (
        <div className={classes.subheader}>
          <Container>
            <Box p={isSmallScreen ? 3 : 10}>
              <Box width={1}>
                <Typography variant="h4">
                  <b>{props.content.subtitleTitle}</b>
                </Typography>
              </Box>
              <Box p={0} mt={props.content.subtitleTitle !== undefined ? 5 : 0}>
                <Typography
                  dangerouslySetInnerHTML={{ __html: props.content.subtitleSub || '' }}
                  align={isSmallScreen ? 'justify' : 'center'}
                  variant="h5"
                ></Typography>
              </Box>
              <Box
                style={props.content.CTA2Text === undefined ? { display: 'none' } : {}}
                height={1}
                display="flex"
                justifyContent="center"
                mt={5}
              >
                <Link to={props.content.CTA2Link || ''}>
                  <CTAButton variant="contained" color="primary" size="large">
                    <b>{props.content.CTA2Text}</b>
                  </CTAButton>
                </Link>
              </Box>
            </Box>
          </Container>
        </div>
      )}
    </div>
  );
};

export default OverlayedHeaderText;
