import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SportsIconType } from '../../core/helpers/selectOptions';

const useStyles = makeStyles((theme) => ({
  root: {},
  bg: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
    display: 'inline-block',
    position: 'relative',
  },
  shiftIcon: {
    position: 'absolute',
    top: '30%',
    left: '30%',
  },
  button: {
    cursor: 'pointer',
    '&:hover': {
      fill: theme.palette.primary.main,
    },
  },
  primaryColor: {
    fill: theme.palette.primary.main,
  },
  whiteColor: {
    fill: '#ffffff',
  },
}));

interface Props {
  icon: SportsIconType;
  showBg?: boolean;
  size?: number;
  isButton?: boolean;
  color?: 'default' | 'primary' | 'white';
  className?: any;
}

const svgPath = '/static/icons/sports/favorites.min.svg';

const SportsIcon: React.FC<Props> = ({
  icon,
  size = 32,
  showBg = false,
  isButton = false,
  color = 'default',
  className,
}: Props) => {
  const classes = useStyles();
  let colorClass = '';
  let iconSize = size;

  if (color === 'primary') {
    colorClass = classes.primaryColor;
  } else if (color === 'white') {
    colorClass = classes.whiteColor;
  }

  if (showBg) {
    iconSize = size * 0.9;
  }

  // 16, 24, 36, 50
  return (
    <i
      className={`icon-user-male-circle ${showBg ? classes.bg : ''}`}
      style={{ width: `${size}px`, height: `${size}px` }}
    >
      <svg
        className={`${isButton ? classes.button : ''} ${showBg ? classes.shiftIcon : ''} ${colorClass} ${className}`}
        width={`${iconSize}px`}
        height={`${iconSize}px`}
        dangerouslySetInnerHTML={{ __html: `<use xlink:href="${svgPath}#${icon}"></use>` }}
      ></svg>
    </i>
  );
};

export default SportsIcon;
