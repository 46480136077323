import React from 'react';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

interface StyledTabProps {
  label?: React.ReactNode;
  fullWidth?: boolean;
  icon?: string | React.ReactElement;
  disabled?: boolean;
}

export const AntTabs = withStyles((theme: Theme) => ({
  root: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    height: 52,
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
    height: '1px',
  },
}))(Tabs);

export const AntTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'none',
      minWidth: 72,
      fontWeight: 400,
      fontSize: theme.typography.pxToRem(16),
      marginRight: theme.spacing(4),
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
      '&:hover': {
        color: theme.palette.primary.main,
        opacity: 1,
      },
      '&$selected': {
        color: theme.palette.primary.main,
      },
      '&:focus': {
        color: theme.palette.primary.main,
      },
    },
    selected: {},
  }),
)((props: StyledTabProps) => <Tab disableRipple {...props} />);
