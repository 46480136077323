import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import { useTranslation } from 'react-i18next';

import { searchAthletes } from '../../core/services/userSvc';
import { User } from '../../core/graphql/types';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  inverted: {},
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  inputRoot: {
    color: 'inherit',
    height: '100%',
    width: '100%',
    '& input': {
      width: '100%',
      height: theme.typography.pxToRem(42),
      fontSize: theme.typography.pxToRem(18),
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.primary.main}`,
    marginLeft: 0,
    width: '100%',
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      // marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchInverted: {
    border: 'none',
    color: theme.palette.text.primary,
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '20ch',
      '&:focus': {
        width: '25ch',
      },
    },
  },
}));

interface Props {
  value: User | null;
  inverted: boolean;
  placeHolder?: string;
  styles?: any;
  onSelect: (user: User) => void;
}

const AthleteSearch: React.FC<Props> = ({ value: initValue, inverted, placeHolder, onSelect, styles }: Props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState<User | null>(initValue);
  const [searching, setSearching] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState<User[]>([]);
  const [open, setOpen] = React.useState(false);
  const [t] = useTranslation();

  React.useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    (async () => {
      setSearching(true);
      const res = await searchAthletes({
        name: {
          matchPhrasePrefix: inputValue,
        },
      });
      setSearching(false);

      if (active && res) {
        setOptions(res.athletes);
      }
    })();

    return () => {
      active = false;
    };
  }, [value, inputValue]);

  React.useEffect(() => {
    if (inputValue === '') {
      setOpen(false);
    }
  }, [inputValue]);

  // check if we have to reset the component
  React.useEffect(() => {
    if (initValue === null) {
      setInputValue('');
      setValue(null);
    }
  }, [initValue]);

  return (
    <Autocomplete
      id="athlete-search"
      className={`${classes.root} ${inverted ? classes.inverted : ''}`}
      getOptionLabel={(option) => option.name}
      options={options}
      open={open}
      onOpen={() => {
        if (inputValue) {
          setOpen(true);
        }
      }}
      onClose={() => {
        setOpen(false);
      }}
      autoComplete
      includeInputInList
      noOptionsText={!searching ? t('global.athlete_not_found') : '...'}
      value={value}
      onChange={(event: any, newValue: User | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        if (newValue) {
          onSelect(newValue);
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <div
          className={`${classes.search} ${inverted ? classes.searchInverted : ''} ${styles}`}
          ref={params.InputProps.ref}
        >
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder={placeHolder || t('components.athlete_search.placeholder_text')}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            {...params.inputProps}
          />
        </div>
      )}
      renderOption={(option) => {
        const matches = match(option.name, inputValue);
        const parts = parse(option.name, matches);

        return (
          <Grid container alignItems="center">
            <Grid item>
              {parts.map((part: any, index: any) => (
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              ))}
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

export default AthleteSearch;
