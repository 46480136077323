import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import { User } from '../core/graphql/types';
import { updateUser } from '../core/services/userSvc';
import { uploadImgBase64 } from '../core/services/imgSvc';
import urlHelpers from '../core/helpers/url';
import { authStore } from '../core/stores/auth';
import ImageCroppingDialog from './dialogs/ImageCroppingDialog';
import userHelpers from '../core/helpers/user';
import { appStore } from '../core/stores/app';

const avatarWidth = 120;
const useStyles = makeStyles((theme) => ({
  editableAvatar: {
    border: `${theme.typography.pxToRem(4)} solid #fff`,
    width: theme.typography.pxToRem(avatarWidth),
    height: theme.typography.pxToRem(avatarWidth),
  },
  avatarEditIcon: {
    border: `${theme.typography.pxToRem(1)} solid ${theme.palette.background.paper}`,
    width: '32px',
    height: '32px',
    '& >  svg': {
      color: theme.palette.text.primary,
    },
  },
}));

export interface AvatarUpdate {
  profileImgS3Key?: string;
}

interface Props {
  user?: User | null;
  editable?: boolean;
  className?: any;
  onUpdated?: (update: AvatarUpdate) => void;
}

const EditableAvatar: React.FC<Props> = ({ user, editable = true, className = '', onUpdated }: Props) => {
  const classes = useStyles();
  const [authState] = useContext(authStore);
  const [, appDispatch] = useContext(appStore);
  const [submitting, setSubmitting] = useState(false);
  const [imageCroppingDialogOpen, setImageCroppingDialogOpen] = useState<boolean>(false);
  const [profileImgUrl, setProfileImgUrl] = useState('');
  const [croppingImgUrl, setCroppingImgUrl] = useState<string>('');
  const handleAvatarEditClick = () => {
    if (editable) {
      setCroppingImgUrl(profileImgUrl);
      setImageCroppingDialogOpen(true);
    }
  };
  const handleImageCropSubmit = async (profileImgBase64: string) => {
    if (user && authState.userInfo) {
      setSubmitting(true);
      const updateObj = {
        profileImgS3Key: await uploadImgBase64(
          urlHelpers.createUserProfileImgS3Key(),
          authState.userInfo.userIdentityId,
          profileImgBase64,
          'protected',
        ),
      };
      await updateUser(user.id, updateObj, appDispatch);
      setSubmitting(false);
      setImageCroppingDialogOpen(false);

      if (onUpdated) {
        onUpdated(updateObj);
      }
    }
  };
  const handleImageCropCancel = () => {
    setImageCroppingDialogOpen(false);
  };

  useEffect(() => {
    setProfileImgUrl(user?.profileImgS3Key ? userHelpers.getProfileImg(user) : '');
  }, [user]);

  return (
    <>
      <label htmlFor="upload-profile-img-btn" onClick={handleAvatarEditClick}>
        <Badge
          style={{ cursor: editable ? 'pointer' : 'default' }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          overlap="circular"
          badgeContent={
            editable && (
              <Avatar className={classes.avatarEditIcon}>
                <PhotoCameraIcon />
              </Avatar>
            )
          }
        >
          <Avatar
            className={`${classes.editableAvatar} ${className ? className : ''}`}
            alt="avatar"
            src={profileImgUrl}
          >
            <Typography variant="h3">{user && userHelpers.getInitials(user)}</Typography>
          </Avatar>
        </Badge>
      </label>

      {imageCroppingDialogOpen && (
        <ImageCroppingDialog
          title=""
          open={imageCroppingDialogOpen}
          imgUrl={croppingImgUrl}
          aspect={userHelpers.imgDimensions.profile.width / userHelpers.imgDimensions.profile.height}
          outputWidth={userHelpers.imgDimensions.profile.width}
          outputHeight={userHelpers.imgDimensions.profile.height}
          submitting={submitting}
          onSubmit={handleImageCropSubmit}
          onCancel={handleImageCropCancel}
          onClose={handleImageCropCancel}
        ></ImageCroppingDialog>
      )}
    </>
  );
};

export default EditableAvatar;
