import React, { useState, useEffect } from 'react';
import Fade from '@material-ui/core/Fade';

const FadeIn: React.FC<{ timeout?: number; children: React.ReactNode }> = ({
  timeout = 1000,
  children,
}: {
  timeout?: number;
  children: React.ReactNode;
}) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    console.log('visible');
    setVisible(true);
  }, []);

  return (
    <Fade in={visible} timeout={timeout}>
      <div>{children}</div>
    </Fade>
  );
};

export default FadeIn;
