import React, { useContext, useState } from 'react';
import { makeRequired, makeValidate } from 'mui-rff';
import * as Yup from 'yup';
import { Form } from 'react-final-form';
import { Auth } from 'aws-amplify';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';

import PaperFormElem from '../../components/ui/PaperFormElem';
import PaperForm from '../../components/ui/PaperForm';
import { User } from '../../core/graphql/types';
import { appStore } from '../../core/stores/app';
import { authStore } from '../../core/stores/auth';
import PaperFormActions from '../../components/ui/PaperFormActions';
import SubmitButton from '../../components/ui/SubmitButton';
import FormTextField from '../../components/ui/FormTextField';
import FormRow from '../../components/ui/FormRow';

const formSchema = Yup.object().shape({
  currentPassword: Yup.string()
    .required('Current password is a required field.')
    .min(8, 'Passwords must have 8 characters or more.'),
  newPassword: Yup.string()
    .required('New password is a required field.')
    .min(8, 'Passwords must have 8 characters or more.'),
  passwordConfirmation: Yup.string()
    .required('New password mus be confirmed.')
    .oneOf([Yup.ref('newPassword')], 'Passwords must match'),
});

const validate = makeValidate(formSchema);
const required = makeRequired(formSchema);

export interface AccountSecurityFormData {
  currentPassword: string;
  newPassword: string;
  passwordConfirmation: string;
}

interface Props {
  user?: User;
}

const AccountSecurity: React.FC<Props> = () => {
  const [, appDispatch] = useContext(appStore);
  const [authState] = useContext(authStore);
  const initialValues = {};
  const subscription = { pristine: true, invalid: true };
  const [submitting, setSubmitting] = useState(false);
  const [showCurrentPwd, setShowCurrentPwd] = useState(false);
  const [showNewPwd, setShowNewPwd] = useState(false);
  const [showConfirmationPwd, setShowConfirmationPwd] = useState(false);
  const formId = 'account-security-form';
  const handleSubmitClick = async (values: AccountSecurityFormData) => {
    if (authState.user) {
      try {
        setSubmitting(true);
        const authUser = await Auth.currentAuthenticatedUser();

        await Auth.changePassword(authUser, values.currentPassword, values.newPassword);

        appDispatch({
          type: 'showToast',
          toastTxt: 'Password changed properly',
          toastSeverity: 'success',
        });
      } catch (err: any) {
        let errMsg;

        if (err.code === 'NotAuthorizedException') {
          errMsg = 'Current password is not correct';
        } else {
          errMsg = err.message;
        }

        appDispatch({
          type: 'showToast',
          toastTxt: errMsg,
          toastSeverity: 'error',
        });
      } finally {
        setSubmitting(false);
      }
    }
  };

  return (
    <Form
      onSubmit={handleSubmitClick}
      initialValues={initialValues}
      subscription={subscription}
      validate={validate}
      render={({ form, handleSubmit, pristine, invalid }) => (
        <form id={formId} onSubmit={handleSubmit} noValidate={true} autoComplete="new-password">
          <PaperForm
            actions={
              <PaperFormActions>
                <Grid container spacing={1} justifyContent="flex-end">
                  <Grid item>
                    <SubmitButton
                      disabled={invalid || submitting || pristine}
                      submitting={false}
                      color="default"
                      type="button" // to avoid the form being submitted
                      onClick={form.reset}
                    >
                      Cancel
                    </SubmitButton>
                  </Grid>
                  <Grid item>
                    <SubmitButton disabled={invalid || submitting || pristine} submitting={submitting}>
                      Submit
                    </SubmitButton>
                  </Grid>
                </Grid>
              </PaperFormActions>
            }
          >
            {/* Change Password */}
            <PaperFormElem title="Change password" required={required.givenName}>
              {/* Current Password */}
              <FormRow label="Current password">
                <FormTextField
                  id="currentPassword"
                  name="currentPassword"
                  type={showCurrentPwd ? 'text' : 'password'}
                  required={required.currentPassword}
                  disabled={submitting}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowCurrentPwd((s) => !s)}
                        >
                          {showCurrentPwd ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormRow>
              {/* New Password */}
              <FormRow label="New password">
                <FormTextField
                  id="newPassword"
                  name="newPassword"
                  type={showNewPwd ? 'text' : 'password'}
                  required={required.newPassword}
                  disabled={submitting}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton aria-label="toggle password visibility" onClick={() => setShowNewPwd((s) => !s)}>
                          {showNewPwd ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormRow>
              {/* Password Confirmation */}
              <FormRow label="Password confirmation">
                <FormTextField
                  id="passwordConfirmation"
                  name="passwordConfirmation"
                  type={showConfirmationPwd ? 'text' : 'password'}
                  required={required.passwordConfirmation}
                  disabled={submitting}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowConfirmationPwd((s) => !s)}
                        >
                          {showConfirmationPwd ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormRow>
            </PaperFormElem>
          </PaperForm>
        </form>
      )}
      key={subscription as any}
    />
  );
};

export default AccountSecurity;
