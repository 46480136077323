import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import EditIcon from '@material-ui/icons/Edit';

import { User } from '../../core/graphql/types';
import { updateUser } from '../../core/services/userSvc';
import AthleteIntroDialog, { AthleteIntroDialogData } from '../../containers/dialogs/AthleteIntroDialog';
import urlHelpers from '../../core/helpers/url';
import { authStore } from '../../core/stores/auth';
import userHelpers from '../../core/helpers/user';
import { extractYoutubeVideoID } from '../../core/helpers/misc';
import VideoPlayer from '../../components/ui/VideoPlayer';
import { uploadVideoToS3 } from '../../core/services/videoSvc';
import { appStore } from '../../core/stores/app';

const useStyles = makeStyles((theme) => ({
  challengeInfoRoot: {
    width: '100%', // material-ui: +16px
    margin: 0, // materiail-ui: -8px
    height: 'fit-content',
  },
  signature: {
    fontFamily: 'BrittanySignature',
  },
  videoPlayer: {
    width: '100%',
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    height: theme.typography.pxToRem(300),
    [theme.breakpoints.down('xs')]: {
      height: theme.typography.pxToRem(200),
    },
  },
}));

interface Props {
  athlete?: User;
  editable: boolean;
  onUserUpdated: (update: { mission?: string; introVideoS3Key?: string; introVideoThumbS3Key?: string }) => void;
}

const AboutMeBlock: React.FC<Props> = ({ athlete, editable, onUserUpdated }: Props) => {
  const classes = useStyles();
  const [authState] = useContext(authStore);
  const [, appDispatch] = useContext(appStore);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [introVideoUrl, setIntroVideoUrl] = useState('');
  const [introVideoThumbUrl, setIntroVideoThumbUrl] = useState('');
  const [introVideoYoutubeId, setIntroVideoYoutubeId] = useState('');
  const handleEditClick = () => {
    setEditDialogOpen(true);
  };
  const handleEditSubmit = async ({ mission, introVideoFile }: AthleteIntroDialogData) => {
    if (athlete && authState.userInfo) {
      const update: {
        mission: string;
        introVideoS3Key?: string;
        introVideoThumbS3Key?: string;
      } = { mission };

      setSubmitting(true);
      if (introVideoFile) {
        const path = urlHelpers.getAthleteIntroVideoPath();
        const s3VideoKey = await uploadVideoToS3(
          introVideoFile,
          'protected',
          path.s3BasePath,
          path.fileNamePrefix,
          {
            refTag: 'athleteintrovideo',
            refOwner: authState.userInfo.userId,
            refOwnerIdentityId: authState.userInfo.userIdentityId,
          },
          () => {
            // videoUploadProgress: number
            // setState((state) => ({ ...state, videoUploadProgress: videoUploadProgress }));
          },
        );
        update.introVideoS3Key = s3VideoKey;
        update.introVideoThumbS3Key = '';
      }

      console.log(athlete.introVideoS3Key, athlete.introVideoThumbS3Key);
      await updateUser(athlete.id, update, appDispatch);
      setSubmitting(false);
      onUserUpdated(update);

      setEditDialogOpen(false);
    }
  };
  const handleEditCancel = () => {
    setEditDialogOpen(false);
  };

  useEffect(() => {
    if (athlete) {
      // TODO: Add a method to use S3 thumbnail once generated
      if (athlete.introVideoS3Key) {
        setIntroVideoUrl(urlHelpers.getMediaUrlFromS3Key(athlete.introVideoS3Key, 'video'));
        setIntroVideoThumbUrl(userHelpers.getIntroVideoThumbUrl(athlete));
      } else {
        if (athlete.introVideoUrl) {
          const videoId = extractYoutubeVideoID(athlete.introVideoUrl);

          if (videoId) {
            setIntroVideoYoutubeId(videoId);
          }
        }
      }

      console.log('Update', athlete.introVideoS3Key, athlete.introVideoThumbS3Key);
    }

    return () => {
      setIntroVideoUrl('');
      setIntroVideoThumbUrl('');
    };
  }, [athlete]);

  return (
    <div className={classes.challengeInfoRoot}>
      {/* Title */}
      <Typography variant="h4" align="left" gutterBottom>
        <b>About me</b>
        {editable && (
          <IconButton color="primary" onClick={handleEditClick}>
            <EditIcon fontSize="small" />
          </IconButton>
        )}
      </Typography>
      {/* Youtube Intro Video */}
      {athlete && introVideoYoutubeId && athlete.introVideoUrl && (
        <Box mb={2}>
          <VideoPlayer className={classes.videoPlayer} videoUrl={athlete.introVideoUrl} isYoutube={true} />
        </Box>
      )}
      {/* Intro Video */}
      {athlete && !introVideoYoutubeId && introVideoUrl && (
        <Box mb={2}>
          <VideoPlayer className={classes.videoPlayer} videoUrl={introVideoUrl} videoThumbUrl={introVideoThumbUrl} />
        </Box>
      )}
      {/* Mission */}
      <Typography variant="body1" align="left">
        {athlete?.mission ? athlete.mission : "I'm still thinking what to tell about myself..."}
      </Typography>
      {/* Signature */}
      <Box mt={3}>
        <Typography className={classes.signature} variant="h5" align="left">
          {athlete?.name}
        </Typography>
      </Box>

      {/* Edit Dialog */}
      {editDialogOpen && (
        <AthleteIntroDialog
          open={editDialogOpen}
          mission={athlete?.mission}
          introVideoUrl={introVideoUrl}
          submitting={submitting}
          onSubmit={handleEditSubmit}
          onCancel={handleEditCancel}
        />
      )}
    </div>
  );
};

export default AboutMeBlock;
