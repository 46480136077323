/*
 * Using mui-rff
 * Check following link for more info: https://github.com/lookfirst/mui-rff
 * */

import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import * as Yup from 'yup';
import { makeValidate } from 'mui-rff';
import { Form } from 'react-final-form';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(2),
  },
  grid: {
    width: '100%',
    margin: 0,
  },
  submitBtnWrapper: {
    position: 'relative',
  },
  submitBtn: {
    margin: theme.spacing(2, 0, 1),
    height: 50,
  },
  submitBtnProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

interface ForgotPasswordForm3Props {
  loading: boolean;
  onSubmit: () => void;
}

const formSchema = Yup.object().shape({});

const validate = makeValidate(formSchema);

const ForgotPasswordForm3: React.FC<ForgotPasswordForm3Props> = ({ loading, onSubmit }: ForgotPasswordForm3Props) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const subscription = { submitting: true };
  const handleSubmitClick = () => {
    onSubmit();
  };

  return (
    <Form
      onSubmit={handleSubmitClick}
      subscription={subscription}
      validate={validate}
      key={subscription as any}
      render={({ handleSubmit }) => (
        <form className={classes.form} onSubmit={handleSubmit} noValidate={true} autoComplete="new-password">
          <Grid className={classes.grid} container direction="column" spacing={2}>
            <Grid item xs>
              {/* Message */}
              <Typography variant="h5" gutterBottom>
                {t('pages.forgot_password.step3_title')}
              </Typography>
              <Typography variant="body1" gutterBottom>
                {t('pages.forgot_password.step3_subtitle')}
              </Typography>

              {/* Submit */}
              <Box mt={2}>
                <div className={classes.submitBtnWrapper}>
                  <Button
                    className={classes.submitBtn}
                    type="submit"
                    disabled={loading}
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    {t('global.login')}
                  </Button>
                  {loading && <CircularProgress className={classes.submitBtnProgress} size={24} />}
                </div>
              </Box>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

export default ForgotPasswordForm3;
