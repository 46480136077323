import React, { useEffect } from 'react';
import { createContext, useContext, useState } from 'react';
import { Bcast, BcastStatus } from '../../API';
import { API, graphqlOperation } from 'aws-amplify';
import {
  joinMeeting as gqJoinMeeting,
  //leaveMeeting as gqLeaveMeeting,
  prepareBcast as gqPrepareBcast,
  startBcast as gqStartBcast,
  stopBcast as gqStopBcast,
} from '../../graphql/mutations';

import { AttendeeInfo, generateExternalAttendeeId, parseExternalAttendeeId } from '../utils/attendee';
import { MeetingSessionConfiguration } from 'amazon-chime-sdk-js';
import { useMeetingManager, DeviceLabels } from 'amazon-chime-sdk-component-library-react';

import { onUpdateBcast } from '../../graphql/subscriptions';
import { updateRecord } from '../../graphql/utils';

const DEBUG_BCAST = false;

// fires upon successful join
interface MeetingJoinCallback {
  (attendee: AttendeeInfo): void;
}

// fires upon starting/ending conference
interface MeetingActivateCallback {
  (active: boolean): void;
}

// broadcast lifecycle:
type BcastStep =
  | undefined // initialization
  | 'preparing' // bcast prepare lambda is called, ether by joining host/moderator or by scheduled cron; bcast status is "scheduled"
  | 'prepared' // transcoder instance started and bcaster client successfully joined; <Start> button is enabled
  | 'starting' // <Start> button is clicked; bcast status is now "LIVE"
  | 'started' // broadcasting / <Stop> button is enabled
  | 'stopping' // <Stop> button is clicked
  | 'finished' // broadcast is successfully finished
  | 'error'; // an error occured on backend; in either of previous steps

interface MeetingSession {
  meetingId?: string;
  bcastRecord?: Bcast;
  attendee?: AttendeeInfo; // local attendee
  attendeeId?: string;
  bcastStep: BcastStep;
  joinMeeting: (attendee: AttendeeInfo, asSpectator?: boolean) => Promise<void>;
  startMeeting: () => Promise<void>;
  leaveMeeting: () => Promise<void>;
  endMeeting: () => Promise<void>;
  prepareBcast: () => Promise<void>;
  startBcast: () => Promise<void>;
  stopBcast: () => Promise<void>;
}

const MeetingSessionContext = createContext<MeetingSession | undefined>(undefined);

interface Props {
  meetingId?: string;
  bcastRecord?: Bcast;
  children?: React.ReactNode;
  onJoin?: MeetingJoinCallback;
  onActivate?: MeetingActivateCallback;
}

export const MeetingSessionProvider: React.FC<Props> = (props: Props) => {
  const meetingId = props.meetingId;
  const meetingManager = useMeetingManager();

  const [bcastRecord, setBcastRecord] = useState<Bcast | undefined>(undefined);
  const [attendee, setAttendee] = useState<AttendeeInfo | undefined>(undefined);
  const [attendeeId, setAttendeeId] = useState<string | undefined>(undefined);

  useEffect(() => {
    let updateSubscription: any;

    if (props.bcastRecord) {
      setBcastRecord(props.bcastRecord);

      updateSubscription = (
        API.graphql(
          graphqlOperation(onUpdateBcast, {
            filter: { id: { eq: props.bcastRecord.id } },
          }),
        ) as any
      ).subscribe({
        next: (data: any) => {
          let {
            value: {
              data: { onUpdateBcast: record },
            },
          } = data;
          record = updateRecord(props.bcastRecord, record);
          setBcastRecord(record);
        },
      });
    }
    return () => {
      if (updateSubscription) {
        updateSubscription.unsubscribe();
      }
    };
  }, [props.bcastRecord]);

  const [isBcasterPresent, setIsBcasterPresent] = useState(false);

  // add meeting session hooks
  useEffect(() => {
    // tracks meeting session lifecycle events for automatic switching views
    const sessionObserver = {
      audioVideoDidStart: () => {
        console.log('Session started');
        if (props.onActivate) props.onActivate(true);
      },
      audioVideoDidStop: (sessionStatus: any) => {
        console.log('Session stopped with status code: ', sessionStatus.statusCode());
        if (props.onActivate) props.onActivate(false);
      },
    };

    // subscribe attendee presence changes
    // to register when the broadcaster comes to the meeting
    // note: this works only if the bcaster app is run in FireFox
    // (if on other broser, callback isn't called because bcaster
    // client is joined without audio)
    const rosterUpdateCallback = async (
      _chimeAttendeeId: string,
      present: boolean,
      externalUserId?: string,
    ): Promise<void> => {
      const info = parseExternalAttendeeId(externalUserId ? externalUserId : '');
      if (info.type === 'bcaster') {
        setIsBcasterPresent(present);
      }
    };

    meetingManager?.meetingSession?.audioVideo?.addObserver(sessionObserver);
    meetingManager?.audioVideo?.realtimeSubscribeToAttendeeIdPresence(rosterUpdateCallback);

    return () => {
      meetingManager?.meetingSession?.audioVideo?.removeObserver(sessionObserver);
      meetingManager?.audioVideo?.realtimeUnsubscribeToAttendeeIdPresence(rosterUpdateCallback);
    };
  }, [attendee]);

  //////////
  // Meeting
  //
  const joinMeeting = async (attendee: AttendeeInfo, asSpectator = false) => {
    // fetch joining params from backend
    const attendeeId = generateExternalAttendeeId(attendee);
    const input = { meetingId, attendeeId };
    const response: any = await API.graphql(graphqlOperation(gqJoinMeeting, { input }));
    const meetingResponse = JSON.parse(response.data.joinMeeting.meetingResponse);
    const attendeeResponse = JSON.parse(response.data.joinMeeting.attendeeResponse);

    // join meeting
    const joinConfiguration = new MeetingSessionConfiguration(meetingResponse, attendeeResponse);
    const joinOptions = {
      deviceLabels: asSpectator ? DeviceLabels.None : DeviceLabels.AudioAndVideo,
    };
    await meetingManager.join(joinConfiguration, joinOptions);

    // store local attendee info
    setAttendee(attendee);
    setAttendeeId(attendeeId);

    if (props.onJoin) props.onJoin(attendee);
  };

  const startMeeting = async () => {
    await meetingManager.start();
  };

  const leaveMeeting = async () => {
    await meetingManager.leave();
    // todo: izgleda ne treba posle gornje linije:
    // const input = { meetingId, attendeeId };
    // await API.graphql(graphqlOperation(gqLeaveMeeting, {input}));
  };

  const endMeeting = async () => {
    // todo
  };

  ////////////
  // Broadcast
  //

  const [bcastStep, setBcastStep] = useState<BcastStep>(undefined);

  // Broadcast lifecycle step is calculated indirectly, based on backed and frontend
  // events (we could do it directly, by exposing bcastRecord.bcasting field, but we
  // want to keep it hidden because of 'bid' access token)
  useEffect(() => {
    switch (bcastRecord?.status) {
      // 'prepareBcast' is called automatically when the meeting starts (by BcastControls component)
      // if we get bcaster joined, the preparation was successful
      case BcastStatus.scheduled:
        setBcastStep(!isBcasterPresent ? 'preparing' : 'prepared');
        break;

      //  'startBcast' was successful
      case BcastStatus.live:
        setBcastStep('started');
        break;

      //  'stopBcast' was successful
      case BcastStatus.finished:
        setBcastStep('finished');
        break;

      // a (backend) error occured in any of the previous steps
      case BcastStatus.error:
        setBcastStep('error');
        break;
    }
  }, [bcastRecord?.status, isBcasterPresent]);

  const prepareBcast = async () => {
    try {
      const input = { meetingId, attendeeId };
      await API.graphql(graphqlOperation(gqPrepareBcast, { input }));
    } catch (error) {
      console.error('Preparing broadcast error:', error);
      setBcastStep('error');
    }
  };

  const startBcast = async () => {
    setBcastStep('starting');
    try {
      const input = { meetingId, attendeeId };
      await API.graphql(graphqlOperation(gqStartBcast, { input }));
    } catch (error) {
      console.error('Starting broadcast error:', error);
      setBcastStep('error');
    }
  };

  const stopBcast = async () => {
    setBcastStep('stopping');
    try {
      const input = { meetingId, attendeeId };
      await API.graphql(graphqlOperation(gqStopBcast, { input }));
    } catch (error) {
      console.error('Stopping broadcast error:', error);
      setBcastStep('error');
    }
  };

  ///////////
  // Provider
  //
  const providerValue = {
    meetingId,
    bcastRecord,
    attendee,
    attendeeId,
    bcastStep,
    joinMeeting,
    startMeeting,
    leaveMeeting,
    endMeeting,
    prepareBcast,
    startBcast,
    stopBcast,
  };

  return (
    <MeetingSessionContext.Provider value={providerValue}>
      {DEBUG_BCAST && (
        <div
          style={{
            position: 'absolute',
            top: '-2rem',
            left: '50%',
            fontWeight: 'bold',
            color: 'yellow',
            zIndex: 100,
          }}
        >
          <p>BcastStep: {bcastStep}</p>
          <p>Bcaster: {isBcasterPresent ? 'YES' : 'NO'}</p>
        </div>
      )}
      {props.children}
    </MeetingSessionContext.Provider>
  );
};

export const useMeetingSession = () => {
  const session = useContext(MeetingSessionContext);
  if (!session) {
    throw new Error('useMeetingSession must be used within MeetingSessionProvider');
  }
  return session;
};
