import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import EditIcon from '@material-ui/icons/Edit';
import VideocamIcon from '@material-ui/icons/Videocam';
import LinkIcon from '@material-ui/icons/Link';

import { AthleteGoal, AthleteAchievement } from '../../core/graphql/types';
import { extractYoutubeVideoID } from '../../core/helpers/misc';
import VideoPlayer from '../../components/ui/VideoPlayer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      textAlign: 'left',
      border: 'solid 1px #bdbdbd',
      boxShadow: 'none',
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    actions: {
      // padding: '0 !important',
      '& > .MuiBox-root': {
        width: '50%',
      },
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    avatar: {
      backgroundColor: red[500],
    },
    cardBlock: {
      padding: theme.spacing(2),
      paddingBottom: '16px !important',
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1),
        paddingBottom: '8px !important',
      },
    },
    videoPlayer: {
      width: '100%',
      borderRadius: theme.shape.borderRadius,
      overflow: 'hidden',
      height: '300px',
      [theme.breakpoints.down('sm')]: {
        height: '200px',
      },
    },
  }),
);

interface Props {
  item?: AthleteAchievement | AthleteGoal;
  editable?: boolean;
  hideArrows?: boolean;
  isFirstItem?: boolean;
  isLastItem?: boolean;
  onEditClick?: () => void;
  onDeleteClick?: () => void;
  onMoveUpClick?: () => void;
  onMoveDownClick?: () => void;
}

const GoalAchievementCard: React.FC<Props> = ({
  item,
  hideArrows,
  isFirstItem,
  isLastItem,
  editable,
  onEditClick,
  onDeleteClick,
  onMoveUpClick,
  onMoveDownClick,
}: Props) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const videoYoutubeId = item?.videoUrl ? extractYoutubeVideoID(item?.videoUrl) : '';

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card className={classes.root} raised={false}>
      <CardHeader
        className={classes.cardBlock}
        action={
          <Box mt={1} mr={1}>
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
              size="small"
            >
              <ExpandMoreIcon />
            </IconButton>
          </Box>
        }
        title={
          <Grid container alignItems="center">
            <Grid item>
              <Typography variant="body1">
                <b>{item?.name}</b>
              </Typography>
            </Grid>
            {(videoYoutubeId || item?.link) && (
              <>
                <Grid item>
                  <Box ml={1}></Box>
                </Grid>
                {videoYoutubeId && (
                  <Grid item>
                    <VideocamIcon fontSize="small" />
                  </Grid>
                )}
                {item?.link && (
                  <Grid item>
                    <LinkIcon fontSize="small" />
                  </Grid>
                )}
              </>
            )}
          </Grid>
        }
        subheader={
          <div>
            {item?.date ? <Typography variant="caption">{new Date(item?.date).toLocaleDateString()}</Typography> : ''}
            <Typography variant="body2">{item?.level}</Typography>
          </div>
        }
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent className={classes.cardBlock}>
          <Typography variant="body1" paragraph>
            {item?.description}
          </Typography>
          {videoYoutubeId && item?.videoUrl && (
            <Box mt={1}>
              {/*<iframe
                className={classes.videoPlayer}
                width="100%"
                height="250"
                src={`https://www.youtube.com/embed/${videoYoutubeId}`}
                frameBorder="0"
                allowFullScreen
              />*/}
              <VideoPlayer className={classes.videoPlayer} videoUrl={item.videoUrl} isYoutube={true} />
            </Box>
          )}
          <Box mt={2}>{item?.link && <Link href={item?.link}>{item?.link}</Link>}</Box>
        </CardContent>
      </Collapse>

      {editable && expanded && (
        <CardActions className={clsx(classes.cardBlock, classes.actions)} disableSpacing>
          <Box display="flex" justifyContent="flex-start">
            {!hideArrows && !isFirstItem && (
              <IconButton size="small" onClick={onMoveUpClick}>
                <ArrowUpwardIcon fontSize="small" />
              </IconButton>
            )}
            {!hideArrows && !isLastItem && (
              <IconButton size="small" onClick={onMoveDownClick}>
                <ArrowDownwardIcon fontSize="small" />
              </IconButton>
            )}
          </Box>

          <Box display="flex" justifyContent="flex-end">
            <IconButton size="small" onClick={onEditClick}>
              <EditIcon fontSize="small" color="primary" />
            </IconButton>
            <IconButton size="small" onClick={onDeleteClick}>
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Box>
        </CardActions>
      )}
    </Card>
  );
};

export default GoalAchievementCard;
