/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { requestBlogBy } from '../core/services/contentfulSvc';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Typography, Box, Container, Fade } from '@material-ui/core';
import { useParams } from 'react-router';
import SpinnerBox from '../components/ui/SpinnerBox';
import { getRealHeight } from '../theme/theme';
import AppHeaderPlaceHolder from '../components/layouts/AppHeaderPlaceHolder';
import { useTheme } from '@material-ui/core';
import { useMediaQuery } from '@material-ui/core';
import domHelpers from '../core/helpers/dom';

const useStyles = makeStyles(() => ({
  mainBox: {
    width: '100%',
    '& blockquote': {
      background: '#f9f9f9',
      borderLeft: '10px solid #ccc',
      margin: '1.5em 10px',
      padding: '0.5em 10px',
      fontStyle: 'italic',
    },
    '& blockquote:before': {
      color: '#ccc',
      content: 'open-quote',
      fontSize: '4em',
      lineHeight: '0.1em',
      marginRight: '0.25em',
      verticalAlign: '-0.4em',
    },
    '& a': {
      color: '#f2a742',
      textDecoration: 'none',
      fontWeight: 'bold',
      '&:hover, &:active, &:focus, &:visited': {
        color: '#f2a742',
        textDecoration: 'underline',
      },
    },
  },
}));

const renderOptions = (links: any) => {
  if (links) {
    const assetMap = new Map();

    if (links.assets) {
      for (const asset of links.assets.block) {
        assetMap.set(asset.sys.id, asset);
      }
    }

    const entryMap = new Map();

    if (links.entries) {
      for (const entry of links.entries.inline) {
        entryMap.set(entry.sys.id, entry);
      }

      for (const entry of links.entries.block) {
        entryMap.set(entry.sys.id, entry);
      }
    }

    return {
      renderNode: {
        [BLOCKS.PARAGRAPH]: (node: any, children: any) => {
          return (
            <Typography style={{ marginTop: '10px', marginBottom: '10px' }} variant="body1">
              {children}
            </Typography>
          );
        },
        [BLOCKS.EMBEDDED_ASSET]: (node: any, children: any) => {
          const asset = assetMap.get(node.data.target.sys.id);
          return (
            <Box p={3} display="flex" alignItems="center" justifyContent="center" width={1}>
              <div
                style={{
                  width: '50%',
                  height: '50%',
                  minWidth: '300px',
                  minHeight: '200px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <img width="100%" height="100%" style={{ objectFit: 'cover' }} src={asset.url} alt={asset.title} />
              </div>
            </Box>
          );
        },
      },
    };
  }
};

const BlogsPage: React.FC = () => {
  const [blogContent, setBlogContent] = useState<any>(undefined);
  const params: any = useParams();
  const theme = useTheme();
  const classes = useStyles();
  const isSmallScreen = domHelpers.isSmallScreen(useMediaQuery, theme);

  useEffect(() => {
    (async () => {
      const resp = await requestBlogBy('id', params.blogId);
      setBlogContent(resp.data?.blogs.content);
    })();
  }, []);

  return (
    <Container
      style={{
        minHeight: getRealHeight('50vh'),
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
      }}
    >
      <AppHeaderPlaceHolder />
      {!blogContent && <SpinnerBox marginTop={0} styles={{ height: '100%', width: '100%' }} size={100} />}

      <Fade in={blogContent !== undefined} timeout={1500}>
        <Box className={classes.mainBox} p={isSmallScreen ? 2 : 10}>
          <div>{documentToReactComponents(blogContent?.json, renderOptions(blogContent?.links))}</div>
        </Box>
      </Fade>
    </Container>
  );
};

export default BlogsPage;
