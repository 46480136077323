import React, { useContext, useEffect, useState } from 'react';
import {
  Backdrop,
  Box,
  Button,
  Fade,
  FormControl,
  FormControlLabel,
  Grid,
  makeStyles,
  Modal,
  Radio,
  RadioGroup,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { useHistory } from 'react-router';
import moment from 'moment';

import domHelpers from '../../../core/helpers/dom';
import { fetchPostVodToken, searchPosts } from '../../../core/services/postsSvc';
import { Post, User } from '../../../core/graphql/types';
import { SearchablePostFilterInput, SearchablePostSortableFields, SearchableSortDirection } from '../../../API';
import VideoPlayer from '../../../components/ui/VideoPlayer';
import { authStore } from '../../../core/stores/auth';
import videoSvc from '../../../core/services/videoSvc';
import { adminReviewPost } from '../../../core/services/adminSvc';
import SpinnerBox from '../../../components/ui/SpinnerBox';
import { appStore } from '../../../core/stores/app';
import { adminDashboardRoute } from '../../../core/helpers/route';
import Header from './Header';
import ReviewCard from './ReviewCard';
import BasicPageLayout from '../../../components/layouts/BasicPageLayout';
import FormSubmitButton from '../../../components/ui/FormSubmitButton';
import ReplayIcon from '@material-ui/icons/Replay';

const useStyles = makeStyles((theme) => ({
  modal: {
    backgroundColor: 'rgba(0,0,0,0.6)',
    display: 'flex',
    alignItems: 'center',
    justifyItems: 'center',
    justifyContent: 'center',
    minHeight: theme.typography.pxToRem(600),
  },

  modalContent: {
    backgroundColor: 'white',
    margin: theme.typography.pxToRem(5),
    '& .MuiIconButton-label .MuiSvgIcon-root': {
      fill: theme.palette.primary.main,
    },
  },
}));

export const pageTitle = 'Content Review';
const prevWeek = moment().subtract(7, 'days');

const ReviewDashboard: React.FC = () => {
  const theme = useTheme();
  const classes = useStyles();
  const [posts, setPosts] = useState<Post[]>();
  const [, appDispatch] = useContext(appStore);
  const [currentPostId, setCurrentPostId] = useState('');
  const [currentReviewStatus, setCurrentReviewStatus] = useState('');
  const [authState] = useContext(authStore);
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [reasonModalOpen, setReasonModalOpen] = useState(false);
  const [selectedAthlete, setSelectedAthlete] = useState<User | null>(null);
  const [nextToken, setNextToken] = useState<string | undefined>();
  const [sureModalOpen, setSureModalOpen] = useState(false);
  const [mode, setMode] = useState<'review' | 'search'>('review');
  const history = useHistory();
  const [vodUrl, setVodUrl] = useState('');
  const [loading, setLoading] = useState(true);
  const [vodToken, setVodToken] = useState('');
  const [reason, setReason] = useState('hate speech');
  const [reviewedPerc, setReviewedPerc] = useState('0%');
  const isSmallScreen = domHelpers.isSmallScreen(useMediaQuery, theme);
  const isExtraSmallScreen = domHelpers.isXtraSmallScreen(useMediaQuery, theme);

  const updateReviewPerc = (posts: any) => {
    const reviewed = posts.filter((post: any) => post.reviewStatus == 'accepted').length || 0;
    const totalPosts = posts.length || 0;
    const reviewPerc = (reviewed * 100) / totalPosts;
    setReviewedPerc(isNaN(reviewPerc) ? '0%' : reviewPerc.toFixed(2) + '%');
  };

  const loadPosts = async (nextToken?: string) => {
    setLoading(true);
    const searchObject: SearchablePostFilterInput = {
      vodStatus: {
        eq: 'complete',
      },
    };

    if (selectedAthlete) {
      searchObject.authorId = {
        eq: selectedAthlete.id,
      };
    }

    if (mode === 'review') {
      searchObject.createdAt = {
        gt: prevWeek.toISOString(),
        lt: new Date().toISOString(),
      };
    }

    const res = await searchPosts(
      searchObject,
      6,
      nextToken,
      SearchablePostSortableFields.createdAt,
      SearchableSortDirection.desc,
      true,
    );
    setPosts((psts) => [...(psts || []), ...(res?.posts || [])]);
    setNextToken(res?.nextToken);

    setLoading(false);
  };

  useEffect(() => {
    if (posts && mode === 'review') {
      updateReviewPerc(posts);
    }
  }, [posts]);

  useEffect(() => {
    if (selectedAthlete && mode === 'search') {
      loadPosts();
    }

    if (mode === 'review') {
      setPosts(undefined);
      loadPosts();
    } else {
      setPosts(undefined);
    }
  }, [selectedAthlete, mode]);

  const handleMarking = (as: 'accepted' | 'declined', postId: string) => {
    setCurrentPostId(postId);
    setCurrentReviewStatus(as);
    if (as === 'declined') {
      setReasonModalOpen(true);
      return;
    }
    setSureModalOpen(true);
  };

  const handleSendClick = async () => {
    try {
      await adminReviewPost(currentPostId, currentReviewStatus, reason);
      appDispatch({
        type: 'showToast',
        toastTxt: `The post was successfully marked as ${currentReviewStatus}`,
        toastSeverity: 'success',
      });
      let newPosts: any = posts?.slice();
      if (newPosts) {
        if (currentReviewStatus === 'accepted') {
          const index = newPosts?.findIndex((ps: any) => ps.id === currentPostId) || 0;
          newPosts[index].reviewStatus = currentReviewStatus;
        } else {
          newPosts = newPosts.filter((ps: any) => {
            return ps.id !== currentPostId;
          });
        }
      }
      setPosts(newPosts);
    } catch (err: any) {
      appDispatch({
        type: 'showToast',
        toastTxt: err.errors[0].message,
        toastSeverity: 'error',
      });
    }
    setSureModalOpen(false);
    setReasonModalOpen(false);
  };

  const handlePlayClick = async (postId: string, vodId: string) => {
    const { vodToken } = await fetchPostVodToken(postId, true);
    setVodToken(vodToken);
    setVodUrl(await videoSvc.getPostVodPlaylistManifest(vodId, vodToken));
    setVideoModalOpen(true);
  };

  useEffect(() => {
    if (authState.user) {
      // Page restricted to: admins and moderators
      if (!authState.isAdmin && !authState.isModerator) {
        history.push('/home');
        return;
      }
    }
  }, [authState.user]);

  return (
    <BasicPageLayout
      title={pageTitle}
      maxWidth="lg"
      showTitle={true}
      breadCrumbs={[{ title: 'Admin', route: adminDashboardRoute }, { title: pageTitle }]}
    >
      <div style={{ height: '100%' }}>
        <Header
          selectedAthlete={selectedAthlete}
          onAthleteSearchChange={setSelectedAthlete}
          setMode={setMode}
          mode={mode}
          reviewerName={
            `${authState.userInfo?.attributes.given_name} ${authState.userInfo?.attributes.family_name || ''}` || ''
          }
          totalPosts={posts?.length || 0}
          reviewed={reviewedPerc}
        />
        <Box mt={2} style={{ height: '100%' }}>
          {!loading && (!posts || posts?.length == 0) && (
            <Box display="flex" alignItems="center" justifyContent="center" width={1} height={1}>
              <Typography variant="h5">
                {mode === 'review'
                  ? 'No posts to review this week :('
                  : !selectedAthlete
                  ? 'Search for an athlete by name'
                  : "This athlete doesn't seem to have any posts :("}
              </Typography>
            </Box>
          )}
          <Box
            style={isExtraSmallScreen ? { padding: theme.typography.pxToRem(2) } : {}}
            p={isSmallScreen ? 5 : 10}
            pt={0}
          >
            {posts && (
              <Grid container style={!isSmallScreen ? {} : { marginTop: theme.typography.pxToRem(20) }} spacing={2}>
                {posts?.map((post, i) => {
                  return (
                    <ReviewCard
                      mode={mode}
                      key={i}
                      post={post}
                      handlePlayClick={handlePlayClick}
                      handleMarking={handleMarking}
                    />
                  );
                })}
              </Grid>
            )}
          </Box>
          {!loading && posts && nextToken && (
            <Box mt={5} display="flex" justifyContent="center">
              <FormSubmitButton
                color="primary"
                endIcon={<ReplayIcon fontSize="large" />}
                onClick={() => {
                  loadPosts(nextToken);
                }}
              >
                Load more
              </FormSubmitButton>
            </Box>
          )}
          {loading && <SpinnerBox />}
        </Box>
        <Modal
          open={videoModalOpen}
          onClose={() => {
            setVideoModalOpen(false);
          }}
          className={classes.modal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={videoModalOpen}>
            <div
              style={{ width: '80%', maxWidth: '500px', height: '25%', minHeight: '250px' }}
              className={classes.modalContent}
            >
              <VideoPlayer fill={true} videoUrl={vodUrl} autoplay={true} isVod={true} vodToken={vodToken} />
            </div>
          </Fade>
        </Modal>
        <Modal
          open={reasonModalOpen}
          onClose={() => {
            setReasonModalOpen(false);
          }}
          className={classes.modal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Box p={3} className={classes.modalContent}>
            <Box>
              <Typography variant="body1">Select the reason: </Typography>
              <hr />
            </Box>
            <Box mt={3}>
              <FormControl component="fieldset">
                <RadioGroup
                  value={reason}
                  onChange={(e) => {
                    setReason(e.target.value);
                  }}
                >
                  <FormControlLabel
                    value="hate speech"
                    control={<Radio />}
                    label="This contains hate speech and/or incites violence"
                  />
                  <FormControlLabel
                    value="bullying"
                    control={<Radio />}
                    label="This contains bullying/harassing behaviour and/or speech"
                  />
                  <FormControlLabel
                    value="nudity"
                    control={<Radio />}
                    label="This contains explicit and or sexual images and language"
                  />
                  <FormControlLabel
                    value="harmful behaviour"
                    control={<Radio />}
                    label="This glorifies dangerous/harmful behaviours and/or stunts"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box display="flex" justifyContent="center" mt={2}>
              <Button variant="contained" color="primary" onClick={handleSendClick}>
                Send
              </Button>
            </Box>
          </Box>
        </Modal>
        <Modal
          open={sureModalOpen}
          onClose={() => {
            setSureModalOpen(false);
          }}
          className={classes.modal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Box p={3} className={classes.modalContent}>
            <Typography>Are you sure you want to set it as accepted?</Typography>
            <Box display="flex" justifyContent="center" mt={2}>
              <Button variant="contained" color="primary" onClick={handleSendClick}>
                Yes, send review
              </Button>
            </Box>
          </Box>
        </Modal>
      </div>
    </BasicPageLayout>
  );
};

export default ReviewDashboard;
