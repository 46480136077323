import React from 'react';
import {
  Box,
  capitalize,
  Grid,
  makeStyles,
  Paper,
  Switch,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import domHelpers from '../../../core/helpers/dom';
import AthleteSearch from '../../../components/elements/AthleteSearch';
import { User } from '../../../core/graphql/types';

const useStyles = makeStyles((theme) => ({
  root: {
    height: theme.typography.pxToRem(64),
    padding: theme.spacing(1, 1, 1, 1),
    backgroundColor: 'white',
    borderBottom: '1px solid rgb(224, 224, 224)',
    color: theme.palette.primary.main,
    width: '100%',
    paddingLeft: '24px',
    paddingRight: '24px',
    [theme.breakpoints.down('md')]: {
      height: 'auto',
    },
    // The first container within the root, holding the switch and review info
    '& .MuiGrid-container': {
      [theme.breakpoints.down('sm')]: {
        '& .MuiGrid-item': {
          margin: ' 10px',
          width: '100%',
        },
        '& .MuiAutocomplete-root': {
          width: '100%',
        },
      },
    },
    // Customization of the athlete search component
    '& .MuiAutocomplete-root': {
      color: 'black',
      height: '42px',
    },
  },
  label: {
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    minWidth: theme.typography.pxToRem(120),
    padding: theme.typography.pxToRem(10),
    borderRadius: theme.typography.pxToRem(20),
    fontSize: theme.typography.pxToRem(13),
  },
}));

interface Props {
  reviewerName: string;
  totalPosts: number;
  reviewed: string;
  mode: 'search' | 'review';
  selectedAthlete: User | null;
  setMode: any;
  onAthleteSearchChange: any;
}

const Header: React.FC<Props> = ({
  reviewerName,
  totalPosts,
  reviewed,
  mode,
  setMode,
  selectedAthlete,
  onAthleteSearchChange,
}: Props) => {
  const theme = useTheme();
  const classes = useStyles();
  const isSmallScreen = domHelpers.isSmallScreen(useMediaQuery, theme);

  return (
    <Box display="flex" flexDirection={isSmallScreen ? 'column' : 'row'} alignItems="center" className={classes.root}>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Typography
            dangerouslySetInnerHTML={{ __html: `Logged in as <b>${capitalize(reviewerName)}</b>` }}
            className={classes.label}
            variant="body1"
          ></Typography>
        </Grid>
        {mode === 'review' && (
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <Typography variant="body2">
                  <b>Total: </b> {totalPosts}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">
                  <b>Reviewed: </b> {reviewed}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
        {mode === 'search' && (
          <AthleteSearch
            value={selectedAthlete}
            inverted={false}
            placeHolder={'Find Athlete...'}
            onSelect={onAthleteSearchChange}
          />
        )}
        <Grid item>
          <Paper style={{ padding: theme.spacing(0.7) }} variant="outlined">
            <Grid style={{ flexWrap: 'nowrap', flexDirection: 'row' }} container alignItems="center">
              <Grid item style={{ color: theme.palette.secondary.dark }}>
                <b>Toggle {mode === 'review' ? 'search' : 'review'} mode</b>
              </Grid>
              <Grid item>
                <Switch
                  onChange={() => {
                    setMode(mode === 'review' ? 'search' : 'review');
                  }}
                  color="secondary"
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Header;
