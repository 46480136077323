import React, { useContext, useEffect, useState } from 'react';
import { Box, Grid, Avatar, Typography, lighten, useTheme } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import clsx from 'clsx';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';

import { Comment, User } from '../../core/graphql/types';
import userHelpers from '../../core/helpers/user';
import { makeStyles } from '@material-ui/styles';
import { PeakzTheme } from '../../theme/theme';
import { CommentParentType } from '../../API';
import { formatDateTime } from '../../core/helpers/misc';
import { fetchLikesByTarget, likeComment } from '../../core/services/likeSvc';
import { authStore } from '../../core/stores/auth';
import { noop } from '@babel/types';
import UserListDialog from '../../components/dialogs/UserListDialog';

interface Props {
  commentParam: Comment;
}

const useStyles = makeStyles((theme: PeakzTheme) => ({
  root: {
    minHeight: theme.typography.pxToRem(50),
  },
  reply: {
    marginLeft: theme.typography.pxToRem(50),
  },
  gridItemCommentAvatar: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  gridItemCommentTxt: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginLeft: theme.typography.pxToRem(15),
  },
  likeIconStyles: {
    fontSize: theme.typography.pxToRem(14),
    cursor: 'pointer',
  },
  linkTxt: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const CommentBox: React.FC<Props> = ({ commentParam }: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [authState] = useContext(authStore);

  const [commentsLikesModalOpen, setCommentsLikesModalOpen] = useState(false);
  const [comment, setComment] = useState<Comment>(commentParam);
  const [likesAuthors, setLikesAuthors] = useState<User[] | undefined>();

  useEffect(() => {
    //Comment update when the props derived from parent change
    //I thought managing the like logic from inside the comment was more readable/organised
    if (comment) {
      comment.id !== comment.id ? setComment(comment) : noop();
    }
  });

  const handleLikeClick = async (targetId: string) => {
    if (authState.user) {
      await likeComment(authState.user.userId, targetId);

      //If the like request succeeds
      setComment((c) => {
        if (c) {
          return { ...c, liked: true, likes: (c.likes || 0) + 1 };
        }
        return c;
      });
    }
  };

  const handleLikeCounterClick = async (targetId: string) => {
    const likes: any[] = await fetchLikesByTarget(targetId);
    setLikesAuthors(likes.map(({ user }) => user));
    setCommentsLikesModalOpen(true);
  };

  return (
    <React.Fragment>
      <Box
        className={clsx({
          [classes.root]: true,
          [classes.reply]: comment.parentType === CommentParentType.comment,
        })}
        mt={2}
      >
        <Box p={1} pl={0} pr={0}>
          <Grid container>
            <Grid item className={classes.gridItemCommentAvatar}>
              <Avatar>
                {comment.author?.profileImgS3Key && (
                  <img src={userHelpers.getProfileImg(comment?.author)} width="100%" />
                )}
                {!comment?.author?.profileImgS3Key && <AccountCircleIcon />}
              </Avatar>
              <Box mt={1} display="flex" alignContent="center">
                {!comment?.liked && (
                  <FavoriteBorderIcon
                    onClick={() => {
                      handleLikeClick(comment?.id || '');
                    }}
                    className={classes.likeIconStyles}
                    style={comment?.likes ? { marginRight: theme.typography.pxToRem(5) } : {}}
                  />
                )}
                {comment?.liked && (
                  <FavoriteIcon
                    className={classes.likeIconStyles}
                    style={{ marginRight: theme.typography.pxToRem(5) }}
                  />
                )}
                {comment?.likes && (
                  <Typography
                    onClick={() => {
                      handleLikeCounterClick(comment.id || '');
                    }}
                    className={classes.linkTxt}
                    variant="caption"
                  >
                    {comment?.likes}
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid className={classes.gridItemCommentTxt} item xs={7}>
              <Box>
                <Typography color="primary" variant="body2">
                  <b>{comment?.author?.name || comment?.author?.givenName || comment?.author?.email}</b>
                </Typography>
              </Box>
              <Box p={0.5}>
                <Typography variant="body2"> {comment?.message}</Typography>
              </Box>
              <Box mt={1}>
                <Typography style={{ color: lighten('#000000', 0.4) }} variant="caption">
                  {' '}
                  {formatDateTime(new Date(comment?.createdAt || ''))}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <UserListDialog
        users={likesAuthors}
        open={commentsLikesModalOpen}
        onCloseClick={() => {
          setCommentsLikesModalOpen(false);
        }}
      />
    </React.Fragment>
  );
};

export default CommentBox;
