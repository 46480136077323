import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import Divider from '@material-ui/core/Divider';
import Skeleton from '@material-ui/lab/Skeleton';

import { User } from '../../core/graphql/types';
import { updateUser } from '../../core/services/userSvc';
import { getCountryName, getSportName } from '../../core/helpers/selectOptions';
import { countryToFlag } from '../../core/helpers/misc';
import AthleteInfoDialog, { AthleteInfoDialogData } from '../../containers/dialogs/AthleteInfoDialog';
import InfoDialog from '../../components/dialogs/InfoDialog';
import { appStore } from '../../core/stores/app';

const avatarWidthXs = 120;
const avatarWidthSm = 120;
const avatarWidthLg = 160;
const useStyles = makeStyles((theme) => ({
  athleteInfoBlock: {
    width: '100%', // material-ui: +16px
    margin: 0, // materiail-ui: -8px
    height: 'fit-content',
  },
  coverImage: {
    objectFit: 'cover',
    filter: 'grayscale(100%)',
    backgroundColor: 'white',
    borderRadius: '0px 0px 5px 5px',
    overflow: 'hidden',
  },
  avatarWrapper: {
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%, -0%)',
    // bottom: theme.spacing(-2),
    bottom: `-${theme.typography.pxToRem(avatarWidthXs / 2)}`,
    [theme.breakpoints.up('xs')]: {
      bottom: `-${theme.typography.pxToRem(avatarWidthSm / 2)}`,
    },
    [theme.breakpoints.up('md')]: {
      bottom: `-${theme.typography.pxToRem(avatarWidthLg / 2)}`,
    },
  },
  avatarEditIcon: {
    border: `${theme.typography.pxToRem(1)} solid ${theme.palette.background.paper}`,
    width: '36px',
    height: '36px',
    '& >  svg': {
      color: theme.palette.text.primary,
    },
  },
  avatar: {
    backgroundColor: '#aaaaaa',
    border: `${theme.typography.pxToRem(4)} solid #fff`,
    width: theme.typography.pxToRem(avatarWidthXs),
    height: theme.typography.pxToRem(avatarWidthXs),
    [theme.breakpoints.up('xs')]: {
      width: theme.typography.pxToRem(avatarWidthSm),
      height: theme.typography.pxToRem(avatarWidthSm),
    },
    [theme.breakpoints.up('md')]: {
      width: theme.typography.pxToRem(avatarWidthLg),
      height: theme.typography.pxToRem(avatarWidthLg),
    },
  },
  coverEditButtonWrapper: {
    position: 'absolute',
    right: theme.spacing(4),
    bottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      right: theme.spacing(1),
      bottom: theme.spacing(1),
    },
  },
  athleteData: {
    marginTop: `${theme.typography.pxToRem(avatarWidthXs / 2)}`,
    [theme.breakpoints.up('xs')]: {
      marginTop: `${theme.typography.pxToRem(avatarWidthSm / 2)}`,
    },
    [theme.breakpoints.up('md')]: {
      marginTop: `${theme.typography.pxToRem(avatarWidthLg / 2)}`,
    },
  },
  socialIcon: {
    backgroundColor: lighten(theme.palette.primary.main, 0.8),
    '&:hover': {
      backgroundColor: lighten(theme.palette.primary.main, 0.75),
    },
    '& svg': {
      fontSize: `${theme.typography.pxToRem(24)} !important}`,
    },
  },
}));

interface Props {
  user?: User;
  editable: boolean;
  onUserUpdated: (update: {
    profileImgS3Key?: string;
    coverImgS3Key?: string;
    givenName?: string;
    familyName?: string;
  }) => void;
}

const AthleteInfoBlock: React.FC<Props> = ({ user, editable, onUserUpdated }: Props) => {
  const classes = useStyles();
  const [, appDispatch] = useContext(appStore);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [postsFeatureRequestedDialogOpen, setPostsFeatureRequestedDialogOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const handleEditClick = () => {
    setEditDialogOpen(true);
  };
  const handleInfoEditSubmit = async (data: AthleteInfoDialogData) => {
    if (user) {
      const updateObj = { ...data, name: `${data.givenName} ${data.familyName}` };

      if (updateObj.nickname) {
        delete updateObj.nickname;
      }
      setSubmitting(true);
      await updateUser(user.id, updateObj, appDispatch);
      setSubmitting(false);
      setEditDialogOpen(false);

      onUserUpdated(updateObj);
    }
  };
  const handleInfoEditCancel = () => {
    setEditDialogOpen(false);
  };

  return (
    <div className={classes.athleteInfoBlock}>
      {/* Name */}
      {!user && (
        <Box display="flex" justifyContent="center" mb={1} mt={1}>
          <Skeleton animation={false} variant="rect" width={400} height={30} />
        </Box>
      )}
      {user && (
        <Typography variant="h5" align="center">
          <b>{user.name}</b>
          {editable && (
            <IconButton color="primary" edge="end" onClick={handleEditClick}>
              <EditIcon fontSize="small" />
            </IconButton>
          )}
        </Typography>
      )}
      {/* Country + Sports */}
      <Grid container alignItems="center" justifyContent="center">
        {!user && (
          <Grid item>
            <Skeleton animation={false} variant="rect" width={200} height={30} />
          </Grid>
        )}
        {user && user.country && user.sport && (
          <>
            <Grid item>
              <Box mr={1}>{countryToFlag(String(user.country))}</Box>
            </Grid>
            <Grid item>
              <Typography variant="body1" align="center">
                {`${getCountryName(user.country || '')}, ${getSportName(user.sport || '')}`}
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
      {/* Scouts */}
      {user?.stats?.numScouts !== undefined && user.stats.numScouts > 0 && (
        <Box display="flex" justifyContent="center" mt={1}>
          <Grid container direction="row" alignItems="center" justifyContent="center">
            <Grid item>
              <Box pr={1}>
                <Typography variant="body1">
                  <b>{user.stats.numScouts}</b>
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Typography variant="body1">Fans</Typography>
            </Grid>
          </Grid>
        </Box>
      )}
      {/* Social Accounts */}
      {!user && (
        <Box display="flex" justifyContent="center" mt={1}>
          <Box m={1}>
            <Skeleton animation={false} variant="circle" width={48} height={48} />
          </Box>
          <Box m={1}>
            <Skeleton animation={false} variant="circle" width={48} height={48} />
          </Box>
          <Box m={1}>
            <Skeleton animation={false} variant="circle" width={48} height={48} />
          </Box>
        </Box>
      )}
      {user && (
        <>
          {/* Social icons */}
          <Box display="flex" justifyContent="center" mt={1}>
            {user.instagramUrl && (
              <Box m={1}>
                <IconButton className={classes.socialIcon} color="primary" href={user.instagramUrl} target="_blank">
                  <InstagramIcon />
                </IconButton>
              </Box>
            )}
            {user.facebookUrl && (
              <Box m={1}>
                <IconButton className={classes.socialIcon} color="primary" href={user.facebookUrl} target="_blank">
                  <FacebookIcon />
                </IconButton>
              </Box>
            )}
            {user.youtubeUrl && (
              <Box m={1}>
                <IconButton className={classes.socialIcon} color="primary" href={user.youtubeUrl} target="_blank">
                  <YouTubeIcon />
                </IconButton>
              </Box>
            )}
          </Box>
        </>
      )}
      {/* Divider */}
      <Box mt={2}>
        <Divider />
      </Box>
      {/* Athlete Info Dialog */}
      {editDialogOpen && (
        <AthleteInfoDialog
          open={editDialogOpen}
          user={user}
          submitting={submitting}
          onSubmit={handleInfoEditSubmit}
          onCancel={handleInfoEditCancel}
        />
      )}
      {/* Posts Feature requested dialog */}
      {postsFeatureRequestedDialogOpen && (
        <InfoDialog
          open={postsFeatureRequestedDialogOpen}
          title="Bravo!"
          description="We received your request. Don’t forget to link your Instagram account to your Peakz profile to be selected!"
          onClose={() => setPostsFeatureRequestedDialogOpen(false)}
        />
      )}
    </div>
  );
};

export default AthleteInfoBlock;
