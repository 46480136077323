import { Auth } from 'aws-amplify';
import * as log from 'loglevel';
import { Dispatch } from 'react';

import { AuthAction, AuthUser } from '../stores/auth';
import { AppAction } from '../stores/app';
import { fetchUserById } from './userSvc';
import { User } from '../graphql/types';
import { getAllInfoByISO } from 'iso-country-currency';

export const completeSignIn = async (
  authUser: AuthUser,
  authDispatch: Dispatch<AuthAction>,
  appDispatch: Dispatch<AppAction>,
  extraAuthParams = {},
): Promise<User> => {
  const userInfo = await Auth.currentUserInfo();
  const userCredentials = await Auth.currentCredentials();
  const userId = authUser.attributes['custom:user_id'];

  // Fetch the latest user data from the server
  // TODO: optimize the result (check where appState.user is used). Fetching all user data is an overkill
  const loggedUser = await fetchUserById(userId, 'full');
  // Check did user already define currency
  if (loggedUser.currency === null) {
    // If not, check do the user set country
    const allowedCurrency = ['eur', 'usd', 'gbp', 'cad', 'aud', 'dkk', 'nok', 'sek', 'inr'];
    if (loggedUser.country === null) {
      // if not check by ip can we get country
      fetch('https://geolocation-db.com/json/')
        .then(function (response) {
          return response.json();
        })
        .then(function (payload) {
          // If yes set the currency by country
          const countryInfo = getAllInfoByISO(payload.country_code);
          if (allowedCurrency.indexOf(countryInfo.currency.toLowerCase()) > -1) {
            loggedUser.currency = countryInfo.currency.toLowerCase();
          } else {
            // if not set USD
            loggedUser.currency = 'usd';
          }
        })
        .catch(function () {
          // if error set usd
          loggedUser.currency = 'usd';
        });
    } else {
      // if user set country find currency by that country
      const countryInfo = getAllInfoByISO(loggedUser.country);
      if (allowedCurrency.indexOf(countryInfo.currency.toLowerCase()) > -1) {
        loggedUser.currency = countryInfo.currency.toLowerCase();
      } else {
        loggedUser.currency = 'usd';
      }
    }
  }

  log.debug('signIn', authUser, userInfo, userCredentials, loggedUser);

  // Setup auth user (Auth User)
  authDispatch({ type: 'setUser', user: authUser, userInfo, userNickname: loggedUser?.nickname, ...extraAuthParams });
  // Setup app user (from DB)
  appDispatch({ type: 'setUser', user: loggedUser });

  return loggedUser;
};

export const signOut = async (authDispatch: Dispatch<AuthAction>) => {
  authDispatch({ type: 'setUser', user: undefined, userInfo: undefined, userNickname: undefined });
  await Auth.signOut();
};
export default {
  completeSignIn,
  signOut,
};
