import { Grid, makeStyles } from '@material-ui/core';
import React, { useContext } from 'react';
import { createPostRoute } from '../../core/helpers/route';
import { getTwitterRequestTokens } from '../../core/services/social/twitterSvc';
import globalStyles from '../../theme/globalStyles';
import cacheHelpers from '../../core/helpers/cache';
import config from '../../config';
import { appStore } from '../../core/stores/app';

interface Props {
  postIdParam?: string;
  instagramShared: boolean;
}

const useStyles = makeStyles((theme) => ({
  button: { ...globalStyles(theme).socialConnectButton, backgroundColor: '#1DA1F2' },
}));

const TwitterConnect: React.FC<Props> = ({ postIdParam, instagramShared }: Props) => {
  const [, appDispatch] = useContext(appStore);
  const classes = useStyles();

  const handleTwitterLogin = async () => {
    try {
      const res: any = await getTwitterRequestTokens(`${config.baseUrl}/signup`);
      window.location.href = JSON.parse(res.data.callTwitter).authUrl;
      const authRedirectUrl = createPostRoute;
      const authRedirectSearch = `&postId=${postIdParam}${instagramShared ? '&instagramShared=true' : ''}`;
      cacheHelpers.save(cacheHelpers.keys.authRedirectRoute, authRedirectUrl);
      cacheHelpers.save(cacheHelpers.keys.authRedirectSearch, authRedirectSearch);
    } catch (error) {
      appDispatch({
        type: 'showToast',
        toastTxt: 'Something went wrong during the login, please, try again later',
        toastSeverity: 'error',
      });
    }
  };

  return (
    <Grid container>
      <Grid md={6} style={{ display: 'flex', justifyContent: 'flex-start' }} item>
        <button onClick={handleTwitterLogin} className={classes.button}>
          <b>Login with Twitter</b>
        </button>
      </Grid>
    </Grid>
  );
};

export default TwitterConnect;
