// since onUpdateXXX subscriptions may get incomplete records (when not
// all fields are requested in mutation result), this helper function
// updates the existing record with all fields from the event that are not null
export const updateRecord = (record: any, update: any) => {
  const merged = { ...record };
  for (const key in update) {
    if (update[key] !== null) {
      merged[key] = update[key];
    }
  }
  return merged;
};
