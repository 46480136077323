import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: theme.typography.pxToRem(14),
  },
}));

interface Props {
  required?: boolean;
  label: string;
  children: React.ReactNode;
  [key: string]: any;
}

const FormRow: React.FC<Props> = ({ required, label, children }: Props) => {
  const classes = useStyles();

  return (
    <Box mb={2}>
      <InputLabel className={classes.label} required={required} color="secondary">
        {label}
      </InputLabel>
      {children}
    </Box>
  );
};

export default FormRow;
