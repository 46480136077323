import React from 'react';
import AppHeaderPlaceHolder from '../components/layouts/AppHeaderPlaceHolder';
import { Grid, Box, Typography, Container, makeStyles, useMediaQuery } from '@material-ui/core';
import { letterSpacing } from '../theme/theme';
import { useTheme } from '@material-ui/core';
import { Link } from 'react-router-dom';
import CTAButton from '../components/ui/CTAButton';
import config from '../config';

const policies = [
  {
    imgSrc: `${config.assetBaseUrl}/static/images/landing-header/termsofuse.png`,
    title: 'TERMS OF USE',
    subtitle: 'The agreement that covers all basic use of Peakz for all users.',
    CTALink: 'https://peakz.io/en/terms-of-use/',
  },
  {
    imgSrc: `${config.assetBaseUrl}/static/images/landing-header/community.png`,
    title: 'COMMUNITY GUIDELINES',
    subtitle: 'These guidelines make sure that all athletes and fans feel comfortable uploading content on Peakz. ',
    CTALink: 'https://peakz.io/en/community-guidelines/',
  },
  {
    imgSrc: `${config.assetBaseUrl}/static/images/landing-header/cookiepeakz.png`,
    title: 'COOKIE POLICY',
    subtitle: 'How we use cookies on Peakz.',
    CTALink: 'https://peakz.io/en/cookie-statement/',
  },
  {
    imgSrc: `${config.assetBaseUrl}/static/images/landing-header/securitypolicy.png`,
    title: 'SECURITY POLICY',
    subtitle: 'The agreement that covers all use of Peakz for athletes and fans. ',
    CTALink: 'https://peakz.io/en/security-policy/',
  },
  {
    imgSrc: `${config.assetBaseUrl}/static/images/landing-header/privacypolicy.png`,
    title: 'PRIVACY POLICY',
    subtitle: 'The agreement that covers all use of Peakz for athletes and fans.',
    CTALink: 'https://peakz.io/en/privacy/',
  },
];

const useStyles = makeStyles(() => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& a': {
      textDecoration: 'none',
    },
  },
  gridItemStyle: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    alignItems: 'center',
  },
  buttonBoxStyles: {
    position: 'absolute',
    bottom: '0',
  },
}));

const PoliciesPage: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <React.Fragment>
      <AppHeaderPlaceHolder />
      <Box p={isSmallScreen ? 0 : 5}>
        <Container style={{ marginBottom: theme.typography.pxToRem(40) }} className={classes.mainContainer}>
          <Box mt={3}>
            <Typography align="center" variant="h4" style={{ letterSpacing: letterSpacing.minimal }}>
              <b>Policies & Terms</b>
            </Typography>
            <br />
            <Typography align="center" variant="h5" style={{ letterSpacing: letterSpacing.minimal }}>
              Thanks for taking the time to learn about our policies. Our mission at Peakz is to make long term funding
              for athletes easier and involve fans in athletes athletic journeys. These policies are designes to help us
              all achieve that goal by setting a few guidelines.
            </Typography>
          </Box>
          <Box p={isSmallScreen ? 3 : 0} mt={10}>
            <Grid spacing={9} justifyContent="center" container>
              {policies.map((policy, index) => {
                return (
                  <Grid md={4} key={index} className={classes.gridItemStyle} item>
                    <Box display="flex" flexDirection="column" alignContent="center" alignItems="center">
                      <Box display="flex" justifyContent="center">
                        <img style={{ maxWidth: theme.typography.pxToRem(300) }} width="50%" src={policy.imgSrc} />
                      </Box>
                      <Box
                        style={{ letterSpacing: letterSpacing.minimal, maxWidth: theme.typography.pxToRem(350) }}
                        mt={2}
                        mb={5}
                      >
                        <Typography align="center">
                          <b>{policy.title}</b>
                        </Typography>
                        <Typography align="center">{policy.subtitle}</Typography>
                      </Box>
                    </Box>
                    <Box className={classes.buttonBoxStyles} display="flex" justifyContent="center" mt={5}>
                      <Link target="_blank" to={{ pathname: policy.CTALink }} rel="noopener">
                        <CTAButton variant="contained" color="primary" size="large">
                          <b>Read it here</b>
                        </CTAButton>
                      </Link>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default PoliciesPage;
