import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import FbIconAlt from './icons/FbIconAlt';

const useStyles = makeStyles((theme) => ({
  facebookButtonRoot: {
    height: theme.typography.pxToRem(50),
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 500,
    '& svg': {
      color: '#4267B2 !important',
    },
  },
}));

interface Props {
  submitting?: boolean;
  children: React.ReactNode;
  [key: string]: any;
}

const FacebookButton: React.FC<Props> = ({ submitting, children, ...props }: Props) => {
  const classes = useStyles();

  return (
    <Button
      className={classes.facebookButtonRoot}
      variant="outlined"
      startIcon={<FbIconAlt />}
      fullWidth
      endIcon={submitting ? <CircularProgress size={28} thickness={4} /> : ''}
      {...props}
    >
      {children}
    </Button>
  );
};

export default FacebookButton;
