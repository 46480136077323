import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import Chip from '@material-ui/core/Chip';
import clsx from 'clsx';

import AddCreditCardDialog from '../../containers/dialogs/AddCreditCardDialog';
import { PaymentMethod } from '../../API';
import { detachPaymentMethod, fetchPaymentMethods } from '../../core/services/subscriptionsSvc';
import ActionConfirmationDialog from '../../components/dialogs/ActionConfirmationDialog';
import PanelContent from '../../components/ui/PanelContent';

const useStyles = makeStyles(() => ({
  itemsList: {
    width: '100%',
  },
  itemPreview: {
    width: '100%',
  },
  evenListItem: {
    backgroundColor: '#fafafa',
  },
}));

export interface Props {
  active: boolean;
  addCreditCardDialogOpen?: boolean;
  onAddPaymentMethodAction?: (action: 'added' | 'canceled', paymentMethod?: PaymentMethod) => void;
}

const PaymentMethodsPanel: React.FC<Props> = ({ active, addCreditCardDialogOpen, onAddPaymentMethodAction }: Props) => {
  const classes = useStyles();
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[] | undefined>();
  const [paymentMethodToDelete, setPaymentMethodToDelete] = useState<PaymentMethod | null>(null);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const loadPaymentMethods = useCallback(async () => {
    setLoading(true);
    const paymentMethodsRes = await fetchPaymentMethods();

    if (paymentMethodsRes) {
      setPaymentMethods(paymentMethodsRes);
    }
    setLoading(false);
  }, []);
  const handleAddCreditCardDialogSubmitted = (paymentMethod: PaymentMethod) => {
    setPaymentMethods((pms) => {
      if (paymentMethod.is_default) {
        return [paymentMethod, ...(pms || []).map((pm) => ({ ...pm, is_default: false }))];
      }
      return [paymentMethod, ...(pms || [])];
    });
    if (onAddPaymentMethodAction) {
      onAddPaymentMethodAction('added', paymentMethod);
    }
  };
  const handleAddCreditCardDialogClose = () => {
    if (onAddPaymentMethodAction) {
      onAddPaymentMethodAction('canceled');
    }
  };
  const handleDeletePaymentMethodClick = (paymentMethod: PaymentMethod) => {
    setPaymentMethodToDelete(paymentMethod);
  };
  const handleDeleteConfirmation = async () => {
    if (paymentMethodToDelete && paymentMethodToDelete.id) {
      setSubmitting(true);
      await detachPaymentMethod(paymentMethodToDelete.id);
      setPaymentMethods((pms) => {
        return (pms || []).filter((pm) => pm.id !== paymentMethodToDelete.id);
      });
      setSubmitting(false);
      setPaymentMethodToDelete(null);
    }
  };
  const handleDeleteCancel = () => {
    setPaymentMethodToDelete(null);
  };

  useEffect(() => {
    if (active && !paymentMethods && !loading) {
      loadPaymentMethods();
    }
  }, [active, loadPaymentMethods]);

  return (
    <>
      {active && (
        <PanelContent loading={loading}>
          {paymentMethods && paymentMethods.length === 0 && (
            <Typography variant="body1">You do not currently have any payment methods.</Typography>
          )}
          {paymentMethods && paymentMethods.length > 0 && (
            <List className={classes.itemsList} component="nav" dense={false}>
              {paymentMethods.map((pm: PaymentMethod, i) => (
                <ListItem
                  key={pm.id}
                  className={clsx({
                    [classes.evenListItem]: i % 2 === 0,
                  })}
                >
                  <ListItemIcon>
                    <CreditCardIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={`**** **** **** ${pm.card?.last4} - ${pm.card?.brand?.toUpperCase()}`}
                    secondary={`Expires: ${pm.card?.exp_month}/${pm.card?.exp_year}`}
                  />
                  <ListItemSecondaryAction>
                    {pm.is_default && <Chip label="default" />}
                    {!pm.is_default && (
                      <IconButton aria-label="delete" onClick={handleDeletePaymentMethodClick.bind(null, pm)}>
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          )}
        </PanelContent>
      )}

      {/* Add Payment Card dialog */}
      {addCreditCardDialogOpen && (
        <AddCreditCardDialog
          open={addCreditCardDialogOpen}
          onClose={handleAddCreditCardDialogClose}
          onSubmitted={handleAddCreditCardDialogSubmitted}
        />
      )}

      {/* Delete item confirmation dialog */}
      {paymentMethodToDelete && paymentMethodToDelete.card && (
        <ActionConfirmationDialog
          open={Boolean(paymentMethodToDelete)}
          title={'Delete payment method'}
          description="Are you sure you want to delete the following card?"
          submitting={submitting}
          onYesClick={handleDeleteConfirmation}
          onNoClick={handleDeleteCancel}
          onClose={handleDeleteCancel}
        >
          <Paper className={classes.itemPreview} variant="outlined">
            <Box p={2}>
              <Typography variant="body1">
                **** **** **** {paymentMethodToDelete.card.last4} - {paymentMethodToDelete.card.brand?.toUpperCase()}
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
              >{`Expires: ${paymentMethodToDelete.card.exp_month}/${paymentMethodToDelete.card.exp_year}`}</Typography>
            </Box>
          </Paper>
        </ActionConfirmationDialog>
      )}
    </>
  );
};

export default PaymentMethodsPanel;
