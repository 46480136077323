import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';

import globalStyles from '../../theme/globalStyles';
import { IconButton, InputAdornment } from '@material-ui/core';
import { Schedule } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  formDatePicker: { ...globalStyles(theme).formDatePicker },
}));

interface Props {
  onChange: any;
  value: any;
  [key: string]: any;
}
const FormDateTimePicker: React.FC<Props> = ({ onChange, value, ...props }: Props) => {
  const classes = useStyles();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DateTimePicker
        className={classes.formDatePicker}
        variant="inline"
        ampm={false}
        value={value}
        onChange={onChange}
        onError={console.log}
        disablePast
        format="dd-MM-yyy HH:mm"
        autoOk={true}
        {...props}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton>
                <Schedule />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </MuiPickersUtilsProvider>
  );
};

export default FormDateTimePicker;
