import React, { useContext, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';

import { User } from '../core/graphql/types';
import UserNameDialog, { UserNameDialogData } from './dialogs/UserNameDialog';
import EditableAvatar from './EditableAvatar';
import { appStore } from '../core/stores/app';
import { updateUser } from '../core/services/userSvc';

interface Props {
  user: User | null;
}

const EditableUserBasics: React.FC<Props> = ({ user }: Props) => {
  const [appState, appDispatch] = useContext(appStore);
  const [submitting, setSubmitting] = useState(false);
  const [userNameDialogOpen, setUserNameDialogOpen] = useState(false);
  const handleEditNameClick = () => {
    setUserNameDialogOpen(true);
  };
  const handleUserNameSubmit = async (data: UserNameDialogData) => {
    if (appState.user) {
      const updateObj = { ...data, name: `${data.givenName} ${data.familyName}` };

      setSubmitting(true);
      await updateUser(appState.user.id, updateObj, appDispatch);
      setSubmitting(false);
      setUserNameDialogOpen(false);
    }
  };
  const handleUserNameCancel = () => {
    setUserNameDialogOpen(false);
  };

  return (
    <div>
      <Box width={1} display="flex" justifyContent="center">
        <EditableAvatar user={user} />
      </Box>
      <Box width={1} mt={1}>
        <Typography variant="body1" align="center">
          {user?.name}{' '}
          <IconButton color="primary" size="small" edge="end" onClick={handleEditNameClick}>
            <EditIcon fontSize="small" />
          </IconButton>
        </Typography>
      </Box>

      {/* Athlete Info Dialog */}
      {userNameDialogOpen && user && (
        <UserNameDialog
          open={userNameDialogOpen}
          user={user}
          submitting={submitting}
          onSubmit={handleUserNameSubmit}
          onCancel={handleUserNameCancel}
        />
      )}
    </div>
  );
};

export default EditableUserBasics;
