import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import { List, ListItem, ListItemText } from '@material-ui/core';

import { authStore } from '../../core/stores/auth';
import BasicPageLayout from '../../components/layouts/BasicPageLayout';
import { pushNotificationsDashboardRoute, reviewDashboardRoute } from '../../core/helpers/route';
import { pageTitle as pushNotificationsPageTitle } from './pushNotificationsDashboard/PushNotificationsDashboard';
import { pageTitle as reviewerPageTitle } from './reviewerDashboard/ReviewerDashboard';

const adminPages = [
  {
    route: reviewDashboardRoute,
    title: reviewerPageTitle,
  },
  {
    route: pushNotificationsDashboardRoute,
    title: pushNotificationsPageTitle,
  },
];

const AdminDashboard: React.FC = () => {
  const [authState] = useContext(authStore);
  const history = useHistory();
  const handlePageClick = (route: string) => {
    history.push(route);
  };

  // Check logged user
  useEffect(() => {
    if (authState.user) {
      // Page restricted to: admins and moderators
      if (!authState.isAdmin) {
        history.push('/home');
        return;
      }
    }
  }, [authState.user]);

  return (
    <BasicPageLayout title="Admin Dashboard" maxWidth="md" showTitle={true}>
      <Paper variant="outlined">
        <Box p={2}>
          <List>
            {adminPages.map((page) => (
              <ListItem key={page.route} button onClick={handlePageClick.bind(null, page.route)}>
                <ListItemText primary={page.title}></ListItemText>
              </ListItem>
            ))}
          </List>
        </Box>
      </Paper>
    </BasicPageLayout>
  );
};

export default AdminDashboard;
