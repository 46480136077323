import React, { useContext } from 'react';
import { makeStyles, alpha } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import clsx from 'clsx';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import green from '@material-ui/core/colors/green';
import WarningIcon from '@material-ui/icons/Warning';
import SettingsIcon from '@material-ui/icons/Settings';
import IconButton from '@material-ui/core/IconButton';
import BlockIcon from '@material-ui/icons/Block';

import { PaymentsAccount } from '../core/graphql/types';
import { appStore } from '../core/stores/app';
import paymentsSvc from '../core/services/stripeSvc';

const successColor = green['A100'];
const useStyles = makeStyles((theme) => ({
  statusChip: {
    height: '25px',
    marginTop: '10px',
    marginBottom: '10px',
  },
  successChip: {
    backgroundColor: successColor,
  },
  errorChip: {
    backgroundColor: alpha(theme.palette.error.main, 0.15),
  },
  errorIcon: {
    marginTop: '4px',
    fontSize: '16px',
  },
  errorTxt: {
    color: theme.palette.error.main,
  },
  successIcon: {
    color: green[400],
    fontSize: '16px',
  },
}));

const EnabledLabel = () => {
  const classes = useStyles();

  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item>
        <CheckCircleIcon className={classes.successIcon} fontSize="small" />
      </Grid>
      <Grid item>
        <Typography variant="body1">Enabled</Typography>
      </Grid>
    </Grid>
  );
};

const DisabledLabel = () => {
  const classes = useStyles();

  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item>
        <WarningIcon className={classes.errorIcon} color="error" />
      </Grid>
      <Grid item>
        <Typography variant="body1" color="error">
          Disabled
        </Typography>
      </Grid>
    </Grid>
  );
};

interface Props {
  paymentsAccount: PaymentsAccount;
}

const AthletePaymentsAccountSummary: React.FC<Props> = ({ paymentsAccount }: Props) => {
  const classes = useStyles();
  const [, appDispatch] = useContext(appStore);
  const handleSettingsClick = async () => {
    const currentPageUrl = `${window.location.origin}${window.location.pathname}`;
    const refreshUrl = currentPageUrl;
    const returnUrl = currentPageUrl;

    appDispatch({ type: 'showBackdrop' });

    window.location.href = await paymentsSvc.getPaymentsAccountLink(refreshUrl, returnUrl);
  };

  return (
    <Box width={1}>
      {/* Title */}
      <Grid container justifyContent="space-between">
        <Grid item>
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <Typography variant="h5">
                <b>{paymentsAccount ? paymentsAccount.email : '...'}</b>
              </Typography>
            </Grid>
            <Grid item>
              {paymentsAccount && paymentsAccount.charges_enabled && paymentsAccount.payouts_enabled && (
                <Chip
                  className={clsx({
                    [classes.statusChip]: true,
                    [classes.successChip]: true,
                  })}
                  label="Complete"
                />
              )}
              {paymentsAccount && (!paymentsAccount.charges_enabled || !paymentsAccount.payouts_enabled) && (
                <Chip
                  className={clsx({
                    [classes.statusChip]: true,
                    [classes.errorChip]: true,
                  })}
                  label={
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item>
                        <Typography variant="body2" color="error">
                          Restricted
                        </Typography>
                      </Grid>
                      <Grid item>
                        <BlockIcon className={classes.errorIcon} color="error" />
                      </Grid>
                    </Grid>
                  }
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <IconButton onClick={handleSettingsClick}>
            <SettingsIcon />
          </IconButton>
        </Grid>
      </Grid>

      {/* Content */}
      <Box mt={1}>
        <Divider />
        <Box mt={1} mb={1}>
          <Grid container spacing={6}>
            <Grid item>
              <Typography variant="body2" color="textSecondary">
                Payments
              </Typography>
              {paymentsAccount.charges_enabled && <EnabledLabel />}
              {!paymentsAccount.charges_enabled && <DisabledLabel />}
            </Grid>
            <Grid item>
              <Typography variant="body2" color="textSecondary">
                Payouts
              </Typography>
              {paymentsAccount.payouts_enabled && <EnabledLabel />}
              {!paymentsAccount.payouts_enabled && <DisabledLabel />}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default AthletePaymentsAccountSummary;
