import { Auth, Storage, API } from 'aws-amplify';
import * as log from 'loglevel';
import { v1 as uuidv1 } from 'uuid';
import Axios from 'axios';

import { awsExports, s3AccelerationEnabled } from '../../config';
import { PostType } from '../../API';

const FILE_CHUNK_SIZE = 10_000_000;

export interface UploadPart {
  ETag: string;
  PartNumber: number;
}

export const uploadVideoToS3Std = async (
  file: File,
  accessLevel: 'protected',
  s3BasePath: string, // i.e: videos/challenge_intros
  fileNamePrefix: string,
  meta: {
    refOwner: string;
    refOwnerIdentityId: string;
  },
  progressCallback: (progess: number) => void,
): Promise<string> => {
  log.debug('uploadVideo', meta, accessLevel);
  let videoUploadProgress = 0;
  const fileExtension = 'mp4'; //file.type.split('/').pop();

  // upload file
  const upload: any = await Storage.put(`${s3BasePath}/${fileNamePrefix}${uuidv1()}.${fileExtension}`, file, {
    level: accessLevel,
    contentType: file.type,
    cacheControl: 'max-age=31536000',
    metadata: {
      ...meta,
      fileType: file.type,
    },
    progressCallback: (progress: any) => {
      videoUploadProgress = Math.floor((100 * progress.loaded) / progress.total);
      progressCallback(videoUploadProgress);
    },
  });

  progressCallback(videoUploadProgress);

  return `${accessLevel}/${meta.refOwnerIdentityId}/${upload.key}`;
};

export const uploadVideoToS3Acc = async (
  file: File,
  accessLevel: 'protected',
  s3BasePath: string, // i.e: videos/challenge_intros
  fileNamePrefix: string,
  meta: {
    refOwner: string;
    refOwnerIdentityId: string;
    refEntity?: string;
    refTag?: string; // reference to a given resource
  },
  progressCallback: (progess: number) => void,
): Promise<string> => {
  const userCredentials = await Auth.currentCredentials();
  const fileExtension = 'mp4'; //file.type.split('/').pop();
  const key = `${accessLevel}/${
    userCredentials.identityId
  }/${s3BasePath}/${fileNamePrefix}${uuidv1()}.${fileExtension}`;
  const params = {
    Body: file,
    Bucket: awsExports.aws_user_files_s3_bucket,
    Key: key,
    ContentType: file.type,
    CacheControl: 'max-age=31536000',
    Metadata: {
      ...meta,
      fileType: file.type,
    },
  };
  const s3Client = new window.AWS.S3({
    region: awsExports.aws_user_files_s3_bucket_region,
    credentials: userCredentials,
    useAccelerateEndpoint: true,
  });
  const upload = new window.AWS.S3.ManagedUpload({
    params: params,
    service: s3Client,
  });
  let videoUploadProgress = 0;

  upload.on('httpUploadProgress', (progress: any) => {
    videoUploadProgress = Math.floor((100 * progress.loaded) / progress.total);
    progressCallback(videoUploadProgress);
  });
  const uploadRes = await upload.promise();

  return uploadRes.Key;
};

export const uploadVideoToS3 = async (
  file: File,
  accessLevel: 'protected',
  s3BasePath: string, // i.e: videos/challenge_intros
  fileNamePrefix: string,
  meta: {
    refOwner: string;
    refOwnerIdentityId: string;
    refEntity?: string; // db entity (legacy: not used in backend)
    refTag?: string; // reference to a given resource
    refChallengeId?: string;
  },
  progressCallback: (progess: number) => void,
): Promise<string> => {
  log.debug('uploadVideo', meta, accessLevel);
  if (s3AccelerationEnabled) {
    return uploadVideoToS3Acc(file, accessLevel, s3BasePath, fileNamePrefix, meta, progressCallback);
  }

  return uploadVideoToS3Std(file, accessLevel, s3BasePath, fileNamePrefix, meta, progressCallback);
};

const file2Buffer = (file: File): Promise<Buffer> => {
  return new Promise(function (resolve) {
    const reader = new FileReader();
    const readFile = function () {
      const buffer = reader.result as Buffer;

      resolve(buffer);
    };

    reader.addEventListener('load', readFile);
    reader.readAsArrayBuffer(file);
  });
};

const uploadParts = async (
  file: File,
  urls: Record<number, string>,
  progressCb: (percent: number) => void,
): Promise<UploadPart[]> => {
  const fileBuffer = await file2Buffer(file);
  const axios = Axios.create();
  const totalSize = file.size;
  let totalUploaded = 0;
  const uploadedBytes = Object.keys(urls).map(() => 0);

  delete axios.defaults.headers.put['Content-Type'];

  const keys = Object.keys(urls);
  const promises: Promise<any>[] = [];

  progressCb(0);
  for (const indexStr of keys) {
    const index = parseInt(indexStr);
    const start = index * FILE_CHUNK_SIZE;
    const end = (index + 1) * FILE_CHUNK_SIZE;
    const blob = index < keys.length ? fileBuffer.slice(start, end) : fileBuffer.slice(start);

    promises.push(
      axios.put(urls[index], blob, {
        onUploadProgress: function (progressEvent) {
          // const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          uploadedBytes[index] = progressEvent.loaded;
          totalUploaded = uploadedBytes.reduce((acc, cur) => acc + cur, 0);
          //console.log(index, totalSize, totalUploaded);
          //console.log(uploadedBytes);
          progressCb(Math.round((totalUploaded / totalSize) * 100));
        },
      }),
    );
  }

  // Upload all parts in parallel
  const resParts = await Promise.all(promises);

  return resParts.map((part, index) => ({
    ETag: (part as any).headers.etag,
    PartNumber: index + 1,
  }));
};

export const completeVodUpload = async (
  uploadId: string,
  fileName: string,
  parts: UploadPart[],
  vodId: string,
  podcastCoverImage: any,
) => {
  const res = await API.post('athletesrestapi', '/api/video/upload-complete', {
    body: {
      uploadId: uploadId,
      fileName: fileName,
      parts: parts,
      vodId: vodId,
      podcastCoverImage: podcastCoverImage,
    },
  });

  if (res.success) {
    return res.data;
  }

  return null;
};

export const getVodUploadUrls = async (
  partsCount: number,
  recaptchaToken: string,
  postType: PostType | undefined,
  metadata: any,
) => {
  const res = await API.get('athletesrestapi', '/api/video/upload-urls', {
    queryStringParameters: {
      parts: partsCount,
      recaptchaToken: recaptchaToken,
      metadata: JSON.stringify(metadata),
      type: postType,
    },
  });

  if (res.success) {
    return res.data;
  }

  return null;
};

export const uploadVod = async (
  videoFile: File,
  recaptchaToken: string,
  postType: PostType | undefined,
  metadata: any,
  podcastCoverImage: any,
  progressCb: (percent: number) => void,
): Promise<string> => {
  const partsCount = Math.floor(videoFile.size / FILE_CHUNK_SIZE) + 1;
  const { urls, uploadId, fileName } = await getVodUploadUrls(partsCount, recaptchaToken, postType, metadata);

  if (urls) {
    const parts = await uploadParts(videoFile, urls, progressCb);

    await completeVodUpload(uploadId, fileName, parts, fileName.split('.')[0], podcastCoverImage);
    console.log('Upload complete');
  }

  return fileName.split('.')[0];
};

export const getPostVodPlaylistManifest = async (vodId: string, vodToken: string): Promise<string> => {
  // return `https://${awsVideoExports.awsOutputVideo}/${vodId}/${vodId}.m3u8`;
  // return `https://${awsVideoExports.awsOutputVideo}/${vodId}/${vodId}_Ott_Hls_Ts_Avc_Aac_16x9_960x540p_30Hz_3500Kbps.m3u8`;
  // return `http://localhost:4000/${vodId}.m3u8${vodToken}`;
  return `${await API.endpoint('videogateway')}/playlist/${vodId}.m3u8${vodToken}`;
};

export const getPostVodPlaylistBcastManifest = async (recordingS3Key: string, vodToken: string): Promise<string> => {
  return `${await API.endpoint('videogateway')}/playlistBcast/${recordingS3Key}${vodToken}`;
};

export default {
  uploadVideoToS3,
  uploadVod,
  getPostVodPlaylistManifest,
  getPostVodPlaylistBcastManifest,
};
