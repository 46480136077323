import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import Dialog from '@material-ui/core/Dialog/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';

import DialogTitle from '../ui/DialogTitle';
import SocialButtons from '../elements/SocialButtons';

interface Props {
  open: boolean;
  title: string;
  shareUrl: string;
  onClose: () => void;
}

const ShareDialog: React.FC<Props> = ({ open, title, shareUrl, onClose }: Props) => {
  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      open={open}
      fullScreen={!isBigScreen}
      maxWidth="xs"
      fullWidth={true}
      aria-labelledby="form-dialog-title"
      onClose={handleClose}
    >
      <DialogTitle id="form-dialog-title" onClose={handleClose}>
        {title}
      </DialogTitle>
      <DialogContent>
        <SocialButtons title="" shareUrl={shareUrl} />
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
};

export default ShareDialog;
