import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import videojs, { VideoJsPlayerOptions } from 'video.js';
import videojsYoutube from 'videojs-youtube/dist/Youtube.min';
import 'video.js/dist/video-js.css';

const useStyles = makeStyles((theme) => ({
  root: {
    '& video': {
      '&:focus, &:active': {
        outline: 'none',
      },
    },
    '& *': {
      fontFamily: theme.typography.fontFamily,
      '&:focus, &:active': {
        outline: 'none',
      },
    },
    // width: '100% !important',
  },
}));

interface Props {
  className?: any;
  videoUrl: string;
  videoThumbUrl?: string;
  isYoutube?: boolean;
  controls?: boolean;
  fluid?: boolean;
  fill?: boolean;
  autoplay?: boolean;
  muted?: boolean;
  isVod?: boolean;
  vodToken?: string;
  makeAudioPlayer?: boolean;
  onVideoLoaded?: ({ duration }: { duration: number }) => void;
  onVideoEnded?: () => void;
}

interface State {
  player?: any;
  videoUrl?: string;
  ref: any;
  videoThumbUrl?: string;
  loadedEventTriggered?: boolean;
}

const errKey =
  'The media could not be loaded, either because the server or network failed or because the format is not supported.';
const errMsg = 'Preview is not available. Please upload and the video will be visible.';
videojs.addLanguage('en', {
  [errKey]: errMsg,
});

const VideoPlayer = React.forwardRef(
  (
    {
      className,
      videoUrl,
      videoThumbUrl,
      isYoutube,
      controls = true,
      fluid,
      fill,
      autoplay = false,
      muted = false,
      isVod = false,
      makeAudioPlayer = false,
      onVideoLoaded,
      onVideoEnded,
    }: Props,
    ref,
  ) => {
    const classes = useStyles();
    const [state, setState] = useState({ videoUrl, videoThumbUrl, loadedEventTriggered: false } as State);
    const playerRef: any = ref ? ref : React.createRef();

    useEffect(() => {
      // Control cache
      //const headers = (videojs as any).Hls.xhr.headers || {};
      //headers['Cache-Control'] = 'max-age=3600';
      //(videojs as any).Hls.xhr.headers = headers;

      const player: any = videojs(
        playerRef.current,
        {
          liveui: true,
          preload: 'metadata',
          notSupportedMessage: 'Video format not supported :(',
          poster: state.videoThumbUrl,
          controls: controls,
          fluid: fluid, // keeps the player sized to a particular aspect ratio (https://docs.videojs.com/tutorial-layout.html)
          fill: fill,
          autoplay: autoplay,
          muted: muted,
        } as VideoJsPlayerOptions,
        async () => {
          if (state.videoUrl) {
            const srcParams: { src?: string; type?: string; enableLowInitialPlaylist?: boolean } = {
              src: state.videoUrl,
            };

            if (isYoutube) {
              console.log(videojsYoutube); // Leave this log here! Just to make sure the plugin is loaded
              srcParams.type = 'video/youtube';
            } else if (isVod) {
              srcParams.type = 'application/x-mpegURL';
            } else {
              srcParams.type = 'video/mp4';
            }

            player.src(srcParams);
          }
          player.on('loadeddata', () => {
            if (player.videoHeight() > player.videoWidth() && player.fluid) {
              player.fluid(false);
            }
          });
          player.on('loadedmetadata', () => {
            if (onVideoLoaded) {
              onVideoLoaded({
                duration: player.duration(),
              });
            }
          });
          player.on('ended', function () {
            if (onVideoEnded) {
              onVideoEnded();
            } else {
              player.currentTime(0);
            }
            player.pause();
          });
        },
      );

      setState((state) => ({ ...state, player }));

      return () => {
        console.log('player: dispose');
        player.dispose();
      };
    }, []);

    useEffect(() => {
      if (state.player) {
        if (state.videoUrl !== videoUrl) {
          state.player.src({
            src: videoUrl,
            type: 'video/mp4',
          });
          setState((state) => ({ ...state, videoUrl }));
        }
        if (state.videoThumbUrl !== videoThumbUrl) {
          state.player.poster(videoThumbUrl);
          setState((state) => ({ ...state, videoThumbUrl }));
        }
      }

      setState((state) => ({ ...state, loadedEventTriggered: false }));
    }, [videoUrl, videoThumbUrl]);

    return (
      <video
        playsInline={makeAudioPlayer ? true : false}
        className={`video-js vjs-big-play-centered ${makeAudioPlayer ? 'vjs-has-started vjs-always-active' : ''} ${
          classes.root
        } ${className}`}
        style={makeAudioPlayer ? { height: '40px', backgroundColor: 'transparent', width: '280px' } : {}}
        //@ts-ignore
        ref={playerRef}
        data-setup='{"language":"en"}'
      />
    );
  },
);

export default VideoPlayer;
VideoPlayer.displayName = 'VideoPlayer';
