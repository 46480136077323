/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type User = {
  __typename: "User",
  id: string,
  name: string,
  givenName: string,
  familyName: string,
  gender?: string | null,
  birthdate?: string | null,
  country?: string | null,
  sport?: string | null,
  profileImgS3Key?: string | null,
  coverImgS3Key?: string | null,
  introVideoS3Key?: string | null,
  introVideoThumbS3Key?: string | null,
  introVideoUrl?: string | null,
  mission?: string | null,
  instagramUrl?: string | null,
  facebookUrl?: string | null,
  youtubeUrl?: string | null,
  goals?:  Array<AthleteGoal | null > | null,
  achievements?:  Array<AthleteAchievement | null > | null,
  fundingGoals?:  Array<AthleteFundingGoal | null > | null,
  sponsors?:  Array<AthleteSponsor | null > | null,
  emailValidated?: boolean | null,
  validationEmailSentAt?: string | null,
  potentialAthlete?: boolean | null,
  currency?: string | null,
  themeId?: string | null,
  role: string,
  nickname?: string | null,
  stripePayoutsEnabled?: boolean | null,
  payoutCurrency?: string | null,
  stats?: UserStats | null,
  features?: UserFeatures | null,
  stripeCustomerId?: string | null,
  ivsChannelArn?: string | null,
  createdAt: string,
  updatedAt: string,
  owner: string,
  email: string,
  stripeActionRequired?: boolean | null,
  stripeAccountId?: string | null,
  social?: UserSocial | null,
  firebaseTokens?: Array< string | null > | null,
};

export type AthleteGoal = {
  __typename: "AthleteGoal",
  id: string,
  name: string,
  level?: string | null,
  description?: string | null,
  videoUrl?: string | null,
  link?: string | null,
};

export type AthleteAchievement = {
  __typename: "AthleteAchievement",
  id: string,
  name: string,
  level?: string | null,
  description?: string | null,
  link?: string | null,
  videoUrl?: string | null,
  date?: string | null,
};

export type AthleteFundingGoal = {
  __typename: "AthleteFundingGoal",
  id: string,
  amount: number,
  currency?: string | null,
  description: string,
};

export type AthleteSponsor = {
  __typename: "AthleteSponsor",
  id: string,
  imgS3Key: string,
};

export type UserStats = {
  __typename: "UserStats",
  numUniqueViews?: number | null,
  numScouts?: number | null,
  paidByScouts?: number | null,
  numPosts?: number | null,
};

export type UserFeatures = {
  __typename: "UserFeatures",
  posts?: boolean | null,
};

export type UserSocial = {
  __typename: "UserSocial",
  fbUserId?: string | null,
  fbAccessToken?: string | null,
  fbTargetPageId?: string | null,
  twitterRequestToken?: string | null,
  twitterRequestSecret?: string | null,
  twitterAccessToken?: string | null,
  twitterAccessSecret?: string | null,
  instagramUserId?: string | null,
};

export type BooleanResponse = {
  __typename: "BooleanResponse",
  value: boolean,
  error?: string | null,
};

export type PaymentMethod = {
  __typename: "PaymentMethod",
  id: string,
  type: string,
  billing_details?: BillingDetails | null,
  card?: CreditCard | null,
  created?: number | null,
  customer?: string | null,
  is_default?: boolean | null,
};

export type BillingDetails = {
  __typename: "BillingDetails",
  address?: BillingAddress | null,
  email?: string | null,
  name?: string | null,
  phone?: string | null,
};

export type BillingAddress = {
  __typename: "BillingAddress",
  city?: string | null,
  country?: string | null,
  line1?: string | null,
  line2?: string | null,
  postal_code?: string | null,
  state?: string | null,
};

export type CreditCard = {
  __typename: "CreditCard",
  brand?: string | null,
  country?: string | null,
  exp_month?: number | null,
  exp_year?: number | null,
  last4?: string | null,
};

export type Tier = {
  __typename: "Tier",
  id: string,
  userId: string,
  name: string,
  currency: string,
  unitAmount: number,
  discountType?: DiscountType | null,
  discount?: number | null,
  discountMonths?: number | null,
  couponId?: string | null,
  createdAt: string,
  updatedAt: string,
};

export enum DiscountType {
  percent = "percent",
  amount = "amount",
}


export type Comment = {
  __typename: "Comment",
  id: string,
  authorId: string,
  parentId: string,
  parentType: CommentParentType,
  lastComment?: Comment | null,
  message: string,
  likes?: number | null,
  comments?: number | null,
  liked?: boolean | null,
  createdAt: string,
  updatedAt?: string | null,
  author?: User | null,
};

export enum CommentParentType {
  post = "post",
  comment = "comment",
}


export type CreateBcastInput = {
  id?: string | null,
  userId: string,
  title: string,
  description?: string | null,
  tierId: string,
  needsSubscription: boolean,
  thumbnailImageS3Key?: string | null,
  previewImageS3Key?: string | null,
  time: string,
  duration?: string | null,
  guests?: string | null,
  allowQuestions: boolean,
  status: BcastStatus,
  prepareArgs?: string | null,
};

export enum BcastStatus {
  created = "created",
  scheduled = "scheduled",
  live = "live",
  finished = "finished",
  error = "error",
}


export type Bcast = {
  __typename: "Bcast",
  id: string,
  userId: string,
  title: string,
  description?: string | null,
  tierId: string,
  needsSubscription: boolean,
  thumbnailImageS3Key?: string | null,
  previewImageS3Key?: string | null,
  time: string,
  duration?: string | null,
  guests:  Array<BcastGuest >,
  allowQuestions: boolean,
  status: BcastStatus,
  bcastUrl?: string | null,
  recordingS3Key?: string | null,
  prepareArgs?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type BcastGuest = {
  __typename: "BcastGuest",
  id: string,
  name: string,
};

export type UpdateBcastInput = {
  id: string,
  userId?: string | null,
  title?: string | null,
  description?: string | null,
  tierId?: string | null,
  needsSubscription?: boolean | null,
  thumbnailImageS3Key?: string | null,
  previewImageS3Key?: string | null,
  time?: string | null,
  duration?: string | null,
  guests?: string | null,
  allowQuestions?: boolean | null,
  status?: BcastStatus | null,
  bcastUrl?: string | null,
  recordingS3Key?: string | null,
  prepareArgs?: string | null,
};

export type UpdatedBcast = {
  __typename: "UpdatedBcast",
  id: string,
  userId?: string | null,
  title?: string | null,
  description?: string | null,
  thumbnailImageS3Key?: string | null,
  previewImageS3Key?: string | null,
  time?: string | null,
  duration?: string | null,
  guests?: string | null,
  allowQuestions?: boolean | null,
  status?: BcastStatus | null,
  bcastUrl?: string | null,
  recordingS3Key?: string | null,
};

export type DeleteBcastInput = {
  id: string,
};

export type JoinMeetingInput = {
  meetingId: string,
  attendeeId: string,
};

export type JoinMeetingResponse = {
  __typename: "JoinMeetingResponse",
  meetingResponse: string,
  attendeeResponse: string,
};

export type MeetingCmdInput = {
  meetingId: string,
  attendeeId: string,
  opts?: string | null,
};

export type MeetingCmdResponse = {
  __typename: "MeetingCmdResponse",
  status: string,
};

export type CreateUserInput = {
  id?: string | null,
  name: string,
  givenName: string,
  familyName: string,
  gender?: string | null,
  birthdate?: string | null,
  country?: string | null,
  sport?: string | null,
  profileImgS3Key?: string | null,
  coverImgS3Key?: string | null,
  introVideoS3Key?: string | null,
  introVideoThumbS3Key?: string | null,
  introVideoUrl?: string | null,
  mission?: string | null,
  instagramUrl?: string | null,
  facebookUrl?: string | null,
  youtubeUrl?: string | null,
  goals?: Array< AthleteGoalInput | null > | null,
  achievements?: Array< AthleteAchievementInput | null > | null,
  fundingGoals?: Array< AthleteFundingGoalInput | null > | null,
  sponsors?: Array< AthleteSponsorInput | null > | null,
  emailValidated?: boolean | null,
  validationEmailSentAt?: string | null,
  potentialAthlete?: boolean | null,
  currency?: string | null,
  themeId?: string | null,
  role: string,
  nickname?: string | null,
  stripePayoutsEnabled?: boolean | null,
  payoutCurrency?: string | null,
  stats?: UserStatsInput | null,
  features?: UserFeaturesInput | null,
  owner: string,
  email: string,
  stripeActionRequired?: boolean | null,
  stripeAccountId?: string | null,
  social?: UserSocialInput | null,
  firebaseTokens?: Array< string | null > | null,
  stripeCustomerId?: string | null,
  ivsChannelArn?: string | null,
};

export type AthleteGoalInput = {
  id: string,
  name: string,
  level?: string | null,
  description?: string | null,
  videoUrl?: string | null,
  link?: string | null,
};

export type AthleteAchievementInput = {
  id: string,
  name: string,
  level?: string | null,
  description?: string | null,
  link?: string | null,
  videoUrl?: string | null,
  date?: string | null,
};

export type AthleteFundingGoalInput = {
  id: string,
  amount: number,
  currency: string,
  description: string,
};

export type AthleteSponsorInput = {
  id: string,
  imgS3Key: string,
};

export type UserStatsInput = {
  numUniqueViews?: number | null,
  numScouts?: number | null,
  paidByScouts?: number | null,
  numPosts?: number | null,
};

export type UserFeaturesInput = {
  posts?: boolean | null,
};

export type UserSocialInput = {
  fbUserId?: string | null,
  fbAccessToken?: string | null,
  fbTargetPageId?: string | null,
  twitterRequestToken?: string | null,
  twitterRequestSecret?: string | null,
  twitterAccessToken?: string | null,
  twitterAccessSecret?: string | null,
  instagramUserId?: string | null,
};

export type ModelUserConditionInput = {
  name?: ModelStringInput | null,
  givenName?: ModelStringInput | null,
  familyName?: ModelStringInput | null,
  gender?: ModelStringInput | null,
  birthdate?: ModelStringInput | null,
  country?: ModelStringInput | null,
  sport?: ModelStringInput | null,
  profileImgS3Key?: ModelStringInput | null,
  coverImgS3Key?: ModelStringInput | null,
  introVideoS3Key?: ModelStringInput | null,
  introVideoThumbS3Key?: ModelStringInput | null,
  introVideoUrl?: ModelStringInput | null,
  mission?: ModelStringInput | null,
  instagramUrl?: ModelStringInput | null,
  facebookUrl?: ModelStringInput | null,
  youtubeUrl?: ModelStringInput | null,
  emailValidated?: ModelBooleanInput | null,
  validationEmailSentAt?: ModelStringInput | null,
  potentialAthlete?: ModelBooleanInput | null,
  currency?: ModelStringInput | null,
  themeId?: ModelStringInput | null,
  role?: ModelStringInput | null,
  nickname?: ModelStringInput | null,
  stripePayoutsEnabled?: ModelBooleanInput | null,
  payoutCurrency?: ModelStringInput | null,
  email?: ModelStringInput | null,
  stripeActionRequired?: ModelBooleanInput | null,
  stripeAccountId?: ModelStringInput | null,
  firebaseTokens?: ModelStringInput | null,
  stripeCustomerId?: ModelStringInput | null,
  ivsChannelArn?: ModelStringInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateUserInput = {
  id: string,
  name?: string | null,
  givenName?: string | null,
  familyName?: string | null,
  gender?: string | null,
  birthdate?: string | null,
  country?: string | null,
  sport?: string | null,
  profileImgS3Key?: string | null,
  coverImgS3Key?: string | null,
  introVideoS3Key?: string | null,
  introVideoThumbS3Key?: string | null,
  introVideoUrl?: string | null,
  mission?: string | null,
  social?: UserSocialInput | null,
  instagramUrl?: string | null,
  facebookUrl?: string | null,
  youtubeUrl?: string | null,
  goals?: Array< AthleteGoalInput | null > | null,
  achievements?: Array< AthleteAchievementInput | null > | null,
  fundingGoals?: Array< AthleteFundingGoalInput | null > | null,
  sponsors?: Array< AthleteSponsorInput | null > | null,
  potentialAthlete?: boolean | null,
  currency?: string | null,
  themeId?: string | null,
  firebaseTokens?: Array< string | null > | null,
};

export type DeleteUserInput = {
  id: string,
};

export type CreatePostInput = {
  id?: string | null,
  authorId: string,
  name: string,
  description?: string | null,
  needsSubscription?: boolean | null,
  type: PostType,
  tierId: string,
  vodId?: string | null,
  vodStatus?: string | null,
  vodMetadata?: PostVodMetadataInput | null,
  bcastMetadata?: PostBcastMetadataInput | null,
  thumbS3Key?: string | null,
  likes?: number | null,
  comments?: number | null,
  liked?: boolean | null,
  reviewStatus?: PostReviewStatus | null,
  feedId?: string | null,
  createdAt?: string | null,
};

export enum PostType {
  video = "video",
  podcast = "podcast",
  bcast = "bcast",
}


export type PostVodMetadataInput = {
  durationInMs?: number | null,
  orgWidth?: number | null,
  orgHeight?: number | null,
  hasPreview?: boolean | null,
};

export type PostBcastMetadataInput = {
  previewImageS3Key?: string | null,
  time: string,
  duration?: string | null,
  guests: Array< BcastGuestInput >,
  allowQuestions: boolean,
  status: BcastStatus,
  bcastUrl?: string | null,
  recordingS3Key?: string | null,
};

export type BcastGuestInput = {
  id: string,
  name: string,
};

export enum PostReviewStatus {
  accepted = "accepted",
  declined = "declined",
}


export type ModelPostConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  needsSubscription?: ModelBooleanInput | null,
  type?: ModelPostTypeInput | null,
  tierId?: ModelIDInput | null,
  vodId?: ModelStringInput | null,
  vodStatus?: ModelStringInput | null,
  thumbS3Key?: ModelStringInput | null,
  likes?: ModelIntInput | null,
  comments?: ModelIntInput | null,
  liked?: ModelBooleanInput | null,
  reviewStatus?: ModelPostReviewStatusInput | null,
  feedId?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelPostConditionInput | null > | null,
  or?: Array< ModelPostConditionInput | null > | null,
  not?: ModelPostConditionInput | null,
};

export type ModelPostTypeInput = {
  eq?: PostType | null,
  ne?: PostType | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelPostReviewStatusInput = {
  eq?: PostReviewStatus | null,
  ne?: PostReviewStatus | null,
};

export type Post = {
  __typename: "Post",
  id: string,
  authorId: string,
  name: string,
  description?: string | null,
  needsSubscription?: boolean | null,
  type: PostType,
  tierId: string,
  vodId?: string | null,
  vodStatus?: string | null,
  vodMetadata?: PostVodMetadata | null,
  bcastMetadata?: PostBcastMetadata | null,
  thumbS3Key?: string | null,
  likes?: number | null,
  comments?: number | null,
  liked?: boolean | null,
  reviewStatus?: PostReviewStatus | null,
  feedId?: string | null,
  createdAt: string,
  updatedAt: string,
  author?: User | null,
  tier?: Tier | null,
};

export type PostVodMetadata = {
  __typename: "PostVodMetadata",
  durationInMs?: number | null,
  orgWidth?: number | null,
  orgHeight?: number | null,
  hasPreview?: boolean | null,
};

export type PostBcastMetadata = {
  __typename: "PostBcastMetadata",
  previewImageS3Key?: string | null,
  time: string,
  duration?: string | null,
  guests:  Array<BcastGuest >,
  allowQuestions: boolean,
  status: BcastStatus,
  bcastUrl?: string | null,
  recordingS3Key?: string | null,
};

export type UpdatePostInput = {
  id: string,
  authorId?: string | null,
  name?: string | null,
  description?: string | null,
  needsSubscription?: boolean | null,
  type?: PostType | null,
  tierId?: string | null,
  vodId?: string | null,
  vodStatus?: string | null,
  vodMetadata?: PostVodMetadataInput | null,
  bcastMetadata?: PostBcastMetadataInput | null,
  thumbS3Key?: string | null,
  likes?: number | null,
  comments?: number | null,
  liked?: boolean | null,
  reviewStatus?: PostReviewStatus | null,
  feedId?: string | null,
  createdAt?: string | null,
};

export type DeletePostInput = {
  id: string,
};

export type CreateAthleteSubscriptionInput = {
  subscriberId: string,
  athleteId: string,
  tierId?: string | null,
  currency: string,
  unitAmount: number,
  paidToAthlete: number,
  status?: SubscriptionStatus | null,
  cancelAtPeriodEnd?: boolean | null,
  currentPeriodEnd?: number | null,
  expiresAt: string,
  discountType?: DiscountType | null,
  discount?: number | null,
  discountRemainingMonths?: number | null,
  couponId?: string | null,
  taxRateId?: string | null,
  updatedAt?: string | null,
  createdAt?: string | null,
  stripeSubscriptionId?: string | null,
  stripePriceId?: string | null,
};

export enum SubscriptionStatus {
  active = "active",
  canceled = "canceled",
  incomplete = "incomplete",
  incomplete_expired = "incomplete_expired",
  past_due = "past_due",
  trialing = "trialing",
  unpaid = "unpaid",
}


export type ModelAthleteSubscriptionConditionInput = {
  tierId?: ModelIDInput | null,
  currency?: ModelStringInput | null,
  unitAmount?: ModelIntInput | null,
  paidToAthlete?: ModelIntInput | null,
  status?: ModelSubscriptionStatusInput | null,
  cancelAtPeriodEnd?: ModelBooleanInput | null,
  currentPeriodEnd?: ModelIntInput | null,
  expiresAt?: ModelStringInput | null,
  discountType?: ModelDiscountTypeInput | null,
  discount?: ModelIntInput | null,
  discountRemainingMonths?: ModelIntInput | null,
  couponId?: ModelStringInput | null,
  taxRateId?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  stripeSubscriptionId?: ModelStringInput | null,
  stripePriceId?: ModelStringInput | null,
  and?: Array< ModelAthleteSubscriptionConditionInput | null > | null,
  or?: Array< ModelAthleteSubscriptionConditionInput | null > | null,
  not?: ModelAthleteSubscriptionConditionInput | null,
};

export type ModelSubscriptionStatusInput = {
  eq?: SubscriptionStatus | null,
  ne?: SubscriptionStatus | null,
};

export type ModelDiscountTypeInput = {
  eq?: DiscountType | null,
  ne?: DiscountType | null,
};

export type AthleteSubscription = {
  __typename: "AthleteSubscription",
  subscriberId: string,
  athleteId: string,
  tierId?: string | null,
  currency: string,
  unitAmount: number,
  paidToAthlete: number,
  status?: SubscriptionStatus | null,
  cancelAtPeriodEnd?: boolean | null,
  currentPeriodEnd?: number | null,
  expiresAt: string,
  discountType?: DiscountType | null,
  discount?: number | null,
  discountRemainingMonths?: number | null,
  couponId?: string | null,
  taxRateId?: string | null,
  updatedAt: string,
  createdAt: string,
  stripeSubscriptionId?: string | null,
  stripePriceId?: string | null,
  subscriber?: User | null,
  athlete?: User | null,
  tier?: Tier | null,
};

export type UpdateAthleteSubscriptionInput = {
  subscriberId: string,
  athleteId: string,
  tierId?: string | null,
  currency?: string | null,
  unitAmount?: number | null,
  paidToAthlete?: number | null,
  status?: SubscriptionStatus | null,
  cancelAtPeriodEnd?: boolean | null,
  currentPeriodEnd?: number | null,
  expiresAt?: string | null,
  discountType?: DiscountType | null,
  discount?: number | null,
  discountRemainingMonths?: number | null,
  couponId?: string | null,
  taxRateId?: string | null,
  updatedAt?: string | null,
  createdAt?: string | null,
  stripeSubscriptionId?: string | null,
  stripePriceId?: string | null,
};

export type DeleteAthleteSubscriptionInput = {
  subscriberId: string,
  athleteId: string,
};

export type CreateFlashRequestInput = {
  userId: string,
  request: FlashRequestType,
  targetId: string,
  payload?: string | null,
  createdAt?: string | null,
};

export enum FlashRequestType {
  feature = "feature",
  reportPost = "reportPost",
}


export type ModelFlashRequestConditionInput = {
  payload?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelFlashRequestConditionInput | null > | null,
  or?: Array< ModelFlashRequestConditionInput | null > | null,
  not?: ModelFlashRequestConditionInput | null,
};

export type FlashRequest = {
  __typename: "FlashRequest",
  userId: string,
  request: FlashRequestType,
  targetId: string,
  payload?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateFlashRequestInput = {
  userId: string,
  request: FlashRequestType,
  targetId: string,
  payload?: string | null,
  createdAt?: string | null,
};

export type DeleteFlashRequestInput = {
  userId: string,
  request: FlashRequestType,
  targetId: string,
};

export type CreateLikeInput = {
  userId: string,
  targetId: string,
  targetType: LikeTargetType,
  createdAt?: string | null,
};

export enum LikeTargetType {
  post = "post",
  athlete = "athlete",
  comment = "comment",
}


export type ModelLikeConditionInput = {
  createdAt?: ModelStringInput | null,
  and?: Array< ModelLikeConditionInput | null > | null,
  or?: Array< ModelLikeConditionInput | null > | null,
  not?: ModelLikeConditionInput | null,
};

export type Like = {
  __typename: "Like",
  userId: string,
  targetId: string,
  targetType: LikeTargetType,
  createdAt: string,
  updatedAt: string,
  user?: User | null,
};

export type UpdateLikeInput = {
  userId: string,
  targetId: string,
  targetType: LikeTargetType,
  createdAt?: string | null,
};

export type DeleteLikeInput = {
  userId: string,
  targetType: LikeTargetType,
  targetId: string,
};

export type CreateMigrationsInput = {
  name: string,
};

export type ModelMigrationsConditionInput = {
  and?: Array< ModelMigrationsConditionInput | null > | null,
  or?: Array< ModelMigrationsConditionInput | null > | null,
  not?: ModelMigrationsConditionInput | null,
};

export type Migrations = {
  __typename: "Migrations",
  name: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateMigrationsInput = {
  name: string,
};

export type DeleteMigrationsInput = {
  name: string,
};

export type Invoice = {
  __typename: "Invoice",
  id: string,
  number?: string | null,
  paid: boolean,
  period_start: number,
  period_end: number,
  total: number,
  currency: string,
  invoice_pdf?: string | null,
  created: number,
};

export type Balance = {
  __typename: "Balance",
  available?:  Array<BalanceAvailable | null > | null,
  pending?:  Array<BalancePending | null > | null,
};

export type BalanceAvailable = {
  __typename: "BalanceAvailable",
  amount: number,
  currency: string,
};

export type BalancePending = {
  __typename: "BalancePending",
  amount: number,
  currency: string,
};

export type BalanceTransactionList = {
  __typename: "BalanceTransactionList",
  data?:  Array<BalanceTransaction | null > | null,
  has_more?: boolean | null,
};

export type BalanceTransaction = {
  __typename: "BalanceTransaction",
  id?: string | null,
  amount?: number | null,
  available_on?: number | null,
  created?: number | null,
  currency?: string | null,
  description?: string | null,
  exchange_rate?: number | null,
  fee?: number | null,
  net?: number | null,
  reporting_category?: string | null,
  status?: string | null,
  type?: string | null,
};

export type PayoutList = {
  __typename: "PayoutList",
  data?:  Array<Payout | null > | null,
  has_more?: boolean | null,
};

export type Payout = {
  __typename: "Payout",
  id?: string | null,
  amount?: number | null,
  arrival_date?: number | null,
  automatic?: boolean | null,
  created?: number | null,
  currency?: string | null,
  description?: string | null,
  source_type?: string | null,
  status?: string | null,
  type?: string | null,
};

export type PaymentsAccount = {
  __typename: "PaymentsAccount",
  id: string,
  business_profile?: AccountBusinessProfile | null,
  charges_enabled: boolean,
  country: string,
  created?: number | null,
  default_currency: string,
  details_submitted: boolean,
  email?: string | null,
  payouts_enabled: boolean,
  requirements?: AccountRequirements | null,
};

export type AccountBusinessProfile = {
  __typename: "AccountBusinessProfile",
  name?: string | null,
  product_description?: string | null,
  support_address?: StripeAddress | null,
  support_email?: string | null,
  support_phone?: string | null,
  support_url?: string | null,
  url?: string | null,
};

export type StripeAddress = {
  __typename: "StripeAddress",
  city?: string | null,
  country?: string | null,
  line1?: string | null,
  line2?: string | null,
  postal_code?: string | null,
  state?: string | null,
};

export type AccountRequirements = {
  __typename: "AccountRequirements",
  current_deadline?: number | null,
  currently_due?: Array< string | null > | null,
  disabled_reason?: string | null,
  errors?:  Array<AccountRequirementsError | null > | null,
  eventually_due?: Array< string | null > | null,
  past_due?: Array< string | null > | null,
  pending_verification?: Array< string | null > | null,
};

export type AccountRequirementsError = {
  __typename: "AccountRequirementsError",
  code: string,
  reason?: string | null,
  requirement?: string | null,
};

export type TaxRate = {
  __typename: "TaxRate",
  id: string,
  percentage: number,
  displayName: string,
  country: string,
};

export type FullPost = {
  __typename: "FullPost",
  id: string,
  authorId: string,
  name: string,
  description?: string | null,
  needsSubscription?: boolean | null,
  type: PostType,
  tierId: string,
  tier?: Tier | null,
  thumbS3Key?: string | null,
  comments?: number | null,
  vodId?: string | null,
  vodToken?: string | null,
  vodStatus?: string | null,
  vodMetadata?: PostVodMetadata | null,
  bcastMetadata?: PostBcastMetadata | null,
  likes?: number | null,
  liked?: boolean | null,
  reviewStatus?: PostReviewStatus | null,
  feedId?: string | null,
  createdAt: string,
};

export type CurrenciesConfig = {
  __typename: "CurrenciesConfig",
  defaultCurrency: string,
  clientCurrency: string,
  currencies:  Array<Currency | null >,
  conversionsTable: Array< Array< number | null > | null >,
};

export type Currency = {
  __typename: "Currency",
  code: string,
  symbol: string,
  round: number,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  givenName?: ModelStringInput | null,
  familyName?: ModelStringInput | null,
  gender?: ModelStringInput | null,
  birthdate?: ModelStringInput | null,
  country?: ModelStringInput | null,
  sport?: ModelStringInput | null,
  profileImgS3Key?: ModelStringInput | null,
  coverImgS3Key?: ModelStringInput | null,
  introVideoS3Key?: ModelStringInput | null,
  introVideoThumbS3Key?: ModelStringInput | null,
  introVideoUrl?: ModelStringInput | null,
  mission?: ModelStringInput | null,
  instagramUrl?: ModelStringInput | null,
  facebookUrl?: ModelStringInput | null,
  youtubeUrl?: ModelStringInput | null,
  emailValidated?: ModelBooleanInput | null,
  validationEmailSentAt?: ModelStringInput | null,
  potentialAthlete?: ModelBooleanInput | null,
  currency?: ModelStringInput | null,
  themeId?: ModelStringInput | null,
  role?: ModelStringInput | null,
  nickname?: ModelStringInput | null,
  stripePayoutsEnabled?: ModelBooleanInput | null,
  payoutCurrency?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  email?: ModelStringInput | null,
  stripeActionRequired?: ModelBooleanInput | null,
  stripeAccountId?: ModelStringInput | null,
  firebaseTokens?: ModelStringInput | null,
  stripeCustomerId?: ModelStringInput | null,
  ivsChannelArn?: ModelStringInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
};

export type SearchableUserFilterInput = {
  id?: SearchableIDFilterInput | null,
  name?: SearchableStringFilterInput | null,
  givenName?: SearchableStringFilterInput | null,
  familyName?: SearchableStringFilterInput | null,
  gender?: SearchableStringFilterInput | null,
  birthdate?: SearchableStringFilterInput | null,
  country?: SearchableStringFilterInput | null,
  sport?: SearchableStringFilterInput | null,
  profileImgS3Key?: SearchableStringFilterInput | null,
  coverImgS3Key?: SearchableStringFilterInput | null,
  introVideoS3Key?: SearchableStringFilterInput | null,
  introVideoThumbS3Key?: SearchableStringFilterInput | null,
  introVideoUrl?: SearchableStringFilterInput | null,
  mission?: SearchableStringFilterInput | null,
  instagramUrl?: SearchableStringFilterInput | null,
  facebookUrl?: SearchableStringFilterInput | null,
  youtubeUrl?: SearchableStringFilterInput | null,
  emailValidated?: SearchableBooleanFilterInput | null,
  validationEmailSentAt?: SearchableStringFilterInput | null,
  potentialAthlete?: SearchableBooleanFilterInput | null,
  currency?: SearchableStringFilterInput | null,
  themeId?: SearchableStringFilterInput | null,
  role?: SearchableStringFilterInput | null,
  nickname?: SearchableStringFilterInput | null,
  stripePayoutsEnabled?: SearchableBooleanFilterInput | null,
  payoutCurrency?: SearchableStringFilterInput | null,
  owner?: SearchableStringFilterInput | null,
  email?: SearchableStringFilterInput | null,
  stripeActionRequired?: SearchableBooleanFilterInput | null,
  stripeAccountId?: SearchableStringFilterInput | null,
  firebaseTokens?: SearchableStringFilterInput | null,
  stripeCustomerId?: SearchableStringFilterInput | null,
  ivsChannelArn?: SearchableStringFilterInput | null,
  and?: Array< SearchableUserFilterInput | null > | null,
  or?: Array< SearchableUserFilterInput | null > | null,
  not?: SearchableUserFilterInput | null,
};

export type SearchableIDFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  match?: string | null,
  matchPhrase?: string | null,
  matchPhrasePrefix?: string | null,
  multiMatch?: string | null,
  exists?: boolean | null,
  wildcard?: string | null,
  regexp?: string | null,
  range?: Array< string | null > | null,
};

export type SearchableStringFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  match?: string | null,
  matchPhrase?: string | null,
  matchPhrasePrefix?: string | null,
  multiMatch?: string | null,
  exists?: boolean | null,
  wildcard?: string | null,
  regexp?: string | null,
  range?: Array< string | null > | null,
};

export type SearchableBooleanFilterInput = {
  eq?: boolean | null,
  ne?: boolean | null,
};

export type SearchableUserSortInput = {
  field?: SearchableUserSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableUserSortableFields {
  name = "name",
  givenName = "givenName",
  familyName = "familyName",
  role = "role",
  gender = "gender",
  country = "country",
  sport = "sport",
  stats_numUniqueViews = "stats_numUniqueViews",
}


export enum SearchableSortDirection {
  asc = "asc",
  desc = "desc",
}


export type SearchableUserConnection = {
  __typename: "SearchableUserConnection",
  items?:  Array<User | null > | null,
  nextToken?: string | null,
  total?: SearchTotal | null,
};

export type SearchTotal = {
  __typename: "SearchTotal",
  value?: number | null,
  relation?: string | null,
};

export type ModelPostFilterInput = {
  id?: ModelIDInput | null,
  authorId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  needsSubscription?: ModelBooleanInput | null,
  type?: ModelPostTypeInput | null,
  tierId?: ModelIDInput | null,
  vodId?: ModelStringInput | null,
  vodStatus?: ModelStringInput | null,
  thumbS3Key?: ModelStringInput | null,
  likes?: ModelIntInput | null,
  comments?: ModelIntInput | null,
  liked?: ModelBooleanInput | null,
  reviewStatus?: ModelPostReviewStatusInput | null,
  feedId?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelPostFilterInput | null > | null,
  or?: Array< ModelPostFilterInput | null > | null,
  not?: ModelPostFilterInput | null,
};

export type ModelPostConnection = {
  __typename: "ModelPostConnection",
  items:  Array<Post | null >,
  nextToken?: string | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type SearchablePostFilterInput = {
  id?: SearchableIDFilterInput | null,
  authorId?: SearchableIDFilterInput | null,
  name?: SearchableStringFilterInput | null,
  description?: SearchableStringFilterInput | null,
  needsSubscription?: SearchableBooleanFilterInput | null,
  tierId?: SearchableIDFilterInput | null,
  vodId?: SearchableStringFilterInput | null,
  vodStatus?: SearchableStringFilterInput | null,
  thumbS3Key?: SearchableStringFilterInput | null,
  likes?: SearchableIntFilterInput | null,
  comments?: SearchableIntFilterInput | null,
  liked?: SearchableBooleanFilterInput | null,
  feedId?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  and?: Array< SearchablePostFilterInput | null > | null,
  or?: Array< SearchablePostFilterInput | null > | null,
  not?: SearchablePostFilterInput | null,
};

export type SearchableIntFilterInput = {
  ne?: number | null,
  gt?: number | null,
  lt?: number | null,
  gte?: number | null,
  lte?: number | null,
  eq?: number | null,
  range?: Array< number | null > | null,
};

export type SearchablePostSortInput = {
  field?: SearchablePostSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchablePostSortableFields {
  id = "id",
  authorId = "authorId",
  name = "name",
  description = "description",
  needsSubscription = "needsSubscription",
  tierId = "tierId",
  vodId = "vodId",
  vodStatus = "vodStatus",
  thumbS3Key = "thumbS3Key",
  likes = "likes",
  comments = "comments",
  liked = "liked",
  feedId = "feedId",
  createdAt = "createdAt",
}


export type SearchablePostConnection = {
  __typename: "SearchablePostConnection",
  items?:  Array<Post | null > | null,
  nextToken?: string | null,
  total?: SearchTotal | null,
};

export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelAthleteSubscriptionFilterInput = {
  subscriberId?: ModelIDInput | null,
  athleteId?: ModelIDInput | null,
  tierId?: ModelIDInput | null,
  currency?: ModelStringInput | null,
  unitAmount?: ModelIntInput | null,
  paidToAthlete?: ModelIntInput | null,
  status?: ModelSubscriptionStatusInput | null,
  cancelAtPeriodEnd?: ModelBooleanInput | null,
  currentPeriodEnd?: ModelIntInput | null,
  expiresAt?: ModelStringInput | null,
  discountType?: ModelDiscountTypeInput | null,
  discount?: ModelIntInput | null,
  discountRemainingMonths?: ModelIntInput | null,
  couponId?: ModelStringInput | null,
  taxRateId?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  stripeSubscriptionId?: ModelStringInput | null,
  stripePriceId?: ModelStringInput | null,
  and?: Array< ModelAthleteSubscriptionFilterInput | null > | null,
  or?: Array< ModelAthleteSubscriptionFilterInput | null > | null,
  not?: ModelAthleteSubscriptionFilterInput | null,
};

export type ModelAthleteSubscriptionConnection = {
  __typename: "ModelAthleteSubscriptionConnection",
  items:  Array<AthleteSubscription | null >,
  nextToken?: string | null,
};

export type SearchableAthleteSubscriptionFilterInput = {
  subscriberId?: SearchableIDFilterInput | null,
  athleteId?: SearchableIDFilterInput | null,
  tierId?: SearchableIDFilterInput | null,
  currency?: SearchableStringFilterInput | null,
  unitAmount?: SearchableIntFilterInput | null,
  paidToAthlete?: SearchableIntFilterInput | null,
  cancelAtPeriodEnd?: SearchableBooleanFilterInput | null,
  currentPeriodEnd?: SearchableIntFilterInput | null,
  expiresAt?: SearchableStringFilterInput | null,
  discount?: SearchableIntFilterInput | null,
  discountRemainingMonths?: SearchableIntFilterInput | null,
  couponId?: SearchableStringFilterInput | null,
  taxRateId?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  stripeSubscriptionId?: SearchableStringFilterInput | null,
  stripePriceId?: SearchableStringFilterInput | null,
  and?: Array< SearchableAthleteSubscriptionFilterInput | null > | null,
  or?: Array< SearchableAthleteSubscriptionFilterInput | null > | null,
  not?: SearchableAthleteSubscriptionFilterInput | null,
};

export type SearchableAthleteSubscriptionSortInput = {
  field?: SearchableAthleteSubscriptionSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableAthleteSubscriptionSortableFields {
  subscriberId = "subscriberId",
  athleteId = "athleteId",
  tierId = "tierId",
  currency = "currency",
  unitAmount = "unitAmount",
  paidToAthlete = "paidToAthlete",
  cancelAtPeriodEnd = "cancelAtPeriodEnd",
  currentPeriodEnd = "currentPeriodEnd",
  expiresAt = "expiresAt",
  discount = "discount",
  discountRemainingMonths = "discountRemainingMonths",
  couponId = "couponId",
  taxRateId = "taxRateId",
  updatedAt = "updatedAt",
  createdAt = "createdAt",
  stripeSubscriptionId = "stripeSubscriptionId",
  stripePriceId = "stripePriceId",
}


export type SearchableAthleteSubscriptionConnection = {
  __typename: "SearchableAthleteSubscriptionConnection",
  items:  Array<AthleteSubscription | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelTierFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  currency?: ModelStringInput | null,
  unitAmount?: ModelIntInput | null,
  discountType?: ModelDiscountTypeInput | null,
  discount?: ModelIntInput | null,
  discountMonths?: ModelIntInput | null,
  couponId?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelTierFilterInput | null > | null,
  or?: Array< ModelTierFilterInput | null > | null,
  not?: ModelTierFilterInput | null,
};

export type ModelTierConnection = {
  __typename: "ModelTierConnection",
  items:  Array<Tier | null >,
  nextToken?: string | null,
};

export type ModelFlashRequestPrimaryCompositeKeyConditionInput = {
  eq?: ModelFlashRequestPrimaryCompositeKeyInput | null,
  le?: ModelFlashRequestPrimaryCompositeKeyInput | null,
  lt?: ModelFlashRequestPrimaryCompositeKeyInput | null,
  ge?: ModelFlashRequestPrimaryCompositeKeyInput | null,
  gt?: ModelFlashRequestPrimaryCompositeKeyInput | null,
  between?: Array< ModelFlashRequestPrimaryCompositeKeyInput | null > | null,
  beginsWith?: ModelFlashRequestPrimaryCompositeKeyInput | null,
};

export type ModelFlashRequestPrimaryCompositeKeyInput = {
  request?: FlashRequestType | null,
  targetId?: string | null,
};

export type ModelFlashRequestFilterInput = {
  userId?: ModelIDInput | null,
  request?: ModelFlashRequestTypeInput | null,
  targetId?: ModelStringInput | null,
  payload?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelFlashRequestFilterInput | null > | null,
  or?: Array< ModelFlashRequestFilterInput | null > | null,
  not?: ModelFlashRequestFilterInput | null,
};

export type ModelFlashRequestTypeInput = {
  eq?: FlashRequestType | null,
  ne?: FlashRequestType | null,
};

export type ModelFlashRequestConnection = {
  __typename: "ModelFlashRequestConnection",
  items:  Array<FlashRequest | null >,
  nextToken?: string | null,
};

export type ModelLikePrimaryCompositeKeyConditionInput = {
  eq?: ModelLikePrimaryCompositeKeyInput | null,
  le?: ModelLikePrimaryCompositeKeyInput | null,
  lt?: ModelLikePrimaryCompositeKeyInput | null,
  ge?: ModelLikePrimaryCompositeKeyInput | null,
  gt?: ModelLikePrimaryCompositeKeyInput | null,
  between?: Array< ModelLikePrimaryCompositeKeyInput | null > | null,
  beginsWith?: ModelLikePrimaryCompositeKeyInput | null,
};

export type ModelLikePrimaryCompositeKeyInput = {
  targetType?: LikeTargetType | null,
  targetId?: string | null,
};

export type ModelLikeFilterInput = {
  userId?: ModelIDInput | null,
  targetId?: ModelStringInput | null,
  targetType?: ModelLikeTargetTypeInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelLikeFilterInput | null > | null,
  or?: Array< ModelLikeFilterInput | null > | null,
  not?: ModelLikeFilterInput | null,
};

export type ModelLikeTargetTypeInput = {
  eq?: LikeTargetType | null,
  ne?: LikeTargetType | null,
};

export type ModelLikeConnection = {
  __typename: "ModelLikeConnection",
  items:  Array<Like | null >,
  nextToken?: string | null,
};

export type ModelCommentFilterInput = {
  id?: ModelIDInput | null,
  authorId?: ModelIDInput | null,
  parentId?: ModelIDInput | null,
  parentType?: ModelCommentParentTypeInput | null,
  message?: ModelStringInput | null,
  likes?: ModelIntInput | null,
  comments?: ModelIntInput | null,
  liked?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelCommentFilterInput | null > | null,
  or?: Array< ModelCommentFilterInput | null > | null,
  not?: ModelCommentFilterInput | null,
};

export type ModelCommentParentTypeInput = {
  eq?: CommentParentType | null,
  ne?: CommentParentType | null,
};

export type ModelCommentConnection = {
  __typename: "ModelCommentConnection",
  items:  Array<Comment | null >,
  nextToken?: string | null,
};

export type ModelMigrationsFilterInput = {
  name?: ModelIDInput | null,
  and?: Array< ModelMigrationsFilterInput | null > | null,
  or?: Array< ModelMigrationsFilterInput | null > | null,
  not?: ModelMigrationsFilterInput | null,
};

export type ModelMigrationsConnection = {
  __typename: "ModelMigrationsConnection",
  items:  Array<Migrations | null >,
  nextToken?: string | null,
};

export type ModelBcastFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  tierId?: ModelIDInput | null,
  needsSubscription?: ModelBooleanInput | null,
  thumbnailImageS3Key?: ModelStringInput | null,
  previewImageS3Key?: ModelStringInput | null,
  time?: ModelStringInput | null,
  duration?: ModelStringInput | null,
  allowQuestions?: ModelBooleanInput | null,
  status?: ModelBcastStatusInput | null,
  bcastUrl?: ModelStringInput | null,
  recordingS3Key?: ModelStringInput | null,
  prepareArgs?: ModelStringInput | null,
  and?: Array< ModelBcastFilterInput | null > | null,
  or?: Array< ModelBcastFilterInput | null > | null,
  not?: ModelBcastFilterInput | null,
};

export type ModelBcastStatusInput = {
  eq?: BcastStatus | null,
  ne?: BcastStatus | null,
};

export type ModelBcastConnection = {
  __typename: "ModelBcastConnection",
  items:  Array<Bcast | null >,
  nextToken?: string | null,
};

export type UpgradeToAthleteMutation = {
  upgradeToAthlete?:  {
    __typename: "User",
    id: string,
    name: string,
    givenName: string,
    familyName: string,
    gender?: string | null,
    birthdate?: string | null,
    country?: string | null,
    sport?: string | null,
    profileImgS3Key?: string | null,
    coverImgS3Key?: string | null,
    introVideoS3Key?: string | null,
    introVideoThumbS3Key?: string | null,
    introVideoUrl?: string | null,
    mission?: string | null,
    instagramUrl?: string | null,
    facebookUrl?: string | null,
    youtubeUrl?: string | null,
    goals?:  Array< {
      __typename: "AthleteGoal",
      id: string,
      name: string,
      level?: string | null,
      description?: string | null,
      videoUrl?: string | null,
      link?: string | null,
    } | null > | null,
    achievements?:  Array< {
      __typename: "AthleteAchievement",
      id: string,
      name: string,
      level?: string | null,
      description?: string | null,
      link?: string | null,
      videoUrl?: string | null,
      date?: string | null,
    } | null > | null,
    fundingGoals?:  Array< {
      __typename: "AthleteFundingGoal",
      id: string,
      amount: number,
      currency?: string | null,
      description: string,
    } | null > | null,
    sponsors?:  Array< {
      __typename: "AthleteSponsor",
      id: string,
      imgS3Key: string,
    } | null > | null,
    emailValidated?: boolean | null,
    validationEmailSentAt?: string | null,
    potentialAthlete?: boolean | null,
    currency?: string | null,
    themeId?: string | null,
    role: string,
    nickname?: string | null,
    stripePayoutsEnabled?: boolean | null,
    payoutCurrency?: string | null,
    stats?:  {
      __typename: "UserStats",
      numUniqueViews?: number | null,
      numScouts?: number | null,
      paidByScouts?: number | null,
      numPosts?: number | null,
    } | null,
    features?:  {
      __typename: "UserFeatures",
      posts?: boolean | null,
    } | null,
    stripeCustomerId?: string | null,
    ivsChannelArn?: string | null,
    createdAt: string,
    updatedAt: string,
    owner: string,
    email: string,
    stripeActionRequired?: boolean | null,
    stripeAccountId?: string | null,
    social?:  {
      __typename: "UserSocial",
      fbUserId?: string | null,
      fbAccessToken?: string | null,
      fbTargetPageId?: string | null,
      twitterRequestToken?: string | null,
      twitterRequestSecret?: string | null,
      twitterAccessToken?: string | null,
      twitterAccessSecret?: string | null,
      instagramUserId?: string | null,
    } | null,
    firebaseTokens?: Array< string | null > | null,
  } | null,
};

export type UpdateUserNicknameMutationVariables = {
  nickname: string,
};

export type UpdateUserNicknameMutation = {
  updateUserNickname?: boolean | null,
};

export type SendEmailValidationEmailMutation = {
  sendEmailValidationEmail?: boolean | null,
};

export type ValidateEmailMutationVariables = {
  token: string,
};

export type ValidateEmailMutation = {
  validateEmail?:  {
    __typename: "BooleanResponse",
    value: boolean,
    error?: string | null,
  } | null,
};

export type CreatePaymentsAccountMutation = {
  createPaymentsAccount?: string | null,
};

export type AttachPaymentMethodMutationVariables = {
  paymentMethodId: string,
};

export type AttachPaymentMethodMutation = {
  attachPaymentMethod?:  {
    __typename: "PaymentMethod",
    id: string,
    type: string,
    billing_details?:  {
      __typename: "BillingDetails",
      address?:  {
        __typename: "BillingAddress",
        city?: string | null,
        country?: string | null,
        line1?: string | null,
        line2?: string | null,
        postal_code?: string | null,
        state?: string | null,
      } | null,
      email?: string | null,
      name?: string | null,
      phone?: string | null,
    } | null,
    card?:  {
      __typename: "CreditCard",
      brand?: string | null,
      country?: string | null,
      exp_month?: number | null,
      exp_year?: number | null,
      last4?: string | null,
    } | null,
    created?: number | null,
    customer?: string | null,
    is_default?: boolean | null,
  } | null,
};

export type DetachPaymentMethodMutationVariables = {
  paymentMethodId: string,
};

export type DetachPaymentMethodMutation = {
  detachPaymentMethod?:  {
    __typename: "PaymentMethod",
    id: string,
    type: string,
    billing_details?:  {
      __typename: "BillingDetails",
      address?:  {
        __typename: "BillingAddress",
        city?: string | null,
        country?: string | null,
        line1?: string | null,
        line2?: string | null,
        postal_code?: string | null,
        state?: string | null,
      } | null,
      email?: string | null,
      name?: string | null,
      phone?: string | null,
    } | null,
    card?:  {
      __typename: "CreditCard",
      brand?: string | null,
      country?: string | null,
      exp_month?: number | null,
      exp_year?: number | null,
      last4?: string | null,
    } | null,
    created?: number | null,
    customer?: string | null,
    is_default?: boolean | null,
  } | null,
};

export type SubscribeToAthleteMutationVariables = {
  athleteId: string,
  tierId: string,
  currency: string,
  taxRateId?: string | null,
};

export type SubscribeToAthleteMutation = {
  subscribeToAthlete?: string | null,
};

export type CancelSubscriptionMutationVariables = {
  athleteId: string,
};

export type CancelSubscriptionMutation = {
  cancelSubscription?: boolean | null,
};

export type CreateTierMutationVariables = {
  name: string,
  currency: string,
  unitAmount: number,
};

export type CreateTierMutation = {
  createTier?:  {
    __typename: "Tier",
    id: string,
    userId: string,
    name: string,
    currency: string,
    unitAmount: number,
    discountType?: DiscountType | null,
    discount?: number | null,
    discountMonths?: number | null,
    couponId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type AdminReviewPostMutationVariables = {
  postId: string,
  reviewStatus: string,
  reason?: string | null,
};

export type AdminReviewPostMutation = {
  adminReviewPost?: boolean | null,
};

export type AdminPushNotificationMutationVariables = {
  to: string,
  ids?: Array< string | null > | null,
  title: string,
  body: string,
};

export type AdminPushNotificationMutation = {
  adminPushNotification?: string | null,
};

export type CallTwitterMutationVariables = {
  action: string,
  previewUrl?: string | null,
  token?: string | null,
  secret?: string | null,
  oauthVerifier?: string | null,
  athleteProfileUrl?: string | null,
  cbUrl?: string | null,
};

export type CallTwitterMutation = {
  callTwitter?: string | null,
};

export type CreateCommentMutationVariables = {
  parentId: string,
  parentType: string,
  message: string,
  recaptchaKey: string,
};

export type CreateCommentMutation = {
  createComment?:  {
    __typename: "Comment",
    id: string,
    authorId: string,
    parentId: string,
    parentType: CommentParentType,
    lastComment?:  {
      __typename: "Comment",
      id: string,
      authorId: string,
      parentId: string,
      parentType: CommentParentType,
      lastComment?:  {
        __typename: "Comment",
        id: string,
        authorId: string,
        parentId: string,
        parentType: CommentParentType,
        message: string,
        likes?: number | null,
        comments?: number | null,
        liked?: boolean | null,
        createdAt: string,
        updatedAt?: string | null,
      } | null,
      message: string,
      likes?: number | null,
      comments?: number | null,
      liked?: boolean | null,
      createdAt: string,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        name: string,
        givenName: string,
        familyName: string,
        gender?: string | null,
        birthdate?: string | null,
        country?: string | null,
        sport?: string | null,
        profileImgS3Key?: string | null,
        coverImgS3Key?: string | null,
        introVideoS3Key?: string | null,
        introVideoThumbS3Key?: string | null,
        introVideoUrl?: string | null,
        mission?: string | null,
        instagramUrl?: string | null,
        facebookUrl?: string | null,
        youtubeUrl?: string | null,
        emailValidated?: boolean | null,
        validationEmailSentAt?: string | null,
        potentialAthlete?: boolean | null,
        currency?: string | null,
        themeId?: string | null,
        role: string,
        nickname?: string | null,
        stripePayoutsEnabled?: boolean | null,
        payoutCurrency?: string | null,
        stripeCustomerId?: string | null,
        ivsChannelArn?: string | null,
        createdAt: string,
        updatedAt: string,
        owner: string,
        email: string,
        stripeActionRequired?: boolean | null,
        stripeAccountId?: string | null,
        firebaseTokens?: Array< string | null > | null,
      } | null,
    } | null,
    message: string,
    likes?: number | null,
    comments?: number | null,
    liked?: boolean | null,
    createdAt: string,
    updatedAt?: string | null,
    author?:  {
      __typename: "User",
      id: string,
      name: string,
      givenName: string,
      familyName: string,
      gender?: string | null,
      birthdate?: string | null,
      country?: string | null,
      sport?: string | null,
      profileImgS3Key?: string | null,
      coverImgS3Key?: string | null,
      introVideoS3Key?: string | null,
      introVideoThumbS3Key?: string | null,
      introVideoUrl?: string | null,
      mission?: string | null,
      instagramUrl?: string | null,
      facebookUrl?: string | null,
      youtubeUrl?: string | null,
      goals?:  Array< {
        __typename: "AthleteGoal",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        videoUrl?: string | null,
        link?: string | null,
      } | null > | null,
      achievements?:  Array< {
        __typename: "AthleteAchievement",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        link?: string | null,
        videoUrl?: string | null,
        date?: string | null,
      } | null > | null,
      fundingGoals?:  Array< {
        __typename: "AthleteFundingGoal",
        id: string,
        amount: number,
        currency?: string | null,
        description: string,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "AthleteSponsor",
        id: string,
        imgS3Key: string,
      } | null > | null,
      emailValidated?: boolean | null,
      validationEmailSentAt?: string | null,
      potentialAthlete?: boolean | null,
      currency?: string | null,
      themeId?: string | null,
      role: string,
      nickname?: string | null,
      stripePayoutsEnabled?: boolean | null,
      payoutCurrency?: string | null,
      stats?:  {
        __typename: "UserStats",
        numUniqueViews?: number | null,
        numScouts?: number | null,
        paidByScouts?: number | null,
        numPosts?: number | null,
      } | null,
      features?:  {
        __typename: "UserFeatures",
        posts?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      ivsChannelArn?: string | null,
      createdAt: string,
      updatedAt: string,
      owner: string,
      email: string,
      stripeActionRequired?: boolean | null,
      stripeAccountId?: string | null,
      social?:  {
        __typename: "UserSocial",
        fbUserId?: string | null,
        fbAccessToken?: string | null,
        fbTargetPageId?: string | null,
        twitterRequestToken?: string | null,
        twitterRequestSecret?: string | null,
        twitterAccessToken?: string | null,
        twitterAccessSecret?: string | null,
        instagramUserId?: string | null,
      } | null,
      firebaseTokens?: Array< string | null > | null,
    } | null,
  } | null,
};

export type CreateBcastMutationVariables = {
  input: CreateBcastInput,
};

export type CreateBcastMutation = {
  createBcast?:  {
    __typename: "Bcast",
    id: string,
    userId: string,
    title: string,
    description?: string | null,
    tierId: string,
    needsSubscription: boolean,
    thumbnailImageS3Key?: string | null,
    previewImageS3Key?: string | null,
    time: string,
    duration?: string | null,
    guests:  Array< {
      __typename: "BcastGuest",
      id: string,
      name: string,
    } >,
    allowQuestions: boolean,
    status: BcastStatus,
    bcastUrl?: string | null,
    recordingS3Key?: string | null,
    prepareArgs?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateBcastMutationVariables = {
  input: UpdateBcastInput,
};

export type UpdateBcastMutation = {
  updateBcast?:  {
    __typename: "UpdatedBcast",
    id: string,
    userId?: string | null,
    title?: string | null,
    description?: string | null,
    thumbnailImageS3Key?: string | null,
    previewImageS3Key?: string | null,
    time?: string | null,
    duration?: string | null,
    guests?: string | null,
    allowQuestions?: boolean | null,
    status?: BcastStatus | null,
    bcastUrl?: string | null,
    recordingS3Key?: string | null,
  } | null,
};

export type DeleteBcastMutationVariables = {
  input: DeleteBcastInput,
};

export type DeleteBcastMutation = {
  deleteBcast?:  {
    __typename: "Bcast",
    id: string,
    userId: string,
    title: string,
    description?: string | null,
    tierId: string,
    needsSubscription: boolean,
    thumbnailImageS3Key?: string | null,
    previewImageS3Key?: string | null,
    time: string,
    duration?: string | null,
    guests:  Array< {
      __typename: "BcastGuest",
      id: string,
      name: string,
    } >,
    allowQuestions: boolean,
    status: BcastStatus,
    bcastUrl?: string | null,
    recordingS3Key?: string | null,
    prepareArgs?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type JoinMeetingMutationVariables = {
  input: JoinMeetingInput,
};

export type JoinMeetingMutation = {
  joinMeeting?:  {
    __typename: "JoinMeetingResponse",
    meetingResponse: string,
    attendeeResponse: string,
  } | null,
};

export type LeaveMeetingMutationVariables = {
  input: MeetingCmdInput,
};

export type LeaveMeetingMutation = {
  leaveMeeting?:  {
    __typename: "MeetingCmdResponse",
    status: string,
  } | null,
};

export type EndMeetingMutationVariables = {
  input: MeetingCmdInput,
};

export type EndMeetingMutation = {
  endMeeting?:  {
    __typename: "MeetingCmdResponse",
    status: string,
  } | null,
};

export type PrepareBcastMutationVariables = {
  input: MeetingCmdInput,
};

export type PrepareBcastMutation = {
  prepareBcast?:  {
    __typename: "MeetingCmdResponse",
    status: string,
  } | null,
};

export type StartBcastMutationVariables = {
  input: MeetingCmdInput,
};

export type StartBcastMutation = {
  startBcast?:  {
    __typename: "MeetingCmdResponse",
    status: string,
  } | null,
};

export type StopBcastMutationVariables = {
  input: MeetingCmdInput,
};

export type StopBcastMutation = {
  stopBcast?:  {
    __typename: "MeetingCmdResponse",
    status: string,
  } | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    name: string,
    givenName: string,
    familyName: string,
    gender?: string | null,
    birthdate?: string | null,
    country?: string | null,
    sport?: string | null,
    profileImgS3Key?: string | null,
    coverImgS3Key?: string | null,
    introVideoS3Key?: string | null,
    introVideoThumbS3Key?: string | null,
    introVideoUrl?: string | null,
    mission?: string | null,
    instagramUrl?: string | null,
    facebookUrl?: string | null,
    youtubeUrl?: string | null,
    goals?:  Array< {
      __typename: "AthleteGoal",
      id: string,
      name: string,
      level?: string | null,
      description?: string | null,
      videoUrl?: string | null,
      link?: string | null,
    } | null > | null,
    achievements?:  Array< {
      __typename: "AthleteAchievement",
      id: string,
      name: string,
      level?: string | null,
      description?: string | null,
      link?: string | null,
      videoUrl?: string | null,
      date?: string | null,
    } | null > | null,
    fundingGoals?:  Array< {
      __typename: "AthleteFundingGoal",
      id: string,
      amount: number,
      currency?: string | null,
      description: string,
    } | null > | null,
    sponsors?:  Array< {
      __typename: "AthleteSponsor",
      id: string,
      imgS3Key: string,
    } | null > | null,
    emailValidated?: boolean | null,
    validationEmailSentAt?: string | null,
    potentialAthlete?: boolean | null,
    currency?: string | null,
    themeId?: string | null,
    role: string,
    nickname?: string | null,
    stripePayoutsEnabled?: boolean | null,
    payoutCurrency?: string | null,
    stats?:  {
      __typename: "UserStats",
      numUniqueViews?: number | null,
      numScouts?: number | null,
      paidByScouts?: number | null,
      numPosts?: number | null,
    } | null,
    features?:  {
      __typename: "UserFeatures",
      posts?: boolean | null,
    } | null,
    stripeCustomerId?: string | null,
    ivsChannelArn?: string | null,
    createdAt: string,
    updatedAt: string,
    owner: string,
    email: string,
    stripeActionRequired?: boolean | null,
    stripeAccountId?: string | null,
    social?:  {
      __typename: "UserSocial",
      fbUserId?: string | null,
      fbAccessToken?: string | null,
      fbTargetPageId?: string | null,
      twitterRequestToken?: string | null,
      twitterRequestSecret?: string | null,
      twitterAccessToken?: string | null,
      twitterAccessSecret?: string | null,
      instagramUserId?: string | null,
    } | null,
    firebaseTokens?: Array< string | null > | null,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    name: string,
    givenName: string,
    familyName: string,
    gender?: string | null,
    birthdate?: string | null,
    country?: string | null,
    sport?: string | null,
    profileImgS3Key?: string | null,
    coverImgS3Key?: string | null,
    introVideoS3Key?: string | null,
    introVideoThumbS3Key?: string | null,
    introVideoUrl?: string | null,
    mission?: string | null,
    instagramUrl?: string | null,
    facebookUrl?: string | null,
    youtubeUrl?: string | null,
    goals?:  Array< {
      __typename: "AthleteGoal",
      id: string,
      name: string,
      level?: string | null,
      description?: string | null,
      videoUrl?: string | null,
      link?: string | null,
    } | null > | null,
    achievements?:  Array< {
      __typename: "AthleteAchievement",
      id: string,
      name: string,
      level?: string | null,
      description?: string | null,
      link?: string | null,
      videoUrl?: string | null,
      date?: string | null,
    } | null > | null,
    fundingGoals?:  Array< {
      __typename: "AthleteFundingGoal",
      id: string,
      amount: number,
      currency?: string | null,
      description: string,
    } | null > | null,
    sponsors?:  Array< {
      __typename: "AthleteSponsor",
      id: string,
      imgS3Key: string,
    } | null > | null,
    emailValidated?: boolean | null,
    validationEmailSentAt?: string | null,
    potentialAthlete?: boolean | null,
    currency?: string | null,
    themeId?: string | null,
    role: string,
    nickname?: string | null,
    stripePayoutsEnabled?: boolean | null,
    payoutCurrency?: string | null,
    stats?:  {
      __typename: "UserStats",
      numUniqueViews?: number | null,
      numScouts?: number | null,
      paidByScouts?: number | null,
      numPosts?: number | null,
    } | null,
    features?:  {
      __typename: "UserFeatures",
      posts?: boolean | null,
    } | null,
    stripeCustomerId?: string | null,
    ivsChannelArn?: string | null,
    createdAt: string,
    updatedAt: string,
    owner: string,
    email: string,
    stripeActionRequired?: boolean | null,
    stripeAccountId?: string | null,
    social?:  {
      __typename: "UserSocial",
      fbUserId?: string | null,
      fbAccessToken?: string | null,
      fbTargetPageId?: string | null,
      twitterRequestToken?: string | null,
      twitterRequestSecret?: string | null,
      twitterAccessToken?: string | null,
      twitterAccessSecret?: string | null,
      instagramUserId?: string | null,
    } | null,
    firebaseTokens?: Array< string | null > | null,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
    name: string,
    givenName: string,
    familyName: string,
    gender?: string | null,
    birthdate?: string | null,
    country?: string | null,
    sport?: string | null,
    profileImgS3Key?: string | null,
    coverImgS3Key?: string | null,
    introVideoS3Key?: string | null,
    introVideoThumbS3Key?: string | null,
    introVideoUrl?: string | null,
    mission?: string | null,
    instagramUrl?: string | null,
    facebookUrl?: string | null,
    youtubeUrl?: string | null,
    goals?:  Array< {
      __typename: "AthleteGoal",
      id: string,
      name: string,
      level?: string | null,
      description?: string | null,
      videoUrl?: string | null,
      link?: string | null,
    } | null > | null,
    achievements?:  Array< {
      __typename: "AthleteAchievement",
      id: string,
      name: string,
      level?: string | null,
      description?: string | null,
      link?: string | null,
      videoUrl?: string | null,
      date?: string | null,
    } | null > | null,
    fundingGoals?:  Array< {
      __typename: "AthleteFundingGoal",
      id: string,
      amount: number,
      currency?: string | null,
      description: string,
    } | null > | null,
    sponsors?:  Array< {
      __typename: "AthleteSponsor",
      id: string,
      imgS3Key: string,
    } | null > | null,
    emailValidated?: boolean | null,
    validationEmailSentAt?: string | null,
    potentialAthlete?: boolean | null,
    currency?: string | null,
    themeId?: string | null,
    role: string,
    nickname?: string | null,
    stripePayoutsEnabled?: boolean | null,
    payoutCurrency?: string | null,
    stats?:  {
      __typename: "UserStats",
      numUniqueViews?: number | null,
      numScouts?: number | null,
      paidByScouts?: number | null,
      numPosts?: number | null,
    } | null,
    features?:  {
      __typename: "UserFeatures",
      posts?: boolean | null,
    } | null,
    stripeCustomerId?: string | null,
    ivsChannelArn?: string | null,
    createdAt: string,
    updatedAt: string,
    owner: string,
    email: string,
    stripeActionRequired?: boolean | null,
    stripeAccountId?: string | null,
    social?:  {
      __typename: "UserSocial",
      fbUserId?: string | null,
      fbAccessToken?: string | null,
      fbTargetPageId?: string | null,
      twitterRequestToken?: string | null,
      twitterRequestSecret?: string | null,
      twitterAccessToken?: string | null,
      twitterAccessSecret?: string | null,
      instagramUserId?: string | null,
    } | null,
    firebaseTokens?: Array< string | null > | null,
  } | null,
};

export type CreatePostMutationVariables = {
  input: CreatePostInput,
  condition?: ModelPostConditionInput | null,
};

export type CreatePostMutation = {
  createPost?:  {
    __typename: "Post",
    id: string,
    authorId: string,
    name: string,
    description?: string | null,
    needsSubscription?: boolean | null,
    type: PostType,
    tierId: string,
    vodId?: string | null,
    vodStatus?: string | null,
    vodMetadata?:  {
      __typename: "PostVodMetadata",
      durationInMs?: number | null,
      orgWidth?: number | null,
      orgHeight?: number | null,
      hasPreview?: boolean | null,
    } | null,
    bcastMetadata?:  {
      __typename: "PostBcastMetadata",
      previewImageS3Key?: string | null,
      time: string,
      duration?: string | null,
      guests:  Array< {
        __typename: "BcastGuest",
        id: string,
        name: string,
      } >,
      allowQuestions: boolean,
      status: BcastStatus,
      bcastUrl?: string | null,
      recordingS3Key?: string | null,
    } | null,
    thumbS3Key?: string | null,
    likes?: number | null,
    comments?: number | null,
    liked?: boolean | null,
    reviewStatus?: PostReviewStatus | null,
    feedId?: string | null,
    createdAt: string,
    updatedAt: string,
    author?:  {
      __typename: "User",
      id: string,
      name: string,
      givenName: string,
      familyName: string,
      gender?: string | null,
      birthdate?: string | null,
      country?: string | null,
      sport?: string | null,
      profileImgS3Key?: string | null,
      coverImgS3Key?: string | null,
      introVideoS3Key?: string | null,
      introVideoThumbS3Key?: string | null,
      introVideoUrl?: string | null,
      mission?: string | null,
      instagramUrl?: string | null,
      facebookUrl?: string | null,
      youtubeUrl?: string | null,
      goals?:  Array< {
        __typename: "AthleteGoal",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        videoUrl?: string | null,
        link?: string | null,
      } | null > | null,
      achievements?:  Array< {
        __typename: "AthleteAchievement",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        link?: string | null,
        videoUrl?: string | null,
        date?: string | null,
      } | null > | null,
      fundingGoals?:  Array< {
        __typename: "AthleteFundingGoal",
        id: string,
        amount: number,
        currency?: string | null,
        description: string,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "AthleteSponsor",
        id: string,
        imgS3Key: string,
      } | null > | null,
      emailValidated?: boolean | null,
      validationEmailSentAt?: string | null,
      potentialAthlete?: boolean | null,
      currency?: string | null,
      themeId?: string | null,
      role: string,
      nickname?: string | null,
      stripePayoutsEnabled?: boolean | null,
      payoutCurrency?: string | null,
      stats?:  {
        __typename: "UserStats",
        numUniqueViews?: number | null,
        numScouts?: number | null,
        paidByScouts?: number | null,
        numPosts?: number | null,
      } | null,
      features?:  {
        __typename: "UserFeatures",
        posts?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      ivsChannelArn?: string | null,
      createdAt: string,
      updatedAt: string,
      owner: string,
      email: string,
      stripeActionRequired?: boolean | null,
      stripeAccountId?: string | null,
      social?:  {
        __typename: "UserSocial",
        fbUserId?: string | null,
        fbAccessToken?: string | null,
        fbTargetPageId?: string | null,
        twitterRequestToken?: string | null,
        twitterRequestSecret?: string | null,
        twitterAccessToken?: string | null,
        twitterAccessSecret?: string | null,
        instagramUserId?: string | null,
      } | null,
      firebaseTokens?: Array< string | null > | null,
    } | null,
    tier?:  {
      __typename: "Tier",
      id: string,
      userId: string,
      name: string,
      currency: string,
      unitAmount: number,
      discountType?: DiscountType | null,
      discount?: number | null,
      discountMonths?: number | null,
      couponId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type UpdatePostMutationVariables = {
  input: UpdatePostInput,
  condition?: ModelPostConditionInput | null,
};

export type UpdatePostMutation = {
  updatePost?:  {
    __typename: "Post",
    id: string,
    authorId: string,
    name: string,
    description?: string | null,
    needsSubscription?: boolean | null,
    type: PostType,
    tierId: string,
    vodId?: string | null,
    vodStatus?: string | null,
    vodMetadata?:  {
      __typename: "PostVodMetadata",
      durationInMs?: number | null,
      orgWidth?: number | null,
      orgHeight?: number | null,
      hasPreview?: boolean | null,
    } | null,
    bcastMetadata?:  {
      __typename: "PostBcastMetadata",
      previewImageS3Key?: string | null,
      time: string,
      duration?: string | null,
      guests:  Array< {
        __typename: "BcastGuest",
        id: string,
        name: string,
      } >,
      allowQuestions: boolean,
      status: BcastStatus,
      bcastUrl?: string | null,
      recordingS3Key?: string | null,
    } | null,
    thumbS3Key?: string | null,
    likes?: number | null,
    comments?: number | null,
    liked?: boolean | null,
    reviewStatus?: PostReviewStatus | null,
    feedId?: string | null,
    createdAt: string,
    updatedAt: string,
    author?:  {
      __typename: "User",
      id: string,
      name: string,
      givenName: string,
      familyName: string,
      gender?: string | null,
      birthdate?: string | null,
      country?: string | null,
      sport?: string | null,
      profileImgS3Key?: string | null,
      coverImgS3Key?: string | null,
      introVideoS3Key?: string | null,
      introVideoThumbS3Key?: string | null,
      introVideoUrl?: string | null,
      mission?: string | null,
      instagramUrl?: string | null,
      facebookUrl?: string | null,
      youtubeUrl?: string | null,
      goals?:  Array< {
        __typename: "AthleteGoal",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        videoUrl?: string | null,
        link?: string | null,
      } | null > | null,
      achievements?:  Array< {
        __typename: "AthleteAchievement",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        link?: string | null,
        videoUrl?: string | null,
        date?: string | null,
      } | null > | null,
      fundingGoals?:  Array< {
        __typename: "AthleteFundingGoal",
        id: string,
        amount: number,
        currency?: string | null,
        description: string,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "AthleteSponsor",
        id: string,
        imgS3Key: string,
      } | null > | null,
      emailValidated?: boolean | null,
      validationEmailSentAt?: string | null,
      potentialAthlete?: boolean | null,
      currency?: string | null,
      themeId?: string | null,
      role: string,
      nickname?: string | null,
      stripePayoutsEnabled?: boolean | null,
      payoutCurrency?: string | null,
      stats?:  {
        __typename: "UserStats",
        numUniqueViews?: number | null,
        numScouts?: number | null,
        paidByScouts?: number | null,
        numPosts?: number | null,
      } | null,
      features?:  {
        __typename: "UserFeatures",
        posts?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      ivsChannelArn?: string | null,
      createdAt: string,
      updatedAt: string,
      owner: string,
      email: string,
      stripeActionRequired?: boolean | null,
      stripeAccountId?: string | null,
      social?:  {
        __typename: "UserSocial",
        fbUserId?: string | null,
        fbAccessToken?: string | null,
        fbTargetPageId?: string | null,
        twitterRequestToken?: string | null,
        twitterRequestSecret?: string | null,
        twitterAccessToken?: string | null,
        twitterAccessSecret?: string | null,
        instagramUserId?: string | null,
      } | null,
      firebaseTokens?: Array< string | null > | null,
    } | null,
    tier?:  {
      __typename: "Tier",
      id: string,
      userId: string,
      name: string,
      currency: string,
      unitAmount: number,
      discountType?: DiscountType | null,
      discount?: number | null,
      discountMonths?: number | null,
      couponId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type DeletePostMutationVariables = {
  input: DeletePostInput,
  condition?: ModelPostConditionInput | null,
};

export type DeletePostMutation = {
  deletePost?:  {
    __typename: "Post",
    id: string,
    authorId: string,
    name: string,
    description?: string | null,
    needsSubscription?: boolean | null,
    type: PostType,
    tierId: string,
    vodId?: string | null,
    vodStatus?: string | null,
    vodMetadata?:  {
      __typename: "PostVodMetadata",
      durationInMs?: number | null,
      orgWidth?: number | null,
      orgHeight?: number | null,
      hasPreview?: boolean | null,
    } | null,
    bcastMetadata?:  {
      __typename: "PostBcastMetadata",
      previewImageS3Key?: string | null,
      time: string,
      duration?: string | null,
      guests:  Array< {
        __typename: "BcastGuest",
        id: string,
        name: string,
      } >,
      allowQuestions: boolean,
      status: BcastStatus,
      bcastUrl?: string | null,
      recordingS3Key?: string | null,
    } | null,
    thumbS3Key?: string | null,
    likes?: number | null,
    comments?: number | null,
    liked?: boolean | null,
    reviewStatus?: PostReviewStatus | null,
    feedId?: string | null,
    createdAt: string,
    updatedAt: string,
    author?:  {
      __typename: "User",
      id: string,
      name: string,
      givenName: string,
      familyName: string,
      gender?: string | null,
      birthdate?: string | null,
      country?: string | null,
      sport?: string | null,
      profileImgS3Key?: string | null,
      coverImgS3Key?: string | null,
      introVideoS3Key?: string | null,
      introVideoThumbS3Key?: string | null,
      introVideoUrl?: string | null,
      mission?: string | null,
      instagramUrl?: string | null,
      facebookUrl?: string | null,
      youtubeUrl?: string | null,
      goals?:  Array< {
        __typename: "AthleteGoal",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        videoUrl?: string | null,
        link?: string | null,
      } | null > | null,
      achievements?:  Array< {
        __typename: "AthleteAchievement",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        link?: string | null,
        videoUrl?: string | null,
        date?: string | null,
      } | null > | null,
      fundingGoals?:  Array< {
        __typename: "AthleteFundingGoal",
        id: string,
        amount: number,
        currency?: string | null,
        description: string,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "AthleteSponsor",
        id: string,
        imgS3Key: string,
      } | null > | null,
      emailValidated?: boolean | null,
      validationEmailSentAt?: string | null,
      potentialAthlete?: boolean | null,
      currency?: string | null,
      themeId?: string | null,
      role: string,
      nickname?: string | null,
      stripePayoutsEnabled?: boolean | null,
      payoutCurrency?: string | null,
      stats?:  {
        __typename: "UserStats",
        numUniqueViews?: number | null,
        numScouts?: number | null,
        paidByScouts?: number | null,
        numPosts?: number | null,
      } | null,
      features?:  {
        __typename: "UserFeatures",
        posts?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      ivsChannelArn?: string | null,
      createdAt: string,
      updatedAt: string,
      owner: string,
      email: string,
      stripeActionRequired?: boolean | null,
      stripeAccountId?: string | null,
      social?:  {
        __typename: "UserSocial",
        fbUserId?: string | null,
        fbAccessToken?: string | null,
        fbTargetPageId?: string | null,
        twitterRequestToken?: string | null,
        twitterRequestSecret?: string | null,
        twitterAccessToken?: string | null,
        twitterAccessSecret?: string | null,
        instagramUserId?: string | null,
      } | null,
      firebaseTokens?: Array< string | null > | null,
    } | null,
    tier?:  {
      __typename: "Tier",
      id: string,
      userId: string,
      name: string,
      currency: string,
      unitAmount: number,
      discountType?: DiscountType | null,
      discount?: number | null,
      discountMonths?: number | null,
      couponId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type CreateAthleteSubscriptionMutationVariables = {
  input: CreateAthleteSubscriptionInput,
  condition?: ModelAthleteSubscriptionConditionInput | null,
};

export type CreateAthleteSubscriptionMutation = {
  createAthleteSubscription?:  {
    __typename: "AthleteSubscription",
    subscriberId: string,
    athleteId: string,
    tierId?: string | null,
    currency: string,
    unitAmount: number,
    paidToAthlete: number,
    status?: SubscriptionStatus | null,
    cancelAtPeriodEnd?: boolean | null,
    currentPeriodEnd?: number | null,
    expiresAt: string,
    discountType?: DiscountType | null,
    discount?: number | null,
    discountRemainingMonths?: number | null,
    couponId?: string | null,
    taxRateId?: string | null,
    updatedAt: string,
    createdAt: string,
    stripeSubscriptionId?: string | null,
    stripePriceId?: string | null,
    subscriber?:  {
      __typename: "User",
      id: string,
      name: string,
      givenName: string,
      familyName: string,
      gender?: string | null,
      birthdate?: string | null,
      country?: string | null,
      sport?: string | null,
      profileImgS3Key?: string | null,
      coverImgS3Key?: string | null,
      introVideoS3Key?: string | null,
      introVideoThumbS3Key?: string | null,
      introVideoUrl?: string | null,
      mission?: string | null,
      instagramUrl?: string | null,
      facebookUrl?: string | null,
      youtubeUrl?: string | null,
      goals?:  Array< {
        __typename: "AthleteGoal",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        videoUrl?: string | null,
        link?: string | null,
      } | null > | null,
      achievements?:  Array< {
        __typename: "AthleteAchievement",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        link?: string | null,
        videoUrl?: string | null,
        date?: string | null,
      } | null > | null,
      fundingGoals?:  Array< {
        __typename: "AthleteFundingGoal",
        id: string,
        amount: number,
        currency?: string | null,
        description: string,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "AthleteSponsor",
        id: string,
        imgS3Key: string,
      } | null > | null,
      emailValidated?: boolean | null,
      validationEmailSentAt?: string | null,
      potentialAthlete?: boolean | null,
      currency?: string | null,
      themeId?: string | null,
      role: string,
      nickname?: string | null,
      stripePayoutsEnabled?: boolean | null,
      payoutCurrency?: string | null,
      stats?:  {
        __typename: "UserStats",
        numUniqueViews?: number | null,
        numScouts?: number | null,
        paidByScouts?: number | null,
        numPosts?: number | null,
      } | null,
      features?:  {
        __typename: "UserFeatures",
        posts?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      ivsChannelArn?: string | null,
      createdAt: string,
      updatedAt: string,
      owner: string,
      email: string,
      stripeActionRequired?: boolean | null,
      stripeAccountId?: string | null,
      social?:  {
        __typename: "UserSocial",
        fbUserId?: string | null,
        fbAccessToken?: string | null,
        fbTargetPageId?: string | null,
        twitterRequestToken?: string | null,
        twitterRequestSecret?: string | null,
        twitterAccessToken?: string | null,
        twitterAccessSecret?: string | null,
        instagramUserId?: string | null,
      } | null,
      firebaseTokens?: Array< string | null > | null,
    } | null,
    athlete?:  {
      __typename: "User",
      id: string,
      name: string,
      givenName: string,
      familyName: string,
      gender?: string | null,
      birthdate?: string | null,
      country?: string | null,
      sport?: string | null,
      profileImgS3Key?: string | null,
      coverImgS3Key?: string | null,
      introVideoS3Key?: string | null,
      introVideoThumbS3Key?: string | null,
      introVideoUrl?: string | null,
      mission?: string | null,
      instagramUrl?: string | null,
      facebookUrl?: string | null,
      youtubeUrl?: string | null,
      goals?:  Array< {
        __typename: "AthleteGoal",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        videoUrl?: string | null,
        link?: string | null,
      } | null > | null,
      achievements?:  Array< {
        __typename: "AthleteAchievement",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        link?: string | null,
        videoUrl?: string | null,
        date?: string | null,
      } | null > | null,
      fundingGoals?:  Array< {
        __typename: "AthleteFundingGoal",
        id: string,
        amount: number,
        currency?: string | null,
        description: string,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "AthleteSponsor",
        id: string,
        imgS3Key: string,
      } | null > | null,
      emailValidated?: boolean | null,
      validationEmailSentAt?: string | null,
      potentialAthlete?: boolean | null,
      currency?: string | null,
      themeId?: string | null,
      role: string,
      nickname?: string | null,
      stripePayoutsEnabled?: boolean | null,
      payoutCurrency?: string | null,
      stats?:  {
        __typename: "UserStats",
        numUniqueViews?: number | null,
        numScouts?: number | null,
        paidByScouts?: number | null,
        numPosts?: number | null,
      } | null,
      features?:  {
        __typename: "UserFeatures",
        posts?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      ivsChannelArn?: string | null,
      createdAt: string,
      updatedAt: string,
      owner: string,
      email: string,
      stripeActionRequired?: boolean | null,
      stripeAccountId?: string | null,
      social?:  {
        __typename: "UserSocial",
        fbUserId?: string | null,
        fbAccessToken?: string | null,
        fbTargetPageId?: string | null,
        twitterRequestToken?: string | null,
        twitterRequestSecret?: string | null,
        twitterAccessToken?: string | null,
        twitterAccessSecret?: string | null,
        instagramUserId?: string | null,
      } | null,
      firebaseTokens?: Array< string | null > | null,
    } | null,
    tier?:  {
      __typename: "Tier",
      id: string,
      userId: string,
      name: string,
      currency: string,
      unitAmount: number,
      discountType?: DiscountType | null,
      discount?: number | null,
      discountMonths?: number | null,
      couponId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type UpdateAthleteSubscriptionMutationVariables = {
  input: UpdateAthleteSubscriptionInput,
  condition?: ModelAthleteSubscriptionConditionInput | null,
};

export type UpdateAthleteSubscriptionMutation = {
  updateAthleteSubscription?:  {
    __typename: "AthleteSubscription",
    subscriberId: string,
    athleteId: string,
    tierId?: string | null,
    currency: string,
    unitAmount: number,
    paidToAthlete: number,
    status?: SubscriptionStatus | null,
    cancelAtPeriodEnd?: boolean | null,
    currentPeriodEnd?: number | null,
    expiresAt: string,
    discountType?: DiscountType | null,
    discount?: number | null,
    discountRemainingMonths?: number | null,
    couponId?: string | null,
    taxRateId?: string | null,
    updatedAt: string,
    createdAt: string,
    stripeSubscriptionId?: string | null,
    stripePriceId?: string | null,
    subscriber?:  {
      __typename: "User",
      id: string,
      name: string,
      givenName: string,
      familyName: string,
      gender?: string | null,
      birthdate?: string | null,
      country?: string | null,
      sport?: string | null,
      profileImgS3Key?: string | null,
      coverImgS3Key?: string | null,
      introVideoS3Key?: string | null,
      introVideoThumbS3Key?: string | null,
      introVideoUrl?: string | null,
      mission?: string | null,
      instagramUrl?: string | null,
      facebookUrl?: string | null,
      youtubeUrl?: string | null,
      goals?:  Array< {
        __typename: "AthleteGoal",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        videoUrl?: string | null,
        link?: string | null,
      } | null > | null,
      achievements?:  Array< {
        __typename: "AthleteAchievement",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        link?: string | null,
        videoUrl?: string | null,
        date?: string | null,
      } | null > | null,
      fundingGoals?:  Array< {
        __typename: "AthleteFundingGoal",
        id: string,
        amount: number,
        currency?: string | null,
        description: string,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "AthleteSponsor",
        id: string,
        imgS3Key: string,
      } | null > | null,
      emailValidated?: boolean | null,
      validationEmailSentAt?: string | null,
      potentialAthlete?: boolean | null,
      currency?: string | null,
      themeId?: string | null,
      role: string,
      nickname?: string | null,
      stripePayoutsEnabled?: boolean | null,
      payoutCurrency?: string | null,
      stats?:  {
        __typename: "UserStats",
        numUniqueViews?: number | null,
        numScouts?: number | null,
        paidByScouts?: number | null,
        numPosts?: number | null,
      } | null,
      features?:  {
        __typename: "UserFeatures",
        posts?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      ivsChannelArn?: string | null,
      createdAt: string,
      updatedAt: string,
      owner: string,
      email: string,
      stripeActionRequired?: boolean | null,
      stripeAccountId?: string | null,
      social?:  {
        __typename: "UserSocial",
        fbUserId?: string | null,
        fbAccessToken?: string | null,
        fbTargetPageId?: string | null,
        twitterRequestToken?: string | null,
        twitterRequestSecret?: string | null,
        twitterAccessToken?: string | null,
        twitterAccessSecret?: string | null,
        instagramUserId?: string | null,
      } | null,
      firebaseTokens?: Array< string | null > | null,
    } | null,
    athlete?:  {
      __typename: "User",
      id: string,
      name: string,
      givenName: string,
      familyName: string,
      gender?: string | null,
      birthdate?: string | null,
      country?: string | null,
      sport?: string | null,
      profileImgS3Key?: string | null,
      coverImgS3Key?: string | null,
      introVideoS3Key?: string | null,
      introVideoThumbS3Key?: string | null,
      introVideoUrl?: string | null,
      mission?: string | null,
      instagramUrl?: string | null,
      facebookUrl?: string | null,
      youtubeUrl?: string | null,
      goals?:  Array< {
        __typename: "AthleteGoal",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        videoUrl?: string | null,
        link?: string | null,
      } | null > | null,
      achievements?:  Array< {
        __typename: "AthleteAchievement",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        link?: string | null,
        videoUrl?: string | null,
        date?: string | null,
      } | null > | null,
      fundingGoals?:  Array< {
        __typename: "AthleteFundingGoal",
        id: string,
        amount: number,
        currency?: string | null,
        description: string,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "AthleteSponsor",
        id: string,
        imgS3Key: string,
      } | null > | null,
      emailValidated?: boolean | null,
      validationEmailSentAt?: string | null,
      potentialAthlete?: boolean | null,
      currency?: string | null,
      themeId?: string | null,
      role: string,
      nickname?: string | null,
      stripePayoutsEnabled?: boolean | null,
      payoutCurrency?: string | null,
      stats?:  {
        __typename: "UserStats",
        numUniqueViews?: number | null,
        numScouts?: number | null,
        paidByScouts?: number | null,
        numPosts?: number | null,
      } | null,
      features?:  {
        __typename: "UserFeatures",
        posts?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      ivsChannelArn?: string | null,
      createdAt: string,
      updatedAt: string,
      owner: string,
      email: string,
      stripeActionRequired?: boolean | null,
      stripeAccountId?: string | null,
      social?:  {
        __typename: "UserSocial",
        fbUserId?: string | null,
        fbAccessToken?: string | null,
        fbTargetPageId?: string | null,
        twitterRequestToken?: string | null,
        twitterRequestSecret?: string | null,
        twitterAccessToken?: string | null,
        twitterAccessSecret?: string | null,
        instagramUserId?: string | null,
      } | null,
      firebaseTokens?: Array< string | null > | null,
    } | null,
    tier?:  {
      __typename: "Tier",
      id: string,
      userId: string,
      name: string,
      currency: string,
      unitAmount: number,
      discountType?: DiscountType | null,
      discount?: number | null,
      discountMonths?: number | null,
      couponId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type DeleteAthleteSubscriptionMutationVariables = {
  input: DeleteAthleteSubscriptionInput,
  condition?: ModelAthleteSubscriptionConditionInput | null,
};

export type DeleteAthleteSubscriptionMutation = {
  deleteAthleteSubscription?:  {
    __typename: "AthleteSubscription",
    subscriberId: string,
    athleteId: string,
    tierId?: string | null,
    currency: string,
    unitAmount: number,
    paidToAthlete: number,
    status?: SubscriptionStatus | null,
    cancelAtPeriodEnd?: boolean | null,
    currentPeriodEnd?: number | null,
    expiresAt: string,
    discountType?: DiscountType | null,
    discount?: number | null,
    discountRemainingMonths?: number | null,
    couponId?: string | null,
    taxRateId?: string | null,
    updatedAt: string,
    createdAt: string,
    stripeSubscriptionId?: string | null,
    stripePriceId?: string | null,
    subscriber?:  {
      __typename: "User",
      id: string,
      name: string,
      givenName: string,
      familyName: string,
      gender?: string | null,
      birthdate?: string | null,
      country?: string | null,
      sport?: string | null,
      profileImgS3Key?: string | null,
      coverImgS3Key?: string | null,
      introVideoS3Key?: string | null,
      introVideoThumbS3Key?: string | null,
      introVideoUrl?: string | null,
      mission?: string | null,
      instagramUrl?: string | null,
      facebookUrl?: string | null,
      youtubeUrl?: string | null,
      goals?:  Array< {
        __typename: "AthleteGoal",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        videoUrl?: string | null,
        link?: string | null,
      } | null > | null,
      achievements?:  Array< {
        __typename: "AthleteAchievement",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        link?: string | null,
        videoUrl?: string | null,
        date?: string | null,
      } | null > | null,
      fundingGoals?:  Array< {
        __typename: "AthleteFundingGoal",
        id: string,
        amount: number,
        currency?: string | null,
        description: string,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "AthleteSponsor",
        id: string,
        imgS3Key: string,
      } | null > | null,
      emailValidated?: boolean | null,
      validationEmailSentAt?: string | null,
      potentialAthlete?: boolean | null,
      currency?: string | null,
      themeId?: string | null,
      role: string,
      nickname?: string | null,
      stripePayoutsEnabled?: boolean | null,
      payoutCurrency?: string | null,
      stats?:  {
        __typename: "UserStats",
        numUniqueViews?: number | null,
        numScouts?: number | null,
        paidByScouts?: number | null,
        numPosts?: number | null,
      } | null,
      features?:  {
        __typename: "UserFeatures",
        posts?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      ivsChannelArn?: string | null,
      createdAt: string,
      updatedAt: string,
      owner: string,
      email: string,
      stripeActionRequired?: boolean | null,
      stripeAccountId?: string | null,
      social?:  {
        __typename: "UserSocial",
        fbUserId?: string | null,
        fbAccessToken?: string | null,
        fbTargetPageId?: string | null,
        twitterRequestToken?: string | null,
        twitterRequestSecret?: string | null,
        twitterAccessToken?: string | null,
        twitterAccessSecret?: string | null,
        instagramUserId?: string | null,
      } | null,
      firebaseTokens?: Array< string | null > | null,
    } | null,
    athlete?:  {
      __typename: "User",
      id: string,
      name: string,
      givenName: string,
      familyName: string,
      gender?: string | null,
      birthdate?: string | null,
      country?: string | null,
      sport?: string | null,
      profileImgS3Key?: string | null,
      coverImgS3Key?: string | null,
      introVideoS3Key?: string | null,
      introVideoThumbS3Key?: string | null,
      introVideoUrl?: string | null,
      mission?: string | null,
      instagramUrl?: string | null,
      facebookUrl?: string | null,
      youtubeUrl?: string | null,
      goals?:  Array< {
        __typename: "AthleteGoal",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        videoUrl?: string | null,
        link?: string | null,
      } | null > | null,
      achievements?:  Array< {
        __typename: "AthleteAchievement",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        link?: string | null,
        videoUrl?: string | null,
        date?: string | null,
      } | null > | null,
      fundingGoals?:  Array< {
        __typename: "AthleteFundingGoal",
        id: string,
        amount: number,
        currency?: string | null,
        description: string,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "AthleteSponsor",
        id: string,
        imgS3Key: string,
      } | null > | null,
      emailValidated?: boolean | null,
      validationEmailSentAt?: string | null,
      potentialAthlete?: boolean | null,
      currency?: string | null,
      themeId?: string | null,
      role: string,
      nickname?: string | null,
      stripePayoutsEnabled?: boolean | null,
      payoutCurrency?: string | null,
      stats?:  {
        __typename: "UserStats",
        numUniqueViews?: number | null,
        numScouts?: number | null,
        paidByScouts?: number | null,
        numPosts?: number | null,
      } | null,
      features?:  {
        __typename: "UserFeatures",
        posts?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      ivsChannelArn?: string | null,
      createdAt: string,
      updatedAt: string,
      owner: string,
      email: string,
      stripeActionRequired?: boolean | null,
      stripeAccountId?: string | null,
      social?:  {
        __typename: "UserSocial",
        fbUserId?: string | null,
        fbAccessToken?: string | null,
        fbTargetPageId?: string | null,
        twitterRequestToken?: string | null,
        twitterRequestSecret?: string | null,
        twitterAccessToken?: string | null,
        twitterAccessSecret?: string | null,
        instagramUserId?: string | null,
      } | null,
      firebaseTokens?: Array< string | null > | null,
    } | null,
    tier?:  {
      __typename: "Tier",
      id: string,
      userId: string,
      name: string,
      currency: string,
      unitAmount: number,
      discountType?: DiscountType | null,
      discount?: number | null,
      discountMonths?: number | null,
      couponId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type CreateFlashRequestMutationVariables = {
  input: CreateFlashRequestInput,
  condition?: ModelFlashRequestConditionInput | null,
};

export type CreateFlashRequestMutation = {
  createFlashRequest?:  {
    __typename: "FlashRequest",
    userId: string,
    request: FlashRequestType,
    targetId: string,
    payload?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateFlashRequestMutationVariables = {
  input: UpdateFlashRequestInput,
  condition?: ModelFlashRequestConditionInput | null,
};

export type UpdateFlashRequestMutation = {
  updateFlashRequest?:  {
    __typename: "FlashRequest",
    userId: string,
    request: FlashRequestType,
    targetId: string,
    payload?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteFlashRequestMutationVariables = {
  input: DeleteFlashRequestInput,
  condition?: ModelFlashRequestConditionInput | null,
};

export type DeleteFlashRequestMutation = {
  deleteFlashRequest?:  {
    __typename: "FlashRequest",
    userId: string,
    request: FlashRequestType,
    targetId: string,
    payload?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateLikeMutationVariables = {
  input: CreateLikeInput,
  condition?: ModelLikeConditionInput | null,
};

export type CreateLikeMutation = {
  createLike?:  {
    __typename: "Like",
    userId: string,
    targetId: string,
    targetType: LikeTargetType,
    createdAt: string,
    updatedAt: string,
    user?:  {
      __typename: "User",
      id: string,
      name: string,
      givenName: string,
      familyName: string,
      gender?: string | null,
      birthdate?: string | null,
      country?: string | null,
      sport?: string | null,
      profileImgS3Key?: string | null,
      coverImgS3Key?: string | null,
      introVideoS3Key?: string | null,
      introVideoThumbS3Key?: string | null,
      introVideoUrl?: string | null,
      mission?: string | null,
      instagramUrl?: string | null,
      facebookUrl?: string | null,
      youtubeUrl?: string | null,
      goals?:  Array< {
        __typename: "AthleteGoal",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        videoUrl?: string | null,
        link?: string | null,
      } | null > | null,
      achievements?:  Array< {
        __typename: "AthleteAchievement",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        link?: string | null,
        videoUrl?: string | null,
        date?: string | null,
      } | null > | null,
      fundingGoals?:  Array< {
        __typename: "AthleteFundingGoal",
        id: string,
        amount: number,
        currency?: string | null,
        description: string,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "AthleteSponsor",
        id: string,
        imgS3Key: string,
      } | null > | null,
      emailValidated?: boolean | null,
      validationEmailSentAt?: string | null,
      potentialAthlete?: boolean | null,
      currency?: string | null,
      themeId?: string | null,
      role: string,
      nickname?: string | null,
      stripePayoutsEnabled?: boolean | null,
      payoutCurrency?: string | null,
      stats?:  {
        __typename: "UserStats",
        numUniqueViews?: number | null,
        numScouts?: number | null,
        paidByScouts?: number | null,
        numPosts?: number | null,
      } | null,
      features?:  {
        __typename: "UserFeatures",
        posts?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      ivsChannelArn?: string | null,
      createdAt: string,
      updatedAt: string,
      owner: string,
      email: string,
      stripeActionRequired?: boolean | null,
      stripeAccountId?: string | null,
      social?:  {
        __typename: "UserSocial",
        fbUserId?: string | null,
        fbAccessToken?: string | null,
        fbTargetPageId?: string | null,
        twitterRequestToken?: string | null,
        twitterRequestSecret?: string | null,
        twitterAccessToken?: string | null,
        twitterAccessSecret?: string | null,
        instagramUserId?: string | null,
      } | null,
      firebaseTokens?: Array< string | null > | null,
    } | null,
  } | null,
};

export type UpdateLikeMutationVariables = {
  input: UpdateLikeInput,
  condition?: ModelLikeConditionInput | null,
};

export type UpdateLikeMutation = {
  updateLike?:  {
    __typename: "Like",
    userId: string,
    targetId: string,
    targetType: LikeTargetType,
    createdAt: string,
    updatedAt: string,
    user?:  {
      __typename: "User",
      id: string,
      name: string,
      givenName: string,
      familyName: string,
      gender?: string | null,
      birthdate?: string | null,
      country?: string | null,
      sport?: string | null,
      profileImgS3Key?: string | null,
      coverImgS3Key?: string | null,
      introVideoS3Key?: string | null,
      introVideoThumbS3Key?: string | null,
      introVideoUrl?: string | null,
      mission?: string | null,
      instagramUrl?: string | null,
      facebookUrl?: string | null,
      youtubeUrl?: string | null,
      goals?:  Array< {
        __typename: "AthleteGoal",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        videoUrl?: string | null,
        link?: string | null,
      } | null > | null,
      achievements?:  Array< {
        __typename: "AthleteAchievement",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        link?: string | null,
        videoUrl?: string | null,
        date?: string | null,
      } | null > | null,
      fundingGoals?:  Array< {
        __typename: "AthleteFundingGoal",
        id: string,
        amount: number,
        currency?: string | null,
        description: string,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "AthleteSponsor",
        id: string,
        imgS3Key: string,
      } | null > | null,
      emailValidated?: boolean | null,
      validationEmailSentAt?: string | null,
      potentialAthlete?: boolean | null,
      currency?: string | null,
      themeId?: string | null,
      role: string,
      nickname?: string | null,
      stripePayoutsEnabled?: boolean | null,
      payoutCurrency?: string | null,
      stats?:  {
        __typename: "UserStats",
        numUniqueViews?: number | null,
        numScouts?: number | null,
        paidByScouts?: number | null,
        numPosts?: number | null,
      } | null,
      features?:  {
        __typename: "UserFeatures",
        posts?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      ivsChannelArn?: string | null,
      createdAt: string,
      updatedAt: string,
      owner: string,
      email: string,
      stripeActionRequired?: boolean | null,
      stripeAccountId?: string | null,
      social?:  {
        __typename: "UserSocial",
        fbUserId?: string | null,
        fbAccessToken?: string | null,
        fbTargetPageId?: string | null,
        twitterRequestToken?: string | null,
        twitterRequestSecret?: string | null,
        twitterAccessToken?: string | null,
        twitterAccessSecret?: string | null,
        instagramUserId?: string | null,
      } | null,
      firebaseTokens?: Array< string | null > | null,
    } | null,
  } | null,
};

export type DeleteLikeMutationVariables = {
  input: DeleteLikeInput,
  condition?: ModelLikeConditionInput | null,
};

export type DeleteLikeMutation = {
  deleteLike?:  {
    __typename: "Like",
    userId: string,
    targetId: string,
    targetType: LikeTargetType,
    createdAt: string,
    updatedAt: string,
    user?:  {
      __typename: "User",
      id: string,
      name: string,
      givenName: string,
      familyName: string,
      gender?: string | null,
      birthdate?: string | null,
      country?: string | null,
      sport?: string | null,
      profileImgS3Key?: string | null,
      coverImgS3Key?: string | null,
      introVideoS3Key?: string | null,
      introVideoThumbS3Key?: string | null,
      introVideoUrl?: string | null,
      mission?: string | null,
      instagramUrl?: string | null,
      facebookUrl?: string | null,
      youtubeUrl?: string | null,
      goals?:  Array< {
        __typename: "AthleteGoal",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        videoUrl?: string | null,
        link?: string | null,
      } | null > | null,
      achievements?:  Array< {
        __typename: "AthleteAchievement",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        link?: string | null,
        videoUrl?: string | null,
        date?: string | null,
      } | null > | null,
      fundingGoals?:  Array< {
        __typename: "AthleteFundingGoal",
        id: string,
        amount: number,
        currency?: string | null,
        description: string,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "AthleteSponsor",
        id: string,
        imgS3Key: string,
      } | null > | null,
      emailValidated?: boolean | null,
      validationEmailSentAt?: string | null,
      potentialAthlete?: boolean | null,
      currency?: string | null,
      themeId?: string | null,
      role: string,
      nickname?: string | null,
      stripePayoutsEnabled?: boolean | null,
      payoutCurrency?: string | null,
      stats?:  {
        __typename: "UserStats",
        numUniqueViews?: number | null,
        numScouts?: number | null,
        paidByScouts?: number | null,
        numPosts?: number | null,
      } | null,
      features?:  {
        __typename: "UserFeatures",
        posts?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      ivsChannelArn?: string | null,
      createdAt: string,
      updatedAt: string,
      owner: string,
      email: string,
      stripeActionRequired?: boolean | null,
      stripeAccountId?: string | null,
      social?:  {
        __typename: "UserSocial",
        fbUserId?: string | null,
        fbAccessToken?: string | null,
        fbTargetPageId?: string | null,
        twitterRequestToken?: string | null,
        twitterRequestSecret?: string | null,
        twitterAccessToken?: string | null,
        twitterAccessSecret?: string | null,
        instagramUserId?: string | null,
      } | null,
      firebaseTokens?: Array< string | null > | null,
    } | null,
  } | null,
};

export type CreateMigrationsMutationVariables = {
  input: CreateMigrationsInput,
  condition?: ModelMigrationsConditionInput | null,
};

export type CreateMigrationsMutation = {
  createMigrations?:  {
    __typename: "Migrations",
    name: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateMigrationsMutationVariables = {
  input: UpdateMigrationsInput,
  condition?: ModelMigrationsConditionInput | null,
};

export type UpdateMigrationsMutation = {
  updateMigrations?:  {
    __typename: "Migrations",
    name: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteMigrationsMutationVariables = {
  input: DeleteMigrationsInput,
  condition?: ModelMigrationsConditionInput | null,
};

export type DeleteMigrationsMutation = {
  deleteMigrations?:  {
    __typename: "Migrations",
    name: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type IsUserCustomerQuery = {
  isUserCustomer?: boolean | null,
};

export type ListPaymentMethodsQuery = {
  listPaymentMethods?:  Array< {
    __typename: "PaymentMethod",
    id: string,
    type: string,
    billing_details?:  {
      __typename: "BillingDetails",
      address?:  {
        __typename: "BillingAddress",
        city?: string | null,
        country?: string | null,
        line1?: string | null,
        line2?: string | null,
        postal_code?: string | null,
        state?: string | null,
      } | null,
      email?: string | null,
      name?: string | null,
      phone?: string | null,
    } | null,
    card?:  {
      __typename: "CreditCard",
      brand?: string | null,
      country?: string | null,
      exp_month?: number | null,
      exp_year?: number | null,
      last4?: string | null,
    } | null,
    created?: number | null,
    customer?: string | null,
    is_default?: boolean | null,
  } | null > | null,
};

export type ListInvoicesQuery = {
  listInvoices?:  Array< {
    __typename: "Invoice",
    id: string,
    number?: string | null,
    paid: boolean,
    period_start: number,
    period_end: number,
    total: number,
    currency: string,
    invoice_pdf?: string | null,
    created: number,
  } | null > | null,
};

export type GetBalanceQuery = {
  getBalance?:  {
    __typename: "Balance",
    available?:  Array< {
      __typename: "BalanceAvailable",
      amount: number,
      currency: string,
    } | null > | null,
    pending?:  Array< {
      __typename: "BalancePending",
      amount: number,
      currency: string,
    } | null > | null,
  } | null,
};

export type GetBalanceTransactionsQueryVariables = {
  type: string,
  startingAfter?: string | null,
  limit?: number | null,
};

export type GetBalanceTransactionsQuery = {
  getBalanceTransactions?:  {
    __typename: "BalanceTransactionList",
    data?:  Array< {
      __typename: "BalanceTransaction",
      id?: string | null,
      amount?: number | null,
      available_on?: number | null,
      created?: number | null,
      currency?: string | null,
      description?: string | null,
      exchange_rate?: number | null,
      fee?: number | null,
      net?: number | null,
      reporting_category?: string | null,
      status?: string | null,
      type?: string | null,
    } | null > | null,
    has_more?: boolean | null,
  } | null,
};

export type GetPayoutsQuery = {
  getPayouts?:  {
    __typename: "PayoutList",
    data?:  Array< {
      __typename: "Payout",
      id?: string | null,
      amount?: number | null,
      arrival_date?: number | null,
      automatic?: boolean | null,
      created?: number | null,
      currency?: string | null,
      description?: string | null,
      source_type?: string | null,
      status?: string | null,
      type?: string | null,
    } | null > | null,
    has_more?: boolean | null,
  } | null,
};

export type GetPaymentsAccountQuery = {
  getPaymentsAccount?:  {
    __typename: "PaymentsAccount",
    id: string,
    business_profile?:  {
      __typename: "AccountBusinessProfile",
      name?: string | null,
      product_description?: string | null,
      support_address?:  {
        __typename: "StripeAddress",
        city?: string | null,
        country?: string | null,
        line1?: string | null,
        line2?: string | null,
        postal_code?: string | null,
        state?: string | null,
      } | null,
      support_email?: string | null,
      support_phone?: string | null,
      support_url?: string | null,
      url?: string | null,
    } | null,
    charges_enabled: boolean,
    country: string,
    created?: number | null,
    default_currency: string,
    details_submitted: boolean,
    email?: string | null,
    payouts_enabled: boolean,
    requirements?:  {
      __typename: "AccountRequirements",
      current_deadline?: number | null,
      currently_due?: Array< string | null > | null,
      disabled_reason?: string | null,
      errors?:  Array< {
        __typename: "AccountRequirementsError",
        code: string,
        reason?: string | null,
        requirement?: string | null,
      } | null > | null,
      eventually_due?: Array< string | null > | null,
      past_due?: Array< string | null > | null,
      pending_verification?: Array< string | null > | null,
    } | null,
  } | null,
};

export type GetPaymentsAccountLinkQueryVariables = {
  refreshUrl: string,
  returnUrl: string,
};

export type GetPaymentsAccountLinkQuery = {
  getPaymentsAccountLink?: string | null,
};

export type GetTaxRateByCountryQueryVariables = {
  country: string,
};

export type GetTaxRateByCountryQuery = {
  getTaxRateByCountry?:  {
    __typename: "TaxRate",
    id: string,
    percentage: number,
    displayName: string,
    country: string,
  } | null,
};

export type GetFullPostQueryVariables = {
  postId: string,
};

export type GetFullPostQuery = {
  getFullPost?:  {
    __typename: "FullPost",
    id: string,
    authorId: string,
    name: string,
    description?: string | null,
    needsSubscription?: boolean | null,
    type: PostType,
    tierId: string,
    tier?:  {
      __typename: "Tier",
      id: string,
      userId: string,
      name: string,
      currency: string,
      unitAmount: number,
      discountType?: DiscountType | null,
      discount?: number | null,
      discountMonths?: number | null,
      couponId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    thumbS3Key?: string | null,
    comments?: number | null,
    vodId?: string | null,
    vodToken?: string | null,
    vodStatus?: string | null,
    vodMetadata?:  {
      __typename: "PostVodMetadata",
      durationInMs?: number | null,
      orgWidth?: number | null,
      orgHeight?: number | null,
      hasPreview?: boolean | null,
    } | null,
    bcastMetadata?:  {
      __typename: "PostBcastMetadata",
      previewImageS3Key?: string | null,
      time: string,
      duration?: string | null,
      guests:  Array< {
        __typename: "BcastGuest",
        id: string,
        name: string,
      } >,
      allowQuestions: boolean,
      status: BcastStatus,
      bcastUrl?: string | null,
      recordingS3Key?: string | null,
    } | null,
    likes?: number | null,
    liked?: boolean | null,
    reviewStatus?: PostReviewStatus | null,
    feedId?: string | null,
    createdAt: string,
  } | null,
};

export type GetCurrenciesConfigQuery = {
  getCurrenciesConfig?:  {
    __typename: "CurrenciesConfig",
    defaultCurrency: string,
    clientCurrency: string,
    currencies:  Array< {
      __typename: "Currency",
      code: string,
      symbol: string,
      round: number,
    } | null >,
    conversionsTable: Array< Array< number | null > | null >,
  } | null,
};

export type GetViewerCurrencyQuery = {
  getViewerCurrency?: string | null,
};

export type ListUsersByStripeAccountIdQueryVariables = {
  stripeAccountId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersByStripeAccountIdQuery = {
  listUsersByStripeAccountId?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      name: string,
      givenName: string,
      familyName: string,
      gender?: string | null,
      birthdate?: string | null,
      country?: string | null,
      sport?: string | null,
      profileImgS3Key?: string | null,
      coverImgS3Key?: string | null,
      introVideoS3Key?: string | null,
      introVideoThumbS3Key?: string | null,
      introVideoUrl?: string | null,
      mission?: string | null,
      instagramUrl?: string | null,
      facebookUrl?: string | null,
      youtubeUrl?: string | null,
      goals?:  Array< {
        __typename: "AthleteGoal",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        videoUrl?: string | null,
        link?: string | null,
      } | null > | null,
      achievements?:  Array< {
        __typename: "AthleteAchievement",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        link?: string | null,
        videoUrl?: string | null,
        date?: string | null,
      } | null > | null,
      fundingGoals?:  Array< {
        __typename: "AthleteFundingGoal",
        id: string,
        amount: number,
        currency?: string | null,
        description: string,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "AthleteSponsor",
        id: string,
        imgS3Key: string,
      } | null > | null,
      emailValidated?: boolean | null,
      validationEmailSentAt?: string | null,
      potentialAthlete?: boolean | null,
      currency?: string | null,
      themeId?: string | null,
      role: string,
      nickname?: string | null,
      stripePayoutsEnabled?: boolean | null,
      payoutCurrency?: string | null,
      stats?:  {
        __typename: "UserStats",
        numUniqueViews?: number | null,
        numScouts?: number | null,
        paidByScouts?: number | null,
        numPosts?: number | null,
      } | null,
      features?:  {
        __typename: "UserFeatures",
        posts?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      ivsChannelArn?: string | null,
      createdAt: string,
      updatedAt: string,
      owner: string,
      email: string,
      stripeActionRequired?: boolean | null,
      stripeAccountId?: string | null,
      social?:  {
        __typename: "UserSocial",
        fbUserId?: string | null,
        fbAccessToken?: string | null,
        fbTargetPageId?: string | null,
        twitterRequestToken?: string | null,
        twitterRequestSecret?: string | null,
        twitterAccessToken?: string | null,
        twitterAccessSecret?: string | null,
        instagramUserId?: string | null,
      } | null,
      firebaseTokens?: Array< string | null > | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListUsersByRoleQueryVariables = {
  role?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersByRoleQuery = {
  listUsersByRole?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      name: string,
      givenName: string,
      familyName: string,
      gender?: string | null,
      birthdate?: string | null,
      country?: string | null,
      sport?: string | null,
      profileImgS3Key?: string | null,
      coverImgS3Key?: string | null,
      introVideoS3Key?: string | null,
      introVideoThumbS3Key?: string | null,
      introVideoUrl?: string | null,
      mission?: string | null,
      instagramUrl?: string | null,
      facebookUrl?: string | null,
      youtubeUrl?: string | null,
      goals?:  Array< {
        __typename: "AthleteGoal",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        videoUrl?: string | null,
        link?: string | null,
      } | null > | null,
      achievements?:  Array< {
        __typename: "AthleteAchievement",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        link?: string | null,
        videoUrl?: string | null,
        date?: string | null,
      } | null > | null,
      fundingGoals?:  Array< {
        __typename: "AthleteFundingGoal",
        id: string,
        amount: number,
        currency?: string | null,
        description: string,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "AthleteSponsor",
        id: string,
        imgS3Key: string,
      } | null > | null,
      emailValidated?: boolean | null,
      validationEmailSentAt?: string | null,
      potentialAthlete?: boolean | null,
      currency?: string | null,
      themeId?: string | null,
      role: string,
      nickname?: string | null,
      stripePayoutsEnabled?: boolean | null,
      payoutCurrency?: string | null,
      stats?:  {
        __typename: "UserStats",
        numUniqueViews?: number | null,
        numScouts?: number | null,
        paidByScouts?: number | null,
        numPosts?: number | null,
      } | null,
      features?:  {
        __typename: "UserFeatures",
        posts?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      ivsChannelArn?: string | null,
      createdAt: string,
      updatedAt: string,
      owner: string,
      email: string,
      stripeActionRequired?: boolean | null,
      stripeAccountId?: string | null,
      social?:  {
        __typename: "UserSocial",
        fbUserId?: string | null,
        fbAccessToken?: string | null,
        fbTargetPageId?: string | null,
        twitterRequestToken?: string | null,
        twitterRequestSecret?: string | null,
        twitterAccessToken?: string | null,
        twitterAccessSecret?: string | null,
        instagramUserId?: string | null,
      } | null,
      firebaseTokens?: Array< string | null > | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListUsersByNicknameQueryVariables = {
  nickname?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersByNicknameQuery = {
  listUsersByNickname?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      name: string,
      givenName: string,
      familyName: string,
      gender?: string | null,
      birthdate?: string | null,
      country?: string | null,
      sport?: string | null,
      profileImgS3Key?: string | null,
      coverImgS3Key?: string | null,
      introVideoS3Key?: string | null,
      introVideoThumbS3Key?: string | null,
      introVideoUrl?: string | null,
      mission?: string | null,
      instagramUrl?: string | null,
      facebookUrl?: string | null,
      youtubeUrl?: string | null,
      goals?:  Array< {
        __typename: "AthleteGoal",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        videoUrl?: string | null,
        link?: string | null,
      } | null > | null,
      achievements?:  Array< {
        __typename: "AthleteAchievement",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        link?: string | null,
        videoUrl?: string | null,
        date?: string | null,
      } | null > | null,
      fundingGoals?:  Array< {
        __typename: "AthleteFundingGoal",
        id: string,
        amount: number,
        currency?: string | null,
        description: string,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "AthleteSponsor",
        id: string,
        imgS3Key: string,
      } | null > | null,
      emailValidated?: boolean | null,
      validationEmailSentAt?: string | null,
      potentialAthlete?: boolean | null,
      currency?: string | null,
      themeId?: string | null,
      role: string,
      nickname?: string | null,
      stripePayoutsEnabled?: boolean | null,
      payoutCurrency?: string | null,
      stats?:  {
        __typename: "UserStats",
        numUniqueViews?: number | null,
        numScouts?: number | null,
        paidByScouts?: number | null,
        numPosts?: number | null,
      } | null,
      features?:  {
        __typename: "UserFeatures",
        posts?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      ivsChannelArn?: string | null,
      createdAt: string,
      updatedAt: string,
      owner: string,
      email: string,
      stripeActionRequired?: boolean | null,
      stripeAccountId?: string | null,
      social?:  {
        __typename: "UserSocial",
        fbUserId?: string | null,
        fbAccessToken?: string | null,
        fbTargetPageId?: string | null,
        twitterRequestToken?: string | null,
        twitterRequestSecret?: string | null,
        twitterAccessToken?: string | null,
        twitterAccessSecret?: string | null,
        instagramUserId?: string | null,
      } | null,
      firebaseTokens?: Array< string | null > | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchUsersQueryVariables = {
  filter?: SearchableUserFilterInput | null,
  sort?: SearchableUserSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchUsersQuery = {
  searchUsers?:  {
    __typename: "SearchableUserConnection",
    items?:  Array< {
      __typename: "User",
      id: string,
      name: string,
      givenName: string,
      familyName: string,
      gender?: string | null,
      birthdate?: string | null,
      country?: string | null,
      sport?: string | null,
      profileImgS3Key?: string | null,
      coverImgS3Key?: string | null,
      introVideoS3Key?: string | null,
      introVideoThumbS3Key?: string | null,
      introVideoUrl?: string | null,
      mission?: string | null,
      instagramUrl?: string | null,
      facebookUrl?: string | null,
      youtubeUrl?: string | null,
      goals?:  Array< {
        __typename: "AthleteGoal",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        videoUrl?: string | null,
        link?: string | null,
      } | null > | null,
      achievements?:  Array< {
        __typename: "AthleteAchievement",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        link?: string | null,
        videoUrl?: string | null,
        date?: string | null,
      } | null > | null,
      fundingGoals?:  Array< {
        __typename: "AthleteFundingGoal",
        id: string,
        amount: number,
        currency?: string | null,
        description: string,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "AthleteSponsor",
        id: string,
        imgS3Key: string,
      } | null > | null,
      emailValidated?: boolean | null,
      validationEmailSentAt?: string | null,
      potentialAthlete?: boolean | null,
      currency?: string | null,
      themeId?: string | null,
      role: string,
      nickname?: string | null,
      stripePayoutsEnabled?: boolean | null,
      payoutCurrency?: string | null,
      stats?:  {
        __typename: "UserStats",
        numUniqueViews?: number | null,
        numScouts?: number | null,
        paidByScouts?: number | null,
        numPosts?: number | null,
      } | null,
      features?:  {
        __typename: "UserFeatures",
        posts?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      ivsChannelArn?: string | null,
      createdAt: string,
      updatedAt: string,
      owner: string,
      email: string,
      stripeActionRequired?: boolean | null,
      stripeAccountId?: string | null,
      social?:  {
        __typename: "UserSocial",
        fbUserId?: string | null,
        fbAccessToken?: string | null,
        fbTargetPageId?: string | null,
        twitterRequestToken?: string | null,
        twitterRequestSecret?: string | null,
        twitterAccessToken?: string | null,
        twitterAccessSecret?: string | null,
        instagramUserId?: string | null,
      } | null,
      firebaseTokens?: Array< string | null > | null,
    } | null > | null,
    nextToken?: string | null,
    total?:  {
      __typename: "SearchTotal",
      value?: number | null,
      relation?: string | null,
    } | null,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    name: string,
    givenName: string,
    familyName: string,
    gender?: string | null,
    birthdate?: string | null,
    country?: string | null,
    sport?: string | null,
    profileImgS3Key?: string | null,
    coverImgS3Key?: string | null,
    introVideoS3Key?: string | null,
    introVideoThumbS3Key?: string | null,
    introVideoUrl?: string | null,
    mission?: string | null,
    instagramUrl?: string | null,
    facebookUrl?: string | null,
    youtubeUrl?: string | null,
    goals?:  Array< {
      __typename: "AthleteGoal",
      id: string,
      name: string,
      level?: string | null,
      description?: string | null,
      videoUrl?: string | null,
      link?: string | null,
    } | null > | null,
    achievements?:  Array< {
      __typename: "AthleteAchievement",
      id: string,
      name: string,
      level?: string | null,
      description?: string | null,
      link?: string | null,
      videoUrl?: string | null,
      date?: string | null,
    } | null > | null,
    fundingGoals?:  Array< {
      __typename: "AthleteFundingGoal",
      id: string,
      amount: number,
      currency?: string | null,
      description: string,
    } | null > | null,
    sponsors?:  Array< {
      __typename: "AthleteSponsor",
      id: string,
      imgS3Key: string,
    } | null > | null,
    emailValidated?: boolean | null,
    validationEmailSentAt?: string | null,
    potentialAthlete?: boolean | null,
    currency?: string | null,
    themeId?: string | null,
    role: string,
    nickname?: string | null,
    stripePayoutsEnabled?: boolean | null,
    payoutCurrency?: string | null,
    stats?:  {
      __typename: "UserStats",
      numUniqueViews?: number | null,
      numScouts?: number | null,
      paidByScouts?: number | null,
      numPosts?: number | null,
    } | null,
    features?:  {
      __typename: "UserFeatures",
      posts?: boolean | null,
    } | null,
    stripeCustomerId?: string | null,
    ivsChannelArn?: string | null,
    createdAt: string,
    updatedAt: string,
    owner: string,
    email: string,
    stripeActionRequired?: boolean | null,
    stripeAccountId?: string | null,
    social?:  {
      __typename: "UserSocial",
      fbUserId?: string | null,
      fbAccessToken?: string | null,
      fbTargetPageId?: string | null,
      twitterRequestToken?: string | null,
      twitterRequestSecret?: string | null,
      twitterAccessToken?: string | null,
      twitterAccessSecret?: string | null,
      instagramUserId?: string | null,
    } | null,
    firebaseTokens?: Array< string | null > | null,
  } | null,
};

export type ListUsersQueryVariables = {
  id?: string | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      name: string,
      givenName: string,
      familyName: string,
      gender?: string | null,
      birthdate?: string | null,
      country?: string | null,
      sport?: string | null,
      profileImgS3Key?: string | null,
      coverImgS3Key?: string | null,
      introVideoS3Key?: string | null,
      introVideoThumbS3Key?: string | null,
      introVideoUrl?: string | null,
      mission?: string | null,
      instagramUrl?: string | null,
      facebookUrl?: string | null,
      youtubeUrl?: string | null,
      goals?:  Array< {
        __typename: "AthleteGoal",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        videoUrl?: string | null,
        link?: string | null,
      } | null > | null,
      achievements?:  Array< {
        __typename: "AthleteAchievement",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        link?: string | null,
        videoUrl?: string | null,
        date?: string | null,
      } | null > | null,
      fundingGoals?:  Array< {
        __typename: "AthleteFundingGoal",
        id: string,
        amount: number,
        currency?: string | null,
        description: string,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "AthleteSponsor",
        id: string,
        imgS3Key: string,
      } | null > | null,
      emailValidated?: boolean | null,
      validationEmailSentAt?: string | null,
      potentialAthlete?: boolean | null,
      currency?: string | null,
      themeId?: string | null,
      role: string,
      nickname?: string | null,
      stripePayoutsEnabled?: boolean | null,
      payoutCurrency?: string | null,
      stats?:  {
        __typename: "UserStats",
        numUniqueViews?: number | null,
        numScouts?: number | null,
        paidByScouts?: number | null,
        numPosts?: number | null,
      } | null,
      features?:  {
        __typename: "UserFeatures",
        posts?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      ivsChannelArn?: string | null,
      createdAt: string,
      updatedAt: string,
      owner: string,
      email: string,
      stripeActionRequired?: boolean | null,
      stripeAccountId?: string | null,
      social?:  {
        __typename: "UserSocial",
        fbUserId?: string | null,
        fbAccessToken?: string | null,
        fbTargetPageId?: string | null,
        twitterRequestToken?: string | null,
        twitterRequestSecret?: string | null,
        twitterAccessToken?: string | null,
        twitterAccessSecret?: string | null,
        instagramUserId?: string | null,
      } | null,
      firebaseTokens?: Array< string | null > | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPostQueryVariables = {
  id: string,
};

export type GetPostQuery = {
  getPost?:  {
    __typename: "Post",
    id: string,
    authorId: string,
    name: string,
    description?: string | null,
    needsSubscription?: boolean | null,
    type: PostType,
    tierId: string,
    vodId?: string | null,
    vodStatus?: string | null,
    vodMetadata?:  {
      __typename: "PostVodMetadata",
      durationInMs?: number | null,
      orgWidth?: number | null,
      orgHeight?: number | null,
      hasPreview?: boolean | null,
    } | null,
    bcastMetadata?:  {
      __typename: "PostBcastMetadata",
      previewImageS3Key?: string | null,
      time: string,
      duration?: string | null,
      guests:  Array< {
        __typename: "BcastGuest",
        id: string,
        name: string,
      } >,
      allowQuestions: boolean,
      status: BcastStatus,
      bcastUrl?: string | null,
      recordingS3Key?: string | null,
    } | null,
    thumbS3Key?: string | null,
    likes?: number | null,
    comments?: number | null,
    liked?: boolean | null,
    reviewStatus?: PostReviewStatus | null,
    feedId?: string | null,
    createdAt: string,
    updatedAt: string,
    author?:  {
      __typename: "User",
      id: string,
      name: string,
      givenName: string,
      familyName: string,
      gender?: string | null,
      birthdate?: string | null,
      country?: string | null,
      sport?: string | null,
      profileImgS3Key?: string | null,
      coverImgS3Key?: string | null,
      introVideoS3Key?: string | null,
      introVideoThumbS3Key?: string | null,
      introVideoUrl?: string | null,
      mission?: string | null,
      instagramUrl?: string | null,
      facebookUrl?: string | null,
      youtubeUrl?: string | null,
      goals?:  Array< {
        __typename: "AthleteGoal",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        videoUrl?: string | null,
        link?: string | null,
      } | null > | null,
      achievements?:  Array< {
        __typename: "AthleteAchievement",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        link?: string | null,
        videoUrl?: string | null,
        date?: string | null,
      } | null > | null,
      fundingGoals?:  Array< {
        __typename: "AthleteFundingGoal",
        id: string,
        amount: number,
        currency?: string | null,
        description: string,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "AthleteSponsor",
        id: string,
        imgS3Key: string,
      } | null > | null,
      emailValidated?: boolean | null,
      validationEmailSentAt?: string | null,
      potentialAthlete?: boolean | null,
      currency?: string | null,
      themeId?: string | null,
      role: string,
      nickname?: string | null,
      stripePayoutsEnabled?: boolean | null,
      payoutCurrency?: string | null,
      stats?:  {
        __typename: "UserStats",
        numUniqueViews?: number | null,
        numScouts?: number | null,
        paidByScouts?: number | null,
        numPosts?: number | null,
      } | null,
      features?:  {
        __typename: "UserFeatures",
        posts?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      ivsChannelArn?: string | null,
      createdAt: string,
      updatedAt: string,
      owner: string,
      email: string,
      stripeActionRequired?: boolean | null,
      stripeAccountId?: string | null,
      social?:  {
        __typename: "UserSocial",
        fbUserId?: string | null,
        fbAccessToken?: string | null,
        fbTargetPageId?: string | null,
        twitterRequestToken?: string | null,
        twitterRequestSecret?: string | null,
        twitterAccessToken?: string | null,
        twitterAccessSecret?: string | null,
        instagramUserId?: string | null,
      } | null,
      firebaseTokens?: Array< string | null > | null,
    } | null,
    tier?:  {
      __typename: "Tier",
      id: string,
      userId: string,
      name: string,
      currency: string,
      unitAmount: number,
      discountType?: DiscountType | null,
      discount?: number | null,
      discountMonths?: number | null,
      couponId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type ListPostsQueryVariables = {
  id?: string | null,
  filter?: ModelPostFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListPostsQuery = {
  listPosts?:  {
    __typename: "ModelPostConnection",
    items:  Array< {
      __typename: "Post",
      id: string,
      authorId: string,
      name: string,
      description?: string | null,
      needsSubscription?: boolean | null,
      type: PostType,
      tierId: string,
      vodId?: string | null,
      vodStatus?: string | null,
      vodMetadata?:  {
        __typename: "PostVodMetadata",
        durationInMs?: number | null,
        orgWidth?: number | null,
        orgHeight?: number | null,
        hasPreview?: boolean | null,
      } | null,
      bcastMetadata?:  {
        __typename: "PostBcastMetadata",
        previewImageS3Key?: string | null,
        time: string,
        duration?: string | null,
        allowQuestions: boolean,
        status: BcastStatus,
        bcastUrl?: string | null,
        recordingS3Key?: string | null,
      } | null,
      thumbS3Key?: string | null,
      likes?: number | null,
      comments?: number | null,
      liked?: boolean | null,
      reviewStatus?: PostReviewStatus | null,
      feedId?: string | null,
      createdAt: string,
      updatedAt: string,
      author?:  {
        __typename: "User",
        id: string,
        name: string,
        givenName: string,
        familyName: string,
        gender?: string | null,
        birthdate?: string | null,
        country?: string | null,
        sport?: string | null,
        profileImgS3Key?: string | null,
        coverImgS3Key?: string | null,
        introVideoS3Key?: string | null,
        introVideoThumbS3Key?: string | null,
        introVideoUrl?: string | null,
        mission?: string | null,
        instagramUrl?: string | null,
        facebookUrl?: string | null,
        youtubeUrl?: string | null,
        emailValidated?: boolean | null,
        validationEmailSentAt?: string | null,
        potentialAthlete?: boolean | null,
        currency?: string | null,
        themeId?: string | null,
        role: string,
        nickname?: string | null,
        stripePayoutsEnabled?: boolean | null,
        payoutCurrency?: string | null,
        stripeCustomerId?: string | null,
        ivsChannelArn?: string | null,
        createdAt: string,
        updatedAt: string,
        owner: string,
        email: string,
        stripeActionRequired?: boolean | null,
        stripeAccountId?: string | null,
        firebaseTokens?: Array< string | null > | null,
      } | null,
      tier?:  {
        __typename: "Tier",
        id: string,
        userId: string,
        name: string,
        currency: string,
        unitAmount: number,
        discountType?: DiscountType | null,
        discount?: number | null,
        discountMonths?: number | null,
        couponId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListPostsByAuthorQueryVariables = {
  authorId?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPostFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPostsByAuthorQuery = {
  listPostsByAuthor?:  {
    __typename: "ModelPostConnection",
    items:  Array< {
      __typename: "Post",
      id: string,
      authorId: string,
      name: string,
      description?: string | null,
      needsSubscription?: boolean | null,
      type: PostType,
      tierId: string,
      vodId?: string | null,
      vodStatus?: string | null,
      vodMetadata?:  {
        __typename: "PostVodMetadata",
        durationInMs?: number | null,
        orgWidth?: number | null,
        orgHeight?: number | null,
        hasPreview?: boolean | null,
      } | null,
      bcastMetadata?:  {
        __typename: "PostBcastMetadata",
        previewImageS3Key?: string | null,
        time: string,
        duration?: string | null,
        allowQuestions: boolean,
        status: BcastStatus,
        bcastUrl?: string | null,
        recordingS3Key?: string | null,
      } | null,
      thumbS3Key?: string | null,
      likes?: number | null,
      comments?: number | null,
      liked?: boolean | null,
      reviewStatus?: PostReviewStatus | null,
      feedId?: string | null,
      createdAt: string,
      updatedAt: string,
      author?:  {
        __typename: "User",
        id: string,
        name: string,
        givenName: string,
        familyName: string,
        gender?: string | null,
        birthdate?: string | null,
        country?: string | null,
        sport?: string | null,
        profileImgS3Key?: string | null,
        coverImgS3Key?: string | null,
        introVideoS3Key?: string | null,
        introVideoThumbS3Key?: string | null,
        introVideoUrl?: string | null,
        mission?: string | null,
        instagramUrl?: string | null,
        facebookUrl?: string | null,
        youtubeUrl?: string | null,
        emailValidated?: boolean | null,
        validationEmailSentAt?: string | null,
        potentialAthlete?: boolean | null,
        currency?: string | null,
        themeId?: string | null,
        role: string,
        nickname?: string | null,
        stripePayoutsEnabled?: boolean | null,
        payoutCurrency?: string | null,
        stripeCustomerId?: string | null,
        ivsChannelArn?: string | null,
        createdAt: string,
        updatedAt: string,
        owner: string,
        email: string,
        stripeActionRequired?: boolean | null,
        stripeAccountId?: string | null,
        firebaseTokens?: Array< string | null > | null,
      } | null,
      tier?:  {
        __typename: "Tier",
        id: string,
        userId: string,
        name: string,
        currency: string,
        unitAmount: number,
        discountType?: DiscountType | null,
        discount?: number | null,
        discountMonths?: number | null,
        couponId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListPostsByVodIdQueryVariables = {
  vodId?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPostFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPostsByVodIdQuery = {
  listPostsByVodId?:  {
    __typename: "ModelPostConnection",
    items:  Array< {
      __typename: "Post",
      id: string,
      authorId: string,
      name: string,
      description?: string | null,
      needsSubscription?: boolean | null,
      type: PostType,
      tierId: string,
      vodId?: string | null,
      vodStatus?: string | null,
      vodMetadata?:  {
        __typename: "PostVodMetadata",
        durationInMs?: number | null,
        orgWidth?: number | null,
        orgHeight?: number | null,
        hasPreview?: boolean | null,
      } | null,
      bcastMetadata?:  {
        __typename: "PostBcastMetadata",
        previewImageS3Key?: string | null,
        time: string,
        duration?: string | null,
        allowQuestions: boolean,
        status: BcastStatus,
        bcastUrl?: string | null,
        recordingS3Key?: string | null,
      } | null,
      thumbS3Key?: string | null,
      likes?: number | null,
      comments?: number | null,
      liked?: boolean | null,
      reviewStatus?: PostReviewStatus | null,
      feedId?: string | null,
      createdAt: string,
      updatedAt: string,
      author?:  {
        __typename: "User",
        id: string,
        name: string,
        givenName: string,
        familyName: string,
        gender?: string | null,
        birthdate?: string | null,
        country?: string | null,
        sport?: string | null,
        profileImgS3Key?: string | null,
        coverImgS3Key?: string | null,
        introVideoS3Key?: string | null,
        introVideoThumbS3Key?: string | null,
        introVideoUrl?: string | null,
        mission?: string | null,
        instagramUrl?: string | null,
        facebookUrl?: string | null,
        youtubeUrl?: string | null,
        emailValidated?: boolean | null,
        validationEmailSentAt?: string | null,
        potentialAthlete?: boolean | null,
        currency?: string | null,
        themeId?: string | null,
        role: string,
        nickname?: string | null,
        stripePayoutsEnabled?: boolean | null,
        payoutCurrency?: string | null,
        stripeCustomerId?: string | null,
        ivsChannelArn?: string | null,
        createdAt: string,
        updatedAt: string,
        owner: string,
        email: string,
        stripeActionRequired?: boolean | null,
        stripeAccountId?: string | null,
        firebaseTokens?: Array< string | null > | null,
      } | null,
      tier?:  {
        __typename: "Tier",
        id: string,
        userId: string,
        name: string,
        currency: string,
        unitAmount: number,
        discountType?: DiscountType | null,
        discount?: number | null,
        discountMonths?: number | null,
        couponId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListPostsByFeedIdQueryVariables = {
  feedId?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPostFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPostsByFeedIdQuery = {
  listPostsByFeedId?:  {
    __typename: "ModelPostConnection",
    items:  Array< {
      __typename: "Post",
      id: string,
      authorId: string,
      name: string,
      description?: string | null,
      needsSubscription?: boolean | null,
      type: PostType,
      tierId: string,
      vodId?: string | null,
      vodStatus?: string | null,
      vodMetadata?:  {
        __typename: "PostVodMetadata",
        durationInMs?: number | null,
        orgWidth?: number | null,
        orgHeight?: number | null,
        hasPreview?: boolean | null,
      } | null,
      bcastMetadata?:  {
        __typename: "PostBcastMetadata",
        previewImageS3Key?: string | null,
        time: string,
        duration?: string | null,
        allowQuestions: boolean,
        status: BcastStatus,
        bcastUrl?: string | null,
        recordingS3Key?: string | null,
      } | null,
      thumbS3Key?: string | null,
      likes?: number | null,
      comments?: number | null,
      liked?: boolean | null,
      reviewStatus?: PostReviewStatus | null,
      feedId?: string | null,
      createdAt: string,
      updatedAt: string,
      author?:  {
        __typename: "User",
        id: string,
        name: string,
        givenName: string,
        familyName: string,
        gender?: string | null,
        birthdate?: string | null,
        country?: string | null,
        sport?: string | null,
        profileImgS3Key?: string | null,
        coverImgS3Key?: string | null,
        introVideoS3Key?: string | null,
        introVideoThumbS3Key?: string | null,
        introVideoUrl?: string | null,
        mission?: string | null,
        instagramUrl?: string | null,
        facebookUrl?: string | null,
        youtubeUrl?: string | null,
        emailValidated?: boolean | null,
        validationEmailSentAt?: string | null,
        potentialAthlete?: boolean | null,
        currency?: string | null,
        themeId?: string | null,
        role: string,
        nickname?: string | null,
        stripePayoutsEnabled?: boolean | null,
        payoutCurrency?: string | null,
        stripeCustomerId?: string | null,
        ivsChannelArn?: string | null,
        createdAt: string,
        updatedAt: string,
        owner: string,
        email: string,
        stripeActionRequired?: boolean | null,
        stripeAccountId?: string | null,
        firebaseTokens?: Array< string | null > | null,
      } | null,
      tier?:  {
        __typename: "Tier",
        id: string,
        userId: string,
        name: string,
        currency: string,
        unitAmount: number,
        discountType?: DiscountType | null,
        discount?: number | null,
        discountMonths?: number | null,
        couponId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchPostsQueryVariables = {
  filter?: SearchablePostFilterInput | null,
  sort?: SearchablePostSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchPostsQuery = {
  searchPosts?:  {
    __typename: "SearchablePostConnection",
    items?:  Array< {
      __typename: "Post",
      id: string,
      authorId: string,
      name: string,
      description?: string | null,
      needsSubscription?: boolean | null,
      type: PostType,
      tierId: string,
      vodId?: string | null,
      vodStatus?: string | null,
      vodMetadata?:  {
        __typename: "PostVodMetadata",
        durationInMs?: number | null,
        orgWidth?: number | null,
        orgHeight?: number | null,
        hasPreview?: boolean | null,
      } | null,
      bcastMetadata?:  {
        __typename: "PostBcastMetadata",
        previewImageS3Key?: string | null,
        time: string,
        duration?: string | null,
        allowQuestions: boolean,
        status: BcastStatus,
        bcastUrl?: string | null,
        recordingS3Key?: string | null,
      } | null,
      thumbS3Key?: string | null,
      likes?: number | null,
      comments?: number | null,
      liked?: boolean | null,
      reviewStatus?: PostReviewStatus | null,
      feedId?: string | null,
      createdAt: string,
      updatedAt: string,
      author?:  {
        __typename: "User",
        id: string,
        name: string,
        givenName: string,
        familyName: string,
        gender?: string | null,
        birthdate?: string | null,
        country?: string | null,
        sport?: string | null,
        profileImgS3Key?: string | null,
        coverImgS3Key?: string | null,
        introVideoS3Key?: string | null,
        introVideoThumbS3Key?: string | null,
        introVideoUrl?: string | null,
        mission?: string | null,
        instagramUrl?: string | null,
        facebookUrl?: string | null,
        youtubeUrl?: string | null,
        emailValidated?: boolean | null,
        validationEmailSentAt?: string | null,
        potentialAthlete?: boolean | null,
        currency?: string | null,
        themeId?: string | null,
        role: string,
        nickname?: string | null,
        stripePayoutsEnabled?: boolean | null,
        payoutCurrency?: string | null,
        stripeCustomerId?: string | null,
        ivsChannelArn?: string | null,
        createdAt: string,
        updatedAt: string,
        owner: string,
        email: string,
        stripeActionRequired?: boolean | null,
        stripeAccountId?: string | null,
        firebaseTokens?: Array< string | null > | null,
      } | null,
      tier?:  {
        __typename: "Tier",
        id: string,
        userId: string,
        name: string,
        currency: string,
        unitAmount: number,
        discountType?: DiscountType | null,
        discount?: number | null,
        discountMonths?: number | null,
        couponId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    total?:  {
      __typename: "SearchTotal",
      value?: number | null,
      relation?: string | null,
    } | null,
  } | null,
};

export type GetAthleteSubscriptionQueryVariables = {
  subscriberId: string,
  athleteId: string,
};

export type GetAthleteSubscriptionQuery = {
  getAthleteSubscription?:  {
    __typename: "AthleteSubscription",
    subscriberId: string,
    athleteId: string,
    tierId?: string | null,
    currency: string,
    unitAmount: number,
    paidToAthlete: number,
    status?: SubscriptionStatus | null,
    cancelAtPeriodEnd?: boolean | null,
    currentPeriodEnd?: number | null,
    expiresAt: string,
    discountType?: DiscountType | null,
    discount?: number | null,
    discountRemainingMonths?: number | null,
    couponId?: string | null,
    taxRateId?: string | null,
    updatedAt: string,
    createdAt: string,
    stripeSubscriptionId?: string | null,
    stripePriceId?: string | null,
    subscriber?:  {
      __typename: "User",
      id: string,
      name: string,
      givenName: string,
      familyName: string,
      gender?: string | null,
      birthdate?: string | null,
      country?: string | null,
      sport?: string | null,
      profileImgS3Key?: string | null,
      coverImgS3Key?: string | null,
      introVideoS3Key?: string | null,
      introVideoThumbS3Key?: string | null,
      introVideoUrl?: string | null,
      mission?: string | null,
      instagramUrl?: string | null,
      facebookUrl?: string | null,
      youtubeUrl?: string | null,
      goals?:  Array< {
        __typename: "AthleteGoal",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        videoUrl?: string | null,
        link?: string | null,
      } | null > | null,
      achievements?:  Array< {
        __typename: "AthleteAchievement",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        link?: string | null,
        videoUrl?: string | null,
        date?: string | null,
      } | null > | null,
      fundingGoals?:  Array< {
        __typename: "AthleteFundingGoal",
        id: string,
        amount: number,
        currency?: string | null,
        description: string,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "AthleteSponsor",
        id: string,
        imgS3Key: string,
      } | null > | null,
      emailValidated?: boolean | null,
      validationEmailSentAt?: string | null,
      potentialAthlete?: boolean | null,
      currency?: string | null,
      themeId?: string | null,
      role: string,
      nickname?: string | null,
      stripePayoutsEnabled?: boolean | null,
      payoutCurrency?: string | null,
      stats?:  {
        __typename: "UserStats",
        numUniqueViews?: number | null,
        numScouts?: number | null,
        paidByScouts?: number | null,
        numPosts?: number | null,
      } | null,
      features?:  {
        __typename: "UserFeatures",
        posts?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      ivsChannelArn?: string | null,
      createdAt: string,
      updatedAt: string,
      owner: string,
      email: string,
      stripeActionRequired?: boolean | null,
      stripeAccountId?: string | null,
      social?:  {
        __typename: "UserSocial",
        fbUserId?: string | null,
        fbAccessToken?: string | null,
        fbTargetPageId?: string | null,
        twitterRequestToken?: string | null,
        twitterRequestSecret?: string | null,
        twitterAccessToken?: string | null,
        twitterAccessSecret?: string | null,
        instagramUserId?: string | null,
      } | null,
      firebaseTokens?: Array< string | null > | null,
    } | null,
    athlete?:  {
      __typename: "User",
      id: string,
      name: string,
      givenName: string,
      familyName: string,
      gender?: string | null,
      birthdate?: string | null,
      country?: string | null,
      sport?: string | null,
      profileImgS3Key?: string | null,
      coverImgS3Key?: string | null,
      introVideoS3Key?: string | null,
      introVideoThumbS3Key?: string | null,
      introVideoUrl?: string | null,
      mission?: string | null,
      instagramUrl?: string | null,
      facebookUrl?: string | null,
      youtubeUrl?: string | null,
      goals?:  Array< {
        __typename: "AthleteGoal",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        videoUrl?: string | null,
        link?: string | null,
      } | null > | null,
      achievements?:  Array< {
        __typename: "AthleteAchievement",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        link?: string | null,
        videoUrl?: string | null,
        date?: string | null,
      } | null > | null,
      fundingGoals?:  Array< {
        __typename: "AthleteFundingGoal",
        id: string,
        amount: number,
        currency?: string | null,
        description: string,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "AthleteSponsor",
        id: string,
        imgS3Key: string,
      } | null > | null,
      emailValidated?: boolean | null,
      validationEmailSentAt?: string | null,
      potentialAthlete?: boolean | null,
      currency?: string | null,
      themeId?: string | null,
      role: string,
      nickname?: string | null,
      stripePayoutsEnabled?: boolean | null,
      payoutCurrency?: string | null,
      stats?:  {
        __typename: "UserStats",
        numUniqueViews?: number | null,
        numScouts?: number | null,
        paidByScouts?: number | null,
        numPosts?: number | null,
      } | null,
      features?:  {
        __typename: "UserFeatures",
        posts?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      ivsChannelArn?: string | null,
      createdAt: string,
      updatedAt: string,
      owner: string,
      email: string,
      stripeActionRequired?: boolean | null,
      stripeAccountId?: string | null,
      social?:  {
        __typename: "UserSocial",
        fbUserId?: string | null,
        fbAccessToken?: string | null,
        fbTargetPageId?: string | null,
        twitterRequestToken?: string | null,
        twitterRequestSecret?: string | null,
        twitterAccessToken?: string | null,
        twitterAccessSecret?: string | null,
        instagramUserId?: string | null,
      } | null,
      firebaseTokens?: Array< string | null > | null,
    } | null,
    tier?:  {
      __typename: "Tier",
      id: string,
      userId: string,
      name: string,
      currency: string,
      unitAmount: number,
      discountType?: DiscountType | null,
      discount?: number | null,
      discountMonths?: number | null,
      couponId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type ListAthleteSubscriptionsQueryVariables = {
  subscriberId?: string | null,
  athleteId?: ModelIDKeyConditionInput | null,
  filter?: ModelAthleteSubscriptionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListAthleteSubscriptionsQuery = {
  listAthleteSubscriptions?:  {
    __typename: "ModelAthleteSubscriptionConnection",
    items:  Array< {
      __typename: "AthleteSubscription",
      subscriberId: string,
      athleteId: string,
      tierId?: string | null,
      currency: string,
      unitAmount: number,
      paidToAthlete: number,
      status?: SubscriptionStatus | null,
      cancelAtPeriodEnd?: boolean | null,
      currentPeriodEnd?: number | null,
      expiresAt: string,
      discountType?: DiscountType | null,
      discount?: number | null,
      discountRemainingMonths?: number | null,
      couponId?: string | null,
      taxRateId?: string | null,
      updatedAt: string,
      createdAt: string,
      stripeSubscriptionId?: string | null,
      stripePriceId?: string | null,
      subscriber?:  {
        __typename: "User",
        id: string,
        name: string,
        givenName: string,
        familyName: string,
        gender?: string | null,
        birthdate?: string | null,
        country?: string | null,
        sport?: string | null,
        profileImgS3Key?: string | null,
        coverImgS3Key?: string | null,
        introVideoS3Key?: string | null,
        introVideoThumbS3Key?: string | null,
        introVideoUrl?: string | null,
        mission?: string | null,
        instagramUrl?: string | null,
        facebookUrl?: string | null,
        youtubeUrl?: string | null,
        emailValidated?: boolean | null,
        validationEmailSentAt?: string | null,
        potentialAthlete?: boolean | null,
        currency?: string | null,
        themeId?: string | null,
        role: string,
        nickname?: string | null,
        stripePayoutsEnabled?: boolean | null,
        payoutCurrency?: string | null,
        stripeCustomerId?: string | null,
        ivsChannelArn?: string | null,
        createdAt: string,
        updatedAt: string,
        owner: string,
        email: string,
        stripeActionRequired?: boolean | null,
        stripeAccountId?: string | null,
        firebaseTokens?: Array< string | null > | null,
      } | null,
      athlete?:  {
        __typename: "User",
        id: string,
        name: string,
        givenName: string,
        familyName: string,
        gender?: string | null,
        birthdate?: string | null,
        country?: string | null,
        sport?: string | null,
        profileImgS3Key?: string | null,
        coverImgS3Key?: string | null,
        introVideoS3Key?: string | null,
        introVideoThumbS3Key?: string | null,
        introVideoUrl?: string | null,
        mission?: string | null,
        instagramUrl?: string | null,
        facebookUrl?: string | null,
        youtubeUrl?: string | null,
        emailValidated?: boolean | null,
        validationEmailSentAt?: string | null,
        potentialAthlete?: boolean | null,
        currency?: string | null,
        themeId?: string | null,
        role: string,
        nickname?: string | null,
        stripePayoutsEnabled?: boolean | null,
        payoutCurrency?: string | null,
        stripeCustomerId?: string | null,
        ivsChannelArn?: string | null,
        createdAt: string,
        updatedAt: string,
        owner: string,
        email: string,
        stripeActionRequired?: boolean | null,
        stripeAccountId?: string | null,
        firebaseTokens?: Array< string | null > | null,
      } | null,
      tier?:  {
        __typename: "Tier",
        id: string,
        userId: string,
        name: string,
        currency: string,
        unitAmount: number,
        discountType?: DiscountType | null,
        discount?: number | null,
        discountMonths?: number | null,
        couponId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListSubscriptionsBySubscriberQueryVariables = {
  subscriberId?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAthleteSubscriptionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSubscriptionsBySubscriberQuery = {
  listSubscriptionsBySubscriber?:  {
    __typename: "ModelAthleteSubscriptionConnection",
    items:  Array< {
      __typename: "AthleteSubscription",
      subscriberId: string,
      athleteId: string,
      tierId?: string | null,
      currency: string,
      unitAmount: number,
      paidToAthlete: number,
      status?: SubscriptionStatus | null,
      cancelAtPeriodEnd?: boolean | null,
      currentPeriodEnd?: number | null,
      expiresAt: string,
      discountType?: DiscountType | null,
      discount?: number | null,
      discountRemainingMonths?: number | null,
      couponId?: string | null,
      taxRateId?: string | null,
      updatedAt: string,
      createdAt: string,
      stripeSubscriptionId?: string | null,
      stripePriceId?: string | null,
      subscriber?:  {
        __typename: "User",
        id: string,
        name: string,
        givenName: string,
        familyName: string,
        gender?: string | null,
        birthdate?: string | null,
        country?: string | null,
        sport?: string | null,
        profileImgS3Key?: string | null,
        coverImgS3Key?: string | null,
        introVideoS3Key?: string | null,
        introVideoThumbS3Key?: string | null,
        introVideoUrl?: string | null,
        mission?: string | null,
        instagramUrl?: string | null,
        facebookUrl?: string | null,
        youtubeUrl?: string | null,
        emailValidated?: boolean | null,
        validationEmailSentAt?: string | null,
        potentialAthlete?: boolean | null,
        currency?: string | null,
        themeId?: string | null,
        role: string,
        nickname?: string | null,
        stripePayoutsEnabled?: boolean | null,
        payoutCurrency?: string | null,
        stripeCustomerId?: string | null,
        ivsChannelArn?: string | null,
        createdAt: string,
        updatedAt: string,
        owner: string,
        email: string,
        stripeActionRequired?: boolean | null,
        stripeAccountId?: string | null,
        firebaseTokens?: Array< string | null > | null,
      } | null,
      athlete?:  {
        __typename: "User",
        id: string,
        name: string,
        givenName: string,
        familyName: string,
        gender?: string | null,
        birthdate?: string | null,
        country?: string | null,
        sport?: string | null,
        profileImgS3Key?: string | null,
        coverImgS3Key?: string | null,
        introVideoS3Key?: string | null,
        introVideoThumbS3Key?: string | null,
        introVideoUrl?: string | null,
        mission?: string | null,
        instagramUrl?: string | null,
        facebookUrl?: string | null,
        youtubeUrl?: string | null,
        emailValidated?: boolean | null,
        validationEmailSentAt?: string | null,
        potentialAthlete?: boolean | null,
        currency?: string | null,
        themeId?: string | null,
        role: string,
        nickname?: string | null,
        stripePayoutsEnabled?: boolean | null,
        payoutCurrency?: string | null,
        stripeCustomerId?: string | null,
        ivsChannelArn?: string | null,
        createdAt: string,
        updatedAt: string,
        owner: string,
        email: string,
        stripeActionRequired?: boolean | null,
        stripeAccountId?: string | null,
        firebaseTokens?: Array< string | null > | null,
      } | null,
      tier?:  {
        __typename: "Tier",
        id: string,
        userId: string,
        name: string,
        currency: string,
        unitAmount: number,
        discountType?: DiscountType | null,
        discount?: number | null,
        discountMonths?: number | null,
        couponId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListSubscriptionsByAthleteQueryVariables = {
  athleteId?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAthleteSubscriptionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSubscriptionsByAthleteQuery = {
  listSubscriptionsByAthlete?:  {
    __typename: "ModelAthleteSubscriptionConnection",
    items:  Array< {
      __typename: "AthleteSubscription",
      subscriberId: string,
      athleteId: string,
      tierId?: string | null,
      currency: string,
      unitAmount: number,
      paidToAthlete: number,
      status?: SubscriptionStatus | null,
      cancelAtPeriodEnd?: boolean | null,
      currentPeriodEnd?: number | null,
      expiresAt: string,
      discountType?: DiscountType | null,
      discount?: number | null,
      discountRemainingMonths?: number | null,
      couponId?: string | null,
      taxRateId?: string | null,
      updatedAt: string,
      createdAt: string,
      stripeSubscriptionId?: string | null,
      stripePriceId?: string | null,
      subscriber?:  {
        __typename: "User",
        id: string,
        name: string,
        givenName: string,
        familyName: string,
        gender?: string | null,
        birthdate?: string | null,
        country?: string | null,
        sport?: string | null,
        profileImgS3Key?: string | null,
        coverImgS3Key?: string | null,
        introVideoS3Key?: string | null,
        introVideoThumbS3Key?: string | null,
        introVideoUrl?: string | null,
        mission?: string | null,
        instagramUrl?: string | null,
        facebookUrl?: string | null,
        youtubeUrl?: string | null,
        emailValidated?: boolean | null,
        validationEmailSentAt?: string | null,
        potentialAthlete?: boolean | null,
        currency?: string | null,
        themeId?: string | null,
        role: string,
        nickname?: string | null,
        stripePayoutsEnabled?: boolean | null,
        payoutCurrency?: string | null,
        stripeCustomerId?: string | null,
        ivsChannelArn?: string | null,
        createdAt: string,
        updatedAt: string,
        owner: string,
        email: string,
        stripeActionRequired?: boolean | null,
        stripeAccountId?: string | null,
        firebaseTokens?: Array< string | null > | null,
      } | null,
      athlete?:  {
        __typename: "User",
        id: string,
        name: string,
        givenName: string,
        familyName: string,
        gender?: string | null,
        birthdate?: string | null,
        country?: string | null,
        sport?: string | null,
        profileImgS3Key?: string | null,
        coverImgS3Key?: string | null,
        introVideoS3Key?: string | null,
        introVideoThumbS3Key?: string | null,
        introVideoUrl?: string | null,
        mission?: string | null,
        instagramUrl?: string | null,
        facebookUrl?: string | null,
        youtubeUrl?: string | null,
        emailValidated?: boolean | null,
        validationEmailSentAt?: string | null,
        potentialAthlete?: boolean | null,
        currency?: string | null,
        themeId?: string | null,
        role: string,
        nickname?: string | null,
        stripePayoutsEnabled?: boolean | null,
        payoutCurrency?: string | null,
        stripeCustomerId?: string | null,
        ivsChannelArn?: string | null,
        createdAt: string,
        updatedAt: string,
        owner: string,
        email: string,
        stripeActionRequired?: boolean | null,
        stripeAccountId?: string | null,
        firebaseTokens?: Array< string | null > | null,
      } | null,
      tier?:  {
        __typename: "Tier",
        id: string,
        userId: string,
        name: string,
        currency: string,
        unitAmount: number,
        discountType?: DiscountType | null,
        discount?: number | null,
        discountMonths?: number | null,
        couponId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListSubscriptionsByStripeSubscriptionIdQueryVariables = {
  stripeSubscriptionId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAthleteSubscriptionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSubscriptionsByStripeSubscriptionIdQuery = {
  listSubscriptionsByStripeSubscriptionId?:  {
    __typename: "ModelAthleteSubscriptionConnection",
    items:  Array< {
      __typename: "AthleteSubscription",
      subscriberId: string,
      athleteId: string,
      tierId?: string | null,
      currency: string,
      unitAmount: number,
      paidToAthlete: number,
      status?: SubscriptionStatus | null,
      cancelAtPeriodEnd?: boolean | null,
      currentPeriodEnd?: number | null,
      expiresAt: string,
      discountType?: DiscountType | null,
      discount?: number | null,
      discountRemainingMonths?: number | null,
      couponId?: string | null,
      taxRateId?: string | null,
      updatedAt: string,
      createdAt: string,
      stripeSubscriptionId?: string | null,
      stripePriceId?: string | null,
      subscriber?:  {
        __typename: "User",
        id: string,
        name: string,
        givenName: string,
        familyName: string,
        gender?: string | null,
        birthdate?: string | null,
        country?: string | null,
        sport?: string | null,
        profileImgS3Key?: string | null,
        coverImgS3Key?: string | null,
        introVideoS3Key?: string | null,
        introVideoThumbS3Key?: string | null,
        introVideoUrl?: string | null,
        mission?: string | null,
        instagramUrl?: string | null,
        facebookUrl?: string | null,
        youtubeUrl?: string | null,
        emailValidated?: boolean | null,
        validationEmailSentAt?: string | null,
        potentialAthlete?: boolean | null,
        currency?: string | null,
        themeId?: string | null,
        role: string,
        nickname?: string | null,
        stripePayoutsEnabled?: boolean | null,
        payoutCurrency?: string | null,
        stripeCustomerId?: string | null,
        ivsChannelArn?: string | null,
        createdAt: string,
        updatedAt: string,
        owner: string,
        email: string,
        stripeActionRequired?: boolean | null,
        stripeAccountId?: string | null,
        firebaseTokens?: Array< string | null > | null,
      } | null,
      athlete?:  {
        __typename: "User",
        id: string,
        name: string,
        givenName: string,
        familyName: string,
        gender?: string | null,
        birthdate?: string | null,
        country?: string | null,
        sport?: string | null,
        profileImgS3Key?: string | null,
        coverImgS3Key?: string | null,
        introVideoS3Key?: string | null,
        introVideoThumbS3Key?: string | null,
        introVideoUrl?: string | null,
        mission?: string | null,
        instagramUrl?: string | null,
        facebookUrl?: string | null,
        youtubeUrl?: string | null,
        emailValidated?: boolean | null,
        validationEmailSentAt?: string | null,
        potentialAthlete?: boolean | null,
        currency?: string | null,
        themeId?: string | null,
        role: string,
        nickname?: string | null,
        stripePayoutsEnabled?: boolean | null,
        payoutCurrency?: string | null,
        stripeCustomerId?: string | null,
        ivsChannelArn?: string | null,
        createdAt: string,
        updatedAt: string,
        owner: string,
        email: string,
        stripeActionRequired?: boolean | null,
        stripeAccountId?: string | null,
        firebaseTokens?: Array< string | null > | null,
      } | null,
      tier?:  {
        __typename: "Tier",
        id: string,
        userId: string,
        name: string,
        currency: string,
        unitAmount: number,
        discountType?: DiscountType | null,
        discount?: number | null,
        discountMonths?: number | null,
        couponId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchAthleteSubscriptionsQueryVariables = {
  filter?: SearchableAthleteSubscriptionFilterInput | null,
  sort?: SearchableAthleteSubscriptionSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchAthleteSubscriptionsQuery = {
  searchAthleteSubscriptions?:  {
    __typename: "SearchableAthleteSubscriptionConnection",
    items:  Array< {
      __typename: "AthleteSubscription",
      subscriberId: string,
      athleteId: string,
      tierId?: string | null,
      currency: string,
      unitAmount: number,
      paidToAthlete: number,
      status?: SubscriptionStatus | null,
      cancelAtPeriodEnd?: boolean | null,
      currentPeriodEnd?: number | null,
      expiresAt: string,
      discountType?: DiscountType | null,
      discount?: number | null,
      discountRemainingMonths?: number | null,
      couponId?: string | null,
      taxRateId?: string | null,
      updatedAt: string,
      createdAt: string,
      stripeSubscriptionId?: string | null,
      stripePriceId?: string | null,
      subscriber?:  {
        __typename: "User",
        id: string,
        name: string,
        givenName: string,
        familyName: string,
        gender?: string | null,
        birthdate?: string | null,
        country?: string | null,
        sport?: string | null,
        profileImgS3Key?: string | null,
        coverImgS3Key?: string | null,
        introVideoS3Key?: string | null,
        introVideoThumbS3Key?: string | null,
        introVideoUrl?: string | null,
        mission?: string | null,
        instagramUrl?: string | null,
        facebookUrl?: string | null,
        youtubeUrl?: string | null,
        emailValidated?: boolean | null,
        validationEmailSentAt?: string | null,
        potentialAthlete?: boolean | null,
        currency?: string | null,
        themeId?: string | null,
        role: string,
        nickname?: string | null,
        stripePayoutsEnabled?: boolean | null,
        payoutCurrency?: string | null,
        stripeCustomerId?: string | null,
        ivsChannelArn?: string | null,
        createdAt: string,
        updatedAt: string,
        owner: string,
        email: string,
        stripeActionRequired?: boolean | null,
        stripeAccountId?: string | null,
        firebaseTokens?: Array< string | null > | null,
      } | null,
      athlete?:  {
        __typename: "User",
        id: string,
        name: string,
        givenName: string,
        familyName: string,
        gender?: string | null,
        birthdate?: string | null,
        country?: string | null,
        sport?: string | null,
        profileImgS3Key?: string | null,
        coverImgS3Key?: string | null,
        introVideoS3Key?: string | null,
        introVideoThumbS3Key?: string | null,
        introVideoUrl?: string | null,
        mission?: string | null,
        instagramUrl?: string | null,
        facebookUrl?: string | null,
        youtubeUrl?: string | null,
        emailValidated?: boolean | null,
        validationEmailSentAt?: string | null,
        potentialAthlete?: boolean | null,
        currency?: string | null,
        themeId?: string | null,
        role: string,
        nickname?: string | null,
        stripePayoutsEnabled?: boolean | null,
        payoutCurrency?: string | null,
        stripeCustomerId?: string | null,
        ivsChannelArn?: string | null,
        createdAt: string,
        updatedAt: string,
        owner: string,
        email: string,
        stripeActionRequired?: boolean | null,
        stripeAccountId?: string | null,
        firebaseTokens?: Array< string | null > | null,
      } | null,
      tier?:  {
        __typename: "Tier",
        id: string,
        userId: string,
        name: string,
        currency: string,
        unitAmount: number,
        discountType?: DiscountType | null,
        discount?: number | null,
        discountMonths?: number | null,
        couponId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetTierQueryVariables = {
  id: string,
};

export type GetTierQuery = {
  getTier?:  {
    __typename: "Tier",
    id: string,
    userId: string,
    name: string,
    currency: string,
    unitAmount: number,
    discountType?: DiscountType | null,
    discount?: number | null,
    discountMonths?: number | null,
    couponId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListTiersQueryVariables = {
  id?: string | null,
  filter?: ModelTierFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListTiersQuery = {
  listTiers?:  {
    __typename: "ModelTierConnection",
    items:  Array< {
      __typename: "Tier",
      id: string,
      userId: string,
      name: string,
      currency: string,
      unitAmount: number,
      discountType?: DiscountType | null,
      discount?: number | null,
      discountMonths?: number | null,
      couponId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListTiersByUserIdQueryVariables = {
  userId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTierFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTiersByUserIdQuery = {
  listTiersByUserId?:  {
    __typename: "ModelTierConnection",
    items:  Array< {
      __typename: "Tier",
      id: string,
      userId: string,
      name: string,
      currency: string,
      unitAmount: number,
      discountType?: DiscountType | null,
      discount?: number | null,
      discountMonths?: number | null,
      couponId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetFlashRequestQueryVariables = {
  userId: string,
  request: FlashRequestType,
  targetId: string,
};

export type GetFlashRequestQuery = {
  getFlashRequest?:  {
    __typename: "FlashRequest",
    userId: string,
    request: FlashRequestType,
    targetId: string,
    payload?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListFlashRequestsQueryVariables = {
  userId?: string | null,
  requestTargetId?: ModelFlashRequestPrimaryCompositeKeyConditionInput | null,
  filter?: ModelFlashRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListFlashRequestsQuery = {
  listFlashRequests?:  {
    __typename: "ModelFlashRequestConnection",
    items:  Array< {
      __typename: "FlashRequest",
      userId: string,
      request: FlashRequestType,
      targetId: string,
      payload?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetLikeQueryVariables = {
  userId: string,
  targetType: LikeTargetType,
  targetId: string,
};

export type GetLikeQuery = {
  getLike?:  {
    __typename: "Like",
    userId: string,
    targetId: string,
    targetType: LikeTargetType,
    createdAt: string,
    updatedAt: string,
    user?:  {
      __typename: "User",
      id: string,
      name: string,
      givenName: string,
      familyName: string,
      gender?: string | null,
      birthdate?: string | null,
      country?: string | null,
      sport?: string | null,
      profileImgS3Key?: string | null,
      coverImgS3Key?: string | null,
      introVideoS3Key?: string | null,
      introVideoThumbS3Key?: string | null,
      introVideoUrl?: string | null,
      mission?: string | null,
      instagramUrl?: string | null,
      facebookUrl?: string | null,
      youtubeUrl?: string | null,
      goals?:  Array< {
        __typename: "AthleteGoal",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        videoUrl?: string | null,
        link?: string | null,
      } | null > | null,
      achievements?:  Array< {
        __typename: "AthleteAchievement",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        link?: string | null,
        videoUrl?: string | null,
        date?: string | null,
      } | null > | null,
      fundingGoals?:  Array< {
        __typename: "AthleteFundingGoal",
        id: string,
        amount: number,
        currency?: string | null,
        description: string,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "AthleteSponsor",
        id: string,
        imgS3Key: string,
      } | null > | null,
      emailValidated?: boolean | null,
      validationEmailSentAt?: string | null,
      potentialAthlete?: boolean | null,
      currency?: string | null,
      themeId?: string | null,
      role: string,
      nickname?: string | null,
      stripePayoutsEnabled?: boolean | null,
      payoutCurrency?: string | null,
      stats?:  {
        __typename: "UserStats",
        numUniqueViews?: number | null,
        numScouts?: number | null,
        paidByScouts?: number | null,
        numPosts?: number | null,
      } | null,
      features?:  {
        __typename: "UserFeatures",
        posts?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      ivsChannelArn?: string | null,
      createdAt: string,
      updatedAt: string,
      owner: string,
      email: string,
      stripeActionRequired?: boolean | null,
      stripeAccountId?: string | null,
      social?:  {
        __typename: "UserSocial",
        fbUserId?: string | null,
        fbAccessToken?: string | null,
        fbTargetPageId?: string | null,
        twitterRequestToken?: string | null,
        twitterRequestSecret?: string | null,
        twitterAccessToken?: string | null,
        twitterAccessSecret?: string | null,
        instagramUserId?: string | null,
      } | null,
      firebaseTokens?: Array< string | null > | null,
    } | null,
  } | null,
};

export type ListLikesQueryVariables = {
  userId?: string | null,
  targetTypeTargetId?: ModelLikePrimaryCompositeKeyConditionInput | null,
  filter?: ModelLikeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListLikesQuery = {
  listLikes?:  {
    __typename: "ModelLikeConnection",
    items:  Array< {
      __typename: "Like",
      userId: string,
      targetId: string,
      targetType: LikeTargetType,
      createdAt: string,
      updatedAt: string,
      user?:  {
        __typename: "User",
        id: string,
        name: string,
        givenName: string,
        familyName: string,
        gender?: string | null,
        birthdate?: string | null,
        country?: string | null,
        sport?: string | null,
        profileImgS3Key?: string | null,
        coverImgS3Key?: string | null,
        introVideoS3Key?: string | null,
        introVideoThumbS3Key?: string | null,
        introVideoUrl?: string | null,
        mission?: string | null,
        instagramUrl?: string | null,
        facebookUrl?: string | null,
        youtubeUrl?: string | null,
        emailValidated?: boolean | null,
        validationEmailSentAt?: string | null,
        potentialAthlete?: boolean | null,
        currency?: string | null,
        themeId?: string | null,
        role: string,
        nickname?: string | null,
        stripePayoutsEnabled?: boolean | null,
        payoutCurrency?: string | null,
        stripeCustomerId?: string | null,
        ivsChannelArn?: string | null,
        createdAt: string,
        updatedAt: string,
        owner: string,
        email: string,
        stripeActionRequired?: boolean | null,
        stripeAccountId?: string | null,
        firebaseTokens?: Array< string | null > | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListLikesByTargetQueryVariables = {
  targetId?: string | null,
  targetType?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLikeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLikesByTargetQuery = {
  listLikesByTarget?:  {
    __typename: "ModelLikeConnection",
    items:  Array< {
      __typename: "Like",
      userId: string,
      targetId: string,
      targetType: LikeTargetType,
      createdAt: string,
      updatedAt: string,
      user?:  {
        __typename: "User",
        id: string,
        name: string,
        givenName: string,
        familyName: string,
        gender?: string | null,
        birthdate?: string | null,
        country?: string | null,
        sport?: string | null,
        profileImgS3Key?: string | null,
        coverImgS3Key?: string | null,
        introVideoS3Key?: string | null,
        introVideoThumbS3Key?: string | null,
        introVideoUrl?: string | null,
        mission?: string | null,
        instagramUrl?: string | null,
        facebookUrl?: string | null,
        youtubeUrl?: string | null,
        emailValidated?: boolean | null,
        validationEmailSentAt?: string | null,
        potentialAthlete?: boolean | null,
        currency?: string | null,
        themeId?: string | null,
        role: string,
        nickname?: string | null,
        stripePayoutsEnabled?: boolean | null,
        payoutCurrency?: string | null,
        stripeCustomerId?: string | null,
        ivsChannelArn?: string | null,
        createdAt: string,
        updatedAt: string,
        owner: string,
        email: string,
        stripeActionRequired?: boolean | null,
        stripeAccountId?: string | null,
        firebaseTokens?: Array< string | null > | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCommentQueryVariables = {
  id: string,
};

export type GetCommentQuery = {
  getComment?:  {
    __typename: "Comment",
    id: string,
    authorId: string,
    parentId: string,
    parentType: CommentParentType,
    lastComment?:  {
      __typename: "Comment",
      id: string,
      authorId: string,
      parentId: string,
      parentType: CommentParentType,
      lastComment?:  {
        __typename: "Comment",
        id: string,
        authorId: string,
        parentId: string,
        parentType: CommentParentType,
        message: string,
        likes?: number | null,
        comments?: number | null,
        liked?: boolean | null,
        createdAt: string,
        updatedAt?: string | null,
      } | null,
      message: string,
      likes?: number | null,
      comments?: number | null,
      liked?: boolean | null,
      createdAt: string,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        name: string,
        givenName: string,
        familyName: string,
        gender?: string | null,
        birthdate?: string | null,
        country?: string | null,
        sport?: string | null,
        profileImgS3Key?: string | null,
        coverImgS3Key?: string | null,
        introVideoS3Key?: string | null,
        introVideoThumbS3Key?: string | null,
        introVideoUrl?: string | null,
        mission?: string | null,
        instagramUrl?: string | null,
        facebookUrl?: string | null,
        youtubeUrl?: string | null,
        emailValidated?: boolean | null,
        validationEmailSentAt?: string | null,
        potentialAthlete?: boolean | null,
        currency?: string | null,
        themeId?: string | null,
        role: string,
        nickname?: string | null,
        stripePayoutsEnabled?: boolean | null,
        payoutCurrency?: string | null,
        stripeCustomerId?: string | null,
        ivsChannelArn?: string | null,
        createdAt: string,
        updatedAt: string,
        owner: string,
        email: string,
        stripeActionRequired?: boolean | null,
        stripeAccountId?: string | null,
        firebaseTokens?: Array< string | null > | null,
      } | null,
    } | null,
    message: string,
    likes?: number | null,
    comments?: number | null,
    liked?: boolean | null,
    createdAt: string,
    updatedAt?: string | null,
    author?:  {
      __typename: "User",
      id: string,
      name: string,
      givenName: string,
      familyName: string,
      gender?: string | null,
      birthdate?: string | null,
      country?: string | null,
      sport?: string | null,
      profileImgS3Key?: string | null,
      coverImgS3Key?: string | null,
      introVideoS3Key?: string | null,
      introVideoThumbS3Key?: string | null,
      introVideoUrl?: string | null,
      mission?: string | null,
      instagramUrl?: string | null,
      facebookUrl?: string | null,
      youtubeUrl?: string | null,
      goals?:  Array< {
        __typename: "AthleteGoal",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        videoUrl?: string | null,
        link?: string | null,
      } | null > | null,
      achievements?:  Array< {
        __typename: "AthleteAchievement",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        link?: string | null,
        videoUrl?: string | null,
        date?: string | null,
      } | null > | null,
      fundingGoals?:  Array< {
        __typename: "AthleteFundingGoal",
        id: string,
        amount: number,
        currency?: string | null,
        description: string,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "AthleteSponsor",
        id: string,
        imgS3Key: string,
      } | null > | null,
      emailValidated?: boolean | null,
      validationEmailSentAt?: string | null,
      potentialAthlete?: boolean | null,
      currency?: string | null,
      themeId?: string | null,
      role: string,
      nickname?: string | null,
      stripePayoutsEnabled?: boolean | null,
      payoutCurrency?: string | null,
      stats?:  {
        __typename: "UserStats",
        numUniqueViews?: number | null,
        numScouts?: number | null,
        paidByScouts?: number | null,
        numPosts?: number | null,
      } | null,
      features?:  {
        __typename: "UserFeatures",
        posts?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      ivsChannelArn?: string | null,
      createdAt: string,
      updatedAt: string,
      owner: string,
      email: string,
      stripeActionRequired?: boolean | null,
      stripeAccountId?: string | null,
      social?:  {
        __typename: "UserSocial",
        fbUserId?: string | null,
        fbAccessToken?: string | null,
        fbTargetPageId?: string | null,
        twitterRequestToken?: string | null,
        twitterRequestSecret?: string | null,
        twitterAccessToken?: string | null,
        twitterAccessSecret?: string | null,
        instagramUserId?: string | null,
      } | null,
      firebaseTokens?: Array< string | null > | null,
    } | null,
  } | null,
};

export type ListCommentsQueryVariables = {
  id?: string | null,
  filter?: ModelCommentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCommentsQuery = {
  listComments?:  {
    __typename: "ModelCommentConnection",
    items:  Array< {
      __typename: "Comment",
      id: string,
      authorId: string,
      parentId: string,
      parentType: CommentParentType,
      lastComment?:  {
        __typename: "Comment",
        id: string,
        authorId: string,
        parentId: string,
        parentType: CommentParentType,
        message: string,
        likes?: number | null,
        comments?: number | null,
        liked?: boolean | null,
        createdAt: string,
        updatedAt?: string | null,
      } | null,
      message: string,
      likes?: number | null,
      comments?: number | null,
      liked?: boolean | null,
      createdAt: string,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        name: string,
        givenName: string,
        familyName: string,
        gender?: string | null,
        birthdate?: string | null,
        country?: string | null,
        sport?: string | null,
        profileImgS3Key?: string | null,
        coverImgS3Key?: string | null,
        introVideoS3Key?: string | null,
        introVideoThumbS3Key?: string | null,
        introVideoUrl?: string | null,
        mission?: string | null,
        instagramUrl?: string | null,
        facebookUrl?: string | null,
        youtubeUrl?: string | null,
        emailValidated?: boolean | null,
        validationEmailSentAt?: string | null,
        potentialAthlete?: boolean | null,
        currency?: string | null,
        themeId?: string | null,
        role: string,
        nickname?: string | null,
        stripePayoutsEnabled?: boolean | null,
        payoutCurrency?: string | null,
        stripeCustomerId?: string | null,
        ivsChannelArn?: string | null,
        createdAt: string,
        updatedAt: string,
        owner: string,
        email: string,
        stripeActionRequired?: boolean | null,
        stripeAccountId?: string | null,
        firebaseTokens?: Array< string | null > | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListCommentsByParentQueryVariables = {
  parentId?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCommentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCommentsByParentQuery = {
  listCommentsByParent?:  {
    __typename: "ModelCommentConnection",
    items:  Array< {
      __typename: "Comment",
      id: string,
      authorId: string,
      parentId: string,
      parentType: CommentParentType,
      lastComment?:  {
        __typename: "Comment",
        id: string,
        authorId: string,
        parentId: string,
        parentType: CommentParentType,
        message: string,
        likes?: number | null,
        comments?: number | null,
        liked?: boolean | null,
        createdAt: string,
        updatedAt?: string | null,
      } | null,
      message: string,
      likes?: number | null,
      comments?: number | null,
      liked?: boolean | null,
      createdAt: string,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        name: string,
        givenName: string,
        familyName: string,
        gender?: string | null,
        birthdate?: string | null,
        country?: string | null,
        sport?: string | null,
        profileImgS3Key?: string | null,
        coverImgS3Key?: string | null,
        introVideoS3Key?: string | null,
        introVideoThumbS3Key?: string | null,
        introVideoUrl?: string | null,
        mission?: string | null,
        instagramUrl?: string | null,
        facebookUrl?: string | null,
        youtubeUrl?: string | null,
        emailValidated?: boolean | null,
        validationEmailSentAt?: string | null,
        potentialAthlete?: boolean | null,
        currency?: string | null,
        themeId?: string | null,
        role: string,
        nickname?: string | null,
        stripePayoutsEnabled?: boolean | null,
        payoutCurrency?: string | null,
        stripeCustomerId?: string | null,
        ivsChannelArn?: string | null,
        createdAt: string,
        updatedAt: string,
        owner: string,
        email: string,
        stripeActionRequired?: boolean | null,
        stripeAccountId?: string | null,
        firebaseTokens?: Array< string | null > | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMigrationsQueryVariables = {
  name: string,
};

export type GetMigrationsQuery = {
  getMigrations?:  {
    __typename: "Migrations",
    name: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListMigrationssQueryVariables = {
  name?: string | null,
  filter?: ModelMigrationsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListMigrationssQuery = {
  listMigrationss?:  {
    __typename: "ModelMigrationsConnection",
    items:  Array< {
      __typename: "Migrations",
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBcastQueryVariables = {
  id: string,
};

export type GetBcastQuery = {
  getBcast?:  {
    __typename: "Bcast",
    id: string,
    userId: string,
    title: string,
    description?: string | null,
    tierId: string,
    needsSubscription: boolean,
    thumbnailImageS3Key?: string | null,
    previewImageS3Key?: string | null,
    time: string,
    duration?: string | null,
    guests:  Array< {
      __typename: "BcastGuest",
      id: string,
      name: string,
    } >,
    allowQuestions: boolean,
    status: BcastStatus,
    bcastUrl?: string | null,
    recordingS3Key?: string | null,
    prepareArgs?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListBcastsQueryVariables = {
  id?: string | null,
  filter?: ModelBcastFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListBcastsQuery = {
  listBcasts?:  {
    __typename: "ModelBcastConnection",
    items:  Array< {
      __typename: "Bcast",
      id: string,
      userId: string,
      title: string,
      description?: string | null,
      tierId: string,
      needsSubscription: boolean,
      thumbnailImageS3Key?: string | null,
      previewImageS3Key?: string | null,
      time: string,
      duration?: string | null,
      guests:  Array< {
        __typename: "BcastGuest",
        id: string,
        name: string,
      } >,
      allowQuestions: boolean,
      status: BcastStatus,
      bcastUrl?: string | null,
      recordingS3Key?: string | null,
      prepareArgs?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListBcastsByUserIdQueryVariables = {
  userId?: string | null,
  time?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBcastFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBcastsByUserIdQuery = {
  listBcastsByUserId?:  {
    __typename: "ModelBcastConnection",
    items:  Array< {
      __typename: "Bcast",
      id: string,
      userId: string,
      title: string,
      description?: string | null,
      tierId: string,
      needsSubscription: boolean,
      thumbnailImageS3Key?: string | null,
      previewImageS3Key?: string | null,
      time: string,
      duration?: string | null,
      guests:  Array< {
        __typename: "BcastGuest",
        id: string,
        name: string,
      } >,
      allowQuestions: boolean,
      status: BcastStatus,
      bcastUrl?: string | null,
      recordingS3Key?: string | null,
      prepareArgs?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnUpdatePostByIdSubscriptionVariables = {
  id: string,
};

export type OnUpdatePostByIdSubscription = {
  onUpdatePostById?:  {
    __typename: "Post",
    id: string,
    authorId: string,
    name: string,
    description?: string | null,
    needsSubscription?: boolean | null,
    type: PostType,
    tierId: string,
    vodId?: string | null,
    vodStatus?: string | null,
    vodMetadata?:  {
      __typename: "PostVodMetadata",
      durationInMs?: number | null,
      orgWidth?: number | null,
      orgHeight?: number | null,
      hasPreview?: boolean | null,
    } | null,
    bcastMetadata?:  {
      __typename: "PostBcastMetadata",
      previewImageS3Key?: string | null,
      time: string,
      duration?: string | null,
      guests:  Array< {
        __typename: "BcastGuest",
        id: string,
        name: string,
      } >,
      allowQuestions: boolean,
      status: BcastStatus,
      bcastUrl?: string | null,
      recordingS3Key?: string | null,
    } | null,
    thumbS3Key?: string | null,
    likes?: number | null,
    comments?: number | null,
    liked?: boolean | null,
    reviewStatus?: PostReviewStatus | null,
    feedId?: string | null,
    createdAt: string,
    updatedAt: string,
    author?:  {
      __typename: "User",
      id: string,
      name: string,
      givenName: string,
      familyName: string,
      gender?: string | null,
      birthdate?: string | null,
      country?: string | null,
      sport?: string | null,
      profileImgS3Key?: string | null,
      coverImgS3Key?: string | null,
      introVideoS3Key?: string | null,
      introVideoThumbS3Key?: string | null,
      introVideoUrl?: string | null,
      mission?: string | null,
      instagramUrl?: string | null,
      facebookUrl?: string | null,
      youtubeUrl?: string | null,
      goals?:  Array< {
        __typename: "AthleteGoal",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        videoUrl?: string | null,
        link?: string | null,
      } | null > | null,
      achievements?:  Array< {
        __typename: "AthleteAchievement",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        link?: string | null,
        videoUrl?: string | null,
        date?: string | null,
      } | null > | null,
      fundingGoals?:  Array< {
        __typename: "AthleteFundingGoal",
        id: string,
        amount: number,
        currency?: string | null,
        description: string,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "AthleteSponsor",
        id: string,
        imgS3Key: string,
      } | null > | null,
      emailValidated?: boolean | null,
      validationEmailSentAt?: string | null,
      potentialAthlete?: boolean | null,
      currency?: string | null,
      themeId?: string | null,
      role: string,
      nickname?: string | null,
      stripePayoutsEnabled?: boolean | null,
      payoutCurrency?: string | null,
      stats?:  {
        __typename: "UserStats",
        numUniqueViews?: number | null,
        numScouts?: number | null,
        paidByScouts?: number | null,
        numPosts?: number | null,
      } | null,
      features?:  {
        __typename: "UserFeatures",
        posts?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      ivsChannelArn?: string | null,
      createdAt: string,
      updatedAt: string,
      owner: string,
      email: string,
      stripeActionRequired?: boolean | null,
      stripeAccountId?: string | null,
      social?:  {
        __typename: "UserSocial",
        fbUserId?: string | null,
        fbAccessToken?: string | null,
        fbTargetPageId?: string | null,
        twitterRequestToken?: string | null,
        twitterRequestSecret?: string | null,
        twitterAccessToken?: string | null,
        twitterAccessSecret?: string | null,
        instagramUserId?: string | null,
      } | null,
      firebaseTokens?: Array< string | null > | null,
    } | null,
    tier?:  {
      __typename: "Tier",
      id: string,
      userId: string,
      name: string,
      currency: string,
      unitAmount: number,
      discountType?: DiscountType | null,
      discount?: number | null,
      discountMonths?: number | null,
      couponId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnAthleteCreationSubscription = {
  onAthleteCreation?:  {
    __typename: "User",
    id: string,
    name: string,
    givenName: string,
    familyName: string,
    gender?: string | null,
    birthdate?: string | null,
    country?: string | null,
    sport?: string | null,
    profileImgS3Key?: string | null,
    coverImgS3Key?: string | null,
    introVideoS3Key?: string | null,
    introVideoThumbS3Key?: string | null,
    introVideoUrl?: string | null,
    mission?: string | null,
    instagramUrl?: string | null,
    facebookUrl?: string | null,
    youtubeUrl?: string | null,
    goals?:  Array< {
      __typename: "AthleteGoal",
      id: string,
      name: string,
      level?: string | null,
      description?: string | null,
      videoUrl?: string | null,
      link?: string | null,
    } | null > | null,
    achievements?:  Array< {
      __typename: "AthleteAchievement",
      id: string,
      name: string,
      level?: string | null,
      description?: string | null,
      link?: string | null,
      videoUrl?: string | null,
      date?: string | null,
    } | null > | null,
    fundingGoals?:  Array< {
      __typename: "AthleteFundingGoal",
      id: string,
      amount: number,
      currency?: string | null,
      description: string,
    } | null > | null,
    sponsors?:  Array< {
      __typename: "AthleteSponsor",
      id: string,
      imgS3Key: string,
    } | null > | null,
    emailValidated?: boolean | null,
    validationEmailSentAt?: string | null,
    potentialAthlete?: boolean | null,
    currency?: string | null,
    themeId?: string | null,
    role: string,
    nickname?: string | null,
    stripePayoutsEnabled?: boolean | null,
    payoutCurrency?: string | null,
    stats?:  {
      __typename: "UserStats",
      numUniqueViews?: number | null,
      numScouts?: number | null,
      paidByScouts?: number | null,
      numPosts?: number | null,
    } | null,
    features?:  {
      __typename: "UserFeatures",
      posts?: boolean | null,
    } | null,
    stripeCustomerId?: string | null,
    ivsChannelArn?: string | null,
    createdAt: string,
    updatedAt: string,
    owner: string,
    email: string,
    stripeActionRequired?: boolean | null,
    stripeAccountId?: string | null,
    social?:  {
      __typename: "UserSocial",
      fbUserId?: string | null,
      fbAccessToken?: string | null,
      fbTargetPageId?: string | null,
      twitterRequestToken?: string | null,
      twitterRequestSecret?: string | null,
      twitterAccessToken?: string | null,
      twitterAccessSecret?: string | null,
      instagramUserId?: string | null,
    } | null,
    firebaseTokens?: Array< string | null > | null,
  } | null,
};

export type OnCreateBcastSubscription = {
  onCreateBcast?:  {
    __typename: "Bcast",
    id: string,
    userId: string,
    title: string,
    description?: string | null,
    tierId: string,
    needsSubscription: boolean,
    thumbnailImageS3Key?: string | null,
    previewImageS3Key?: string | null,
    time: string,
    duration?: string | null,
    guests:  Array< {
      __typename: "BcastGuest",
      id: string,
      name: string,
    } >,
    allowQuestions: boolean,
    status: BcastStatus,
    bcastUrl?: string | null,
    recordingS3Key?: string | null,
    prepareArgs?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateBcastSubscription = {
  onUpdateBcast?:  {
    __typename: "UpdatedBcast",
    id: string,
    userId?: string | null,
    title?: string | null,
    description?: string | null,
    thumbnailImageS3Key?: string | null,
    previewImageS3Key?: string | null,
    time?: string | null,
    duration?: string | null,
    guests?: string | null,
    allowQuestions?: boolean | null,
    status?: BcastStatus | null,
    bcastUrl?: string | null,
    recordingS3Key?: string | null,
  } | null,
};

export type OnDeleteBcastSubscription = {
  onDeleteBcast?:  {
    __typename: "Bcast",
    id: string,
    userId: string,
    title: string,
    description?: string | null,
    tierId: string,
    needsSubscription: boolean,
    thumbnailImageS3Key?: string | null,
    previewImageS3Key?: string | null,
    time: string,
    duration?: string | null,
    guests:  Array< {
      __typename: "BcastGuest",
      id: string,
      name: string,
    } >,
    allowQuestions: boolean,
    status: BcastStatus,
    bcastUrl?: string | null,
    recordingS3Key?: string | null,
    prepareArgs?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreatePostSubscription = {
  onCreatePost?:  {
    __typename: "Post",
    id: string,
    authorId: string,
    name: string,
    description?: string | null,
    needsSubscription?: boolean | null,
    type: PostType,
    tierId: string,
    vodId?: string | null,
    vodStatus?: string | null,
    vodMetadata?:  {
      __typename: "PostVodMetadata",
      durationInMs?: number | null,
      orgWidth?: number | null,
      orgHeight?: number | null,
      hasPreview?: boolean | null,
    } | null,
    bcastMetadata?:  {
      __typename: "PostBcastMetadata",
      previewImageS3Key?: string | null,
      time: string,
      duration?: string | null,
      guests:  Array< {
        __typename: "BcastGuest",
        id: string,
        name: string,
      } >,
      allowQuestions: boolean,
      status: BcastStatus,
      bcastUrl?: string | null,
      recordingS3Key?: string | null,
    } | null,
    thumbS3Key?: string | null,
    likes?: number | null,
    comments?: number | null,
    liked?: boolean | null,
    reviewStatus?: PostReviewStatus | null,
    feedId?: string | null,
    createdAt: string,
    updatedAt: string,
    author?:  {
      __typename: "User",
      id: string,
      name: string,
      givenName: string,
      familyName: string,
      gender?: string | null,
      birthdate?: string | null,
      country?: string | null,
      sport?: string | null,
      profileImgS3Key?: string | null,
      coverImgS3Key?: string | null,
      introVideoS3Key?: string | null,
      introVideoThumbS3Key?: string | null,
      introVideoUrl?: string | null,
      mission?: string | null,
      instagramUrl?: string | null,
      facebookUrl?: string | null,
      youtubeUrl?: string | null,
      goals?:  Array< {
        __typename: "AthleteGoal",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        videoUrl?: string | null,
        link?: string | null,
      } | null > | null,
      achievements?:  Array< {
        __typename: "AthleteAchievement",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        link?: string | null,
        videoUrl?: string | null,
        date?: string | null,
      } | null > | null,
      fundingGoals?:  Array< {
        __typename: "AthleteFundingGoal",
        id: string,
        amount: number,
        currency?: string | null,
        description: string,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "AthleteSponsor",
        id: string,
        imgS3Key: string,
      } | null > | null,
      emailValidated?: boolean | null,
      validationEmailSentAt?: string | null,
      potentialAthlete?: boolean | null,
      currency?: string | null,
      themeId?: string | null,
      role: string,
      nickname?: string | null,
      stripePayoutsEnabled?: boolean | null,
      payoutCurrency?: string | null,
      stats?:  {
        __typename: "UserStats",
        numUniqueViews?: number | null,
        numScouts?: number | null,
        paidByScouts?: number | null,
        numPosts?: number | null,
      } | null,
      features?:  {
        __typename: "UserFeatures",
        posts?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      ivsChannelArn?: string | null,
      createdAt: string,
      updatedAt: string,
      owner: string,
      email: string,
      stripeActionRequired?: boolean | null,
      stripeAccountId?: string | null,
      social?:  {
        __typename: "UserSocial",
        fbUserId?: string | null,
        fbAccessToken?: string | null,
        fbTargetPageId?: string | null,
        twitterRequestToken?: string | null,
        twitterRequestSecret?: string | null,
        twitterAccessToken?: string | null,
        twitterAccessSecret?: string | null,
        instagramUserId?: string | null,
      } | null,
      firebaseTokens?: Array< string | null > | null,
    } | null,
    tier?:  {
      __typename: "Tier",
      id: string,
      userId: string,
      name: string,
      currency: string,
      unitAmount: number,
      discountType?: DiscountType | null,
      discount?: number | null,
      discountMonths?: number | null,
      couponId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnUpdatePostSubscription = {
  onUpdatePost?:  {
    __typename: "Post",
    id: string,
    authorId: string,
    name: string,
    description?: string | null,
    needsSubscription?: boolean | null,
    type: PostType,
    tierId: string,
    vodId?: string | null,
    vodStatus?: string | null,
    vodMetadata?:  {
      __typename: "PostVodMetadata",
      durationInMs?: number | null,
      orgWidth?: number | null,
      orgHeight?: number | null,
      hasPreview?: boolean | null,
    } | null,
    bcastMetadata?:  {
      __typename: "PostBcastMetadata",
      previewImageS3Key?: string | null,
      time: string,
      duration?: string | null,
      guests:  Array< {
        __typename: "BcastGuest",
        id: string,
        name: string,
      } >,
      allowQuestions: boolean,
      status: BcastStatus,
      bcastUrl?: string | null,
      recordingS3Key?: string | null,
    } | null,
    thumbS3Key?: string | null,
    likes?: number | null,
    comments?: number | null,
    liked?: boolean | null,
    reviewStatus?: PostReviewStatus | null,
    feedId?: string | null,
    createdAt: string,
    updatedAt: string,
    author?:  {
      __typename: "User",
      id: string,
      name: string,
      givenName: string,
      familyName: string,
      gender?: string | null,
      birthdate?: string | null,
      country?: string | null,
      sport?: string | null,
      profileImgS3Key?: string | null,
      coverImgS3Key?: string | null,
      introVideoS3Key?: string | null,
      introVideoThumbS3Key?: string | null,
      introVideoUrl?: string | null,
      mission?: string | null,
      instagramUrl?: string | null,
      facebookUrl?: string | null,
      youtubeUrl?: string | null,
      goals?:  Array< {
        __typename: "AthleteGoal",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        videoUrl?: string | null,
        link?: string | null,
      } | null > | null,
      achievements?:  Array< {
        __typename: "AthleteAchievement",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        link?: string | null,
        videoUrl?: string | null,
        date?: string | null,
      } | null > | null,
      fundingGoals?:  Array< {
        __typename: "AthleteFundingGoal",
        id: string,
        amount: number,
        currency?: string | null,
        description: string,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "AthleteSponsor",
        id: string,
        imgS3Key: string,
      } | null > | null,
      emailValidated?: boolean | null,
      validationEmailSentAt?: string | null,
      potentialAthlete?: boolean | null,
      currency?: string | null,
      themeId?: string | null,
      role: string,
      nickname?: string | null,
      stripePayoutsEnabled?: boolean | null,
      payoutCurrency?: string | null,
      stats?:  {
        __typename: "UserStats",
        numUniqueViews?: number | null,
        numScouts?: number | null,
        paidByScouts?: number | null,
        numPosts?: number | null,
      } | null,
      features?:  {
        __typename: "UserFeatures",
        posts?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      ivsChannelArn?: string | null,
      createdAt: string,
      updatedAt: string,
      owner: string,
      email: string,
      stripeActionRequired?: boolean | null,
      stripeAccountId?: string | null,
      social?:  {
        __typename: "UserSocial",
        fbUserId?: string | null,
        fbAccessToken?: string | null,
        fbTargetPageId?: string | null,
        twitterRequestToken?: string | null,
        twitterRequestSecret?: string | null,
        twitterAccessToken?: string | null,
        twitterAccessSecret?: string | null,
        instagramUserId?: string | null,
      } | null,
      firebaseTokens?: Array< string | null > | null,
    } | null,
    tier?:  {
      __typename: "Tier",
      id: string,
      userId: string,
      name: string,
      currency: string,
      unitAmount: number,
      discountType?: DiscountType | null,
      discount?: number | null,
      discountMonths?: number | null,
      couponId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnDeletePostSubscription = {
  onDeletePost?:  {
    __typename: "Post",
    id: string,
    authorId: string,
    name: string,
    description?: string | null,
    needsSubscription?: boolean | null,
    type: PostType,
    tierId: string,
    vodId?: string | null,
    vodStatus?: string | null,
    vodMetadata?:  {
      __typename: "PostVodMetadata",
      durationInMs?: number | null,
      orgWidth?: number | null,
      orgHeight?: number | null,
      hasPreview?: boolean | null,
    } | null,
    bcastMetadata?:  {
      __typename: "PostBcastMetadata",
      previewImageS3Key?: string | null,
      time: string,
      duration?: string | null,
      guests:  Array< {
        __typename: "BcastGuest",
        id: string,
        name: string,
      } >,
      allowQuestions: boolean,
      status: BcastStatus,
      bcastUrl?: string | null,
      recordingS3Key?: string | null,
    } | null,
    thumbS3Key?: string | null,
    likes?: number | null,
    comments?: number | null,
    liked?: boolean | null,
    reviewStatus?: PostReviewStatus | null,
    feedId?: string | null,
    createdAt: string,
    updatedAt: string,
    author?:  {
      __typename: "User",
      id: string,
      name: string,
      givenName: string,
      familyName: string,
      gender?: string | null,
      birthdate?: string | null,
      country?: string | null,
      sport?: string | null,
      profileImgS3Key?: string | null,
      coverImgS3Key?: string | null,
      introVideoS3Key?: string | null,
      introVideoThumbS3Key?: string | null,
      introVideoUrl?: string | null,
      mission?: string | null,
      instagramUrl?: string | null,
      facebookUrl?: string | null,
      youtubeUrl?: string | null,
      goals?:  Array< {
        __typename: "AthleteGoal",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        videoUrl?: string | null,
        link?: string | null,
      } | null > | null,
      achievements?:  Array< {
        __typename: "AthleteAchievement",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        link?: string | null,
        videoUrl?: string | null,
        date?: string | null,
      } | null > | null,
      fundingGoals?:  Array< {
        __typename: "AthleteFundingGoal",
        id: string,
        amount: number,
        currency?: string | null,
        description: string,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "AthleteSponsor",
        id: string,
        imgS3Key: string,
      } | null > | null,
      emailValidated?: boolean | null,
      validationEmailSentAt?: string | null,
      potentialAthlete?: boolean | null,
      currency?: string | null,
      themeId?: string | null,
      role: string,
      nickname?: string | null,
      stripePayoutsEnabled?: boolean | null,
      payoutCurrency?: string | null,
      stats?:  {
        __typename: "UserStats",
        numUniqueViews?: number | null,
        numScouts?: number | null,
        paidByScouts?: number | null,
        numPosts?: number | null,
      } | null,
      features?:  {
        __typename: "UserFeatures",
        posts?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      ivsChannelArn?: string | null,
      createdAt: string,
      updatedAt: string,
      owner: string,
      email: string,
      stripeActionRequired?: boolean | null,
      stripeAccountId?: string | null,
      social?:  {
        __typename: "UserSocial",
        fbUserId?: string | null,
        fbAccessToken?: string | null,
        fbTargetPageId?: string | null,
        twitterRequestToken?: string | null,
        twitterRequestSecret?: string | null,
        twitterAccessToken?: string | null,
        twitterAccessSecret?: string | null,
        instagramUserId?: string | null,
      } | null,
      firebaseTokens?: Array< string | null > | null,
    } | null,
    tier?:  {
      __typename: "Tier",
      id: string,
      userId: string,
      name: string,
      currency: string,
      unitAmount: number,
      discountType?: DiscountType | null,
      discount?: number | null,
      discountMonths?: number | null,
      couponId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnCreateAthleteSubscriptionSubscriptionVariables = {
  subscriberId?: string | null,
};

export type OnCreateAthleteSubscriptionSubscription = {
  onCreateAthleteSubscription?:  {
    __typename: "AthleteSubscription",
    subscriberId: string,
    athleteId: string,
    tierId?: string | null,
    currency: string,
    unitAmount: number,
    paidToAthlete: number,
    status?: SubscriptionStatus | null,
    cancelAtPeriodEnd?: boolean | null,
    currentPeriodEnd?: number | null,
    expiresAt: string,
    discountType?: DiscountType | null,
    discount?: number | null,
    discountRemainingMonths?: number | null,
    couponId?: string | null,
    taxRateId?: string | null,
    updatedAt: string,
    createdAt: string,
    stripeSubscriptionId?: string | null,
    stripePriceId?: string | null,
    subscriber?:  {
      __typename: "User",
      id: string,
      name: string,
      givenName: string,
      familyName: string,
      gender?: string | null,
      birthdate?: string | null,
      country?: string | null,
      sport?: string | null,
      profileImgS3Key?: string | null,
      coverImgS3Key?: string | null,
      introVideoS3Key?: string | null,
      introVideoThumbS3Key?: string | null,
      introVideoUrl?: string | null,
      mission?: string | null,
      instagramUrl?: string | null,
      facebookUrl?: string | null,
      youtubeUrl?: string | null,
      goals?:  Array< {
        __typename: "AthleteGoal",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        videoUrl?: string | null,
        link?: string | null,
      } | null > | null,
      achievements?:  Array< {
        __typename: "AthleteAchievement",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        link?: string | null,
        videoUrl?: string | null,
        date?: string | null,
      } | null > | null,
      fundingGoals?:  Array< {
        __typename: "AthleteFundingGoal",
        id: string,
        amount: number,
        currency?: string | null,
        description: string,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "AthleteSponsor",
        id: string,
        imgS3Key: string,
      } | null > | null,
      emailValidated?: boolean | null,
      validationEmailSentAt?: string | null,
      potentialAthlete?: boolean | null,
      currency?: string | null,
      themeId?: string | null,
      role: string,
      nickname?: string | null,
      stripePayoutsEnabled?: boolean | null,
      payoutCurrency?: string | null,
      stats?:  {
        __typename: "UserStats",
        numUniqueViews?: number | null,
        numScouts?: number | null,
        paidByScouts?: number | null,
        numPosts?: number | null,
      } | null,
      features?:  {
        __typename: "UserFeatures",
        posts?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      ivsChannelArn?: string | null,
      createdAt: string,
      updatedAt: string,
      owner: string,
      email: string,
      stripeActionRequired?: boolean | null,
      stripeAccountId?: string | null,
      social?:  {
        __typename: "UserSocial",
        fbUserId?: string | null,
        fbAccessToken?: string | null,
        fbTargetPageId?: string | null,
        twitterRequestToken?: string | null,
        twitterRequestSecret?: string | null,
        twitterAccessToken?: string | null,
        twitterAccessSecret?: string | null,
        instagramUserId?: string | null,
      } | null,
      firebaseTokens?: Array< string | null > | null,
    } | null,
    athlete?:  {
      __typename: "User",
      id: string,
      name: string,
      givenName: string,
      familyName: string,
      gender?: string | null,
      birthdate?: string | null,
      country?: string | null,
      sport?: string | null,
      profileImgS3Key?: string | null,
      coverImgS3Key?: string | null,
      introVideoS3Key?: string | null,
      introVideoThumbS3Key?: string | null,
      introVideoUrl?: string | null,
      mission?: string | null,
      instagramUrl?: string | null,
      facebookUrl?: string | null,
      youtubeUrl?: string | null,
      goals?:  Array< {
        __typename: "AthleteGoal",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        videoUrl?: string | null,
        link?: string | null,
      } | null > | null,
      achievements?:  Array< {
        __typename: "AthleteAchievement",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        link?: string | null,
        videoUrl?: string | null,
        date?: string | null,
      } | null > | null,
      fundingGoals?:  Array< {
        __typename: "AthleteFundingGoal",
        id: string,
        amount: number,
        currency?: string | null,
        description: string,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "AthleteSponsor",
        id: string,
        imgS3Key: string,
      } | null > | null,
      emailValidated?: boolean | null,
      validationEmailSentAt?: string | null,
      potentialAthlete?: boolean | null,
      currency?: string | null,
      themeId?: string | null,
      role: string,
      nickname?: string | null,
      stripePayoutsEnabled?: boolean | null,
      payoutCurrency?: string | null,
      stats?:  {
        __typename: "UserStats",
        numUniqueViews?: number | null,
        numScouts?: number | null,
        paidByScouts?: number | null,
        numPosts?: number | null,
      } | null,
      features?:  {
        __typename: "UserFeatures",
        posts?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      ivsChannelArn?: string | null,
      createdAt: string,
      updatedAt: string,
      owner: string,
      email: string,
      stripeActionRequired?: boolean | null,
      stripeAccountId?: string | null,
      social?:  {
        __typename: "UserSocial",
        fbUserId?: string | null,
        fbAccessToken?: string | null,
        fbTargetPageId?: string | null,
        twitterRequestToken?: string | null,
        twitterRequestSecret?: string | null,
        twitterAccessToken?: string | null,
        twitterAccessSecret?: string | null,
        instagramUserId?: string | null,
      } | null,
      firebaseTokens?: Array< string | null > | null,
    } | null,
    tier?:  {
      __typename: "Tier",
      id: string,
      userId: string,
      name: string,
      currency: string,
      unitAmount: number,
      discountType?: DiscountType | null,
      discount?: number | null,
      discountMonths?: number | null,
      couponId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnUpdateAthleteSubscriptionSubscriptionVariables = {
  subscriberId?: string | null,
};

export type OnUpdateAthleteSubscriptionSubscription = {
  onUpdateAthleteSubscription?:  {
    __typename: "AthleteSubscription",
    subscriberId: string,
    athleteId: string,
    tierId?: string | null,
    currency: string,
    unitAmount: number,
    paidToAthlete: number,
    status?: SubscriptionStatus | null,
    cancelAtPeriodEnd?: boolean | null,
    currentPeriodEnd?: number | null,
    expiresAt: string,
    discountType?: DiscountType | null,
    discount?: number | null,
    discountRemainingMonths?: number | null,
    couponId?: string | null,
    taxRateId?: string | null,
    updatedAt: string,
    createdAt: string,
    stripeSubscriptionId?: string | null,
    stripePriceId?: string | null,
    subscriber?:  {
      __typename: "User",
      id: string,
      name: string,
      givenName: string,
      familyName: string,
      gender?: string | null,
      birthdate?: string | null,
      country?: string | null,
      sport?: string | null,
      profileImgS3Key?: string | null,
      coverImgS3Key?: string | null,
      introVideoS3Key?: string | null,
      introVideoThumbS3Key?: string | null,
      introVideoUrl?: string | null,
      mission?: string | null,
      instagramUrl?: string | null,
      facebookUrl?: string | null,
      youtubeUrl?: string | null,
      goals?:  Array< {
        __typename: "AthleteGoal",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        videoUrl?: string | null,
        link?: string | null,
      } | null > | null,
      achievements?:  Array< {
        __typename: "AthleteAchievement",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        link?: string | null,
        videoUrl?: string | null,
        date?: string | null,
      } | null > | null,
      fundingGoals?:  Array< {
        __typename: "AthleteFundingGoal",
        id: string,
        amount: number,
        currency?: string | null,
        description: string,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "AthleteSponsor",
        id: string,
        imgS3Key: string,
      } | null > | null,
      emailValidated?: boolean | null,
      validationEmailSentAt?: string | null,
      potentialAthlete?: boolean | null,
      currency?: string | null,
      themeId?: string | null,
      role: string,
      nickname?: string | null,
      stripePayoutsEnabled?: boolean | null,
      payoutCurrency?: string | null,
      stats?:  {
        __typename: "UserStats",
        numUniqueViews?: number | null,
        numScouts?: number | null,
        paidByScouts?: number | null,
        numPosts?: number | null,
      } | null,
      features?:  {
        __typename: "UserFeatures",
        posts?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      ivsChannelArn?: string | null,
      createdAt: string,
      updatedAt: string,
      owner: string,
      email: string,
      stripeActionRequired?: boolean | null,
      stripeAccountId?: string | null,
      social?:  {
        __typename: "UserSocial",
        fbUserId?: string | null,
        fbAccessToken?: string | null,
        fbTargetPageId?: string | null,
        twitterRequestToken?: string | null,
        twitterRequestSecret?: string | null,
        twitterAccessToken?: string | null,
        twitterAccessSecret?: string | null,
        instagramUserId?: string | null,
      } | null,
      firebaseTokens?: Array< string | null > | null,
    } | null,
    athlete?:  {
      __typename: "User",
      id: string,
      name: string,
      givenName: string,
      familyName: string,
      gender?: string | null,
      birthdate?: string | null,
      country?: string | null,
      sport?: string | null,
      profileImgS3Key?: string | null,
      coverImgS3Key?: string | null,
      introVideoS3Key?: string | null,
      introVideoThumbS3Key?: string | null,
      introVideoUrl?: string | null,
      mission?: string | null,
      instagramUrl?: string | null,
      facebookUrl?: string | null,
      youtubeUrl?: string | null,
      goals?:  Array< {
        __typename: "AthleteGoal",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        videoUrl?: string | null,
        link?: string | null,
      } | null > | null,
      achievements?:  Array< {
        __typename: "AthleteAchievement",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        link?: string | null,
        videoUrl?: string | null,
        date?: string | null,
      } | null > | null,
      fundingGoals?:  Array< {
        __typename: "AthleteFundingGoal",
        id: string,
        amount: number,
        currency?: string | null,
        description: string,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "AthleteSponsor",
        id: string,
        imgS3Key: string,
      } | null > | null,
      emailValidated?: boolean | null,
      validationEmailSentAt?: string | null,
      potentialAthlete?: boolean | null,
      currency?: string | null,
      themeId?: string | null,
      role: string,
      nickname?: string | null,
      stripePayoutsEnabled?: boolean | null,
      payoutCurrency?: string | null,
      stats?:  {
        __typename: "UserStats",
        numUniqueViews?: number | null,
        numScouts?: number | null,
        paidByScouts?: number | null,
        numPosts?: number | null,
      } | null,
      features?:  {
        __typename: "UserFeatures",
        posts?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      ivsChannelArn?: string | null,
      createdAt: string,
      updatedAt: string,
      owner: string,
      email: string,
      stripeActionRequired?: boolean | null,
      stripeAccountId?: string | null,
      social?:  {
        __typename: "UserSocial",
        fbUserId?: string | null,
        fbAccessToken?: string | null,
        fbTargetPageId?: string | null,
        twitterRequestToken?: string | null,
        twitterRequestSecret?: string | null,
        twitterAccessToken?: string | null,
        twitterAccessSecret?: string | null,
        instagramUserId?: string | null,
      } | null,
      firebaseTokens?: Array< string | null > | null,
    } | null,
    tier?:  {
      __typename: "Tier",
      id: string,
      userId: string,
      name: string,
      currency: string,
      unitAmount: number,
      discountType?: DiscountType | null,
      discount?: number | null,
      discountMonths?: number | null,
      couponId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnDeleteAthleteSubscriptionSubscriptionVariables = {
  subscriberId?: string | null,
};

export type OnDeleteAthleteSubscriptionSubscription = {
  onDeleteAthleteSubscription?:  {
    __typename: "AthleteSubscription",
    subscriberId: string,
    athleteId: string,
    tierId?: string | null,
    currency: string,
    unitAmount: number,
    paidToAthlete: number,
    status?: SubscriptionStatus | null,
    cancelAtPeriodEnd?: boolean | null,
    currentPeriodEnd?: number | null,
    expiresAt: string,
    discountType?: DiscountType | null,
    discount?: number | null,
    discountRemainingMonths?: number | null,
    couponId?: string | null,
    taxRateId?: string | null,
    updatedAt: string,
    createdAt: string,
    stripeSubscriptionId?: string | null,
    stripePriceId?: string | null,
    subscriber?:  {
      __typename: "User",
      id: string,
      name: string,
      givenName: string,
      familyName: string,
      gender?: string | null,
      birthdate?: string | null,
      country?: string | null,
      sport?: string | null,
      profileImgS3Key?: string | null,
      coverImgS3Key?: string | null,
      introVideoS3Key?: string | null,
      introVideoThumbS3Key?: string | null,
      introVideoUrl?: string | null,
      mission?: string | null,
      instagramUrl?: string | null,
      facebookUrl?: string | null,
      youtubeUrl?: string | null,
      goals?:  Array< {
        __typename: "AthleteGoal",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        videoUrl?: string | null,
        link?: string | null,
      } | null > | null,
      achievements?:  Array< {
        __typename: "AthleteAchievement",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        link?: string | null,
        videoUrl?: string | null,
        date?: string | null,
      } | null > | null,
      fundingGoals?:  Array< {
        __typename: "AthleteFundingGoal",
        id: string,
        amount: number,
        currency?: string | null,
        description: string,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "AthleteSponsor",
        id: string,
        imgS3Key: string,
      } | null > | null,
      emailValidated?: boolean | null,
      validationEmailSentAt?: string | null,
      potentialAthlete?: boolean | null,
      currency?: string | null,
      themeId?: string | null,
      role: string,
      nickname?: string | null,
      stripePayoutsEnabled?: boolean | null,
      payoutCurrency?: string | null,
      stats?:  {
        __typename: "UserStats",
        numUniqueViews?: number | null,
        numScouts?: number | null,
        paidByScouts?: number | null,
        numPosts?: number | null,
      } | null,
      features?:  {
        __typename: "UserFeatures",
        posts?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      ivsChannelArn?: string | null,
      createdAt: string,
      updatedAt: string,
      owner: string,
      email: string,
      stripeActionRequired?: boolean | null,
      stripeAccountId?: string | null,
      social?:  {
        __typename: "UserSocial",
        fbUserId?: string | null,
        fbAccessToken?: string | null,
        fbTargetPageId?: string | null,
        twitterRequestToken?: string | null,
        twitterRequestSecret?: string | null,
        twitterAccessToken?: string | null,
        twitterAccessSecret?: string | null,
        instagramUserId?: string | null,
      } | null,
      firebaseTokens?: Array< string | null > | null,
    } | null,
    athlete?:  {
      __typename: "User",
      id: string,
      name: string,
      givenName: string,
      familyName: string,
      gender?: string | null,
      birthdate?: string | null,
      country?: string | null,
      sport?: string | null,
      profileImgS3Key?: string | null,
      coverImgS3Key?: string | null,
      introVideoS3Key?: string | null,
      introVideoThumbS3Key?: string | null,
      introVideoUrl?: string | null,
      mission?: string | null,
      instagramUrl?: string | null,
      facebookUrl?: string | null,
      youtubeUrl?: string | null,
      goals?:  Array< {
        __typename: "AthleteGoal",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        videoUrl?: string | null,
        link?: string | null,
      } | null > | null,
      achievements?:  Array< {
        __typename: "AthleteAchievement",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        link?: string | null,
        videoUrl?: string | null,
        date?: string | null,
      } | null > | null,
      fundingGoals?:  Array< {
        __typename: "AthleteFundingGoal",
        id: string,
        amount: number,
        currency?: string | null,
        description: string,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "AthleteSponsor",
        id: string,
        imgS3Key: string,
      } | null > | null,
      emailValidated?: boolean | null,
      validationEmailSentAt?: string | null,
      potentialAthlete?: boolean | null,
      currency?: string | null,
      themeId?: string | null,
      role: string,
      nickname?: string | null,
      stripePayoutsEnabled?: boolean | null,
      payoutCurrency?: string | null,
      stats?:  {
        __typename: "UserStats",
        numUniqueViews?: number | null,
        numScouts?: number | null,
        paidByScouts?: number | null,
        numPosts?: number | null,
      } | null,
      features?:  {
        __typename: "UserFeatures",
        posts?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      ivsChannelArn?: string | null,
      createdAt: string,
      updatedAt: string,
      owner: string,
      email: string,
      stripeActionRequired?: boolean | null,
      stripeAccountId?: string | null,
      social?:  {
        __typename: "UserSocial",
        fbUserId?: string | null,
        fbAccessToken?: string | null,
        fbTargetPageId?: string | null,
        twitterRequestToken?: string | null,
        twitterRequestSecret?: string | null,
        twitterAccessToken?: string | null,
        twitterAccessSecret?: string | null,
        instagramUserId?: string | null,
      } | null,
      firebaseTokens?: Array< string | null > | null,
    } | null,
    tier?:  {
      __typename: "Tier",
      id: string,
      userId: string,
      name: string,
      currency: string,
      unitAmount: number,
      discountType?: DiscountType | null,
      discount?: number | null,
      discountMonths?: number | null,
      couponId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnCreateFlashRequestSubscriptionVariables = {
  userId?: string | null,
};

export type OnCreateFlashRequestSubscription = {
  onCreateFlashRequest?:  {
    __typename: "FlashRequest",
    userId: string,
    request: FlashRequestType,
    targetId: string,
    payload?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateFlashRequestSubscriptionVariables = {
  userId?: string | null,
};

export type OnUpdateFlashRequestSubscription = {
  onUpdateFlashRequest?:  {
    __typename: "FlashRequest",
    userId: string,
    request: FlashRequestType,
    targetId: string,
    payload?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteFlashRequestSubscriptionVariables = {
  userId?: string | null,
};

export type OnDeleteFlashRequestSubscription = {
  onDeleteFlashRequest?:  {
    __typename: "FlashRequest",
    userId: string,
    request: FlashRequestType,
    targetId: string,
    payload?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateLikeSubscriptionVariables = {
  userId?: string | null,
};

export type OnCreateLikeSubscription = {
  onCreateLike?:  {
    __typename: "Like",
    userId: string,
    targetId: string,
    targetType: LikeTargetType,
    createdAt: string,
    updatedAt: string,
    user?:  {
      __typename: "User",
      id: string,
      name: string,
      givenName: string,
      familyName: string,
      gender?: string | null,
      birthdate?: string | null,
      country?: string | null,
      sport?: string | null,
      profileImgS3Key?: string | null,
      coverImgS3Key?: string | null,
      introVideoS3Key?: string | null,
      introVideoThumbS3Key?: string | null,
      introVideoUrl?: string | null,
      mission?: string | null,
      instagramUrl?: string | null,
      facebookUrl?: string | null,
      youtubeUrl?: string | null,
      goals?:  Array< {
        __typename: "AthleteGoal",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        videoUrl?: string | null,
        link?: string | null,
      } | null > | null,
      achievements?:  Array< {
        __typename: "AthleteAchievement",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        link?: string | null,
        videoUrl?: string | null,
        date?: string | null,
      } | null > | null,
      fundingGoals?:  Array< {
        __typename: "AthleteFundingGoal",
        id: string,
        amount: number,
        currency?: string | null,
        description: string,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "AthleteSponsor",
        id: string,
        imgS3Key: string,
      } | null > | null,
      emailValidated?: boolean | null,
      validationEmailSentAt?: string | null,
      potentialAthlete?: boolean | null,
      currency?: string | null,
      themeId?: string | null,
      role: string,
      nickname?: string | null,
      stripePayoutsEnabled?: boolean | null,
      payoutCurrency?: string | null,
      stats?:  {
        __typename: "UserStats",
        numUniqueViews?: number | null,
        numScouts?: number | null,
        paidByScouts?: number | null,
        numPosts?: number | null,
      } | null,
      features?:  {
        __typename: "UserFeatures",
        posts?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      ivsChannelArn?: string | null,
      createdAt: string,
      updatedAt: string,
      owner: string,
      email: string,
      stripeActionRequired?: boolean | null,
      stripeAccountId?: string | null,
      social?:  {
        __typename: "UserSocial",
        fbUserId?: string | null,
        fbAccessToken?: string | null,
        fbTargetPageId?: string | null,
        twitterRequestToken?: string | null,
        twitterRequestSecret?: string | null,
        twitterAccessToken?: string | null,
        twitterAccessSecret?: string | null,
        instagramUserId?: string | null,
      } | null,
      firebaseTokens?: Array< string | null > | null,
    } | null,
  } | null,
};

export type OnUpdateLikeSubscriptionVariables = {
  userId?: string | null,
};

export type OnUpdateLikeSubscription = {
  onUpdateLike?:  {
    __typename: "Like",
    userId: string,
    targetId: string,
    targetType: LikeTargetType,
    createdAt: string,
    updatedAt: string,
    user?:  {
      __typename: "User",
      id: string,
      name: string,
      givenName: string,
      familyName: string,
      gender?: string | null,
      birthdate?: string | null,
      country?: string | null,
      sport?: string | null,
      profileImgS3Key?: string | null,
      coverImgS3Key?: string | null,
      introVideoS3Key?: string | null,
      introVideoThumbS3Key?: string | null,
      introVideoUrl?: string | null,
      mission?: string | null,
      instagramUrl?: string | null,
      facebookUrl?: string | null,
      youtubeUrl?: string | null,
      goals?:  Array< {
        __typename: "AthleteGoal",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        videoUrl?: string | null,
        link?: string | null,
      } | null > | null,
      achievements?:  Array< {
        __typename: "AthleteAchievement",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        link?: string | null,
        videoUrl?: string | null,
        date?: string | null,
      } | null > | null,
      fundingGoals?:  Array< {
        __typename: "AthleteFundingGoal",
        id: string,
        amount: number,
        currency?: string | null,
        description: string,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "AthleteSponsor",
        id: string,
        imgS3Key: string,
      } | null > | null,
      emailValidated?: boolean | null,
      validationEmailSentAt?: string | null,
      potentialAthlete?: boolean | null,
      currency?: string | null,
      themeId?: string | null,
      role: string,
      nickname?: string | null,
      stripePayoutsEnabled?: boolean | null,
      payoutCurrency?: string | null,
      stats?:  {
        __typename: "UserStats",
        numUniqueViews?: number | null,
        numScouts?: number | null,
        paidByScouts?: number | null,
        numPosts?: number | null,
      } | null,
      features?:  {
        __typename: "UserFeatures",
        posts?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      ivsChannelArn?: string | null,
      createdAt: string,
      updatedAt: string,
      owner: string,
      email: string,
      stripeActionRequired?: boolean | null,
      stripeAccountId?: string | null,
      social?:  {
        __typename: "UserSocial",
        fbUserId?: string | null,
        fbAccessToken?: string | null,
        fbTargetPageId?: string | null,
        twitterRequestToken?: string | null,
        twitterRequestSecret?: string | null,
        twitterAccessToken?: string | null,
        twitterAccessSecret?: string | null,
        instagramUserId?: string | null,
      } | null,
      firebaseTokens?: Array< string | null > | null,
    } | null,
  } | null,
};

export type OnDeleteLikeSubscriptionVariables = {
  userId?: string | null,
};

export type OnDeleteLikeSubscription = {
  onDeleteLike?:  {
    __typename: "Like",
    userId: string,
    targetId: string,
    targetType: LikeTargetType,
    createdAt: string,
    updatedAt: string,
    user?:  {
      __typename: "User",
      id: string,
      name: string,
      givenName: string,
      familyName: string,
      gender?: string | null,
      birthdate?: string | null,
      country?: string | null,
      sport?: string | null,
      profileImgS3Key?: string | null,
      coverImgS3Key?: string | null,
      introVideoS3Key?: string | null,
      introVideoThumbS3Key?: string | null,
      introVideoUrl?: string | null,
      mission?: string | null,
      instagramUrl?: string | null,
      facebookUrl?: string | null,
      youtubeUrl?: string | null,
      goals?:  Array< {
        __typename: "AthleteGoal",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        videoUrl?: string | null,
        link?: string | null,
      } | null > | null,
      achievements?:  Array< {
        __typename: "AthleteAchievement",
        id: string,
        name: string,
        level?: string | null,
        description?: string | null,
        link?: string | null,
        videoUrl?: string | null,
        date?: string | null,
      } | null > | null,
      fundingGoals?:  Array< {
        __typename: "AthleteFundingGoal",
        id: string,
        amount: number,
        currency?: string | null,
        description: string,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "AthleteSponsor",
        id: string,
        imgS3Key: string,
      } | null > | null,
      emailValidated?: boolean | null,
      validationEmailSentAt?: string | null,
      potentialAthlete?: boolean | null,
      currency?: string | null,
      themeId?: string | null,
      role: string,
      nickname?: string | null,
      stripePayoutsEnabled?: boolean | null,
      payoutCurrency?: string | null,
      stats?:  {
        __typename: "UserStats",
        numUniqueViews?: number | null,
        numScouts?: number | null,
        paidByScouts?: number | null,
        numPosts?: number | null,
      } | null,
      features?:  {
        __typename: "UserFeatures",
        posts?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      ivsChannelArn?: string | null,
      createdAt: string,
      updatedAt: string,
      owner: string,
      email: string,
      stripeActionRequired?: boolean | null,
      stripeAccountId?: string | null,
      social?:  {
        __typename: "UserSocial",
        fbUserId?: string | null,
        fbAccessToken?: string | null,
        fbTargetPageId?: string | null,
        twitterRequestToken?: string | null,
        twitterRequestSecret?: string | null,
        twitterAccessToken?: string | null,
        twitterAccessSecret?: string | null,
        instagramUserId?: string | null,
      } | null,
      firebaseTokens?: Array< string | null > | null,
    } | null,
  } | null,
};

export type OnCreateMigrationsSubscription = {
  onCreateMigrations?:  {
    __typename: "Migrations",
    name: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateMigrationsSubscription = {
  onUpdateMigrations?:  {
    __typename: "Migrations",
    name: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteMigrationsSubscription = {
  onDeleteMigrations?:  {
    __typename: "Migrations",
    name: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};
