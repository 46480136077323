import {
  AccountBusinessProfile,
  AccountRequirements,
  CommentParentType,
  DiscountType,
  PostBcastMetadata,
  PostType,
  PostVodMetadata,
} from '../../API';

export type SubscriptionEvent<D> = {
  value: {
    data: D;
  };
};
export type UserRole = 'athlete' | 'fan';
export const userRoles: { [key: string]: UserRole } = {
  athlete: 'athlete',
  fan: 'fan',
};
export type InstagramUser = {
  profilePicUrl: string;
  follows: number;
  followedBy: number;
  posts: number;
};

export type User = {
  // omit 'owner'
  id: string;
  name: string;
  givenName: string;
  familyName: string;
  gender: string;
  country: string;
  sport: string;
  role: string;
  email?: string; // not optional in Schema
  nickname?: string;
  mission?: string;
  profileImgS3Key?: string;
  coverImgS3Key?: string;
  introVideoS3Key?: string;
  introVideoThumbS3Key?: string;
  introVideoUrl?: string;
  instagramUrl?: string;
  facebookUrl?: string;
  youtubeUrl?: string;
  birthdate?: string;
  goals?: AthleteGoal[];
  social?: UserSocial;
  achievements?: AthleteAchievement[];
  fundingGoals?: AthleteFundingGoal[];
  sponsors: AthleteSponsor[];
  emailValidated?: boolean;
  potentialAthlete?: boolean;
  currency?: string;
  payoutCurrency?: string;
  validationEmailSentAt?: string;
  stripeAccountId?: string; // TO BE REMOVED: When we use Stripe Custom Accounts
  stripePayoutsEnabled?: boolean;
  stripeActionRequired?: boolean;
  stats: {
    numUniqueViews?: number;
    numScouts?: number;
    paidByScouts?: number;
    numPosts?: number;
  };
  features?: UserFeatures;
  firebaseToken?: string;
  firebaseTokens?: string[];
};
export type UserMin = {
  id: string;
  nickname: string;
  name: string;
};

export type UserSocial = {
  twitterRequestToken?: string;
  twitterRequestSecret?: string;
  twitterAccessToken?: string;
  twitterAccessSecret?: string;
  fbUserId: string;
  fbAccessToken: string;
  fbTargetPageId: string;
  instagramUserId: string;
};

export type AthleteGoal = {
  id: string;
  name: string;
  level?: string;
  description: string;
  link?: string;
  videoUrl?: string;
  date?: Date;
};

export type AthleteAchievement = {
  id?: string;
  name: string;
  level?: string;
  description: string;
  link?: string;
  videoUrl?: string;
  date?: Date;
};

export type AthleteFundingGoal = {
  id: string;
  amount: number;
  currency: string;
  description: string;
};

export type AthleteSponsor = {
  id: string;
  imgS3Key: string;
};

export type UserFeatures = {
  posts?: boolean;
  postsSubscriptionsTTL?: number;
};

export type Post = {
  id: string;
  authorId: string;
  author?: User;
  name: string;
  description: string;
  type: PostType;
  tierId: string;
  tier?: Tier;
  needsSubscription?: boolean;
  comments: number;
  vodId?: string;
  vodStatus?: PostVodStatus;
  vodToken?: string;
  vodMetadata?: PostVodMetadata;
  bcastMetadata?: PostBcastMetadata;
  reviewStatus: 'accepted' | 'declined';
  thumbS3Key?: string;
  likes?: number;
  liked?: boolean;
  createdAt: string;
};

export enum SubscriptionStatus {
  active = 'active',
  canceled = 'canceled',
  incomplete = 'incomplete',
  incomplete_expired = 'incomplete_expired',
  past_due = 'past_due',
  trialing = 'trialing',
  unpaid = 'unpaid',
}

export enum PostVodStatus {
  submitted = 'submitted',
  progressing = 'progressing',
  complete = 'complete',
  probing = 'probing',
  transcoding = 'transcoding',
  uploading = 'uploading',
  error = 'error',
}

export type AthleteSubscription = {
  subscriberId: string;
  athleteId: string;
  subscriber?: User;
  athlete?: User;
  tierId?: string;
  tier?: Tier;
  currency?: string;
  unitAmount?: number;
  status: SubscriptionStatus | null;
  cancelAtPeriodEnd?: boolean;
  currentPeriodEnd?: number;
  stripeSubscriptionId?: string | null;
  stripePriceId?: string | null;
  expiresAt: string;
  discountType?: DiscountType;
  discount?: number;
  discountRemainingMonths?: number;
  createdAt: string;
  updatedAt: string;
};

export type Tier = {
  id: string;
  userId: string;
  name: string;
  currency: string;
  unitAmount: number;
  discountType?: DiscountType;
  discount?: number;
  discountMonths?: number;
  createdAt: string;
};

export type PaymentsAccount = {
  id: string;
  business_profile?: AccountBusinessProfile;
  charges_enabled?: boolean;
  country: string;
  created: number;
  default_currency: string;
  details_submitted: boolean;
  email?: string | null;
  payouts_enabled?: boolean;
  requirements?: AccountRequirements;
};

export type BalanceTransaction = {
  id?: string;
  amount: number;
  available_on: number;
  created: number;
  currency: string;
  description: string;
  exchange_rate?: number;
  fee?: number;
  net?: number;
  reporting_category?: string;
  status: string;
  type?: string;
};

export type Payout = {
  id?: string;
  amount: number;
  arrival_date?: number;
  automatic?: boolean;
  created: number;
  currency: string;
  description: string;
  source_type?: string;
  status: string;
  type?: string;
};

export type TaxRate = {
  id: string;
  percentage: number;
  displayName: string;
  country: string;
};

export type Comment = {
  id?: string;
  authorId?: string;
  parentId: string;
  parentType: CommentParentType;
  message: string;
  likes?: number | null;
  comments?: number | null;
  lastComment?: Comment;
  liked?: boolean;
  createdAt?: string;
  updatedAt?: string;
  author?: User | null;
};

export enum GRAPHQL_AUTH_MODE {
  API_KEY = 'API_KEY',
  AWS_IAM = 'AWS_IAM',
  OPENID_CONNECT = 'OPENID_CONNECT',
  AMAZON_COGNITO_USER_POOLS = 'AMAZON_COGNITO_USER_POOLS',
}

export type Currency = {
  code: string;
  symbol: string;
  round: number;
};

export type CurrenciesConfig = {
  defaultCurrency: string;
  clientCurrency: string;
  currencies: Array<Currency>;
  conversionsTable: Array<Array<number>>;
};
