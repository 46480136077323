import React, { useEffect } from 'react';
import Spinner from './Spinner';

import { BcastStatus } from '../../API';
import { useMeetingSession } from '../providers/MeetingSessionProvider';

const BcastControls: React.FC = () => {
  const { bcastRecord, bcastStep, prepareBcast, startBcast, stopBcast } = useMeetingSession();

  // start preparing broadcasting as soon as host/moderator joins the meeting
  // (note: there's also cron set 10 mins before to do the same)
  useEffect(() => {
    if (bcastRecord?.status === BcastStatus.scheduled) {
      prepareBcast();
    }
  }, []);

  const startButtonStyle = {
    margin: '0px',
    backgroundColor: 'orange',
    borderRadius: '2em',
    border: 'none',
    height: '2em',
    width: '5em',
    color: 'black',
    // fontWeight: "bold",
  };

  const stopButtonStyle = {
    margin: '0px',
    backgroundColor: 'red',
    borderRadius: '2em',
    border: 'none',
    height: '2em',
    width: '5em',
    color: 'white',
    // fontWeight: "bold",
  };

  return (
    <>
      {bcastStep === 'prepared' && (
        <button onClick={startBcast} style={startButtonStyle}>
          Start
        </button>
      )}
      {bcastStep === 'starting' && <Spinner style={startButtonStyle} />}
      {bcastStep === 'started' && (
        <button onClick={stopBcast} style={stopButtonStyle}>
          Stop
        </button>
      )}
      {bcastStep === 'stopping' && <Spinner style={stopButtonStyle} />}
    </>
  );
};

export default BcastControls;
