import { Box, capitalize, Grid, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '300px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(1),
    '& .MuiSvgIcon-root': {
      fontSize: theme.typography.pxToRem(35),
      marginTop: theme.typography.pxToRem(8),
    },
  },
  gridSubContainer: {
    height: '100%',
    cursor: 'pointer',
    transition: 'all .5s',
    boxShadow: '0 1px 3px',
  },
  selectedSubContainer: {
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    '& .MuiTypography-root': {
      fontWeight: 500,
    },
  },
  unselectedSubContainer: {
    color: 'gray',
    border: '1px solid #C8C8C8',
  },
}));

export interface SelectableData {
  title: string;
  icon: JSX.Element;
  value: any;
}

interface Props {
  data: SelectableData[];
  title: string;
  onCardClick: (value: any) => void;
  selectedItemValue: any;
}

const SelectableCards: React.FC<Props> = ({ data, onCardClick, selectedItemValue, title }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Box p={2}>
        <Typography align="center" variant="h4">
          {title}
        </Typography>
      </Box>
      <Grid
        justifyContent="center"
        style={{ height: '100%', marginBottom: '20px', marginTop: '20px' }}
        spacing={1}
        container
      >
        {data.map((item: SelectableData, index: number) => {
          return (
            <Grid key={index} item>
              <Grid
                className={clsx({
                  [classes.gridSubContainer]: true,
                  [classes.selectedSubContainer]: selectedItemValue === item.value,
                  [classes.unselectedSubContainer]: selectedItemValue !== item.value,
                })}
                onClick={onCardClick.bind(null, item.value)}
                component="div"
                container
              >
                <Box
                  minWidth="250px"
                  p={2}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box>
                    <Typography variant="h5">{capitalize(item.title)}</Typography>
                  </Box>
                  <Box>{item.icon}</Box>
                </Box>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default SelectableCards;
