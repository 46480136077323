import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useMeetingSession } from './providers/MeetingSessionProvider';
import { useLocation } from 'react-router-dom';

import Spinner from './components/Spinner';
//import { useAuthenticator } from '@aws-amplify/ui-react';
import { commitButtonStyle, formStyle } from './components/FormStyles';
import { AttendeeType } from './utils/attendee';
import { authStore } from '../core/stores/auth';
import { BcastGuest } from '../API';

/*

Joins the meeting as "host", "guest", or moderator.
("bcaster" joins through BroadcasrtPage)

Attendee type is determined by url params:

1) gid = guest id:
   access allowed without login, specified id must exist in guest list
   in the bcast record
2) mid = moderator id:
   id must exist in the moderator list in bcast record (todo) and must
   match logged in user
3) without params = host 
   intended for the host (athlete)
   currently logged in user must match author of the bcast record
*/

const JoinForm: React.FC = () => {
  const [authState] = useContext(authStore);

  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const { bcastRecord, joinMeeting } = useMeetingSession();
  const [attendeeType, setAttendeeType] = useState<AttendeeType | null>(null);
  const [attendeeId, setAttendeeId] = useState('');
  const [attendeeName, setAttendeeName] = useState('');
  const [error, setError] = useState('');
  const [isJoining, setIsJoining] = useState(false);

  useEffect(() => {
    if (bcastRecord) {
      if (searchParams.has('gid')) {
        const gid = searchParams.get('gid') || '';
        setAttendeeType('guest');
        setAttendeeId(gid);
        const guest = bcastRecord.guests.find((g: BcastGuest) => g.id === gid);
        setAttendeeName(guest?.name || '');
      } else if (searchParams.has('mid')) {
        const mid = searchParams.get('mid') || '';
        throw new Error('Todo....');
        setAttendeeType('moderator');
        setAttendeeId(mid);
        setAttendeeName('?');
      } else if (authState.user) {
        setAttendeeType('host');
        setAttendeeId(authState.user.userId);
        setAttendeeName(authState.userNickname || authState.user.challengeName || '');
      }
    }

    // note: funny, but this empty function prevent warning that occurs because of
    // setIsJoining(false) on line 96, when the form is closed by parent component:
    return () => {
      return;
    };
  }, []);

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (isJoining) return;
    setIsJoining(true);
    setError('');
    try {
      if (attendeeName.trim().length === 0) {
        throw 'Please enter your name';
      }
      if (!attendeeType) {
        throw 'Unknown attendee';
      }
      await joinMeeting({
        id: attendeeId,
        type: attendeeType,
        name: attendeeName.trim(),
      });
    } catch (error) {
      if (typeof error === 'string') {
        setError(error);
      } else {
        console.log('Error', JSON.stringify(error));
        setError('Failed to join the meeting');
      }
    }
    setIsJoining(false);
  };

  return (
    <div style={formStyle}>
      <div>
        <h3 style={{ color: 'orange' }}>Join the broadcast</h3>
        <h3>{bcastRecord?.title}</h3>
        <form onSubmit={handleSubmit}>
          <p style={{ margin: '2em 0' }}>
            <label>Your name: </label>
            <input
              type="text"
              value={attendeeName}
              placeholder="Enter display name"
              onChange={(e) => setAttendeeName(e.target.value)}
              style={{
                maxWidth: '100%',
                backgroundColor: 'rgb(63, 65, 73)',
                color: 'white',
                border: 'none',
              }}
            />
          </p>
          <p>
            <button type="submit" value="Submit" style={commitButtonStyle}>
              {isJoining ? <Spinner style={{ margin: '0px' }} /> : 'Continue'}
            </button>
          </p>
        </form>
      </div>
      {error.length > 0 && (
        <p
          style={{
            color: 'red',
            fontWeight: 'bolder',
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: '100%',
            textAlign: 'center',
          }}
        >
          {error}
        </p>
      )}
    </div>
  );
};

export default JoinForm;
