import React, { useEffect } from 'react';
import { useMeetingManager, useVideoInputs } from 'amazon-chime-sdk-component-library-react';
import { DefaultDeviceController, ContentShareMediaStreamBroker } from 'amazon-chime-sdk-js';
import Config from '../utils/config';

// Component is used to optimize video uplink bandwidth.
// I's done by reducing video input resolution depending on the
// current number of participants/screen shares.

interface Props {
  sharesCount: number;
  videosCount: number;
}

const VideoOptimizer: React.FC<Props> = (props: Props) => {
  const meetingManager = useMeetingManager();
  const { selectedDevice } = useVideoInputs();

  useEffect(() => {
    // async function which updates video stream, if can find it
    const updateVideoStream = async (constraints: MediaTrackConstraints) => {
      const controller = meetingManager?.meetingSession?.deviceController as DefaultDeviceController;
      if (controller) {
        const stream = await controller.acquireVideoInputStream();
        if (stream && stream.active) {
          const track = stream.getVideoTracks()[0];
          await track.applyConstraints(constraints);
        }
      }
    };

    // async function that limits screen sharing stream (if we're sharing screen)
    const updateContentStream = async () => {
      const controller = meetingManager?.meetingSession?.contentShare as any;
      if (controller) {
        const broker = controller.mediaStreamBroker as ContentShareMediaStreamBroker;
        if (broker) {
          const stream = broker.mediaStream;
          if (stream && stream.active) {
            console.log(stream);
            const track = stream.getVideoTracks()[0];
            await track.applyConstraints(Config.CONTENT_SHARE_CONSTRAINTS);
          }
        }
      }
    };

    // begin with 100% resolution
    let c = 1.0;

    // if a screen share, reduce to 25%
    // (corresponds to Config.THUMBNAIL_HEIGHT)
    if (props.sharesCount > 0) {
      c = c / 4;
    }

    // further reduce in proportion to participant number
    if (props.videosCount > 0) {
      c = c / props.videosCount;
    }

    // we need sqrt, because both dimensions are affected
    const d = Math.sqrt(c);
    const constraints = {
      width: { ideal: Math.round(Config.VIDEO_CAPTURE_WIDTH * d) },
      height: { ideal: Math.round(Config.VIDEO_CAPTURE_HEIGHT * d) },
    };

    // update camera stream
    updateVideoStream(constraints);

    // update sharing screen
    updateContentStream();
  }, [props.sharesCount, props.videosCount, selectedDevice]);

  return <></>;
};

export default VideoOptimizer;
