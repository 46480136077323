import { API } from 'aws-amplify';
import axios from 'axios';
import { validate as uuidValidate } from 'uuid';
import * as DateFns from 'date-fns';

import { AthleteFundingGoal, GRAPHQL_AUTH_MODE, InstagramUser, SubscriptionEvent, User } from '../graphql/types';
import {
  getUser,
  getUserFull,
  getUserMin,
  getUserNickname,
  listFullUsersByNickname,
  listUserIdByNickname,
  listUsersByNickname,
  searchUsers,
} from '../graphql/customQueries';
import {
  updateUser as mutUpdateUser,
  updateUserNickname,
  upgradeToAthlete as upgradeToAthleteMut,
  sendEmailValidationEmail as sendEmailValidationEmailMut,
  validateEmail as validateEmailMut,
} from '../graphql/customMutations';
import { SearchableSortDirection, SearchableUserFilterInput, SearchableUserSortableFields } from '../../API';
import { AppAction } from '../stores/app';
import cacheHelpers from '../helpers/cache';
import { onAthleteCreation } from '../graphql/customSubscriptions';

export const fetchUserById = async (userId: string, format: 'basic' | 'min' | 'full' = 'basic') => {
  const userRes: any = await API.graphql({
    query: format === 'basic' ? getUser : format === 'min' ? getUserMin : getUserFull,
    variables: {
      id: userId,
    },
    authMode: format === 'full' ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS : GRAPHQL_AUTH_MODE.API_KEY,
  });
  const user = userRes.data.getUser;

  // Sort funding goals
  if (user && user.fundingGoals) {
    user.fundingGoals.sort((a: AthleteFundingGoal, b: AthleteFundingGoal) => (a.amount < b.amount ? -1 : 1));
  }

  return user || null;
};

export const getUserNicknameFromId = async (userId: string) => {
  const userRes: any = await API.graphql({
    query: getUserNickname,
    variables: {
      id: userId,
    },
    authMode: GRAPHQL_AUTH_MODE.API_KEY,
  });
  return userRes.data.getUser?.nickname || null;
};

export const getUserIdFromNickname = async (nickname: string) => {
  const userRes: any = await API.graphql({
    query: listUserIdByNickname,
    variables: {
      nickname: nickname,
    },
    authMode: GRAPHQL_AUTH_MODE.API_KEY,
  });
  const { items: users } = userRes.data.listUsersByNickname;

  return (users[0] && users[0].id) || null;
};

export const fetchUserByNickname = async (nickname: string, format: 'basic' | 'full' = 'basic') => {
  const userRes: any = await API.graphql({
    query: format === 'full' ? listFullUsersByNickname : listUsersByNickname,
    variables: {
      nickname: nickname,
    },
    authMode: format === 'full' ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS : GRAPHQL_AUTH_MODE.API_KEY,
  });
  const { items: users } = userRes.data.listUsersByNickname;

  return users[0] || null;
};

export const fetchUser = async (userIdentifier: string, loggedUserId?: string): Promise<User> => {
  const isNickname = !uuidValidate(userIdentifier);
  const userId = isNickname ? await getUserIdFromNickname(userIdentifier) : userIdentifier;
  const format = loggedUserId === userId ? 'full' : 'basic';
  const user = isNickname ? await fetchUserByNickname(userIdentifier, format) : await fetchUserById(userId, format);

  return user;
};

export const fetchUserInstagramData = async (user: User): Promise<InstagramUser | null> => {
  if (user.instagramUrl) {
    const url = new URL(user.instagramUrl);
    const pathName = `${url.pathname}`.replace('//', '/'); // before: /?__a=1
    const reqUrl = `${url.origin}${pathName}`;

    try {
      const response = await axios.get(reqUrl);
      const jsonObject = response.data
        .match(/<script type="text\/javascript">window\._sharedData = (.*)<\/script>/)[1]
        .slice(0, -1);
      const parsedObject = JSON.parse(jsonObject);
      // const instaUsrData = response.data.graphql.user;
      const instaUsrData = parsedObject.entry_data.ProfilePage[0].graphql.user;

      return {
        profilePicUrl: instaUsrData.profile_pic_url,
        follows: instaUsrData.edge_follow.count,
        followedBy: instaUsrData.edge_followed_by.count,
        posts: instaUsrData.edge_owner_to_timeline_media.count,
      };
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  return null;
};

export const subscribeToAthleteCreation = async (
  next: (evt: SubscriptionEvent<{ onAthleteCreation: User }>) => void,
) => {
  const subscription = (
    API.graphql({
      query: onAthleteCreation,
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    }) as any
  ).subscribe({
    next: next,
  });

  return subscription;
};

export const searchAthletes = async (
  filter: SearchableUserFilterInput,
  pageSize?: number,
  from?: number,
  sortField?: SearchableUserSortableFields | string,
  sortDirection?: SearchableSortDirection,
): Promise<{ athletes: User[]; total: number; nextToken?: string } | null> => {
  const res: any = await API.graphql({
    query: searchUsers,
    variables: {
      filter: {
        ...filter,
        role: {
          eq: 'athlete',
        },
      },
      from: from || null,
      limit: pageSize || 16,
      sort: {
        field: sortField || SearchableUserSortableFields.name,
        direction: sortDirection || SearchableSortDirection.asc,
      },
    },
    authMode: GRAPHQL_AUTH_MODE.API_KEY,
  });

  if (res.data.searchUsers) {
    return {
      athletes: res.data.searchUsers.items,
      nextToken: res.data.searchUsers.nextToken,
      total: res.data.searchUsers.total?.value,
    };
  }

  return null;
};

export const updateUser = async (
  userId: string,
  values: any,
  appDispatch: React.Dispatch<AppAction>,
  showToast = true,
) => {
  // Trim values before submitting
  const trimmedValues = trimObj(values, [
    'givenName',
    'familyName',
    'nickname',
    'sport',
    'instagramUrl',
    'facebookUrl',
    'youtubeUrl',
  ]);

  // Birthdate should be in 'yyyy-MM-dd' format
  if (trimmedValues.birthdate) {
    trimmedValues.birthdate = DateFns.format(new Date(trimmedValues.birthdate), 'yyyy-MM-dd');
  }

  await API.graphql({
    query: mutUpdateUser,
    variables: {
      input: {
        id: userId,
        ...trimmedValues,
      },
    },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  });
  appDispatch({ type: 'updateUser', userUpdateObj: values });

  if (showToast) {
    appDispatch({
      type: 'showToast',
      toastTxt: 'Data saved successfully',
      toastSeverity: 'success',
    });
  }
};

export const updateMyNickname = async (nickname: string) => {
  const res: any = await API.graphql({
    query: updateUserNickname,
    variables: {
      nickname,
    },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  });

  return Boolean(res && res.data && res.data.updateUserNickname);
};

export const upgradeToAthlete = async () => {
  await API.graphql({
    query: upgradeToAthleteMut,
    variables: {},
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  });

  // Reload page
  const cacheExpirationMs = Date.now() + 60 * 1000; // 60 seconds
  cacheHelpers.save(cacheHelpers.keys.showConfetti, '1', cacheExpirationMs);
  window.location.href = `${window.location.origin}/home`;
};

export const sendEmailValidationEmail = async () => {
  const res: any = await API.graphql({
    query: sendEmailValidationEmailMut,
    variables: {},
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  });

  return res.data.sendEmailValidationEmail;
};

export const validateEmail = async (token: string): Promise<{ value: boolean; error: string }> => {
  const res: any = await API.graphql({
    query: validateEmailMut,
    variables: {
      token,
    },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  });

  return res.data.validateEmail;
};

export default {
  // get
  getUserNicknameFromId,
  getUserIdFromNickname,
  fetchUserById,
  fetchUserByNickname,
  fetchUser,
  fetchUserInstagramData,
  searchAthletes,
  // post
  updateUser,
  updateMyNickname,
  upgradeToAthlete,
  sendEmailValidationEmail,
  validateEmail,
  //subscriptions
  subscribeToAthleteCreation,
};

function trimObj(obj: any, keys: string[]) {
  const trimmedObj = { ...obj };

  keys.forEach((key) => {
    const val = trimmedObj[key];

    if (val && typeof val === 'string') {
      trimmedObj[key] = val.trim();
    }
  });

  return trimmedObj;
}
