import React, { useEffect } from 'react';
import {
  AudioInputControl,
  AudioOutputControl,
  ControlBar,
  ControlBarButton,
  Phone,
  VideoInputControl,
  ContentShareControl,
} from 'amazon-chime-sdk-component-library-react';
import { useMeetingSession } from '../providers/MeetingSessionProvider';
import CSS from 'csstype';
import { ControlBarLayout } from 'amazon-chime-sdk-component-library-react/lib/components/ui/ControlBar';
import Config from '../utils/config';
import { useDevice } from '../providers/DeviceProvider';
import { useLocalVideo } from 'amazon-chime-sdk-component-library-react';

interface Props {
  layout: ControlBarLayout;
  style?: CSS.Properties;
  children?: React.ReactNode;
}

const MeetingControls: React.FC<Props> = (props: Props) => {
  const { leaveMeeting } = useMeetingSession();
  const { isVideoEnabled, toggleVideo } = useLocalVideo();
  const { deviceType } = useDevice();

  //const [isStartingBcast, setIsStartingBcast] = useState(false);

  useEffect(() => {
    // Start video automatically when participant joins a meetting
    // (it's stopped by default)
    if (!isVideoEnabled) {
      toggleVideo();
    }
  }, []);

  return (
    <>
      <ControlBar
        layout={props.layout}
        showLabels
        style={{
          ...props.style,
          backgroundColor: Config.BARS_COLOR,
        }}
      >
        <AudioInputControl />
        <VideoInputControl />
        <AudioOutputControl />
        {deviceType === 'desktop' && <ContentShareControl />}
        <ControlBarButton icon={<Phone />} onClick={leaveMeeting} label="Leave" />
        {props.children}
      </ControlBar>
    </>
  );
};

export default MeetingControls;
