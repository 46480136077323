/*
 * Using mui-rff
 * Check following link for more info: https://github.com/lookfirst/mui-rff
 * */

import React, { useState, useEffect, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import * as Yup from 'yup';
import { makeValidate, makeRequired, TextField } from 'mui-rff';
import { Form, FormSpy, Field } from 'react-final-form';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import Dialog from '@material-ui/core/Dialog/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Paper from '@material-ui/core/Paper';
import clsx from 'clsx';
import ClearIcon from '@material-ui/icons/Clear';
import { Alert } from '@material-ui/lab';
import { mbToBytes, removeNullAttributes } from '../../core/helpers/misc';
import DialogTitle from '../../components/ui/DialogTitle';
import VideoImageDropZone from '../../components/ui/VideoImageDropZone';
import VideoPlayer from '../../components/ui/VideoPlayer';

const introVideoHeight = 250;
const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submitBtnWrapper: {
    position: 'relative',
  },
  submitBtn: {
    margin: theme.spacing(2, 0, 1),
    height: 50,
  },
  submitBtnProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  charCounter: {
    position: 'absolute',
    right: theme.spacing(1),
    bottom: theme.spacing(1 / 2),
  },
  videoUploadButton: {
    display: 'none',
  },
  introVideoDropzonePaper: {
    height: theme.typography.pxToRem(introVideoHeight),
  },
  introVideoWrapper: {
    height: theme.typography.pxToRem(introVideoHeight),
  },
  blackBg: {
    backgroundColor: '#000000',
  },
  videoPlayer: {
    maxHeight: theme.typography.pxToRem(introVideoHeight),
    maxWidth: '100%',
    width: '100%',
  },
  introVideoThumb: {
    width: '100%',
    height: theme.typography.pxToRem(introVideoHeight),
    objectFit: 'cover',
  },
}));

interface AthleteIntroDialogProps {
  open: boolean;
  mission?: string;
  introVideoUrl?: string;
  submitting?: boolean;
  onSubmit: (data: AthleteIntroDialogData) => void;
  onCancel: () => void;
}

export interface AthleteIntroDialogData {
  mission: string;
  introVideoFile: File | null | undefined;
}

const maxMissionLen = 250;
const maxMBIntroVIdeo = 500;
const maxIntroVideoSizeBytes = mbToBytes(maxMBIntroVIdeo); // in Bytes (1Mb = 1048576 Bytes)
const maxIntroVideoDuration = undefined; // 20; // seconds

const formSchema = Yup.object().shape({
  mission: Yup.string()
    .required('Mission is a required field.')
    .max(maxMissionLen, `The mission can be max ${maxMissionLen} characters long.`),
});

const validate = makeValidate(formSchema);
const required = makeRequired(formSchema);

const AthleteIntroDialog: React.FC<AthleteIntroDialogProps> = ({
  open,
  mission,
  introVideoUrl,
  submitting,
  onSubmit,
  onCancel,
}: AthleteIntroDialogProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const [missionState, setMissionState] = useState('');
  const [introVideoFile, setIntroVideoFile] = useState<File | null | undefined>(undefined);
  const [introVideoFileUrl, setIntroVideoFileUrl] = useState(introVideoUrl);
  const [fileRefectionAlert, setFileRejectionAlert] = useState(undefined);
  const [introVideoChanged, setIntroVideoChanged] = useState(false);
  const formId = 'athlete-mission-dialog-form';
  const subscription = { pristine: true, invalid: true };
  const fieldsMargin = 'dense';
  const initialValues = removeNullAttributes({ mission });
  const renderVideo = useMemo(() => {
    if (introVideoFileUrl) {
      return <VideoPlayer className={classes.videoPlayer} videoUrl={introVideoFileUrl} />;
    }
    return <></>;
  }, [introVideoFileUrl]);
  const handleSubmitClick = (values: AthleteIntroDialogData) => {
    onSubmit({ ...values, introVideoFile: introVideoFile });
  };
  const handleCancelClick = () => {
    onCancel();
  };
  const handleFormChange = ({ values }: { values: AthleteIntroDialogData }) => {
    // Avoid React render loop
    setTimeout(() => {
      setMissionState(values.mission);
    }, 0);
  };
  const handleIntroFileSelected = (files: File[]) => {
    if (!introVideoFile && files.length > 0) {
      const file = files[0];
      setIntroVideoFile(file);
      setFileRejectionAlert(undefined);
      setIntroVideoChanged(true);
    }
  };
  const handleIntroFileRejected = (rejections: any) => {
    const fileRejection = rejections[0];
    if (fileRejection) {
      setFileRejectionAlert(
        fileRejection.errors[0].code === 'file-too-large'
          ? `File cannot be more than ${maxMBIntroVIdeo} mb`
          : fileRejection.errors[0].message,
      );
    }
  };
  const handleReplaceIntroFileClick = () => {
    setIntroVideoFile(null);
    setIntroVideoFileUrl('');
  };

  useEffect(() => {
    if (introVideoFile !== undefined) {
      setIntroVideoFileUrl(introVideoFile ? URL.createObjectURL(introVideoFile) : '');
    }
  }, [introVideoFile]);

  return (
    <Form
      onSubmit={handleSubmitClick}
      initialValues={initialValues}
      subscription={subscription}
      validate={validate}
      key={subscription as any}
      render={({ handleSubmit, pristine, invalid }) => (
        <form id={formId} className={classes.form} onSubmit={handleSubmit} noValidate={true}>
          <FormSpy subscription={{ values: true }} onChange={handleFormChange} />
          <Dialog
            open={open}
            maxWidth="sm"
            fullWidth={true}
            fullScreen={!isBigScreen}
            aria-labelledby="form-dialog-name"
          >
            <DialogTitle id="form-dialog-name" onClose={handleCancelClick}>
              About me
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {/* Mission */}
                  <TextField
                    id="mission"
                    name="mission"
                    label="Description"
                    placeholder="Tell the world your mission"
                    helperText="Tell the world your mission"
                    multiline={true}
                    rows={8}
                    required={required.mission}
                    margin={fieldsMargin}
                    fullWidth
                    autoFocus
                    inputProps={{
                      maxLength: maxMissionLen,
                    }}
                    InputProps={{
                      endAdornment: (
                        <Typography className={classes.charCounter} variant="caption" color="textSecondary">
                          {missionState ? `${missionState.length}/${maxMissionLen}` : ''}
                        </Typography>
                      ),
                    }}
                    disabled={submitting}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">
                      <Typography variant="body2">Intro video</Typography>
                    </FormLabel>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Box width={1}>
                    {!introVideoFileUrl && (
                      <Field
                        name="introVideoS3Key"
                        render={() => {
                          return (
                            <Paper className={classes.introVideoDropzonePaper} variant="outlined">
                              <VideoImageDropZone
                                fontSize={'sm'}
                                hideButton={false}
                                maxVideoDuration={maxIntroVideoDuration}
                                maxFileSizeBytes={maxIntroVideoSizeBytes}
                                onChange={handleIntroFileSelected}
                                onRejected={handleIntroFileRejected}
                              />
                            </Paper>
                          );
                        }}
                      ></Field>
                    )}
                    {introVideoFileUrl && (
                      <>
                        <Box
                          className={clsx({
                            [classes.introVideoWrapper]: true,
                            [classes.blackBg]: true,
                          })}
                        >
                          {renderVideo}
                        </Box>
                        <Box mt={1} width={1} display="flex" justifyContent="flex-end">
                          <Button startIcon={<ClearIcon />} disabled={submitting} onClick={handleReplaceIntroFileClick}>
                            Choose another file
                          </Button>
                        </Box>
                      </>
                    )}
                    {fileRefectionAlert && <Alert severity="error">{fileRefectionAlert}</Alert>}
                  </Box>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button disabled={submitting} onClick={handleCancelClick}>
                Cancel
              </Button>
              <Button
                form={formId}
                color="primary"
                type="submit"
                disabled={submitting || ((invalid || pristine) && !introVideoChanged)}
                endIcon={submitting ? <CircularProgress size={24} /> : <ArrowForwardIcon fontSize="large" />}
              >
                Save changes
              </Button>
            </DialogActions>
          </Dialog>
        </form>
      )}
    />
  );
};

export default AthleteIntroDialog;
