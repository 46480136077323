import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth/withWidth';
import Typography from '@material-ui/core/Typography';
import grey from '@material-ui/core/colors/grey';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Pagination from '@material-ui/lab/Pagination';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import Avatar from '@material-ui/core/Avatar';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';

import { Filters } from './FiltersBlock';
import AthleteAvatar from '../../components/elements/AthleteAvatar';
import { User } from '../../core/graphql/types';
import { getCountryName, getSportName } from '../../core/helpers/selectOptions';
import { searchAthletes } from '../../core/services/userSvc';
import { countryToFlag } from '../../core/helpers/misc';
import { SearchableSortDirection, SearchableUserSortableFields } from '../../API';
import ActiveFiltersList from './ActiveFiltersList';
import LocalBackdrop from '../../components/ui/LocalBackdrop';
import { getAthleteProfileRoute } from '../../core/helpers/route';

const firstColWLg = 250;
const avatarW = 48;
const useStyles = makeStyles((theme) => ({
  athletesBlockRoot: {
    width: '100%', // material-ui: +16px
    margin: 0, // materiail-ui: -8px
    height: 'fit-content',
  },
  athletesTableWrapper: {
    position: 'relative',
  },
  athletesTable: {
    width: '100%',
    tableLayout: 'fixed',
    borderCollapse: 'collapse',
    '& > thead > tr': {
      height: theme.typography.pxToRem(40),
      borderBottom: `1px solid ${grey[300]}`,
    },
    '& > tbody > tr > td': {
      height: theme.typography.pxToRem(64),
    },
    '& tr': {
      borderBottom: `1px solid ${grey[300]}`,
    },
  },
  columnTitle: {
    textTransform: 'none',
    color: theme.palette.text.secondary,
    '&.active': {
      color: theme.palette.primary.main,
    },
  },
  firstCol: {
    width: theme.typography.pxToRem(firstColWLg),
    [theme.breakpoints.down('md')]: {
      width: '60%',
    },
  },
  lastCol: {
    [theme.breakpoints.down('md')]: {
      width: '40%',
    },
  },
  athleteInfoTable: {
    [theme.breakpoints.down('md')]: {
      tableLayout: 'fixed',
      width: '100%',
    },
  },
  athleteAvatar: {
    width: theme.typography.pxToRem(avatarW),
    height: theme.typography.pxToRem(avatarW),
    marginLeft: theme.spacing(-1 / 2),
    marginRight: theme.spacing(1),
  },
  athleteField: {
    width: theme.typography.pxToRem(firstColWLg - (avatarW + 20)),
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  sportName: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));

interface Props {
  width?: string; // injected by 'withWidth'
  filters: Filters;
  activeFiltersList: { group: string; key: string; label: string }[];
  onFiltersClick: () => void;
  onDeleteFilterClick: (filterGroup: string, filterKey: string) => void;
  onClearAllFiltersClick: () => void;
}

interface SortCriteria {
  label: string;
  field: SearchableUserSortableFields | string;
  direction: SearchableSortDirection;
}

const sortCriterias: SortCriteria[] = [
  {
    label: 'Name',
    field: SearchableUserSortableFields.name,
    direction: SearchableSortDirection.asc,
  },
  {
    label: 'Name',
    field: SearchableUserSortableFields.name,
    direction: SearchableSortDirection.desc,
  },
  {
    label: 'Sports',
    field: SearchableUserSortableFields.sport,
    direction: SearchableSortDirection.asc,
  },
  {
    label: 'Sports',
    field: SearchableUserSortableFields.sport,
    direction: SearchableSortDirection.desc,
  },
  {
    label: 'Page Views',
    field: 'stats_numUniqueViews',
    direction: SearchableSortDirection.asc,
  },
  {
    label: 'Page Views',
    field: 'stats_numUniqueViews',
    direction: SearchableSortDirection.desc,
  },
];

const ColumnTitle: React.FC<{
  label: string;
  field: SearchableUserSortableFields | string;
  direction: SearchableSortDirection;
  isActive: boolean;
  onClick: (field: SearchableUserSortableFields | string) => void;
}> = ({
  label,
  field,
  direction,
  isActive,
  onClick,
}: {
  label: string;
  field: SearchableUserSortableFields | string;
  direction: SearchableSortDirection;
  isActive: boolean;
  onClick: (field: SearchableUserSortableFields | string) => void;
}) => {
  const classes = useStyles();
  const handleClick = () => {
    onClick(field);
  };

  return (
    <>
      {isActive && (
        <Button
          className={clsx({
            [classes.columnTitle]: true,
            active: true,
          })}
          color="primary"
          size="small"
          endIcon={
            <>
              {direction === SearchableSortDirection.asc && <ArrowDownwardIcon fontSize="small" color="primary" />}
              {direction === SearchableSortDirection.desc && <ArrowUpwardIcon fontSize="small" color="primary" />}
            </>
          }
          onClick={handleClick}
        >
          {label}
        </Button>
      )}
      {!isActive && (
        <Button
          className={clsx({
            [classes.columnTitle]: true,
            active: false,
          })}
          size="small"
          color="secondary"
          onClick={handleClick}
        >
          {label}
        </Button>
      )}
    </>
  );
};

let timerInt: NodeJS.Timeout;
const AthletesBlock: React.FC<Props> = ({
  width,
  filters,
  activeFiltersList,
  onFiltersClick,
  onDeleteFilterClick,
  onClearAllFiltersClick,
}: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const isBigScreen = width && !['xs', 'sm'].includes(width);
  const pageSize = isBigScreen ? 10 : 5;
  const [itemsCount, setItemsCount] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [athletes, setAthletes] = useState<User[]>([]);
  const [curPage, setCurPage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState(false);
  const [sortingAnchorEl, setSortingAnchorEl] = React.useState<null | HTMLElement>(null);
  const [sortCriteria, setSortCriteria] = React.useState<SortCriteria>(sortCriterias[0]);
  const fetchAthletes = async () => {
    const search = async () => {
      setIsLoading(true);
      const queryFilter = generateQueryFilter(filters);
      const res = await searchAthletes(
        queryFilter,
        pageSize,
        (curPage - 1) * pageSize,
        sortCriteria.field,
        sortCriteria.direction,
      );
      setIsLoading(false);

      if (res) {
        setAthletes(res.athletes);
        setItemsCount(res.total);
      }
    };

    if (filters.delaySearch) {
      if (timerInt) {
        clearTimeout(timerInt);
      }
      timerInt = setTimeout(search, 300);
    } else {
      search();
    }
  };
  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurPage(value);
  };
  const handleSortClick = (event: any) => {
    setSortingAnchorEl(event.currentTarget);
  };
  const handleFilterClick = () => {
    onFiltersClick();
  };
  const handleSortClose = () => {
    setSortingAnchorEl(null);
  };
  const handleSort = (sc: SortCriteria) => {
    handleSortClose();

    setSortCriteria(sc);
  };
  const handleColumnTitleClick = (field: SearchableUserSortableFields | string) => {
    if (field === sortCriteria.field) {
      setSortCriteria((sc) => ({
        ...sc,
        direction:
          sc.direction === SearchableSortDirection.asc ? SearchableSortDirection.desc : SearchableSortDirection.asc,
      }));
    } else {
      const theSortCriteria = sortCriterias.find((item) => item.field === field);

      if (theSortCriteria) {
        setSortCriteria({
          ...theSortCriteria,
          field: field,
          direction: SearchableSortDirection.asc,
        });
      }
    }
  };
  const handleDeleteFilterClick = (filterGroup: string, filterKey: string) => {
    onDeleteFilterClick(filterGroup, filterKey);
  };
  const handelClearAllFiltersClick = () => {
    onClearAllFiltersClick();
  };
  const handleAthleteClick = (user: User) => {
    history.push(getAthleteProfileRoute(user));
  };

  useEffect(() => {
    fetchAthletes();
  }, [curPage, sortCriteria]);

  useEffect(() => {
    setCurPage(1);
    fetchAthletes();
  }, [filters]);

  useEffect(() => {
    setPageCount(Math.ceil(itemsCount / pageSize));
  }, [itemsCount]);

  return (
    <div className={classes.athletesBlockRoot}>
      {/* Sort + Count */}
      <Box mb={1}>
        <Grid container justifyContent="space-between">
          <Grid item>
            {/* Desktop */}
            <Hidden smDown>
              <Typography variant="body1" align="left" gutterBottom>
                <b>{itemsCount} athletes</b>
              </Typography>
            </Hidden>
            {/* Mobile/Tablet */}
            <Hidden smUp>
              <Chip
                variant="outlined"
                size="small"
                avatar={activeFiltersList.length > 0 ? <Avatar>{activeFiltersList.length}</Avatar> : <></>}
                label={
                  <Box display="flex" alignItems="vertical" alignContent="vertical">
                    <Typography variant="body2" style={{ lineHeight: 1.6 }}>
                      Filters
                    </Typography>
                  </Box>
                }
                clickable
                color="primary"
                onClick={handleFilterClick}
              />
            </Hidden>
          </Grid>
          <Grid item>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <Typography variant="body2">Sort on:</Typography>
              </Grid>
              <Grid item>
                <Chip
                  variant="outlined"
                  size="small"
                  label={
                    <Box display="flex" alignItems="vertical" alignContent="vertical">
                      <Typography variant="body2" style={{ lineHeight: 1.6 }}>
                        {sortCriteria.label}
                      </Typography>
                      {sortCriteria.direction === SearchableSortDirection.asc && <ArrowDownwardIcon fontSize="small" />}
                      {sortCriteria.direction === SearchableSortDirection.desc && <ArrowUpwardIcon fontSize="small" />}
                    </Box>
                  }
                  clickable
                  color="primary"
                  onClick={handleSortClick}
                />
                <Menu
                  id="sorting-menu"
                  anchorEl={sortingAnchorEl}
                  keepMounted
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(sortingAnchorEl)}
                  onClose={handleSortClose}
                >
                  {sortCriterias.map((sc: SortCriteria, index: number) => (
                    <MenuItem key={index} onClick={handleSort.bind(null, sc)}>
                      {sc.label}
                      <ListItemIcon>
                        {sc.direction === SearchableSortDirection.asc && <ArrowDownwardIcon fontSize="small" />}
                        {sc.direction === SearchableSortDirection.desc && <ArrowUpwardIcon fontSize="small" />}
                      </ListItemIcon>
                    </MenuItem>
                  ))}
                </Menu>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      {/* Active filters list */}
      <Hidden smDown>
        <Box mb={1}>
          <ActiveFiltersList
            activeFiltersList={activeFiltersList}
            onDeleteClick={handleDeleteFilterClick}
            onClearAllClick={handelClearAllFiltersClick}
          />
        </Box>
      </Hidden>

      {/* Athletes table */}
      <Box className={classes.athletesTableWrapper} mb={1}>
        <table className={classes.athletesTable}>
          {/* Table Head */}
          <thead>
            <tr>
              {/* Athlete Name & Country */}
              <th className={classes.firstCol} align="left">
                <ColumnTitle
                  label="Athlete"
                  field={SearchableUserSortableFields.name}
                  direction={sortCriteria.direction}
                  isActive={sortCriteria.field === SearchableUserSortableFields.name}
                  onClick={handleColumnTitleClick}
                />
              </th>
              {/* Desktop */}
              <Hidden smDown>
                {/* Sport */}
                <th align="left">
                  <ColumnTitle
                    label="Sports"
                    field={SearchableUserSortableFields.sport}
                    direction={sortCriteria.direction}
                    isActive={sortCriteria.field === SearchableUserSortableFields.sport}
                    onClick={handleColumnTitleClick}
                  />
                </th>
                {/* Page Views */}
                <th className={classes.lastCol} align="right">
                  <ColumnTitle
                    label="Pageviews"
                    field={'stats_numUniqueViews'}
                    direction={sortCriteria.direction}
                    isActive={sortCriteria.field === 'stats_numUniqueViews'}
                    onClick={handleColumnTitleClick}
                  />
                </th>
              </Hidden>
              {/* Mobile/Tablet */}
              <Hidden smUp>
                {/* Page Views */}
                <th className={classes.lastCol} align="right">
                  <ColumnTitle
                    label={sortCriteria.field === SearchableUserSortableFields.sport ? 'Sport' : 'Pageviews'}
                    field={
                      sortCriteria.field === SearchableUserSortableFields.sport
                        ? SearchableUserSortableFields.sport
                        : 'stats_numUniqueViews'
                    }
                    direction={sortCriteria.direction}
                    isActive={
                      sortCriteria.field === SearchableUserSortableFields.sport ||
                      sortCriteria.field === 'stats_numUniqueViews'
                    }
                    onClick={handleColumnTitleClick}
                  />
                </th>
              </Hidden>
            </tr>
          </thead>
          {/* Table Body */}
          <tbody>
            {athletes.map((athlete) => (
              <tr key={athlete.id}>
                {/* Athlete */}
                <td className={classes.firstCol} align="left">
                  <table className={classes.athleteInfoTable}>
                    <tbody>
                      <tr>
                        <td style={{ width: `${avatarW}px` }}>
                          <AthleteAvatar
                            className={classes.athleteAvatar}
                            user={athlete}
                            onClick={handleAthleteClick}
                          />
                        </td>
                        <td>
                          <Typography className={classes.athleteField} variant="body1" noWrap>
                            {athlete.name}
                          </Typography>
                          <Typography className={classes.athleteField} variant="body2" noWrap>
                            <span>{countryToFlag(athlete.country)}</span> {getCountryName(athlete.country)}
                          </Typography>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                {/* Desktop */}
                <Hidden smDown>
                  {/* Sports */}
                  <td align="left">
                    <Typography variant="body1">{getSportName(athlete.sport)}</Typography>
                  </td>
                  {/* Pageviews */}
                  <td className={classes.lastCol} align="right">
                    {athlete.stats?.numUniqueViews || 0}
                  </td>
                </Hidden>
                {/* Mobile/Tablet */}
                <Hidden smUp>
                  {/* Pageviews */}
                  <td className={classes.lastCol} align="right">
                    {sortCriteria.field === SearchableUserSortableFields.sport && (
                      <>
                        <Typography className={classes.sportName} variant="body1" noWrap>
                          {getSportName(athlete.sport)}
                        </Typography>
                      </>
                    )}
                    {sortCriteria.field !== SearchableUserSortableFields.sport && (
                      <>{athlete.stats?.numUniqueViews || 0}</>
                    )}
                  </td>
                </Hidden>
              </tr>
            ))}
          </tbody>
        </table>
        {/* Spinner */}
        <LocalBackdrop open={isLoading} invisible={true}>
          <CircularProgress color="primary" size={60} />
        </LocalBackdrop>
        {/* Pagination */}
        {Boolean(pageCount) && (
          <Box display="flex" justifyContent="center" mt={isBigScreen ? 4 : 2}>
            <Pagination
              count={pageCount}
              page={curPage}
              color="primary"
              size={isBigScreen ? 'medium' : 'small'}
              siblingCount={1}
              onChange={handlePageChange}
            />
          </Box>
        )}
      </Box>
    </div>
  );
};

export default withWidth()(AthletesBlock);

function generateQueryFilter(filters: Filters) {
  const queryFilter: any = { and: [] };

  // Athlete name
  if (filters.name) {
    queryFilter.name = {
      matchPhrasePrefix: filters.name,
    };
  }

  // Country filter
  if (filters.country) {
    queryFilter.country = {
      eq: filters.country.value,
    };
  }

  // Genders filter
  const genderOr: { gender: { eq: string } }[] = [];
  Object.entries(filters.genders).forEach(([key, value]) => {
    if (value) {
      genderOr.push({ gender: { eq: key } });
    }
  });
  if (genderOr.length > 0) {
    queryFilter.and.push({ or: genderOr });
  }

  // Sports filter
  const sportsOr: { sport: { eq: string } }[] = [];
  Object.entries(filters.sports).forEach(([key, value]) => {
    if (value) {
      sportsOr.push({ sport: { eq: key } });
    }
  });
  if (sportsOr.length > 0) {
    queryFilter.and.push({ or: sportsOr });
  }

  if (queryFilter.and.length === 0) {
    delete queryFilter.and;
  }

  return queryFilter;
}
