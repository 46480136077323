import React from 'react';
import ReactDOM from 'react-dom';
import Amplify, { API, Auth, Hub } from 'aws-amplify';
import { HelmetProvider } from 'react-helmet-async';
import ReactGA from 'react-ga';
import * as log from 'loglevel';
import remote from 'loglevel-plugin-remote';
import { BrowserRouter } from 'react-router-dom';
import { Elements as StripeElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import '@stripe/stripe-js';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import {
  awsExports,
  environment,
  firebaseApiKey,
  firebaseAppId,
  firebaseMessagingSenderId,
  firebaseProjectId,
  firebaseClientKey,
  gaDebug,
  gaId,
  stripePublicKey,
} from './config';
import './index.css';
import App from './App';
import './i18n';
import { AuthStateProvider } from './core/stores/auth';
import { AppStateProvider } from './core/stores/app';
import registerPWASW from './PWAConfig';

//

initAmplify();
initLogging();
initAnalytics();
initPushNotificationsSw();
initPushNotifications();

registerPWASW();

// Stripe
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(stripePublicKey);

ReactDOM.render(
  <React.StrictMode>
    <AppStateProvider>
      <AuthStateProvider>
        <HelmetProvider>
          <StripeElements stripe={stripePromise}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </StripeElements>
        </HelmetProvider>
      </AuthStateProvider>
    </AppStateProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// ------------------------ Functions ----------------------------------
function initAmplify() {
  Amplify.configure(awsExports);
  // Use ID token instead of access token in GraphQL API calls
  Amplify.configure({
    API: {
      // Necessary for graphQL to be able to use 'custom:userId'
      graphql_headers: async () => {
        try {
          const session = await Auth.currentSession();
          const idToken = session.getIdToken().getJwtToken();

          return {
            Authorization: idToken,
          };
        } catch (err) {
          // console.error('graphql_headers', err);
        }

        return {};
      },
    },
    Analytics: {
      autoSessionRecord: false,
    },
  });
}

function initLogging() {
  // Amplify.Logger.LOG_LEVEL = 'DEBUG';
  const isDev = environment === 'development';
  log.setLevel(isDev ? log.levels.DEBUG : log.levels.ERROR, true);
  // Remote logs only if production (cloud build)
  if (!isDev) {
    (async function () {
      try {
        const endpoint = await API.endpoint('loggingapi');
        const { sessionToken } = await API.Credentials.get();

        remote.apply(log, {
          url: `${endpoint}/log`,
          method: 'POST',
          headers: {
            'x-amz-security-token': sessionToken,
          },
          token: '',
          onUnauthorized: (failedToken) => {
            console.log('unAuth', failedToken);
          },
          timeout: 0,
          interval: 1000,
          level: 'trace',
          backoff: {
            multiplier: 2,
            jitter: 0.1,
            limit: 30000,
          },
          capacity: 500,
          stacktrace: {
            levels: ['trace', 'warn', 'error'],
            depth: 3,
            excess: 0,
          },
          timestamp: () => new Date().toISOString(),
          format: remote.json,
        });
      } catch (err) {
        console.log('ERROR', err);
      }
    })();
  }
}

function initAnalytics() {
  // Report to challenge-{env}.peakz.com only
  ReactGA.initialize(gaId, {
    debug: gaDebug,
  });
}

function initPushNotifications() {
  try {
    initializeApp({
      apiKey: firebaseApiKey,
      projectId: firebaseProjectId,
      messagingSenderId: firebaseMessagingSenderId,
      appId: firebaseAppId,
    });
    const messaging = getMessaging();

    getToken(messaging, {
      vapidKey: firebaseClientKey,
    })
      .then((currentToken) => {
        if (currentToken) {
          // Send the token to your server and update the UI if necessary
          console.log('Here the token (2)', currentToken);
          Hub.dispatch('pushNotifications', {
            event: 'newToken',
            data: currentToken,
          });
        } else {
          // Show permission request UI
          console.log('No registration token available. Request permission to generate one.');
        }
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        const permissionResult = Notification.requestPermission((result) => {
          console.log(result);
        });

        if (permissionResult) {
          permissionResult.then(
            (r) => {
              console.log('permissionResult', r);
            },
            (e) => {
              console.log('e', e);
            },
          );
        }
      });

    onMessage(messaging, (payload) => {
      console.log(payload);
      Hub.dispatch('pushNotifications', {
        event: 'newNotification',
        data: payload.notification,
      });
    });
  } catch (err) {
    // Nothing
  }
}

function initPushNotificationsSw() {
  // Register the Push Notifications service worker
  if ('serviceWorker' in navigator) {
    console.log('ServiceWorker Registration started');
    const firebaseConfig = encodeURIComponent(
      JSON.stringify({
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
      }),
    );
    navigator.serviceWorker
      .register(`/firebase-messaging-sw.js?firebaseConfig=${firebaseConfig}`)
      .then(function (registration) {
        console.log('Registration successful, scope is:', registration.scope);
      })
      .catch(function (err) {
        console.log('Service worker registration failed, error:', err);
      });
  }
}
