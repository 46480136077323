export interface Currency {
  name: string;
  symbol: string;
  code: string;
  ratio: number;
  round: number;
}

export interface UserCurrencyConfig {
  currencyCode: string;
  userDefined: boolean;
}

export const getCurrencyPrecission = (currency: string) => {
  const referenceConv = new Intl.NumberFormat('en-GB', { style: 'currency', currency: currency }).format(100);
  const decimals = referenceConv.split('.')[1];
  const precission = decimals ? decimals.length : 0;

  return precission;
};

export const getCurrencyFactor = (currency: string) => {
  if (currency) {
    const referenceConv = new Intl.NumberFormat('en-GB', { style: 'currency', currency: currency }).format(100);
    const factor = parseInt('1'.concat(referenceConv.split('.')[1]), 10);

    return factor;
  }

  return 1;
};

export const formatAmount = (amountInCents: number, currency: string) => {
  const factor = getCurrencyFactor(currency);
  const formatted = (amountInCents / factor).toLocaleString(navigator.language, {
    style: 'currency',
    currency: currency,
  });

  return formatted;
};

export const toAmountMinUnit = (amount: number, currency: string) => {
  const factor = currency ? getCurrencyFactor(currency) : 1;

  return amount * factor;
};

export const toMultipleOf = (val: number, m: number) => {
  const xCeil = Math.ceil(val);

  if (xCeil % m === 0) {
    return xCeil;
  }

  const r = Math.floor(xCeil / m);

  return (r + 1) * m;
};

export default {
  getCurrencyPrecission,
  getCurrencyFactor,
  formatAmount,
  toAmountMinUnit,
  toMultipleOf,
};
