import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import Skeleton from '@material-ui/lab/Skeleton';

import { User } from '../../core/graphql/types';
import userHelpers from '../../core/helpers/user';

const avatarWidthXs = 40;
const avatarWidthSm = 60;
const avatarWidthLg = 80;

const useStyles = makeStyles((theme) => ({
  avatarWrapper: {
    width: theme.typography.pxToRem(avatarWidthXs),
    height: theme.typography.pxToRem(avatarWidthXs),
    [theme.breakpoints.up('xs')]: {
      width: theme.typography.pxToRem(avatarWidthSm),
      height: theme.typography.pxToRem(avatarWidthSm),
    },
    [theme.breakpoints.up('md')]: {
      width: theme.typography.pxToRem(avatarWidthLg),
      height: theme.typography.pxToRem(avatarWidthLg),
    },
    color: 'white',
    // backgroundColor: 'white',
    cursor: 'pointer',
  },
  avatar: {
    width: '100%',
    height: '100%',
    color: 'white',
    cursor: 'pointer',
    border: `${theme.typography.pxToRem(2)} solid ${theme.palette.background.paper}`,
  },
  children: {
    pointerEvents: 'none',
    border: `${theme.typography.pxToRem(2)} solid ${theme.palette.background.paper}`,
    width: '18px',
    height: '18px',
    '& > svg': {
      fontSize: '10px',
    },
    [theme.breakpoints.up('md')]: {
      width: '24px',
      height: '24px',
      '& > svg': {
        fontSize: '12px',
      },
    },
    backgroundColor: theme.palette.primary.main,
  },
}));

interface Props {
  user?: User;
  auxText?: string;
  className?: any;
  style?: any;
  children?: React.ReactNode;
  onClick?: (user: User) => void;
}

const AthleteAvatar: React.FC<Props> = ({ user, auxText, children, className, style, onClick }: Props) => {
  const classes = useStyles();
  const popoverId = `avatar-user-${user?.id}`;
  const handleAvatarClick = () => {
    if (user && onClick) {
      onClick(user);
    }
  };

  return (
    <>
      {user && (
        <Badge
          className={className ? className : classes.avatarWrapper}
          aria-describedby={popoverId}
          overlap="circular"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          badgeContent={children && <Avatar className={classes.children}>{children}</Avatar>}
        >
          <Avatar
            className={classes.avatar}
            style={style}
            alt="Remy Sharp"
            src={user && userHelpers.getProfileImg(user)}
            onClick={handleAvatarClick}
          >
            {user && !user.profileImgS3Key && <Typography variant="h6">{userHelpers.getInitials(user)}</Typography>}
            {!user && auxText && <Typography variant="h6">{auxText}</Typography>}
          </Avatar>
        </Badge>
      )}
      {!user && <Skeleton className={className ? className : classes.avatarWrapper} variant="circle"></Skeleton>}
    </>
  );
};

export default AthleteAvatar;
