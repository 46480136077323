import { API } from 'aws-amplify';

import { GRAPHQL_AUTH_MODE, TaxRate } from '../graphql/types';
import {
  attachPaymentMethod as attachPaymentMethodMut,
  detachPaymentMethod as detachPaymentMethodMut,
  subscribeToAthlete as subscribeToAthleteMut,
  cancelSubscription as cancelSubscriptionMut,
  createTier as createTierMut,
} from '../graphql/customMutations';
import {
  isUserCustomer as isUserCustomerQuery,
  listPaymentMethods,
  listInvoices,
  listTiersByUserId,
  listSubscriptionsBySubscriber,
  getTier,
  getTaxRateByCountry,
} from '../graphql/customQueries';

export const isUserCustomer = async () => {
  const result: any = await API.graphql({
    query: isUserCustomerQuery,
    variables: {},
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  });

  if (result.data.isUserCustomer) {
    return result.data.isUserCustomer;
  }

  return null;
};

export const fetchPaymentMethods = async () => {
  const result: any = await API.graphql({
    query: listPaymentMethods,
    variables: {},
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  });

  if (result.data.listPaymentMethods) {
    return result.data.listPaymentMethods;
  }

  return null;
};

export const fetchSubscriptions = async (subscriberId: string) => {
  const result: any = await API.graphql({
    query: listSubscriptionsBySubscriber,
    variables: {
      subscriberId,
    },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  });

  if (result.data.listSubscriptionsBySubscriber.items) {
    return result.data.listSubscriptionsBySubscriber.items;
  }

  return null;
};

export const fetchInvoices = async () => {
  const result: any = await API.graphql({
    query: listInvoices,
    variables: {},
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  });

  if (result.data.listInvoices) {
    return result.data.listInvoices;
  }

  return null;
};

export const fetchTiers = async (userId: string) => {
  const result: any = await API.graphql({
    query: listTiersByUserId,
    variables: {
      userId: userId,
    },
    authMode: GRAPHQL_AUTH_MODE.API_KEY,
  });

  if (result.data.listTiersByUserId.items) {
    return result.data.listTiersByUserId.items;
  }

  return null;
};

export const fetchTierById = async (tierId: string) => {
  const result: any = await API.graphql({
    query: getTier,
    variables: {
      id: tierId,
    },
    authMode: GRAPHQL_AUTH_MODE.API_KEY,
  });

  if (result.data.getTier) {
    return result.data.getTier;
  }

  return null;
};

export const attachPaymentMethod = async (paymentMethodId: string) => {
  const res: any = await API.graphql({
    query: attachPaymentMethodMut,
    variables: {
      paymentMethodId: paymentMethodId,
    },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  });

  if (res.data.attachPaymentMethod) {
    return res.data.attachPaymentMethod;
  }

  return null;
};

export const detachPaymentMethod = async (paymentMethodId: string) => {
  const res: any = await API.graphql({
    query: detachPaymentMethodMut,
    variables: {
      paymentMethodId: paymentMethodId,
    },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  });

  if (res.data.detachPaymentMethod) {
    return res.data.detachPaymentMethod;
  }

  return null;
};

export const subscribeToAthlete = async (athleteId: string, tierId: string, currency: string, taxRateId?: string) => {
  const res: any = await API.graphql({
    query: subscribeToAthleteMut,
    variables: {
      athleteId,
      tierId,
      currency,
      taxRateId,
    },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  });

  if (res.data.subscribeToAthlete) {
    return res.data.subscribeToAthlete;
  }

  return null;
};

export const cancelSubscription = async (athleteId: string) => {
  const res: any = await API.graphql({
    query: cancelSubscriptionMut,
    variables: {
      athleteId,
    },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  });

  if (res.data.cancelSubscription) {
    return res.data.cancelSubscription;
  }

  return null;
};

export const createTier = async (name: string, currency: string, unitAmount: number) => {
  const res: any = await API.graphql({
    query: createTierMut,
    variables: {
      name: name,
      currency: currency,
      unitAmount: unitAmount,
    },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  });

  if (res.data.createTier) {
    return res.data.createTier;
  }

  return null;
};

export const getCountryTaxRate = async (country: string): Promise<TaxRate | null> => {
  const res: any = await API.graphql({
    query: getTaxRateByCountry,
    variables: {
      country: country,
    },
    authMode: GRAPHQL_AUTH_MODE.API_KEY,
  });

  if (res.data.getTaxRateByCountry) {
    return res.data.getTaxRateByCountry;
  }

  return null;
};

export default {
  // get
  isUserCustomer,
  fetchSubscriptions,
  fetchInvoices,
  fetchTiers,
  getCountryTaxRate,
  // post
  attachPaymentMethod,
  detachPaymentMethod,
  subscribeToAthlete,
  cancelSubscription,
  createTier,
};
