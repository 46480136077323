import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { User } from '../../core/graphql/types';
import urlHelpers from '../../core/helpers/url';
import { PeakzTheme } from '../../theme/theme';
import ShareButton from '../../components/elements/ShareButton';
import EditableAvatar, { AvatarUpdate } from '../../containers/EditableAvatar';
import EditableCoverImage, { CoverImageUpate } from '../../containers/EditableCoverImage';

const avatarWidthXs = 160;
const avatarWidthSm = 160;
const avatarWidthLg = 160;
const useStyles = makeStyles((theme: PeakzTheme) => ({
  athleteHeaderBlockRoot: {
    position: 'relative',
    marginBottom: `${theme.typography.pxToRem(avatarWidthXs / 2)}`,
    [theme.breakpoints.up('xs')]: {
      marginBottom: `${theme.typography.pxToRem(avatarWidthSm / 2)}`,
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: `${theme.typography.pxToRem(avatarWidthLg / 2)}`,
    },
  },
  coverImage: {
    objectFit: 'cover',
    filter: 'grayscale(100%)',
    backgroundColor: 'white',
    borderRadius: '0px 0px 5px 5px',
    overflow: 'hidden',
    maxHeight: theme.typography.pxToRem(480),
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0px',
    },
  },
  coverImagePlaceHolder: {
    position: 'absolute',
    top: '0',
    left: '0',
  },
  avatarWrapper: {
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%, -0%)',
    // bottom: theme.spacing(-2),
    bottom: `-${theme.typography.pxToRem(avatarWidthXs / 2)}`,
    [theme.breakpoints.up('xs')]: {
      bottom: `-${theme.typography.pxToRem(avatarWidthSm / 2)}`,
    },
    [theme.breakpoints.up('md')]: {
      bottom: `-${theme.typography.pxToRem(avatarWidthLg / 2)}`,
    },
  },
  avatarEditIcon: {
    border: `${theme.typography.pxToRem(1)} solid ${theme.palette.background.paper}`,
    width: '36px',
    height: '36px',
    '& >  svg': {
      color: theme.palette.text.primary,
    },
  },
  avatar: {
    width: theme.typography.pxToRem(avatarWidthXs),
    height: theme.typography.pxToRem(avatarWidthXs),
    [theme.breakpoints.up('xs')]: {
      width: theme.typography.pxToRem(avatarWidthSm),
      height: theme.typography.pxToRem(avatarWidthSm),
    },
    [theme.breakpoints.up('md')]: {
      width: theme.typography.pxToRem(avatarWidthLg),
      height: theme.typography.pxToRem(avatarWidthLg),
    },
  },
  coverEditButtonWrapper: {
    position: 'absolute',
    right: theme.spacing(4),
    bottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      right: theme.spacing(1),
      bottom: theme.spacing(1),
    },
  },
  rightActionsWrapper: {
    position: 'absolute',
    paddingTop: theme.spacing(1),
    right: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(0.5),
      right: theme.spacing(1),
    },
  },
  leftActionsWrapper: {
    position: 'absolute',
    left: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(0.5),
      left: theme.spacing(1),
    },
  },
  actionButton: {
    border: `${theme.typography.pxToRem(1)} solid ${theme.palette.peakz.gray}`,
  },
}));

interface Props {
  user?: User;
  editable: boolean;
  onUserUpdated: (update: {
    profileImgS3Key?: string;
    coverImgS3Key?: string;
    givenName?: string;
    familyName?: string;
  }) => void;
}

const AthleteInfo: React.FC<Props> = ({ user, editable, onUserUpdated }: Props) => {
  const classes = useStyles();
  const handleCoverImageUpdated = (updateObj: CoverImageUpate) => {
    onUserUpdated(updateObj);
  };
  const handleAvatarUpdated = (updateObj: AvatarUpdate) => {
    onUserUpdated(updateObj);
  };

  return (
    <div className={classes.athleteHeaderBlockRoot}>
      {/* Cover image */}
      <EditableCoverImage user={user} editable={editable} onUpdated={handleCoverImageUpdated} />

      {/* Avatar */}
      <div className={classes.avatarWrapper}>
        <EditableAvatar className={classes.avatar} user={user} editable={editable} onUpdated={handleAvatarUpdated} />
      </div>

      {/* Settings Button */}
      <div className={classes.rightActionsWrapper}>
        <Grid container spacing={1}>
          {user && (
            <Grid item>
              <ShareButton
                title={`${user.name} (${user.country})`}
                description={user.mission ? user.mission : ''}
                shareUrl={urlHelpers.getUserShareUrl(user)}
              />
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
};

export default AthleteInfo;
