import { API } from 'aws-amplify';
import {
  getPaymentsAccount,
  getBalance,
  getBalanceTransactions,
  getPayouts,
  getPaymentsAccountLink as getPaymentsAccountLinkQuery,
} from '../graphql/customQueries';
import { createPaymentsAccount as createPaymentsAccountMut } from '../graphql/customMutations';
import { BalanceTransaction, GRAPHQL_AUTH_MODE, PaymentsAccount } from '../graphql/types';

/*
 * https://stripe.com/docs/connect/identity-verification-api
 * When requirements[currently_due] is not empty, additional information is required.
 * Connected accounts may be blocked from creating charges, receiving payouts, or performing certain
 * tasks if you don’t provide this information in a timely manner.
 *
 * */
export const stripeAccountFields: { [key: string]: { label: string; priority: number } } = {
  email: { label: 'Email', priority: 1 },
  country: { label: 'Country', priority: 2 },
  default_currency: { label: 'Default currency', priority: 2 },
  business_type: { label: 'Business type', priority: 2 },
  external_account: { label: 'External account', priority: 3 },
  'individual.address': { label: 'Personal address', priority: 4 },
  'individual.dob': { label: 'Date of birth', priority: 4 },
  'individual.verification.document': { label: 'Identification document', priority: 4 },
  'individual.verification.additional_document': { label: 'Identification document', priority: 4 },
  individual: { label: 'Personal data', priority: 4 },
  business_profile: { label: 'Business data', priority: 5 },
  company: { label: 'Company data', priority: 5 },
  documents: { label: 'Documents', priority: 6 },
  tos_acceptance: { label: 'Accept terms of service', priority: 7 },
  capabilities: { label: 'Capabilities', priority: 8 },
};

export const getPaymentsAccountLink = async (refreshUrl: string, returnUrl: string) => {
  const balanceRes: any = await API.graphql({
    query: getPaymentsAccountLinkQuery,
    variables: {
      refreshUrl,
      returnUrl,
    },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  });

  return balanceRes.data.getPaymentsAccountLink;
};

export const getMyPaymentsAccount = async () => {
  const balanceRes: any = await API.graphql({
    query: getPaymentsAccount,
    variables: {},
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  });

  return balanceRes.data.getPaymentsAccount;
};

export const getMyBalance = async () => {
  const balanceRes: any = await API.graphql({
    query: getBalance,
    variables: {},
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  });

  return balanceRes.data.getBalance;
};

export const getMyBalanceTransactions = async (
  type: 'payment' | 'payout',
): Promise<{ data: BalanceTransaction[]; has_more: boolean }> => {
  const balanceTrxRes: any = await API.graphql({
    query: getBalanceTransactions,
    variables: {
      type,
    },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  });

  return balanceTrxRes.data.getBalanceTransactions;
};

export const getMyPayouts = async (): Promise<{ data: BalanceTransaction[]; has_more: boolean }> => {
  const balanceTrxRes: any = await API.graphql({
    query: getPayouts,
    variables: {
      type: 'payout',
    },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  });

  return balanceTrxRes.data.getPayouts;
};

const getRemainingFieldsNames = (
  paymentsAccount: PaymentsAccount,
  type: 'currently_due' | 'pending_verification',
): { found: string[]; notFound: string[] } => {
  const missingFields =
    type === 'currently_due'
      ? paymentsAccount.requirements?.currently_due
      : paymentsAccount.requirements?.pending_verification;

  if (missingFields && missingFields.length > 0) {
    const fieldNamesFoundHash: { [key: string]: { label: string; priority: number } } = {};
    const fieldsNotFound: string[] = [];

    missingFields.forEach((fieldId) => {
      if (fieldId) {
        const parts = fieldId.split('.');
        let joinedParts: string;
        let match: { label: string; priority: number } | null = null;

        for (let numParts = parts.length; numParts >= 0 && !match; numParts -= 1) {
          joinedParts = parts.slice(0, numParts).join('.');

          if (stripeAccountFields[joinedParts]) {
            match = stripeAccountFields[joinedParts];
          }
        }

        if (!match) {
          fieldsNotFound.push(fieldId);
        } else if (!fieldNamesFoundHash[match.label]) {
          fieldNamesFoundHash[match.label] = match;
        }
      }
    });

    const fieldNamesFound = Object.values(fieldNamesFoundHash)
      .sort((a, b) => a.priority - b.priority)
      .map((f) => f.label);

    return { found: fieldNamesFound, notFound: fieldsNotFound };
  }

  return { found: [], notFound: [] };
};

export const createPaymentsAccount = async (): Promise<string> => {
  const result: any = await API.graphql({
    query: createPaymentsAccountMut,
    variables: {},
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  });

  if (result.data.createPaymentsAccountMut) {
    return result.data.createPaymentsAccountMut;
  }

  return '';
};

export const createCheckoutSession = async (
  stripeAccountId: string,
  description: string,
  amount: number,
  currency: string,
  successUrl: string,
  cancelUrl: string,
  intent: 'subscription' | 'donation',
  meta: any,
) => {
  const { sessionId } = await API.post('striperestapi', '/checkout/session', {
    body: {
      stripeAccountId,
      amount,
      description,
      currency,
      successUrl,
      cancelUrl,
      intent,
      ...meta,
    },
  });

  return sessionId;
};

// export const redirectToCheckout = async (
//   checkoutSessionId: string,
//   user: User,
//   intent: 'donation' | 'subscription',
// ) => {
//   const cacheExpirationMs = Date.now() + 5 * 60 * 1000; // expires in 5 minutes
//   const cacheExpirationDate = new Date(cacheExpirationMs);
//   const checkoutRedirectPath = `${window.location.pathname}${window.location.search}`;
//
//   Cache.setItem('checkoutSessionId', checkoutSessionId, { expires: cacheExpirationDate.getTime() });
//   Cache.setItem('checkoutRedirectPath', checkoutRedirectPath);
//   Cache.setItem('checkoutIntent', intent);
//   Cache.setItem('checkoutAthleteName', user.name);
//   Cache.setItem('checkoutAthleteId', user.id);
//
//   if (window.Stripe) {
//     const stripe =
//       intent === 'donation'
//         ? window.Stripe(stripePublicKey)
//         : window.Stripe(stripePublicKey, {
//             stripeAccount: user.stripeAccountId,
//           });
//
//     // doc: https://stripe.com/docs/js/checkout/redirect_to_checkout
//     await stripe.redirectToCheckout({
//       sessionId: checkoutSessionId,
//     });
//   }
// };

export default {
  stripeAccountFields,
  // get
  getPaymentsAccountLink,
  getMyPaymentsAccount,
  getMyBalance,
  getMyBalanceTransactions,
  getMyPayouts,
  getRemainingFieldsNames,
  // post
  createPaymentsAccount,
  createCheckoutSession,
  // misc
  // redirectToCheckout,
};
