import React, { useState, useEffect } from 'react';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import SwipeableViews from 'react-swipeable-views';
import { lighten } from '@material-ui/core';
import { Box, Container, Grid, Typography, makeStyles, Tab, Tabs, useTheme, useMediaQuery } from '@material-ui/core';

import AppHeaderPlaceHolder from '../components/layouts/AppHeaderPlaceHolder';
import OverlayedHeaderText from '../components/ui/OverlayedHeaderText';
import { letterSpacing, PeakzTheme } from '../theme/theme';
import {
  CONTENTFUL_MODELS,
  fetchContentByType,
  getBlogsByCategory,
  getBlogCategories,
  filterBlogsByCategorization,
} from '../core/services/contentfulSvc';
import { Blogs } from '../core/contentful/types';
import { Link } from 'react-router-dom';
import domHelpers from '../core/helpers/dom';
import { v4 as uuidv4 } from 'uuid';
import WarningMessage from '../components/ui/WarningMessage';
import * as log from 'loglevel';
import SpinnerBox from '../components/ui/SpinnerBox';
import { getContentfulAssetURL, getContentfulItemID } from '../core/helpers/misc';
import { getOptimisedImgUrl } from '../core/helpers/url';
import { useTranslation } from 'react-i18next';

interface Props {
  value: number;
  index: number;
  blogs: Blogs[];
}

const useStylesForPanel = makeStyles((theme) => ({
  boxPortrait: {
    height: '230px',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    boxShadow: `0 0 ${theme.typography.pxToRem(15)} 0 black`,
  },
  positionedImg: {
    position: 'absolute',
    width: '90%',
    height: '102%',
  },
  blogTitle: {
    textOverflow: 'ellipsis',
    letterSpacing: letterSpacing.tiny,
    textDecoration: 'underline',
    '& b': {
      color: 'black',
    },
  },
}));

const TabPanel: React.FC<Props> = ({ blogs }: Props) => {
  const classes = useStylesForPanel();
  const theme: PeakzTheme = useTheme();
  const isSmallScreen = domHelpers.isSmallScreen(useMediaQuery, theme);

  return (
    <Box mt={4} pb={5}>
      <Grid style={isSmallScreen ? { display: 'flex' } : {}} spacing={0} container>
        {blogs.map((blog: Blogs) => {
          return (
            <Grid
              key={uuidv4()}
              style={{ padding: theme.typography.pxToRem(22), position: 'relative' }}
              md={4}
              sm={6}
              xs={12}
              item
            >
              <Link to={`/blogs/${getContentfulItemID(blog)}`}>
                <Box mt={3}>
                  <Box className={classes.boxPortrait} style={{ backgroundColor: blog.academyBgColor?.hexColorCode }}>
                    <img className={classes.positionedImg} src={getContentfulAssetURL(blog, 'thumbnail', 25)} />
                  </Box>
                  <Box>
                    <br />
                    <Typography className={classes.blogTitle} variant="body1" noWrap>
                      <b>{blog.title}</b>
                    </Typography>
                    <Typography align="left" variant="body2" style={{ letterSpacing: letterSpacing.tiny }}>
                      {blog.shortDescription.substring(0, 100) + '...'}
                    </Typography>
                    <br />
                    <Typography
                      variant="caption"
                      style={{ letterSpacing: letterSpacing.tiny, position: 'absolute', bottom: 0 }}
                      dangerouslySetInnerHTML={{
                        __html: `<span style="color:${lighten('#000', 0.6)}">${
                          blog.date !== null
                            ? new Date(Date.parse(blog.date))
                                .toUTCString()
                                .substring(0, new Date(Date.parse(blog.date)).toUTCString().length - 4)
                            : ''
                        }</span> | <b style={{letterSpacing: "3px"}}>${blog.author.name}</b>`,
                      }}
                    ></Typography>
                  </Box>
                </Box>
              </Link>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    '& a': {
      textDecoration: 'none',
      color: 'black',
      '&:focus, &:visited, &:active': {
        color: 'black',
      },
    },
    '& .MuiTabs-flexContainer': {
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'initial',
      },
    },
  },
  tabRoot: {
    color: lighten('#000', 0.6),
    '& .Mui-selected': {
      color: 'black',
    },
    '& .react-swipeable-view-container div': {
      overflow: 'hidden',
    },
    '& .MuiTab-wrapper:hover': {
      color: 'black',
    },
  },
}));

const AcademyPage: React.FC = () => {
  /**
   * Performance here is shit, I mean, no matter what I try.
   * The problem isn't about contentful, is about the Material TAB component.
   * It is a known behaviour, doesn't handle well re-renders. I tried to load blogs
   * on demand when a category is clicked but performance is better loading all of them
   * at the beginning. Issue: https://github.com/mui-org/material-ui/issues/13319
   *
   * Things I tried:
   *
   * - useMemo() hook
   * - Implement cache from scratch
   * - Downgrade img quality (helps a bit)
   * - Load on demand (things get worse, you go from bothering the user at first to bother him everytime he switch tab)
   *
   */
  const [tabIndex, setTabIndex] = useState(0);
  const [categories, setCategories] = useState<string[]>();
  const [fetchErr, setFetchErr] = useState<boolean>(false);
  const [swipeableViews, setSwipeableViews] = useState<any[]>([]);
  let blogs: Blogs[];
  const classes = useStyles();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(1025));
  const [t] = useTranslation();

  const headerContent = {
    progressiveArr: [
      getOptimisedImgUrl('landing-header', 'academy_header.png'),
      getOptimisedImgUrl('landing-header', 'academy_header.png', 'prog'),
    ],
    headerTitle: t('landing.peakz_academy.header.title').toUpperCase(),
    headerSubtitle: '',
    CTALink: '/',
  };

  const addToSwipeableViews = (blogs: Blogs[], key: number, value: number, index: number) => {
    setSwipeableViews((prev) => [
      ...prev,
      <TabPanel blogs={blogs} key={`${blogs[0].title}${key}`} value={value} index={index} />,
    ]);
  };

  useEffect(() => {
    (async () => {
      try {
        const resp = await fetchContentByType(CONTENTFUL_MODELS.BLOGS, 'withCat', true);
        const cat = getBlogCategories(filterBlogsByCategorization(resp.data.blogsCollection.items, true));
        setCategories(cat);
        blogs = filterBlogsByCategorization(resp.data.blogsCollection.items, true);

        cat?.map((c, i) => {
          addToSwipeableViews(getBlogsByCategory(blogs, c), i, tabIndex, i);
        });
      } catch (err) {
        setFetchErr(true);
        log.error(err);
      }
    })();
  }, []);

  return (
    <Box className={classes.root}>
      <AppHeaderPlaceHolder />
      <OverlayedHeaderText content={headerContent} />
      <Box p={isTablet ? 0 : 10}>
        {!fetchErr && swipeableViews.length == 0 && <SpinnerBox />}
        {fetchErr && (
          <WarningMessage
            icon={<ErrorOutlineIcon color="primary" />}
            text="There was a problem retrieving the blogs, please try again later..."
          />
        )}
        {!fetchErr && swipeableViews.length > 0 && (
          <Container>
            <Box width={1} mt={isTablet ? 5 : 0}>
              <Typography variant="h4" align="center">
                <b>{t('landing.peakz_academy.tabs.title')}</b>
              </Typography>
            </Box>
            <Box pt={10}>
              <Tabs
                onChange={(_, v) => {
                  setTabIndex(v);
                }}
                variant="scrollable"
                scrollButtons="on"
                className={classes.tabRoot}
                value={tabIndex}
                indicatorColor="primary"
              >
                {categories?.map((category, index) => {
                  return <Tab key={index} label={category} />;
                })}
              </Tabs>
              <SwipeableViews
                index={tabIndex}
                onChangeIndex={(e, v) => {
                  setTabIndex(v);
                }}
              >
                {swipeableViews}
              </SwipeableViews>
            </Box>
          </Container>
        )}
      </Box>
    </Box>
  );
};

export default AcademyPage;
