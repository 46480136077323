import { Box, Grid, Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import VideoImageDropZone from '../../../components/ui/VideoImageDropZone';
import clsx from 'clsx';
import { Tier } from '../../../core/graphql/types';
import { appStore } from '../../../core/stores/app';
import { PostType } from '../../../API';
import AudioDropZone from '../../../components/ui/AudioDropZone';
import EditableImage, { ImageUpdate } from '../../../containers/EditableImage';

interface Props {
  fileToUpload: File | null;
  setVideoDimensions: any;
  setPodcastCoverImage: any;
  setFileToUpload: any;
  tier: Tier | undefined;
  videoPlayer?: JSX.Element | any;
  audioPlayer?: JSX.Element | any;
  audioCoverSrc?: JSX.Element | any;
  classes: any;
  postType: PostType | undefined;
}

const PostStep0: React.FC<Props> = ({
  fileToUpload,
  tier,
  videoPlayer,
  audioPlayer,
  setPodcastCoverImage,
  audioCoverSrc,
  classes,
  postType,
  setVideoDimensions,
  setFileToUpload,
}: Props) => {
  const [, appDispatch] = useContext(appStore);
  const handleVideoFileSelected = (files: File[], videoDimensions: any) => {
    if (!fileToUpload && files.length > 0) {
      const file = files[0];

      setVideoDimensions({ width: String(videoDimensions.width), height: String(videoDimensions.height) });

      console.log('set fileToUpload');
      setFileToUpload(file);
    }
  };

  const handlePodcastFileSelected = (acceptedFiles: File[]) => {
    setFileToUpload(acceptedFiles[0]);
  };

  const handlePodcastCoverSelected = (update: ImageUpdate) => {
    setPodcastCoverImage(update.src);
  };

  const handleFileRejected = (rejections: any) => {
    const fileRejection = rejections[0];

    if (fileRejection) {
      appDispatch({
        type: 'showToast',
        toastTxt: fileRejection.errors[0].message,
        toastSeverity: 'error',
      });
    }
  };

  return (
    <>
      {!fileToUpload && postType === PostType.video && (
        <VideoImageDropZone disabled={!tier} onChange={handleVideoFileSelected} onRejected={handleFileRejected} />
      )}
      {postType === PostType.podcast && (
        <Box display="flex" alignItems="center" flexWrap="wrap" flexDirection="row" justifyContent="center">
          <Box display="flex" alignSelf="flex-start" flexDirection="column" alignItems="center">
            {!audioCoverSrc && (
              <Box mt={2}>
                <Typography variant="h6">Select the cover image</Typography>
              </Box>
            )}
            <Box mt={3}>
              <EditableImage onUpdated={handlePodcastCoverSelected} src={audioCoverSrc} />
            </Box>
          </Box>
          {!fileToUpload && (
            <Box display="flex" flexDirection="column" alignItems="center">
              <Box mt={2}>
                <Typography variant="h6">Select the audio file</Typography>
              </Box>
              <AudioDropZone disabled={!tier} onChange={handlePodcastFileSelected} onRejected={handleFileRejected} />
            </Box>
          )}
          {fileToUpload && <Box>{audioPlayer}</Box>}
        </Box>
      )}
      {fileToUpload && postType === PostType.video && (
        <Grid
          className={clsx({
            [classes.content]: true,
            [classes.blackBg]: true,
          })}
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid className={classes.maxFullWithItem} item>
            {videoPlayer}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default PostStep0;
