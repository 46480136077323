import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import * as Yup from 'yup';
import { makeRequired, makeValidate } from 'mui-rff';
import { Form } from 'react-final-form';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ReCaptchaV2 from 'react-google-recaptcha';
import { useMediaQuery } from '@material-ui/core';

import TextDivider from '../../components/ui/TextDivider';
import SubmitButton from '../../components/ui/SubmitButton';
import FacebookButton from '../../components/ui/FacebookButton';
import GoogleButton from '../../components/ui/GoogleButton';
import FormRow from '../../components/ui/FormRow';
import globalStyles from '../../theme/globalStyles';
import { pwdMinLen, recaptchaSiteKeyV2, termsLink } from '../../config';
import FormTextField from '../../components/ui/FormTextField';
import domHelpers from '../../core/helpers/dom';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  grid: {
    width: '100%',
    margin: 0,
  },
  formTextField: { ...globalStyles(theme).formTextField },
}));

interface SignupFormProps {
  submitting: boolean;
  submitType: 'cognito' | 'google' | 'facebook';
  errorTxt: string;
  errorTxtPosition?: 'top' | 'bottom';
  onSignupClick: (data: SignupFormData) => void;
  onGoogleSignInClick: () => void;
  onFacebookSignInClick: () => void;
}

export interface SignupFormData {
  name: string;
  email: string;
  emailConfirmation: string;
  password: string;
  recaptchaToken?: string;
}

const formSchema = Yup.object().shape({
  name: Yup.string().required('Name is required.'),
  email: Yup.string().trim().email('Please enter a valid email.').required('Please enter a valid email.'),
  emailConfirmation: Yup.string()
    .trim()
    .oneOf([Yup.ref('email'), null], 'Your email confirmation does not match.'),
  password: Yup.string().min(pwdMinLen, `Password needs to be at least ${pwdMinLen} characters.`),
});

const validate = makeValidate(formSchema);
const required = makeRequired(formSchema);

const SignupForm: React.FC<SignupFormProps> = ({
  submitting,
  submitType = 'cognito',
  errorTxt,
  errorTxtPosition = 'bottom',
  onSignupClick,
  onGoogleSignInClick,
  onFacebookSignInClick,
}: SignupFormProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = domHelpers.isSmallScreen(useMediaQuery, theme);
  const [recaptchaToken, setRecaptchaToken] = useState();
  const initialValues: SignupFormData = {
    name: '',
    email: '',
    emailConfirmation: '',
    password: '',
    recaptchaToken: '',
  };
  const subscription = { invalid: true };
  const handleSignInClick = (values: SignupFormData) => {
    if (recaptchaToken) {
      onSignupClick({ ...values, recaptchaToken: recaptchaToken });
    }
  };
  const handleGoogleSignInClick = () => {
    onGoogleSignInClick();
  };
  const handleFacebookSignInClick = () => {
    onFacebookSignInClick();
  };
  const handleRecaptchaV2 = (token: any) => {
    setRecaptchaToken(token);
  };

  return (
    <Form
      key={subscription as any}
      initialValues={initialValues}
      subscription={subscription}
      validate={validate}
      onSubmit={handleSignInClick}
      render={({ handleSubmit, invalid }) => (
        <form className={classes.form} onSubmit={handleSubmit} noValidate={true} autoComplete="new-password">
          <Grid className={classes.grid} container direction="column" spacing={1}>
            {/* Google Sign In Button */}
            <Grid item xs>
              <Box mt={1}>
                <GoogleButton
                  submitting={submitting && submitType === 'google'}
                  disabled={submitting}
                  onClick={handleGoogleSignInClick}
                >
                  Continue with Google
                </GoogleButton>
              </Box>
            </Grid>

            {/* Facebook Sign In Button */}
            <Grid item xs>
              <FacebookButton
                submitting={submitting && submitType === 'facebook'}
                disabled={submitting}
                onClick={handleFacebookSignInClick}
              >
                Continue with Facebook
              </FacebookButton>
            </Grid>

            {/* Error Text */}
            {errorTxt && errorTxtPosition === 'top' && (
              <Grid item xs>
                <Box mt={1}>
                  <Typography variant="body1" align="center" color="error" gutterBottom>
                    {errorTxt}
                  </Typography>
                </Box>
              </Grid>
            )}

            {/* Divider */}
            <Grid item xs>
              <Box mt={3} mb={3}>
                <TextDivider text="or" />
              </Box>
            </Grid>

            {/* Signup items */}
            <Grid item xs>
              <FormRow label="Name">
                <FormTextField id="name" name="name" type="text" required={required.name} disabled={submitting} />
              </FormRow>

              <FormRow label="Email">
                <FormTextField
                  id="email"
                  name="email"
                  type="text"
                  autoComplete="email"
                  required={required.email}
                  disabled={submitting}
                />
              </FormRow>

              <FormRow label="Confirm email">
                <FormTextField
                  id="emailConfirmation"
                  name="emailConfirmation"
                  required={required.emailConfirmation}
                  disabled={submitting}
                />
              </FormRow>

              <FormRow label="Password">
                <FormTextField
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required={required.password}
                  disabled={submitting}
                />
              </FormRow>

              <ReCaptchaV2
                sitekey={recaptchaSiteKeyV2}
                size={isSmallScreen ? 'compact' : 'normal'}
                onChange={handleRecaptchaV2}
              />

              {/* Submit button */}
              <Box mt={3}>
                {/* Error Text */}
                {errorTxt && errorTxtPosition === 'bottom' && (
                  <Box mb={1}>
                    <Typography variant="body1" align="center" color="error" gutterBottom>
                      {errorTxt}
                    </Typography>
                  </Box>
                )}
                <SubmitButton
                  submitting={submitting && submitType === 'cognito'}
                  disabled={invalid || submitting || !recaptchaToken}
                  fullWidth
                >
                  Sign Up
                </SubmitButton>
              </Box>
            </Grid>

            <Grid item xs>
              <Box mt={3} mb={1}>
                <Typography variant="body1" align={'center'}>
                  By signing up, you agree to{' '}
                  <a href={termsLink} target="_blank" rel="noreferrer">
                    Peakz's Terms of Use
                  </a>
                  .
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

export default SignupForm;
