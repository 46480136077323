import { Box, Button, Grid } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import React from 'react';

interface Props {
  activeStep: number;
  fileToUpload: File | null;
  setFileToUpload: any;
  setActiveStep: any;
  isNextDisabled: any;
  handleNextClick: any;
}

const Controls: React.FC<Props> = ({
  activeStep,
  fileToUpload,
  setFileToUpload,
  setActiveStep,
  isNextDisabled,
  handleNextClick,
}: Props) => {
  const handleBackClick = () => {
    setActiveStep((prevActiveStep: number) => Math.max(0, prevActiveStep - 1));
  };
  const handleClearFileToUploadClick = () => {
    setFileToUpload(null);
  };

  return (
    <Box mt={3} width={1}>
      <Grid container justifyContent="center" spacing={3}>
        <Grid item>
          <Box ml={2}>
            {activeStep === 1 && fileToUpload && (
              <Button variant="outlined" startIcon={<ClearIcon />} onClick={handleClearFileToUploadClick}>
                Remove
              </Button>
            )}
            {((activeStep > 1 && activeStep <= 3) || (activeStep === 1 && !fileToUpload)) && (
              <Button variant="outlined" startIcon={<ArrowLeftIcon />} onClick={handleBackClick}>
                Back
              </Button>
            )}
          </Box>
        </Grid>
        <Grid item>
          <Box mr={2}>
            <Button
              variant="contained"
              color="primary"
              endIcon={<ArrowRightIcon />}
              disabled={isNextDisabled()}
              onClick={handleNextClick}
            >
              Next
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Controls;
