import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { FacebookShareButton, WhatsappShareButton, TwitterShareButton } from 'react-share';
import copy from 'copy-to-clipboard';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { isMobile } from 'react-device-detect';
import { alpha } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {},
  item: {
    '&:nth-child(1)': {
      paddingLeft: 0,
    },
  },
  socialButton: {
    // overwrite defaults
    outline: 'none',
    paddingLeft: `${theme.typography.pxToRem(4)} !important`,
    paddingRight: `${theme.typography.pxToRem(4)} !important`,
    lineHeight: `${theme.typography.pxToRem(12)} !important`,
  },
  pointer: {
    cursor: 'pointer',
  },
  black: {
    color: theme.palette.text.primary,
    '&:hover': {
      color: theme.palette.primary.main,
    },
    '&:active': {
      color: alpha(theme.palette.primary.main, 0.8),
    },
  },
  white: {
    color: 'white',
    '&:hover': {
      color: alpha('#ffffff', 0.8),
    },
    '&:active': {
      color: alpha('#ffffff', 0.5),
    },
  },
}));

interface Props {
  shareUrl: string;
  title: string;
  description?: string;
  size?: number;
  color?: 'black' | 'white';
  justify?: 'space-around' | 'center' | 'flex-start';
  spacing?: 0 | 4 | 1 | 2 | 3 | 5 | 6 | 7 | 8 | 9 | 10 | undefined;
}

interface State {
  toastText?: string;
}

const initialState: State = {};

const SocialButtons: React.FC<Props> = ({
  shareUrl,
  title,
  description,
  size = 28,
  color = 'black',
  justify = 'space-around',
  spacing = 1,
}: Props) => {
  const classes = useStyles();
  const colorClass = color === 'black' ? classes.black : classes.white;
  const [state, setState] = useState(initialState);
  const handleCopyLink = () => {
    copy(shareUrl);

    setState({ ...state, toastText: 'Link copied!' });

    setTimeout(() => {
      setState({ ...state, toastText: '' });
    }, 3000);
  };
  const handleMobileShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: title,
          text: description,
          url: shareUrl,
        })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    }
  };
  const handleToastClose = () => {
    setState({ ...state, toastText: '' });
  };

  return (
    <Grid container justifyContent={justify} spacing={spacing}>
      {isMobile && (
        <Grid className={classes.item} item>
          <i
            className={`fas fa-share-alt ${classes.pointer} ${colorClass}`}
            style={{ fontSize: size }}
            onClick={handleMobileShare}
          ></i>
        </Grid>
      )}
      <Grid className={classes.item} item>
        <FacebookShareButton className={classes.socialButton} url={shareUrl} quote={title}>
          <i className={`fab fa-facebook-square ${colorClass}`} style={{ fontSize: size }}></i>
        </FacebookShareButton>
      </Grid>
      <Grid className={classes.item} item>
        <WhatsappShareButton className={classes.socialButton} url={shareUrl} title={title}>
          <i className={`fab fa-whatsapp ${colorClass}`} style={{ fontSize: size }}></i>
        </WhatsappShareButton>
      </Grid>
      <Grid className={classes.item} item>
        <TwitterShareButton className={classes.socialButton} url={shareUrl} title={title}>
          <i className={`fab fa-twitter ${colorClass}`} style={{ fontSize: size }}></i>
        </TwitterShareButton>
      </Grid>
      <Grid className={classes.item} item>
        <i
          className={`fas fa-link ${classes.pointer} ${colorClass}`}
          style={{ fontSize: size }}
          onClick={handleCopyLink}
        ></i>
      </Grid>

      <Snackbar
        open={Boolean(state.toastText)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={handleToastClose}
      >
        <Alert onClose={handleToastClose} severity="success">
          {state.toastText}
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default SocialButtons;
