import Filter from 'bad-words';

const wordsList = {
  es: {
    abanto: 2,
    abrazafarolas: 2,
    acabada: 2,
    adefesio: 2,
    adufe: 2,
    afilar: 1,
    agarraderas: 2,
    aguayon: 1,
    aguayón: 2,
    alcornoque: 2,
    alfeñique: 2,
    anda: 1,
    andorga: 2,
    andurriasmo: 2,
    andá: 2,
    aniz: 2,
    argolla: 1,
    arrastracueros: 2,
    arrecho: 2,
    artaban: 1,
    artabán: 2,
    asesinato: 1,
    asno: 1,
    atarre: 2,
    avergallon: 1,
    avergallón: 2,
    ayotes: 2,
    baboso: 2,
    balurde: 2,
    barrabas: 1,
    barrabás: 2,
    barriobajero: 2,
    bastardo: 2,
    bebecharcos: 2,
    bellaco: 2,
    belloto: 2,
    berzotas: 2,
    besugo: 2,
    bizcocho: 1,
    bobalicon: 1,
    bobalicón: 2,
    bocabuzon: 1,
    bocabuzón: 2,
    bocachancla: 2,
    bocallanta: 2,
    bolas: 2,
    bollera: 1,
    bollito: 2,
    bollo: 2,
    boludo: 2,
    bombin: 1,
    bombín: 2,
    boniato: 2,
    boquimuelle: 2,
    borondanga: 2,
    borraja: 2,
    borrico: 2,
    bostazo: 2,
    botarate: 2,
    brisco: 2,
    brizna: 2,
    brocha: 2,
    bruta: 2,
    bucha: 2,
    bufarron: 1,
    bufarrón: 2,
    bujarra: 2,
    bujarron: 1,
    bujarrón: 2,
    burundanga: 1,
    butifarra: 2,
    cabecipelao: 2,
    cabestro: 2,
    cabezaalberca: 2,
    cabezabuque: 2,
    cabezahueca: 1,
    cabron: 2,
    cabrona: 2,
    cabrones: 2,
    cabrón: 2,
    caca: 1,
    cacha: 2,
    cachapera: 2,
    cachar: 2,
    cacheteo: 2,
    cachilo: 2,
    cachimba: 2,
    cachivache: 1,
    cachufleta: 2,
    cafre: 2,
    cagada: 2,
    cagado: 2,
    cagalindes: 2,
    cagar: 2,
    cagarruta: 2,
    cagarse: 2,
    cagon: 2,
    cagueta: 1,
    cajeta: 1,
    calabazo: 2,
    calambuco: 2,
    calamidad: 2,
    calduo: 1,
    caldúo: 2,
    calientahielos: 2,
    caliente: 2,
    callate: 1,
    calzamonas: 2,
    cansalmas: 2,
    cantamañanas: 2,
    cantimplora: 2,
    capullo: 2,
    'cara de pupu': 2,
    caracaballo: 2,
    caracarton: 1,
    caracartón: 2,
    caraculo: 2,
    caraflema: 2,
    carajaula: 2,
    carajote: 2,
    carapapa: 2,
    carapijo: 2,
    casquete: 1,
    cazurro: 2,
    cebollino: 2,
    cenizo: 1,
    cenutrio: 2,
    ceporro: 2,
    cernicalo: 1,
    cernícalo: 2,
    cerote: 2,
    chacon: 1,
    chacón: 2,
    chancay: 2,
    chancla: 1,
    chapero: 2,
    chaqueta: 2,
    charran: 1,
    charrán: 2,
    chiche: 2,
    chichi: 2,
    chifar: 2,
    chimar: 2,
    'chinga a madre': 2,
    'chinga tu madre': 2,
    chingado: 2,
    chingar: 2,
    chiquilicuatre: 2,
    chirimbaina: 2,
    chocho: 2,
    chucha: 2,
    chupacables: 2,
    chupada: 1,
    chupador: 1,
    chupapollas: 1,
    chuparla: 2,
    chupasangre: 2,
    chuperson: 2,
    chupeton: 1,
    chupetón: 1,
    chupoptero: 1,
    chupóptero: 2,
    cierrabares: 2,
    cipote: 2,
    clavar: 1,
    cocheche: 2,
    cocones: 2,
    cocos: 1,
    coger: 2,
    cogollo: 2,
    cogote: 2,
    cojido: 2,
    cojieron: 2,
    cojiste: 2,
    cojones: 2,
    cola: 2,
    colgados: 2,
    colgazon: 1,
    colgazón: 2,
    colipato: 2,
    colison: 1,
    colisón: 2,
    collofe: 2,
    colloma: 2,
    colon: 1,
    colón: 2,
    comadres: 2,
    come: 1,
    comebolsas: 2,
    comechapas: 2,
    comeflores: 2,
    comemierda: 2,
    comer: 1,
    'comer bistec': 1,
    comestacas: 2,
    compañero: 1,
    compañeros: 1,
    concha: 1,
    'concha de tu madre': 1,
    conchudo: 2,
    conejo: 1,
    conejos: 1,
    copa: 1,
    copeton: 1,
    copetón: 2,
    coprofagia: 1,
    coquimbanos: 2,
    corneta: 1,
    cornudo: 2,
    coronta: 2,
    corota: 2,
    corotas: 2,
    corozo: 2,
    correa: 1,
    corrida: 1,
    coso: 2,
    cotorra: 2,
    coyoles: 2,
    coyones: 2,
    coyos: 2,
    coña: 1,
    coño: 2,
    cretino: 2,
    crica: 2,
    cuacha: 2,
    cuaresmeño: 2,
    cuates: 2,
    cuca: 2,
    cuchara: 2,
    cuchillo: 1,
    cucon: 2,
    cucona: 2,
    cueco: 2,
    cuerpoescombro: 2,
    culamen: 2,
    culeado: 2,
    culear: 2,
    culero: 2,
    culo: 1,
    culopollo: 2,
    culos: 2,
    curtir: 1,
    cuyones: 2,
    cállate: 1,
    descerebrado: 2,
    desgarracalzas: 2,
    desgraciarse: 2,
    dondiego: 2,
    donnadie: 2,
    echacantos: 2,
    ejarramantas: 2,
    'el puto ese': 2,
    'el puto que te pario': 2,
    'el puto que te parió': 1,
    empiernarse: 2,
    emputado: 2,
    emputecido: 2,
    energumeno: 1,
    energúmeno: 2,
    ensartar: 1,
    esbaratabailes: 2,
    escolimoso: 2,
    escornacabras: 2,
    estaca: 1,
    estafiate: 2,
    estulto: 2,
    estupido: 2,
    exprimir: 1,
    fachar: 2,
    fanfosquero: 2,
    fantoche: 2,
    fariseo: 2,
    fifar: 2,
    filiberto: 2,
    filimincias: 2,
    fleto: 2,
    foligoso: 2,
    follador: 2,
    follar: 2,
    forrar: 2,
    forro: 1,
    fulastre: 2,
    fundillo: 2,
    ganapan: 1,
    ganapio: 2,
    ganapán: 2,
    gandul: 1,
    garchar: 2,
    garompa: 2,
    gaznapiro: 1,
    gaznápiro: 2,
    gañan: 1,
    gañán: 2,
    gilipichis: 2,
    gilipollas: 2,
    gilipuertas: 2,
    giraesquinas: 2,
    gomas: 1,
    gorrino: 2,
    gorrumino: 2,
    guaranga: 2,
    guitarro: 2,
    gurriato: 2,
    gustarle: 2,
    güebo: 2,
    habahela: 1,
    habahelá: 2,
    'hacer una mamada': 2,
    'hacer una paja': 2,
    'haciendo el amor': 2,
    heroina: 1,
    heroína: 2,
    'hija de puta': 2,
    'hijo de mil putas': 2,
    'hijo de puta': 2,
    hueco: 2,
    huelegateras: 2,
    huevo: 1,
    huevon: 1,
    huevos: 1,
    huevón: 2,
    idiota: 2,
    ignorante: 1,
    imbecil: 2,
    imbeciles: 2,
    imbécil: 2,
    imbéciles: 1,
    impermeable: 1,
    infierno: 1,
    jilipollas: 2,
    joder: 2,
    kapullo: 2,
    'la puta que te pario': 2,
    'la puta que te parió': 1,
    'la puta que te recontrapario': 2,
    'la puta que te recontraparió': 1,
    ladilla: 2,
    lamecharcos: 2,
    lameculos: 2,
    lamehuevos: 1,
    lameplatos: 2,
    leche: 2,
    leches: 2,
    lechuguino: 2,
    lerdo: 2,
    letrin: 1,
    letrín: 2,
    lloramigas: 2,
    lolas: 2,
    longanizas: 2,
    lumbreras: 2,
    maciza: 1,
    macizorra: 2,
    maganto: 2,
    majadero: 2,
    malandro: 1,
    malasangre: 2,
    malasombra: 2,
    maldita: 1,
    maldito: 2,
    maleton: 1,
    maletón: 2,
    malparido: 2,
    'mama huevos': 2,
    mamada: 2,
    mamahuevo: 1,
    mamar: 2,
    mamarla: 2,
    mameluco: 2,
    mamey: 2,
    mamon: 2,
    mamona: 1,
    mamplora: 2,
    mamporrero: 2,
    mamón: 1,
    manchas: 2,
    manegueta: 2,
    mangarran: 1,
    mangarrán: 2,
    mangurrian: 1,
    mangurrián: 2,
    maraco: 2,
    marica: 2,
    maricon: 2,
    maricona: 1,
    mariconazo: 2,
    maricos: 2,
    maricón: 2,
    mariposa: 1,
    mariposon: 1,
    mariposón: 2,
    martillo: 1,
    marujo: 2,
    mastuerzo: 2,
    masturbacion: 1,
    masturbación: 2,
    matacandiles: 2,
    mayate: 2,
    meapilas: 2,
    mecos: 2,
    melon: 1,
    melones: 1,
    melón: 1,
    mendrugo: 2,
    mentecato: 2,
    mequetrefe: 2,
    merluzo: 2,
    metemuertos: 2,
    metijaco: 2,
    mico: 2,
    mierda: 2,
    mindundi: 2,
    minga: 2,
    mojar: 1,
    'mojar el bizcocho': 2,
    'mojar el chingolo': 2,
    montar: 1,
    morfarse: 2,
    morlaco: 2,
    morroestufa: 2,
    morrongo: 2,
    muerdesartenes: 2,
    nabo: 2,
    naco: 2,
    ninfo: 2,
    'no jodas': 1,
    nojoda: 1,
    ojete: 2,
    orate: 2,
    orina: 1,
    orto: 2,
    ovejo: 2,
    pagafantas: 2,
    paja: 2,
    pajara: 1,
    pajaro: 1,
    pajeado: 2,
    pajearse: 2,
    pajero: 2,
    palo: 2,
    palurdo: 2,
    pamplinas: 2,
    panarra: 2,
    panocha: 2,
    panoli: 2,
    papafrita: 2,
    papallona: 2,
    papanatas: 2,
    papaya: 1,
    papayona: 2,
    papirote: 2,
    paquete: 1,
    pardillo: 2,
    pargo: 2,
    parguela: 2,
    pasmarote: 2,
    pasmasuegras: 2,
    pata: 1,
    pataliebre: 2,
    patan: 1,
    pato: 1,
    patán: 2,
    pavitonto: 2,
    pazguato: 2,
    pecholata: 2,
    pechugas: 2,
    pedazo: 1,
    pedo: 2,
    pedorro: 2,
    peinabombillas: 2,
    peinaovejas: 2,
    pelagallos: 2,
    pelagambas: 2,
    pelagatos: 2,
    pelatigres: 2,
    pelazarzas: 2,
    pelele: 2,
    pelma: 2,
    pelotas: 2,
    pelotudo: 2,
    pendejo: 2,
    pepa: 2,
    percebe: 2,
    perrocostra: 2,
    perroflauta: 2,
    pervertido: 2,
    peterete: 2,
    petimetre: 2,
    pezon: 1,
    pezón: 2,
    picapleitos: 2,
    picha: 2,
    pichabrava: 2,
    pico: 2,
    pija: 2,
    pijo: 2,
    pillavispas: 2,
    piltrafa: 2,
    pinchauvas: 2,
    pinche: 2,
    pinga: 2,
    pintamonas: 2,
    piojoso: 2,
    pipe: 2,
    pipian: 1,
    pipián: 1,
    pis: 2,
    pisar: 2,
    pistola: 2,
    pitañoso: 2,
    pito: 2,
    pitofloro: 2,
    playo: 2,
    plomo: 1,
    pocasluces: 2,
    polla: 2,
    pollas: 2,
    pollopera: 2,
    polvazo: 1,
    polvo: 2,
    popa: 2,
    poronga: 2,
    poto: 2,
    pringao: 1,
    prostituta: 2,
    prostituto: 2,
    puchero: 2,
    puta: 2,
    putada: 1,
    putas: 1,
    putica: 1,
    puto: 2,
    putona: 1,
    putos: 1,
    pájara: 1,
    pájaro: 1,
    quebracho: 2,
    quebrachon: 1,
    quebrachón: 2,
    quitahipos: 2,
    racista: 1,
    raja: 2,
    ramera: 2,
    rastrapajo: 2,
    reata: 2,
    rebañasandias: 1,
    rebañasandías: 2,
    remame: 2,
    repisas: 2,
    retazo: 2,
    revientabaules: 2,
    riata: 2,
    rieleches: 1,
    rifle: 1,
    robaperas: 2,
    rulacho: 2,
    sabandija: 2,
    sacamuelas: 2,
    sadico: 1,
    sanguijuela: 2,
    semen: 2,
    seno: 2,
    senos: 2,
    sexo: 2,
    'sexo oral': 2,
    sinentraero: 2,
    singar: 2,
    sinsustancia: 2,
    sobarsela: 1,
    sobo: 2,
    sobársela: 2,
    sonajas: 2,
    sonso: 2,
    sopladores: 2,
    soplagaitas: 2,
    soplaguindas: 2,
    soplanucas: 2,
    soplapollas: 2,
    sorete: 2,
    sosco: 2,
    sádico: 2,
    tagarote: 2,
    tajarse: 2,
    tanate: 2,
    tarado: 2,
    tarugo: 2,
    tereso: 2,
    tetas: 2,
    'tetas grandes': 2,
    tetona: 1,
    'tia buena': 1,
    'tio bueno': 1,
    tiralevitas: 2,
    tirar: 1,
    tirarse: 1,
    tocapelotas: 2,
    tocho: 2,
    tolai: 2,
    tolete: 2,
    tompeates: 2,
    tonga: 2,
    tontaco: 2,
    tontucio: 2,
    tordo: 2,
    tortillera: 2,
    totona: 2,
    traga: 2,
    tragaldabas: 2,
    tragasables: 2,
    transar: 2,
    travesti: 2,
    trincar: 2,
    trio: 2,
    trola: 2,
    trolo: 2,
    trío: 1,
    tuercebotas: 2,
    tunante: 2,
    'tía buena': 2,
    'tío bueno': 1,
    vejiga: 1,
    verga: 2,
    vergallito: 2,
    vergallo: 2,
    vete: 2,
    'vete a la mierda': 2,
    violador: 2,
    violar: 2,
    volar: 1,
    volarse: 2,
    'voltearse la rosca': 2,
    'voltearse la tortilla': 2,
    vulva: 2,
    yegua: 2,
    zamacuco: 2,
    zambombo: 2,
    zampabollos: 2,
    zamugo: 2,
    zangano: 1,
    zarrapastroso: 2,
    zascandil: 2,
    zopenco: 2,
    zoquete: 2,
    zorra: 2,
    zote: 2,
    zullenco: 2,
    zurcefrenillos: 2,
    zángano: 1,
    ñema: 1,
    ñorro: 1,
  },
  it: {
    allupare: 1,
    allupata: 1,
    allupate: 1,
    allupati: 1,
    allupato: 1,
    ammucchiata: 1,
    ammucchiate: 1,
    anale: 2,
    anali: 2,
    ano: 2,
    arando: 0,
    arare: 0,
    arata: 0,
    arate: 0,
    arati: 0,
    arato: 0,
    arrapa: 2,
    arrapamenti: 2,
    arrapamento: 2,
    arrapando: 2,
    arrapano: 2,
    arrapare: 2,
    arrapate: 2,
    arrapati: 2,
    arrapato: 2,
    arrusa: 2,
    arruso: 2,
    assatanata: 2,
    assatanate: 2,
    assatanati: 2,
    assatanato: 2,
    azz: 2,
    azzo: 2,
    bagasce: 2,
    bagascia: 2,
    bagassa: 2,
    bagasse: 2,
    baldracca: 2,
    baldracche: 2,
    baldraccone: 2,
    baldracconi: 2,
    balla: 0,
    balle: 0,
    barzotta: 2,
    barzotte: 2,
    barzotti: 2,
    barzotto: 2,
    bastardata: 2,
    bastardate: 2,
    bastarde: 2,
    bastardi: 1,
    bastardo: 2,
    battere: 0,
    battona: 2,
    battone: 2,
    'bel bocconcino': 1,
    belini: 2,
    belino: 2,
    'bestia dio': 2,
    biga: 0,
    bimbaminkia: 2,
    bimbeminkia: 2,
    bimbiminkia: 2,
    bimbominkia: 2,
    bocchinara: 2,
    bocchinare: 2,
    bocchinari: 2,
    bocchinaro: 2,
    bocchini: 2,
    bocchino: 2,
    bocconcino: 0,
    bofilo: 2,
    boia: 1,
    'boia dio': 2,
    boiata: 1,
    boiate: 1,
    bonazza: 2,
    bonazzo: 2,
    bordello: 2,
    brinca: 0,
    bucaiola: 2,
    bucaiolo: 2,
    buciadiculo: 2,
    buciodiculo: 2,
    bucodiculo: 2,
    budella: 1,
    budello: 1,
    budiulo: 2,
    busona: 2,
    busone: 2,
    busoni: 2,
    cacando: 2,
    cacano: 2,
    cacare: 2,
    cacarella: 2,
    cacarelle: 2,
    cacarsi: 2,
    cacasotto: 2,
    cacata: 2,
    cacate: 2,
    cacca: 2,
    caccata: 2,
    cacchio: 1,
    caccona: 2,
    caccone: 2,
    caciocappella: 2,
    caciocappelle: 2,
    caco: 0,
    cacone: 2,
    cadavere: 0,
    cagare: 2,
    cagata: 2,
    cagate: 2,
    cagati: 2,
    cagato: 2,
    cagna: 1,
    'cagna madonna': 2,
    cagne: 1,
    cammello: 0,
    cancara: 2,
    cancare: 2,
    cancari: 2,
    cancaro: 2,
    'cane dio': 2,
    canna: 0,
    cannate: 2,
    cannati: 2,
    cannato: 2,
    canne: 0,
    cappella: 0,
    cappelle: 0,
    carciofo: 0,
    casci: 1,
    castronaggine: 2,
    castronata: 2,
    castronate: 2,
    castroneria: 2,
    castronerie: 2,
    cazzata: 2,
    cazzate: 2,
    cazzeggi: 2,
    cazzeggia: 2,
    cazzeggiano: 2,
    cazzeggiare: 2,
    cazzeggiate: 2,
    cazzeggio: 2,
    cazzi: 2,
    cazziata: 2,
    cazziate: 2,
    cazziatona: 2,
    cazziatone: 2,
    cazzimma: 2,
    cazzo: 2,
    'cazzo di cane': 2,
    cazzone: 2,
    cazzoni: 2,
    cazzuta: 2,
    cazzute: 2,
    cazzuti: 2,
    cazzuto: 2,
    cessa: 2,
    cesse: 2,
    cessi: 2,
    cesso: 2,
    checca: 2,
    checche: 2,
    checchetta: 2,
    checchette: 2,
    chiappa: 1,
    chiappe: 1,
    chiappona: 2,
    chiappone: 2,
    chiapponi: 2,
    chiavabile: 2,
    chiavando: 2,
    chiavano: 2,
    chiavare: 2,
    chiavata: 2,
    chiavate: 2,
    chiavati: 2,
    chiavato: 2,
    chiaviamo: 2,
    chiavo: 2,
    ciccia: 1,
    ciccio: 1,
    cicciona: 2,
    ciccione: 2,
    ciccioni: 2,
    ciolla: 2,
    ciolle: 2,
    ciucciacazzi: 2,
    ciucciacazzo: 2,
    ciula: 2,
    ciulando: 2,
    ciulano: 2,
    ciulare: 2,
    ciulata: 2,
    ciulate: 2,
    ciulati: 2,
    ciulato: 2,
    ciuli: 2,
    ciulo: 2,
    cogliona: 2,
    coglionano: 2,
    coglionare: 2,
    coglionata: 2,
    coglionate: 2,
    coglionati: 2,
    coglionato: 2,
    coglione: 2,
    coglioni: 2,
    coito: 2,
    coregge: 2,
    coreggia: 2,
    cornuta: 1,
    cornute: 2,
    cornuti: 2,
    cornuto: 1,
    cozza: 0,
    cozze: 0,
    cretino: 2,
    cribbio: 0,
    crucca: 2,
    crucche: 2,
    crucchi: 2,
    crucco: 2,
    cuccando: 2,
    cuccano: 2,
    cuccata: 2,
    cuccate: 2,
    cuccati: 2,
    cuccato: 2,
    cucchi: 2,
    cucco: 2,
    cula: 2,
    culatella: 2,
    culatone: 2,
    culatoni: 2,
    culattella: 2,
    culattina: 2,
    culattine: 2,
    culattoni: 2,
    culattun: 2,
    culi: 1,
    culo: 1,
    cunnilingio: 2,
    cunnilinguo: 2,
    'dio bestia': 2,
    'dio boia': 2,
    'dio cane': 2,
    'dio infame': 2,
    'dio legnetto': 2,
    'dio lurido': 2,
    'dio maiale': 2,
    'dio merda': 2,
    'dio porco': 2,
    'dio porcone': 2,
    'dio stronzo': 2,
    'dio suino': 2,
    diobestia: 2,
    dioboia: 2,
    diocane: 2,
    dioinfame: 2,
    diolegnetto: 2,
    diolurido: 2,
    diomaiale: 2,
    diomerda: 2,
    dioporco: 2,
    dioporcone: 2,
    diopporco: 2,
    diostronzo: 2,
    diosuino: 2,
    ditalini: 1,
    ditalino: 2,
    durelli: 2,
    durello: 2,
    eiacula: 2,
    eiaculando: 2,
    eiaculano: 2,
    eiaculare: 2,
    eiaculazione: 2,
    eiaculi: 2,
    eiaculo: 2,
    esticazzi: 2,
    extracomunitari: 0,
    extracomunitario: 0,
    'facce da culo': 2,
    'faccia da culo': 2,
    falli: 2,
    fallo: 2,
    fanculo: 2,
    'far girare i coglioni': 2,
    fasci: 0,
    fascio: 0,
    fattone: 2,
    fattoni: 2,
    fava: 1,
    fave: 1,
    feci: 1,
    fellatio: 2,
    femminucce: 2,
    femminuccia: 2,
    fessa: 2,
    fesse: 2,
    fessi: 2,
    fesso: 2,
    fica: 2,
    fiche: 2,
    fichi: 0,
    fico: 0,
    figa: 2,
    fighe: 2,
    fighetta: 2,
    fighette: 2,
    fighetti: 2,
    'figli di puttana': 2,
    'figli di puttane': 2,
    'figli di troia': 2,
    'figli di troie': 2,
    'figlia di puttana': 2,
    'figlia di puttane': 2,
    'figlia di troia': 2,
    'figlia di troie': 2,
    'figlie di puttana': 2,
    'figlie di puttane': 2,
    'figlie di troia': 2,
    'figlie di troie': 2,
    'figlio di puttana': 2,
    'figlio di puttane': 2,
    'figlio di troia': 2,
    'figlio di troie': 2,
    figo: 1,
    figone: 1,
    finocchi: 0,
    finocchio: 0,
    finocchione: 2,
    finocchioni: 2,
    fotte: 2,
    fottendo: 2,
    fottere: 2,
    fottersene: 2,
    fottersi: 2,
    fottuta: 2,
    fottute: 2,
    fottuti: 2,
    fottutissima: 2,
    fottutissime: 2,
    fottutissimi: 2,
    fottutissimo: 2,
    fottuto: 2,
    fregna: 2,
    fregnacce: 2,
    fregnaccia: 2,
    fregne: 2,
    frocetti: 2,
    frocetto: 2,
    froci: 2,
    frociaggine: 2,
    frociata: 2,
    frociate: 2,
    frocio: 2,
    frocione: 2,
    frocioni: 2,
    frosci: 2,
    froscio: 2,
    'fuori dai coglioni': 2,
    'fuori dalle palle': 2,
    gay: 1,
    gnocca: 2,
    gnocche: 2,
    goldone: 2,
    goldoni: 2,
    grilletti: 0,
    grilletto: 0,
    grulla: 1,
    grulle: 1,
    grulli: 1,
    grullo: 1,
    guardona: 2,
    guardone: 2,
    guardoni: 2,
    idiota: 2,
    immerdato: 2,
    'in culo alla balena': 2,
    incazzano: 2,
    incazzarsi: 2,
    incazzata: 2,
    incazzate: 2,
    incazzati: 2,
    incazzato: 2,
    incazziamo: 2,
    incazzo: 2,
    incazzosa: 2,
    incazzosamente: 2,
    incazzose: 2,
    incazzosi: 2,
    incazzoso: 2,
    inchiappetta: 2,
    inchiappettando: 2,
    inchiappettano: 2,
    inchiappettare: 2,
    inchiappettata: 2,
    inchiappettate: 2,
    inchiappettati: 2,
    inchiappettato: 2,
    inchiappetti: 2,
    inchiappettiamo: 2,
    inchiappetto: 2,
    inchiavabile: 2,
    incoglionirsi: 2,
    incoglionita: 2,
    incoglionite: 2,
    incoglioniti: 2,
    incoglionito: 2,
    incula: 2,
    inculando: 2,
    inculano: 2,
    inculare: 2,
    inculata: 2,
    inculate: 2,
    inculati: 2,
    inculato: 2,
    inculi: 2,
    inculiamo: 2,
    inculo: 2,
    inculone: 2,
    inculoni: 2,
    infame: 2,
    'infame dio': 2,
    infamedio: 2,
    ingoi: 0,
    ingoia: 0,
    ingoiando: 0,
    ingoiano: 0,
    ingoiare: 0,
    ingoiata: 0,
    ingoiate: 0,
    ingoiati: 0,
    ingoiato: 0,
    ingoio: 0,
    ingroppa: 1,
    ingroppando: 1,
    ingroppano: 1,
    ingroppare: 1,
    ingroppata: 1,
    ingroppate: 1,
    ingroppati: 1,
    ingroppato: 1,
    ingroppi: 1,
    ingroppiamo: 1,
    ingroppo: 1,
    leccaciuffi: 2,
    leccaculi: 2,
    leccaculo: 2,
    leccapalle: 2,
    lecchina: 2,
    lecchine: 2,
    lecchini: 2,
    lecchino: 2,
    'legnetto dio': 2,
    legnettodio: 2,
    lofa: 2,
    lofare: 2,
    loffa: 2,
    loffare: 2,
    loffe: 2,
    lucciola: 0,
    lucciole: 0,
    'lurida madonna': 2,
    luridamadonna: 2,
    'lurido dio': 2,
    luridodio: 2,
    madonna: 2,
    'madonna cagna': 2,
    'madonna lurida': 2,
    'madonna prostituta': 2,
    'madonna puttana': 2,
    'madonna slabbrata': 2,
    'madonna troia': 2,
    'madonna vacca': 2,
    madonnacagna: 2,
    madonnalurida: 2,
    madonnaprostituta: 2,
    madonnaputtana: 2,
    madonnaslabbrata: 2,
    madonnatroia: 2,
    madonnavacca: 2,
    maiala: 2,
    maialata: 2,
    maialate: 2,
    maiale: 1,
    'maiale dio': 2,
    maialedio: 2,
    maiali: 1,
    maialone: 2,
    maialoni: 2,
    mangiaparana: 2,
    mangiaparane: 2,
    mangiapatata: 2,
    mangiapatate: 2,
    manici: 0,
    manico: 0,
    mannaggia: 0,
    marone: 2,
    maroni: 2,
    marrone: 0,
    marroni: 0,
    mazza: 0,
    mazze: 0,
    melanzana: 0,
    melanzane: 0,
    menano: 2,
    menare: 2,
    menata: 2,
    menate: 2,
    menati: 2,
    menato: 2,
    merda: 2,
    'merda dio': 2,
    merdacce: 2,
    merdaccia: 2,
    'merdaccia dio': 2,
    merdacciadio: 2,
    merdadio: 2,
    merdai: 2,
    merdaia: 2,
    merdaio: 2,
    merdaiola: 2,
    merdaiole: 2,
    merdaioli: 2,
    merdaiolo: 2,
    merdata: 2,
    merdate: 2,
    merdina: 2,
    merdine: 2,
    merdone: 2,
    merdoni: 2,
    merdosa: 2,
    merdosamente: 2,
    merdose: 2,
    merdosi: 2,
    merdoso: 2,
    meretrice: 2,
    mezzasega: 2,
    mezzeseghe: 2,
    micia: 0,
    mignotta: 2,
    mignotte: 2,
    mignottone: 2,
    mignottoni: 2,
    minchia: 2,
    minchiata: 2,
    minchie: 2,
    minchiona: 2,
    minchionando: 2,
    minchionare: 2,
    minchionata: 2,
    minchione: 2,
    minchioni: 2,
    mocciosa: 2,
    mocciose: 2,
    mocciosi: 2,
    moccioso: 2,
    mona: 2,
    monata: 2,
    monate: 2,
    monta: 0,
    montano: 0,
    montare: 0,
    montata: 0,
    montate: 0,
    montati: 0,
    montato: 0,
    mora: 0,
    more: 0,
    mori: 0,
    moro: 0,
    mussa: 2,
    musse: 2,
    mussi: 2,
    musso: 2,
    nana: 0,
    nane: 0,
    nani: 0,
    nano: 0,
    negra: 2,
    negre: 2,
    negretta: 2,
    negrette: 2,
    negretti: 2,
    negretto: 2,
    negri: 2,
    negro: 2,
    nerchia: 2,
    nerchie: 2,
    nigga: 2,
    nuda: 0,
    nude: 0,
    nudi: 0,
    nudo: 0,
    ominide: 0,
    ominidi: 0,
    ovi: 0,
    ovo: 0,
    padulo: 1,
    palla: 0,
    palle: 1,
    pallosa: 2,
    pallose: 2,
    pallosi: 2,
    palloso: 2,
    pappone: 2,
    papponi: 2,
    paraculi: 2,
    paraculo: 2,
    passera: 0,
    passero: 0,
    patacca: 0,
    patacche: 0,
    patonza: 2,
    patonze: 2,
    pecorina: 2,
    pecorine: 2,
    pederasta: 2,
    pederasti: 2,
    pene: 2,
    peni: 2,
    perbacco: 0,
    perdiana: 0,
    pesce: 0,
    pesci: 0,
    picio: 2,
    pinciano: 2,
    pinciare: 2,
    pinciata: 2,
    pinciate: 2,
    pinciati: 2,
    pinciato: 2,
    pippa: 2,
    pippaiola: 2,
    pippaiole: 2,
    pippaioli: 2,
    pippaiolo: 2,
    pipparola: 2,
    pipparole: 2,
    pipparoli: 2,
    pipparolo: 2,
    pippe: 2,
    pippetta: 2,
    pippette: 2,
    pippona: 2,
    pippone: 2,
    pipponi: 2,
    pipì: 0,
    pirla: 1,
    pirlata: 1,
    pirlate: 1,
    pirlona: 1,
    pirlone: 1,
    pirloni: 1,
    piscia: 2,
    pisciando: 2,
    pisciare: 2,
    pisciasotto: 2,
    pisciata: 2,
    pisciate: 2,
    pisciati: 2,
    pisciato: 2,
    pisciatona: 2,
    pisciatone: 2,
    piscio: 2,
    pisciona: 2,
    piscione: 2,
    piscioni: 2,
    pisciosa: 2,
    pisciose: 2,
    pisciosi: 2,
    piscioso: 2,
    pisella: 2,
    piselle: 2,
    piselli: 0,
    pisello: 0,
    pistola: 0,
    pistolotto: 1,
    pomiciare: 1,
    pomiciata: 1,
    pompinara: 2,
    pompinare: 2,
    pompinari: 2,
    pompinaro: 2,
    pompini: 2,
    pompino: 2,
    popò: 0,
    'porca eva': 2,
    'porca madonna': 2,
    'porca puttana': 2,
    'porca troia': 2,
    porcamadonna: 2,
    porcapputtana: 2,
    porcaputtana: 2,
    porcatroia: 2,
    porche: 2,
    porci: 1,
    porco: 1,
    'porco cristo': 2,
    'porco dio': 2,
    'porco due': 2,
    'porco gesù': 2,
    'porco là': 2,
    'porco qua': 2,
    'porco zio': 2,
    porcocane: 1,
    porcocazzo: 2,
    porcoddio: 2,
    porcodio: 2,
    'porcone dio': 2,
    porconedio: 2,
    porcozio: 2,
    porcozzio: 2,
    potta: 2,
    prostituirsi: 2,
    prostituite: 2,
    prostituito: 2,
    prostituta: 2,
    'prostituta madonna': 2,
    prostitutamadonna: 2,
    prostitute: 2,
    pucchiacchiera: 2,
    pucchiacchiere: 2,
    pula: 2,
    pulotti: 2,
    pulotto: 2,
    puppa: 2,
    puppalo: 2,
    puppami: 2,
    puttana: 2,
    'puttana eva': 2,
    'puttana madonna': 2,
    puttanaeva: 2,
    puttanaio: 2,
    puttanamadonna: 2,
    puttanata: 2,
    puttanate: 2,
    puttane: 2,
    puttaniere: 2,
    puttanona: 2,
    puttanone: 2,
    puttanoni: 2,
    quaglia: 0,
    quaglie: 0,
    rammollita: 0,
    rammollite: 0,
    rammolliti: 0,
    rammollito: 0,
    randelli: 0,
    randello: 0,
    recchia: 2,
    recchione: 2,
    recchioni: 2,
    regina: 0,
    ricchione: 2,
    ricchioni: 2,
    rincoglionimento: 2,
    rincoglionire: 2,
    rincoglionirsi: 2,
    rincoglionita: 2,
    rincoglionite: 2,
    rincoglioniti: 2,
    rincoglionito: 2,
    rizzarsi: 1,
    rompiballe: 2,
    rompicazzo: 2,
    rompicoglioni: 2,
    rompimaroni: 2,
    rompimarroni: 2,
    rompipalle: 2,
    ruffiano: 0,
    sardegnola: 2,
    sardegnolo: 2,
    sballare: 0,
    sballato: 1,
    sballo: 0,
    sbatte: 0,
    sbattendo: 0,
    sbattere: 0,
    sbattersi: 1,
    sbattete: 0,
    sbatti: 1,
    sbattiamo: 0,
    sbatto: 1,
    sbattuta: 0,
    sbattute: 0,
    sbattuti: 0,
    sbattuto: 0,
    sbocca: 0,
    sbocchinara: 2,
    sbocchinare: 2,
    sbocchinari: 2,
    sbocchinaro: 2,
    sbocchini: 2,
    sbocchino: 2,
    sbocco: 0,
    sbora: 2,
    sboro: 2,
    sborona: 2,
    sborone: 2,
    sborra: 2,
    sborrare: 2,
    sborrata: 2,
    sborrate: 2,
    sborro: 2,
    sborrone: 2,
    sbrodolata: 0,
    sbrodolato: 0,
    sburra: 2,
    sburrata: 2,
    sburrate: 2,
    sburrati: 2,
    sburrato: 2,
    sburro: 2,
    scacazza: 2,
    scacazzando: 2,
    scacazzano: 2,
    scacazzare: 2,
    scacazzata: 2,
    scacazzate: 2,
    scacazzi: 2,
    scacazziamo: 2,
    scacazzo: 2,
    scagazza: 2,
    scagazzando: 2,
    scagazzano: 2,
    scagazzare: 2,
    scagazzata: 2,
    scagazzate: 2,
    scagazzi: 2,
    scagazziamo: 2,
    scagazzo: 2,
    scappella: 2,
    scappellamenti: 2,
    scappellamento: 2,
    scappellano: 2,
    scappellare: 2,
    scappellata: 2,
    scappellate: 2,
    scappellati: 2,
    scappellato: 2,
    scassacazzi: 2,
    scassacazzo: 2,
    scassaminchia: 2,
    scassapalle: 2,
    scazza: 2,
    scazzando: 2,
    scazzano: 2,
    scazzare: 2,
    scazzata: 2,
    scazzate: 2,
    scazzati: 2,
    scazzato: 2,
    scazzi: 2,
    scazziamo: 2,
    scazzo: 2,
    scema: 2,
    scemata: 2,
    scemate: 2,
    sceme: 2,
    scemi: 2,
    scemo: 2,
    schiappa: 2,
    schiappe: 2,
    scoglionamento: 2,
    scoglionandosi: 2,
    scoglionare: 2,
    scoglionarsi: 2,
    scoglionata: 2,
    scoglionate: 2,
    scoglionati: 2,
    scoglionato: 2,
    scopamica: 2,
    scopamiche: 2,
    scopamici: 2,
    scopamico: 2,
    scopando: 0,
    scopare: 0,
    scopata: 1,
    scopate: 1,
    scopati: 2,
    scopato: 1,
    scoreggia: 2,
    scoreggiare: 2,
    scorreggia: 2,
    scorreggiare: 2,
    scrofa: 1,
    scrofe: 1,
    scrotale: 2,
    scroti: 2,
    scroto: 2,
    sculata: 2,
    sculate: 2,
    sculati: 2,
    sculato: 2,
    secchiona: 2,
    secchione: 2,
    secchioni: 2,
    sedere: 1,
    sederi: 1,
    sederini: 1,
    sederino: 1,
    sega: 0,
    segaiola: 2,
    segaiole: 2,
    segaioli: 2,
    segaiolo: 2,
    segare: 0,
    segarsi: 2,
    seghe: 1,
    segone: 2,
    segoni: 2,
    sfiga: 1,
    sfigare: 2,
    sfigata: 2,
    sfigate: 2,
    sfigati: 2,
    sfigato: 2,
    sfighe: 1,
    sfondando: 0,
    sfondano: 0,
    sfondare: 0,
    sfondata: 0,
    sfondate: 0,
    sfondati: 0,
    sfondato: 0,
    sgrillettando: 2,
    sgrillettano: 2,
    sgrillettare: 2,
    sgrillettarsi: 2,
    sgrillettata: 2,
    sgrillettate: 2,
    sgrillettati: 2,
    sgrillettato: 2,
    slabbrata: 2,
    'slabbrata madonna': 2,
    slabbratamadonna: 2,
    slabbrato: 2,
    slinguano: 2,
    slinguare: 2,
    slinguata: 2,
    slinguate: 2,
    slinguati: 2,
    slinguato: 2,
    smandrappano: 2,
    smandrappata: 2,
    smandrappate: 2,
    smandrappati: 2,
    smandrappato: 2,
    smerda: 2,
    smerdando: 2,
    smerdare: 2,
    smerdata: 2,
    smerdato: 2,
    "socc'mel": 2,
    soccia: 2,
    socmel: 2,
    sodomita: 2,
    sodomiti: 2,
    soffocotti: 2,
    soffocotto: 2,
    sorca: 2,
    sorce: 2,
    sorcia: 2,
    sorice: 1,
    spaccamaroni: 2,
    spaccamarroni: 2,
    spagnola: 0,
    spagnole: 0,
    spagnoletta: 0,
    spagnolette: 0,
    spantana: 1,
    spantanando: 1,
    spantanano: 1,
    spantanare: 1,
    spantani: 1,
    spantano: 1,
    sperma: 2,
    spinelli: 1,
    spinello: 1,
    spompina: 2,
    spompinando: 2,
    spompinao: 2,
    spompinara: 2,
    spompinare: 2,
    spompinari: 2,
    spompinaro: 2,
    spompinata: 2,
    spompinate: 2,
    spompinati: 2,
    spompinato: 2,
    spompini: 2,
    spompino: 2,
    sputtanando: 2,
    sputtanano: 2,
    sputtanare: 2,
    sputtanate: 2,
    sputtani: 2,
    squillo: 1,
    squinzia: 2,
    squinzie: 2,
    sticazzi: 2,
    sticchio: 2,
    stocazzo: 2,
    stronza: 2,
    stronzaggine: 2,
    stronzaggini: 2,
    stronzata: 2,
    stronzate: 2,
    stronze: 2,
    stronzeggiare: 2,
    stronzetta: 2,
    stronzette: 2,
    stronzetti: 2,
    stronzetto: 2,
    stronzi: 2,
    stronzio: 1,
    stronzo: 2,
    'stronzo dio': 2,
    stronzodio: 2,
    stronzone: 2,
    stronzoni: 2,
    stupidi: 1,
    stupido: 1,
    suca: 2,
    sucando: 2,
    sucano: 2,
    sucanti: 2,
    sucare: 2,
    sucata: 2,
    sucate: 2,
    sucato: 2,
    succhi: 1,
    succhia: 1,
    succhiacazzi: 2,
    succhiacazzo: 2,
    succhio: 1,
    suina: 2,
    suine: 2,
    suini: 1,
    suino: 1,
    'suino dio': 2,
    suinodio: 2,
    superdotata: 1,
    superdotate: 1,
    superdotati: 1,
    superdotato: 1,
    sveltina: 2,
    sveltine: 2,
    svergina: 2,
    sverginando: 2,
    sverginano: 2,
    sverginare: 2,
    sverginata: 2,
    sverginate: 2,
    sverginati: 2,
    sverginato: 2,
    tarzanelli: 2,
    tarzanello: 2,
    tega: 2,
    teghe: 2,
    tegona: 2,
    tegone: 2,
    terga: 2,
    terghe: 2,
    terrona: 2,
    terrone: 2,
    terroni: 2,
    terronia: 2,
    terrun: 2,
    'testa di cazzo': 2,
    'testa di merda': 2,
    'teste di cazzo': 2,
    'teste di merda': 2,
    testicoli: 2,
    testicolo: 2,
    tetta: 2,
    tettata: 2,
    tettate: 2,
    tette: 2,
    tettina: 2,
    tettine: 2,
    tettona: 2,
    tettone: 2,
    topa: 0,
    tope: 1,
    topona: 1,
    topone: 1,
    troia: 2,
    'troia madonna': 2,
    troiaio: 2,
    troiamadonna: 2,
    troiata: 2,
    troiate: 2,
    troie: 2,
    troiona: 2,
    troione: 2,
    troioni: 2,
    tromba: 0,
    trombamica: 2,
    trombamiche: 2,
    trombamici: 2,
    trombamico: 2,
    trombando: 2,
    trombano: 2,
    trombare: 2,
    trombata: 2,
    trombate: 2,
    trombati: 2,
    trombato: 2,
    trombi: 2,
    uccella: 2,
    uccelli: 0,
    uccello: 0,
    uccellone: 2,
    uccelloni: 2,
    vacca: 0,
    'vacca madonna': 2,
    vaccamadonna: 2,
    vacche: 0,
    vaccona: 2,
    vaccone: 2,
    vaffanculo: 2,
    vagina: 2,
    vaginale: 2,
    vagine: 2,
    'vai a fare in culo': 2,
    vangare: 0,
    vangata: 0,
    vangate: 0,
    vangati: 0,
    vangato: 0,
    vatteneaffanculo: 2,
    venendo: 0,
    vengono: 0,
    venire: 0,
    venite: 0,
    venuta: 0,
    venute: 0,
    venuti: 0,
    venuto: 0,
    verga: 1,
    verghe: 1,
    vergine: 0,
    vergini: 0,
    vulva: 1,
    vulve: 2,
    vulvetta: 2,
    vulvette: 2,
    zebedei: 2,
    zebedeo: 2,
    zingara: 0,
    zingare: 0,
    zingari: 0,
    zingaro: 0,
    zinna: 2,
    zinne: 2,
    zinnona: 2,
    zinnone: 2,
    zoccola: 2,
    zoccole: 2,
    zoccoletta: 2,
    zoccolette: 2,
    zoccolona: 2,
    zoccolone: 2,
  },
  fr: {
    BLC: 2,
    Bitembois: 2,
    DMC: 2,
    DTC: 2,
    'Dieu me pignole': 2,
    'Dieu me tripote': 2,
    FDP: 2,
    NTM: 2,
    PD: 2,
    PQR: 2,
    RAB: 2,
    TBM: 2,
    TG: 2,
    VDM: 2,
    'aller aux putes': 2,
    'aller libérer Mandela': 2,
    'aller niquer sa mère': 2,
    'aller se faire empapaouter': 2,
    'aller se faire enculer': 2,
    'aller se faire foutre': 2,
    'aller se faire mettre': 2,
    'allez vous faire foutre': 2,
    archifoutre: 2,
    'arriver comme le marquis de Couille-Verte': 2,
    asphalteuse: 2,
    astiquer: 2,
    'avoir de la chatte': 2,
    'avoir de la merde dans les yeux': 2,
    'avoir de la moule': 2,
    'avoir des couilles': 2,
    'avoir des couilles au cul': 2,
    'avoir du cul': 2,
    'avoir du poil au cul': 2,
    'avoir la gueule dans le cul': 2,
    'avoir la tête dans le cul': 2,
    'avoir le cul bordé de médailles': 2,
    'avoir le cul bordé de nouilles': 2,
    'avoir le feu au cul': 2,
    'avoir le papier qui colle aux bonbons': 2,
    'avoir le trou du cul en chou-fleur': 2,
    'avoir les couilles': 2,
    'avoir les nerfs': 2,
    'avoir les rideaux qui collent aux fenêtres': 2,
    'avoir les yeux en trou de bite': 2,
    'avoir les yeux en trou de pine': 2,
    'avoir l’air con': 2,
    'avoir plein le cul': 2,
    'avoir un balai dans le cul': 2,
    bagouse: 1,
    baisable: 2,
    baise: 2,
    baiser: 1,
    'baiser Fanny': 2,
    'baiser comme des lapins': 2,
    'baiser comme un lapin': 2,
    baiseur: 2,
    'balancer la sauce': 1,
    balayette: 1,
    banane: 1,
    bander: 1,
    'bander comme un Turc': 2,
    'bander comme un cerf': 2,
    'bander comme un taureau': 2,
    'bander comme un âne': 2,
    baptême: 1,
    'bar à putes': 2,
    bat: 1,
    batte: 1,
    benzer: 2,
    berlingue: 1,
    biatch: 2,
    bibite: 2,
    biffle: 2,
    biffler: 2,
    bifle: 2,
    bifler: 2,
    bitch: 2,
    bite: 1,
    bitembois: 2,
    'bivouaquer dans la crevasse': 2,
    biétaze: 2,
    bolosser: 2,
    'bombe atomique': 2,
    'bon coup': 2,
    bonasse: 2,
    bonnasse: 2,
    bonne: 1,
    bordel: 2,
    'bordel de merde': 2,
    'bordel à cul': 2,
    'bordel à cul de pompe à merde': 2,
    'bordel à cul pompe à merde': 2,
    bosnioule: 2,
    'botter le cul': 2,
    boucaque: 2,
    bouffable: 2,
    'bouffer la chatte': 2,
    'bouffer le cul': 2,
    bougnoul: 2,
    bougnoule: 2,
    bougnoulisation: 2,
    bougnouliser: 2,
    boukak: 2,
    boule: 2,
    bounioul: 2,
    bounioule: 2,
    'bourrer le mou': 2,
    bourriquer: 2,
    'boyau cullier': 2,
    'branle-couille': 2,
    branler: 2,
    'branler le mammouth': 2,
    branlette: 2,
    'branlette espagnole': 2,
    'branlette intellectuelle': 2,
    branleur: 2,
    branlo: 2,
    branlotter: 2,
    branlée: 2,
    'brise-burnes': 2,
    'briser les noix': 2,
    broutage: 1,
    'broute-minou': 2,
    brouter: 1,
    'brouter le gazon': 2,
    'brouter les couilles': 2,
    brouteur: 1,
    brouteuse: 1,
    brêle: 2,
    burne: 2,
    bâton: 1,
    cagasse: 2,
    cagoince: 2,
    cahba: 2,
    calice: 1,
    'carrer dans l’oignon': 2,
    'carte de France': 1,
    'casse-bonbon': 2,
    'casse-burette': 2,
    'casse-couille': 2,
    'casse-couilles': 2,
    'casser la gueule': 2,
    'casser le pot': 2,
    'casser les boules': 2,
    'casser les burnes': 2,
    'casser les couilles': 2,
    cerise: 1,
    'chacun sa merde': 2,
    chagasse: 2,
    chagatte: 2,
    charmouta: 2,
    chat: 1,
    'chat bite': 2,
    'chat-bite': 2,
    chatte: 1,
    'chaude-pisse': 2,
    'cherche-merde': 2,
    chiant: 2,
    'chiant comme la Lune': 2,
    chiasse: 2,
    chiasser: 2,
    chiatique: 2,
    chibre: 2,
    chiennasse: 2,
    chienne: 1,
    chiennerie: 1,
    chier: 2,
    'chier comme tout le monde': 2,
    'chier dans les bottes': 2,
    'chier dans son froc': 2,
    'chier pour la marine': 2,
    'chier une pendule': 2,
    chierie: 2,
    chieur: 2,
    chiotte: 2,
    chiottes: 2,
    chiée: 2,
    choper: 2,
    choune: 2,
    christ: 1,
    ciboire: 1,
    'claque merde': 2,
    'claque-merde': 2,
    clille: 2,
    'comme papa dans maman': 2,
    'comme un chien fout sa merde': 2,
    'con comme un balai': 2,
    'con comme un gland': 2,
    'con comme une bite': 2,
    conchier: 2,
    connard: 2,
    connarde: 2,
    connasse: 2,
    conneau: 2,
    connerie: 2,
    contrecrisser: 2,
    'copain de baise': 2,
    'copine de baise': 2,
    coquillard: 2,
    couille: 2,
    'couille de loup': 2,
    'couille molle': 2,
    'couilles au poteau': 2,
    couillu: 2,
    'coup de pute': 2,
    'couter la peau des couilles': 2,
    'couter la peau du cul': 2,
    'coûter la peau des couilles': 2,
    'coûter la peau du cul': 2,
    'coûter une couille': 2,
    cramouille: 2,
    'craquer son slip': 2,
    'craquer son string': 2,
    'cravacher le pur-sang': 2,
    crisse: 2,
    crissement: 2,
    crosse: 2,
    crosser: 2,
    cruchasse: 2,
    cul: 2,
    culasse: 2,
    culbuter: 2,
    culer: 2,
    culeter: 2,
    culeur: 2,
    câliboire: 2,
    câlice: 1,
    câlisse: 1,
    câlisser: 2,
    céoène: 2,
    'c’est de la couille de loup': 2,
    'dans ton cul': 2,
    'de mes couilles': 2,
    'de mes deux': 2,
    'de mon vier': 2,
    'demi-molle': 2,
    'des couilles': 2,
    diarrhée: 2,
    dilater: 2,
    djoc: 2,
    does: 2,
    doeser: 2,
    doigter: 2,
    drisser: 2,
    drouille: 2,
    ducon: 2,
    duconnot: 2,
    dugland: 2,
    débagouler: 2,
    débander: 2,
    déboiter: 2,
    'débroussailler la tranchée': 2,
    décharger: 2,
    déconnade: 2,
    déconnage: 2,
    déconner: 2,
    décrisser: 2,
    déculer: 2,
    décâlisser: 2,
    défoncer: 2,
    défonceur: 2,
    défourailler: 2,
    dégager: 2,
    déglinguer: 2,
    dégueulassement: 2,
    'démerde yourself': 2,
    démonter: 2,
    dépucelage: 2,
    dépuceler: 2,
    'dérouler du câble': 2,
    désengorger: 2,
    emmancher: 2,
    emmanché: 2,
    emmerder: 2,
    emmerdeur: 2,
    emmerdé: 2,
    empapaoutage: 2,
    'en avoir deux': 2,
    'en avoir plein le cul': 2,
    'en avoir plein les couilles': 2,
    'en avoir ras les burnes': 2,
    'en chier': 2,
    enconnade: 2,
    enconner: 2,
    encuguler: 2,
    enculable: 2,
    enculade: 2,
    'enculade de mouche': 2,
    enculagailler: 2,
    enculage: 2,
    'enculage de mouche': 2,
    'enculage de mouches': 2,
    enculailleur: 2,
    enculatoire: 2,
    enculement: 2,
    enculer: 2,
    'enculer les mouches': 2,
    enculerie: 2,
    enculette: 2,
    enculeur: 2,
    'enculeur de mouches': 2,
    enculeuse: 2,
    enculé: 2,
    'enculé de ta mère': 2,
    'enculé de ta race': 2,
    'enfant de chienne': 2,
    'enfant de putain': 2,
    'enfant de pute': 2,
    enfiler: 2,
    enfler: 2,
    enfoirer: 2,
    enfoiré: 2,
    enfourailler: 2,
    enfoutrer: 2,
    englander: 2,
    engrosser: 2,
    engueuler: 2,
    'entre couilles': 2,
    entuber: 2,
    envoyer: 2,
    'envoyer chier': 2,
    envulver: 2,
    esque: 2,
    estie: 2,
    'et mon cul, c’est du poulet': 2,
    'et ta connerie': 2,
    exploser: 2,
    'exploser le terrier': 2,
    'face de pet': 2,
    faciale: 2,
    faire: 0,
    'faire chier': 2,
    'faire chier la bite': 2,
    'faire crapahuter le flemmard': 2,
    'faire minette': 2,
    'faire sa pute': 2,
    'faire sprinter l’unijambiste': 2,
    'faire un caca nerveux': 2,
    'fan de putain': 2,
    'fant de pute': 2,
    fdp: 2,
    'ferme ta gueule': 2,
    'fermer sa gueule': 2,
    'fermer son claque merde': 2,
    'fermer son claque-merde': 2,
    fesse: 2,
    fif: 2,
    fifi: 2,
    'fille à pédés': 2,
    'film de boule': 2,
    'film de boules': 2,
    'fils de bâtard': 2,
    'fils de chien': 2,
    'fils de chienne': 2,
    'fils de garce': 2,
    'fils de putain': 2,
    'fils de pute': 2,
    'fils de ta race': 2,
    'fils-de-puterie': 2,
    filsdeputerie: 2,
    fion: 2,
    fiotte: 2,
    fister: 2,
    flûte: 2,
    foirade: 2,
    foufoune: 2,
    foufounette: 2,
    'fouille-merde': 2,
    foune: 2,
    fourbir: 2,
    fourrer: 2,
    'foutage de gueule': 2,
    fouteur: 2,
    'fouteur de merde': 2,
    foutoir: 2,
    foutre: 2,
    'foutre en l’air': 2,
    'foutre la merde': 2,
    'foutre la paix': 2,
    'foutre le camp': 2,
    'foutre sur la gueule': 2,
    foutriquer: 2,
    fuck: 2,
    'fuck me boots': 2,
    fucker: 2,
    'garage à bite': 2,
    'garage à bites': 2,
    gland: 2,
    glaoui: 2,
    glisser: 2,
    gnasse: 2,
    gniouf: 2,
    gnoul: 2,
    gnoule: 2,
    gosse: 2,
    gougnote: 2,
    gougnoter: 2,
    gourdin: 2,
    gousse: 2,
    graine: 2,
    gros: 2,
    grouille: 2,
    gueule: 2,
    gueuler: 2,
    imbaisable: 2,
    imbitabilité: 2,
    imbitable: 2,
    'joual vert': 2,
    journalope: 2,
    'laisser pisser le mérinos': 2,
    'langue de pute': 2,
    latter: 2,
    'le con de sa mère': 2,
    'libérer Mandela': 2,
    limer: 2,
    lopette: 2,
    'lèche-cul': 2,
    'lécher le cul': 2,
    'l’avoir dans le cul': 2,
    'manche à couilles': 2,
    'mange-merde': 2,
    'manger de la marde': 2,
    maquerellage: 2,
    matos: 2,
    'mazouter le pingouin': 2,
    melon: 2,
    mercon: 2,
    merdasse: 2,
    merde: 2,
    merder: 2,
    merderie: 2,
    merdeux: 2,
    merdicité: 2,
    merdier: 2,
    merdogène: 2,
    'mes couilles': 2,
    'mes couilles le temps se brouille': 2,
    mettre: 2,
    'mettre dans la sauce': 2,
    'mettre dans l’os': 2,
    'mettre la misère': 2,
    'mettre la quenelle dans le shaker': 2,
    'mettre le nez de quelqu’un dans sa merde': 2,
    'mettre ses couilles sur la table': 2,
    'mettre sur la gueule': 2,
    mijole: 2,
    minou: 2,
    mollard: 2,
    'mon cul': 2,
    'mon vier': 2,
    'mon vier, Madame Olivier': 2,
    morue: 2,
    motocultable: 2,
    motoculter: 2,
    'mouche à merde': 2,
    mouille: 2,
    moule: 2,
    'moule à merde': 2,
    'moule-bite': 2,
    mouron: 2,
    'ne plus se sentir pisser': 2,
    'ne rien branler': 2,
    'nique sa mère': 2,
    'nique ta mère': 2,
    niquer: 2,
    'niquer sa mère': 2,
    'nul à chier': 2,
    nulach: 2,
    'nulach’': 2,
    'n’en avoir rien à foutre': 2,
    'oh hisse, enculé': 2,
    'on s’encule': 2,
    ordure: 2,
    'oui ou merde': 2,
    'ouvrir sa grande gueule': 2,
    pachole: 2,
    panier: 2,
    'papier-cul': 2,
    'parachuter un Sénégalais': 2,
    'pare-choc': 2,
    'pare-chocs': 2,
    'parle à mon cul, ma tête est malade': 2,
    'partir en couille': 2,
    'pays-bas': 2,
    'peau de zob': 2,
    peler: 2,
    'pelle à merde': 2,
    'penser avec sa bite': 2,
    'penser avec sa queue': 2,
    'pet sauce': 2,
    piment: 2,
    pine: 2,
    piner: 2,
    pinocumettable: 2,
    pinocumettre: 2,
    pipe: 2,
    'pisse-au-lit': 2,
    pisser: 2,
    'pisser de la copie': 2,
    'pisser sa côtelette': 2,
    'pisser à la raie': 2,
    pisseur: 2,
    'plan cul': 2,
    'planter le javelot dans la moquette': 2,
    'plotte à cash': 2,
    plume: 2,
    poche: 2,
    'pogne-cul': 2,
    poil: 2,
    'pomme à l’huile': 2,
    'pompe à merde': 2,
    pomper: 2,
    porc: 2,
    'porter le pet': 2,
    pougner: 2,
    'pour les couilles du pape': 2,
    pourrir: 2,
    'pousser le repas de la veille': 2,
    'pousser une gueulante': 2,
    poutrer: 2,
    putain: 2,
    'putain con': 2,
    'putain de': 2,
    'putain de bordel de merde': 2,
    'putain de merde': 2,
    'putain de moine': 2,
    'putain de sa mère': 2,
    'putain de sa race': 2,
    'putain de ta race': 2,
    pute: 2,
    'pute borgne': 2,
    'pute de luxe': 2,
    pédale: 2,
    pédoque: 2,
    pédé: 2,
    'pédé comme un Grec': 2,
    pélot: 2,
    pépom: 2,
    'péter dans la soie': 2,
    'péter le cul': 2,
    'péter les couilles': 2,
    'péter à la gueule': 2,
    queue: 2,
    queutard: 2,
    'qu’il aille se faire foutre': 2,
    'qu’ils aillent se faire foutre': 2,
    'qu’on l’encule': 2,
    'raccrocher à la gueule': 2,
    radasse: 2,
    ramoner: 2,
    'ramoner le trou de balle': 2,
    'ras la touffe': 2,
    'ras le cul': 2,
    'ras-la-moule': 2,
    'ras-le-bonbon': 2,
    'ras-les-fesses': 2,
    'ratisser le bunker': 2,
    refucker: 2,
    'remuer la merde': 2,
    retard: 2,
    'retourne aux asperges': 2,
    'rien à enculer': 2,
    'rien à foutre': 2,
    riflard: 2,
    rondelle: 2,
    roubignole: 2,
    roupette: 2,
    'récurer la marmite': 2,
    réempaffer: 2,
    réenculer: 2,
    'sa mère': 2,
    'sac à merde': 2,
    'saint-chrême': 2,
    'saint-ciboire': 2,
    'saint-crême': 2,
    'saint-sacrament': 2,
    'saint-simonaque': 2,
    salaud: 2,
    'salle des fêtes': 2,
    salopard: 2,
    salope: 2,
    'sans-couilles': 2,
    sarce: 2,
    'saute au paf': 2,
    'saute-au-paf': 2,
    sauter: 2,
    schleu: 2,
    schnek: 2,
    'se battre les couilles de': 2,
    'se branler comme de l’an quarante de': 2,
    'se branler les couilles': 2,
    'se casser la gueule': 2,
    'se casser le cul': 2,
    'se casser le tronc': 2,
    'se casser les couilles': 2,
    'se cirer le manche': 2,
    'se cogner': 2,
    'se contrecrisser': 2,
    'se contrefoutre': 2,
    'se câlisser': 2,
    'se doigter': 2,
    'se désenclaver la péninsule': 2,
    'se faire': 1,
    'se faire enculer': 2,
    'se faire la bite': 2,
    'se faire pousser au cul': 2,
    "se faire quelqu'un": 1,
    'se faire reluire': 2,
    'se foutre comme de l’an quarante de': 2,
    'se foutre de': 2,
    'se foutre de la gueule de': 2,
    'se geler le croupion': 2,
    'se geler le fion': 2,
    'se geler les couilles': 2,
    'se les cailler': 2,
    'se magner le cul': 2,
    'se manger les couilles': 2,
    'se ronger le cul à la vinaigrette': 2,
    'se sortir les doigts du cul': 2,
    'se taper': 2,
    'se taper une queue': 2,
    'se vider les couilles': 2,
    'sent-la-pisse': 2,
    shampoing: 2,
    shit: 2,
    simonaque: 2,
    souchien: 2,
    souchienne: 2,
    'sous-merde': 2,
    sphincter: 2,
    'suce-boules': 2,
    'suce-médailles': 2,
    sucer: 2,
    suceuse: 2,
    's’archifoutre': 2,
    's’astiquer le poireau': 2,
    's’en badigeonner les testicules avec le pinceau de l’indifférence': 2,
    's’en battre': 2,
    's’en battre lec': 2,
    's’en beurrer la raie': 2,
    's’en branler': 2,
    's’en cogner': 2,
    's’en câlisser': 2,
    's’en taper': 2,
    'ta bouche': 2,
    'ta gueule': 2,
    'ta mère': 2,
    'ta mère la pute': 2,
    tabarnac: 2,
    tabarnak: 2,
    taboune: 2,
    tafiole: 2,
    tantouze: 2,
    taper: 2,
    tapette: 2,
    tapettitude: 2,
    tapiole: 2,
    tarlouze: 2,
    tartissure: 2,
    taspé: 2,
    tassepé: 2,
    techa: 2,
    tepu: 2,
    teub: 2,
    teuch: 2,
    teucha: 2,
    tg: 2,
    'tire-au-cul': 2,
    tirelire: 1,
    'tirer son coup': 2,
    'tirer un coup': 2,
    'touche à ton cul t’auras des verrues': 2,
    touffe: 1,
    'tout de la gueule': 2,
    trappe: 1,
    'tremper son biscuit': 1,
    treuf: 2,
    troncher: 2,
    'trou de balle': 2,
    'trou de cul': 2,
    'trou du cul': 2,
    'trou du cul du monde': 2,
    trouduc: 2,
    trouducune: 2,
    'trouer le cul': 2,
    troufignoler: 2,
    troufignolerie: 2,
    troufignoliser: 2,
    troufignon: 2,
    truie: 1,
    turluchon: 2,
    turlutte: 2,
    tuyau: 1,
    tâter: 2,
    'téléphoner aux Congolais': 2,
    'tête de gland': 2,
    'tête de nœud': 2,
    'va chier': 2,
    'va libérer Mandela': 2,
    'va niquer ta mère': 2,
    'va te faire empapaouter': 2,
    'va te faire enculer': 2,
    'va te faire foutre': 2,
    'va te faire mettre': 2,
    'va te faire une soupe d’esques': 2,
    'vais libérer Mandela': 2,
    valseuse: 1,
    'vendre du piment': 2,
    viarge: 2,
    vieille: 1,
    vier: 2,
    'vier d’âne': 2,
    vtff: 2,
    'y avoir une couille dans le potage': 2,
    'y avoir une couille dans le pâté': 2,
    yeule: 2,
    youp: 2,
    youpin: 2,
    youpine: 2,
    zboob: 2,
    zboube: 2,
    zguègue: 2,
    'à chier': 2,
    'à couilles rabattues': 2,
    'à la con': 2,
    'à la mords-moi': 1,
    'à la mords-moi-le-nœud': 1,
    'à la roule-moi les couilles dans la laitue': 2,
    'à un poil de cul': 1,
    éburné: 2,
    écouillé: 2,
    'étrangler le borgne': 2,
    'être chié': 2,
    'être couillu': 2,
    'être la fête': 2,
    'être mou de la bite': 2,
  },
};

const getByProbability = (list: Record<string, number>, probability: number) => {
  const words = [];
  const keys = Object.keys(list);
  for (const key of keys) {
    const profaneprob = list[key];
    if (profaneprob >= probability) {
      words.push(key);
    }
  }
  return words;
};

const addToFilteringArray = (langKeys: string[], filterArr: string[]) => {
  for (const key of langKeys) {
    /* @ts-ignore */
    const list = wordsList[key];
    filterArr.push(...getByProbability(list, 2));
  }
};

export const getFilter = (code: string | string[]) => {
  let filterWords: string[] = [];
  const langKeys = Object.keys(wordsList);
  if (code === 'all') {
    addToFilteringArray(langKeys, filterWords);
    return;
  }

  if (Array.isArray(code)) {
    addToFilteringArray(code, filterWords);
  } else {
    /* @ts-ignore */
    filterWords = getByProbability(wordsList[code], 2);
  }

  const filter = new Filter();
  filter.addWords(...filterWords);

  return filter;
};
