import { Cache } from 'aws-amplify';
import { User } from '../graphql/types';
import { UserCurrencyConfig } from './currency';

const keys = {
  authRedirectRoute: 'authRedirectRoute',
  authRedirectSearch: 'authRedirectSearch',
  showConfetti: 'showConfetti',
  userCurrencyConfig: 'userCurrencyConfig',
};
const dynamicKeys = {
  getUserKey: (id: string) => `user_${id}`,
};
const booleanParser = (item: string) => item === '1';
const parsers: { [key: string]: (item: string) => any } = {
  showConfetti: booleanParser,
};

const save = (key: string, value: string, cacheExpirationMs?: number) => {
  let cacheOptions: any = {};

  if (cacheExpirationMs) {
    // const defaultCacheExpirationMs = Date.now() + 60 * 1000; // 60 seconds
    const cacheExpirationDate = new Date(cacheExpirationMs);

    cacheOptions = { expires: cacheExpirationDate.getTime() };
  }

  Cache.setItem(key, value, cacheOptions);
};

const load = (key: string): any => {
  const parser: (item: string) => any = parsers[key];
  const item = Cache.getItem(key);

  if (parser) {
    return parser(item);
  }

  return item;
};

const saveUser = (user: User) => {
  const cacheKey = dynamicKeys.getUserKey(user.id);

  save(cacheKey, JSON.stringify(user));
};

const saveUserCurrencyConfig = (userCurrencyConfig: UserCurrencyConfig) => {
  save(keys.userCurrencyConfig, JSON.stringify(userCurrencyConfig));
};

const loadUser = (userId: string) => {
  const cacheKey = dynamicKeys.getUserKey(userId);
  const cachedUser = load(cacheKey);

  try {
    if (cachedUser) {
      return JSON.parse(cachedUser);
    }
  } catch (e) {
    // Nothing
  }

  return null;
};

const loadUserCurrencyConfig = () => {
  const cachedConfig = load(keys.userCurrencyConfig);

  try {
    if (cachedConfig) {
      return JSON.parse(cachedConfig);
    }
  } catch (e) {
    // Nothing
  }

  return null;
};

const remove = (key: string): any => {
  return Cache.removeItem(key);
};

export default {
  keys,
  save,
  saveUser,
  saveUserCurrencyConfig,
  load,
  loadUser,
  loadUserCurrencyConfig,
  remove,
};
