import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import copy from 'copy-to-clipboard';
import { isMobile as isMobileDevice } from 'react-device-detect';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

import { PeakzTheme } from '../../theme/theme';

const useStyles = makeStyles((theme: PeakzTheme) => ({
  shareButtonRoot: {
    border: `${theme.typography.pxToRem(1)} solid ${theme.palette.peakz.gray}`,
  },
}));

interface Props {
  title: string;
  description: string;
  shareUrl: string;
}

const ShareButton: React.FC<Props> = ({ title, description, shareUrl }: Props) => {
  const classes = useStyles();
  const [toastText, setToastText] = useState('');
  const handleCopyLink = () => {
    copy(shareUrl);

    setToastText('Link to profile was copied to clipboard!');

    setTimeout(() => {
      setToastText('');
    }, 3000);
  };
  const handleMobileShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: title,
          text: description,
          url: shareUrl,
        })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    }
  };
  const handleClick = () => {
    if (isMobileDevice) {
      handleMobileShare();
    } else {
      handleCopyLink();
    }
  };
  const handleToastClose = () => {
    setToastText('');
  };

  return (
    <>
      <IconButton className={classes.shareButtonRoot} color="primary" size="medium" onClick={handleClick}>
        <i className="far fa-share-square"></i>
      </IconButton>
      <Snackbar
        open={Boolean(toastText)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={handleToastClose}
      >
        <Alert onClose={handleToastClose} severity="success">
          {toastText}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ShareButton;
