import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { validate as uuidValidate } from 'uuid';

import { getUserIdFromNickname, fetchUserById, fetchUserByNickname } from '../core/services/userSvc';
import { User } from '../core/graphql/types';
import { authStore } from '../core/stores/auth';
import AppHeaderPlaceHolder from '../components/layouts/AppHeaderPlaceHolder';

const UserJsonPage: React.FC = () => {
  const userIdParam = useParams<{ userId?: string }>().userId || '';
  const history = useHistory();
  const [authState] = useContext(authStore);
  const [user, setUser] = useState<User | undefined>();
  const [userJson, setUserJson] = useState('');
  const [t] = useTranslation();
  const loadUser = useCallback(
    async (userIdentifier: string) => {
      const isNickname = !uuidValidate(userIdentifier);
      const userId = isNickname ? await getUserIdFromNickname(userIdentifier) : userIdentifier;
      const user = isNickname ? await fetchUserByNickname(userIdentifier) : await fetchUserById(userId);

      if (!user) {
        history.push({
          pathname: '/athletenotfound',
          state: {
            message: t('global.athlete_not_found'),
          },
        });
      } else {
        if (user) {
          setUser(user);
          setUserJson(JSON.stringify(user));
        }
      }
    },
    [authState.user],
  );

  useEffect(() => {
    try {
      (async function () {
        if (userIdParam) {
          loadUser(userIdParam);
        } else if (authState.user) {
          loadUser(authState.user.userId);
        }
      })();
    } catch (e) {
      history.push({
        pathname: '/athletenotfound',
        state: {
          message: t('global.athlete_not_found'),
        },
      });
    }
  }, [userIdParam, loadUser]);

  return (
    <Container maxWidth="md">
      <AppHeaderPlaceHolder />
      <Typography variant="h5">ID</Typography>
      <Typography variant="body1">{user?.id}</Typography>
      <br />
      <Typography variant="h5">JSON</Typography>
      <Typography variant="body1">{userJson}</Typography>
    </Container>
  );
};

export default UserJsonPage;
