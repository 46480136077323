import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Variant } from '@material-ui/core/styles/createTypography';
import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress';

type TextColor = 'initial' | 'inherit' | 'primary' | 'secondary' | 'textPrimary' | 'textSecondary' | 'error';

const CircularProgressWithLabel = (
  props: CircularProgressProps & { value: number; textVariant?: Variant; textColor?: TextColor },
) => {
  const { textVariant = 'caption', textColor = 'textSecondary', ...theProps } = props;

  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...theProps} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant={textVariant} component="div" color={textColor}>{`${Math.round(
          theProps.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
};

export default CircularProgressWithLabel;
