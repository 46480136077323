import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import AppHeaderPlaceHolder from './AppHeaderPlaceHolder';
import PageTitle from './PageTitle';
import { PeakzTheme } from '../../theme/theme';
import globalStyles from '../../theme/globalStyles';

const useStyles = makeStyles((theme: PeakzTheme) => ({
  basicPageLayoutRoot: { ...globalStyles(theme).pageRoot },
  basicPageLayoutContent: { ...globalStyles(theme).pageContentMd },
}));

interface Props {
  title?: string;
  titleChildren?: React.ReactNode;
  titleMarginSize?: 'normal' | 'none';
  breadCrumbs?: { title: string; route?: string }[];
  maxWidth?: 'sm' | 'md' | 'lg';
  showTitle?: boolean;
  children: React.ReactNode;
}

const BasicPageLayout: React.FC<Props> = ({
  title,
  titleChildren,
  titleMarginSize,
  breadCrumbs,
  maxWidth = 'lg',
  showTitle = true,
  children,
}: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.basicPageLayoutRoot}>
      <AppHeaderPlaceHolder />
      <Container className={classes.basicPageLayoutContent} maxWidth={maxWidth}>
        {/* Page Title */}
        {showTitle && (title || titleChildren) && (
          <PageTitle text={title} marginSize={titleMarginSize} breadCrumbs={breadCrumbs}>
            {titleChildren}
          </PageTitle>
        )}

        {/* Content */}
        {children}
      </Container>
    </div>
  );
};

export default BasicPageLayout;
