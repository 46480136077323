import { Box, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import i18next from 'i18next';
import clsx from 'clsx';

const languagesData = [
  {
    img: '/locales/en/en_icon.png',
    code: 'en',
  },
  {
    img: '/locales/es/es_icon.png',
    code: 'es',
  },
];

const useStyles = makeStyles(() => ({
  selected: {
    filter: 'grayscale(0%)',
  },
  unselected: {
    filter: 'grayscale(60%)',
  },
}));

interface Props {
  justifyContent: 'flex-start' | 'flex-end' | 'center';
}

const LanguageSelector: React.FC<Props> = ({ justifyContent = 'flex-start' }: Props) => {
  const classes = useStyles();
  const [, setSelectedIndex] = useState(-1);
  const handleClick = (code: string, index: number) => {
    setSelectedIndex(index);
    i18next.changeLanguage(code);
  };

  return (
    <Box display="flex" flexDirection="row" justifyContent={justifyContent}>
      {languagesData.map((lang, index) => {
        return (
          <img
            onClick={() => {
              handleClick(lang.code, index);
            }}
            key={lang.code}
            style={{ margin: '5px', cursor: 'pointer' }}
            className={clsx({
              [classes.selected]: localStorage.getItem('language') === lang.code,
              [classes.unselected]: localStorage.getItem('language') !== lang.code,
            })}
            width="20px"
            height="20px"
            src={lang.img}
          />
        );
      })}
    </Box>
  );
};

export default LanguageSelector;
