import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import domHelpers from '../../core/helpers/dom';

interface Props {
  children: React.ReactNode[] | React.ReactNode;
  actions?: React.ReactNode;
  showDivider?: boolean;
}

const PaperForm: React.FC<Props> = ({ actions, children, showDivider = true }: Props) => {
  const theme = useTheme();
  const isSmallScreen = domHelpers.isSmallScreen(useMediaQuery, theme);

  return (
    <Paper variant="outlined">
      <Box p={isSmallScreen ? 2 : 3}>
        <Grid container direction="column" spacing={isSmallScreen ? 2 : 4}>
          {children}
        </Grid>
      </Box>
      {showDivider && <Divider />}
      <Box p={2}>{actions}</Box>
    </Paper>
  );
};

export default PaperForm;
