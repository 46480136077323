/*
 * Using mui-rff
 * Check following link for more info: https://github.com/lookfirst/mui-rff
 * */

import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import * as Yup from 'yup';
import { makeRequired, makeValidate } from 'mui-rff';
import { Form } from 'react-final-form';
import Alert from '@material-ui/lab/Alert/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

import FormRow from '../../../components/ui/FormRow';
import FormTextField from '../../../components/ui/FormTextField';
import { pwdMinLen } from '../../../config';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(2),
  },
  grid: {
    width: '100%',
    margin: 0,
  },
  submitBtnWrapper: {
    position: 'relative',
  },
  submitBtn: {
    margin: theme.spacing(2, 0, 1),
    height: 50,
  },
  submitBtnProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

interface ForgotPasswordForm2Props {
  userEmail: string | undefined;
  loading: boolean;
  errorTxt: string | undefined;
  onSubmit: (data: ForgotPasswordForm2Data) => void;
  onAlertCloseClick: () => void;
}

export interface ForgotPasswordForm2Data {
  code: string;
  password: string;
}

const formSchema = Yup.object().shape({
  code: Yup.string().required(),
  password: Yup.string().min(pwdMinLen, `Password needs to be at least ${pwdMinLen} characters.`),
});

const validate = makeValidate(formSchema);
const required = makeRequired(formSchema);

const ForgotPasswordForm2: React.FC<ForgotPasswordForm2Props> = ({
  loading,
  userEmail,
  errorTxt,
  onSubmit,
  onAlertCloseClick,
}: ForgotPasswordForm2Props) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const subscription = { submitting: true };
  const handleSubmitClick = (values: ForgotPasswordForm2Data) => {
    onSubmit(values);
  };
  const handleAlertCloseClick = () => {
    onAlertCloseClick();
  };

  return (
    <Form
      key={subscription as any}
      subscription={subscription}
      validate={validate}
      onSubmit={handleSubmitClick}
      render={({ handleSubmit }) => (
        <form className={classes.form} onSubmit={handleSubmit} noValidate={true} autoComplete="new-password">
          <Grid className={classes.grid} container direction="column" spacing={2}>
            <Grid item xs>
              <Typography
                variant="body1"
                gutterBottom
                dangerouslySetInnerHTML={{
                  __html: t('pages.forgot_password.step2_title', { userEmail: `<b>${userEmail}</b>` }),
                }}
              ></Typography>
            </Grid>
            <Grid item xs>
              <FormRow label={t('pages.forgot_password.field2_label')}>
                <FormTextField
                  id="code"
                  name="code"
                  type="number"
                  required={required.code}
                  autoFocus
                  helperText={t('pages.forgot_password.field2_helper_text')}
                />
              </FormRow>

              <FormRow label={t('pages.forgot_password.field3_label')}>
                <FormTextField
                  id="password"
                  name="password"
                  type="password"
                  required={required.password}
                  fullWidth
                  helperText={t('pages.forgot_password.field3_helper_text')}
                />
              </FormRow>

              {/* Error alert */}
              {errorTxt && (
                <Alert variant="outlined" onClose={handleAlertCloseClick} severity="error">
                  {errorTxt}
                </Alert>
              )}

              {/* Submit */}
              <div className={classes.submitBtnWrapper}>
                <Button
                  className={classes.submitBtn}
                  type="submit"
                  disabled={loading}
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  {t('global.submit_button')}
                </Button>
                {loading && <CircularProgress className={classes.submitBtnProgress} size={24} />}
              </div>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

export default ForgotPasswordForm2;
