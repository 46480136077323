import { lighten, Theme } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';

const globalStyles = (theme: Theme) => ({
  pageRoot: {
    width: '100%',
  },
  pageContentMd: {
    padding: theme.spacing(4, 16),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(3, 2),
    },
  },
  pageContentCompact: {
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 2),
    },
  },
  formTextField: {
    minHeight: `${theme.typography.pxToRem(50)} !important`,
    paddingTop: 0,
    paddingBottom: 0,
  },
  formTextArea: {
    height: `${theme.typography.pxToRem(50)} !important`,
    paddingTop: 0,
    paddingBottom: 0,
  },
  formSelect: {
    height: `${theme.typography.pxToRem(50)} !important`,
    paddingTop: 0,
    paddingBottom: 0,
    '& > .MuiSelect-select': {
      paddingTop: '14.5px',
      paddingBottom: '14.5px',
    },
  },
  formAutocomplete: {
    '& > .MuiInputBase-root': {
      paddingTop: '11px !important',
      paddingBottom: '11px !important',
    },
  },
  formDatePicker: {
    '& > .MuiInputBase-root': {
      height: `${theme.typography.pxToRem(50)} !important`,
    },
    '& input': {
      paddingTop: '14.5px !important',
      paddingBottom: '14.5px !important',
    },
  },
  centerAbsolute: {
    position: 'absolute' as const,
    margin: 'auto',
    top: '50%',
    left: '50%',
    '-webkit-transform': 'translate(-50%,-50%)',
    '-ms-transform': 'translate(-50%,-50%)',
    transform: 'translate(-50%,-50%)',
  },
  charCounter: {
    position: 'absolute',
    right: theme.spacing(1),
    bottom: theme.spacing(1 / 2),
  },
  roundedCorners: {
    borderRadius: '4px',
  },
  socialConnectButton: {
    borderRadius: theme.typography.pxToRem(20),
    height: theme.typography.pxToRem(35),
    border: 'none',
    minWidth: theme.typography.pxToRem(150),
    color: 'white',
    cursor: 'pointer',
  },
  styledCarouselArrowsAndDots: {
    '& .rec-arrow': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      color: lighten(theme.palette.primary.main, 0.4),
      fontSize: theme.typography.pxToRem(50),
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
      '&:hover:enabled, &:focus:enabled': {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        color: theme.palette.primary.main,
      },
    },
    '& .rec-pagination': {
      display: 'none',
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        margin: theme.spacing(5),
      },
    },
    '& .rec-dot': {
      display: 'none',
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        pointerEvents: 'none',
        boxShadow: `0 1px 3px ${lighten('#000', 0.7)}`,
        '&:hover, &:focus': {
          backgroundColor: lighten(theme.palette.primary.main, 0.2),
          boxShadow: `0 1px 3px ${lighten('#000', 0.9)}`,
        },
      },
    },
    '& .rec-dot_active': {
      backgroundColor: lighten(theme.palette.primary.main, 0.2),
      boxShadow: `0 1px 3px ${lighten('#000', 0.9)}`,
    },
  },
  styledVideoBar: {
    '& .vjs-control-bar': {
      backgroundColor: 'transparent',
      '& .vjs-picture-in-picture-control, .vjs-remaining-time': {
        display: 'none',
      },
      '& .vjs-play-progress': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
});

export const successColorStrong = green['400'];

export default globalStyles;
