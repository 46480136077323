import React, { useState, useEffect, useMemo, MouseEventHandler } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Zoom from '@material-ui/core/Zoom';
import clsx from 'clsx';
import { Avatar } from '@material-ui/core';
import GraphicEqIcon from '@material-ui/icons/GraphicEq';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import ErrorIcon from '@material-ui/icons/Error';

import { Post, PostVodStatus } from '../../../core/graphql/types';
import VideoPlayer from '../../ui/VideoPlayer';
import urlHelpers from '../../../core/helpers/url';
import PostLockOverlay from '../PostLockOverlay';
import videoSvc from '../../../core/services/videoSvc';
import { fetchPostVodToken } from '../../../core/services/postsSvc';
import { AuthState } from '../../../core/stores/auth';
import AudioPlayer from '../../ui/AudioPlayer';

interface Props {
  post: Post;
  authState: AuthState;
  globalClasses: any;
  classes: any;
  viewPermission: boolean;
  handleSubscribeClick: MouseEventHandler<HTMLDivElement>;
  setPost: React.Dispatch<React.SetStateAction<Post | undefined>>;
}

const PodcastView: React.FC<Props> = (props: Props) => {
  const { post, globalClasses, classes, viewPermission, handleSubscribeClick, setPost, authState } = props;

  const [previewUrl, setPreviewUrl] = useState('');
  const [vodUrl, setVodUrl] = useState('');
  const [loadingVodToken, setLoadingVodToken] = useState(false);
  const [thumbUrl, setThumbUrl] = useState('');

  useEffect(() => {
    if (post) {
      if (!post.vodId && post.thumbS3Key) {
        setThumbUrl(urlHelpers.getMediaUrlFromS3Key(post.thumbS3Key, 'img'));
      } else if (post.vodId) {
        setThumbUrl(urlHelpers.getPostVodThumbUrl(post.vodId));
      }
    }
  }, [post]);

  const renderPreview = useMemo(() => {
    return (
      <Zoom in={Boolean(previewUrl)} timeout={{ enter: 500, exit: 500 }}>
        <div style={{ position: 'absolute', top: 0, width: '100%', height: '100%', background: '#000000' }}>
          <VideoPlayer
            className={classes.videoPlayer}
            videoUrl={previewUrl}
            autoplay={true}
            onVideoEnded={() => {
              setPreviewUrl('');
            }}
          />
        </div>
      </Zoom>
    );
  }, [previewUrl]);

  const handleWatchPreviewClick = () => {
    if (post && post.vodId) {
      setPreviewUrl(urlHelpers.getPostVodPreviewUrl(post.vodId));
    }
  };

  const handlePlayVodClick = async () => {
    if (post && post.vodId && !vodUrl) {
      if (post.vodToken) {
        // Test url
        // setVodUrl(
        //   'https://multiplatform-f.akamaihd.net/i/multi/will/bunny/big_buck_bunny_,640x360_400,640x360_700,640x360_1000,950x540_1500,.f4v.csmil/master.m3u8',
        // );
        setVodUrl(await videoSvc.getPostVodPlaylistManifest(post.vodId, post.vodToken));
      } else if (!loadingVodToken) {
        // If there is no vodToken, fetch it
        setLoadingVodToken(true);
        const { vodToken } = await fetchPostVodToken(post.id, Boolean(authState.user));
        setLoadingVodToken(false);

        setPost((p) => {
          if (p) {
            return { ...p, vodToken };
          }

          return p;
        });
        setVodUrl(await videoSvc.getPostVodPlaylistManifest(post.vodId, vodToken));
      }
    }
  };

  return (
    <div style={{ position: 'relative' }} className={classes.postPodcastHeadWrapper}>
      <div
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          filter: 'blur(10px)',
          backgroundImage: `url(${thumbUrl})`,
          backgroundSize: 'cover',
          boxShadow: 'rgba(0, 0, 0, .5) 0 0 0 1000000px inset',
        }}
      >
        &nbsp;
      </div>

      {/* Processing Status */}
      {post.vodStatus === PostVodStatus.progressing && (
        <>
          <div className={classes.incompleteBg} />
          <div className={globalClasses.centerAbsolute}>
            <Box display="flex" justifyContent="center" width={1}>
              <img src="/static/images/loaders/peakz-logo-loader.gif" alt="loading..." height="50px" />
            </Box>
            <Box mt={1}>
              <Typography variant="body1" color="primary" align="center">
                Optimizing audio...
              </Typography>
            </Box>
          </div>
        </>
      )}

      {/* Complete Status */}
      {post.vodStatus === PostVodStatus.complete && (
        <>
          {/* Blocker */}
          {!viewPermission && (
            <div className={classes.postThumbVodWrapper} onClick={handleSubscribeClick}>
              <img
                className={clsx({
                  [classes.postVodThumb]: true,
                  [classes.postVodThumbBlur]: true,
                })}
                src={thumbUrl}
                alt="header-img"
                width="100%"
                height="100%"
              />
              <PostLockOverlay post={post} classes={classes} onWatchPreviewClicked={handleWatchPreviewClick} />
            </div>
          )}

          {/* Preview */}
          {renderPreview}

          {/* Audio is playable */}
          {viewPermission && (
            <Box style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'center' }}>
                <Avatar style={{ width: '100px', height: '100px' }} src={thumbUrl} />
                <Box zIndex={999} mt={2} mb={0}>
                  <GraphicEqIcon style={{ fontSize: '30px' }} color="primary" />
                </Box>
                {!vodUrl && (
                  <Zoom in={!loadingVodToken} timeout={1000}>
                    <Button variant="outlined" color="primary" onClick={handlePlayVodClick} size="small">
                      Play this podcast
                    </Button>
                  </Zoom>
                )}
              </div>
              {vodUrl && post.vodToken && (
                <Box p={2}>
                  <AudioPlayer isHLS={true} url={vodUrl} />
                </Box>
              )}
            </Box>
          )}
        </>
      )}

      {/* Other Status */}
      {post.vodStatus !== PostVodStatus.complete && post.vodStatus !== PostVodStatus.progressing && (
        <>
          <div className={classes.incompleteBg} />
          <Chip
            className={globalClasses.centerAbsolute}
            variant="outlined"
            color="primary"
            icon={<ErrorIcon />}
            label={post.vodStatus?.toUpperCase()}
          />
        </>
      )}
    </div>
  );
};

export default PodcastView;
