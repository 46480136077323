import config from '../../config';
import { User, UserMin } from '../graphql/types';
// ------ Router Routes -----
export const loginRoute = '/login';
export const signupRoute = '/signup';
export const loginJoinRoute = '/join/:userId/login';
export const signupJoinRoute = '/join/:userId/signup';
export const userHomeRoute = '/home';
export const premiumInviteRoute = '/premiuminvite/:name/:email';
export const athleteChannelRoute = '/channel';
export const athleteChannelSocialRoute = '/channel/social';
export const athleteChannelPostsRoute = '/channel/posts';
export const athleteChannelFundingRoute = 'channel/funding';
export const athletePaymentsRoute = '/payments';
export const membershipsRoute = '/memberships';
export const userSettingsRoute = '/settings';
export const athleteProfileEditRoute = '/profile';
export const athleteProfileSportsEditRoute = '/profile/sports';
export const becomeAthleteRoute = '/becomeathlete';
export const athletesRoute = '/athletes';
export const aboutRoute = '/about';
export const fansRoute = '/fans';
export const policyRoute = '/policies';
export const academyRoute = '/academy';
export const createPostRoute = '/create/post';
export const athleteCheckoutRoute = '/join/:userId/checkout';
export const athleteProfileRoute = '/:userId';
export const policiesRoute = '/policies';
export const athleteOverviewRoute = '/athletes/overview';
export const userJSONRoute = '/a/:userId/json';
export const postViewerRoute = '/p/:postId';
export const docsRoute = '/docs/:docId';
export const blogViewerRoute = '/blogs/:blogId';
export const forgotPasswordRoute = '/forgotpassword';
export const notFoundRoute = '/notfound';
export const athleteNotFoundRoute = '/athletenotfound';
export const postNotFoundRoute = '/postnotfound';
export const noDocFoundRoute = '/nodocfound';
export const qwertyRoute = '/qwerty';
export const athleteProfileRouterExt = '/a/:userId';
// Admin routes
export const adminDashboardRoute = '/pkz-admin';
export const reviewDashboardRoute = `${adminDashboardRoute}/review`;
export const pushNotificationsDashboardRoute = `${adminDashboardRoute}/push`;
// bcast
export const bcastFormRoute = '/bcast/:id';
export const meetingPageRoute = '/meeting/:id';
export const broadcastPageRoute = '/broadcast/:id';
// ----- Calculated Routes ------
export const getAthleteProfileRouteById = (userId: string): string => {
  return `/a/${userId}`;
};
export const getAthleteProfileRouteByNickname = (nickname: string): string => {
  return `/${nickname}`;
};
export const getPostRoute = (postId: string): string => {
  return `/p/${postId}`;
};
export const getBcastRoute = (bcastId: string): string => {
  return `/bcast/${bcastId}`;
};

export const getMeetingRoute = (bcastId: string, relative = true): string => {
  if (relative) {
    return `/meeting/${bcastId}`;
  } else {
    return `${config.baseUrl}/meeting/${bcastId}`;
  }
};

export const getAthleteProfileRoute = (user: User | UserMin): string => {
  if (user.nickname) {
    return getAthleteProfileRouteByNickname(user.nickname);
  } else {
    return '/';
  }
};
export const getAthleteCheckoutRoute = (user: User | UserMin, tierId?: string): string => {
  return `/join/${user.nickname || user.id}/checkout${tierId ? `?tid=${tierId}` : ''}`;
};
export const getSignupRoute = (user?: User | UserMin): string => {
  if (user) {
    return `/join/${user.nickname || user.id}/signup`;
  }
  return '/signup';
};

export const getBaseRoute = (pathname: string) => {
  const splitted = pathname.split('/');
  if (splitted.length > 2) {
    return `/${splitted[1]}`;
  }
  return pathname;
};

export default {
  // ------ Static Routes -------
  becomeAthleteRoute,
  membershipsRoute,
  userSettingsRoute,
  createPostRoute,
  // ----- Calculated Routes ------
  getPostRoute,
  getBcastRoute,
  getAthleteCheckoutRoute,
  getSignupRoute,
  getAthleteProfileRoute,
};
