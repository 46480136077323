import { Box, Grid, LinearProgress, Typography } from '@material-ui/core';
import React from 'react';

interface Props {
  classes: any;
  uploadedPercent: number;
}

const PostStep3: React.FC<Props> = ({ classes, uploadedPercent }: Props) => {
  return (
    <Grid className={classes.content} container direction="column" alignItems="center" justifyContent="center">
      {uploadedPercent >= 0 && (
        <Grid className={classes.fullWithItem} item>
          <Box display="flex" justifyContent="center" width={1}>
            {uploadedPercent === 0 && (
              <Typography variant="h6" align="center">
                Preparing upload...
              </Typography>
            )}
            {uploadedPercent > 0 && uploadedPercent <= 100 && (
              <Typography variant="h6" align="center">
                {uploadedPercent}% uploaded
              </Typography>
            )}
          </Box>
          <Box width={1} mt={1} pl={3} pr={3}>
            <Grid container justifyContent="center">
              <Grid item xs={12} sm={10}>
                <LinearProgress variant="determinate" value={uploadedPercent} />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default PostStep3;
