import React, { useCallback, useContext, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import { nanoid } from 'nanoid';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import ReCAPTCHA from 'react-google-recaptcha';
import { noop } from '@babel/types';
import BasicPageLayout from '../../components/layouts/BasicPageLayout';
import VideoPlayer from '../../components/ui/VideoPlayer';
import { createPost } from '../../core/services/postsSvc';
import { authStore } from '../../core/stores/auth';
import { Tier, User } from '../../core/graphql/types';
import { fetchTiers } from '../../core/services/subscriptionsSvc';
import { uploadVod } from '../../core/services/videoSvc';
import {
  athletePaymentsRoute,
  getAthleteProfileRouteById,
  getAthleteProfileRouteByNickname,
} from '../../core/helpers/route';
import { appStore } from '../../core/stores/app';
import { reportError } from '../../core/helpers/core';
import CreateTierDialog from '../../containers/dialogs/CreateTierDialog';
import CreatePostBlockerDialog from '../../components/dialogs/CreatePostBlockerDialog';
import { moderationFilter } from '../../App';
import { recaptchaSiteKeyV3 } from '../../config';
import { getPayoutCurrency } from '../../core/helpers/user';
import ShareAfterPost from './ShareAfterPost';
import PreviewCropper from './PreviewCropper';
import domHelpers from '../../core/helpers/dom';
import PostStep0 from './steps/PostStep0';
import PostStep1 from './steps/PostStep1';
import PostStep2 from './steps/PostStep2';
import PostStep3 from './steps/PostStep3';
import Controls from './Controls';
import { PostType } from '../../API';
import PostTypeSelector from './PostTypeSelector';
import AudioPlayer from '../../components/ui/AudioPlayer';
import { useMediaQuery, useTheme } from '@material-ui/core';

const videoHeight = 350;
const useStyles = makeStyles((theme) => ({
  createPostPageRoot: {
    width: '100%',
    '& .html5-video-player': {
      backgroundColor: '#fff',
    },
  },
  contentContainer: {
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
  contentWrapper: {
    height: 'auto',
    minHeight: '300px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    height: '100%',
  },
  blackBg: {
    backgroundColor: '#000000',
  },
  fileUploadIcon: {
    fontSize: theme.typography.pxToRem(90),
  },
  uploadButton: {
    height: theme.typography.pxToRem(50),
    paddingLeft: theme.typography.pxToRem(30),
    paddingRight: theme.typography.pxToRem(30),
  },
  videoPlayer: {
    maxHeight: theme.typography.pxToRem(videoHeight),
    maxWidth: '100%',
    [theme.breakpoints.down('md')]: {
      maxHeight: theme.typography.pxToRem(300),
    },
  },
  videoPlayerWrapper: {
    maxHeight: theme.typography.pxToRem(400),
  },
  videoPlayerBlur: {
    filter: 'blur(2px)',
    '-webkit-filter': 'blur(2px)',
  },
  fullWithItem: {
    width: '100%',
  },
  maxFullWithItem: {
    maxWidth: '100%',
  },
  ctaButton: {
    height: theme.typography.pxToRem(50),
    paddingLeft: theme.typography.pxToRem(30),
    paddingRight: theme.typography.pxToRem(30),
  },
}));

const steps = ['Type of content', 'Select a file', 'Monetization', 'Post details', 'Upload!'];
const maxPostName = 50;
const maxPostDescription = 400;
const CreatePostPage: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const [authState] = useContext(authStore);
  const [appState, appDispatch] = useContext(appStore);
  const [previewRange, setPreviewRange] = useState<any>({
    start: '0',
    end: '0',
  });
  const [videoDimensions, setVideoDimensions] = useState<any>({
    width: '0',
    height: '0',
  });
  const theme = useTheme();
  const [postType, setPostType] = useState<PostType | undefined>(PostType.video);
  const [podcastCoverImage, setPodcastCoverImage] = useState<File | undefined>();
  const [postNeedsSubscription, setpostNeedsSubscription] = useState<boolean>(true);
  const recaptchaRef: any = useRef();
  const [createTierDialogOpen, setCreateTierDialogOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [postName, setPostName] = useState('');
  const [detectedProfanity, setDetectedProfanity] = useState(false);
  const [postDescription, setPostDescription] = React.useState('');
  const [tier, setTier] = useState<Tier | undefined>();
  const [fileToUpload, setFileToUpload] = useState<File | null>(null);
  const [uploadedPercent, setUploadedPercent] = useState(-1);
  const [postCreationComplete, setPostCreationComplete] = useState(false);
  const [createPostBlockerDialogOpen, setCreatePostBlockerDialogOpen] = useState(false);
  const [postId, setPostId] = useState<string | undefined>();
  const isSocialRedirect = Boolean(new URLSearchParams(location.search).get('oauth_token'));
  const playerRef = React.createRef<HTMLVideoElement>();
  const isSmallScreen = domHelpers.isSmallScreen(useMediaQuery, theme);

  const onPreviewRangeChange = (start: string, end: string) => {
    let newValues = Object.assign({}, previewRange);
    newValues = {
      start: start,
      end: end,
    };
    setPreviewRange(newValues);
  };

  const loadTiers = useCallback(
    async (athlete: User) => {
      try {
        if (!tier) {
          const tiers = await fetchTiers(athlete.id);

          if (tiers.length > 0) {
            setTier(tiers[0]);
          } else {
            // OPEN PAYMENT DIALOG HERE
            setCreateTierDialogOpen(true);
          }
        }
      } catch (err) {
        appDispatch({
          type: 'showToast',
          toastTxt: 'There has been a problem creating the Tier',
          toastSeverity: 'error',
        });
        reportError('create_post', 'load_tiers', err);
      }
    },
    [authState.user],
  );
  const submitPost = async (videoId: string) => {
    if (authState.user && tier) {
      try {
        const postId = nanoid(11);

        //Set the postId for post update subscription on share component
        setPostId(postId);

        const postData: any = {
          id: postId,
          type: postType,
          authorId: authState.user.userId,
          name: postName,
          description: postDescription,
          needsSubscription: postNeedsSubscription,
          tierId: tier.id,
          feedId: 'fansDashboard',
          vodId: videoId,
          vodStatus: 'progressing',
        };
        await createPost(postData);

        return true;
      } catch (err: any) {
        console.log(err);
        appDispatch({
          type: 'showToast',
          toastTxt: 'The post could not be created. Please, contact Peazk admins',
          toastSeverity: 'error',
        });
        reportError('create_post', 'idem', err.message);
      }
    } else {
      const errMsg = 'Post can not be created. The user is not currently logged or there is no Tier';

      appDispatch({
        type: 'showToast',
        toastTxt: errMsg,
        toastSeverity: 'error',
      });
      reportError('create_post', 'idem', new Error(errMsg));
    }

    return false;
  };
  const handleFinishClick = () => {
    if (authState.user) {
      let redirectRoute;
      if (authState.userNickname) {
        redirectRoute = getAthleteProfileRouteByNickname(authState.userNickname);
      } else {
        redirectRoute = getAthleteProfileRouteById(authState.user.userId);
      }

      history.push({
        pathname: redirectRoute,
        state: {
          scroll: true,
        },
      });
    }
  };
  const uploadAndSubmit = async (file: File) => {
    // Upload video
    console.log('upload post');

    if (authState.user) {
      try {
        // Upload video
        const recaptchaToken = await recaptchaRef.current.executeAsync();
        setUploadedPercent(0);
        const videoMetadata = {
          ...previewRange,
          ...videoDimensions,
        };

        const vodId = await uploadVod(file, recaptchaToken, postType, videoMetadata, podcastCoverImage, (percent) => {
          setUploadedPercent(percent);
        });

        // Create the post
        console.log('created post', vodId);
        submitPost(vodId);
        setPostCreationComplete(true);
        if (postType === PostType.podcast) {
          handleFinishClick();
        }
      } catch (err) {
        console.log(err);
        appDispatch({
          type: 'showToast',
          toastTxt: "There's been an error creating your Post.",
          toastSeverity: 'error',
        });
        setUploadedPercent(-1);
        setActiveStep(3);
        reportError('create_post', 'idem', err);
      }
    }
  };

  const isNextDisabled = () => {
    const typeCondition = activeStep === 0 && !postType;
    const fileCondition =
      (postType === PostType.video && activeStep === 1 && !fileToUpload) ||
      (postType === PostType.podcast && activeStep === 1 && (!fileToUpload || !podcastCoverImage));
    const monetizableCondition = activeStep === 2 && false;
    const postDataCondition =
      activeStep === 3 &&
      (!postName || !postDescription || postName.length > maxPostName || postDescription.length > maxPostDescription);
    const stepCondition = activeStep === steps.length - 1;
    return typeCondition || fileCondition || monetizableCondition || postDataCondition || stepCondition;
  };
  const handleNextClick = () => {
    // If we completed step 1 -> Upload
    if (activeStep === 3 && fileToUpload) {
      if (moderationFilter?.isProfane(postName) || moderationFilter?.isProfane(postDescription)) {
        setDetectedProfanity(true);
        return;
      }
      uploadAndSubmit(fileToUpload);
    }

    // Increase active step
    setActiveStep((prevActiveStep) => Math.min(steps.length - 1, prevActiveStep + 1));
  };

  const handleTierCreated = (tier: Tier) => {
    setTier(tier);
    setCreateTierDialogOpen(false);
  };
  const handleCreatePostBlockerClick = () => {
    history.push(athletePaymentsRoute);
  };

  useEffect(() => {
    isSocialRedirect ? setPostCreationComplete(true) : noop();
  }, []);

  useEffect(() => {
    setDetectedProfanity(false);
  }, [postName, postDescription]);

  useEffect(() => {
    (async function () {
      if (appState.user) {
        if (appState.user.stripePayoutsEnabled && appState.user.payoutCurrency) {
          await loadTiers(appState.user);
        } else {
          setCreatePostBlockerDialogOpen(true);
        }
      }
    })();
  }, [appState.user, loadTiers]);

  return (
    <BasicPageLayout titleMarginSize="none">
      {!postCreationComplete && (
        <>
          {/* Stepper */}
          <Stepper
            activeStep={activeStep}
            orientation={isSmallScreen ? 'vertical' : 'horizontal'}
            alternativeLabel={!isSmallScreen}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          {/* Content Wrapper */}
          <Container maxWidth="md" className={classes.contentContainer}>
            {/* Content */}
            <Paper
              style={{ display: 'flex', flexDirection: 'column' }}
              className={classes.contentWrapper}
              variant="outlined"
            >
              {/* Step 0 (Not really a step, here the athlete select the kind of content for the post) */}
              {activeStep === 0 && <PostTypeSelector postType={postType} onPostTypeSelect={setPostType} />}
              {/* Step 1 (Select Video or Audio file) */}
              {activeStep === 1 && (
                <PostStep0
                  setPodcastCoverImage={setPodcastCoverImage}
                  setVideoDimensions={setVideoDimensions}
                  setFileToUpload={setFileToUpload}
                  postType={postType}
                  classes={classes}
                  fileToUpload={fileToUpload}
                  tier={tier}
                  videoPlayer={
                    fileToUpload && (
                      <VideoPlayer
                        ref={playerRef}
                        className={classes.videoPlayer}
                        videoUrl={URL.createObjectURL(fileToUpload)}
                      />
                    )
                  }
                  audioPlayer={
                    fileToUpload && (
                      <AudioPlayer isPreview={true} isHLS={false} url={URL.createObjectURL(fileToUpload)} />
                    )
                  }
                  audioCoverSrc={podcastCoverImage ? podcastCoverImage : undefined}
                />
              )}
              {fileToUpload && postType === PostType.video && (
                <PreviewCropper
                  onChange={onPreviewRangeChange}
                  dropzoneReset={activeStep !== 1 || !fileToUpload}
                  videoRef={playerRef}
                />
              )}
              {/* Step 2 (Select the type of post, free or monetizable) */}
              {activeStep === 2 && <PostStep1 setPostNeedsSubscription={setpostNeedsSubscription} />}
              {/* Step 3 (Select Title and Desc) */}
              {activeStep === 3 && (
                <PostStep2
                  maxPostDescription={maxPostDescription}
                  maxPostName={maxPostName}
                  setPostName={setPostName}
                  setPostDescription={setPostDescription}
                  postName={postName}
                  postDescription={postDescription}
                  detectedProfanity={detectedProfanity}
                  classes={classes}
                />
              )}

              {/* Step 4 (Create Post) */}
              {activeStep === 4 && <PostStep3 uploadedPercent={uploadedPercent} classes={classes} />}
              <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={recaptchaSiteKeyV3} />
            </Paper>
          </Container>

          {/* Controls */}
          {uploadedPercent < 0 && (
            <Controls
              fileToUpload={fileToUpload}
              setFileToUpload={setFileToUpload}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              isNextDisabled={isNextDisabled}
              handleNextClick={handleNextClick}
            />
          )}
        </>
      )}

      {postCreationComplete && postType === PostType.video && (
        <>
          <Container maxWidth="md" className={classes.contentContainer}>
            {/* Content */}
            <Paper style={{ alignItems: 'center !important' }} variant="outlined" className={classes.contentWrapper}>
              <Box mt={4}>
                <Box>
                  <ShareAfterPost postId={postId} onFinishClick={handleFinishClick} />
                </Box>
              </Box>
            </Paper>
          </Container>
        </>
      )}

      {/* Payment Dialog */}
      {appState.user && appState.currenciesConfig && (
        <CreateTierDialog
          open={createTierDialogOpen}
          athlete={appState.user}
          currency={getPayoutCurrency(appState.user, appState.currenciesConfig)}
          onTierCreated={handleTierCreated}
        />
      )}
      {/* Feature blocker */}
      <CreatePostBlockerDialog
        open={createPostBlockerDialogOpen}
        onConfigurePaymentsClick={handleCreatePostBlockerClick}
      />
    </BasicPageLayout>
  );
};

export default CreatePostPage;
