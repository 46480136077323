import React from 'react';
import { Box, Grid, Typography, makeStyles, useTheme } from '@material-ui/core';
import { letterSpacing } from '../../theme/theme';
import { Link } from 'react-router-dom';
import { useMediaQuery } from '@material-ui/core';
import domHelpers from '../../core/helpers/dom';
import config from '../../config';
import { useTranslation } from 'react-i18next';

const teamData = [
  {
    name: 'Michiel Wolters',
    position: 'Founder & CEO',
    hashTags:
      '#allaboutsports #tennis #innovation #creator #onamission #change #equalchances #passionate #teambuilder #founder',
    img: `${config.assetBaseUrl}/static/images/landing-header/Michiel_team.png`,
    link: 'https://www.linkedin.com/in/michiel-wolters/',
  },
  {
    name: 'Samuel Pelaez Perez',
    position: 'Founder & CCO',
    hashTags: '#nodreamsjustgoals #eyefortalent #sports #brandawarness #sales #fitness #letshelpathletes',
    img: `${config.assetBaseUrl}/static/images/landing-header/Samuel_team.jpg`,
    link: 'https://www.linkedin.com/in/samuelpelaezperez/',
  },
  {
    name: 'Arturo Canalda',
    position: 'Founder & CTO',
    hashTags:
      '#sportsaddict #architect #javascript #innovation #sportsclimbing #thereisalwaysaway #highquality #teamplayer',
    img: `${config.assetBaseUrl}/static/images/landing-header/Arturo_team.jpg`,
    link: 'https://www.linkedin.com/in/arturocanalda/',
  },
  {
    name: 'Santhie Krishnasing',
    position: 'Head of operations',
    hashTags: '#makeithappen #teamplayer #product #researchqueen #fighter #boxing #fairfunding',
    img: `${config.assetBaseUrl}/static/images/landing-header/Santhie_team.jpg`,
    link: 'https://www.linkedin.com/in/santhie-krishnasing-764a512/',
  },
  {
    name: 'Carlos Molero Mata',
    position: 'Developer',
    hashTags:
      '#developer #gamer #musician #react #javascript #runner #traveler #spain #enthusiastic #curious #psychologist #geek #front',
    img: `${config.assetBaseUrl}/static/images/landing-header/Carlos_team.jpg`,
    link: 'https://www.linkedin.com/in/carlos-molero-mata-8a12111a3/',
  },
  {
    name: 'Theo Hoeksma',
    position: 'Investor & Advisor',
    hashTags: '#serialentrepreneur #startupguru #founder #investor #teamadvisor #eyeforsuccess',
    img: `${config.assetBaseUrl}/static/images/landing-header/Theo_team.jpg`,
    link: 'https://www.linkedin.com/in/theohoeksema/',
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    backgroundColor: 'black',
    minHeight: '100vh',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  titleBox: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    '& h6': {
      marginTop: theme.typography.pxToRem(15),
    },
  },
  linkedinImgDesktop: {
    position: 'absolute',
    width: theme.typography.pxToRem(25),
    right: 0,
    marginLeft: theme.typography.pxToRem(5),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  linkedinImgMobile: {
    marginTop: theme.typography.pxToRem(12),
    width: theme.typography.pxToRem(25),
    [theme.breakpoints.up(769)]: {
      display: 'none',
    },
  },
  boxOnSm: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
  },
}));

const TeamBlock: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = domHelpers.isSmallScreen(useMediaQuery, theme);
  const isExtraSmallScreen = domHelpers.isXtraSmallScreen(useMediaQuery, theme);
  const [t] = useTranslation();

  //I've come to the point that having irregular queries in the code is inevitable
  //It will always depends on the particular component case ¯\_(ツ)_/¯
  const isSmallDesktop = useMediaQuery(theme.breakpoints.down(1442));

  return (
    <Box justifyContent="center" className={classes.root} p={isExtraSmallScreen ? 5 : 15}>
      <Box mb={3}>
        <Typography style={{ color: 'white' }} align="center" variant="h4">
          <b>{t('landing.about_peakz.team.title')}</b>
        </Typography>
      </Box>
      <Grid
        justifyContent={isSmallDesktop ? 'center' : undefined}
        style={{ marginTop: theme.typography.pxToRem(80) }}
        spacing={10}
        container
      >
        {teamData.map((data, index) => {
          return (
            <Grid
              style={
                !isSmallScreen
                  ? { minWidth: theme.typography.pxToRem(430) }
                  : { minWidth: theme.typography.pxToRem(250) }
              }
              key={index}
              md={4}
              item
            >
              <Box className={isSmallScreen ? classes.boxOnSm : ''} width={1} p={2}>
                <img style={{ borderRadius: '50%' }} src={data.img} width={isSmallScreen ? '170px' : '200px'} />
                <Box className={classes.titleBox}>
                  <Typography color="inherit" style={{ letterSpacing: letterSpacing.minimal }} variant="h6">
                    {data.name}
                  </Typography>
                  <Link rel="noopener" target="_blank" to={{ pathname: data.link }}>
                    <img className={classes.linkedinImgDesktop} src="/static/images/landing-header/linkedinicon.png" />
                  </Link>
                </Box>
                <Box>
                  <Typography color="inherit" style={{ letterSpacing: letterSpacing.minimal }}>
                    {data.position}
                  </Typography>{' '}
                  <Link rel="noopener" target="_blank" to={{ pathname: data.link }}>
                    <img className={classes.linkedinImgMobile} src="/static/images/landing-header/linkedinicon.png" />
                  </Link>
                </Box>
                <Box mt={2}>
                  <Typography variant="caption" style={{ letterSpacing: letterSpacing.minimal, color: 'white' }}>
                    {data.hashTags}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default TeamBlock;
