import { Grid } from '@material-ui/core';
import React, { useContext, useEffect } from 'react';

// import { getFBUserPages, getInstagramBusinessUserId } from '../../core/services/social/instagramSvc';
// import FacebookLogin from 'react-facebook-login';
// import { updateUser } from '../../core/services/userSvc';
import { appStore } from '../../core/stores/app';
// import config from '../../config';
// import globalStyles from '../../theme/globalStyles';

// const useStyles = makeStyles((theme) => ({
//   button: { ...globalStyles(theme).socialConnectButton, backgroundColor: '#3b5998', fontWeight: 'bold' },
// }));

const FacebookConnect: React.FC = () => {
  // const classes = useStyles();
  const [appState] = useContext(appStore);
  // const [userFacebookPages] = useState<any[]>();

  // const getPages = async (accessToken:string) => {
  //   try {
  //     const items = await getFBUserPages(accessToken);
  //     if (items) {
  //       const temp: any[] = [];
  //       items.map((item: any) => {
  //         const page = {
  //           name: item.name,
  //           id: item.id,
  //         };
  //         temp.push(page);
  //       });
  //       setUserFacebookPages(temp);
  //     }
  //   } catch (error) {
  //     appDispatch({
  //       type: 'showToast',
  //       toastTxt: 'There was a problem retrieving your available pages, try again later',
  //       toastSeverity: 'error',
  //     });
  //   }
  // };

  // const updateUserFacebookTokens = async (social: any) => {
  //   try {
  //     await updateUser(appState.user?.id || '', { social }, appDispatch, false);
  //   } catch (error) {
  //     console.error(error);
  //     appDispatch({
  //       type: 'showToast',
  //       toastTxt: 'Something unexpected happened while updating your information, please try again later',
  //       toastSeverity: 'error',
  //     });
  //   }
  // };

  // const handleFacebookLogin = async (response: any) => {
  //   const { userID, accessToken } = response;
  //   if (!userID || !accessToken) {
  //     appDispatch({
  //       type: 'showToast',
  //       toastTxt: 'Something went wrong during your social login, please try again later',
  //       toastSeverity: 'error',
  //     });
  //     return;
  //   }

  //   const social = {
  //     ...(appState.user?.social || {}),
  //     fbUserId: userID,
  //     fbAccessToken: accessToken,
  //   };
  //   updateUserFacebookTokens(social);
  //   // getPages(accessToken);
  // };

  // const handleRadioChange = async (event:any) => {
  //   const pageId = event.target.value;
  //   try {
  //     const instagramUserId = await getInstagramBusinessUserId(pageId, appState.user?.social?.fbAccessToken || '');
  //     const social = {
  //       ...(appState.user?.social || {}),
  //       fbTargetPageId: pageId,
  //       instagramUserId: instagramUserId,
  //     };
  //     updateUserFacebookTokens(social);
  //     appDispatch({
  //       type: 'showToast',
  //       toastTxt: 'Data saved successfully',
  //       toastSeverity: 'success',
  //     });
  //   } catch (error) {
  //     console.error(error);
  //     appDispatch({
  //       type: 'showToast',
  //       toastTxt:
  //         "We couldn't retrieve your instagram account. Remember that you must have a business account and link it to the desired facebook page",
  //       toastSeverity: 'warning',
  //     });
  //   }
  // };

  useEffect(() => {
    if (appState.user?.social?.fbAccessToken) {
      // getPages(appState.user?.social?.fbAccessToken);
    }
  }, []);

  return (
    <Grid container>
      {/* <Grid style={{ display: 'flex', justifyContent: 'flex-start' }} item md={12} xs={12}>
        <FacebookLogin
          cssClass={classes.button}
          appId={config.facebookAppId}
          callback={handleFacebookLogin}
          fields="name,email,picture"
          isMobile={false}
          scope="pages_show_list,instagram_content_publish,pages_read_engagement,instagram_basic"
        />
      </Grid>
      {userFacebookPages && (
        <Grid item md={12} xs={12}>
          <FormControl fullWidth>
            <Box mt={2}>
              <Typography variant="caption">
                Facebook requires to select a page linked to your instagram business account
              </Typography>
            </Box>
            <RadioGroup
              onChange={handleRadioChange}
              aria-label="facebook-page"
              defaultValue={appState.user?.social?.fbTargetPageId}
              name="facebook-pages-radio-group"
            >
              {userFacebookPages.map((page, index) => {
                return (
                  <FormControlLabel
                    style={{ fontSize: '10px' }}
                    key={index}
                    value={page.id}
                    control={<Radio color="primary" />}
                    label={page.name}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
        </Grid>
      )} */}
    </Grid>
  );
};

export default FacebookConnect;
