import { Box, FormHelperText, InputAdornment, makeStyles, Typography, useTheme } from '@material-ui/core';
import * as Yup from 'yup';
import { makeRequired, makeValidate } from 'mui-rff';
import React, { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { Form, FormSpy } from 'react-final-form';

import FormTextField from '../../components/ui/FormTextField';
import PaperForm from '../../components/ui/PaperForm';
import PaperFormElem from '../../components/ui/PaperFormElem';
import { User } from '../../core/graphql/types';
import { countryToFlag, micknameRegex, removeNullAttributes } from '../../core/helpers/misc';
import EditableAvatar, { AvatarUpdate } from '../../containers/EditableAvatar';
import FormAutocomplete from '../../components/ui/FormAutocomplete';
import { countries, sports, sportsObj } from '../../core/helpers/selectOptions';
import FormRow from '../../components/ui/FormRow';
import { getAthleteProfileCompletion, UserProfileMissingAttribs } from '../../core/helpers/user';
import FormDatePicker from '../../components/ui/FormDatePicker';
import { appStore } from '../../core/stores/app';

interface Props {
  user: User;
  nicknameUpdateError: boolean;
  onUserUpdate: any;
  submitting: boolean;
}

const minMissionLen = 100;
const maxMissionLen = 250;

const formSchema = Yup.object().shape({
  givenName: Yup.string().required('Name is a required field.'),
  familyName: Yup.string().required('Last name is a required field.'),
  gender: Yup.string(),
  birthdate: Yup.string()
    .test('len', 'This is not a valid date', (val) => {
      return val !== 'Invalid Date';
    })
    .nullable(true),
  country: Yup.string(),
  sport: Yup.string(),
  altSport: Yup.string().when('sport', {
    is: 'different',
    then: (fieldSchema: any) => fieldSchema.required('You have to specify your sport'),
  }),
  nickname: Yup.string()
    .ensure()
    .test('pattern', 'Wrong nickname', (val) => val.match(micknameRegex)),
  mission: Yup.string(),
  // .test('mission', `Your story has to be at least ${minMissionLen} characters. `, (val) => {
  //   return val && val.length >= minMissionLen;
  // })
  // .test('mission', `Your story can have max ${maxMissionLen} characters. `, (val) => {
  //   return val && val.length <= maxMissionLen;
  // })
});

const validate = makeValidate(formSchema);
const required = makeRequired(formSchema);

const useStyles = makeStyles((theme) => ({
  countryOption: {
    fontSize: 15,
    '& > span': {
      marginRight: theme.spacing(1),
      fontSize: 18,
    },
  },
}));

const today = new Date();
const formId = 'athlete-onboarding-form';

const UpgradeToAthleteForm: React.FC<Props> = ({ user, onUserUpdate, nicknameUpdateError, submitting }: Props) => {
  const [firstChange, setFirstChange] = useState(true);
  const [, appDispatch] = useContext(appStore);

  // Form statted fields
  const [familyName, setFamilyName] = useState(user?.familyName);
  const [givenName, setGivenName] = useState(user?.givenName);
  const [mission, setMission] = useState(user?.mission);
  const [altSportSelected, setAltSportSelected] = useState(false);
  const [nickname, setNickname] = useState(user?.nickname);

  const theme = useTheme();
  const classes = useStyles();
  const [accountMissingAttribs, setAccountMissingAttribs] = useState<UserProfileMissingAttribs>({});
  const subscription = { pristine: true, invalid: true };

  const initialValues = removeNullAttributes({
    profileImgS3Key: user.profileImgS3Key,
    givenName: givenName,
    familyName: familyName,
    birthdate: user.birthdate,
    //If sport is not listed
    altSport: sportsObj[user?.sport] === undefined ? user.sport : undefined,
    sport: user.sport && sportsObj[user?.sport] === undefined ? 'different' : user.sport,
    country: user.country,
    nickname: user.nickname,
    mission: mission,
  });

  //It's better to listen for changes directly on some elements
  const onProfileImageUpdate = (updateObj: AvatarUpdate) => {
    onUserUpdate({
      profileImgS3Key: updateObj.profileImgS3Key,
    });
  };

  const onSportsUpdate = (event: SyntheticEvent, option: any) => {
    if (option.value === 'different') {
      return;
    }
    setAltSportSelected(false);
    onUserUpdate({
      sport: option.value,
    });
  };

  const onCountryUpdate = (event: SyntheticEvent, option: any) => {
    onUserUpdate({
      country: option.value,
    });
  };

  //Input elements get automatically updated on the state
  const handleFormChange = ({ values }: any) => {
    if (firstChange) {
      setFirstChange(false);
    } else {
      setGivenName(values.givenName);
      setFamilyName(values.familyName);
      setMission(values.mission);
      setNickname(values.nickname);
      if (values.birthdate !== user.birthdate) {
        onUserUpdate({
          birthdate: values.birthdate,
        });
      }

      if (values.sport === 'different' && !altSportSelected) {
        setAltSportSelected(true);
      }
    }
  };

  const handleFormBlur = ({ target }: any) => {
    if (target.id !== 'birthdate' && target.id !== 'country' && target.id !== 'sport') {
      //Only send update on form blur
      const updateObj: any = {};
      if (givenName) {
        updateObj.givenName = givenName;
      }

      if (familyName) {
        updateObj.familyName = familyName;
      }

      if (mission && !(mission.length < minMissionLen || mission.length > maxMissionLen)) {
        updateObj.mission = mission;
      }

      if (target.id === 'altSport') {
        updateObj.sport = target.value;
      }

      updateObj.nickname = nickname;
      onUserUpdate(updateObj, Boolean(nickname !== user.nickname));
    }
  };

  useEffect(() => {
    const profileCompletion = getAthleteProfileCompletion(user);
    setAccountMissingAttribs(profileCompletion.missingAttribs);
  }, [user]);

  useEffect(() => {
    if (nicknameUpdateError) {
      appDispatch({
        type: 'showToast',
        toastTxt: "Nickname couldn't be updated, probably because it's already in use",
        toastSeverity: 'error',
      });
    }
  }, [nicknameUpdateError]);

  useEffect(() => {
    if (initialValues.altSport) {
      setAltSportSelected(true);
    }
  }, []);

  const renderGivenName = React.useMemo(() => {
    return (
      <PaperFormElem title="First name" missing={Boolean(accountMissingAttribs['givenName'])}>
        <FormTextField
          id="givenName"
          name="givenName"
          margin="none"
          required={required.givenName}
          disabled={submitting}
        />
      </PaperFormElem>
    );
  }, [givenName]);

  const renderFamilyName = React.useMemo(() => {
    return (
      <PaperFormElem title="Last name" missing={Boolean(accountMissingAttribs['familyName'])}>
        <FormTextField
          id="familyName"
          name="familyName"
          margin="none"
          required={required.familyName}
          disabled={submitting}
        />
      </PaperFormElem>
    );
  }, [familyName]);

  const renderMission = React.useMemo(() => {
    return (
      <PaperFormElem title="About me" required={required.mission} missing={Boolean(accountMissingAttribs['mission'])}>
        <FormTextField
          id="mission"
          name="mission"
          required={required.mission}
          disabled={submitting}
          margin="none"
          multiline={true}
          rows={8}
          InputProps={{
            endAdornment: (
              <Typography style={{ marginTop: theme.typography.pxToRem(140) }} variant="caption" color="textSecondary">
                {initialValues.mission ? `${initialValues.mission.length}/${maxMissionLen}` : ''}
              </Typography>
            ),
          }}
        />
      </PaperFormElem>
    );
  }, [mission]);

  const renderNickname = React.useMemo(() => {
    return (
      <PaperFormElem title="Nickname" required={required.nickname} missing={Boolean(accountMissingAttribs['nickname'])}>
        <FormTextField
          id="nickname"
          name="nickname"
          required={required.nickname}
          disabled={submitting}
          margin="none"
          InputProps={{
            startAdornment: <InputAdornment position="start">peakz.com/</InputAdornment>,
          }}
        />
        <FormHelperText>Your personal Peakz page</FormHelperText>
      </PaperFormElem>
    );
  }, [nickname]);

  const renderAltSport = React.useMemo(() => {
    if (altSportSelected) {
      return (
        <Box mt={2}>
          <FormRow label="What's your sport?">
            <FormTextField id="altSport" name="altSport" required={required.altSport} />
          </FormRow>
        </Box>
      );
    }
  }, [altSportSelected]);

  return (
    <Box>
      <Form
        onSubmit={() => {
          return undefined;
        }}
        initialValues={initialValues}
        subscription={subscription}
        validate={validate}
        key={subscription as any}
        render={({ handleSubmit }) => (
          <form
            id={formId}
            onSubmit={handleSubmit}
            onBlur={handleFormBlur}
            noValidate={true}
            autoComplete="new-password"
          >
            <FormSpy subscription={{ values: initialValues }} onChange={handleFormChange} />
            <PaperForm showDivider={false}>
              <PaperFormElem title="Profile picture" missing={Boolean(accountMissingAttribs['profileImgS3Key'])}>
                <Box width={1} display="flex" justifyContent="flex-start">
                  <EditableAvatar onUpdated={onProfileImageUpdate} user={user} />
                </Box>
              </PaperFormElem>
              {/* Given name */}
              {renderGivenName}
              {/* Family name */}
              {renderFamilyName}
              {/* About Me */}
              {renderMission}
              {/* Date of birth */}
              <PaperFormElem title="Date of birth" missing={Boolean(accountMissingAttribs['birthdate'])}>
                <FormDatePicker
                  id="birthdate"
                  name="birthdate"
                  margin="none"
                  maxDate={today}
                  initialFocusedDate={new Date(today.getFullYear() - 18, today.getMonth(), today.getDate())}
                  disabled={submitting}
                  required={required.birthdate}
                />
              </PaperFormElem>
              {/* Country */}
              <PaperFormElem title="Country" missing={Boolean(accountMissingAttribs['country'])}>
                <FormAutocomplete
                  id="country"
                  name="country"
                  margin="none"
                  required={required.country}
                  disabled={Boolean(submitting)}
                  options={countries}
                  onAutocompleteUpdate={onCountryUpdate}
                  getOptionValue={(option: any) => option.value}
                  getOptionLabel={(option: any) => option.label}
                  renderOption={(option: any) => (
                    <div className={classes.countryOption}>
                      <span>{countryToFlag(String(option.value))}</span>
                      {option.label}
                    </div>
                  )}
                />
              </PaperFormElem>
              {/* Sport */}
              <PaperFormElem title="Sport" missing={Boolean(accountMissingAttribs['sport'])}>
                <FormAutocomplete
                  id="sport"
                  name="sport"
                  margin="none"
                  onAutocompleteUpdate={onSportsUpdate}
                  required={required.sport}
                  disabled={Boolean(submitting)}
                  options={sports}
                  getOptionValue={(option: any) => option.value}
                  getOptionLabel={(option: any) => option.label}
                  renderOption={(option: any) => <div>{option.label}</div>}
                />
                {/* AltSport */}
                {renderAltSport}
              </PaperFormElem>
              {/* Nickname */}
              {renderNickname}
            </PaperForm>
          </form>
        )}
      />
    </Box>
  );
};

export default UpgradeToAthleteForm;
