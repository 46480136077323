import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { PeakzTheme } from '../../theme/theme';
import { makeStyles } from '@material-ui/styles';
import { Box, Container, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { CONTENTFUL_MODELS, fetchContentByType } from '../../core/services/contentfulSvc';
import { Blogs } from '../../core/contentful/types';
import domHelpers from '../../core/helpers/dom';
import SpinnerBox from '../ui/SpinnerBox';
import * as log from 'loglevel';
import WarningMessage from '../ui/WarningMessage';
import { getContentfulAssetURL, getContentfulItemID, getContentfulRandomArr } from '../../core/helpers/misc';
import HoverableContainer from './HoverableContainer';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const useStyles = makeStyles((theme: PeakzTheme) => ({
  boxRoot: {
    minHeight: theme.typography.pxToRem(350),
  },
  gridRoot: {
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      padding: theme.typography.pxToRem(80),
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.typography.pxToRem(0),
      paddingTop: theme.typography.pxToRem(32),
      paddingBottom: theme.typography.pxToRem(32),
    },
  },
  sideTitle: {
    position: 'relative',
    margin: theme.typography.pxToRem(5), //Mandatory to make the black container equally height, otherwise it will overflow, see margin on gridItem
  },
  gridItem: {
    position: 'relative',
    height: theme.typography.pxToRem(300),
    margin: theme.typography.pxToRem(5),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.typography.pxToRem(32),
    },
  },
}));

const NewBlogsLayout: React.FC = () => {
  const [hoveredState, setHoveredState] = useState(-1);
  const [blogs, setBlogs] = useState<Blogs[]>();
  const [fetchErr, setFetchErr] = useState<boolean>(false);
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = domHelpers.isSmallScreen(useMediaQuery, theme);
  const isTablet = useMediaQuery(theme.breakpoints.down(1025));

  useEffect(() => {
    (async () => {
      try {
        const resp = await fetchContentByType(CONTENTFUL_MODELS.BLOGS, 'withCat', false);
        const random = getContentfulRandomArr(resp.data.blogsCollection.items, 3);
        setBlogs(random);
      } catch (err) {
        log.error(err);
        setFetchErr(true);
      }
    })();
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyItems="center"
      className={classes.boxRoot}
      style={isTablet ? { paddingLeft: 0, paddingRight: 0 } : {}}
      p={isSmallScreen ? 0 : 10}
    >
      {!fetchErr && !blogs && <SpinnerBox marginTop={0} styles={{ height: '100%', width: '100%' }} size={100} />}
      {!fetchErr && blogs && (
        <Container>
          <Grid
            style={{ color: 'white', display: 'flex', justifyContent: 'center' }}
            container
            className={classes.gridRoot}
          >
            <Grid md={2} xs={12} className={classes.sideTitle} item>
              <Box
                style={!isSmallScreen ? { backgroundColor: 'black' } : { color: 'black' }}
                height={1}
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <Typography variant={isSmallScreen ? 'h4' : 'h5'} align="center">
                  <span style={isSmallScreen ? { fontWeight: 'bold' } : {}}>Blogs</span>
                </Typography>
              </Box>
            </Grid>
            {blogs?.map((data, index) => {
              return (
                <Grid
                  onMouseEnter={() => {
                    setHoveredState(index);
                  }}
                  onMouseLeave={() => {
                    setHoveredState(-1);
                  }}
                  className={classes.gridItem}
                  key={data.title}
                  md={3}
                  xs={12}
                  item
                >
                  <HoverableContainer
                    title={data.title.toUpperCase()}
                    description={data.shortDescription}
                    slug="READ ON PEAKZ"
                    img={getContentfulAssetURL(data, 'thumbnail', 25)}
                    hovered={hoveredState === index ? true : false}
                    link={`/blogs/${getContentfulItemID(data)}`}
                    buttonText="READ"
                    small={true}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Container>
      )}
      {fetchErr && (
        <WarningMessage
          icon={<ErrorOutlineIcon color="primary" />}
          text="There was a problem retrieving the blogs, please try again later..."
        />
      )}
    </Box>
  );
};

export default NewBlogsLayout;
