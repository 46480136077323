import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import Dialog from '@material-ui/core/Dialog/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';

import FormSubmitButton from '../ui/FormSubmitButton';
import DialogTitle from '../ui/DialogTitle';

interface Props {
  open: boolean;
  ageFragment: 'below_13' | 'below_18' | 'unknown';
  onNoClick: () => void;
  onYesClick: () => void;
  onClose: () => void;
}

const AgeBlockerDialog: React.FC<Props> = ({ open, ageFragment, onNoClick, onYesClick, onClose }: Props) => {
  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const handleClose = () => {
    onClose();
  };
  const handleNoClick = () => {
    onNoClick();
  };
  const handleYesClick = () => {
    onYesClick();
  };

  return (
    <Dialog open={open} fullScreen={!isBigScreen} maxWidth="xs" fullWidth={true} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title" onClose={handleClose}>
        Age control
      </DialogTitle>
      <DialogContent>
        {ageFragment === 'below_13' && (
          <>
            It seems you are younger than 13 years old. Please, contact us at{' '}
            <a href="mailto: support@peakz.com">support@peakz.com</a>
          </>
        )}
        {ageFragment === 'below_18' && (
          <>It seems you are younger than 18 years old. Do you have permission of your parents or legal guardians?</>
        )}
        {ageFragment === 'unknown' && (
          <>
            Are you 18 years or older or older than 13 years old with permission of your parents or legal guardian to
            make a payment to an athlete?
          </>
        )}
      </DialogContent>
      <DialogActions>
        {(ageFragment === 'below_18' || ageFragment === 'unknown') && (
          <DialogActions>
            <FormSubmitButton onClick={handleNoClick}>No</FormSubmitButton>
            <FormSubmitButton color="primary" onClick={handleYesClick}>
              yes
            </FormSubmitButton>
          </DialogActions>
        )}
        {ageFragment === 'below_13' && (
          <DialogActions>
            <FormSubmitButton color="primary" onClick={handleClose}>
              Ok
            </FormSubmitButton>
          </DialogActions>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AgeBlockerDialog;
