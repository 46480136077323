import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { v1 as uuidv1 } from 'uuid';

import { AthleteSponsor, User } from '../../core/graphql/types';
import ImageCroppingDialog from '../../containers/dialogs/ImageCroppingDialog';
import { uploadImgBase64 } from '../../core/services/imgSvc';
import urlHelpers from '../../core/helpers/url';
import { updateUser } from '../../core/services/userSvc';
import { authStore } from '../../core/stores/auth';
import ActionConfirmationDialog from '../../components/dialogs/ActionConfirmationDialog';
import FormSubmitButton from '../../components/ui/FormSubmitButton';
import SponsorCard from '../../components/elements/SponsorCard';
import { appStore } from '../../core/stores/app';

const useStyles = makeStyles(() => ({
  athleteSponsorsBlock: {
    width: '100%', // material-ui: +16px
    margin: 0, // materiail-ui: -8px
    height: 'fit-content',
  },
}));

interface Props {
  athlete?: User;
  editable: boolean;
  onUserUpdated: (update: { sponsors?: AthleteSponsor[] }) => void;
}

const AthleteSponsorsBlock: React.FC<Props> = ({ athlete, editable, onUserUpdated }: Props) => {
  const classes = useStyles();
  const [authState] = useContext(authStore);
  const [, appDispatch] = useContext(appStore);
  const [addSponsorDialogOpen, setAddSponsorDialogOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [deletingItemIndex, setDeletingItemIndex] = useState(-1);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const handleAddSponsorClick = () => {
    setAddSponsorDialogOpen(true);
  };
  const handleImageCropSubmit = async (imgBase64: string) => {
    if (athlete && authState.userInfo) {
      setSubmitting(true);
      const imgS3Key = await uploadImgBase64(
        urlHelpers.createUserSponsorImgS3Key(),
        authState.userInfo.userIdentityId,
        imgBase64,
        'protected',
      );

      const userUpdate = { sponsors: [...(athlete.sponsors || []), { id: uuidv1(), imgS3Key: imgS3Key }] };
      await updateUser(athlete.id, userUpdate, appDispatch);
      onUserUpdated(userUpdate);
      setSubmitting(false);
      setAddSponsorDialogOpen(false);
    }
  };
  const handleImageCropCancel = () => {
    setAddSponsorDialogOpen(false);
  };
  const handleDeleteClick = (index: number) => {
    setDeletingItemIndex(index);
    setConfirmationDialogOpen(true);
  };
  const handleDeleteConfirmation = async () => {
    setConfirmationDialogOpen(false);

    if (athlete && deletingItemIndex >= 0) {
      const newSponsors = [...athlete.sponsors];
      newSponsors.splice(deletingItemIndex, 1);
      const userUpdate = { sponsors: newSponsors };

      await updateUser(athlete.id, userUpdate, appDispatch);

      onUserUpdated(userUpdate);
    }
  };
  const handleDeleteCancel = () => {
    setConfirmationDialogOpen(false);
  };

  return (
    <div className={classes.athleteSponsorsBlock}>
      <Typography variant="h4" align="left">
        <b>My Sponsors</b>
      </Typography>
      <Box mt={2}>
        <Grid container spacing={2}>
          {athlete?.sponsors &&
            athlete.sponsors.map((sponsor, index) => (
              <Grid key={sponsor.id} item xs={6}>
                <SponsorCard
                  sponsor={sponsor}
                  editable={editable}
                  onDeleteClick={handleDeleteClick.bind(null, index)}
                />
              </Grid>
            ))}
          {(!athlete?.sponsors || athlete?.sponsors.length === 0) && (
            <Box m={1}>
              <Typography variant="body1">No sponsors as far as we know. Not yet :)</Typography>
            </Box>
          )}
        </Grid>
      </Box>
      {editable && (
        <Box display="flex" mt={3} justifyContent="center">
          <FormSubmitButton
            color="primary"
            endIcon={<AddCircleOutlineIcon fontSize="large" />}
            onClick={handleAddSponsorClick}
          >
            Add sponsor
          </FormSubmitButton>
        </Box>
      )}

      {/* Add Sponsor Dialog */}
      {addSponsorDialogOpen && (
        <ImageCroppingDialog
          title="Sponsor logo upload"
          open={addSponsorDialogOpen}
          imgUrl={''}
          aspect={6 / 3}
          submitting={submitting}
          onSubmit={handleImageCropSubmit}
          onCancel={handleImageCropCancel}
          onClose={handleImageCropCancel}
        ></ImageCroppingDialog>
      )}

      {/* Delete Achievement or Goal */}
      {confirmationDialogOpen && (
        <ActionConfirmationDialog
          open={confirmationDialogOpen}
          title={''}
          description={'Are you sure you want to delete the entry?'}
          onYesClick={handleDeleteConfirmation}
          onNoClick={handleDeleteCancel}
          onClose={handleDeleteCancel}
        />
      )}
    </div>
  );
};

export default AthleteSponsorsBlock;
