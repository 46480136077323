import React from 'react';
import { Box, Typography } from '@material-ui/core';
import domHelpers from '../../core/helpers/dom';
import { useMediaQuery, useTheme } from '@material-ui/core';

interface Props {
  text: string;
  icon: JSX.Element;
  type?: string;
  wordbreak?: any;
}

const WarningMessage: React.FC<Props> = ({ wordbreak = 'normal', text, icon, type }: Props) => {
  const theme = useTheme();
  const isSmallScreen = domHelpers.isSmallScreen(useMediaQuery, theme);

  return (
    <Box
      p={isSmallScreen ? 5 : 10}
      display="flex"
      style={type === 'field' ? { padding: '0' } : {}}
      flexDirection={type === 'field' ? 'row' : 'column'}
      alignItems="center"
      alignContent="center"
    >
      <Box p={type === 'field' ? 0 : 2} pr={type === 'field' ? 1 : 0}>
        {icon}
      </Box>
      <Typography
        style={{ wordBreak: wordbreak }}
        align={isSmallScreen ? 'left' : 'center'}
        variant={type === 'field' ? 'body2' : 'h5'}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default WarningMessage;
