/*
 * Using mui-rff
 * Check following link for more info: https://github.com/lookfirst/mui-rff
 * */

import React, { useState, useContext, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import Dialog from '@material-ui/core/Dialog/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useTranslation } from 'react-i18next';

import DialogTitle from '../../components/ui/DialogTitle';
import { updateUser } from '../../core/services/userSvc';
import { appStore } from '../../core/stores/app';
import { Currency } from '../../core/graphql/types';

const useStyles = makeStyles((theme) => ({
  currencyBox: {
    cursor: 'pointer',
  },
  currencyBoxSelected: {
    borderColor: theme.palette.primary.main,
  },
}));

interface CurrencyBoxProps {
  name: string;
  symbol: string;
  selected: boolean;
  code: string;
  onClick: (currencyObj: any) => void;
}

const CurrencyBox: React.FC<CurrencyBoxProps> = ({ name, symbol, code, selected, onClick }: CurrencyBoxProps) => {
  const classes = useStyles();

  return (
    <Paper
      className={clsx({
        [classes.currencyBox]: true,
        [classes.currencyBoxSelected]: selected,
      })}
      variant="outlined"
      onClick={onClick.bind(null, code)}
    >
      <Box p={1}>
        <Typography variant="body1">
          <b>{name}</b>
        </Typography>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="body1">{symbol}</Typography>
          </Grid>
          <Grid item>
            <CheckCircleIcon fontSize="small" style={{ display: selected ? 'block' : 'none' }} />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

interface ChangeCurrencyDialogProps {
  open: boolean;
  initCurrency: string;
  onSubmitted: (currency: string) => void;
  onCancel: () => void;
}

const ChangeCurrencyDialog: React.FC<ChangeCurrencyDialogProps> = ({
  open,
  initCurrency,
  onSubmitted,
  onCancel,
}: ChangeCurrencyDialogProps) => {
  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const [appState, appDispatch] = useContext(appStore);
  const [t] = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const [paymentCurrencies, setPaymentCurrencies] = useState<Currency[]>([]);
  const [selectedCurrency, setSelectedCurrency] = useState<string | undefined>();
  const handleSubmitClick = async () => {
    if (selectedCurrency) {
      if (appState.user) {
        setSubmitting(true);
        await updateUser(appState.user.id, { currency: selectedCurrency }, appDispatch);
        setSubmitting(false);
      }
      appDispatch({
        type: 'setUserCurrencyConfig',
        userCurrencyConfig: { currencyCode: selectedCurrency, userDefined: true },
      });
      onSubmitted(selectedCurrency);
    }
  };
  const handleCancelClick = () => {
    onCancel();
  };
  const handleCurrencyClick = (currency: string) => {
    setSelectedCurrency(currency);
  };

  useEffect(() => {
    if (appState.currenciesConfig) {
      setPaymentCurrencies(appState.currenciesConfig.currencies);
      setSelectedCurrency(
        appState.currenciesConfig.currencies.find((c) => c.code === initCurrency) ? initCurrency : '',
      );
    }
  }, [appState.currenciesConfig]);

  return (
    <Dialog open={open} maxWidth="xs" fullWidth={true} fullScreen={!isBigScreen} aria-labelledby="form-dialog-name">
      <DialogTitle id="form-dialog-name" onClose={handleCancelClick}>
        Choose your currency
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {paymentCurrencies.map((currencyObj: any) => (
            <Grid key={currencyObj.code} item xs={6}>
              <CurrencyBox
                name={t(`currencies.${currencyObj.code}`)}
                symbol={currencyObj.symbol}
                code={currencyObj.code}
                selected={selectedCurrency === currencyObj.code}
                onClick={handleCurrencyClick}
              ></CurrencyBox>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancelClick}>Cancel</Button>
        <Button
          color="primary"
          disabled={!selectedCurrency}
          endIcon={submitting ? <CircularProgress size={24} /> : ''}
          onClick={handleSubmitClick}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeCurrencyDialog;
