import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
  activeFiltersRoot: {},
  filterChip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  clearAllBtn: {
    textTransform: 'none',
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

interface Props {
  activeFiltersList: { group: string; key: string; label: string }[];
  onDeleteClick: (filterGroup: string, filterKey: string) => void;
  onClearAllClick: () => void;
}

const ActiveFiltersList: React.FC<Props> = ({ activeFiltersList, onDeleteClick, onClearAllClick }: Props) => {
  const classes = useStyles();
  const handleDeleteClick = (filterGroup: string, filterKey: string) => {
    onDeleteClick(filterGroup, filterKey);
  };
  const handleClearAllClick = () => {
    onClearAllClick();
  };

  return (
    <Box className={classes.activeFiltersRoot} display="flex" flexWrap="wrap">
      {activeFiltersList.length > 0 && (
        <Box pb={1} width={1}>
          <Divider />
        </Box>
      )}
      {activeFiltersList.map((af, index) => (
        <Chip
          className={classes.filterChip}
          key={index}
          variant="outlined"
          size="small"
          label={af.label}
          clickable
          color="primary"
          onDelete={handleDeleteClick.bind(null, af.group, af.key)}
        />
      ))}
      {activeFiltersList.length > 0 && (
        <>
          <Box display="flex" justifyContent="flex-end" width={1}>
            <Button className={classes.clearAllBtn} color="primary" size="small" onClick={handleClearAllClick}>
              clear filters
            </Button>
          </Box>
          <Box pb={1} width={1}>
            <Divider />
          </Box>
        </>
      )}
    </Box>
  );
};

export default ActiveFiltersList;
