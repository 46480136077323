import React, { useState, useEffect } from 'react';
import { makeIcsFile } from './utils/ics';
import { useMeetingSession } from './providers/MeetingSessionProvider';
import { formStyle } from './components/FormStyles';
import Config from './utils/config';

const ReminderForm: React.FC = () => {
  const { bcastRecord } = useMeetingSession();
  const [icsUrl, setIcsUrl] = useState('');

  useEffect(() => {
    // create url for .ics download
    if (bcastRecord) {
      const file = makeIcsFile(
        bcastRecord.id,
        bcastRecord.time,
        bcastRecord.duration,
        bcastRecord.title,
        bcastRecord.description,
        window.location.href,
        Config.BCAST_REMINDER_INTERVAL,
      );
      const url = window.URL.createObjectURL(new Blob([file]));
      setIcsUrl(url);
    }
  }, []);

  return (
    <>
      {bcastRecord && (
        <div style={formStyle}>
          <div style={{ textAlign: 'center' }}>
            <h3 style={{ color: 'orange' }}>{bcastRecord.title}</h3>
            <p style={{ padding: '1rem' }}>
              Broadcast is scheduled for <br />
              <b>{bcastRecord.time}</b>
            </p>
          </div>
          <p style={{ textAlign: 'left' }}>To create a reminder in your calendar:</p>
          <ol type="1" style={{ textAlign: 'left', paddingLeft: '1rem' }}>
            <li>
              Click{' '}
              <a
                href={icsUrl}
                rel="noreferrer"
                target="_blank"
                download={bcastRecord.title + '.ics'}
                style={{ color: 'orange' }}
              >
                here
              </a>{' '}
              to download .ics file
            </li>
            <li>Open the file to add calendar event</li>
          </ol>
        </div>
      )}
    </>
  );
};

export default ReminderForm;
