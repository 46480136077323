import { API } from 'aws-amplify';

import { GRAPHQL_AUTH_MODE, Post, SubscriptionEvent } from '../graphql/types';
import {
  getPost,
  getFullPost,
  getPostVodToken,
  listPostsByAuthor,
  getAthleteSubscription,
  listSubscriptionsBySubscriber,
  searchPosts as searchPostsQry,
  listPostsByFeedId,
} from '../graphql/customQueries';
import { createPost as createPostMut, deletePost } from '../graphql/customMutations';
import { onUpdatePostById } from '../graphql/customSubscriptions';
import {
  SearchablePostFilterInput,
  SearchablePostSortableFields,
  SearchableSortDirection,
  SearchableUserSortableFields,
} from '../../API';

export const fetchPostById = async (postId: string) => {
  const postRes: any = await API.graphql({
    query: getPost,
    variables: {
      id: postId,
    },
    authMode: GRAPHQL_AUTH_MODE.API_KEY,
  });
  const post = postRes.data.getPost;

  return post || null;
};

export const fetchFullPostById = async (postId: string, useCognitoAuth?: boolean) => {
  const postRes: any = await API.graphql({
    query: getFullPost,
    variables: {
      postId: postId,
    },
    authMode: useCognitoAuth ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS : GRAPHQL_AUTH_MODE.API_KEY,
  });
  const post = postRes.data.getFullPost;

  return post || null;
};

export const fetchPostVodToken = async (postId: string, useCognitoAuth?: boolean) => {
  const postRes: any = await API.graphql({
    query: getPostVodToken,
    variables: {
      postId: postId,
    },
    authMode: useCognitoAuth ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS : GRAPHQL_AUTH_MODE.API_KEY,
  });
  const post = postRes.data.getFullPost;

  return post || null;
};

export const fetchPostsByAuthor = async (
  authorId: string,
  pageSize?: number,
  nextToken?: string,
  useCognitoAuth?: boolean,
) => {
  const result: any = await API.graphql({
    query: listPostsByAuthor,
    variables: {
      authorId: authorId,
      limit: pageSize || 16,
      sortDirection: 'DESC',
      nextToken: nextToken || null,
    },
    authMode: useCognitoAuth ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS : GRAPHQL_AUTH_MODE.API_KEY,
  });

  if (result.data.listPostsByAuthor) {
    return {
      posts: result.data.listPostsByAuthor.items,
      nextToken: result.data.listPostsByAuthor.nextToken,
    };
  }

  return null;
};

export const fetchAthleteSubscription = async (subscriberId: string, athleteId: string) => {
  const result: any = await API.graphql({
    query: getAthleteSubscription,
    variables: {
      subscriberId,
      athleteId,
    },
    authMode: GRAPHQL_AUTH_MODE.API_KEY,
  });

  if (result.data.getAthleteSubscription) {
    return result.data.getAthleteSubscription;
  }

  return null;
};

export const fetchAthleteSubscriptionsBySubscriber = async (subscriberId: string, limit = 10) => {
  const result: any = await API.graphql({
    query: listSubscriptionsBySubscriber,
    variables: {
      subscriberId,
      sortDirection: 'DESC',
      limit: limit,
    },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  });

  if (result.data.listSubscriptionsBySubscriber) {
    return {
      athleteSubscriptions: result.data.listSubscriptionsBySubscriber.items,
      nextToken: result.data.listSubscriptionsBySubscriber.nextToken,
    };
  }

  return null;
};

export const searchPosts = async (
  filter: SearchablePostFilterInput,
  pageSize?: number,
  nextToken?: string,
  sortField?: SearchablePostSortableFields,
  sortDirection?: SearchableSortDirection,
  useCognitoAuth?: boolean,
): Promise<{ posts: Post[]; nextToken?: string; total: number } | null> => {
  const res: any = await API.graphql({
    query: searchPostsQry,
    variables: {
      filter: filter,
      limit: pageSize || 16,
      nextToken: nextToken || null,
      sort: {
        field: sortField || SearchableUserSortableFields.name,
        direction: sortDirection || SearchableSortDirection.asc,
      },
    },
    authMode: useCognitoAuth ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS : GRAPHQL_AUTH_MODE.API_KEY,
  });

  if (res.data.searchPosts) {
    return {
      posts: res.data.searchPosts.items,
      nextToken: res.data.searchPosts.nextToken,
      total: res.data.searchPosts.total?.value,
    };
  }

  return null;
};

export const fetchPostsByFeedId = async (
  feedId: string,
  pageSize?: number,
  nextToken?: string,
  useCognitoAuth?: boolean,
) => {
  const result: any = await API.graphql({
    query: listPostsByFeedId,
    variables: {
      feedId: feedId,
      sortDirection: 'DESC',
      limit: pageSize || 16,
      nextToken: nextToken || null,
    },
    authMode: useCognitoAuth ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS : GRAPHQL_AUTH_MODE.API_KEY,
  });

  if (result.data.listPostsByFeedId) {
    return {
      posts: result.data.listPostsByFeedId.items,
      nextToken: result.data.listPostsByFeedId.nextToken,
    };
  }

  return null;
};

export const createPost = async (postData: any): Promise<Post | null> => {
  const result: any = await API.graphql({
    query: createPostMut,
    variables: {
      input: postData,
    },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  });

  if (result.data.createPost) {
    return result.data.createPost;
  }

  return null;
};

export const deletePostById = async (postId: string) => {
  await API.graphql({
    query: deletePost,
    variables: {
      input: {
        id: postId,
      },
    },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  });
};

export const subscribeToUpdatePost = (
  postId: string,
  next: (evt: SubscriptionEvent<{ onUpdatePostById: Post }>) => void,
) => {
  const subscription = (
    API.graphql({
      query: onUpdatePostById,
      variables: {
        id: postId,
      },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    }) as any
  ).subscribe({
    next: next,
  });

  return subscription;
};

export default {
  // get
  fetchPostById,
  fetchPostsByAuthor,
  fetchAthleteSubscription,
  fetchAthleteSubscriptionsBySubscriber,
  // post
  createPost,
  deletePostById,
  // subscriptions
  subscribeToUpdatePost,
};
