import React, { useContext, useState, useEffect } from 'react';
import { makeRequired, makeValidate } from 'mui-rff';
import * as Yup from 'yup';
import { Form, FormSpy } from 'react-final-form';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { v1 as uuidv1 } from 'uuid';

import PaperFormElem from '../../components/ui/PaperFormElem';
import PaperForm from '../../components/ui/PaperForm';
import SubmitButton from '../../components/ui/SubmitButton';
import PaperFormActions from '../../components/ui/PaperFormActions';
import FormTextField from '../../components/ui/FormTextField';
import { removeNullAttributes } from '../../core/helpers/misc';
import { AthleteAchievement, AthleteGoal, AthleteSponsor, User } from '../../core/graphql/types';
import { updateUser } from '../../core/services/userSvc';
import { sports, sportsObj } from '../../core/helpers/selectOptions';
import FormAutocomplete from '../../components/ui/FormAutocomplete';
import FormRow from '../../components/ui/FormRow';
import { appStore } from '../../core/stores/app';
import GoalAchievementDialog, { AchievementGoalDialogData } from '../../containers/dialogs/GoalAchievementDialog';
import GoalAchievementCard from '../athleteProfilePage/GoalAchievementCard';
import ImageCroppingDialog from '../../containers/dialogs/ImageCroppingDialog';
import { uploadImgBase64 } from '../../core/services/imgSvc';
import urlHelpers from '../../core/helpers/url';
import SponsorCard from '../../components/elements/SponsorCard';
import { authStore } from '../../core/stores/auth';
import SpinnerBox from '../../components/ui/SpinnerBox';
import { getAthleteProfileCompletion, UserProfileMissingAttribs } from '../../core/helpers/user';

const formSchema = Yup.object().shape({
  sport: Yup.string(),
  altSport: Yup.string().when('sport', {
    is: 'different',
    then: (fieldSchema: any) => fieldSchema.required('You have to specify your sport'),
  }),
});

const validate = makeValidate(formSchema);
const required = makeRequired(formSchema);

export interface SportsSectionFormData {
  sport?: string;
  altSport?: string;
}

export interface SportsSectionUpdate extends SportsSectionFormData {
  profileImgS3Key?: string;
}

interface Props {
  user: User;
  onUserUpdated?: (update: SportsSectionUpdate) => void;
}

const SportsSection: React.FC<Props> = ({ user, onUserUpdated }: Props) => {
  const [authState] = useContext(authStore);
  const [, appDispatch] = useContext(appStore);
  const subscription = { pristine: true, invalid: true };
  const [submitting, setSubmitting] = useState(false);
  const [altSportSelected, setAltSportSelected] = useState(false);
  const [selectedGoalAchievement, setSelectedGoalAchievement] = useState<
    AthleteAchievement | AthleteGoal | undefined
  >();
  const [goalAchievementDialogOpen, setGoalAchievementDialogOpen] = useState(false);
  const [addSponsorDialogOpen, setAddSponsorDialogOpen] = useState(false);
  const [achievements, setAchievements] = useState<AthleteAchievement[]>(user.achievements || []);
  const [goals, setGoals] = useState<AthleteGoal[]>(user.goals || []);
  const [sponsors, setSponsors] = useState<AthleteSponsor[]>(user.sponsors || []);
  const [goalAchievementEditType, setGoalAchievementEditType] = useState<'goal' | 'achievement'>('achievement');
  const [arraysChanged, setArraysChanged] = useState(false);
  const [uploadingSponsorImg, setUploadingSponsorImg] = useState(false);
  const [accountMissingAttribs, setAccountMissingAttribs] = useState<UserProfileMissingAttribs>({});
  const initialValues = removeNullAttributes({
    sport: user?.sport,
  });
  const formId = 'account-sports-form';
  const submitUpdate = async (updateObj: SportsSectionUpdate) => {
    if (user) {
      setSubmitting(true);
      await updateUser(user.id, updateObj, appDispatch);
      setSubmitting(false);

      if (onUserUpdated) {
        onUserUpdated(updateObj);
      }
    }
  };
  const handleFormChange = ({ values }: any) => {
    setTimeout(() => {
      const isAltSport = values.sport === 'different';

      setAltSportSelected(isAltSport);
    }, 0);
  };
  const handleSubmitClick = async (values: SportsSectionFormData) => {
    // Prepare the update object
    const { altSport, ...updateObj } = { ...values, goals: goals, achievements: achievements, sponsors: sponsors };

    if (updateObj.sport === 'different') {
      if (altSport) {
        updateObj.sport = altSport;
      }
    }

    // Submit the update
    await submitUpdate(updateObj);

    setArraysChanged(false);
  };
  const handleCancelClick = (form: any) => {
    form.reset();
    setAchievements(user.achievements || []);
    setGoals(user.goals || []);
    setArraysChanged(false);
  };
  const handleAddGoalAchievementClick = (editType: 'goal' | 'achievement') => {
    setGoalAchievementEditType(editType);
    setSelectedGoalAchievement(undefined);
    setGoalAchievementDialogOpen(true);
  };
  const goalAchievementSubmit = (setArr: any, obj: AchievementGoalDialogData) => {
    // New object added
    if (!obj.id) {
      obj.id = uuidv1();
      setArr((arr: any) => [...arr, obj]);
    } else {
      // Object has been edited
      setArr((arr: any) => {
        const index = arr.findIndex((a: any) => a.id === obj.id);

        if (index >= 0) {
          arr[index] = { ...arr[index], ...obj };
        }

        return arr;
      });
    }

    setGoalAchievementDialogOpen(false);
    setArraysChanged(true);
  };
  const goalAchievementEdit = (arr: AchievementGoalDialogData[], index: number) => {
    const obj = arr[index];

    if (obj) {
      setSelectedGoalAchievement(obj);
      setGoalAchievementDialogOpen(true);
      setArraysChanged(true);
    }
  };
  const goalAchievementUp = (setArr: any, index: number) => {
    setArr((arr: any) => {
      const arrCpy = [...arr];
      const obj = arrCpy[index];

      arrCpy[index] = arrCpy[index - 1];
      arrCpy[index - 1] = obj;

      return arrCpy;
    });
    setArraysChanged(true);
  };
  const goalAchievementDown = (setArr: any, index: number) => {
    setArr((arr: any) => {
      const arrCpy = [...arr];
      const obj = arrCpy[index];

      arrCpy[index] = arrCpy[index + 1];
      arrCpy[index + 1] = obj;

      return arrCpy;
    });
    setArraysChanged(true);
  };
  const goalAchievementDelete = (setArr: any, index: number) => {
    setArr((arr: any) => {
      const arrCpy = [...arr];
      arrCpy.splice(index, 1);

      return arrCpy;
    });
    setArraysChanged(true);
  };
  const handleGoalAchievementSubmit = (obj: AchievementGoalDialogData) => {
    goalAchievementSubmit(goalAchievementEditType === 'goal' ? setGoals : setAchievements, obj);
  };
  const handleGoalAchievementCancel = () => {
    setGoalAchievementDialogOpen(false);
  };
  const handleGoalAchievementEditClick = (editType: 'goal' | 'achievement', index: number) => {
    goalAchievementEdit(editType === 'goal' ? goals : achievements, index);
  };
  const handleGoalAchievementUpClick = (editType: 'goal' | 'achievement', index: number) => {
    goalAchievementUp(editType === 'goal' ? setGoals : setAchievements, index);
  };
  const handleGoalAchievementDownClick = (editType: 'goal' | 'achievement', index: number) => {
    goalAchievementDown(editType === 'goal' ? setGoals : setAchievements, index);
  };
  const handleGoalAchievementDeleteClick = (editType: 'goal' | 'achievement', index: number) => {
    goalAchievementDelete(editType === 'goal' ? setGoals : setAchievements, index);
  };
  const handleAddSponsorClick = () => {
    setAddSponsorDialogOpen(true);
  };
  const handleDeleteSponsorClick = (index: number) => {
    setSponsors((arr: any) => {
      const arrCpy = [...arr];
      arrCpy.splice(index, 1);

      return arrCpy;
    });
    setArraysChanged(true);
  };
  const handleAddSponsorSubmit = async (imgBase64: string) => {
    setAddSponsorDialogOpen(false);
    if (authState.userInfo) {
      setUploadingSponsorImg(true);
      const imgS3Key = await uploadImgBase64(
        urlHelpers.createUserSponsorImgS3Key(),
        authState.userInfo.userIdentityId,
        imgBase64,
        'protected',
      );

      setUploadingSponsorImg(false);
      setSponsors((arr) => [...arr, { id: uuidv1(), imgS3Key: imgS3Key }]);
      setArraysChanged(true);
    }
  };
  const handleAddSponsorCancel = () => {
    setAddSponsorDialogOpen(false);
  };

  // If the sport is not listed
  if (initialValues.sport && !sportsObj[initialValues.sport]) {
    initialValues.altSport = initialValues.sport;
    initialValues.sport = 'different';
  }

  useEffect(() => {
    const profileCompletion = getAthleteProfileCompletion(user);

    setAccountMissingAttribs(profileCompletion.missingAttribs);
  }, [user]);

  return (
    <>
      <Form
        onSubmit={handleSubmitClick}
        initialValues={initialValues}
        subscription={subscription}
        validate={validate}
        key={subscription as any}
        render={({ form, handleSubmit, pristine, invalid }) => (
          <form id={formId} onSubmit={handleSubmit} noValidate={true} autoComplete="new-password">
            <FormSpy subscription={{ values: true }} onChange={handleFormChange} />
            <PaperForm
              actions={
                <PaperFormActions>
                  <Grid container spacing={1} justifyContent="flex-end">
                    <Grid item>
                      <SubmitButton
                        disabled={(invalid || submitting || pristine) && !arraysChanged}
                        submitting={false}
                        color="default"
                        type="button" // to avoid the form being submitted
                        onClick={handleCancelClick.bind(null, form)}
                      >
                        Cancel
                      </SubmitButton>
                    </Grid>
                    <Grid item>
                      <SubmitButton
                        disabled={(invalid || submitting || pristine) && !arraysChanged}
                        submitting={submitting}
                      >
                        Save changes
                      </SubmitButton>
                    </Grid>
                  </Grid>
                </PaperFormActions>
              }
            >
              {/* Sport */}
              <PaperFormElem title="Sport" missing={Boolean(accountMissingAttribs['sport'])}>
                <FormAutocomplete
                  id="sport"
                  name="sport"
                  margin="none"
                  required={required.sport}
                  disabled={Boolean(submitting)}
                  options={sports}
                  getOptionValue={(option: any) => option.value}
                  getOptionLabel={(option: any) => option.label}
                  renderOption={(option: any) => <div>{option.label}</div>}
                />
                {/* AltSport */}
                {altSportSelected && (
                  <Box mt={2}>
                    <FormRow label="What's your sport?">
                      <FormTextField id="altSport" name="altSport" required={required.altSport} />
                    </FormRow>
                  </Box>
                )}
              </PaperFormElem>
              {/* Achievements */}
              <PaperFormElem title="Achievements" missing={Boolean(accountMissingAttribs['achievements'])}>
                {/* Achievements */}
                <Grid container spacing={2}>
                  {achievements.map((achievement, i) => (
                    <Grid key={i} item xs={12}>
                      <GoalAchievementCard
                        item={achievement}
                        editable={true}
                        hideArrows={achievements.length === 1}
                        isFirstItem={i === 0}
                        isLastItem={i === achievements.length - 1}
                        onEditClick={handleGoalAchievementEditClick.bind(null, 'achievement', i)}
                        onMoveUpClick={handleGoalAchievementUpClick.bind(null, 'achievement', i)}
                        onMoveDownClick={handleGoalAchievementDownClick.bind(null, 'achievement', i)}
                        onDeleteClick={handleGoalAchievementDeleteClick.bind(null, 'achievement', i)}
                      />
                    </Grid>
                  ))}
                </Grid>
                {/* Add achievement button */}
                <Box width={1} display="flex" justifyContent="flex-start" mt={achievements.length > 0 ? 2 : 0}>
                  <Button
                    color="primary"
                    endIcon={<AddCircleOutlineIcon fontSize="large" />}
                    onClick={handleAddGoalAchievementClick.bind(null, 'achievement')}
                  >
                    Add your achievement
                  </Button>
                </Box>
              </PaperFormElem>
              {/* Goals */}
              <PaperFormElem title="Goals" missing={Boolean(accountMissingAttribs['goals'])}>
                {/* Goals */}
                <Grid container spacing={2}>
                  {goals.map((goal, i) => (
                    <Grid key={i} item xs={12}>
                      <GoalAchievementCard
                        item={goal}
                        editable={true}
                        hideArrows={goals.length === 1}
                        isFirstItem={i === 0}
                        isLastItem={i === goals.length - 1}
                        onEditClick={handleGoalAchievementEditClick.bind(null, 'goal', i)}
                        onMoveUpClick={handleGoalAchievementUpClick.bind(null, 'goal', i)}
                        onMoveDownClick={handleGoalAchievementDownClick.bind(null, 'goal', i)}
                        onDeleteClick={handleGoalAchievementDeleteClick.bind(null, 'goal', i)}
                      />
                    </Grid>
                  ))}
                </Grid>
                {/* Add goals button */}
                <Box width={1} display="flex" justifyContent="flex-start" mt={achievements.length > 0 ? 2 : 0}>
                  <Button
                    color="primary"
                    endIcon={<AddCircleOutlineIcon fontSize="large" />}
                    onClick={handleAddGoalAchievementClick.bind(null, 'goal')}
                  >
                    Add your goal
                  </Button>
                </Box>
              </PaperFormElem>
              {/* Sponsors */}
              <PaperFormElem title="Sponsors" missing={Boolean(accountMissingAttribs['sponsors'])}>
                {/* Sponsors */}
                <Grid container spacing={2}>
                  {sponsors.map((sponsor, index) => (
                    <Grid key={sponsor.id} item xs={6}>
                      <SponsorCard
                        sponsor={sponsor}
                        editable={true}
                        onDeleteClick={handleDeleteSponsorClick.bind(null, index)}
                      />
                    </Grid>
                  ))}
                  {uploadingSponsorImg && (
                    <Grid item xs={6}>
                      <SpinnerBox />
                    </Grid>
                  )}
                </Grid>
                {/* Add sponsors button */}
                <Box width={1} display="flex" justifyContent="flex-start" mt={sponsors.length > 0 ? 2 : 0}>
                  <Button
                    color="primary"
                    endIcon={<AddCircleOutlineIcon fontSize="large" />}
                    onClick={handleAddSponsorClick}
                  >
                    Add your Sponsor
                  </Button>
                </Box>
              </PaperFormElem>
            </PaperForm>
          </form>
        )}
      />

      {/* Achievement/Goal Dialog */}
      {goalAchievementDialogOpen && (
        <GoalAchievementDialog
          item={selectedGoalAchievement}
          itemType={goalAchievementEditType}
          open={goalAchievementDialogOpen}
          submitting={submitting}
          onSubmit={handleGoalAchievementSubmit}
          onCancel={handleGoalAchievementCancel}
        ></GoalAchievementDialog>
      )}

      {/* Add Sponsor Dialog */}
      {addSponsorDialogOpen && (
        <ImageCroppingDialog
          title="Sponsor logo upload"
          open={addSponsorDialogOpen}
          imgUrl={''}
          aspect={6 / 3}
          submitting={submitting}
          onSubmit={handleAddSponsorSubmit}
          onCancel={handleAddSponsorCancel}
          onClose={handleAddSponsorCancel}
        ></ImageCroppingDialog>
      )}
    </>
  );
};

export default SportsSection;
