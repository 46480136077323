import { API } from 'aws-amplify';

import { GRAPHQL_AUTH_MODE } from '../graphql/types';
import { createFlashRequest } from '../graphql/customMutations';
import { getFlashRequest } from '../graphql/customQueries';

export const fetchFlashRequest = async (userId: string, request: 'feature', targetId: string) => {
  const res: any = await API.graphql({
    query: getFlashRequest,
    variables: {
      userId: userId,
      request: 'feature',
      targetId: targetId,
    },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  });

  return res.data.getFlashRequest;
};

export const requestFeature = async (userId: string, featureName: 'posts') => {
  const res: any = await API.graphql({
    query: createFlashRequest,
    variables: {
      input: {
        userId: userId,
        request: 'feature',
        targetId: featureName,
      },
    },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  });

  return Boolean(res.createFlashRequest);
};
export const reportPost = async (userId: string, postId: string, reason: string) => {
  const res: any = await API.graphql({
    query: createFlashRequest,
    variables: {
      input: {
        userId: userId,
        request: 'reportPost',
        targetId: postId,
        payload: reason,
      },
    },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  });

  return Boolean(res.createFlashRequest);
};

export default {
  // get
  fetchFlashRequest,
  // post
  requestFeature,
  reportPost,
};
