import React, { useState } from 'react';
import { useMeetingSession } from './providers/MeetingSessionProvider';
import CSS from 'csstype';
import {
  darkTheme,
  MicSelection,
  SpeakerSelection,
  useAudioOutputs,
  SecondaryButton,
  CameraSelection,
  PreviewVideo,
} from 'amazon-chime-sdk-component-library-react';
import { ThemeProvider } from 'styled-components';
import MicrophoneActivityPreviewBar from './components/MicrophoneActivityBar';
import TestSound from './utils/TestSound';
import { commitButtonStyle, formStyle } from './components/FormStyles';
import Spinner from './components/Spinner';

const SelectDevices: React.FC = () => {
  const { startMeeting } = useMeetingSession();

  const [isJoining, setIsJoining] = useState(false);

  const handleSubmit = async () => {
    if (isJoining) return;
    setIsJoining(true);
    await startMeeting();
  };

  const { selectedDevice } = useAudioOutputs();
  const [selectedOutput, setSelectedOutput] = useState(selectedDevice);

  const handleSpeakerChange = (deviceId: string): void => {
    setSelectedOutput(deviceId);
  };

  const handleTestSpeaker = () => {
    new TestSound(selectedOutput);
  };

  const rowStyle: CSS.Properties = { display: 'flex', padding: '0.5em 0' };
  const leftColumnStyle: CSS.Properties = { flex: '0 0 60%', paddingRight: '10%' };
  const rightColumnStyle: CSS.Properties = { flex: '0 0 35%', position: 'relative' };

  return (
    <ThemeProvider theme={darkTheme}>
      <div style={formStyle}>
        <div>
          <h3 style={{ color: 'orange' }}>Select devices</h3>
          <div style={{ textAlign: 'left' }}>
            <div style={rowStyle}>
              <div style={leftColumnStyle}>
                <CameraSelection />
              </div>
              <div style={rightColumnStyle}>
                <div style={{ display: 'flex', height: '100%', maxHeight: '4rem' }}>
                  <PreviewVideo />
                </div>
              </div>
            </div>
            <div style={rowStyle}>
              <div style={leftColumnStyle}>
                <MicSelection />
              </div>
              <div style={{ ...rightColumnStyle, marginTop: '2.5em' }}>
                <MicrophoneActivityPreviewBar />
              </div>
            </div>
            <div style={rowStyle}>
              <div style={leftColumnStyle}>
                <SpeakerSelection onChange={handleSpeakerChange} />
              </div>
              <div style={{ ...rightColumnStyle, marginTop: '1.5em' }}>
                <SecondaryButton
                  id="testSpeakerButton"
                  label="Test speakers"
                  onClick={handleTestSpeaker}
                  style={{
                    width: '100%',
                    height: '2.25em',
                    marginTop: '0.25em',
                    lineHeight: '80%',
                    backgroundColor: 'rgb(63, 65, 73)',
                  }}
                />
              </div>
            </div>
          </div>
          <p>
            <button onClick={handleSubmit} style={commitButtonStyle}>
              {isJoining ? <Spinner style={{ margin: '0px' }} /> : 'Join'}
            </button>
          </p>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default SelectDevices;
