import React, { useContext, useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import SettingsIcon from '@material-ui/icons/Settings';
import LaunchIcon from '@material-ui/icons/Launch';

import { PaymentsAccount, User } from '../core/graphql/types';
import { appStore } from '../core/stores/app';
import paymentsSvc from '../core/services/stripeSvc';
import SubmitButton from '../components/ui/SubmitButton';
import stripeSvc from '../core/services/stripeSvc';
import AthletePaymentsAccountSummary from './AthletePaymentsAccountSummary';
import AthletePaymentsAccountDetails from './AthletePaymentsAccountDetails';

interface Props {
  user: User;
  onUserUpdated: (updateObj: { stripeAccountId?: string }) => void;
}
const PaymentsSetup: React.FC<Props> = ({ user, onUserUpdated }: Props) => {
  const [, appDispatch] = useContext(appStore);
  const [waitingForResponse, setWaitingForResponse] = useState(false);
  const [accountStatus, setAccountStatus] = useState<'notExists' | 'inProgress' | 'complete' | 'unknown'>('unknown');
  const [paymentsAccount, setPaymentsAccount] = useState<PaymentsAccount | null>(null);
  const redirectToStripe = async () => {
    const currentPageUrl = `${window.location.origin}${window.location.pathname}`;
    const refreshUrl = currentPageUrl;
    const returnUrl = currentPageUrl;

    setWaitingForResponse(true);
    appDispatch({ type: 'showBackdrop' });

    window.location.href = await paymentsSvc.getPaymentsAccountLink(refreshUrl, returnUrl);
  };
  const createStripeAccount = async () => {
    setWaitingForResponse(true);
    try {
      const stripeAccountId = await stripeSvc.createPaymentsAccount();

      appDispatch({ type: 'updateUser', userUpdateObj: { stripeAccountId: stripeAccountId } });
      onUserUpdated({ stripeAccountId: stripeAccountId });

      // Get the config link and continue with the Stripe account config process
      redirectToStripe();
    } catch (err: any) {
      let errMessage =
        (err.errors && err.errors[0] && err.errors[0].message) || "There's been an error creating the account";

      if (errMessage.indexOf('is not currently supported by Stripe') >= 0) {
        errMessage = 'Your country is not currently supported by Stripe';
      }

      appDispatch({
        type: 'showToast',
        toastTxt: errMessage,
        toastSeverity: 'error',
      });
    } finally {
      setWaitingForResponse(false);
    }
  };
  const getButtonIcon = () => {
    if (waitingForResponse) {
      return <CircularProgress size={24} />;
    } else if (accountStatus === 'notExists') {
      return <LaunchIcon fontSize="large" />;
    } else if (accountStatus === 'inProgress') {
      return <LaunchIcon fontSize="large" />;
    } else if (accountStatus === 'complete') {
      return <SettingsIcon fontSize="large" />;
    }
  };
  const handleActionClick = async () => {
    if (accountStatus === 'notExists') {
      createStripeAccount();
    } else if (accountStatus === 'inProgress') {
      redirectToStripe();
    }
  };

  useEffect(() => {
    if (user) {
      if (!user.stripeAccountId) {
        setAccountStatus('notExists');
      } else if (user.stripeAccountId && !user.stripePayoutsEnabled) {
        setAccountStatus('inProgress');
        paymentsSvc.getMyPaymentsAccount().then((account) => {
          console.log(account);
          setPaymentsAccount(account);
        });
      } else {
        setAccountStatus('complete');
      }
    } else {
      setAccountStatus('unknown');
    }
  }, [user]);

  return (
    <Paper variant="outlined">
      <Box p={3}>
        <Box width={1}>
          <Typography variant="body1" gutterBottom align="center">
            {accountStatus === 'notExists' && "Your payments accounts hasn't been set up yet"}
            {accountStatus === 'inProgress' && 'Your payments account is in progress...'}
            {accountStatus === 'complete' && 'Your payments account is complete!'}
          </Typography>
        </Box>
        {(accountStatus === 'notExists' || accountStatus === 'inProgress' || accountStatus === 'complete') && (
          <Box width={1} mt={2} display="flex" justifyContent="center">
            <SubmitButton
              disabled={waitingForResponse}
              endIcon={getButtonIcon()}
              submitting={waitingForResponse}
              onClick={handleActionClick}
            >
              {accountStatus === 'notExists' && 'Add payment details'}
              {accountStatus === 'inProgress' && 'Continue'}
              {accountStatus === 'complete' && 'Update configuration'}
            </SubmitButton>
          </Box>
        )}
        {accountStatus === 'inProgress' && paymentsAccount && (
          <Box mt={2}>
            <AthletePaymentsAccountSummary paymentsAccount={paymentsAccount} />
            <AthletePaymentsAccountDetails paymentsAccount={paymentsAccount} />
          </Box>
        )}
      </Box>
    </Paper>
  );
};

export default PaymentsSetup;
