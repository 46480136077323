import { contentfulSpaceID, contentfulToken } from '../../config';
import { Blogs } from '../contentful/types';
import {
  fetchAthletesCollection,
  fetchBlogsCollection,
  fetchLandingPreviewsCollection,
  getBlogById,
} from '../contentful/graphql/queries';

const dataServiceURL = 'https://graphql.contentful.com/content/v1/spaces/';

export enum CONTENTFUL_MODELS {
  ATHLETES = 0,
  BLOGS = 1,
  LANDING_PREVIEWS = 2,
}

const doRequest = (body: string) => {
  return fetch(`${dataServiceURL}${contentfulSpaceID}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${contentfulToken}`,
    },
    body: body,
  }).then((response) => response.json());
};

export const fetchContentByType = async (model: CONTENTFUL_MODELS, varName?: string, value?: boolean) => {
  const body: any = {
    query: '',
    variables: {},
  };

  switch (model) {
    case CONTENTFUL_MODELS.ATHLETES:
      body.query = fetchAthletesCollection;
      break;
    case CONTENTFUL_MODELS.BLOGS:
      body.query = fetchBlogsCollection;
      break;
    case CONTENTFUL_MODELS.LANDING_PREVIEWS:
      body.query = fetchLandingPreviewsCollection;
  }

  if (varName && value !== undefined) {
    body.variables[varName] = value;
  }

  return doRequest(JSON.stringify(body));
};

export const requestBlogBy = (varName: string, value: any) => {
  const body: any = {
    query: getBlogById,
    variables: {},
  };

  body.variables[varName] = value;

  return doRequest(JSON.stringify(body));
};

export const getBlogsByCategory = (itemArr: Blogs[], categoryName: string) => {
  return itemArr.filter((item) => item.category?.name === categoryName);
};

export const filterBlogsByCategorization = (itemArr: Blogs[], categorization: boolean) => {
  return itemArr.filter((item) => (categorization ? item.category !== null : item.category === null));
};

export const getBlogCategories = (itemArr: Blogs[]) => {
  const categoryNames: string[] = [];
  for (const index in itemArr) {
    itemArr[index].category !== null ? categoryNames.push(itemArr[index].category.name) : '';
  }

  return categoryNames.filter((item, pos) => {
    return categoryNames.indexOf(item) == pos;
  });
};

export const getLandingPreviews = async () => {
  const res = await fetchContentByType(CONTENTFUL_MODELS.LANDING_PREVIEWS);
  return res.data.landingPreviewsCollection.items;
};
