import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar/Snackbar';
import Alert from '@material-ui/lab/Alert/Alert';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router';

import ForgotPasswordForm1, { ForgotPasswordForm1Data } from '../containers/forms/forgotPassword/ForgotPasswordForm1';
import ForgotPasswordFormActivate, {
  ForgotPasswordFormActivateData,
} from '../containers/forms/forgotPassword/ForgotPasswordFormActivate';
import ForgotPasswordForm2, { ForgotPasswordForm2Data } from '../containers/forms/forgotPassword/ForgotPasswordForm2';
import ForgotPasswordForm3 from '../containers/forms/forgotPassword/ForgotPasswordForm3';
import { reportError } from '../core/helpers/core';
import AppHeaderPlaceHolder from '../components/layouts/AppHeaderPlaceHolder';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%', // material-ui: +16px
    minHeight: theme.typography.pxToRem(500),
    margin: 0, // materiail-ui: -8px
    backgroundColor: '#ffffff',
  },
  appHeaderPlaceHolder: theme.mixins.toolbar,
  formsWrapper: {
    [theme.breakpoints.up('sm')]: {
      width: theme.typography.pxToRem(400),
    },
  },
}));

interface PageState {
  activeStep: number;
  userEmail: string;
  form1ErrorTxt?: string;
  form2ErrorTxt?: string;
  toastText?: string;
  userNeedsActivation: boolean;
  waitingForResponse: boolean;
}

const pageInitialState: PageState = {
  activeStep: 1,
  userEmail: '',
  form1ErrorTxt: '',
  form2ErrorTxt: '',
  toastText: '',
  userNeedsActivation: false,
  waitingForResponse: false,
};

const ForgotPasswordPage: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const [pageState, setPageState] = useState(pageInitialState);
  const handleStep1Submit = async (form1Data: ForgotPasswordForm1Data) => {
    setPageState((state) => ({ ...state, userEmail: form1Data.email, waitingForResponse: true }));

    try {
      await Auth.forgotPassword(form1Data.email);

      setPageState((state) => ({ ...state, activeStep: pageState.activeStep + 1 }));
    } catch (err: any) {
      reportError('password_reset', 'forgot_password', err);
      if (err.code === 'UserNotFoundException') {
        setFormError(pageState, setPageState, 'User not found');
      } else if (err.code === 'InvalidParameterException' && err.message.indexOf('no registered/verified ') >= 0) {
        setPageState((state) => ({ ...state, userNeedsActivation: true }));
      } else {
        setFormError(pageState, setPageState, `Forgot password error with code ${err.code}`);
      }
    } finally {
      setPageState((state) => ({ ...state, waitingForResponse: false }));
    }
  };
  const handleStep1ActiveSubmit = async (values: ForgotPasswordFormActivateData) => {
    setPageState((state) => ({ ...state, waitingForResponse: true }));

    try {
      if (values.code) {
        await Auth.confirmSignUp(pageState.userEmail, values.code.toString(), {
          // Optional. Force user confirmation irrespective of existing alias. By default set to True.
          forceAliasCreation: true,
        });
        await Auth.forgotPassword(pageState.userEmail);

        setPageState((state) => ({ ...state, activeStep: pageState.activeStep + 1 }));
      }
    } catch (err: any) {
      reportError('password_reset', 'activate', err);
      setFormError(pageState, setPageState, err.message);
    } finally {
      setPageState((state) => ({ ...state, waitingForResponse: false }));
    }
  };
  const handleResendActivationCodeClick = async () => {
    setPageState((state) => ({ ...state, waitingForResponse: true }));

    try {
      await Auth.resendSignUp(pageState.userEmail);
      setPageToast(pageState, setPageState, `A new activation code has been sent to ${pageState.userEmail}`);
    } catch (err: any) {
      reportError('password_reset', 'resend_code', err);
      setFormError(pageState, setPageState, err.message);
    } finally {
      setPageState((state) => ({ ...state, waitingForResponse: false }));
    }
  };
  const handleStep2Submit = async (form2Data: ForgotPasswordForm2Data) => {
    setPageState((state) => ({ ...state, waitingForResponse: true }));

    try {
      await Auth.forgotPasswordSubmit(pageState.userEmail, form2Data.code, form2Data.password);
      setPageState((state) => ({ ...state, activeStep: pageState.activeStep + 1 }));
    } catch (err: any) {
      if (err.code === 'CodeMismatchException') {
        setFormError(pageState, setPageState, 'Wrong code. Please, check your e-mail');
      } else {
        setFormError(pageState, setPageState, err.message);
      }
    } finally {
      setPageState((state) => ({ ...state, waitingForResponse: false }));
    }
  };
  const handleStep3Submit = () => {
    history.push('/login');
  };
  const handleFormAlertCloseClick = () => {
    setFormError(pageState, setPageState, '');
  };
  const handleToastClose = () => {
    setPageToast(pageState, setPageState, '');
  };

  return (
    <>
      <AppHeaderPlaceHolder />
      <Grid
        className={classes.root}
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        spacing={2}
      >
        {/*<Grid className={classes.appHeaderPlaceHolder} item></Grid>*/}
        <Grid className={classes.formsWrapper} item>
          {/*  Step 1 */}
          {pageState.activeStep === 1 && (
            <>
              {!pageState.userNeedsActivation && (
                <ForgotPasswordForm1
                  loading={pageState.waitingForResponse}
                  errorTxt={pageState.form1ErrorTxt}
                  onSubmit={handleStep1Submit}
                  onAlertCloseClick={handleFormAlertCloseClick}
                ></ForgotPasswordForm1>
              )}
              {pageState.userNeedsActivation && (
                <ForgotPasswordFormActivate
                  userEmail={pageState.userEmail}
                  loading={pageState.waitingForResponse}
                  errorTxt={pageState.form1ErrorTxt}
                  onSubmit={handleStep1ActiveSubmit}
                  onAlertCloseClick={handleFormAlertCloseClick}
                  onResendActivationCodeClick={handleResendActivationCodeClick}
                ></ForgotPasswordFormActivate>
              )}
            </>
          )}
          {/*  Step 2 */}
          {pageState.activeStep === 2 && (
            <ForgotPasswordForm2
              userEmail={pageState.userEmail}
              loading={pageState.waitingForResponse}
              errorTxt={pageState.form2ErrorTxt}
              onSubmit={handleStep2Submit}
              onAlertCloseClick={handleFormAlertCloseClick}
            ></ForgotPasswordForm2>
          )}
          {/* Step 3 */}
          {pageState.activeStep === 3 && (
            <ForgotPasswordForm3 loading={pageState.waitingForResponse} onSubmit={handleStep3Submit} />
          )}
        </Grid>
        {/* Page Toast */}
        <Snackbar
          open={Boolean(pageState.toastText)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={6000}
          onClose={handleToastClose}
        >
          <Alert onClose={handleToastClose} severity="success">
            {pageState.toastText}
          </Alert>
        </Snackbar>
      </Grid>
    </>
  );
};

function setFormError(pageState: PageState, setPageState: (state: PageState) => void, txt: string, closeDelay = 5000) {
  const formErrorAttrib = `form${pageState.activeStep}ErrorTxt`;

  setPageState({ ...pageState, [formErrorAttrib]: txt });

  setTimeout(() => {
    setPageState({ ...pageState, [formErrorAttrib]: '' });
  }, closeDelay);
}

function setPageToast(pageState: PageState, setPageState: (state: PageState) => void, txt: string) {
  setPageState({ ...pageState, toastText: txt });
}

export default ForgotPasswordPage;
