import { API } from 'aws-amplify';
import { CommentParentType, ModelSortDirection } from '../../API';
import { createComment as createCommentMutation } from '../../graphql/mutations';
import { listCommentsByParent as listCommentsByParentQuery } from '../graphql/customQueries';
import { GRAPHQL_AUTH_MODE } from '../graphql/types';

const createComment = async (
  parentId: string,
  parentType: CommentParentType,
  message: string,
  recaptchaKey: string,
) => {
  const res: any = await API.graphql({
    query: createCommentMutation,
    variables: {
      parentId: parentId,
      parentType: parentType,
      message: message,
      recaptchaKey: recaptchaKey,
    },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  });

  return res.data.createComment;
};

const fetchCommentsByParent = async (
  parentId: string,
  sortDirection: ModelSortDirection,
  limit: number,
  nextToken?: string,
) => {
  const res: any = await API.graphql({
    query: listCommentsByParentQuery,
    variables: {
      parentId: parentId,
      sortDirection: sortDirection,
      limit: limit,
      nextToken: nextToken,
    },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  });
  return res.data;
};

export { createComment, fetchCommentsByParent };
