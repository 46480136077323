import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useHistory } from 'react-router';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import * as DateFns from 'date-fns';

import CircularProgressWithLabel from '../components/ui/CircularProgressWithLabel';
import userSvc, { fetchUserById } from '../core/services/userSvc';
import { authStore } from '../core/stores/auth';
import { User } from '../core/graphql/types';
import { getAthleteBasicsCompletionPct } from '../core/helpers/user';
import domHelpers from '../core/helpers/dom';
import { appStore } from '../core/stores/app';
import SpinnerBox from '../components/ui/SpinnerBox';
import SubmitButton from '../components/ui/SubmitButton';
import { getAthleteProfileRoute } from '../core/helpers/route';
import { noop } from '@babel/types';
import UpgradeToAthleteForm from '../containers/forms/UpgradeToAthleteForm';
import BasicPageLayout from '../components/layouts/BasicPageLayout';
import AgeBlockerDialog from '../components/dialogs/AgeBlockerDialog';

const useStyles = makeStyles((theme) => ({
  stepper: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  titleWrapper: {
    position: 'relative',
  },
  floatingTitleItemLeft: {
    position: 'absolute',
    left: theme.spacing(2),
    top: 0,
  },
  floatingTitleItemRight: {
    position: 'absolute',
    right: theme.spacing(2),
    top: 0,
  },
  upgradeToAthleteWrapper: {
    marginTop: '-15px',
    position: 'relative',
    overflow: 'hidden',
  },
  upgradeToAthleteButton: {
    color: '#fff',
    fontSize: theme.typography.pxToRem(16),
    '&::after': {
      animation: '$sheen 0.8s 0.3s 2 forwards;',
      animationTimingFunction: 'ease',
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      background: 'linear-gradient(to bottom, transparent, rgba(255,255,255,0.5) 50%, transparent)',
      transform: 'rotateZ(60deg) translate(-2em, 200%)',
    },
  },
  '@keyframes sheen': {
    '100%': {
      transform: 'rotateZ(60deg) translate(0, -200%)',
    },
  },
}));

const today = new Date();
const noAuthMessage = 'It seems you are trying to access a private page';

const BecomeAthletePage: React.FC = () => {
  const [authState] = useContext(authStore);
  const [, appDispatch] = useContext(appStore);
  const [user, setUser] = useState<User | undefined>();
  const [submitting, setSubmitting] = useState(false);
  const [nicknameUpdateError, setNicknameUpdateError] = useState(false);
  const [ageFragment, setAgeFrament] = useState<'below_13' | 'below_18' | null>(null);
  const [accountCompletionPct, setAccountCompletionPct] = useState(0);
  const [isReady, setIsReady] = useState(false);

  const history = useHistory();

  const theme = useTheme();
  const classes = useStyles();
  const isSmallScreen = domHelpers.isSmallScreen(useMediaQuery, theme);

  const loadUser = async (userId: string) => {
    const user = await fetchUserById(userId, 'full');
    if (user) {
      setUser(user);
    } else {
      history.push({
        pathname: '/notfound',
        state: {
          message: noAuthMessage,
        },
      });
    }
  };

  useEffect(() => {
    if (authState.isAthlete) {
      history.push('/home');
      return;
    }

    try {
      (async function () {
        if (authState.user) {
          loadUser(authState.user.userId);
        }
      })();
    } catch (e) {
      history.push({
        pathname: '/notfound',
        state: {
          message: noAuthMessage,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (user) {
      const completion = getAthleteBasicsCompletionPct(user, 'onboarding');
      setAccountCompletionPct(completion);
      completion === 100 ? setIsReady(true) : noop();
    }
  }, [user]);

  const upgradeToAthlete = async () => {
    setSubmitting(true);
    appDispatch({ type: 'showBackdrop', backdropTxt: 'Publishing your page...' });
    await userSvc.upgradeToAthlete();
    setSubmitting(false);
  };

  const handleAgeBlockerNoClick = () => {
    setAgeFrament(null);
  };
  const handleAgeBlockerYesClick = async () => {
    setAgeFrament(null);
    upgradeToAthlete();
  };
  const handleAgeBlockerCloseClick = () => {
    setAgeFrament(null);
  };

  const handlePublishClick = () => {
    if (user?.birthdate) {
      const limit13Date = DateFns.subYears(today, 13);
      const limit118ate = DateFns.subYears(today, 18);

      if (new Date(user.birthdate) > limit13Date) {
        setAgeFrament('below_13');
        return;
      } else if (new Date(user.birthdate) > limit118ate) {
        setAgeFrament('below_18');
        return;
      }
    }
    upgradeToAthlete();
  };

  const handlePreviewClick = async () => {
    if (user) {
      history.push(getAthleteProfileRoute(user));
    }
  };

  const onUserUpdate = async (update: any, nickname = false) => {
    const updatedUser: any = { ...user, ...update };
    setUser(updatedUser);
    if (!nickname) {
      await userSvc.updateUser(
        user?.id || '',
        {
          profileImgS3Key: updatedUser?.profileImgS3Key,
          givenName: updatedUser?.givenName,
          familyName: updatedUser?.familyName,
          birthdate: updatedUser?.birthdate,
          sport: updatedUser?.sport,
          country: updatedUser?.country,
          mission: updatedUser?.mission,
        },
        appDispatch,
        false,
      );
      return;
    }
    const isNickNameUpdated = await userSvc.updateMyNickname(update.nickname);
    if (isNickNameUpdated) {
      appDispatch({
        type: 'updateUser',
        userUpdateObj: { nickname: update.nickname },
      });
      setNicknameUpdateError(false);
    } else {
      setNicknameUpdateError(true);
    }
  };

  return (
    <BasicPageLayout maxWidth="lg">
      <Box width={1} pl={isSmallScreen ? 0 : 0} pr={isSmallScreen ? 0 : 0}>
        <Box mt={2}>
          <Grid container alignItems="center" style={{ width: '100%' }} direction="row-reverse">
            {isSmallScreen && (
              <Grid item xs={12}>
                <Box display="flex" justifyContent="center">
                  <CircularProgressWithLabel
                    value={accountCompletionPct}
                    size={isSmallScreen ? 72 : 60}
                    textVariant="body1"
                  />
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
      {/* Title */}
      <Box className={classes.titleWrapper} mt={3}>
        {!isSmallScreen && (
          <>
            {isReady && (
              <div className={classes.floatingTitleItemRight}>
                <Grid container spacing={1} direction="column" alignItems="stretch">
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      startIcon={<OpenInNewIcon />}
                      fullWidth
                      onClick={handlePreviewClick}
                    >
                      Preview
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant="contained" color="primary" fullWidth onClick={handlePublishClick}>
                      Publish!
                    </Button>
                  </Grid>
                </Grid>
              </div>
            )}
            <div className={isReady ? classes.floatingTitleItemLeft : classes.floatingTitleItemRight}>
              <CircularProgressWithLabel value={accountCompletionPct} size={72} textVariant="body1" />
            </div>
          </>
        )}
        <Typography variant="h4" align="center">
          <b>Channel information</b>
        </Typography>
      </Box>

      {/* Description */}
      <Box mt={1}>
        <Typography variant="body1" align="center" color="textSecondary">
          Fill all the fields in order to create your channel
        </Typography>
      </Box>

      {/* Content */}
      <Box width={1} mt={3} mb={4}>
        {!user && <SpinnerBox />}
        {user && (
          <UpgradeToAthleteForm
            submitting={submitting}
            nicknameUpdateError={nicknameUpdateError}
            onUserUpdate={onUserUpdate}
            user={user}
          />
        )}

        <Box mt={3} width={1}>
          <Grid container spacing={1}>
            <Grid item md={6} sm={6} xs={12}>
              <SubmitButton
                disabled={!isReady}
                variant="outlined"
                fullWidth
                startIcon={<OpenInNewIcon />}
                onClick={handlePreviewClick}
              >
                Preview!
              </SubmitButton>
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <SubmitButton disabled={!isReady} fullWidth onClick={handlePublishClick}>
                Publish!
              </SubmitButton>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {ageFragment !== null && (
        <AgeBlockerDialog
          open={Boolean(ageFragment)}
          ageFragment={ageFragment}
          onNoClick={handleAgeBlockerNoClick}
          onYesClick={handleAgeBlockerYesClick}
          onClose={handleAgeBlockerCloseClick}
        />
      )}
    </BasicPageLayout>
  );
};

export default BecomeAthletePage;
