import React from 'react';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

interface Props {
  title: string;
  children?: any;
}

const Panel: React.FC<Props> = ({ title, children }: Props) => {
  return (
    <Paper variant="outlined">
      <Box p={2}>
        <Typography variant="body1">
          <b>{title}</b>
        </Typography>
      </Box>
      <Divider />
      <Box p={2} display="flex" justifyContent="center">
        {children}
      </Box>
    </Paper>
  );
};

export default Panel;
