import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import AppHeaderPlaceHolder from '../../components/layouts/AppHeaderPlaceHolder';
import HeaderBlock from './HeaderBlock';
import OurStories from './OurStories';
import SwitcherChannel from './SwitcherChannel';
import BlogsSection from './BlogsSection';

const useStyles = makeStyles(() => ({
  homePageRoot: {
    width: '100%',
    '& a': {
      textDecoration: 'none',
    },
  },
}));

const HomePage: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.homePageRoot}>
      <AppHeaderPlaceHolder />

      {/* Header */}
      <HeaderBlock />

      {/* Our stories */}
      <OurStories />

      {/* Slider and highlights of having a Peakz channel */}
      <SwitcherChannel />

      {/* Blogs */}
      <BlogsSection />
    </div>
  );
};

export default HomePage;
