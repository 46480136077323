import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  loaderContainer: {
    animation: `$CircularPulse 3000ms ${theme.transitions.easing.easeInOut} infinite`,
    '& > svg': {
      fontSize: theme.typography.pxToRem(50),
    },
  },
  '@keyframes CircularPulse': {
    '50%': {
      transform: 'rotate(360deg) scale(1.2)',
    },
    '100%': {
      transform: 'rotate(0deg) scale(1.0)',
    },
  },
}));

interface Props {
  loaderColor: string;
  loaderIcon: JSX.Element;
}

const ShareLoader: React.FC<Props> = ({ loaderColor, loaderIcon }: Props) => {
  const classes = useStyles();
  return (
    <Box>
      <Typography variant="h4" align="center">
        We are currently sharing your post...
      </Typography>
      <Box mt={2}>
        <Typography variant="h5" align="center">
          Cool stuff is happening, wait a little :)
        </Typography>
      </Box>
      <Box
        style={{ color: loaderColor }}
        className={classes.loaderContainer}
        mt={5}
        display="flex"
        justifyContent="center"
      >
        {loaderIcon}
      </Box>
    </Box>
  );
};

export default ShareLoader;
