import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ProgressiveImage from 'react-progressive-image';
import Skeleton from '@material-ui/lab/Skeleton';
import Button from '@material-ui/core/Button';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import Hidden from '@material-ui/core/Hidden';

import { User } from '../core/graphql/types';
import { updateUser } from '../core/services/userSvc';
import { uploadImgBase64 } from '../core/services/imgSvc';
import urlHelpers from '../core/helpers/url';
import { authStore } from '../core/stores/auth';
import ImageCroppingDialog from './dialogs/ImageCroppingDialog';
import userHelpers from '../core/helpers/user';
import { appStore } from '../core/stores/app';
import { Box } from '@material-ui/core';

const imgPlaceholderHeightLG = 350;
const imgPlaceholderHeightSM = 250;
const imgPlaceholderHeightXS = 150;

const useStyles = makeStyles((theme) => ({
  coverImage: {
    objectFit: 'fill',
    filter: 'grayscale(100%)',
    backgroundColor: 'white',
    borderRadius: '0px 0px 5px 5px',
    overflow: 'hidden',
    maxHeight: theme.typography.pxToRem(480),
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0px',
    },
  },
  coverImagePlaceHolder: {
    height: theme.typography.pxToRem(imgPlaceholderHeightLG),
    [theme.breakpoints.down('lg')]: {
      height: theme.typography.pxToRem(imgPlaceholderHeightLG),
    },
    [theme.breakpoints.down('md')]: {
      height: theme.typography.pxToRem(imgPlaceholderHeightSM),
    },
    [theme.breakpoints.down('sm')]: {
      height: theme.typography.pxToRem(imgPlaceholderHeightXS),
    },
  },
  coverEditButtonWrapper: {
    position: 'absolute',
    right: theme.spacing(4),
    bottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      right: theme.spacing(1),
      bottom: theme.spacing(1),
    },
  },
}));

export interface CoverImageUpate {
  coverImgS3Key?: string;
}

interface Props {
  user?: User | null;
  editable?: boolean;
  onUpdated?: (update: CoverImageUpate) => void;
}

const EditableCoverImage: React.FC<Props> = ({ user, editable = true, onUpdated }: Props) => {
  const classes = useStyles();
  const [authState] = useContext(authStore);
  const [, appDispatch] = useContext(appStore);
  const [submitting, setSubmitting] = useState(false);
  const [imageCroppingDialogOpen, setImageCroppingDialogOpen] = useState<boolean>(false);
  const [croppingImgUrl, setCroppingImgUrl] = useState<string>('');
  const [coverImgUrl, setCoverImgUrl] = useState<string>('');
  const handleCoverEditClick = () => {
    setCroppingImgUrl(coverImgUrl);
    setImageCroppingDialogOpen(true);
  };
  const handleImageCropSubmit = async (profileImgBase64: string) => {
    if (user && authState.userInfo) {
      setSubmitting(true);
      const theUpdate = {
        coverImgS3Key: await uploadImgBase64(
          urlHelpers.createUserProfileImgS3Key(),
          authState.userInfo.userIdentityId,
          profileImgBase64,
          'protected',
        ),
      };
      await updateUser(user.id, theUpdate, appDispatch);

      setSubmitting(false);
      setImageCroppingDialogOpen(false);

      if (onUpdated) {
        onUpdated(theUpdate);
      }
    }
  };
  const handleImageCropCancel = () => {
    setImageCroppingDialogOpen(false);
  };

  useEffect(() => {
    if (user) {
      setCoverImgUrl(user?.coverImgS3Key ? userHelpers.getCoverImg(user) : '/static/images/tennis-field.png');
    }
  }, [user]);

  return (
    <>
      <Box position="relative">
        <Skeleton
          style={!coverImgUrl ? { position: 'relative' } : { position: 'absolute', height: '97%' }}
          className={classes.coverImagePlaceHolder}
          animation="pulse"
          variant="rect"
          width="100%"
        ></Skeleton>
        {coverImgUrl && (
          <ProgressiveImage src={coverImgUrl} placeholder={coverImgUrl}>
            {(src: string, loading: boolean) => (
              <img
                style={{ opacity: loading ? 0.5 : 1 }}
                className={classes.coverImage}
                src={src}
                alt="header-img"
                width="100%"
                height="100%"
              />
            )}
          </ProgressiveImage>
        )}
      </Box>

      {/* Cover Edit Button */}
      {editable && (
        <div className={classes.coverEditButtonWrapper}>
          <Hidden mdDown>
            <Button variant="contained" startIcon={<PhotoCameraIcon />} onClick={handleCoverEditClick}>
              Edit cover image
            </Button>
          </Hidden>
          <Hidden lgUp>
            <Button variant="contained" aria-label="upload-cover-picture" onClick={handleCoverEditClick}>
              <PhotoCameraIcon />
            </Button>
          </Hidden>
        </div>
      )}

      {/* Avatar Edit Dialog */}
      {imageCroppingDialogOpen && (
        <ImageCroppingDialog
          title=""
          open={imageCroppingDialogOpen}
          imgUrl={croppingImgUrl}
          aspect={userHelpers.imgDimensions.cover.width / userHelpers.imgDimensions.cover.height}
          outputWidth={userHelpers.imgDimensions.cover.width}
          outputHeight={userHelpers.imgDimensions.cover.height}
          submitting={submitting}
          onSubmit={handleImageCropSubmit}
          onCancel={handleImageCropCancel}
          onClose={handleImageCropCancel}
        ></ImageCroppingDialog>
      )}
    </>
  );
};

export default EditableCoverImage;
