import React from 'react';
import CSS from 'csstype';
import Config from '../utils/config';

// attende's video (local & remote)

interface Props {
  id: string;
  name: string;
  isThumbnail: boolean;
  fontSize: number;
  debug: boolean;
}

const VideoTile: React.FC<Props> = (props: Props) => {
  const objectStyle: CSS.Properties = {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    overflow: 'hidden',
  };

  return (
    <>
      {props.debug && (
        <img
          id={props.id}
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTm70UE_hj99DmwCxFvng5-4CtIgV4q7ccRiA"
          style={objectStyle}
        />
      )}
      {!props.debug && <video id={props.id} style={objectStyle} />}
      <header
        style={{
          backdropFilter: 'blur(20px)',
          backgroundColor: Config.BARS_COLOR,
          left: '2%',
          bottom: '2%',
          color: 'white',
          maxWidth: '94%',
          padding: `${props.fontSize * 0.25}px ${props.fontSize * 0.75}px`,
          position: 'absolute',
          borderRadius: `${props.fontSize * 0.2}px`,
        }}
      >
        <p
          style={{
            fontSize: `${props.fontSize * (props.isThumbnail ? 0.75 : 1)}px`,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            margin: 0,
          }}
        >
          {props.name}
        </p>
      </header>
    </>
  );
};

export default VideoTile;
