import API from '@aws-amplify/api';
import { callTwitter } from '../../../graphql/mutations';
import { GRAPHQL_AUTH_MODE } from '../../graphql/types';

const getTwitterRequestTokens = async (cbUrl: string) => {
  const res: any = await API.graphql({
    query: callTwitter,
    variables: {
      action: 'get_request',
      cbUrl: cbUrl,
    },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  });
  return res;
};

const getTwitterAccessTokens = async (
  token: string | undefined,
  secret: string | undefined,
  oauthVerifier: string | null,
) => {
  const res: any = await API.graphql({
    query: callTwitter,
    variables: {
      action: 'get_access',
      token: token,
      secret: secret,
      oauthVerifier: oauthVerifier,
    },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  });
  return res;
};

const uploadPreviewToTwitter = async (
  token: string | undefined,
  secret: string | undefined,
  previewUrl: string | undefined,
  athleteProfileUrl: string | undefined,
) => {
  const res: any = await API.graphql({
    query: callTwitter,
    variables: {
      action: 'upload_video',
      token: token,
      secret: secret,
      previewUrl: previewUrl,
      athleteProfileUrl: athleteProfileUrl,
    },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  });
  return res;
};

export { getTwitterRequestTokens, getTwitterAccessTokens, uploadPreviewToTwitter };
