import React, { useContext } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { appStore } from '../core/stores/app';
import { updateUser } from '../core/services/userSvc';

interface Props {
  showToast?: boolean;
}

const CurrencySelector: React.FC<Props> = ({ showToast = true }: Props) => {
  const [appState, appDispatch] = useContext(appStore);
  const handleChange = async (event: any) => {
    const newCurrencyCode = event.target.value;

    if (newCurrencyCode) {
      appDispatch({
        type: 'setUserCurrencyConfig',
        userCurrencyConfig: { currencyCode: newCurrencyCode, userDefined: true },
      });
      if (appState.user) {
        await updateUser(appState.user.id, { currency: newCurrencyCode }, appDispatch, showToast);
      }
    }
  };

  return (
    <Select
      labelId="demo-simple-select-standard-label"
      id="demo-simple-select-standard"
      value={appState.user?.currency}
      onChange={handleChange}
      label="Age"
    >
      {appState.currenciesConfig?.currencies.map((ccy) => (
        <MenuItem key={ccy.code} value={ccy.code}>
          {`${ccy.symbol} (${ccy.code.toUpperCase()})`}
        </MenuItem>
      ))}
    </Select>
  );
};

export default CurrencySelector;
