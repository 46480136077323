import { Dispatch } from 'react';
import { AppAction } from '../stores/app';

const setLaunchInfo = (appDispatch: Dispatch<AppAction>) => {
  //@ts-ignore
  const isStandalone = navigator.standalone || window.matchMedia('(display-mode: standalone)').matches;
  const isIOS =
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document);

  appDispatch({
    type: 'setLaunchInfo',
    launchInfo: {
      isMobileiOS: isIOS,
      isStandalone: isStandalone,
    },
  });
  appDispatch({
    type: 'setInstallBannerDismissed',
    installBannerDismissed: false,
  });
};

export { setLaunchInfo };
