import React, { useEffect } from 'react';
import { createContext, useContext, useState } from 'react';

export type DeviceType = 'desktop' | 'tablet' | 'mobile';
export type DisplayOrientation = 'portrait' | 'landscape';

const DEBUG = false;

type ContextValue = {
  deviceType: DeviceType;
  displayWidth: number;
  displayHeight: number;
  displayOrientation: DisplayOrientation;
};

const getDeviceType = (): DeviceType => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return 'tablet';
  } else if (
    /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)
  ) {
    return 'mobile';
  }
  return 'desktop';
};
const deviceType = getDeviceType();

const getCurrentValue = (): ContextValue => {
  const displayWidth = document.body.clientWidth;
  const displayHeight = document.body.clientHeight;
  return {
    deviceType,
    displayWidth,
    displayHeight,
    displayOrientation: displayWidth > displayHeight ? 'landscape' : 'portrait',
  };
};

const DeviceContext = createContext<ContextValue>(getCurrentValue());

interface Props {
  children?: React.ReactNode;
}

export const DeviceProvider: React.FC<Props> = (props: Props) => {
  const [value, setValue] = useState<ContextValue>(getCurrentValue());

  useEffect(() => {
    const update = () => {
      setValue(getCurrentValue());
    };

    // subscribe to window size changes
    const resizeObserver = new ResizeObserver(() => {
      update();
    });
    resizeObserver.observe(document.body);

    // subscribe to mobile orientation changes
    window.addEventListener('orientationchange', update);

    return () => {
      resizeObserver.unobserve(document.body);
      window.removeEventListener('orientationchange', update);
    };
  }, []);

  return (
    <DeviceContext.Provider value={value}>
      <>
        {DEBUG && (
          <div
            style={{
              position: 'absolute',
              zIndex: 100,
              color: 'yellow',
              top: '4em',
              left: '1em',
            }}
          >
            {JSON.stringify(value)}
          </div>
        )}
        {props.children}
      </>
    </DeviceContext.Provider>
  );
};

export const DeviceConsumer = DeviceContext.Consumer;

export const useDevice = () => {
  const context = useContext(DeviceContext);
  if (!context) {
    throw new Error('useDevice must be used within DeviceProvider');
  }
  return context;
};
