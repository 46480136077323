import React, { useState, useRef, useImperativeHandle } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import { PeakzTheme } from '../../theme/theme';

const useStyles = makeStyles((theme: PeakzTheme) => ({
  label: {
    fontSize: theme.typography.pxToRem(14),
  },
  iconCell: {
    height: '100%',
  },
}));

const StripeInput = ({ component: Component, inputRef, ...other }: any) => {
  const elementRef = useRef();

  useImperativeHandle(inputRef, () => ({
    // @ts-ignore
    focus: () => elementRef.current.focus,
  }));

  return <Component {...other} />; //  onReady={(element: any) => (elementRef.current = element)}
};

interface Props {
  label: string;
  name: string;
  component: any;
  disabled: boolean;
  icon?: React.ReactElement;
  defaultErrorMessage?: string;
  autoFocus?: boolean;
  onChange: (name: string, value?: string, isValid?: boolean) => void;
}

const StripeTextField: React.FC<Props> = ({
  label,
  name,
  component,
  disabled,
  icon,
  defaultErrorMessage = '',
  autoFocus = false,
  onChange,
}: Props) => {
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const hasError = errorMessage !== null;
  const fieldsMargin = 'normal';
  function handleElementChange(event: any) {
    // If there is elementType, it's a Stripe element
    if (event.elementType) {
      const error = event.error;

      if (error) {
        setErrorMessage(error.message);
      } else {
        setErrorMessage(null);
      }
    } else {
      // Otherwise, we consider the field as required
      if (Boolean(event.target.value)) {
        setErrorMessage(null);
      } else {
        setErrorMessage(defaultErrorMessage);
      }
    }
  }

  return (
    <>
      <InputLabel className={classes.label} required={true} color="secondary">
        {label}
      </InputLabel>
      <Grid container spacing={1}>
        <Grid item xs>
          <TextField
            name={name}
            variant="outlined"
            fullWidth
            margin={fieldsMargin}
            error={hasError}
            helperText={hasError ? errorMessage || 'Invalid' : ''}
            disabled={disabled}
            autoFocus={autoFocus}
            InputProps={{
              inputProps: {
                component: component,
                options: {
                  style: {
                    base: {
                      fontSize: '16px',
                      color: '#424770',
                      '::placeholder': {
                        color: '#aab7c4',
                      },
                    },
                    invalid: {
                      color: 'rgb(204, 50, 63)',
                    },
                  },
                  disabled: disabled,
                },
                onChange: (event: any) => {
                  // If there is elementType, it's a Stripe element
                  if (event.elementType) {
                    onChange(name, '', event.complete);
                  } else {
                    onChange(name, event.target.value, Boolean(event.target.value));
                  }
                },
              },
              inputComponent: StripeInput,
            }}
            onChange={handleElementChange}
          />
        </Grid>
        {icon && (
          <Grid item>
            <Grid className={classes.iconCell} item container alignItems="center">
              <Grid item>{icon}</Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default StripeTextField;
