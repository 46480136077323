import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { SelectData } from 'mui-rff';
import Hidden from '@material-ui/core/Hidden';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';

import FiltersBlock, { Filters, GendersFilter } from './FiltersBlock';
import AthletesBlock from './AthletesBlock';
import AppHeaderPlaceHolder from '../../components/layouts/AppHeaderPlaceHolder';
import ActiveFiltersList from './ActiveFiltersList';
import { getGenderName, getSportName } from '../../core/helpers/selectOptions';
import DialogTitle from '../../components/ui/DialogTitle';
import SubmitButton from '../../components/ui/SubmitButton';

const useStyles = makeStyles((theme) => ({
  athletesPageRoot: { width: '100%' },
  contentBlock: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
}));

const initialFilters = {
  name: '',
  country: null,
  genders: {
    male: false,
    female: false,
    prefer_not_to_say: false,
    other: false,
  },
  sports: {},
};

const AthletesOverviewPage: React.FC = () => {
  const classes = useStyles();
  const [filters, setFilters] = useState<Filters>(initialFilters);
  const [nextFilters, setNextFilters] = useState<Filters>(initialFilters);
  const [activeFiltersList, setActiveFiltersList] = useState<{ group: string; key: string; label: string }[]>([]);
  const [nextActiveFiltersList, setNextActiveFiltersList] = useState<{ group: string; key: string; label: string }[]>(
    [],
  );
  const [filtersDialogOpen, setFiltersDialogOpen] = useState(false);
  const deleteFilter = (setFiltersF: any, filterGroup: string, filterKey: string) => {
    if (filterGroup === 'name') {
      setFiltersF((f: Filters) => ({ ...f, name: '' }));
    } else if (filterGroup === 'country') {
      setFiltersF((f: Filters) => ({ ...f, country: null }));
    } else if (filterGroup === 'genders') {
      setFiltersF((f: Filters) => ({ ...f, genders: { ...f.genders, [filterKey]: false } }));
    } else if (filterGroup === 'sports') {
      setFiltersF((f: Filters) => ({ ...f, sports: { ...f.sports, [filterKey]: false } }));
    }
  };
  const handleNameChange = (name: string) => {
    setFilters((f) => ({ ...f, name })); // if name is cleared, do not add delay
  };
  const handleCountryChange = (country: SelectData | null) => {
    setFilters((f) => ({ ...f, country }));
  };
  const handleGendersChange = (genders: GendersFilter) => {
    setFilters((f) => ({ ...f, genders }));
  };
  const handleSportsChange = (sports: any) => {
    setFilters((f) => ({ ...f, sports }));
  };
  const handleNextNameChange = (name: string) => {
    setNextFilters((f) => ({ ...f, name }));
  };
  const handleNextCountryChange = (country: SelectData | null) => {
    setNextFilters((f) => ({ ...f, country }));
  };
  const handleNextGendersChange = (genders: GendersFilter) => {
    setNextFilters((f) => ({ ...f, genders }));
  };
  const handleNextSportsChange = (sports: any) => {
    setNextFilters((f) => ({ ...f, sports }));
  };
  const handleFiltersClick = () => {
    // Make a copy of filters without 'triggerGroup'
    setNextFilters({
      name: filters.name ? filters.name : '',
      country: filters.country ? { ...filters.country } : null,
      genders: { ...filters.genders },
      sports: { ...filters.sports },
    });
    setFiltersDialogOpen(true);
  };
  const handleDeleteFilterClick = (filterGroup: string, filterKey: string) => {
    deleteFilter(setFilters, filterGroup, filterKey);
  };
  const handleDeleteNextFilterClick = (filterGroup: string, filterKey: string) => {
    deleteFilter(setNextFilters, filterGroup, filterKey);
  };
  const handleClearAllFiltersClick = () => {
    setFilters(initialFilters);
  };
  const handleClearNextAllFiltersClick = () => {
    setNextFilters(initialFilters);
  };
  const handleApplyFilters = () => {
    setFiltersDialogOpen(false);
    setFilters(nextFilters);
  };

  useEffect(() => {
    setActiveFiltersList(getActiveFilters(filters));
  }, [filters]);

  useEffect(() => {
    setNextActiveFiltersList(getActiveFilters(nextFilters));
  }, [nextFilters]);

  return (
    <div className={classes.athletesPageRoot}>
      <AppHeaderPlaceHolder />

      <Container className={classes.contentBlock} maxWidth="lg">
        {/* Title */}
        <Box mt={3}>
          <Typography variant="h3" gutterBottom>
            Athlete overview
          </Typography>
        </Box>
        {/* Content */}
        <Box mt={3}>
          <Grid container spacing={3}>
            {/* Filters block */}
            <Hidden smDown>
              <Grid item xs={3}>
                <FiltersBlock
                  filters={filters}
                  onNameChange={handleNameChange}
                  onCountryChange={handleCountryChange}
                  onGendersChange={handleGendersChange}
                  onSportsChange={handleSportsChange}
                />
              </Grid>
            </Hidden>
            {/* Athletes block */}
            <Grid item xs={12} md={9}>
              <AthletesBlock
                filters={filters}
                activeFiltersList={activeFiltersList}
                onFiltersClick={handleFiltersClick}
                onDeleteFilterClick={handleDeleteFilterClick}
                onClearAllFiltersClick={handleClearAllFiltersClick}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>

      {/* Filters dialog */}
      {filtersDialogOpen && (
        <Dialog
          open={filtersDialogOpen}
          fullScreen={true}
          onClose={() => setFiltersDialogOpen(!filtersDialogOpen)}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle
            id="form-dialog-title"
            align="center"
            onClose={() => {
              setFiltersDialogOpen(false);
            }}
          >
            Filter
          </DialogTitle>
          <DialogContent>
            {/* Active Filters */}
            <Box mb={1}>
              <ActiveFiltersList
                activeFiltersList={nextActiveFiltersList}
                onDeleteClick={handleDeleteNextFilterClick}
                onClearAllClick={handleClearNextAllFiltersClick}
              />
            </Box>
            {/* Filters Block */}
            <FiltersBlock
              filters={nextFilters}
              onNameChange={handleNextNameChange}
              onCountryChange={handleNextCountryChange}
              onGendersChange={handleNextGendersChange}
              onSportsChange={handleNextSportsChange}
            />
            <Box mb={2}></Box>
          </DialogContent>
          <DialogActions>
            <SubmitButton onClick={handleApplyFilters}>Apply</SubmitButton>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default AthletesOverviewPage;

function getActiveFilters(filters: Filters): { group: string; key: string; label: string }[] {
  const activeFilters: { group: string; key: string; label: string }[] = [];

  // Name filter
  if (filters.name) {
    activeFilters.push({
      group: 'name',
      key: String(filters.name),
      label: `Name: ${filters.name}`,
    });
  }

  // Country filter
  if (filters.country) {
    activeFilters.push({
      group: 'country',
      key: String(filters.country.value),
      label: filters.country.label,
    });
  }

  // Genders filter
  Object.entries(filters.genders).forEach(([key, value]) => {
    if (value) {
      activeFilters.push({
        group: 'genders',
        key: key,
        label: getGenderName(key),
      });
    }
  });

  // Sports filter
  Object.entries(filters.sports).forEach(([key, value]) => {
    if (value) {
      activeFilters.push({
        group: 'sports',
        key: key,
        label: getSportName(key),
      });
    }
  });

  return activeFilters;
}
