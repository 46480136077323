import React, { useState } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';

interface Props {
  text: string;
  alphaHeight: string;
  textLimit: number;
  alphaBottomSeparation: string;
}

const useStyles = makeStyles((theme) => ({
  showLabel: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}));

const AlphaText: React.FC<Props> = ({ text, textLimit, alphaHeight, alphaBottomSeparation }: Props) => {
  const [textDisplayed, setTextDisplayed] = useState(false);
  const classes = useStyles();
  return (
    <Box position="relative" mt={2} mb={2}>
      <Typography style={{ position: 'relative' }} variant="body2">
        {text.length < textLimit ? text : textDisplayed ? text : text.substring(0, 200) + '...'}
      </Typography>
      {!textDisplayed && text.length > textLimit && (
        <Box
          style={{ backgroundColor: 'rgba(255, 255, 255, 0.69)', bottom: `${alphaBottomSeparation}` }}
          width="100%"
          height={alphaHeight}
          position="absolute"
        />
      )}
      {text.length > textLimit && (
        <Box mt={2} width={1}>
          <span
            className={classes.showLabel}
            onClick={() => {
              setTextDisplayed(textDisplayed ? false : true);
            }}
          >
            {textDisplayed ? 'Show less' : 'Show more'}
          </span>
        </Box>
      )}
    </Box>
  );
};

export default AlphaText;
