import React from 'react';
import { Helmet } from 'react-helmet-async';
import { facebookAppId } from '../../config';

interface Props {
  title: string;
  description?: string;
  shareUrl?: string;
  thumbnailUrl?: string;
  whatsappThumbnailUrl?: string;
}

const Metadata: React.FC<Props> = ({ title, description, shareUrl, thumbnailUrl, whatsappThumbnailUrl }: Props) => {
  return (
    <Helmet
      onChangeClientState={() => {
        // newState: any, addedTags: HelmetTags, removedTags: HelmetTags
        const metaTags = document.head.getElementsByTagName('meta');
        const refTag = document.getElementById('ref-head-insert-metatags'); // empty script tag in index.html

        if (refTag) {
          for (let i = 0; i < metaTags.length; i += 1) {
            const tag = metaTags[i];

            document.head.insertBefore(tag, refTag);
          }
        }
      }}
    >
      {/* Primary Meta Tags */}
      <title>{title}</title>
      <meta name="title" content={title} />
      {description && <meta name="description" content={description} />}
      <meta name="keywords" content="video, sharing, camera phone, video phone, free, upload" />

      {/* Opengraph (Facebook / Social media) */}
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="Peakz" />
      {shareUrl && <meta property="og:url" content={shareUrl} />}
      <meta property="og:title" content={title} />
      {description && <meta property="og:description" content={description} />}
      {thumbnailUrl && <meta property="og:image" content={thumbnailUrl} />}
      {thumbnailUrl && (
        <meta property="og:image:type" content={thumbnailUrl.indexOf('.jpg') > 0 ? 'image/jpeg' : 'image/png'} />
      )}
      {thumbnailUrl && <meta property="og:image:url" content={thumbnailUrl} />}
      {thumbnailUrl && <meta property="og:image:secure_url" content={thumbnailUrl} />}

      {/* Facebook */}
      <meta property="fb:app_id" content={facebookAppId} />

      {/* Whatsapp.
      Images (arrays in general) are evaluated from top to bottom and MUST around 180kb max. for whatsapp */}
      {whatsappThumbnailUrl && <meta property="og:image" content={whatsappThumbnailUrl} />}
      {whatsappThumbnailUrl && (
        <meta
          property="og:image:type"
          content={whatsappThumbnailUrl.indexOf('.jpg') > 0 ? 'image/jpeg' : 'image/png'}
        />
      )}
      {whatsappThumbnailUrl && <meta property="og:image:url" content={whatsappThumbnailUrl} />}
      {whatsappThumbnailUrl && <meta property="og:image:secure_url" content={whatsappThumbnailUrl} />}
      {whatsappThumbnailUrl && <meta property="og:image:width" content="400" />}
      {whatsappThumbnailUrl && <meta property="og:image:height" content="400" />}

      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={shareUrl} />
      <meta property="twitter:title" content={title} />
      {description && <meta property="twitter:description" content={description} />}
      {thumbnailUrl && <meta property="twitter:image" content={thumbnailUrl} />}
    </Helmet>
  );
};

export default Metadata;
