/*
 * Using mui-rff
 * Check following link for more info: https://github.com/lookfirst/mui-rff
 * */

import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import * as Yup from 'yup';
import { makeValidate, TextField } from 'mui-rff';
import { Form, FormSpy } from 'react-final-form';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import Dialog from '@material-ui/core/Dialog/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import { v1 as uuidv1 } from 'uuid';
import InputAdornment from '@material-ui/core/InputAdornment';

import { AthleteFundingGoal } from '../../core/graphql/types';
import DialogTitle from '../../components/ui/DialogTitle';
import getSymbolFromCurrency from 'currency-symbol-map';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  charCounter: {
    position: 'absolute',
    right: theme.spacing(1),
    bottom: theme.spacing(1 / 2),
  },
  textInputCurrency: {
    '& p': {
      fontSize: '22px',
      fontWeight: theme.typography.fontWeightBold,
    },
  },
}));

interface AthleteFundingGoalsDialogProps {
  open: boolean;
  fundingGoals: { id: string; amount: number | string; currency: string; description: string }[];
  currency: string;
  submitting?: boolean;
  onSubmit: (data: AthleteFundingGoalsDialogData, localVideoFile?: File) => void;
  onCancel: () => void;
}

export interface AthleteFundingGoalsDialogData {
  fundingGoals: AthleteFundingGoal[];
}

const maxDescriptionLen = 250;
const formSchema = Yup.object().shape({
  fundingGoals: Yup.array().of(
    Yup.object().shape({
      id: Yup.string(),
      amount: Yup.number().required('Amount is required.'),
      description: Yup.string().required('Description is required.'),
    }),
  ),
});

const validate = makeValidate(formSchema);

const AthleteFundingGoalsDialog: React.FC<AthleteFundingGoalsDialogProps> = ({
  open,
  fundingGoals,
  currency,
  submitting,
  onSubmit,
  onCancel,
}: AthleteFundingGoalsDialogProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const [initialValues, setInitialValues] = useState<{ fundingGoals: any[] }>({ fundingGoals: [] });
  const [curValues, setCurValues] = useState<AthleteFundingGoalsDialogData>({ fundingGoals: [] });
  const formId = 'athlete-funding-goals-dialog-form';
  const subscription = { pristine: true, invalid: true };
  const fieldsMargin = 'dense';
  const handleSubmitClick = (values: AthleteFundingGoalsDialogData) => {
    // Cleanup fields
    values.fundingGoals.forEach((fg) => {
      if (!fg.id) {
        fg.id = uuidv1();
      }
      fg.amount = parseInt(String(fg.amount), 10);
      fg.currency = currency;
    });
    // Sort
    values.fundingGoals.sort((a: AthleteFundingGoal, b: AthleteFundingGoal) => (a.amount < b.amount ? -1 : 1));
    onSubmit({ ...values });
  };
  const handleCancelClick = () => {
    onCancel();
  };
  const handleFormChange = ({ values }: { values: AthleteFundingGoalsDialogData }) => {
    // Avoid React render loop
    setTimeout(() => {
      setCurValues(values);
    }, 0);
  };

  useEffect(() => {
    setInitialValues({ fundingGoals: fundingGoals || [] });
  }, []);

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmitClick}
      mutators={{
        ...arrayMutators,
      }}
      subscription={subscription}
      validate={validate}
      key={subscription as any}
      render={({
        handleSubmit,
        pristine,
        invalid,
        form: {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          mutators: { push, pop },
        }, // injected from final-form-arrays above
      }) => (
        <form id={formId} className={classes.form} onSubmit={handleSubmit} noValidate={true}>
          <FormSpy subscription={{ values: true }} onChange={handleFormChange} />
          <Dialog
            open={open}
            maxWidth="sm"
            fullWidth={true}
            fullScreen={!isBigScreen}
            aria-labelledby="form-dialog-name"
          >
            <DialogTitle id="form-dialog-name" onClose={handleCancelClick}>
              Funding goals
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <FieldArray name="fundingGoals">
                  {({ fields }) => (
                    <>
                      {fields.map((name, index) => (
                        <Grid key={name} item xs={12}>
                          <TextField
                            id={`${name}.amount`}
                            name={`${name}.amount`}
                            label="Goal amount"
                            placeholder={`How much money you need to achieve your goal (${getSymbolFromCurrency(
                              currency,
                            )})`}
                            helperText={`How much money you need to achieve your goal (${getSymbolFromCurrency(
                              currency,
                            )})`}
                            required={true}
                            margin={fieldsMargin}
                            fullWidth={false}
                            type="number"
                            autoFocus={index === 0}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment className={classes.textInputCurrency} position="start">
                                  {getSymbolFromCurrency(currency)}
                                </InputAdornment>
                              ),
                            }}
                            disabled={submitting}
                          />
                          <TextField
                            id={`${name}.description`}
                            name={`${name}.description`}
                            label="Goal description"
                            placeholder="Explain your goal here"
                            helperText="Here you can describe your goal with more detail"
                            multiline={true}
                            rows={8}
                            required={true}
                            margin={fieldsMargin}
                            fullWidth
                            variant="filled"
                            disabled={submitting}
                            inputProps={{
                              maxLength: maxDescriptionLen,
                            }}
                            InputProps={{
                              endAdornment: (
                                <Typography className={classes.charCounter} variant="caption" color="textSecondary">
                                  {curValues.fundingGoals[index] && curValues.fundingGoals[index].description
                                    ? `${curValues.fundingGoals[index].description.length}/${maxDescriptionLen}`
                                    : name}
                                </Typography>
                              ),
                            }}
                          />
                          <Box display="flex" justifyContent="flex-end" mb={1}>
                            <Button endIcon={<DeleteIcon />} onClick={() => fields.remove(index)}>
                              Remove goal
                            </Button>
                          </Box>
                          <Divider />
                        </Grid>
                      ))}
                      <Grid item xs={12}>
                        <Box display="flex" justifyContent="center" mt={1}>
                          <Button
                            variant="outlined"
                            endIcon={<AddCircleOutlineIcon />}
                            onClick={() => fields.push({ amount: '', description: '' })}
                          >
                            Add funding goal
                          </Button>
                        </Box>
                      </Grid>
                    </>
                  )}
                </FieldArray>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancelClick}>Cancel</Button>
              <Button
                form={formId}
                color="primary"
                type="submit"
                disabled={submitting || pristine || invalid}
                endIcon={submitting ? <CircularProgress size={24} /> : <ArrowForwardIcon fontSize="large" />}
              >
                Save changes
              </Button>
            </DialogActions>
          </Dialog>
        </form>
      )}
    />
  );
};

export default AthleteFundingGoalsDialog;
