import React, { useEffect, useState, useContext } from 'react';
import Button from '@material-ui/core/Button';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';

import SubscriptionsPanel from './SubscriptionsPanel';
import BillingHistoryPanel from './BillingHistoryPanel';
import PaymentMethodsPanel from './PaymentMethodsPanel';
import BasicPageLayout from '../../components/layouts/BasicPageLayout';
import PanelGroup from '../../components/ui/PanelGroup';
import { appStore } from '../../core/stores/app';

const defaultSectionId = 'active';
const sections = [
  { id: 'active', title: 'Active memberships' },
  { id: 'history', title: 'Billing history' },
  { id: 'paymethods', title: 'Payment methods' },
];
const MembershipsPage: React.FC = () => {
  const { sectionId = defaultSectionId }: { sectionId: string } = useParams();
  const location = useLocation();
  const history = useHistory();
  const [appState] = useContext(appStore);
  const [selectedTab, setSelectedTab] = useState(0);
  const [addCreditCardDialogOpen, setAddCreditCardDialogOpen] = useState(false);
  const handleAddCardClick = () => {
    setAddCreditCardDialogOpen(true);
  };
  const handleSelectedTabChange = (newValue: number) => {
    if (newValue < sections.length) {
      const nextSection = sections[newValue].id || defaultSectionId;
      const pathRoot = location.pathname.split('/')[1];

      history.push({
        pathname: `/${pathRoot}/${nextSection}`,
      });
    }
  };
  const handleAddPaymentMethodAction = () => {
    setAddCreditCardDialogOpen(false);
  };

  useEffect(() => {
    const sectionIndex = sections.findIndex((s) => s.id === sectionId);

    setSelectedTab(sectionIndex >= 0 ? sectionIndex : sections.findIndex((s) => s.id === defaultSectionId));
  }, [sectionId]);

  return (
    <BasicPageLayout
      title="Manage memberships"
      maxWidth={appState.sideMenuVisible ? 'lg' : 'md'}
      showTitle={!appState.sideMenuVisible}
    >
      {/* Panel Group */}
      <PanelGroup
        tabs={sections.map((s) => s.title)}
        selectedTab={selectedTab}
        actions={
          <>
            {selectedTab === 2 && (
              <Button variant="contained" color="primary" onClick={handleAddCardClick}>
                Add Card
              </Button>
            )}
          </>
        }
        onSelectedTabChange={handleSelectedTabChange}
      >
        {/* Active Memberships */}
        <SubscriptionsPanel active={sections[selectedTab].id === 'active'} />

        {/* Billing History */}
        <BillingHistoryPanel active={sections[selectedTab].id === 'history'} />

        {/* Payment Methods */}
        <PaymentMethodsPanel
          active={sections[selectedTab].id === 'paymethods'}
          addCreditCardDialogOpen={addCreditCardDialogOpen}
          onAddPaymentMethodAction={handleAddPaymentMethodAction}
        />
      </PanelGroup>
    </BasicPageLayout>
  );
};

export default MembershipsPage;
