import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import Dialog from '@material-ui/core/Dialog/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogTitle from '../ui/DialogTitle';

const useStyles = makeStyles(() =>
  createStyles({
    content: {
      height: '100%',
    },
  }),
);

interface Props {
  open: boolean;
  title: string;
  description: string;
  descriptionAlign?: 'center' | 'left';
  children?: any;
  submitting?: boolean;
  confirmationText?: any;
  cancelationText?: any;
  onNoClick: () => void;
  onYesClick: () => void;
  onClose: () => void;
}

const ActionConfirmationDialog: React.FC<Props> = ({
  open,
  title,
  description,
  descriptionAlign = 'center',
  children,
  submitting,
  confirmationText = 'Yes',
  cancelationText = 'No',
  onNoClick,
  onYesClick,
  onClose,
}: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const handleClose = () => {
    onClose();
  };
  const handleNoClick = () => {
    onNoClick();
  };
  const handleYesClick = () => {
    onYesClick();
  };

  return (
    <Dialog open={open} fullScreen={!isBigScreen} maxWidth="xs" fullWidth={true} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title" onClose={handleClose}>
        {title}
      </DialogTitle>
      <DialogContent>
        <Grid className={classes.content} container direction="column" justifyContent="center">
          <Grid item>
            <Typography
              variant="body1"
              align={descriptionAlign}
              dangerouslySetInnerHTML={{ __html: description }}
            ></Typography>
          </Grid>
          {children && (
            <Grid item>
              <Box mt={2} width={1} display="flex" justifyContent="center">
                {children}
              </Box>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button disabled={submitting} onClick={handleNoClick}>
          {cancelationText}
        </Button>
        <Button
          color="primary"
          endIcon={submitting ? <CircularProgress size={24} /> : ''}
          disabled={submitting}
          onClick={handleYesClick}
        >
          {confirmationText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ActionConfirmationDialog;
