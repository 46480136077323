import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
  textDividerRoot: {
    position: 'relative',
    width: '100%',
  },
  dividerText: {
    position: 'absolute',
    background: '#fff',
    margin: 'auto',
    top: '50%',
    left: '50%',
    '-webkit-transform': 'translate(-50%,-50%)',
    '-ms-transform': 'translate(-50%,-50%)',
    transform: 'translate(-50%,-50%)',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

const TextDivider: React.FC<{ text: string; bgColor?: string }> = ({
  text,
  bgColor,
}: {
  text: string;
  bgColor?: string;
}) => {
  const classes = useStyles();

  return (
    <div className={classes.textDividerRoot}>
      <Divider />
      <Typography
        className={classes.dividerText}
        style={bgColor ? { background: bgColor } : {}}
        variant="body1"
        color="textSecondary"
      >
        {text}
      </Typography>
    </div>
  );
};

export default TextDivider;
