import {
  makeStyles,
  useTheme,
  Box,
  Typography,
  Grid,
  Paper,
  Button,
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import RateReviewIcon from '@material-ui/icons/RateReview';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import React, { useState } from 'react';
import { Post } from '../../../core/graphql/types';
import urlHelpers from '../../../core/helpers/url';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import CopyTooltipOverlay from '../../../components/ui/CopyTooltipOverlay';

const useStyles = makeStyles((theme) => ({
  videoPaperContainer: {
    position: 'relative',
    minHeight: theme.typography.pxToRem(290),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    '& img': {
      position: 'absolute',
      top: '0%',
    },
  },
  absoluteContainer: {
    width: '100%',
    bottom: 0,
    display: 'flex',
    alignItems: 'flex-end',
    padding: theme.typography.pxToRem(10),
    '& button': {
      margin: theme.spacing(0, 0.5, 0, 0.5),
    },
  },
  acceptedBadge: {
    padding: theme.spacing(0.5),
    borderRadius: '50%',
    position: 'absolute',
    top: '5px',
    border: '1px solid #0DB81F',
    backgroundColor: '#E3F6E5',
    '& .MuiSvgIcon-root': {
      fontSize: '25px',
      color: 'green',
    },
  },
}));

interface Props {
  handlePlayClick: any;
  handleMarking: any;
  post: Post;
  mode: 'search' | 'review';
}

const ReviewCard: React.FC<Props> = ({ handlePlayClick, handleMarking, post, mode }: Props) => {
  const theme = useTheme();
  const classes = useStyles();
  const [markingMenuAnchorEl, setMarkingMenuAnchorEl] = useState(null);
  const openMarkingMenu = Boolean(markingMenuAnchorEl);
  const handleCloseMarkingMenu = () => {
    setMarkingMenuAnchorEl(null);
  };
  const handleMarkingMenuClick = (e: any) => {
    setMarkingMenuAnchorEl(e.currentTarget);
  };
  const handleMarkingMenuItemClick = (markAs: string) => {
    handleMarking(markAs, post.id);
    handleCloseMarkingMenu();
  };
  const handleDownloadPreview = () => {
    if (post && post.vodId) {
      fetch(urlHelpers.getPostVodPreviewUrl(post.vodId))
        .then((response) => response.blob())
        .then((blob) => {
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = `${post?.name}_preview`;
          link.click();
        });
    }
  };
  return (
    <Grid style={{ paddingTop: theme.typography.pxToRem(20), position: 'relative' }} item md={4} sm={6} xs={12}>
      <CopyTooltipOverlay
        copyOpts={{
          'Preview url': urlHelpers.getPostVodPreviewUrl(post.vodId || ''),
          'Thumbnail url': urlHelpers.getPostVodThumbUrl(post.vodId || ''),
        }}
      />
      <Paper className={classes.videoPaperContainer} elevation={3}>
        <Box
          style={{
            overflow: 'hidden',
            position: 'relative',
            width: '100%',
            height: '140px',
            backgroundImage: `url(${urlHelpers.getPostVodThumbUrl(post?.vodId || '')})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        ></Box>
        <Box style={{ position: 'relative' }} alignItems="center" display="flex" p={1}>
          {mode === 'review' && post.reviewStatus && (
            <Box className={classes.acceptedBadge} display="flex">
              <VerifiedUserIcon />
            </Box>
          )}
          <Box ml={6}>
            <Typography>{post.name}</Typography>
          </Box>
        </Box>
        <Box className={classes.absoluteContainer}>
          <Button
            variant="contained"
            size="small"
            color="primary"
            style={{ cursor: 'pointer', position: 'absolute', right: '10px', bottom: '10px' }}
            onClick={handlePlayClick.bind(null, post.id, post.vodId || '')}
            startIcon={<PlayCircleFilledIcon />}
          >
            Play
          </Button>
          <Button
            variant="outlined"
            size="small"
            color="primary"
            style={{ cursor: 'pointer', position: 'absolute', right: '85px', bottom: '10px' }}
            onClick={handleDownloadPreview.bind(null, null)}
            startIcon={<CloudDownloadIcon />}
          >
            Download
          </Button>
        </Box>
        {mode === 'review' && !post.reviewStatus && (
          <Box className={classes.absoluteContainer}>
            <IconButton
              onClick={(e) => {
                handleMarkingMenuClick(e);
              }}
              style={{ padding: 0, position: 'absolute', bottom: '14px' }}
            >
              <RateReviewIcon />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={markingMenuAnchorEl}
              open={openMarkingMenu}
              onClose={handleCloseMarkingMenu}
            >
              <MenuItem style={{ color: 'green' }} onClick={handleMarkingMenuItemClick.bind(null, 'accepted')}>
                <CheckIcon />
              </MenuItem>
              <MenuItem style={{ color: 'red' }} onClick={handleMarkingMenuItemClick.bind(null, 'declined')}>
                <CloseIcon />
              </MenuItem>
            </Menu>
          </Box>
        )}
      </Paper>
    </Grid>
  );
};

export default ReviewCard;
