import { API, Storage } from 'aws-amplify';

import { dataURItoBlob } from '../helpers/misc';

export const fromUrlToBase64 = async (imgUrl: string) => {
  const { data } = await API.get('athletesrestapi', '/api/tools/image-url-to-base-64', {
    queryStringParameters: {
      imgUrl,
    },
  });

  return data;
};

export const uploadImgBase64 = async (
  path: string,
  userIdentityId: string,
  base64: string,
  accessLevel: 'protected' | 'public' | 'private' | undefined = 'public',
): Promise<string> => {
  const file = dataURItoBlob(base64);
  const upload: any = await Storage.put(path, file, {
    level: accessLevel,
    contentType: 'image/jpeg',
    cacheControl: 'max-age=31536000', // otherwise Amplify will add no-cache
  });
  const key = `${accessLevel}/${userIdentityId}/${upload.key}`;

  return key;
};

export default {
  fromUrlToBase64,
  uploadImgBase64,
};
