import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';

interface Props {
  chars: number;
  max: number;
}

const CharacterCounter: React.FC<Props> = ({ chars, max }: Props) => {
  const [aboveMax, setAboveMax] = useState(false);

  useEffect(() => {
    chars > max ? setAboveMax(true) : setAboveMax(false);
  }, [chars]);

  return (
    <Box display="flex" justifyContent="flex-end" style={{ fontSize: '10px' }}>
      <span style={aboveMax ? { color: 'red' } : {}}>{`${chars}/${max}`}</span>
    </Box>
  );
};

export default CharacterCounter;
