import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import { Post } from '../../core/graphql/types';
import PostElement from '../../components/elements/PostElement';

const useStyles = makeStyles(() => ({
  postHeaderRoot: {},
}));

interface Props {
  post?: Post;
  editable: boolean;
  viewPermission: boolean;
}

const PostHeaderBlock: React.FC<Props> = ({ post, editable, viewPermission }: Props) => {
  const classes = useStyles();

  return (
    <Grid className={classes.postHeaderRoot} container justifyContent="center" alignItems="center" spacing={0}>
      <PostElement
        showPreviewDownloaderButton={false}
        post={post}
        author={post && post.author}
        editPermission={editable}
        viewPermission={viewPermission}
        isPageHeader={true}
      />
    </Grid>
  );
};

export default PostHeaderBlock;
