import React from 'react';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import GraphicEqIcon from '@material-ui/icons/GraphicEq';
import { PostType } from '../../API';
import SelectableCards from '../../components/ui/SelectableCards';

const data = [
  {
    title: PostType.video,
    value: PostType.video,
    icon: <OndemandVideoIcon />,
  },
  {
    title: PostType.podcast,
    value: PostType.podcast,
    icon: <GraphicEqIcon />,
  },
];

interface Props {
  postType: PostType | undefined;
  onPostTypeSelect: any;
}

const PostTypeSelector: React.FC<Props> = ({ postType, onPostTypeSelect }: Props) => {
  const handleTypeClick = (type: PostType) => {
    onPostTypeSelect(type);
  };

  return (
    <SelectableCards
      title="Select the type of content"
      data={data}
      selectedItemValue={postType}
      onCardClick={handleTypeClick}
    />
  );
};

export default PostTypeSelector;
