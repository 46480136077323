import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import ReceiptIcon from '@material-ui/icons/Receipt';

import { Invoice } from '../../API';
import { fetchInvoices } from '../../core/services/subscriptionsSvc';
import { formatAmount } from '../../core/helpers/currency';
import { formatDateTime } from '../../core/helpers/misc';
import PanelContent from '../../components/ui/PanelContent';

const useStyles = makeStyles(() => ({
  itemsList: {
    width: '100%',
  },
  itemPreview: {
    width: '100%',
  },
  evenListItem: {
    backgroundColor: '#fafafa',
  },
  chipSuccess: {
    backgroundColor: '#cbf4c9',
  },
}));

export interface Props {
  active: boolean;
}

const BillingHistoryPanel: React.FC<Props> = ({ active }: Props) => {
  const classes = useStyles();
  const [invoices, setInvoices] = useState<Invoice[] | undefined>();
  const [loading, setLoading] = useState(false);

  const loadInvoices = useCallback(async () => {
    setLoading(true);
    const invoicesRes = await fetchInvoices();

    if (invoicesRes) {
      setInvoices(invoicesRes);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (active && !invoices && !loading) {
      loadInvoices();
    }
  }, [active, loadInvoices]);

  return (
    <>
      {active && (
        <PanelContent loading={loading}>
          {invoices && invoices.length === 0 && (
            <Typography variant="body1">You have no payment history yet.</Typography>
          )}
          {invoices && invoices.length > 0 && (
            <List className={classes.itemsList} component="nav" dense={false}>
              {invoices.map((inv: Invoice, i) => (
                <ListItem
                  key={inv.id}
                  className={clsx({
                    [classes.evenListItem]: i % 2 === 0,
                  })}
                >
                  <ListItemIcon>
                    <ReceiptIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={inv.total && inv.currency && `${formatAmount(inv.total, inv.currency)}`}
                    secondary={inv.created && `${formatDateTime(new Date(inv.created * 1000))}`}
                  />
                  <ListItemSecondaryAction>
                    <Grid container alignItems="center" spacing={1}>
                      {inv.invoice_pdf && (
                        <Grid item>
                          <Button href={inv.invoice_pdf}>Download as pdf</Button>
                        </Grid>
                      )}
                      {inv.paid && (
                        <Grid item>
                          <Chip className={classes.chipSuccess} label="paid" />
                        </Grid>
                      )}
                      {inv.number && (
                        <Grid item>
                          <Chip label={inv.number} />
                        </Grid>
                      )}
                    </Grid>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          )}
        </PanelContent>
      )}
    </>
  );
};

export default BillingHistoryPanel;
