import React from 'react';
import { User } from '../../core/graphql/types';
import userHelpers from '../../core/helpers/user';
import { Dialog, List, ListItem, ListItemText, ListItemAvatar, Avatar, Button, useMediaQuery } from '@material-ui/core';
import DialogTitle from '../ui/DialogTitle';
import { makeStyles } from '@material-ui/styles';
import theme, { PeakzTheme } from '../../theme/theme';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import domHelpers from '../../core/helpers/dom';
import { useHistory } from 'react-router';

interface Props {
  users?: User[];
  open: boolean;
  onCloseClick: () => void;
}

const useStyles = makeStyles((theme: PeakzTheme) => ({
  root: {
    position: 'relative',
    '& .MuiBackdrop-root': {
      [theme.breakpoints.down('xs')]: {
        backgroundColor: 'white',
      },
    },
    '& .MuiDialog-container .MuiPaper-root': {
      maxHeight: theme.typography.pxToRem(360),
      [theme.breakpoints.down('xs')]: {
        maxHeight: '100%',
        height: '100%',
        boxShadow: 'none',
      },
    },
    '& .MuiPaper-root': {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        margin: 0,
        overflowY: 'hidden',
      },
    },
  },
  button: {
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    '&:hover': {
      color: 'white',
    },
  },
  list: {
    overflowY: 'auto',
  },
}));

const UserListDialog: React.FC<Props> = ({ users, open, onCloseClick }: Props) => {
  const classes = useStyles();
  const isExtraSmallScreen = domHelpers.isXtraSmallScreen(useMediaQuery, theme);
  const history = useHistory();

  const handleItemButtonClick = (user: User) => {
    if (!user?.nickname) {
      history.push(`/${user.id}`);
      return;
    }
    history.push(`/${user.nickname}`);
  };

  return (
    <Dialog className={classes.root} fullWidth={true} maxWidth={isExtraSmallScreen ? undefined : 'xs'} open={open}>
      <DialogTitle id="user-list-dialog-title" onClose={onCloseClick}>
        Liked by
      </DialogTitle>
      <List className={classes.list}>
        {users?.map((user, index) => (
          <ListItem key={index}>
            <ListItemAvatar>
              <Avatar>
                {user?.profileImgS3Key && <img src={userHelpers.getProfileImg(user)} width="100%" />}
                {!user?.profileImgS3Key && <AccountCircleIcon />}
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={user.givenName} secondary={user?.nickname ? `@${user.nickname}` : ''} />
            <ListItemText style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                style={user.role !== 'athlete' ? { border: 'none' } : {}}
                onClick={() => {
                  handleItemButtonClick(user);
                }}
                disabled={user.role !== 'athlete'}
                className={classes.button}
                variant="contained"
                color="primary"
              >
                See profile
              </Button>
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
};

export default UserListDialog;
