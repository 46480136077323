import React from 'react';
import './style.css';

// vintage movie countdown
// refer: https://codepen.io/cdmoro/pen/rNeRowx

interface Props {
  number: number;
}

const FilmCountdown: React.FC<Props> = (props: Props) => {
  return (
    <div
      className="fc-body"
      style={{
        position: 'absolute' /* assuming that parent has 'relative' position */,
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        opacity: '0.4',
        zIndex: 101, // prevent clicking on meeting control buttons
      }}
    >
      <div className="fc-screen"></div>
      <div className="fc-lines"></div>
      <div className="fc-number">{props.number}</div>
    </div>
  );
};

export default FilmCountdown;
