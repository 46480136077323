import { Hub } from 'aws-amplify';

type UserFlow =
  | 'onboarding'
  | 'fan_signup'
  | 'signin'
  | 'password_reset'
  | 'create_challenge'
  | 'create_challenge_video'
  | 'join_challenge'
  | 'create_post'
  | 'create_bcast';
type UserFlowAction =
  | 'idem'
  | 'signup'
  | 'activate'
  | 'signin'
  | 'resend_code'
  | 'upload_video'
  | 'publish_video'
  | 'forgot_password'
  | 'join_challenge'
  | 'update_user'
  | 'load_tiers';

export const reportError = (userFlow: UserFlow, userFlowAction: UserFlowAction, err: any, ctx?: string): void => {
  const data: {
    userFlow: UserFlow;
    userFlowAction: UserFlowAction;
    message: string;
    client: any;
    errCode?: string;
    ctx?: string;
  } = {
    userFlow,
    userFlowAction,
    message: err.message,
    client: {
      platform: window.clientInformation?.platform,
      userAgent: window.clientInformation?.userAgent,
      language: window.clientInformation?.language,
      product: window.clientInformation?.product,
      productSub: window.clientInformation?.productSub,
    },
  };

  if (err.code) {
    data.errCode = err.code;
  }

  if (ctx) {
    data.ctx = ctx;
  }

  Hub.dispatch('apperror', {
    event: 'error',
    data: data,
  });
};
