import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';

import { AthleteFundingGoal, User } from '../../core/graphql/types';
import BorderLinearProgress from '../ui/BorderLinearProgress';
import AthleteFundingGoalsDialog, {
  AthleteFundingGoalsDialogData,
} from '../../containers/dialogs/AthleteFundingGoalsDialog';
import { updateUser } from '../../core/services/userSvc';
import { appStore } from '../../core/stores/app';
import { formatAmount } from '../../core/helpers/currency';
import { convertToCurrency } from '../../core/services/currencySvc';
import { getPayoutCurrency } from '../../core/helpers/user';

const useStyles = makeStyles((theme) => ({
  amountHighlight: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(20),
  },
}));

interface Props {
  user: User;
  editDialogOpen?: boolean;
  showEditButton?: boolean;
  onUpdated: (update: { fundingGoals: AthleteFundingGoal[] }) => void;
  onEditClick?: () => void;
  onCancel: () => void;
}

const AthleteFundingGoals: React.FC<Props> = ({
  user,
  editDialogOpen,
  showEditButton,
  onUpdated,
  onEditClick,
  onCancel,
}: Props) => {
  const classes = useStyles();
  const [appState, appDispatch] = useContext(appStore);
  const [curGoalIndex, setCurGoalIndex] = useState(-1);
  const [curGoal, setCurGoal] = useState<AthleteFundingGoal | null>(null);
  const [curGoalProgress, setCurGoalProgress] = useState(0);
  const [totalAmountPaid, setTotalAmountPaid] = useState<number>(0); // In user currency
  const [payoutCurrency, setPayoutCurrency] = useState<string | undefined>(); // Athlete payout currency
  const [userCurrency, setUserCurrency] = useState<string | undefined>(); // Current user currency
  const [submitting, setSubmitting] = useState(false);
  const handleGoalChange = (inc: number) => {
    setCurGoalIndex((i) => {
      if (user && user.fundingGoals) {
        return Math.min(Math.max(0, i + inc), user.fundingGoals.length - 1);
      }
      return i;
    });
  };
  const handleEditSubmit = async ({ fundingGoals }: AthleteFundingGoalsDialogData) => {
    const update: { fundingGoals: AthleteFundingGoal[] } = { fundingGoals };

    // Convert back to cents
    update.fundingGoals = update.fundingGoals.map((g) => ({ ...g, amount: g.amount * 100 }));

    setSubmitting(true);
    await updateUser(user.id, update, appDispatch);
    setSubmitting(false);

    // Send update before submitting,
    onUpdated(update);
  };
  const handleEditCancel = () => {
    onCancel();
  };
  const handleEditClick = () => {
    if (onEditClick) {
      onEditClick();
    }
  };

  // Update the accumulated amounts array and initialize index
  useEffect(() => {
    let isUnmounted = false;

    // All the amounts in user currency (appState.userCurrencyConfig.currencyCode)
    if (user && appState.currenciesConfig && appState.userCurrencyConfig) {
      let initialCurGoalIndex = 0;
      const amountPaidConv = convertToCurrency(
        appState.currenciesConfig,
        user.stats?.paidByScouts || 0,
        'eur',
        appState.userCurrencyConfig.currencyCode,
      );

      if (user.fundingGoals) {
        user.fundingGoals.forEach((fg: AthleteFundingGoal, i: number) => {
          if (appState.currenciesConfig && appState.userCurrencyConfig) {
            const goalAmountConv = convertToCurrency(
              appState.currenciesConfig,
              fg.amount,
              fg.currency || appState.currenciesConfig.defaultCurrency,
              appState.userCurrencyConfig.currencyCode,
            );

            if (amountPaidConv >= goalAmountConv) {
              initialCurGoalIndex = i + 1;
            }
          }
        });

        // If there is an index overflow or unset, set it to the last goal
        if (initialCurGoalIndex >= user.fundingGoals.length) {
          initialCurGoalIndex = user.fundingGoals.length - 1;
        }
      }

      if (!isUnmounted) {
        setTotalAmountPaid(amountPaidConv);
        setUserCurrency(appState.userCurrencyConfig.currencyCode);
        setPayoutCurrency(getPayoutCurrency(user, appState.currenciesConfig));
        // Set the last one
        setCurGoalIndex(initialCurGoalIndex);
      }
    }

    return () => {
      isUnmounted = true;
    };
  }, [user, appState.currenciesConfig, appState.userCurrencyConfig]);

  // It will trigger when the user changes the current goal
  useEffect(() => {
    if (user && user.fundingGoals && user.fundingGoals[curGoalIndex] && appState.currenciesConfig && userCurrency) {
      const newCurGoal = user.fundingGoals[curGoalIndex];
      const newCurGoalAmountConv = convertToCurrency(
        appState.currenciesConfig,
        newCurGoal.amount,
        newCurGoal.currency,
        userCurrency,
      );
      const newGoalProgress = Math.min(100, (totalAmountPaid / newCurGoalAmountConv) * 100);

      setCurGoal(newCurGoal);
      setCurGoalProgress(newGoalProgress);
    } else {
      setCurGoal(null);
      setCurGoalProgress(0);
    }
  }, [curGoalIndex]);

  return (
    <div>
      {/* Progress Bar */}
      {curGoal && user.fundingGoals && (
        <Box mb={2}>
          <Box mb={1} display="flex" justifyContent="flex-end">
            <IconButton
              component="span"
              size="small"
              disabled={curGoalIndex === 0}
              onClick={handleGoalChange.bind(null, -1)}
            >
              <ArrowBackIosIcon fontSize="small" />
            </IconButton>
            <IconButton
              component="span"
              size="small"
              disabled={curGoalIndex === user.fundingGoals.length - 1}
              onClick={handleGoalChange.bind(null, 1)}
            >
              <ArrowForwardIosIcon fontSize="small" />
            </IconButton>
          </Box>
          <BorderLinearProgress variant="determinate" value={curGoalProgress} />
          <Box mt={1}>
            {appState.currenciesConfig && userCurrency && (
              <Typography variant="body1" align="left" color="textSecondary">
                <b className={classes.amountHighlight}>{formatAmount(totalAmountPaid, userCurrency)}</b> of{' '}
                {formatAmount(
                  convertToCurrency(appState.currenciesConfig, curGoal.amount, curGoal.currency, userCurrency),
                  userCurrency,
                )}{' '}
                per month
              </Typography>
            )}
          </Box>
        </Box>
      )}

      {/* Description */}
      {curGoal && (
        <Box mb={2}>
          <Typography variant="body1" align="left">
            {curGoal.description}
          </Typography>
        </Box>
      )}

      {/* Pagination count */}
      {curGoal && user.fundingGoals && (
        <Box mb={2}>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <Typography variant="body1" color="textSecondary" align="left">
                {`${curGoalIndex + 1} of ${user.fundingGoals.length}`}
              </Typography>
            </Grid>
            {showEditButton && (
              <Grid item>
                <Button endIcon={<EditIcon fontSize="large" />} color="primary" onClick={handleEditClick}>
                  Edit goals
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
      )}

      {/* No goals message */}
      {!curGoal && (
        <Box mb={2}>
          <BorderLinearProgress variant="determinate" value={0} />
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <Typography variant="body1" color="textSecondary" align="left">
                No goals defined yet...
              </Typography>
            </Grid>
            {showEditButton && (
              <Grid item>
                <Button endIcon={<AddCircleOutlineIcon fontSize="large" />} color="primary" onClick={handleEditClick}>
                  Add goals
                </Button>
              </Grid>
            )}
          </Grid>
          {totalAmountPaid > 0 && (
            <Box mt={1}>
              <Typography variant="body1" color="textSecondary" align="left">
                Total amount received: <b className={classes.amountHighlight}>{formatAmount(totalAmountPaid, 'eur')}</b>
              </Typography>
            </Box>
          )}
        </Box>
      )}

      {/* Edit Dialog */}
      {editDialogOpen && appState.currenciesConfig && payoutCurrency && (
        <AthleteFundingGoalsDialog
          open={editDialogOpen}
          fundingGoals={
            user.fundingGoals && user.fundingGoals.length > 0
              ? user.fundingGoals.map((fg: AthleteFundingGoal) => ({
                  // Convert from Funding goal currency to user payoutCurrency
                  ...fg,
                  amount: appState.currenciesConfig
                    ? convertToCurrency(appState.currenciesConfig, fg.amount, fg.currency, payoutCurrency) / 100 // From cents to whole value
                    : 0,
                }))
              : // Empty slot
                [
                  {
                    id: '',
                    amount: '',
                    currency: payoutCurrency,
                    description: '',
                  },
                ]
          }
          currency={payoutCurrency}
          submitting={submitting}
          onSubmit={handleEditSubmit}
          onCancel={handleEditCancel}
        />
      )}
    </div>
  );
};

export default AthleteFundingGoals;
