import React from 'react';
import Typography from '@material-ui/core/Typography';
import { PaymentsAccount } from '../core/graphql/types';
import stripeSvc from '../core/services/stripeSvc';

interface Props {
  paymentsAccount: PaymentsAccount;
  type: 'currently_due' | 'pending_verification';
}
const AthletePaymentsAccountRemainingDetails: React.FC<Props> = ({ paymentsAccount, type }: Props) => {
  const missingFieldsNames = stripeSvc.getRemainingFieldsNames(paymentsAccount, type);

  return (
    <div>
      {(missingFieldsNames.found.length > 0 || missingFieldsNames.notFound.length > 0) && (
        <Typography variant="body2" gutterBottom>
          {type === 'currently_due'
            ? 'There are still some details required in order to complete your payments account. Following some of the missing fields:'
            : 'Your payments account is in progress. Following the fields being currently reviewed by Stripe:'}
        </Typography>
      )}
      <ul>
        {missingFieldsNames.found.map((fieldLabel) => (
          <li key={fieldLabel}>
            <Typography variant="body2">{fieldLabel}</Typography>
          </li>
        ))}
        {missingFieldsNames.notFound.length > 0 && (
          <li key="other-key">
            <Typography variant="body2">
              {missingFieldsNames.notFound.length > 0 ? 'Other...' : 'Account specifics'}
            </Typography>
          </li>
        )}
      </ul>
    </div>
  );
};

export default AthletePaymentsAccountRemainingDetails;
