import CSS from 'csstype';

export const formStyle: CSS.Properties = {
  backgroundColor: 'black',
  color: 'white',
  margin: '10% auto',
  padding: '2em',
  textAlign: 'center',
  width: '30rem',
  maxWidth: '100%',
  borderRadius: '0.25rem',
};

export const commitButtonStyle: CSS.Properties = {
  backgroundColor: 'orange',
  borderRadius: '2em',
  border: 'none',
  marginTop: '1em',
  height: '2.5em',
  width: '10em',
};
