import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';

import domHelpers from '../helpers/dom';

export default function ScrollToTop() {
  const { pathname } = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (history.action === 'PUSH') {
      domHelpers.scrollToTop();
    }
  }, [pathname]);

  return null;
}
