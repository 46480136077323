/*
 * Using mui-rff
 * Check following link for more info: https://github.com/lookfirst/mui-rff
 * */

import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import * as Yup from 'yup';
import { makeValidate, makeRequired, TextField, KeyboardDatePicker } from 'mui-rff';
import { Form, FormSpy } from 'react-final-form';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import Dialog from '@material-ui/core/Dialog/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import DateFnsAdapter from '@date-io/date-fns';

import { AthleteAchievement, AthleteGoal } from '../../core/graphql/types';
import { extractYoutubeVideoID, removeNullAttributes } from '../../core/helpers/misc';
import DialogTitle from '../../components/ui/DialogTitle';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submitBtnWrapper: {
    position: 'relative',
  },
  submitBtn: {
    margin: theme.spacing(2, 0, 1),
    height: 50,
  },
  submitBtnProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  charCounter: {
    position: 'absolute',
    right: theme.spacing(1),
    bottom: theme.spacing(1 / 2),
  },
}));

interface AchievementGoalDialogProps {
  open: boolean;
  item?: AthleteAchievement | AthleteGoal;
  itemType?: 'achievement' | 'goal';
  submitting?: boolean;
  onSubmit: (data: AthleteAchievement | AthleteGoal) => void;
  onCancel: () => void;
}

export interface AchievementGoalDialogData extends AthleteAchievement {
  id?: string;
}

const maxNameLen = 50;
const maxDescLen = 120;

const formSchema = Yup.object().shape({
  id: Yup.string(),
  name: Yup.string()
    .required('Name is a required field.')
    .max(maxNameLen, `The name can be max ${maxNameLen} characters long.`),
  date: Yup.date(),
  description: Yup.string().max(maxDescLen, `The description can be max ${maxDescLen} characters long.`),
  level: Yup.string(),
  link: Yup.string().url('This field has to be a valid URL'),
  videoUrl: Yup.string().test('videourl', 'It must be a YouTube video', (val) => {
    if (val) {
      try {
        const youtubeVideoId = extractYoutubeVideoID(val);
        return Boolean(youtubeVideoId);
      } catch (e) {}
      return false;
    }
    return true;
  }),
});

const validate = makeValidate(formSchema);
const required = makeRequired(formSchema);

const GoalAchievementDialog: React.FC<AchievementGoalDialogProps> = ({
  open,
  item,
  itemType,
  submitting,
  onSubmit,
  onCancel,
}: AchievementGoalDialogProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const formId = 'achievement-dialog-form';
  const subscription = { pristine: true, invalid: true };
  const fieldsMargin = 'dense';
  const today = new Date();
  const initialValues = removeNullAttributes(item);
  const handleSubmitClick = (values: AchievementGoalDialogData) => {
    onSubmit(values);
  };
  const handleCancelClick = () => {
    onCancel();
  };
  const handleFormChange = ({ values }: { values: AchievementGoalDialogData }) => {
    setName(values.name);
    setDescription(values.description);
  };

  return (
    <Form
      onSubmit={handleSubmitClick}
      initialValues={initialValues}
      subscription={subscription}
      validate={validate}
      key={subscription as any}
      render={({ handleSubmit, pristine, invalid }) => (
        <form
          id={formId}
          className={classes.form}
          onSubmit={handleSubmit}
          noValidate={true}
          autoComplete="new-password"
        >
          <FormSpy subscription={{ values: true }} onChange={handleFormChange} />
          <Dialog
            open={open}
            maxWidth="sm"
            fullWidth={true}
            fullScreen={!isBigScreen}
            aria-labelledby="form-dialog-name"
          >
            <DialogTitle id="form-dialog-name" onClose={handleCancelClick}>
              {itemType === 'achievement' && <>{item ? 'Update Achievement' : 'Create Achievement'}</>}
              {itemType === 'goal' && <>{item ? 'Update Goal' : 'Create Goal'}</>}
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    id="name"
                    name="name"
                    label="Title"
                    placeholder="First at the National Championships"
                    required={required.name}
                    margin={fieldsMargin}
                    fullWidth
                    autoFocus
                    inputProps={{
                      maxlength: maxNameLen,
                    }}
                    InputProps={{
                      endAdornment: (
                        <Typography className={classes.charCounter} variant="caption" color="textSecondary">
                          {name ? `${name.length}/${maxNameLen}` : ''}
                        </Typography>
                      ),
                    }}
                    disabled={submitting}
                  />
                </Grid>
                {itemType === 'achievement' && (
                  <Grid item xs={12} sm={6}>
                    <KeyboardDatePicker
                      disableToolbar={false}
                      id="date"
                      label="Date achieved"
                      name="date"
                      margin={fieldsMargin}
                      format="dd - MM - yyyy"
                      maxDate={today}
                      initialFocusedDate={new Date(today.getFullYear() - 18, today.getMonth(), today.getDate())}
                      disabled={submitting}
                      required={true}
                      dateFunsUtils={DateFnsAdapter}
                      helperText=" "
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="level"
                    name="level"
                    label="Level"
                    placeholder="National Championships"
                    required={required.level}
                    margin={fieldsMargin}
                    fullWidth
                    disabled={submitting}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="description"
                    name="description"
                    label="Description"
                    placeholder=""
                    helperText="Mention something important about your achievement"
                    multiline={true}
                    rows={5}
                    required={required.description}
                    margin={fieldsMargin}
                    fullWidth
                    inputProps={{
                      maxlength: maxDescLen,
                    }}
                    InputProps={{
                      endAdornment: (
                        <Typography className={classes.charCounter} variant="caption" color="textSecondary">
                          {description ? `${description.length}/${maxDescLen}` : ''}
                        </Typography>
                      ),
                    }}
                    disabled={submitting}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="videoUrl"
                    name="videoUrl"
                    label="Video url"
                    placeholder="https://www.youtube.com"
                    helperText="Share the youtube video that shows the achievement"
                    required={required.videoUrl}
                    margin={fieldsMargin}
                    fullWidth
                    disabled={submitting}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="link"
                    name="link"
                    label="Link"
                    placeholder="https://www.myachievement.com"
                    helperText="Share links related to your achievement"
                    required={required.link}
                    margin={fieldsMargin}
                    fullWidth
                    disabled={submitting}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancelClick}>Cancel</Button>
              <Button
                form={formId}
                color="primary"
                type="submit"
                disabled={submitting || pristine || invalid}
                endIcon={submitting ? <CircularProgress size={24} /> : <ArrowForwardIcon fontSize="large" />}
              >
                {item ? 'Save' : 'Create'}
              </Button>
            </DialogActions>
          </Dialog>
        </form>
      )}
    />
  );
};

export default GoalAchievementDialog;
