import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(() => ({
  panelContentRoot: {
    width: '100%',
  },
}));

interface Props {
  children: React.ReactNode;
  loading?: boolean;
}

const PanelContent: React.FC<Props> = ({ children, loading }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.panelContentRoot}>
      {loading && (
        <Box display="flex" justifyContent="center" width={1}>
          <CircularProgress />
        </Box>
      )}
      {children}
    </div>
  );
};

export default PanelContent;
