import { CurrenciesConfig, User } from '../graphql/types';
import { AuthState, AuthUser } from '../stores/auth';
import urlHelpers from './url';
import { imgBaseUrl } from '../../config';
import { gaEventActions, gaEventCategories, sendGaEvent } from '../services/gaSvc';
import {
  athleteChannelFundingRoute,
  athleteChannelRoute,
  athleteChannelSocialRoute,
  athletePaymentsRoute,
  athleteProfileEditRoute,
  athleteProfileSportsEditRoute,
  getAthleteCheckoutRoute,
  getSignupRoute,
} from './route';

const isLoggedIn = (authUser: AuthUser | null | undefined): boolean => {
  return Boolean(authUser && authUser.signInUserSession);
};

export const isSignedUp = (authUser: AuthUser | null | undefined): boolean => {
  const isCompleteProfile = Boolean(authUser && authUser.userId);

  return isCompleteProfile;
};

const isFederated = (authUser: AuthUser | null | undefined) => {
  const userName = authUser && authUser.username;
  let federated = userName ? userName.startsWith('Google') || userName.startsWith('Facebook') : false;

  if (!federated) {
    const payload = authUser && authUser.signInUserSession && authUser.signInUserSession.idToken.payload;

    if (payload && payload.identities) {
      if (payload.identities.find((i) => ['Google', 'Facebook'].includes(i.providerName))) {
        federated = true;
      }
    }
  }

  return federated;
};

export const onboardingInProgress = (authUser: AuthUser | null | undefined): boolean => {
  // return user && user.signInUserSession && Boolean(user.signInUserSession.idToken.payload['custom:role']);
  return Boolean(
    authUser &&
      authUser.signInUserSession &&
      (!authUser.signInUserSession.idToken.payload['custom:signup_phase'] ||
        parseInt(authUser.signInUserSession.idToken.payload['custom:signup_phase'], 10) < 4), // Only Athletes and Fans
  );
};

const getRole = (authUser: AuthUser | null | undefined) => {
  return authUser && authUser.signInUserSession && authUser.signInUserSession.idToken.payload['custom:role'];
};

const getProfileData = (authUser: AuthUser | null) => {
  return authUser && authUser.signInUserSession && authUser.signInUserSession.idToken.payload;
};

const getProfileImg = (user: User) => {
  if (user.profileImgS3Key) {
    return urlHelpers.getMediaUrlFromS3Key(user.profileImgS3Key, 'img');
  }

  return '';
};
const getCoverImg = (user: User) => {
  if (user.coverImgS3Key) {
    return urlHelpers.getMediaUrlFromS3Key(user.coverImgS3Key, 'img');
  }

  return '';
};

const getInitials = (user: User | null) => {
  return user && `${user.givenName.charAt(0)}${user.familyName?.charAt(0)}`;
};

const getIntroVideoThumbUrl = (user: User): string => {
  if (user.introVideoThumbS3Key) {
    const url = `${imgBaseUrl}/${user.introVideoThumbS3Key}`;

    return url;
  }

  return '';
};

const hasFeatureEnabled = (user: User, feature: 'posts'): boolean => {
  return Boolean(user && user.features && user.features[feature]);
};

const goToAthleteCheckout = async (athlete: User, authState: AuthState, history: any, tierId?: string) => {
  sendGaEvent(gaEventCategories.subscriptions, gaEventActions.click);

  if (!authState.isSignedUp) {
    history.push(getSignupRoute(athlete));
    return;
  }

  if (athlete) {
    if (!athlete.stripePayoutsEnabled) {
      alert('Athlete without payments configured. Please, contact the Peakz team.');
      return;
    }

    if (athlete && authState.user && athlete.stripePayoutsEnabled) {
      history.push(getAthleteCheckoutRoute(athlete, tierId));
    }
  }
};

export const getAthleteBasicsCompletionPct = (
  user: User,
  section: 'basics' | 'channel' | 'onboarding' | 'payments' | 'all' = 'all',
) => {
  const theUser = { ...user, introVideo: user.introVideoS3Key }; // Add introVideo as attribute to check
  const sectionAttributes = {
    basics: ['profileImgS3Key', 'givenName', 'familyName', 'gender', 'birthdate', 'country', 'sport'],
    onboarding: ['profileImgS3Key', 'givenName', 'familyName', 'birthdate', 'country', 'sport', 'nickname', 'mission'],
    channel: ['nickname', 'mission'],
    payments: ['stripeAccountId', 'stripePayoutsEnabled'],
  };
  let attribsToCheck;

  if (section === 'all') {
    attribsToCheck = [].concat(...(Object.values(sectionAttributes) as Array<any>));
  } else {
    attribsToCheck = sectionAttributes[section];
  }

  const completeAttribs = attribsToCheck.reduce((acc, attrib) => {
    if ((theUser as any)[attrib]) {
      return acc + 1;
    }
    return acc;
  }, 0);

  return Math.floor((completeAttribs / attribsToCheck.length) * 100);
};

export const getPayoutCurrency = (user: User, currenciesConfig: CurrenciesConfig) => {
  const payoutCurrency = currenciesConfig.currencies.find((c) => c.code === user.payoutCurrency);
  const currency = currenciesConfig.currencies.find((c) => c.code === user.currency);

  // If the user payoutCurrency is supported, return
  if (payoutCurrency) {
    return payoutCurrency.code;
  }

  // If the user currency is supported, return
  if (currency) {
    return currency.code;
  }

  return currenciesConfig.defaultCurrency;
};

export type UserProfileMissingAttrib = { pos: number; name: string; route: string };
export type UserProfileMissingAttribs = { [key: string]: UserProfileMissingAttrib };
export const getAthleteProfileCompletion = (
  user: User,
): { completionPct: number; missingAttribs: UserProfileMissingAttribs } => {
  const theUser = {
    ...user,
    introVideo: user.introVideoUrl || user.introVideoS3Key, // Calculated
    goals: user.goals && user.goals.length > 0, // Calculated
    achievements: user.achievements && user.achievements.length > 0, // Calculated
    sponsors: user.sponsors && user.sponsors.length > 0, // Calculated
    fundingGoals: user.fundingGoals && user.fundingGoals.length > 0, // Calculated
  };
  const attribsToCheck: UserProfileMissingAttrib[] = [
    { pos: 1, name: 'givenName', route: athleteProfileEditRoute },
    { pos: 2, name: 'familyName', route: athleteProfileEditRoute },
    { pos: 3, name: 'gender', route: athleteProfileEditRoute },
    { pos: 4, name: 'birthdate', route: athleteProfileEditRoute },
    { pos: 5, name: 'country', route: athleteProfileEditRoute },
    { pos: 6, name: 'sport', route: athleteProfileSportsEditRoute },
    { pos: 7, name: 'goals', route: athleteProfileSportsEditRoute },
    { pos: 8, name: 'achievements', route: athleteProfileSportsEditRoute },
    { pos: 9, name: 'sponsors', route: athleteProfileSportsEditRoute },
    { pos: 10, name: 'profileImgS3Key', route: athleteProfileEditRoute },
    { pos: 11, name: 'coverImgS3Key', route: athleteProfileEditRoute },
    { pos: 12, name: 'introVideo', route: athleteChannelRoute }, //introVideoS3Key,introVideoUrl
    { pos: 13, name: 'mission', route: athleteChannelRoute },
    { pos: 14, name: 'nickname', route: athleteChannelRoute },
    { pos: 15, name: 'instagramUrl', route: athleteChannelSocialRoute },
    { pos: 16, name: 'facebookUrl', route: athleteChannelSocialRoute },
    { pos: 17, name: 'youtubeUrl', route: athleteChannelSocialRoute },
    { pos: 18, name: 'stripePayoutsEnabled', route: athletePaymentsRoute },
    { pos: 19, name: 'fundingGoals', route: athleteChannelFundingRoute },
  ];
  const missingAttribs: UserProfileMissingAttribs = {};
  const completeAttribsCount = attribsToCheck.reduce((acc, attrib) => {
    if ((theUser as any)[attrib.name]) {
      return acc + 1;
    } else {
      missingAttribs[attrib.name] = attrib;
    }
    return acc;
  }, 0);
  const completionPct = Math.floor((completeAttribsCount / attribsToCheck.length) * 100);

  return { completionPct, missingAttribs };
};

const imgDimensions = {
  profile: {
    width: 400,
    height: 400,
  },
  cover: {
    width: 1280,
    height: 350,
  },
};

export default {
  isLoggedIn,
  isSignedUp,
  isFederated,
  onboardingInProgress,
  getRole,
  getProfileData,
  getProfileImg,
  getCoverImg,
  getInitials,
  getIntroVideoThumbUrl,
  hasFeatureEnabled,
  goToAthleteCheckout,
  getAthleteBasicsCompletionPct,
  getPayoutCurrency,
  imgDimensions,
};
