import React, { useContext, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import { useLocation, useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import BasicPageLayout from '../../components/layouts/BasicPageLayout';
import { User } from '../../core/graphql/types';
import { fetchUserById } from '../../core/services/userSvc';
import { authStore } from '../../core/stores/auth';
import { AntTab, AntTabs } from '../../components/ui/AntTabs';
import General from './General';
import AccountSecurity from './AccountSecurity';
import AccountBilling, { AccountBillingFormData } from './AccountBilling';
import { appStore } from '../../core/stores/app';
import SpinnerBox from '../../components/ui/SpinnerBox';

const defaultSectionId = 'general';
const sections = [
  { id: 'general', title: 'General' },
  { id: 'security', title: 'Account security' },
  { id: 'billing', title: 'Billing' },
];
const notFoundErrorMsg = 'It seems you are trying to access a private page';
interface Props {
  toggleDark?: boolean;
  settoggleDark?: any;
}

const SettingsPage: React.FC<Props> = (Props) => {
  const { sectionId = defaultSectionId }: { sectionId: string } = useParams();
  const location = useLocation();
  const history = useHistory();
  const [authState] = useContext(authStore);
  const [appState] = useContext(appStore);
  const [selectedTab, setSelectedTab] = useState(0);
  const [user, setUser] = useState<User | undefined>();
  const loadUser = async (userId: string) => {
    const user = await fetchUserById(userId, 'full');

    if (user) {
      setUser(user);
    } else {
      history.push({
        pathname: '/notfound',
        state: {
          message: notFoundErrorMsg,
        },
      });
    }
  };
  const handleSelectedTabChange = (event: React.ChangeEvent<any>, newValue: number) => {
    if (newValue < sections.length) {
      const nextSection = sections[newValue].id || defaultSectionId;
      const pathRoot = location.pathname.split('/')[1];

      history.push({
        pathname: `/${pathRoot}/${nextSection}`,
      });
    }
  };
  const handleUserUpdated = (updateObj: AccountBillingFormData) => {
    setUser((user) => {
      if (user) {
        return { ...user, ...updateObj } as any;
      }
    });
  };

  useEffect(() => {
    const sectionIndex = sections.findIndex((s) => s.id === sectionId);

    setSelectedTab(sectionIndex >= 0 ? sectionIndex : sections.findIndex((s) => s.id === defaultSectionId));
  }, [sectionId]);

  useEffect(() => {
    try {
      (async function () {
        if (authState.user) {
          loadUser(authState.user.userId);
        }
      })();
    } catch (e) {
      history.push({
        pathname: '/notfound',
        state: {
          message: notFoundErrorMsg,
        },
      });
    }
  }, [authState.user]);

  return (
    <BasicPageLayout
      title="Account settings"
      maxWidth={appState.sideMenuVisible ? 'lg' : 'md'}
      showTitle={!appState.sideMenuVisible}
    >
      {/* Tab menu */}
      <Box mb={3}>
        <AntTabs
          value={selectedTab}
          variant="scrollable"
          scrollButtons="auto"
          onChange={handleSelectedTabChange}
          aria-label="ant example"
        >
          {sections.map((s, i) => (
            <AntTab key={i} label={s.title} />
          ))}
        </AntTabs>
      </Box>

      {/* Content */}
      <Box>
        {!user && <SpinnerBox />}
        {user && (
          <>
            {/* General */}
            {sections[selectedTab].id === 'general' && (
              <General user={user} toggleDark={Props.toggleDark} settoggleDark={Props.settoggleDark} />
            )}
            {/* Security */}
            {sections[selectedTab].id === 'security' && <AccountSecurity user={user} />}
            {/* Billing */}
            {sections[selectedTab].id === 'billing' && <AccountBilling user={user} onUserUpdated={handleUserUpdated} />}
          </>
        )}
      </Box>
    </BasicPageLayout>
  );
};

export default SettingsPage;
