import * as DateFns from 'date-fns';

export const youtubeRegExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
export const micknameRegex = /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/gim; // From instagram. It can't start with capital

export const mbToBytes = (mb: number): number => {
  const mbBytes = 1048576;

  return mb * mbBytes;
};

export const countryToFlag = (isoCode: string) => {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode && isoCode.toUpperCase().replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
    : isoCode;
};

export const dataURItoBlob = (dataURI: string) => {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1]);
  else byteString = unescape(dataURI.split(',')[1]);

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
};

export const removeNullAttributes = (obj: any) => {
  const copyObj = { ...obj };

  Object.keys(copyObj).forEach((key) => copyObj[key] == null && delete copyObj[key]);

  return copyObj;
};

export const capitalize = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const extractYoutubeVideoID = (url: string) => {
  const match = url.match(youtubeRegExp);

  if (match && match[7].length == 11) {
    return match[7];
  } else {
    return '';
  }
};

export const formatDateTime = (date: Date): string => {
  const dateStr = DateFns.format(date, 'MMM dd, yyyy');
  const timeStr = DateFns.format(date, 'h:mm aa');

  return `${dateStr} AT ${timeStr}`;
};

export const formatDateTimeShort = (date: Date): string => {
  const dateStr = DateFns.format(date, 'MMM dd');
  const timeStr = DateFns.format(date, 'hh:mm');

  return `${dateStr} ${timeStr}`;
};

export const getNextMonthName = (shift: number | undefined) => {
  const today = new Date();

  if (!shift) {
    shift = 1;
  }
  return new Date(today.setMonth(today.getMonth() + shift)).toLocaleString('default', { month: 'long' });
};

export const secondsToStr = (seconds: number | undefined): string => {
  if (!seconds) {
    return '00:00';
  }
  const dateObj = new Date(seconds * 1000);
  const mins = dateObj.getUTCMinutes();
  const secs = dateObj.getSeconds();
  const paddingZeros = (val: number) => (val > 9 ? val.toString() : `0${val}`);

  return `${paddingZeros(mins)}:${paddingZeros(secs)}`;
};

export const getContentfulItemID = (obj: any) => {
  return obj?.sys.id;
};

export const getContentfulAssetURL = (obj: any, propertyName: string, quality: number) => {
  return obj[propertyName].url + `?fm=jpg&q=${quality}&fl=progressive`;
};

export const getContentfulRandomArr = (itemArr: any[], n: number) => {
  const randomlyGen: any[] = [];

  if (itemArr.length > n) {
    while (randomlyGen.length < n) {
      const pushable = itemArr[Math.floor(Math.random() * (itemArr.length - 1))];
      if (!randomlyGen.find((item) => getContentfulItemID(item) === getContentfulItemID(pushable))) {
        randomlyGen.push(pushable);
      }
    }
    return randomlyGen;
  }
  return itemArr;
};

export const getCountryFromCode = (code: string) => {
  // @ts-ignore
  const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });
  return regionNames.of(code);
};

export default {
  mbToBytes,
  countryToFlag,
  dataURItoBlob,
  removeNullAttributes,
  extractYoutubeVideoID,
  formatDateTime,
  getNextMonthName,
  secondsToStr,
  micknameRegex,
  getContentfulRandomArr,
  getContentfulAssetURL,
  getContentfulItemID,
  getCountryFromCode,
};
