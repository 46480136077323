import React, { useContext, useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import Confetti from 'react-dom-confetti';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';

import AthleteHeaderBlock from './AthleteHeaderBlock';
import AthleteInfoBlock from './AthleteInfoBlock';
import AboutMeBlock from './AboutMeBlock';
import GoalsAndAchievementsBlock from './GoalsAndAchievementsBlock';
import AthleteSponsorsBlock from './AthleteSponsorsBlock';
import AthletePosts from '../../containers/AthletePosts';
import { appStore } from '../../core/stores/app';
import urlHelpers from '../../core/helpers/url';
import userSvc, { fetchUser } from '../../core/services/userSvc';
import { AthleteAchievement, AthleteFundingGoal, AthleteGoal, AthleteSponsor, User } from '../../core/graphql/types';
import { authStore } from '../../core/stores/auth';
import { PeakzTheme } from '../../theme/theme';
import userHelpers, { getAthleteBasicsCompletionPct } from '../../core/helpers/user';
import AppHeaderPlaceHolder from '../../components/layouts/AppHeaderPlaceHolder';
import FundingGoalsBlock from './FundingGoalsBlock';
import { fetchAthleteSubscription } from '../../core/services/postsSvc';
import { isSubscriptionActive } from '../../core/helpers/athleteSubscription';
import Metadata from '../../core/misc/Metadata';
import domHelpers from '../../core/helpers/dom';
import colors from '../../core/helpers/colors';
import { sendGaEvent } from '../../core/services/gaSvc';

const useStyles = makeStyles((theme: PeakzTheme) => ({
  athleteProfilePageRoot: { width: '100%', height: '100%', overflowX: 'hidden', overflowY: 'hidden' },
  block: {
    marginBottom: theme.spacing(3),
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: `${theme.spacing(1.5)}px`,
      paddingRight: `${theme.spacing(1.5)}px`,
    },
  },
  headerBlock: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  navigationMenuDesktop: {
    marginBottom: theme.spacing(4),
  },
  navigationMenuMobile: {
    width: '100%',
    height: theme.typography.pxToRem(60),
    position: 'fixed',
    bottom: 0,
    // background: theme.palette.primary.main,
    zIndex: 1,
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  navigationMenuMobileAction: {
    '& span': {
      // color: '#ffffff',
    },
    '& svg': {
      fontSize: theme.typography.pxToRem(30),
    },
  },
  publishButtonAlert: {
    backgroundColor: colors.peazkGreen,
  },
  supportButton: {
    height: theme.typography.pxToRem(56),
    color: '#fff',
    fontSize: theme.typography.pxToRem(16),
    [theme.breakpoints.down('sm')]: {
      height: theme.typography.pxToRem(56),
    },
  },
}));

type ViewType = 'o' | 'p';
const navigationItems: any = [
  {
    label: '',
    value: 'cp',
    icon: <AddCircleOutlineIcon style={{ fontSize: '50px' }} />,
    public: true,
    userFeature: 'posts',
    bottomMenuAction: 'createPost',
  },
  {
    label: 'Posts',
    value: 'p',
    icon: <VideoLibraryIcon />,
    public: true,
    userFeature: 'posts',
  },
  { label: 'Overview', value: 'o', icon: <AccountCircleIcon />, public: true },
];
const confettiConfig = {
  angle: 90,
  spread: 180,
  startVelocity: 80,
  elementCount: 80,
  dragFriction: 0.2,
  duration: 6000,
  stagger: 0,
  width: '10px',
  height: '10px',
  perspective: '500px',
  colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
};

const AthleteProfilePage: React.FC = () => {
  const userIdParam = (useParams<{ userId?: string }>().userId || '').toLowerCase();
  const classes = useStyles();
  const history = useHistory();
  const location: { search: string; state: any } = useLocation();
  const [authState] = useContext(authStore);
  const [, appDispatch] = useContext(appStore);
  const [athlete, setAthlete] = useState<User | undefined>();
  const [defaultView, setDefaultView] = useState<string>('');
  const [isLoggedUser, setIsLoggedUser] = useState<boolean>(false);
  const [profileImgUrl, setProfileImgUrl] = useState<string>('');
  const [currentView, setCurrentView] = useState<string>();
  const [isUserSubscribed, setIsUserSubscribed] = useState<boolean | undefined>(undefined);
  const [isConfettiVisible, setIsConfettiVisible] = useState<boolean>(false);
  const [showPublish, setShowPublish] = useState(false);
  const contentRef = useRef(null);
  const [t] = useTranslation();
  const meta = {
    title:
      currentView === 'p'
        ? 'Watch my exclusive behind-the-scene videos'
        : athlete
        ? `${athlete.name} (${athlete.country})`
        : '',
    description:
      currentView === 'p'
        ? 'Requests from fans, my training sessions and other exclusive content I will post on Peakz. Check out my profile and posts and become part of my journey as a professional athlete.'
        : athlete && athlete.mission
        ? athlete.mission
        : '',
    shareUrl: athlete ? urlHelpers.getUserShareUrl(athlete) : '',
    thumbnailUrl: profileImgUrl ? profileImgUrl : '',
  };

  const loadAthlete = async (userIdentifier: string) => {
    const user = await fetchUser(userIdentifier, authState.user?.userId);
    // Only athletes are accepted
    if (!user) {
      history.push({
        pathname: '/athletenotfound',
        state: {
          message: t('global.athlete_not_found'),
        },
      });
    } else {
      setAthlete(user);
      setIsLoggedUser(authState.user?.userId === user.id);
      if (user.profileImgS3Key) {
        setProfileImgUrl(userHelpers.getProfileImg(user));
      } else {
        setProfileImgUrl('');
      }

      // Activate confetti if coming from a successful checkout
      if (location.state && location.state.checkoutSuccess) {
        setIsConfettiVisible(true);
        // Reset state
        history.push({
          state: {},
        });
      }
    }

    // Scroll to the content area if specified
    if (location.state && location.state.scroll) {
      domHelpers.scrollToElem(contentRef, null, false, 100);
      history.push({ state: {} });
    }
  };
  const updateQueryString = (searchObj: any) => {
    history.push({
      pathname: window.location.pathname,
      search: urlHelpers.objectToQueryString(searchObj),
    });
  };
  const handleUserUpdated = (update: {
    profileImgS3Key?: string;
    coverImgS3Key?: string;
    givenName?: string;
    familyName?: string;
    mission?: string;
    achievements?: AthleteAchievement[];
    goals?: AthleteGoal[];
    fundingGoals?: AthleteFundingGoal[];
    sponsors?: AthleteSponsor[];
  }) => {
    if (athlete) {
      const updatedUser: User = { ...athlete, ...update };

      setAthlete(updatedUser);
    }
  };
  const handleViewChange = (event: React.ChangeEvent<any>, newValue: ViewType) => {
    const searchObj: any = {};

    if (newValue !== defaultView) {
      searchObj.v = newValue;
    }

    updateQueryString(searchObj);

    domHelpers.scrollToElem(contentRef);
  };
  const handleBottomNavigationButtonClick = (item: any) => {
    if (item.bottomMenuAction === 'createPost') {
      history.push('/create/post');
    }
  };
  const handlePublishClick = async () => {
    setShowPublish(false);
    appDispatch({ type: 'showBackdrop', backdropTxt: 'Publishing your page...' });
    await userSvc.upgradeToAthlete();
  };

  // Current view [location.search]
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const defView = athlete && athlete.stripePayoutsEnabled && isViewValid('p', isLoggedUser, athlete) ? 'p' : 'o';

    setDefaultView(defView);

    const paramViewValue = searchParams.get('v');
    if (paramViewValue) {
      if (paramViewValue && isViewValid(paramViewValue, isLoggedUser, athlete)) {
        setCurrentView(paramViewValue);
      } else {
        setCurrentView(defView);
      }
    } else {
      setCurrentView(defView);
    }

    if (athlete && athlete.role === 'fan') {
      const isBasicsComplete = getAthleteBasicsCompletionPct(athlete, 'basics') === 100;
      const isChannelComplete = getAthleteBasicsCompletionPct(athlete, 'channel') === 100;

      setShowPublish(isBasicsComplete && isChannelComplete);
    }
  }, [location.search, athlete, isLoggedUser]);

  // Load athlete [userIdParam]
  useEffect(() => {
    try {
      if (userIdParam) {
        loadAthlete(userIdParam);
      } else if (authState.user) {
        loadAthlete(authState.user.userId);
      }
    } catch (e) {
      history.push({
        pathname: '/athletenotfound',
        state: {
          message: t('global.athlete_not_found'),
        },
      });
    }
  }, [userIdParam]);

  const handleSubscribeClick = async () => {
    if (athlete) {
      sendGaEvent(
        'user trying to subscribe',
        'athlete_channel',
        `an user clicked subscribe to athlete ${
          athlete?.givenName + ' | ' + athlete?.familyName + ' | ' + athlete?.email
        }`,
      );
      await userHelpers.goToAthleteCheckout(athlete, authState, history);
    }
  };

  // Subscription [athlete]
  useEffect(() => {
    // Check if the user is subscribed to the athlete
    (async function () {
      if (authState.initialized && athlete) {
        let hasSubscription = false;

        if (authState.user && authState.user.userId !== athlete.id) {
          const subscription = await fetchAthleteSubscription(authState.user.userId, athlete.id);

          if (subscription && isSubscriptionActive(subscription)) {
            hasSubscription = true;
          }
        }
        setIsUserSubscribed(hasSubscription);
      }
    })();
  }, [authState.initialized, athlete]);

  useEffect(() => {
    sendGaEvent(
      'pageview',
      window.location.pathname,
      athlete?.givenName + ' | ' + athlete?.familyName + ' | ' + athlete?.email,
    );
  });

  return (
    <div className={classes.athleteProfilePageRoot}>
      <Metadata
        title={meta.title}
        description={meta.description}
        shareUrl={meta.shareUrl}
        thumbnailUrl={meta.thumbnailUrl}
      />
      <AppHeaderPlaceHolder />

      <Container className={`${classes.block} ${classes.headerBlock}`} maxWidth="lg">
        <AthleteHeaderBlock
          user={athlete}
          editable={isLoggedUser}
          onUserUpdated={handleUserUpdated}
        ></AthleteHeaderBlock>
      </Container>

      <Box display="flex" justifyContent="center" width={1}>
        <Confetti active={isConfettiVisible} config={confettiConfig} />
      </Box>

      <Container className={classes.block} maxWidth="sm">
        <AthleteInfoBlock user={athlete} editable={isLoggedUser} onUserUpdated={handleUserUpdated} />
      </Container>

      <Container className={classes.block} maxWidth="sm">
        <AboutMeBlock athlete={athlete} editable={isLoggedUser} onUserUpdated={handleUserUpdated} />
      </Container>

      {false /* athlete?.fundingGoals && athlete.fundingGoals.length > 0 */ && (
        <Container className={classes.block} maxWidth="sm">
          <FundingGoalsBlock
            athlete={athlete}
            editable={isLoggedUser}
            isUserSubscribed={isUserSubscribed}
            onUserUpdated={handleUserUpdated}
          />
        </Container>
      )}

      {/* Subscribe Button */}
      {isUserSubscribed !== undefined &&
        !isUserSubscribed &&
        athlete &&
        athlete.stats?.numPosts !== undefined &&
        athlete.stats.numPosts > 0 && (
          <Container className={classes.block} maxWidth="sm">
            <Box mb={2}>
              <Button
                className={classes.supportButton}
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleSubscribeClick}
              >
                <b>{`Subscribe to ${athlete.givenName}'s posts`}</b>
              </Button>
            </Box>
            <Typography variant="body2" color="textSecondary" align="center">
              <i className="fas fa-medal"></i> {`Become part of ${athlete.givenName}’s journey`}
            </Typography>
          </Container>
        )}

      <div ref={contentRef}>
        {/* Navigation Menu Desktop */}
        <Hidden smDown>
          <Container className={classes.block} maxWidth="sm">
            <Tabs
              className={classes.navigationMenuDesktop}
              value={currentView}
              indicatorColor="primary"
              textColor="primary"
              centered
              onChange={handleViewChange}
              aria-label="disabled tabs example"
            >
              {navigationItems.map((item: any) => {
                if (isNavigationItemVisible(item, isLoggedUser, athlete) && !item.bottomMenuAction) {
                  return <Tab key={item.value} label={item.label} value={item.value} icon={item.icon} />;
                }
              })}
            </Tabs>
          </Container>
        </Hidden>

        {currentView === 'p' && (
          <Container className={classes.block} maxWidth="sm">
            <AthletePosts athlete={athlete} editable={isLoggedUser} isUserSubscribed={isUserSubscribed} />
          </Container>
        )}
        {currentView === 'o' && (
          <>
            <Container className={classes.block} maxWidth="sm">
              <GoalsAndAchievementsBlock athlete={athlete} editable={isLoggedUser} onUserUpdated={handleUserUpdated} />
            </Container>
            <Container className={classes.block} maxWidth="sm">
              <AthleteSponsorsBlock athlete={athlete} editable={isLoggedUser} onUserUpdated={handleUserUpdated} />
            </Container>
          </>
        )}

        {/* Navigation Menu Mobile */}
        <Hidden smUp>
          <BottomNavigation
            className={classes.navigationMenuMobile}
            value={currentView}
            showLabels
            onChange={handleViewChange}
          >
            {navigationItems.map((item: any) => {
              if (
                isNavigationItemVisible(item, isLoggedUser, athlete) &&
                (!item.bottomMenuAction || (item.bottomMenuAction && isLoggedUser))
              ) {
                return (
                  <BottomNavigationAction
                    key={item.value}
                    className={classes.navigationMenuMobileAction}
                    label={item.label}
                    value={item.value}
                    icon={item.icon}
                    onClick={handleBottomNavigationButtonClick.bind(null, item)}
                  />
                );
              }
            })}
          </BottomNavigation>
        </Hidden>
      </div>

      {/* Toast to allow Athlete to publish the page */}
      <Snackbar
        open={showPublish}
        autoHideDuration={10000}
        onClose={() => {
          setShowPublish(false);
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          className={classes.publishButtonAlert}
          severity="success"
          variant="filled"
          elevation={6}
          action={
            <Button color="inherit" size="small" onClick={handlePublishClick}>
              Publish
            </Button>
          }
        >
          Your profile is ready !
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AthleteProfilePage;

function isNavigationItemVisible(item: any, isLoggedUser: boolean, user?: User) {
  if ((!item.public && isLoggedUser) || item.public) {
    if (!item.isVisible || (item.isVisible && item.isVisible(user))) {
      return true;
    }
  }

  return false;
}

function isViewValid(view: string, isLoggedUser: boolean, athlete?: User) {
  const navigationItem = navigationItems.find((i: any) => i.value === view);

  if (navigationItem && isNavigationItemVisible(navigationItem, isLoggedUser, athlete)) {
    return true;
  }

  return false;
}
