import React, { useContext, useState, useEffect } from 'react';
import { makeRequired, makeValidate } from 'mui-rff';
import * as Yup from 'yup';
import { Form, FormSpy } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import * as DateFns from 'date-fns';

import PaperFormElem from '../components/ui/PaperFormElem';
import PaperForm from '../components/ui/PaperForm';
import SubmitButton from '../components/ui/SubmitButton';
import PaperFormActions from '../components/ui/PaperFormActions';
import FormTextField from '../components/ui/FormTextField';
import { countryToFlag, removeNullAttributes } from '../core/helpers/misc';
import { User } from '../core/graphql/types';
import { updateUser } from '../core/services/userSvc';
import FormDatePicker from '../components/ui/FormDatePicker';
import { countries, genders, sports, sportsObj } from '../core/helpers/selectOptions';
import FormSelect from '../components/ui/FormSelect';
import FormAutocomplete from '../components/ui/FormAutocomplete';
import FormRow from '../components/ui/FormRow';
import AgeBlockerDialog from '../components/dialogs/AgeBlockerDialog';
import { appStore } from '../core/stores/app';
import EditableAvatar, { AvatarUpdate } from './EditableAvatar';
import EditableCoverImage, { CoverImageUpate } from './EditableCoverImage';
import { getAthleteProfileCompletion, UserProfileMissingAttribs } from '../core/helpers/user';

const useStyles = makeStyles((theme) => ({
  countryOption: {
    fontSize: 15,
    '& > span': {
      marginRight: theme.spacing(1),
      fontSize: 18,
    },
  },
  coverImageWrapper: {
    position: 'relative',
    height: '200px',
  },
}));

const formSchema = Yup.object().shape({
  givenName: Yup.string().required('Name is a required field.'),
  familyName: Yup.string().required('Last name is a required field.'),
  gender: Yup.string(),
  birthdate: Yup.string()
    .test('len', 'This is not a valid date', (val) => {
      return val !== 'Invalid Date';
    })
    .nullable(true),
  country: Yup.string(),
  sport: Yup.string(),
  altSport: Yup.string().when('sport', {
    is: 'different',
    then: (fieldSchema: any) => fieldSchema.required('You have to specify your sport'),
  }),
});

const validate = makeValidate(formSchema);
const required = makeRequired(formSchema);

export interface AthleteProfileBasicsFormData {
  givenName: string;
  familyName?: string;
  gender?: string;
  birthdate?: string | null;
  country?: string;
  sport?: string;
  altSport?: string;
}

export interface AthleteProfileBasicsUpdate extends AthleteProfileBasicsFormData {
  profileImgS3Key?: string;
  coverImgS3Key?: string;
}

interface Props {
  user?: User;
  showSport?: boolean;
  showCoverImage?: boolean;
  onUserUpdated?: (update: AthleteProfileBasicsUpdate) => void;
}

const AthleteProfileBasics: React.FC<Props> = ({
  user,
  showSport = true,
  showCoverImage = true,
  onUserUpdated,
}: Props) => {
  const classes = useStyles();
  const [, appDispatch] = useContext(appStore);
  const subscription = { pristine: true, invalid: true };
  const [submitting, setSubmitting] = useState(false);
  const [altSportSelected, setAltSportSelected] = useState(false);
  const [update, setUpdate] = useState<AthleteProfileBasicsUpdate | undefined>();
  const [ageFragment, setAgeFrament] = useState<'below_13' | 'below_18' | null>(null);
  const [accountMissingAttribs, setAccountMissingAttribs] = useState<UserProfileMissingAttribs>({});
  const [unmounted, setUnmounted] = useState(false);
  const initialValues = removeNullAttributes({
    givenName: user?.givenName,
    familyName: user?.familyName,
    gender: user?.gender,
    birthdate: user?.birthdate,
    sport: user?.sport,
    country: user?.country,
  });
  const formId = 'athlete-basics-form';
  const today = new Date();
  const handleCoverImageUpdated = (updateObj: CoverImageUpate) => {
    appDispatch({
      type: 'showToast',
      toastTxt: 'Cover image saved successfully',
      toastSeverity: 'success',
    });

    if (user && onUserUpdated) {
      onUserUpdated({ givenName: user.givenName, ...updateObj });
    }
  };
  const handleProfileImageUpdated = (updateObj: AvatarUpdate) => {
    appDispatch({
      type: 'showToast',
      toastTxt: 'Profile image saved successfully',
      toastSeverity: 'success',
    });

    if (user && onUserUpdated) {
      onUserUpdated({ givenName: user.givenName, ...updateObj });
    }
  };
  const submitUpdate = async (updateObj?: AthleteProfileBasicsUpdate) => {
    const theUpdate = updateObj || update;

    if (user && theUpdate) {
      setSubmitting(true);
      await updateUser(user.id, theUpdate, appDispatch);
      setSubmitting(false);

      if (onUserUpdated) {
        onUserUpdated(theUpdate);
      }
    }
  };
  const handleFormChange = ({ values }: any) => {
    setTimeout(() => {
      const isAltSport = values.sport === 'different';

      setAltSportSelected(isAltSport);
    }, 0);
  };
  const handleSubmitClick = async (values: AthleteProfileBasicsFormData) => {
    // Prepare the update object
    const { altSport, ...updateObj } = { ...values, name: `${values.givenName} ${values.familyName}` };

    if (updateObj.sport === 'different') {
      if (altSport) {
        updateObj.sport = altSport;
      }
    }

    setUpdate(updateObj);

    // Check the Athlete is in legal age
    if (values.birthdate) {
      const limit13Date = DateFns.subYears(today, 13);
      const limit118ate = DateFns.subYears(today, 18);

      if (new Date(values.birthdate) > limit13Date) {
        setAgeFrament('below_13');
        return;
      } else if (new Date(values.birthdate) > limit118ate) {
        setAgeFrament('below_18');
        return;
      }
    }

    // Submit the update
    submitUpdate(updateObj);
  };
  const handleAgeBlockerNoClick = () => {
    setAgeFrament(null);
  };
  const handleAgeBlockerYesClick = () => {
    setAgeFrament(null);
    submitUpdate();
  };
  const handleAgeBlockerCloseClick = () => {
    setAgeFrament(null);
  };

  // If the sport is not listed
  if (initialValues.sport && !sportsObj[initialValues.sport]) {
    initialValues.altSport = initialValues.sport;
    initialValues.sport = 'different';
  }

  useEffect(() => {
    if (!unmounted && user) {
      const profileCompletion = getAthleteProfileCompletion(user);

      setAccountMissingAttribs(profileCompletion.missingAttribs);
    }

    return () => {
      setUnmounted(true);
    };
  }, [user]);

  return (
    <>
      <Form
        onSubmit={handleSubmitClick}
        initialValues={initialValues}
        subscription={subscription}
        validate={validate}
        key={subscription as any}
        render={({ form, handleSubmit, pristine, invalid }) => (
          <form id={formId} onSubmit={handleSubmit} noValidate={true} autoComplete="new-password">
            <FormSpy subscription={{ values: true }} onChange={handleFormChange} />
            <PaperForm
              actions={
                <PaperFormActions>
                  <Grid container spacing={1} justifyContent="flex-end">
                    <Grid item>
                      <SubmitButton
                        disabled={invalid || submitting || pristine}
                        submitting={false}
                        color="default"
                        type="button" // to avoid the form being submitted
                        onClick={form.reset}
                      >
                        Cancel
                      </SubmitButton>
                    </Grid>
                    <Grid item>
                      <SubmitButton disabled={invalid || submitting || pristine} submitting={submitting}>
                        Save changes
                      </SubmitButton>
                    </Grid>
                  </Grid>
                </PaperFormActions>
              }
            >
              {/* Cover image */}
              {showCoverImage && (
                <PaperFormElem title="Cover image" missing={Boolean(accountMissingAttribs['coverImgS3Key'])}>
                  <Box className={classes.coverImageWrapper} width={1}>
                    <EditableCoverImage user={user} onUpdated={handleCoverImageUpdated} />
                  </Box>
                </PaperFormElem>
              )}
              {/* Profile image */}
              <PaperFormElem title="Profile picture" missing={Boolean(accountMissingAttribs['profileImgS3Key'])}>
                <Box width={1} display="flex" justifyContent="flex-start">
                  <EditableAvatar user={user} onUpdated={handleProfileImageUpdated} />
                </Box>
              </PaperFormElem>
              {/* Given name */}
              <PaperFormElem title="First name" missing={Boolean(accountMissingAttribs['givenName'])}>
                <FormTextField
                  id="givenName"
                  name="givenName"
                  margin="none"
                  required={required.givenName}
                  disabled={submitting}
                />
              </PaperFormElem>
              {/* Family name */}
              <PaperFormElem title="Last name" missing={Boolean(accountMissingAttribs['familyName'])}>
                <FormTextField
                  id="familyName"
                  name="familyName"
                  margin="none"
                  required={required.familyName}
                  disabled={submitting}
                />
              </PaperFormElem>
              {/* Gender */}
              <PaperFormElem title="Gender" missing={Boolean(accountMissingAttribs['gender'])}>
                <FormSelect
                  id="gender"
                  name="gender"
                  margin="none"
                  disabled={Boolean(submitting)}
                  required={required.gender}
                  data={genders}
                  multiple={false}
                />
              </PaperFormElem>
              {/* Date of birth */}
              <PaperFormElem title="Date of birth" missing={Boolean(accountMissingAttribs['birthdate'])}>
                <FormDatePicker
                  id="birthdate"
                  name="birthdate"
                  margin="none"
                  maxDate={today}
                  initialFocusedDate={new Date(today.getFullYear() - 18, today.getMonth(), today.getDate())}
                  disabled={submitting}
                  required={required.birthdate}
                />
              </PaperFormElem>
              {/* Country */}
              <PaperFormElem title="Country" missing={Boolean(accountMissingAttribs['country'])}>
                <FormAutocomplete
                  id="country"
                  name="country"
                  margin="none"
                  required={required.country}
                  disabled={Boolean(submitting)}
                  options={countries}
                  getOptionValue={(option: any) => option.value}
                  getOptionLabel={(option: any) => option.label}
                  renderOption={(option: any) => (
                    <div className={classes.countryOption}>
                      <span>{countryToFlag(String(option.value))}</span>
                      {option.label}
                    </div>
                  )}
                />
              </PaperFormElem>
              {/* Sport */}
              {showSport && (
                <PaperFormElem title="Sport" missing={Boolean(accountMissingAttribs['sport'])}>
                  <FormAutocomplete
                    id="sport"
                    name="sport"
                    margin="none"
                    required={required.sport}
                    disabled={Boolean(submitting)}
                    options={sports}
                    getOptionValue={(option: any) => option.value}
                    getOptionLabel={(option: any) => option.label}
                    renderOption={(option: any) => <div>{option.label}</div>}
                  />
                  {/* AltSport */}
                  {altSportSelected && (
                    <Box mt={2}>
                      <FormRow label="What's your sport?">
                        <FormTextField id="altSport" name="altSport" required={required.altSport} />
                      </FormRow>
                    </Box>
                  )}
                </PaperFormElem>
              )}
            </PaperForm>
          </form>
        )}
      />

      {ageFragment && (
        <AgeBlockerDialog
          open={Boolean(ageFragment)}
          ageFragment={ageFragment}
          onNoClick={handleAgeBlockerNoClick}
          onYesClick={handleAgeBlockerYesClick}
          onClose={handleAgeBlockerCloseClick}
        />
      )}
    </>
  );
};

export default AthleteProfileBasics;
