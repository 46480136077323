import React, { useContext, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { useTheme } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';

import { AthleteSubscription, User, userRoles } from '../../core/graphql/types';
import { appStore } from '../../core/stores/app';
import SpinnerBox from '../../components/ui/SpinnerBox';
import CompactPageLayout from '../../components/layouts/CompactPageLayout';
import { becomeAthleteRoute, getAthleteProfileRoute } from '../../core/helpers/route';
import domHelpers from '../../core/helpers/dom';
import SubscriptionsList from '../../components/elements/SubscriptionsList';
import AthleteSearch from '../../components/elements/AthleteSearch';
import EditableUserBasics from '../../containers/EditableUserBasics';
import ActionConfirmationDialog from '../../components/dialogs/ActionConfirmationDialog';
import SubmitButton from '../../components/ui/SubmitButton';
import EmailValidator from '../../containers/EmailValidator';
import { fetchAthleteSubscriptionsBySubscriber } from '../../core/services/postsSvc';
import { authStore } from '../../core/stores/auth';
import FanFeed from '../../components/elements/FanFeed';
import { updateUser } from '../../core/services/userSvc';

const ColumnCell: React.FC<{ children: React.ReactNode | React.ReactNode[]; noPadding?: boolean }> = ({
  children,
  noPadding,
}: {
  children: React.ReactNode | React.ReactNode[];
  noPadding?: boolean;
}) => {
  return (
    <Box width={1} p={noPadding ? 0 : 2}>
      {children}
    </Box>
  );
};

const FanDashboardPage: React.FC = () => {
  const history = useHistory();
  const [authState] = useContext(authStore);
  const [appState, appDispatch] = useContext(appStore);
  const theme = useTheme();
  const isSmallScreen = domHelpers.isSmallScreen(useMediaQuery, theme);
  const [searchValue, setSearchValue] = useState<User | null>(null);
  const [athleteQuestionDialogOpen, setAthleteQuestionDialogOpen] = useState(false);
  const [subscriptions, setSubscriptions] = useState<AthleteSubscription[] | undefined>();
  const handleUserSearchSelect = (user: User) => {
    setSearchValue(user);
    history.push(getAthleteProfileRoute(user));
  };
  const handleAthleteQuestionConfirm = async () => {
    // Store answer so we don't ask again in case of being an athlete
    if (appState.user) {
      setAthleteQuestionDialogOpen(false);
      await updateUser(appState.user.id, { potentialAthlete: true }, appDispatch, false);
      history.push(becomeAthleteRoute);
    }
  };
  const handleAthleteQuestionCancel = async () => {
    if (appState.user) {
      setAthleteQuestionDialogOpen(false);
      await updateUser(appState.user.id, { potentialAthlete: false }, appDispatch, false);
    }
  };
  const handleBecomeAnAthleteClick = () => {
    // If the athlete already declared to go for the top
    if (appState.user && appState.user.role !== userRoles.athlete && appState.user.potentialAthlete) {
      history.push(becomeAthleteRoute);
    } else {
      setAthleteQuestionDialogOpen(true);
    }
  };

  useEffect(() => {
    // Check if we already asked the user if he/she is an Athlete
    if (appState.user && !(appState.user.potentialAthlete === true || appState.user.potentialAthlete === false)) {
      setAthleteQuestionDialogOpen(true);
    }
  }, [appState.user]);

  useEffect(() => {
    (async function () {
      if (authState.user) {
        const res = await fetchAthleteSubscriptionsBySubscriber(authState.user.userId);

        if (res) {
          setSubscriptions(res.athleteSubscriptions);
        }
      }
    })();
  }, [authState.user]);

  return (
    <CompactPageLayout>
      <Box width={1} pl={isSmallScreen ? 0 : 2} pr={isSmallScreen ? 0 : 2} pt={2} pb={2}>
        {!appState.user && <SpinnerBox />}
        {appState.user && (
          <Grid container spacing={2}>
            {/* Left column */}
            <Grid item xs={12} sm={3}>
              <Paper variant="outlined">
                {/* User */}
                <ColumnCell>
                  <EditableUserBasics user={appState.user} />
                </ColumnCell>
                {/* Supported Athletes */}
                <Divider />
                <ColumnCell>
                  <Typography variant="body1" align="center">
                    <b>YOU'RE FAN OF</b>
                  </Typography>
                </ColumnCell>
                <Divider />
                <ColumnCell>
                  <SubscriptionsList subscriptions={subscriptions} />
                </ColumnCell>
              </Paper>
            </Grid>

            {/* Center column */}
            <Grid item xs={12} sm={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Paper elevation={2}>
                    <Box p={2}>
                      <AthleteSearch
                        value={searchValue}
                        inverted={false}
                        placeHolder="Become fan of athletes"
                        onSelect={handleUserSearchSelect}
                      />
                    </Box>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper elevation={0}>
                    <ColumnCell noPadding={true}>
                      <Typography variant="h6" align="center">
                        <b>LATEST POSTS</b>
                      </Typography>
                    </ColumnCell>
                    <Box mb={2}>
                      <Grid container justifyContent="center">
                        <Grid item xs={12} sm={6}>
                          <Divider />
                        </Grid>
                      </Grid>
                    </Box>
                    <ColumnCell noPadding={true}>
                      <FanFeed subscriptions={subscriptions} />
                    </ColumnCell>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>

            {/* Right column */}
            <Grid item xs={12} sm={3}>
              <Grid container spacing={2}>
                {/* Create Channel */}
                <Grid item xs={12}>
                  <Paper variant="outlined">
                    <ColumnCell>
                      <Typography variant="body1">
                        <b>CREATE YOUR CHANNEL</b>
                      </Typography>
                    </ColumnCell>
                    <Divider />
                    <ColumnCell>
                      <Typography variant="body1">You're almost there! Complete your channel and go live.</Typography>
                      <Box mt={2} display="flex" justifyContent="center">
                        <SubmitButton originalSize onClick={handleBecomeAnAthleteClick}>
                          Complete your page
                        </SubmitButton>
                      </Box>
                    </ColumnCell>
                  </Paper>
                </Grid>
                {/* Validate Email */}
                <Grid item xs={12}>
                  <Paper variant="outlined">
                    <ColumnCell>
                      <Typography variant="body1">
                        <b>VALIDATE YOUR EMAIL</b>
                      </Typography>
                    </ColumnCell>
                    <Divider />
                    <ColumnCell>
                      <EmailValidator />
                    </ColumnCell>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Box>

      {/* Are you an athlete dialog */}
      {athleteQuestionDialogOpen && (
        <ActionConfirmationDialog
          open={athleteQuestionDialogOpen}
          title={'Are you a top athlete?'}
          description={
            'On Peakz you can create a Peakz Channel if you are an athlete going for the top in your sports. Are you?'
          }
          descriptionAlign="left"
          onYesClick={handleAthleteQuestionConfirm}
          onNoClick={handleAthleteQuestionCancel}
          onClose={handleAthleteQuestionCancel}
        />
      )}
    </CompactPageLayout>
  );
};

export default FanDashboardPage;
