import React, { useState, useEffect, useContext, useMemo } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { createBcast, updateBcast, deleteBcast } from '../../../graphql/mutations';
import { getBcast } from '../../../graphql/queries';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { getBcastRoute } from '../../../core/helpers/route';
import { authStore } from '../../../core/stores/auth';

import domHelpers from '../../../core/helpers/dom';

import { v4 as uuidv4 } from 'uuid';
import GuestList from './GuestList';
import BcastView from '../postType/BcastView';

import { Bcast, BcastStatus, PostType } from '../../../API';
import { uploadImgBase64 } from '../../../core/services/imgSvc';
import urlHelpers from '../../../core/helpers/url';
import { awsExports, baseUrl } from '../../../config';
import { AddPhotoAlternate } from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import PostStep1 from '../../../pages/createPostPage/steps/PostStep1';

import {
  Grid,
  Typography,
  Stepper,
  Step,
  StepButton,
  useMediaQuery,
  useTheme,
  Box,
  TextField,
  Slider,
  // Switch,
  // RadioGroup,
  // FormControlLabel,
  // Radio,
  IconButton,
} from '@material-ui/core';
import BasicPageLayout from '../../layouts/BasicPageLayout';
import CharacterCounter from '../../ui/CharacterCounter';
import StyledTextArea from '../../ui/StyledTextArea';
//import SubmitButton from '../../ui/SubmitButton';
import PaperForm from '../../ui/PaperForm';
import PaperFormElem from '../../ui/PaperFormElem';
import PaperFormActions from '../../ui/PaperFormActions';
import FormDateTimePicker from '../../ui/FormDateTimePicker';
import moment from 'moment-timezone';
import ImageCroppingDialog from '../../../containers/dialogs/ImageCroppingDialog';
import SpinnerBox from '../../ui/SpinnerBox';
import { Post } from '../../../core/graphql/types';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

import { Tier } from '../../../core/graphql/types';
import { fetchTiers } from '../../../core/services/subscriptionsSvc';
import { appStore } from '../../../core/stores/app';
import { reportError } from '../../../core/helpers/core';
import CreateTierDialog from '../../../containers/dialogs/CreateTierDialog';
import { getPayoutCurrency } from '../../../core/helpers/user';

// True for testing record subscriptions
const DEBUG = false;
const maxBcastName = 50;
const maxBcastDescription = 400;
const CloseTooltip = withStyles({
  tooltipPlacementBottom: {
    margin: '8px 0',
  },
})(Tooltip);

const BcastForm: React.FC = () => {
  const theme = useTheme();
  const history = useHistory();
  const [authState] = useContext(authStore);
  const isSmallScreen = domHelpers.isSmallScreen(useMediaQuery, theme);

  ////////////
  // db helpers
  // todo: error handling

  // const formSchema = Yup.object().shape({
  //   dateTime: Yup.date().notRequired(),
  // });
  // const required = makeRequired(formSchema);

  const fetchRecord = async (id: string) => {
    const response: any = await API.graphql(graphqlOperation(getBcast, { id: id }));
    return response.data.getBcast;
  };

  const executeMutation = async (mutation: any, record: Bcast) => {
    let input: any;
    switch (mutation) {
      case createBcast:
      case updateBcast:
        input = { ...record };
        delete input.__typename;
        delete input.createdAt;
        delete input.bcastUrl;
        delete input.recordingS3Key;
        delete input.updatedAt;
        input.guests = JSON.stringify(
          input.guests.map((g: any) => {
            delete g.__typename;
            return g;
          }),
        );
        input.prepareArgs = JSON.stringify({
          appsyncEndpoint: awsExports.aws_appsync_graphqlEndpoint,
          apiKey: awsExports.aws_appsync_apiKey,
          baseUrl: baseUrl,
        });
        break;
      case deleteBcast:
        input = { id: record.id };
        break;
    }
    await API.graphql(graphqlOperation(mutation, { input }));
  };

  /////////
  // initialization

  const { id }: any = useParams();
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const isNewRecord = id === 'create' || searchParams.has('duplicate');

  const steps = ['Broadcast info', 'Monetization', 'Scheduled time', 'Invite guests', 'Promotion page', 'Actions'];
  const firstStep = 0;
  const lastStep = steps.length - 1;
  const [activeStep, setActiveStep] = useState(0);
  //const [applyOption, setApplyOption] = useState('');
  // const [detectedProfanity, setDetectedProfanity] = useState(false);
  //2525-05-08T15:16:43Z
  const [record, setRecord] = useState<Bcast>({
    __typename: 'Bcast',
    id: '',
    userId: '',
    title: '',
    description: '',
    tierId: '',
    needsSubscription: true,
    thumbnailImageS3Key: '',
    previewImageS3Key: '',
    time: '',
    duration: '',
    guests: [],
    allowQuestions: false,
    status: BcastStatus.created,
    createdAt: '',
    updatedAt: '',
  });

  const [addThumbnailDialogOpen, setAddThumbnailDialogOpen] = useState(false);
  const [uploadingThumbnailImg, setUploadingThumbnailImg] = useState(false);

  const [addPreviewDialogOpen, setAddPreviewDialogOpen] = useState(false);
  const [uploadingPreviewImg, setUploadingPreviewImg] = useState(false);

  const setInput = async (key: string, value: any) => {
    setRecord({ ...record, [key]: value });
  };

  const currentUserId = authState.user ? authState.user.userId : '';

  useEffect(() => {
    setActiveStep(0);

    if (id === 'create') {
      setRecord({
        ...record,
        id: uuidv4(),
        userId: currentUserId,
        time: moment().add(7, 'days').toISOString(),
        duration: '01:00',
        status: BcastStatus.created,
      });
    } else if (searchParams.has('duplicate')) {
      fetchRecord(id).then((record) =>
        setRecord({
          ...record,
          id: uuidv4(),
          time: moment().add(1, 'days').toISOString(),
          duration: '01:00',
          status: BcastStatus.created,
        }),
      );
    } else {
      fetchRecord(id).then((record) => setRecord(record));
    }
  }, [id, searchParams]);

  // // set default apply option
  // useEffect(() => {
  //   switch (record.status) {
  //     case BcastStatus.created:
  //       setApplyOption('save');
  //       break;
  //     case BcastStatus.scheduled:
  //       setApplyOption('publish');
  //       break;
  //     default:
  //       setApplyOption('');
  //       break;
  //   }
  // }, [record.status]);

  const [previewPost, setPreviewPost] = useState<Post | undefined>(undefined);

  useEffect(() => {
    const post: Post = {
      id: '',
      authorId: '',
      name: record.title,
      description: record.description || '',
      type: PostType.bcast,
      thumbS3Key: record.thumbnailImageS3Key || '',
      tierId: '',
      needsSubscription: true,
      comments: 0,
      reviewStatus: 'accepted',
      createdAt: '',
      bcastMetadata: {
        __typename: 'PostBcastMetadata',
        previewImageS3Key: record.previewImageS3Key,
        time: record.time,
        duration: record.duration,
        guests: [],
        allowQuestions: record.allowQuestions,
        status: BcastStatus.scheduled, // !
        bcastUrl: record.bcastUrl,
        recordingS3Key: record.recordingS3Key,
      },
    };
    setPreviewPost(post);
  }, [record]);

  //////////////////
  // events

  const setpostNeedsSubscription = (needsSubscription: boolean) => {
    setInput('needsSubscription', needsSubscription);
  };

  const handleBackClick = () => {
    setActiveStep(activeStep - 1);
  };

  const handleNextClick = () => {
    setActiveStep(activeStep + 1);
  };

  const handleStepClick = (step: number) => {
    setActiveStep(step);
  };

  const handleDateTimeSelected = (dateTime: any) => {
    setInput('time', dateTime.toISOString());
  };

  const handleActionsClick = async (action: string) => {
    switch (action) {
      case 'save':
        if (isNewRecord) {
          executeMutation(createBcast, record);
        } else {
          executeMutation(updateBcast, record);
        }
        history.goBack();
        break;

      case 'publish':
        if (isNewRecord) {
          executeMutation(createBcast, { ...record, status: BcastStatus.scheduled });
        } else {
          executeMutation(updateBcast, { ...record, status: BcastStatus.scheduled });
        }
        history.goBack();
        break;

      case 'duplicate':
        history.push(getBcastRoute(record.id) + '?duplicate');
        break;

      case 'delete':
        if (!isNewRecord) {
          executeMutation(deleteBcast, record);
        }
        history.goBack();
        break;

      default:
        if (DEBUG) {
          executeMutation(updateBcast, record);
        } else {
          alert('error, unhandled');
        }
    }
  };

  // const handleApplyClick = async () => {
  //   switch (applyOption) {
  //     case 'save':
  //       if (isNewRecord) {
  //         executeMutation(createBcast, record);
  //       } else {
  //         executeMutation(updateBcast, record);
  //       }
  //       history.goBack();
  //       break;

  //     case 'publish':
  //       if (isNewRecord) {
  //         executeMutation(createBcast, { ...record, status: BcastStatus.scheduled });
  //       } else {
  //         executeMutation(updateBcast, { ...record, status: BcastStatus.scheduled });
  //       }
  //       history.goBack();
  //       break;

  //     case 'duplicate':
  //       history.push(getBcastRoute(record.id) + '?duplicate');
  //       break;

  //     case 'delete':
  //       if (!isNewRecord) {
  //         executeMutation(deleteBcast, record);
  //       }
  //       history.goBack();
  //       break;

  //     default:
  //       if (DEBUG) {
  //         executeMutation(updateBcast, record);
  //       } else {
  //         alert('error, unhandled');
  //       }
  //   }
  // };

  const handleSaveAsDraft = () => {
    // setApplyOption('save');
    handleActionsClick('save');
  };

  const handlePublishBroadcast = () => {
    // setApplyOption('publish');
    handleActionsClick('publish');
  };

  const handleDuplicateBroadcast = () => {
    // setApplyOption('duplicate');
    handleActionsClick('duplicate');
  };

  const handleDeleteBroadcast = async () => {
    // setApplyOption('delete');
    handleActionsClick('delete');
  };

  const getDurationValueLabel = (value: number) => {
    return value + 'min';
  };

  const handleDurationChange = (event: React.ChangeEvent<unknown>, newValue: number | number[]) => {
    setInput(
      'duration',
      Math.floor(parseInt(newValue.toString()) / 60)
        .toString()
        .padStart(2, '0') +
        ':' +
        (parseInt(newValue.toString()) % 60).toString().padStart(2, '0'),
    );
  };

  const handleThumbnailImageCropSubmit = async (imgBase64: string) => {
    setAddThumbnailDialogOpen(false);
    if (authState.userInfo) {
      setUploadingThumbnailImg(true);
      const imgS3Key = await uploadImgBase64(
        urlHelpers.createBcastImgS3Key('thumbnail'),
        authState.userInfo.userIdentityId,
        imgBase64,
        'protected',
      );

      setUploadingThumbnailImg(false);
      setInput('thumbnailImageS3Key', imgS3Key);
    }
  };
  const handleThumbnailImageCropCancel = () => {
    setAddThumbnailDialogOpen(false);
  };

  const handlePreviewImageCropSubmit = async (imgBase64: string) => {
    setAddPreviewDialogOpen(false);
    if (authState.userInfo) {
      setUploadingPreviewImg(true);
      const imgS3Key = await uploadImgBase64(
        urlHelpers.createBcastImgS3Key('preview'),
        authState.userInfo.userIdentityId,
        imgBase64,
        'protected',
      );

      setUploadingPreviewImg(false);
      setInput('previewImageS3Key', imgS3Key);
    }
  };
  const handlePreviewImageCropCancel = () => {
    setAddPreviewDialogOpen(false);
  };

  const durationMarks = [
    {
      value: 0,
      label: '0 min',
    },
    {
      value: 30,
      label: '30 min',
    },
    {
      value: 60,
      label: '60 min',
    },
    {
      value: 90,
      label: '90 min',
    },
  ];

  const getTumbnailUrl = () =>
    record && record.thumbnailImageS3Key
      ? urlHelpers.getMediaUrlFromS3Key(record.thumbnailImageS3Key, 'img')
      : undefined;

  const getPreviewlUrl = () =>
    record && record.previewImageS3Key ? urlHelpers.getMediaUrlFromS3Key(record.previewImageS3Key, 'img') : undefined;

  //////////////////
  // Tiers
  // Todo: This is temporary solution and should be re-implemented when decided how tiers work
  // It insures existing at least one tier before Bcast record is creaded
  // Id of the first tier is written in newly Bcast record and is copied to the Post record
  // automatically when the boradcast finishes.
  // Note: most of the code is copied from src/pages/createPostPage/CreatePostPage.tsx
  const [createTierDialogOpen, setCreateTierDialogOpen] = useState(false);
  const [appState] = useContext(appStore);

  useEffect(() => {
    const setTier = async () => {
      try {
        const tiers = await fetchTiers(currentUserId);

        if (tiers.length > 0) {
          const tier = tiers[0];
          setInput('tierId', tier.id);
        } else {
          // OPEN PAYMENT DIALOG HERE
          setCreateTierDialogOpen(true);
        }
      } catch (err) {
        alert('There has been a problem creating the Tier');
        reportError('create_bcast', 'load_tiers', err);
      }
    };

    if (activeStep === lastStep && isNewRecord && record.tierId === '') {
      setTier();
    }
  }, [activeStep]);

  const handleTierCreated = (tier: Tier) => {
    setInput('tierId', tier.id);
    setCreateTierDialogOpen(false);
  };

  return (
    <BasicPageLayout>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom align="center">
            Create broadcasts
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Stepper
            activeStep={activeStep}
            nonLinear={!isNewRecord}
            orientation={isSmallScreen ? 'vertical' : 'horizontal'}
            alternativeLabel={!isSmallScreen}
          >
            {steps.map((label, index) => (
              <Step key={label}>
                <StepButton onClick={() => handleStepClick(index)}>{label}</StepButton>
              </Step>
            ))}
          </Stepper>
        </Grid>

        <Grid item xs={12}>
          <Box p={3} pb={0}>
            <PaperForm
              actions={
                <PaperFormActions>
                  <Grid container alignItems="center" justifyContent="center">
                    {activeStep > firstStep && (
                      <Grid item xs={12} md={1}>
                        <Box p={3} pb={0} justifyContent="center" display="flex">
                          <Button variant="contained" color="primary" fullWidth onClick={handleBackClick}>
                            Back
                          </Button>
                          &ensp;
                        </Box>
                      </Grid>
                    )}
                    {activeStep < lastStep && (
                      <Grid item xs={12} md={1}>
                        <Box p={3} pb={0} justifyContent="center" display="flex">
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={handleNextClick}
                            disabled={record.title === ''}
                          >
                            Next
                          </Button>
                          &ensp;
                        </Box>
                      </Grid>
                    )}
                    {activeStep === lastStep && record.status === BcastStatus.created && (
                      <Grid item xs={12} md={4}>
                        <Box p={3} pb={0} justifyContent="center" display="flex">
                          <Button
                            variant="contained"
                            color="secondary"
                            fullWidth
                            onClick={handleSaveAsDraft}
                            disabled={record.title === ''}
                          >
                            Save (without publishing)
                          </Button>
                          &ensp;
                        </Box>
                      </Grid>
                    )}
                    {activeStep === lastStep && record.status !== BcastStatus.finished && (
                      <Grid item xs={12} md={2}>
                        <Box p={3} pb={0} justifyContent="center" display="flex">
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={handlePublishBroadcast}
                            disabled={record.title === ''}
                          >
                            Publish
                          </Button>
                          &ensp;
                        </Box>
                      </Grid>
                    )}
                    {activeStep === lastStep && !isNewRecord && (
                      <Grid item xs={12} md={2}>
                        <Box p={3} pb={0} justifyContent="center" display="flex">
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={handleDuplicateBroadcast}
                            disabled={record.title === ''}
                          >
                            Duplicate
                          </Button>
                          &ensp;
                        </Box>
                      </Grid>
                    )}
                    {activeStep === lastStep && !isNewRecord && (
                      <Grid item xs={12} md={3}>
                        <Box p={3} pb={0} justifyContent="center" display="flex">
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={handleDeleteBroadcast}
                            disabled={record.title === ''}
                          >
                            Delete
                          </Button>
                          &ensp;
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </PaperFormActions>
              }
            >
              {/* info step */}
              {activeStep === 0 && (
                <>
                  <PaperFormElem title="Title">
                    <TextField
                      variant="outlined"
                      fullWidth
                      margin="dense"
                      autoFocus={true}
                      InputProps={{
                        inputProps: {
                          onChange: (event: any) => {
                            setInput('title', event.target.value);
                          },
                        },
                      }}
                      value={record.title || ''}
                      error={record.title === ''}
                      helperText={record.title === '' ? 'Title is a required field.' : ' '}
                    />
                    <CharacterCounter chars={record.title.length} max={maxBcastName} />
                  </PaperFormElem>
                  <PaperFormElem title="Description">
                    <StyledTextArea
                      minRows={5}
                      maxRows={8}
                      onChange={(event: any) => {
                        setInput('description', event.target.value);
                      }}
                      value={record.description || ''}
                    />
                    <CharacterCounter chars={(record.duration || '').length} max={maxBcastDescription} />
                  </PaperFormElem>
                  <PaperFormElem title="Thumbnail image">
                    <>
                      {record.thumbnailImageS3Key && (
                        <CloseTooltip
                          placement="bottom"
                          title="Click to change the thumbnail image"
                          aria-label="Click to change the thumbnail image"
                          arrow
                        >
                          <img
                            src={getTumbnailUrl()}
                            onClick={() => {
                              setAddThumbnailDialogOpen(true);
                            }}
                            width="128px"
                          />
                        </CloseTooltip>
                      )}
                      {!record.thumbnailImageS3Key && (
                        <CloseTooltip
                          placement="bottom"
                          title="Click here to upload the thumbnail image"
                          aria-label="Click here to upload the thumbnail image"
                          arrow
                        >
                          <IconButton
                            size="medium"
                            onClick={() => {
                              setAddThumbnailDialogOpen(true);
                            }}
                          >
                            <AddPhotoAlternate fontSize="large" />
                          </IconButton>
                        </CloseTooltip>
                      )}

                      {/* <TextField
                      variant="outlined"
                      fullWidth
                      margin="dense"
                      autoFocus={true}
                      InputProps={{
                        inputProps: {
                          onChange: (event: any) => {
                            setInput('thumbnailImageS3Key', event.target.value);
                          },
                        },
                      }}
                      value={record.thumbnailImageS3Key || ''}
                      onClick={() => {
                        setAddThumbnailDialogOpen(true);
                      }}
                    /> */}
                    </>
                  </PaperFormElem>
                  <ImageCroppingDialog
                    title="Upload thumbnail image"
                    open={addThumbnailDialogOpen}
                    imgUrl={''}
                    aspect={6 / 3}
                    onSubmit={handleThumbnailImageCropSubmit}
                    onCancel={handleThumbnailImageCropCancel}
                    onClose={handleThumbnailImageCropCancel}
                  ></ImageCroppingDialog>
                  {uploadingThumbnailImg && (
                    <Grid item xs={6}>
                      <SpinnerBox />
                    </Grid>
                  )}
                </>
              )}

              {/* monetization */}
              {activeStep === 1 && <PostStep1 setPostNeedsSubscription={setpostNeedsSubscription} />}

              {/* time step */}
              {activeStep === 2 && (
                <>
                  <PaperFormElem title="Planned start time">
                    <FormDateTimePicker
                      onChange={handleDateTimeSelected}
                      value={record.time}
                      minDate={moment().add(30, 'minutes')}
                      maxDate={moment().add(2, 'months')}
                      disablePast
                    />
                  </PaperFormElem>
                  <PaperFormElem title="Planned duration">
                    <Slider
                      value={moment.duration(record.duration || '00:00').asMinutes()}
                      getAriaValueText={getDurationValueLabel}
                      valueLabelDisplay="on"
                      step={5}
                      marks={durationMarks}
                      min={5}
                      max={90}
                      onChange={handleDurationChange}
                    />
                  </PaperFormElem>
                </>
              )}
              {/* guests step */}
              {activeStep === 3 && (
                <>
                  <Grid container alignItems="center" justifyContent="center">
                    <Grid item xs={12}>
                      <Typography variant="h6" gutterBottom align="left">
                        Participans
                      </Typography>
                    </Grid>
                  </Grid>
                  <GuestList record={record} onChange={(value) => setInput('guests', value)} />
                </>
              )}
              {/* promotion step */}
              {activeStep === 4 && (
                <>
                  {/* <PaperFormElem title="Allow fans questions">
                    <Switch
                      checked={record.allowQuestions}
                      onChange={(event: any) => setInput('allowQuestions', event.target.checked)}
                      name="allowFunQuestions"
                      color="default"
                    />
                  </PaperFormElem> */}
                  <PaperFormElem title="Banner image">
                    <>
                      {record.previewImageS3Key && (
                        <CloseTooltip
                          placement="bottom"
                          title="Click to change the preview image"
                          aria-label="Click to change the preview image"
                          arrow
                        >
                          <img
                            src={getPreviewlUrl()}
                            onClick={() => {
                              setAddPreviewDialogOpen(true);
                            }}
                            width="128px"
                          />
                        </CloseTooltip>
                      )}
                      {!record.previewImageS3Key && (
                        <CloseTooltip
                          placement="bottom"
                          title="Click here to upload the preview image"
                          aria-label="Click here to upload the preview image"
                          arrow
                        >
                          <IconButton
                            size="medium"
                            onClick={() => {
                              setAddPreviewDialogOpen(true);
                            }}
                          >
                            <AddPhotoAlternate fontSize="large" />
                          </IconButton>
                        </CloseTooltip>
                      )}{' '}
                    </>
                    {/* <TextField
                      variant="outlined"
                      fullWidth
                      margin="dense"
                      autoFocus={true}
                      InputProps={{
                        inputProps: {
                          onChange: (event: any) => {
                            setInput('previewImageS3Key', event.target.value);
                          },
                        },
                      }}
                      value={record.previewImageS3Key || ''}
                      onClick={() => {
                        setAddPreviewDialogOpen(true);
                      }}
                    /> */}
                  </PaperFormElem>
                  <ImageCroppingDialog
                    title="Upload preview image"
                    open={addPreviewDialogOpen}
                    imgUrl={''}
                    aspect={6 / 3}
                    onSubmit={handlePreviewImageCropSubmit}
                    onCancel={handlePreviewImageCropCancel}
                    onClose={handlePreviewImageCropCancel}
                  ></ImageCroppingDialog>
                  {uploadingPreviewImg && (
                    <Grid item xs={6}>
                      <SpinnerBox />
                    </Grid>
                  )}
                  <PaperFormElem title="Page preview">
                    {previewPost && <BcastView post={previewPost} isPreview={true} viewPermission={true} />}
                  </PaperFormElem>
                </>
              )}
              {/* final step */}
              {activeStep === 5 && (
                <>
                  {/* tier */}
                  {appState.user && appState.currenciesConfig && (
                    <CreateTierDialog
                      open={createTierDialogOpen}
                      athlete={appState.user}
                      currency={getPayoutCurrency(appState.user, appState.currenciesConfig)}
                      onTierCreated={handleTierCreated}
                    />
                  )}
                  <Grid container alignItems="center" justifyContent="center">
                    <Grid item xs={12} md={6}>
                      <h4>Name:</h4>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      {record.title || ''}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <h4>Description:</h4>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      {record.description || ''}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <h4>Date:</h4>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      {moment(record.time).toLocaleString() || ''}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <h4>Invited guests:</h4>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <>
                        {record.guests.length === 0 ? (
                          <div>No guests, I will broadcast alone.</div>
                        ) : (
                          record.guests.map((guest) => {
                            return <div key={'guestRow' + guest.id}>{guest.name}</div>;
                          })
                        )}
                      </>
                    </Grid>
                    {/* <Grid item xs={12} md={6}>
                      <h4>Questions from funs:</h4>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <>{record.allowQuestions ? <div>Allowed</div> : <div>Not allowed</div>}</>
                    </Grid> */}
                  </Grid>

                  {/* <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                    onChange={(event) => setApplyOption(event.target.value)}
                  >
                    {record.status === 'created' && (
                      <FormControlLabel
                        value="save"
                        control={<Radio />}
                        label="Save changes without publishing (don't show it to the fans yet)"
                        checked={applyOption === 'save'}
                      />
                    )}
                    {(record.status === BcastStatus.created || record.status === BcastStatus.scheduled) && (
                      <FormControlLabel
                        value="publish"
                        control={<Radio />}
                        label="Publish the broadcast (show it in my channel)"
                        checked={applyOption === 'publish'}
                      />
                    )}
                    {(record.status === BcastStatus.created || record.status === BcastStatus.scheduled) && (
                      <FormControlLabel
                        value="duplicate"
                        control={<Radio />}
                        label="Make a duplicate (a new bradcast will be created using this one as template)"
                        checked={applyOption === 'duplicate'}
                      />
                    )}
                    {(record.status === BcastStatus.created || record.status === BcastStatus.scheduled) && (
                      <FormControlLabel
                        value="delete"
                        control={<Radio />}
                        label="Delete"
                        checked={applyOption === 'delete'}
                      />
                    )}
                  </RadioGroup> */}
                </>
              )}
            </PaperForm>
          </Box>
        </Grid>
      </Grid>
    </BasicPageLayout>
  );
};

export default BcastForm;
