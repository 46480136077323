/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onUpdatePostById = /* GraphQL */ `
  subscription OnUpdatePostById($id: ID!) {
    onUpdatePostById(id: $id) {
      id
      authorId
      name
      description
      type
      tierId
      vodStatus
      vodId
      vodMetadata {
        durationInMs
        orgWidth
        orgHeight
        hasPreview
      }
      bcastMetadata {
        previewImageS3Key
        time
        duration
        guests {
          id
          name
        }
        allowQuestions
        status
        bcastUrl
        recordingS3Key
      }
      createdAt
      updatedAt
    }
  }
`;

export const onAthleteCreation = /* GraphQL */ `
  subscription OnAthleteCreation {
    onAthleteCreation {
      name
      givenName
      familyName
      country
      id
      profileImgS3Key
      nickname
    }
  }
`;
