import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import ImageCroppingDialog from './dialogs/ImageCroppingDialog';
import userHelpers from '../core/helpers/user';

const avatarWidth = 120;
const useStyles = makeStyles((theme) => ({
  editableImage: {
    border: `${theme.typography.pxToRem(4)} solid #fff`,
    width: theme.typography.pxToRem(avatarWidth),
    height: theme.typography.pxToRem(avatarWidth),
  },
  imageEditIcon: {
    border: `${theme.typography.pxToRem(1)} solid ${theme.palette.background.paper}`,
    width: '32px',
    height: '32px',
    '& >  svg': {
      color: theme.palette.text.primary,
    },
  },
}));

export interface ImageUpdate {
  profileImgS3Key?: string;
  src?: string;
}

interface Props {
  src?: string;
  className?: any;
  onUpdated?: (update: ImageUpdate) => void;
}

const EditableImage: React.FC<Props> = ({ className = '', onUpdated, src }: Props) => {
  const classes = useStyles();
  const [submitting, setSubmitting] = useState(false);
  const [imageCroppingDialogOpen, setImageCroppingDialogOpen] = useState<boolean>(false);
  const [croppingImgUrl, setCroppingImgUrl] = useState<string>('');
  const handleAvatarEditClick = () => {
    setCroppingImgUrl(src || '');
    setImageCroppingDialogOpen(true);
  };
  const handleImageCropSubmit = async (profileImgBase64: string) => {
    setSubmitting(true);
    if (onUpdated) {
      onUpdated({
        src: profileImgBase64,
      });
    }
    setSubmitting(false);
    setImageCroppingDialogOpen(false);
  };
  const handleImageCropCancel = () => {
    setImageCroppingDialogOpen(false);
  };

  return (
    <>
      <label htmlFor="upload-profile-img-btn" onClick={handleAvatarEditClick}>
        <Badge
          style={{ cursor: 'pointer' }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          overlap="circular"
          badgeContent={
            <Avatar className={classes.imageEditIcon}>
              <PhotoCameraIcon />
            </Avatar>
          }
        >
          <Avatar className={`${classes.editableImage} ${className ? className : ''}`} alt="avatar" src={src}></Avatar>
        </Badge>
      </label>

      {imageCroppingDialogOpen && (
        <ImageCroppingDialog
          title=""
          open={imageCroppingDialogOpen}
          imgUrl={croppingImgUrl}
          aspect={userHelpers.imgDimensions.profile.width / userHelpers.imgDimensions.profile.height}
          outputWidth={userHelpers.imgDimensions.profile.width}
          outputHeight={userHelpers.imgDimensions.profile.height}
          submitting={submitting}
          onSubmit={handleImageCropSubmit}
          onCancel={handleImageCropCancel}
          onClose={handleImageCropCancel}
        ></ImageCroppingDialog>
      )}
    </>
  );
};

export default EditableImage;
