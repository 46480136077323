import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Box from '@material-ui/core/Box';
import { SelectData } from 'mui-rff';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';

import { countries, sports } from '../../core/helpers/selectOptions';
import { countryToFlag } from '../../core/helpers/misc';
import AthleteAutocomplete from '../../components/elements/AthleteAutocomplete';

const useStyles = makeStyles(() => ({
  athleteFilterRoot: {
    width: '100%', // material-ui: +16px
    margin: 0, // materiail-ui: -8px
    height: 'fit-content',
  },
  countryOption: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
}));

export interface GendersFilter {
  male: boolean;
  female: boolean;
  prefer_not_to_say: boolean;
  other: boolean;
}

export interface Filters {
  name: string;
  country: SelectData | null;
  genders: GendersFilter;
  sports: any;
  delaySearch?: boolean;
}

interface Props {
  filters: Filters;
  onNameChange: (name: string) => void;
  onCountryChange: (country: SelectData | null) => void;
  onGendersChange: (genders: GendersFilter) => void;
  onSportsChange: (sports: any) => void;
}

const FiltersBlock: React.FC<Props> = ({
  filters,
  onNameChange,
  onCountryChange,
  onGendersChange,
  onSportsChange,
}: Props) => {
  const classes = useStyles();
  const filterBoxMarginBottom = 3;
  const handleAthleteNameChange = (name: string) => {
    onNameChange(name);
  };
  const handleCountryChange = (event: any, val: SelectData | null | undefined) => {
    onCountryChange(val ? val : null);
  };
  const handleGenderChange = (gender: string, event: any, val: any) => {
    onGendersChange({ ...filters.genders, [gender]: val });
  };
  const handleSportsChange = (sport: string, event: any, val: any) => {
    onSportsChange({ ...filters.sports, [sport]: val });
  };

  return (
    <div className={classes.athleteFilterRoot}>
      {/* Country */}
      <Box mb={filterBoxMarginBottom}>
        <Typography variant="body1" align="left" gutterBottom>
          <b>Athlete name</b>
        </Typography>
        <AthleteAutocomplete inputValue={filters.name} onValueChange={handleAthleteNameChange} />
      </Box>
      {/* Country */}
      <Box mb={filterBoxMarginBottom}>
        <Typography variant="body1" align="left" gutterBottom>
          <b>Country</b>
        </Typography>
        <Autocomplete
          id="country-select"
          options={countries}
          classes={{
            option: classes.countryOption,
          }}
          autoHighlight
          getOptionLabel={(option) => option.label}
          renderOption={(option) => (
            <React.Fragment>
              <span>{countryToFlag(String(option.value))}</span>
              {option.label}
            </React.Fragment>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Choose a country"
              variant="outlined"
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
            />
          )}
          value={filters.country}
          onChange={handleCountryChange}
        />
      </Box>

      {/* Gender */}
      <Box mb={filterBoxMarginBottom}>
        <Typography variant="body1" align="left" gutterBottom>
          <b>Gender</b>
        </Typography>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={filters.genders['male']}
                onChange={handleGenderChange.bind(null, 'male')}
                name="male"
              />
            }
            label="Male"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={filters.genders['female']}
                onChange={handleGenderChange.bind(null, 'female')}
                name="female"
              />
            }
            label="Female"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={filters.genders['prefer_not_to_say']}
                onChange={handleGenderChange.bind(null, 'prefer_not_to_say')}
                name="prefer_not_to_say"
              />
            }
            label="Prefer not to say"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={filters.genders['other']}
                onChange={handleGenderChange.bind(null, 'other')}
                name="other"
              />
            }
            label="Other"
          />
        </FormGroup>
      </Box>

      {/* Sports */}
      <Box mb={filterBoxMarginBottom}>
        <Typography variant="body1" align="left" gutterBottom>
          <b>Sports</b>
        </Typography>
        <FormGroup>
          {sports.map(
            (sport: SelectData, index) =>
              sport.value !== 'different' && (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      name={String(sport.value)}
                      checked={filters.sports[String(sport.value)] || false}
                      onChange={handleSportsChange.bind(null, String(sport.value))}
                    />
                  }
                  label={sport.label}
                />
              ),
          )}
        </FormGroup>
      </Box>
    </div>
  );
};

export default FiltersBlock;
