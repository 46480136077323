import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

import { PeakzTheme } from '../../theme/theme';

const useStyles = makeStyles((theme: PeakzTheme) => ({
  pageRow: {
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 1, 2),
    },
  },
  pageRowContent: {
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 1, 0),
    },
  },
}));

const PageRow = ({ children, className = '' }: { children: any; className?: any }) => {
  const classes = useStyles();

  return (
    <Grid className={`${classes.pageRow} ${className}`} item xs={12}>
      <Container className={classes.pageRowContent} maxWidth="lg">
        {children}
      </Container>
    </Grid>
  );
};

export default PageRow;
