import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

interface Props {
  text?: string;
  marginSize?: 'normal' | 'none';
  justifyContent?: 'flex-start' | 'center';
  children?: React.ReactNode;
  breadCrumbs?: { title: string; route?: string }[];
}

const PageTitle: React.FC<Props> = ({
  text,
  marginSize = 'normal',
  justifyContent = 'flex-start',
  children,
  breadCrumbs,
}: Props) => {
  const margin = marginSize === 'normal' ? 3 : 0;

  return (
    <Box mt={margin} mb={margin} display="flex" justifyContent={justifyContent}>
      {text && (
        <Typography variant="h5">
          {text}
          {breadCrumbs && (
            <Breadcrumbs aria-label="breadcrumb">
              {breadCrumbs.map((bc) => {
                if (bc.route) {
                  return (
                    <Link key={bc.title} color="inherit" href={bc.route} variant="body2">
                      {bc.title}
                    </Link>
                  );
                }
                return (
                  <Typography key={bc.title} color="textPrimary" variant="body2">
                    {bc.title}
                  </Typography>
                );
              })}
            </Breadcrumbs>
          )}
        </Typography>
      )}
      {children}
    </Box>
  );
};

export default PageTitle;
