import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import PostHeaderBlock from './PostHeaderBlock';
import { Post } from '../../core/graphql/types';
import urlHelpers from '../../core/helpers/url';
import { fetchAthleteSubscription, fetchFullPostById, fetchPostById } from '../../core/services/postsSvc';
import AppHeaderPlaceHolder from '../../components/layouts/AppHeaderPlaceHolder';
import Metadata from '../../core/misc/Metadata';
import { baseUrl } from '../../config';
import { isSubscriptionActive } from '../../core/helpers/athleteSubscription';
import { authStore } from '../../core/stores/auth';
import PostCommentSection from './PostCommentSection';

const useStyles = makeStyles((theme) => ({
  postPageRoot: { width: '100%' },
  block: {
    marginBottom: theme.spacing(4),
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(0),
      paddingLeft: `${theme.spacing(2)}px`,
      paddingRight: `${theme.spacing(2)}px`,
    },
  },
  headerBlock: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
}));

const PostPage: React.FC = () => {
  const { postId = '' }: { postId: string } = useParams();
  const classes = useStyles();
  const [authState] = useContext(authStore);
  const history = useHistory();
  const [post, setPost] = useState<Post | undefined>();
  const [isLoggedUser, setIsLoggedUser] = useState<boolean>(false);
  const [isUserSubscribed, setIsUserSubscribed] = useState<boolean | undefined>();
  const meta = {
    title: post ? post.name : 'Become fan of your favorite athletes.',
    description: 'Peakz, exclusive videos from Athletes',
    shareUrl: post ? urlHelpers.getPostShareUrl(post.id) : '',
    thumbnailUrl:
      post && post.thumbS3Key
        ? urlHelpers.getMediaUrlFromS3Key(post.thumbS3Key, 'img')
        : `${baseUrl}/static/images/landing-header/female-short-track-1440.jpg`,
    whatsappThumbnailUrl:
      post && post.thumbS3Key
        ? urlHelpers.getMediaUrlFromS3Key(post.thumbS3Key, 'img')
        : `${baseUrl}/static/images/landing-header/female-short-track-400x400.jpg`,
  };

  useEffect(() => {
    if (authState.initialized) {
      (async function () {
        let publicPost;
        if (!post) {
          publicPost = await fetchPostById(postId);

          // Redirect if post not found
          if (!publicPost) {
            history.push({
              pathname: '/postnotfound',
              state: {
                message: 'Post not found',
              },
            });
            return;
          }

          // Update isLogged
          setIsLoggedUser(authState.user?.userId === publicPost.authorId);
        } else {
          publicPost = post;
        }

        // Set pos private data and subscription
        if (publicPost && authState.user) {
          const subscription = await fetchAthleteSubscription(authState.user.userId, publicPost.authorId);
          const isValidSubscription = subscription && isSubscriptionActive(subscription);
          const canFetchFullPost = Boolean(
            !publicPost.needsSubscription ||
              isValidSubscription ||
              authState.user.userId === publicPost.authorId ||
              authState.isAdmin ||
              authState.isModerator ||
              authState.isGuest,
          );

          setIsUserSubscribed(isValidSubscription);
          if (canFetchFullPost) {
            const fullPostData = await fetchFullPostById(postId, Boolean(authState.user));

            setPost({ ...publicPost, ...fullPostData });
          }
          return;
        }
        setPost(publicPost);
        setIsUserSubscribed(false);
      })();
    }
  }, [authState.initialized, authState.user]);

  return (
    <div className={classes.postPageRoot}>
      <Metadata
        title={meta.title}
        description={meta.description}
        shareUrl={meta.shareUrl}
        thumbnailUrl={meta.thumbnailUrl}
      />
      <AppHeaderPlaceHolder />

      <Container className={`${classes.block} ${classes.headerBlock}`} maxWidth="lg">
        <PostHeaderBlock
          post={post}
          editable={isLoggedUser}
          viewPermission={
            isLoggedUser ||
            !post?.needsSubscription ||
            isUserSubscribed ||
            authState.isAdmin ||
            authState.isModerator ||
            authState.isGuest
          }
        />
        {authState.initialized && authState.user && (
          <PostCommentSection contentLoaded={Boolean(post)} postAuthorId={post?.authorId || ''} postId={postId} />
        )}
      </Container>
    </div>
  );
};

export default PostPage;
