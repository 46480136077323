import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDropzone, FileRejection } from 'react-dropzone';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
  dropZoneRoot: {
    height: '100%',
  },
  content: {
    height: '100%',
  },
  fileIcon: {
    fontSize: theme.typography.pxToRem(90),
  },
  selectButton: {
    height: theme.typography.pxToRem(50),
    paddingLeft: theme.typography.pxToRem(30),
    paddingRight: theme.typography.pxToRem(30),
  },
}));

interface Props {
  maxFileSizeBytes?: number;
  className?: any;
  acceptedFileTypes?: string[];
  disabled?: boolean;
  fontSize?: 'lg' | 'sm';
  hideButton?: boolean;
  maxVideoDuration?: number;
  onRejected: (rejectedFiles: FileRejection[]) => void;
  onChange: (files: any, videoDimensions: any) => void;
}

const VideoImageDropZone: React.FC<Props> = ({
  maxFileSizeBytes,
  className,
  acceptedFileTypes,
  disabled,
  fontSize = 'lg',
  hideButton,
  maxVideoDuration,
  onRejected,
  onChange,
}: Props) => {
  const classes = useStyles();
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const tempVideo = document.createElement('video');

  const getVideoBelowMinimumResolution = (acceptedFiles: any) => {
    return new Promise((resolve) => {
      tempVideo.addEventListener('loadeddata', () => {
        const invalidVideos = [];
        const errors = [];
        const isPortrait = tempVideo.videoHeight > tempVideo.videoWidth;
        if (
          (!isPortrait && (tempVideo.videoWidth < 480 || tempVideo.videoHeight < 360)) ||
          (isPortrait && (tempVideo.videoWidth < 360 || tempVideo.videoHeight < 480))
        ) {
          errors.push({
            code: 'unsupported-resolution',
            message: 'Minimum supported resolution is 480x360 for landscape videos and 360x480 for portrait ones',
          });
          invalidVideos.push({
            file: acceptedFiles[0],
            errors: errors,
          });
        }
        resolve(invalidVideos);
      });
    });
  };

  const getVideoAboveTimeLimit = (acceptedFiles: any) => {
    return new Promise((resolve) => {
      tempVideo.addEventListener('loadedmetadata', () => {
        const invalidVideos = [];
        const errors = [];
        if (maxVideoDuration && tempVideo.duration > maxVideoDuration) {
          errors.push({
            code: 'video-too-long',
            message: `Intro video can't be longer than ${maxVideoDuration} seconds`,
          });

          invalidVideos.push({
            file: acceptedFiles[0],
            errors: errors,
          });
        }
        resolve(invalidVideos);
      });
    });
  };

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    accept: acceptedFileTypes ? acceptedFileTypes.join(',') : 'video/mp4, video/x-m4v, video/mpeg, video/*',
    maxFiles: 1,
    multiple: false,
    noClick: true,
    noKeyboard: true,
    disabled: disabled,
    maxSize: maxFileSizeBytes || 2000000000,
    onDrop: async (acceptedFiles, fileRejections) => {
      const { accept } = getInputProps();
      if (accept !== 'image/*') {
        try {
          tempVideo.src = URL.createObjectURL(acceptedFiles[0]);
          tempVideo.autoplay = true;
          tempVideo.muted = true;
        } catch (err) {
          // Nothing
        }

        if (fileRejections.length > 0) {
          onRejected(fileRejections);
        } else if (acceptedFiles.length > 0 && selectedFiles.length === 0) {
          let invalidVideos: any;
          // Check if there are videos exceeding maxVideoDuration
          if (maxVideoDuration) {
            invalidVideos = await getVideoAboveTimeLimit(acceptedFiles);
            if (invalidVideos.length) {
              return onRejected(invalidVideos);
            }
          }

          invalidVideos = await getVideoBelowMinimumResolution(acceptedFiles);
          if (invalidVideos.length) {
            return onRejected(invalidVideos);
          }
        }
      }
      // All validations passed
      setSelectedFiles(acceptedFiles);
      onChange(acceptedFiles, { width: tempVideo.videoWidth, height: tempVideo.videoHeight });
      tempVideo.remove();
    },
  });
  const { ...rootProps } = getRootProps();

  return (
    <div className={`${classes.dropZoneRoot} ${className}`} {...rootProps}>
      <Box p={2} width={1} height={1}>
        <input {...getInputProps()} />
        <Grid className={classes.content} container direction="column" alignItems="center" justifyContent="center">
          <Grid item>
            {!hideButton && <CloudUploadIcon className={classes.fileIcon} />}
            {hideButton && (
              <IconButton onClick={open}>
                <CloudUploadIcon className={classes.fileIcon} />
              </IconButton>
            )}
          </Grid>
          <Grid item>
            <Box mb={fontSize === 'lg' ? 4 : 2}>
              <Grid container justifyContent="center">
                <Grid item xs={12} sm={9}>
                  <Typography variant={fontSize == 'lg' ? 'h5' : 'body1'} align="center">
                    {isDragActive ? 'Drop the file here' : 'Drag and drop your file'}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          {!hideButton && (
            <Grid item>
              <Box mb={1}>
                {fontSize === 'lg' && (
                  <Button className={classes.selectButton} variant="contained" color="primary" onClick={open}>
                    <Typography variant="h6" align="center">
                      or choose a file
                    </Typography>
                  </Button>
                )}
                {fontSize !== 'lg' && (
                  <Button variant="contained" color="primary" onClick={open}>
                    or choose a file
                  </Button>
                )}
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
    </div>
  );
};

export default VideoImageDropZone;
