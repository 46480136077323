import { Box, Fade, Grid, TextField } from '@material-ui/core';
import React from 'react';
import CharacterCounter from '../../../components/ui/CharacterCounter';
import FormRow from '../../../components/ui/FormRow';
import StyledTextArea from '../../../components/ui/StyledTextArea';
import WarningMessage from '../../../components/ui/WarningMessage';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

interface Props {
  classes: any;
  setPostName: any;
  setPostDescription: any;
  postName: string;
  postDescription: string;
  detectedProfanity: boolean;
  maxPostName: number;
  maxPostDescription: number;
}

const PostStep2: React.FC<Props> = ({
  classes,
  setPostName,
  setPostDescription,
  postName,
  postDescription,
  detectedProfanity,
  maxPostDescription,
  maxPostName,
}: Props) => {
  return (
    <Grid className={classes.content} container direction="column" alignItems="center" justifyContent="center">
      <Grid item className={classes.fullWithItem}>
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={8} md={8}>
            <Box p={3} pb={0}>
              <FormRow label="Post title">
                <TextField
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  autoFocus={true}
                  InputProps={{
                    inputProps: {
                      onChange: (event: any) => {
                        setPostName(event.target.value);
                      },
                    },
                  }}
                  value={postName}
                />
                <CharacterCounter chars={postName.length} max={maxPostName} />
              </FormRow>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.fullWithItem}>
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={8} md={8}>
            <Box p={3}>
              <FormRow label="Post description">
                <StyledTextArea
                  minRows={5}
                  maxRows={8}
                  onChange={(event: any) => {
                    setPostDescription(event.target.value);
                  }}
                  defaultValue={postDescription}
                />
                <CharacterCounter chars={postDescription.length} max={maxPostDescription} />
              </FormRow>
              <Fade in={detectedProfanity} timeout={500}>
                <Box>
                  <WarningMessage
                    type="field"
                    icon={<ErrorOutlineIcon style={{ color: 'red' }} />}
                    text="Your post contains inappropriate language"
                  />
                </Box>
              </Fade>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PostStep2;
