import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from 'mui-rff';
import clsx from 'clsx';

import globalStyles from '../../theme/globalStyles';

const useStyles = makeStyles((theme) => ({
  formAutocomplete: { ...globalStyles(theme).formAutocomplete },
  noMargin: {
    '& > .MuiInputBase-root': {
      paddingTop: '6px !important',
      paddingBottom: '6px !important',
    },
  },
}));

interface Props {
  id: string;
  name: string;
  options: any[];
  required: boolean;
  disabled: boolean;
  onAutocompleteUpdate?: any;
  margin?: 'none' | 'dense' | 'normal';
  [key: string]: any;
}
const FormAutocomplete: React.FC<Props> = ({
  id,
  name,
  options,
  required,
  disabled,
  onAutocompleteUpdate,
  margin = 'dense',
  ...props
}: Props) => {
  const classes = useStyles();

  return (
    <Autocomplete
      id={id}
      name={name}
      label=""
      options={options}
      required={required}
      disabled={disabled}
      disableClearable
      blurOnSelect="touch"
      textFieldProps={{
        margin: margin,
        variant: 'outlined',
        classes: {
          root: clsx({ [classes.noMargin]: margin === 'none', [classes.formAutocomplete]: true }),
        },
      }}
      onChange={onAutocompleteUpdate}
      {...props}
    />
  );
};

export default FormAutocomplete;
