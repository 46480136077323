import ReactGA from 'react-ga';

export const gaEventCategories = {
  onboarding: 'Onboarding',
  createChallenge: 'Create Challenge',
  createVideo: 'Create Video',
  fanOnboarding: 'Fan Onboarding',
  subscriptions: 'Subscriptions',
};

export const gaEventActions = {
  click: 'Click',
  submit: 'Submit Click',
  emailSignup: 'Email Signup Click',
  facebookSignup: 'Facebook Signup Click',
  googleSignup: 'Google Signup Click',
  nextStep: 'Next Step Click',
  finalize: 'Finalize Click',
  selectVideo: 'Select Video',
};

export const sendGaEvent = async (eventCategory: string, eventAction: string, eventLabel?: string) => {
  // ga('send', 'event', [eventCategory], [eventAction], [eventLabel], [eventValue], [fieldsObject]);
  ReactGA.ga('send', 'event', eventCategory, eventAction, eventLabel);
};
