import React, { useState, useEffect } from 'react';
import { useMeetingSession } from './providers/MeetingSessionProvider';
import { useMeetingManager, darkTheme } from 'amazon-chime-sdk-component-library-react';
import { VideoTileState } from 'amazon-chime-sdk-js';
import { ThemeProvider } from 'styled-components';
import CSS from 'csstype';
import MeetingControls from './components/MeetingControls';
import VideoTile from './components/VideoTile';
import ShareTile from './components/ShareTile';
import { parseExternalAttendeeId } from './utils/attendee';
import DebugLayout from './components/DebugLayout';
import { useDevice } from './providers/DeviceProvider';
import Config from './utils/config';
import VideoOptimizer from './components/VideoOptimizer';
import BcastControls from './components/BcastControls';
import BcastInfo from './components/BcastInfo';

// set it to true do using mocking components instead of real video tiles
// to play with different layout scenarios
const DEBUG_LAYOUT = false;

// config:
const ELNAME_PREFIX = 'MeetingView-';

// Screen share information
type ShareItem = {
  index: number;
  tileId: number | null;
  style: CSS.Properties;
};

// Attendee video information
type VideoItem = {
  index: number;
  tileId: number | null;
  attendeeName: string;
  isHost: boolean;
  isLocal: boolean;
  style: CSS.Properties;
  isThumbnail: boolean;
};

type ControlsDisplay = 'none' | 'bottom' | 'right';

const MeetingView: React.FC = () => {
  const [screenContainerStyle, setScreenContainerStyle] = useState<CSS.Properties>({});
  const [sharesContainerStyle, setSharesContainerStyle] = useState<CSS.Properties>({ display: 'none' });
  const [shares] = useState<ShareItem[]>([]);
  const [videosContainerStyle, setVideosContainerStyle] = useState<CSS.Properties>({ display: 'none' });
  const [videos] = useState<VideoItem[]>([]);
  const [controlsDisplay, setControlsDisplay] = useState<ControlsDisplay>('none');
  const [controlsStyle, setControlsStyle] = useState<CSS.Properties>({});
  const [acquiredSharesCount, setAcquiredSharesCount] = useState(0);
  const [acquiredVideosCount, setAcquiredVideosCount] = useState(0);
  const [tileFontSize, setTileFontSize] = useState(16);

  const meetingManager = useMeetingManager();
  const { attendee } = useMeetingSession();
  const { attendeeId } = useMeetingSession();

  const { deviceType, displayOrientation } = useDevice();
  const [containerSize, setContainerSize] = useState<any>(null);
  const [, updateLayout] = useState({});

  const getVideoElement = (type: 'Share' | 'Video', index: number): HTMLVideoElement => {
    const el = document.getElementById(`${ELNAME_PREFIX}${type}#${index}`);
    if (!el) {
      throw new Error('Invalid video element index');
    }
    return el as HTMLVideoElement;
  };

  const refresh = () => {
    if (attendee === undefined) return;
    // set container div dimensions
    // they depend on the attendee - for THE BROADCASTE they are fixed
    // but for active participants they fit current window
    if (attendee.type === 'bcaster') {
      setScreenContainerStyle({
        width: Config.BROADCAST_WIDTH,
        height: Config.BROADCAST_HEIGHT,
      });
      setTileFontSize(40);
    } else {
      setScreenContainerStyle({
        width: '100%',
        height: '100%',
      });
      setTileFontSize(20);
    }

    // set controls
    let controlsDisplay: ControlsDisplay;
    let controlsStyle: CSS.Properties;
    if (attendee.type === 'moderator' || attendee.type === 'bcaster') {
      controlsDisplay = 'none';
      controlsStyle = {};
    } else {
      if (deviceType === 'mobile' && displayOrientation === 'landscape') {
        controlsDisplay = 'right';
        controlsStyle = {
          width: Config.CONTROLS_SIZE,
          height: '100%',
          justifyContent: 'start',
          top: 0,
          left: 'auto',
        };
      } else {
        controlsDisplay = 'bottom';
        controlsStyle = {
          width: '100%',
          height: Config.CONTROLS_SIZE,
          justifyContent: 'center',
          top: 'auto',
          left: 0,
        };
      }
    }
    setControlsDisplay(controlsDisplay);
    setControlsStyle(controlsStyle);

    // set video grid
    const acquiredSharesCount = shares.filter((item) => item.tileId !== null).length;
    setAcquiredSharesCount(acquiredSharesCount);
    const acquiredVideosCount = videos.filter((item) => item.tileId !== null).length;
    setAcquiredVideosCount(acquiredVideosCount);

    if (acquiredSharesCount > 0) {
      if (displayOrientation === 'landscape') {
        // horizontal with a screenshare
        setSharesContainerStyle({
          display: 'inline-block',
          float: 'left',
          width: `calc(100% - ${Config.THUMBNAIL_WIDTH} - ${
            controlsDisplay === 'right' ? Config.CONTROLS_SIZE : '0px'
          })`,
          height: `calc(100% - ${controlsDisplay === 'bottom' ? Config.CONTROLS_SIZE : '0px'})`,
          margin: '0, 5px',
        });
        shares.map((item) => {
          item.style = {
            display: item.tileId !== null ? 'block' : 'none',
          };
        });
        setVideosContainerStyle({
          display: 'inline-block',
          float: 'left',
          width: `${Config.THUMBNAIL_WIDTH}`,
          height: `calc(100% - ${controlsDisplay === 'bottom' ? Config.CONTROLS_SIZE : '0px'})`,
        });
        videos.map((item) => {
          item.style = {
            display: item.tileId !== null ? 'block' : 'none',
            width: '100%',
            height: `${100 / acquiredVideosCount}%`,
            maxHeight: '40%',
            float: 'none',
          };
          item.isThumbnail = true;
        });
      } else {
        // vertical with screen share
        setSharesContainerStyle({
          display: 'block',
          float: 'none',
          width: '100%',
          height: `calc(100% - 2 * ${Config.THUMBNAIL_HEIGHT} - ${Config.CONTROLS_SIZE})`,
        });
        shares.map((item) => {
          item.style = {
            display: item.tileId ? 'block' : 'none',
          };
        });
        setVideosContainerStyle({
          display: 'block',
          float: 'none',
          width: '100%',
          height: `calc(2 * ${Config.THUMBNAIL_HEIGHT})`,
          textAlign: 'center',
        });
        videos.map((item) => {
          item.isThumbnail = false;
          switch (acquiredVideosCount) {
            case 0:
              item.style = { display: 'none' };
              break;

            case 1:
              item.style = {
                display: item.tileId ? 'block' : 'none',
                width: '100%',
                height: '100%',
                float: 'none',
              };
              break;

            case 2:
              item.style = {
                display: item.tileId !== null ? 'inline-block' : 'none',
                width: `${100 / acquiredVideosCount}%`,
                height: '100%',
                float: 'left',
              };
              break;

            case 3:
              item.style = {
                display: item.tileId !== null ? 'inline-block' : 'none',
                width: item.index === 0 ? '100%' : '50%',
                height: '50%',
                float: 'left',
              };
              break;

            case 4:
              item.style = {
                display: item.tileId !== null ? 'inline-block' : 'none',
                width: '50%',
                height: '50%',
                float: 'left',
              };
              break;
          }
        });
      }
    } else {
      // without screenshare, both horizontal & vertical
      setSharesContainerStyle({
        display: 'none',
      });
      setVideosContainerStyle({
        display: 'block',
        float: 'none',
        width: `calc(100% - ${controlsDisplay === 'right' ? Config.CONTROLS_SIZE : '0px'})`,
        height: `calc(100% - ${controlsDisplay === 'bottom' ? Config.CONTROLS_SIZE : '0px'})`,
      });
      videos.map((item) => {
        item.isThumbnail = false;
        switch (acquiredVideosCount) {
          case 0:
            item.style = { display: 'none' };
            break;

          case 1:
            item.style = {
              display: item.tileId ? 'block' : 'none',
              width: '100%',
              height: '100%',
              float: 'none',
            };
            break;

          case 2:
          case 3:
            if (displayOrientation === 'landscape') {
              item.style = {
                display: item.tileId !== null ? 'inline-block' : 'none',
                width: `${100 / acquiredVideosCount}%`,
                height: '100%',
                float: 'left',
              };
            } else {
              item.style = {
                display: item.tileId ? 'block' : 'none',
                width: '100%',
                height: `${100 / acquiredVideosCount}%`,
                float: 'none',
              };
            }
            break;

          case 4:
            item.style = {
              display: item.tileId !== null ? 'inline-block' : 'none',
              width: '50%',
              height: '50%',
              float: 'left',
            };
            break;
        }
      });
    }

    updateLayout({});
  };

  // MAX_SHARE_ITEMS is 1. If we already have a share and another is coming,
  // the new one will just replace the existing share (at the same time,
  // session subscriber below will stop the first sharing if it is local )
  const acquireShareElement = (tileId: number): HTMLVideoElement => {
    // Return the same video element if already bound.
    for (const item of shares) {
      if (item.tileId === tileId) {
        return getVideoElement('Share', item.index);
      }
    }

    // Return the next available video element.
    for (const item of shares) {
      if (!item.tileId) {
        item.tileId = tileId;
        refresh();
        return getVideoElement('Share', item.index);
      }
    }
    // Just replace the first one
    if (shares.length > 0) {
      const item = shares[0];
      item.tileId = tileId;
      refresh();
      return getVideoElement('Share', item.index);
    }

    throw new Error('no share element is available');
  };

  const releaseShareElement = (tileId: number) => {
    for (const item of shares) {
      if (item.tileId === tileId) {
        item.tileId = null;
        refresh();
        return;
      }
    }
  };

  // throws error if no more video elements available
  const acquireVideoElement = (
    tileId: number,
    attendeeName: string,
    isHost = false,
    isLocal = false,
  ): HTMLVideoElement => {
    // Return the same video element if already bound.
    for (const item of videos) {
      if (item.tileId === tileId) {
        return getVideoElement('Video', item.index);
      }
    }
    // Return the next available video element
    // but keep the first element for the host
    for (const item of videos) {
      if (!item.tileId) {
        if (!isHost && item.index === 0) {
          continue;
        }
        item.tileId = tileId;
        item.attendeeName = attendeeName;
        item.isHost = isHost;
        item.isLocal = isLocal;
        refresh();
        return getVideoElement('Video', item.index);
      }
    }
    throw new Error('no video element is available');
  };

  const releaseVideoElement = (tileId: number) => {
    for (const item of videos) {
      if (item.tileId === tileId) {
        item.tileId = null;
        refresh();
        return;
      }
    }
  };

  useEffect(() => {
    // init index maps
    if (shares.length === 0) {
      for (let i = 0; i < Config.MAX_SHARE_ITEMS; i++) {
        shares.push({
          index: i,
          tileId: null,
          style: { display: 'none' },
        });
      }
    }
    if (videos.length === 0) {
      for (let i = 0; i < Config.MAX_VIDEO_ITEMS; i++) {
        videos.push({
          index: i,
          tileId: null,
          attendeeName: '',
          style: { display: 'none' },
          isHost: false,
          isLocal: false,
          isThumbnail: false,
        });
      }
    }

    // subscribe to meeting video events
    const meetingSession = meetingManager.meetingSession;
    if (meetingSession === null)
      return () => {
        return;
      };

    const meetingObserver = {
      // videoTileDidUpdate is called whenever a new tile is created or tileState changes.
      videoTileDidUpdate: (tileState: VideoTileState) => {
        if (!tileState.tileId || !tileState.boundExternalUserId) {
          return;
        }
        if (tileState.isContent) {
          // if new share is not ours and we're already sharing
          // stop our sharing (there can be only one share)
          if (tileState.boundExternalUserId !== attendeeId) {
            meetingSession.audioVideo.stopContentShare();
          }
          meetingSession.audioVideo.bindVideoElement(tileState.tileId, acquireShareElement(tileState.tileId));
        } else {
          if (tileState.localTile && !meetingSession.audioVideo.hasStartedLocalVideoTile()) {
            releaseVideoElement(tileState.tileId);
            return;
          }
          const info = parseExternalAttendeeId(tileState.boundExternalUserId);
          meetingSession.audioVideo.bindVideoElement(
            tileState.tileId,
            acquireVideoElement(tileState.tileId, info.name, info.type === 'host', tileState.localTile),
          );
        }
      },
      videoTileWasRemoved: (tileId: number) => {
        releaseShareElement(tileId);
        releaseVideoElement(tileId);
      },
    };
    if (!DEBUG_LAYOUT) {
      meetingSession.audioVideo.addObserver(meetingObserver);
    }

    // subscribe to container size changes
    const resizeObserver = new ResizeObserver((entries) => {
      setContainerSize(entries[0].contentRect);
    });
    if (document === null)
      return () => {
        return;
      };
    const container = document.getElementById(`${ELNAME_PREFIX}VideoContainer`);
    if (container === null)
      return () => {
        return;
      };
    resizeObserver.observe(container);

    return () => {
      if (!DEBUG_LAYOUT) {
        meetingSession.audioVideo.removeObserver(meetingObserver);
      }
      resizeObserver.unobserve(container);
    };
  }, []);

  // react on phone rotation
  useEffect(() => {
    refresh();
  }, [displayOrientation]);

  // react on container size changes
  useEffect(() => {
    refresh();
  }, [containerSize]);

  return (
    <ThemeProvider theme={darkTheme}>
      <div
        id={`${ELNAME_PREFIX}Screen`}
        style={{
          ...screenContainerStyle,
          backgroundColor: Config.BACKGROUND_COLOR,
          border: Config.TILE_BORDER,
          position: 'relative',
        }}
      >
        {DEBUG_LAYOUT && (
          <DebugLayout shares={shares} videos={videos} maxVideos={Config.MAX_VIDEO_ITEMS} refresh={refresh} />
        )}
        <div
          id={`${ELNAME_PREFIX}ShareContainer`}
          style={{
            ...sharesContainerStyle,
          }}
        >
          {shares.map((item) => (
            <div
              key={item.index}
              style={{
                ...item.style,
                width: '100%',
                height: '100%',
                border: Config.TILE_BORDER,
              }}
            >
              <ShareTile id={`${ELNAME_PREFIX}Share#${item.index}`} debug={DEBUG_LAYOUT} />
            </div>
          ))}
        </div>
        <div
          id={`${ELNAME_PREFIX}VideoContainer`}
          style={{
            ...videosContainerStyle,
          }}
        >
          {videos.map((item) => (
            <div
              key={item.index}
              style={{
                ...item.style,
                position: 'relative',
                border: Config.TILE_BORDER,
              }}
            >
              <VideoTile
                id={`${ELNAME_PREFIX}Video#${item.index}`}
                name={item.attendeeName}
                isThumbnail={item.isThumbnail}
                fontSize={tileFontSize}
                debug={DEBUG_LAYOUT}
              />
            </div>
          ))}
        </div>
        {controlsDisplay !== 'none' && attendee && (
          <>
            <BcastInfo />
            <MeetingControls layout={controlsDisplay} style={controlsStyle}>
              {(attendee.type === 'host' || attendee.type === 'moderator') && <BcastControls />}
            </MeetingControls>
            <VideoOptimizer sharesCount={acquiredSharesCount} videosCount={acquiredVideosCount} />
          </>
        )}
      </div>
    </ThemeProvider>
  );
};

export default MeetingView;
