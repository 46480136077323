import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/styles';
import TextSwitcher from '../../components/ui/TextSwitcher';
import { getRealHeight, letterSpacing } from '../../theme/theme';
import CTAButton from '../../components/ui/CTAButton';
import { Link } from 'react-router-dom';
import domHelpers from '../../core/helpers/dom';
import { PeakzTheme } from '../../theme/theme';
import config from '../../config';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: PeakzTheme) => ({
  channelIncludesRoot: {
    backgroundColor: 'black',
    color: 'white',
    minHeight: getRealHeight('100vh'),
    height: 'auto',
    paddingLeft: '20%',
    paddingRight: '20%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: theme.typography.pxToRem(80),
      paddingLeft: '25%',
      paddingRight: '25%',
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.typography.pxToRem(24),
    },
  },
  centerIcon: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const SwitcherChannel: React.FC = () => {
  const classes = useStyles();
  const theme: PeakzTheme = useTheme();
  const isSmallScreen = domHelpers.isSmallScreen(useMediaQuery, theme);
  const [t] = useTranslation();

  const switcherData = [
    {
      title: t('landing.home.text_switcher.titles.first'),
      slug: t('landing.home.text_switcher.labels.first'),
      text: t('landing.home.text_switcher.descriptions.first'),
    },
    {
      title: t('landing.home.text_switcher.titles.second'),
      slug: t('landing.home.text_switcher.labels.second'),
      text: t('landing.home.text_switcher.descriptions.second'),
    },
    {
      title: t('landing.home.text_switcher.titles.third'),
      slug: t('landing.home.text_switcher.labels.third'),
      text: t('landing.home.text_switcher.descriptions.third'),
    },
  ];

  const channelData = [
    {
      img: `${config.assetBaseUrl}/static/images/landing-header/channel_profile.png`,
      title: t('landing.home.channel_highlights.own_channel.title'),
      text: t('landing.home.channel_highlights.own_channel.description'),
    },
    {
      img: `${config.assetBaseUrl}/static/images/landing-header/channel_academy.png`,
      title: t('landing.home.channel_highlights.peakz_academy.title'),
      text: t('landing.home.channel_highlights.peakz_academy.description'),
    },
    {
      img: `${config.assetBaseUrl}/static/images/landing-header/channel_support.png`,
      title: t('landing.home.channel_highlights.peakz_support.title'),
      text: t('landing.home.channel_highlights.peakz_support.description'),
    },
    {
      img: `${config.assetBaseUrl}/static/images/landing-header/channel_scout.png`,
      title: t('landing.home.channel_highlights.lots_of_visibility.title'),
      text: t('landing.home.channel_highlights.lots_of_visibility.description'),
    },
    {
      img: `${config.assetBaseUrl}/static/images/landing-header/channel_payments.png`,
      title: t('landing.home.channel_highlights.monthly_payments.title'),
      text: t('landing.home.channel_highlights.monthly_payments.description'),
    },
    {
      img: `${config.assetBaseUrl}/static/images/landing-header/channel_community.png`,
      title: t('landing.home.channel_highlights.athlete_community.title'),
      text: t('landing.home.channel_highlights.athlete_community.description'),
    },
  ];

  return (
    <React.Fragment>
      <TextSwitcher
        content={switcherData}
        background="white"
        buttonText={t('global.read_more')}
        buttonLink="/athletes"
      />
      <Box pt={5} className={classes.channelIncludesRoot}>
        <Typography variant="h4" align="center">
          <b>{t('landing.home.channel_highlights.title')}</b>
        </Typography>
        <Box mt={5}>
          <Grid justifyContent={isSmallScreen ? 'center' : 'flex-start'} container>
            {channelData.map((data: { img: string; title: string; text: string }, index: number) => {
              return (
                <Grid
                  key={`Peakz_channel_Image_${index}`}
                  md={6}
                  style={{ padding: theme.typography.pxToRem(15) }}
                  item
                  className={isSmallScreen ? classes.centerIcon : ''}
                >
                  <img style={{ width: theme.typography.pxToRem(70) }} src={data.img} alt={data.title} />
                  <Typography
                    align={isSmallScreen ? 'center' : 'inherit'}
                    style={{ letterSpacing: letterSpacing.minimal }}
                    variant="h6"
                    color="primary"
                  >
                    {data.title}
                  </Typography>
                  <Typography
                    variant={isSmallScreen ? 'body2' : 'body1'}
                    align={isSmallScreen ? 'center' : 'inherit'}
                    style={{ letterSpacing: letterSpacing.minimal }}
                  >
                    {data.text}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        </Box>
        <Box display="flex" justifyContent="center" mt={5} mb={5}>
          <Link rel="noopener" to="/signup">
            <CTAButton variant="contained" color="primary" size="large">
              {t('global.start_channel')}
            </CTAButton>
          </Link>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default SwitcherChannel;
