import React, { createContext, Dispatch, useReducer } from 'react';
import * as log from 'loglevel';

import noop from '../helpers/noop';
import { CurrenciesConfig, User } from '../graphql/types';
import cacheHelpers from '../helpers/cache';
import { UserCurrencyConfig } from '../helpers/currency';

interface PaymentData {
  user: User;
}

interface AppState {
  initialized: boolean;
  user: User | null;
  appHeaderImgVisible: boolean;
  currenciesConfig?: CurrenciesConfig;
  userCurrencyConfig?: UserCurrencyConfig;
  toastTxt?: string;
  toastSeverity?: 'success' | 'info' | 'warning' | 'error';
  toastAction?: React.ReactNode;
  notificationTxt?: string;
  notificationTitle?: string;
  notificationAvatar?: string;
  backdropTxt?: string;
  sideMenuVisible?: boolean;
  installBannerDismissed: boolean;
  installPrompt?: any;
  launchInfo?: {
    isMobileiOS: boolean;
    isStandalone: boolean;
  };
}
export interface AppAction {
  type:
    | 'setUser'
    | 'updateUser'
    | 'showToast'
    | 'hideToast'
    | 'showBackdrop'
    | 'hideBackdrop'
    | 'sideMenuOn'
    | 'sideMenuOff'
    | 'setCurrenciesConfig'
    | 'setUserCurrencyConfig'
    | 'setInstallBannerDismissed'
    | 'setInstallPrompt'
    | 'setLaunchInfo';
  user?: User | null;
  userUpdateObj?: any;
  toastTxt?: string;
  toastSeverity?: 'success' | 'info' | 'warning' | 'error';
  toastAction?: React.ReactNode;
  notificationTxt?: string;
  notificationTitle?: string;
  notificationAvatar?: string;
  backdropTxt?: string;
  paymentData?: PaymentData;
  currenciesConfig?: CurrenciesConfig;
  userCurrencyConfig?: UserCurrencyConfig;
  installPrompt?: any;
  installBannerDismissed?: boolean;
  launchInfo?: {
    isMobileiOS: boolean;
    isStandalone: boolean;
  };
}

const initialState: AppState = {
  initialized: false,
  user: null,
  appHeaderImgVisible: false,
  sideMenuVisible: false,
  installBannerDismissed: true,
};
const appStore = createContext<[AppState, Dispatch<AppAction>]>([initialState, noop]);
const { Provider } = appStore;
const reducer = (state: any, action: any) => {
  log.debug('app action', action.type, action);

  switch (action.type) {
    case 'initializing':
      return { ...state, initialized: false };
    case 'initialized':
      return { ...state, initialized: true };
    case 'setUser':
      // Save in cache
      if (action.user) {
        cacheHelpers.saveUser(action.user);
      }

      return {
        ...state,
        user: action.user,
      };
    case 'updateUser': {
      const newUser = { ...state.user, ...action.userUpdateObj };

      // Save in cache
      if (newUser && newUser.id) {
        cacheHelpers.saveUser(newUser);
      }

      return {
        ...state,
        user: newUser,
      };
    }
    case 'showToast':
      return {
        ...state,
        toastTxt: action.toastTxt,
        toastSeverity: action.toastSeverity,
        toastAction: action.toastAction || undefined,
      };
    case 'showNotification':
      return {
        ...state,
        notificationTitle: action.notificationTitle,
        notificationTxt: action.notificationTxt,
        notificationAvatar: action.notificationAvatar,
      };
    case 'hideNotification': {
      return {
        ...state,
        notificationTitle: '',
        notificationTxt: '',
        notificationAvatar: '',
      };
    }
    case 'hideToast':
      return {
        ...state,
        toastTxt: '',
      };
    case 'showBackdrop':
      return {
        ...state,
        backdropTxt: action.backdropTxt || 'You are being redirected...',
      };
    case 'hideBackdrop':
      return {
        ...state,
        backdropTxt: '',
      };
    case 'sideMenuOn':
      return {
        ...state,
        sideMenuVisible: true,
      };
    case 'sideMenuOff':
      return {
        ...state,
        sideMenuVisible: false,
      };
    case 'setCurrenciesConfig':
      return {
        ...state,
        currenciesConfig: action.currenciesConfig,
      };
    case 'setUserCurrencyConfig':
      // Make sure the currency is supported and different to current before we set it and cache it
      if (
        action.userCurrencyConfig &&
        (!state.userCurrencyConfig ||
          state.userCurrencyConfig.currencyCode !== action.userCurrencyConfig.currencyCode ||
          state.userCurrencyConfig.userDefined !== action.userCurrencyConfig.userDefined)
      ) {
        // Save in cache
        cacheHelpers.saveUserCurrencyConfig(action.userCurrencyConfig);

        return {
          ...state,
          userCurrencyConfig: action.userCurrencyConfig,
        };
      }

      return state;
    case 'setLaunchInfo':
      return {
        ...state,
        launchInfo: action.launchInfo,
      };

    case 'setInstallPrompt':
      return {
        ...state,
        installPrompt: action.installPrompt,
      };
    case 'setInstallBannerDismissed':
      return {
        ...state,
        installBannerDismissed: action.installBannerDismissed,
      };

    default:
      return state;
  }
};

const AppStateProvider = ({ children }: { children: React.ReactNode }): any => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <Provider value={[state, dispatch]}>{children}</Provider>;
};

export { appStore, AppStateProvider };
