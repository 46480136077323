import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { useHistory } from 'react-router';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import { AthleteSubscription, User } from '../../core/graphql/types';
import noop from '../../core/helpers/noop';
import { isSubscriptionActive } from '../../core/helpers/athleteSubscription';
import AthleteAvatar from './AthleteAvatar';
import { getAthleteProfileRoute } from '../../core/helpers/route';
import SpinnerBox from '../ui/SpinnerBox';

const avatarWidthXs = 30;
const avatarWidthSm = 60;
const avatarWidthLg = 80;
const useStyles = makeStyles((theme) => ({
  subscriptionsRoot: {
    width: '100%', // material-ui: +16px
    margin: 0, // materiail-ui: -8px
    height: 'fit-content',
  },
  subscriptionPaper: {
    cursor: 'pointer',
    backgroundColor: theme.palette.grey[50],
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  athleteAvatarWrapper: {
    position: 'relative',
    width: theme.typography.pxToRem(avatarWidthXs),
    height: theme.typography.pxToRem(avatarWidthXs),
    [theme.breakpoints.up('xs')]: {
      width: theme.typography.pxToRem(avatarWidthSm),
      height: theme.typography.pxToRem(avatarWidthSm),
    },
    [theme.breakpoints.up('md')]: {
      width: theme.typography.pxToRem(avatarWidthLg),
      height: theme.typography.pxToRem(avatarWidthLg),
    },
  },
  athleteAvatar: {
    width: '100%',
    height: '100%',
  },
  expiredOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    //backgroundColor: 'rgba(255, 255, 255, 0.5)',
    color: 'white',
    width: '100%',
    height: '100%',
    pointerEvents: 'none',
  },
  lockIcon: {
    fontSize: theme.typography.pxToRem(16),
    marginTop: theme.typography.pxToRem(4),
    marginRight: theme.typography.pxToRem(2),
  },
}));

export interface Props {
  subscriptions?: AthleteSubscription[];
}

const SubscriptionsList: React.FC<Props> = ({ subscriptions }: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const handleAthleteClick = (athlete: User) => {
    history.push({
      pathname: getAthleteProfileRoute(athlete),
    });
  };

  return (
    <div>
      {subscriptions && subscriptions.length > 0 && (
        <Grid container spacing={2}>
          {subscriptions.map((subscription: AthleteSubscription) => {
            if (subscription.athlete) {
              // TODO: if expired, show it
              const active = isSubscriptionActive(subscription);

              return (
                <Grid key={subscription.athlete.id} item xs={12}>
                  <Paper
                    className={classes.subscriptionPaper}
                    elevation={0}
                    onClick={
                      subscription.athlete
                        ? handleAthleteClick.bind(null, subscription.athlete)
                        : () => {
                            noop();
                          }
                    }
                  >
                    <Box p={1}>
                      <Grid container spacing={2}>
                        <Grid className={classes.athleteAvatarWrapper} item>
                          <AthleteAvatar className={classes.athleteAvatar} user={subscription.athlete}></AthleteAvatar>
                          {/*{!active && (*/}
                          {/*  <Grid*/}
                          {/*    className={classes.expiredOverlay}*/}
                          {/*    container*/}
                          {/*    direction="column"*/}
                          {/*    justifyContent="center"*/}
                          {/*    alignItems="center"*/}
                          {/*  >*/}
                          {/*    <Grid item>*/}
                          {/*      <LockOutlinedIcon className={classes.lockIcon} />*/}
                          {/*    </Grid>*/}
                          {/*  </Grid>*/}
                          {/*)}*/}
                        </Grid>
                        <Grid item xs={8}>
                          <Grid container direction="column" justifyContent="center" style={{ height: '100%' }}>
                            <Grid item style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                              <Typography variant="body1" noWrap={true}>
                                {subscription.athlete.name}
                              </Typography>
                            </Grid>
                            {!active && (
                              <Grid item>
                                <Grid container alignItems="center">
                                  <Grid item>
                                    <LockOutlinedIcon className={classes.lockIcon} />
                                  </Grid>
                                  <Grid item>
                                    <Typography variant="caption" color="textSecondary">
                                      Expired
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  </Paper>
                </Grid>
              );
            }
          })}
        </Grid>
      )}
      {subscriptions && subscriptions.length === 0 && (
        <Typography variant="body1">You aren’t fan of any athletes yet.</Typography>
      )}
      {!subscriptions && <SpinnerBox />}
    </div>
  );
};

export default SubscriptionsList;
