import React, { useState, useEffect, MouseEventHandler } from 'react';
import IVSPlayer from '../../ui/IVSPlayer';
import { Box, Typography } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import clsx from 'clsx';
import Countdown from 'react-countdown';
import config from '../../../config';
import moment from 'moment';
import FilmCountdown from '../../../meeting/components/FilmCountdown';
import PlayerError from '../../ui/PlayerError';
import Config from '../../../meeting/utils/config';
import { BcastStatus } from '../../../API';
import urlHelpers from '../../../core/helpers/url';
import { Post } from '../../../core/graphql/types';
import { fetchPostVodToken } from '../../../core/services/postsSvc';
import videoSvc from '../../../core/services/videoSvc';
import { AuthState } from '../../../core/stores/auth';
import PostLockOverlay from '../PostLockOverlay';
interface Props {
  post: Post;
  isPreview?: boolean;
  authState?: AuthState;
  viewPermission?: boolean;
  globalClasses?: any;
  classes?: any;
  handleSubscribeClick?: MouseEventHandler<HTMLDivElement>;
  setPost?: React.Dispatch<React.SetStateAction<Post | undefined>>;
}

const BcastView: React.FC<Props> = (props: Props) => {
  const { isPreview, post, authState, viewPermission, classes } = props;
  //
  const scheduledCountdownRenderer = ({ days, hours, minutes, seconds, completed }: any) => {
    const countdownColor = 'orange'; /*theme.palette.primary.main*/
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <Box p={5} display="flex" alignItems="center" flexDirection="column">
          {!completed && (
            <>
              <Typography align="center" variant="h5" style={{ color: countdownColor }}>
                <b>Live streaming will start in</b>
              </Typography>
              <img
                width={isPreview ? '100px' : '100px'}
                height={isPreview ? '100px' : '100px'}
                src={`${config.assetBaseUrl}/static/images/logos/Icon_logo_orange.png`}
              />
              <Typography align="center" variant="h5" style={{ color: countdownColor }}>
                <b>
                  {days > 0 ? `${days} days` : ``} {hours > 0 ? `${hours} hours` : ``}{' '}
                  {minutes > 0 ? `${minutes} minutes` : ``} {seconds} seconds
                </b>
              </Typography>
            </>
          )}
          {completed && (
            <>
              <img
                width={isPreview ? '100px' : '100px'}
                height={isPreview ? '100px' : '100px'}
                src={`${config.assetBaseUrl}/static/images/logos/Icon_logo_orange.png`}
              />
              <Typography align="center" variant="h5" style={{ color: countdownColor }}>
                <b>Live streaming will start very soon...</b>
              </Typography>
            </>
          )}
        </Box>
      </Box>
    );
  };

  const [liveCountdown, setLiveCountdown] = useState<number>(-1);
  const [lastBcastStatus, setLastBcastStatus] = useState<string>('');
  const [vodUrl, setVodUrl] = useState('');
  const [loadingVodToken, setLoadingVodToken] = useState(false);
  const [thumbUrl, setThumbUrl] = useState('');

  useEffect(() => {
    if (post && post.thumbS3Key) {
      setThumbUrl(urlHelpers.getMediaUrlFromS3Key(post.thumbS3Key, 'img'));
    } else {
      setThumbUrl('/static/images/tennis-field.png');
    }
  }, [post]);

  useEffect(() => {
    if (post.bcastMetadata) {
      if (lastBcastStatus === BcastStatus.scheduled && post.bcastMetadata.status === BcastStatus.live) {
        const showLiveCountDown = (number: number) => {
          setLiveCountdown(number);
          setTimeout(() => {
            if (number > 0) {
              showLiveCountDown(number - 1);
            }
          }, 1000);
        };
        showLiveCountDown(Config.LIVE_COUNTDOWN_SECONDS);
      }
      setLastBcastStatus(post.bcastMetadata.status);
    }
  }, [post.bcastMetadata?.status]);

  const handlePlayVodClick = async () => {
    if (post && post.vodId && !vodUrl && authState && props.setPost && post.bcastMetadata?.recordingS3Key) {
      if (post.vodToken) {
        // Test url
        // setVodUrl(
        //   'https://multiplatform-f.akamaihd.net/i/multi/will/bunny/big_buck_bunny_,640x360_400,640x360_700,640x360_1000,950x540_1500,.f4v.csmil/master.m3u8',
        // );
        setVodUrl(await videoSvc.getPostVodPlaylistBcastManifest(post.bcastMetadata.recordingS3Key, post.vodToken));
      } else if (!loadingVodToken) {
        // If there is no vodToken, fetch it
        setLoadingVodToken(true);
        const { vodToken } = await fetchPostVodToken(post.id, Boolean(authState.user));
        setLoadingVodToken(false);

        props.setPost((p) => {
          if (p) {
            return { ...p, vodToken };
          }

          return p;
        });
        setVodUrl(await videoSvc.getPostVodPlaylistBcastManifest(post.bcastMetadata.recordingS3Key, vodToken));
      }
    }
  };

  return !post.bcastMetadata ? (
    <></>
  ) : !viewPermission ? (
    <>
      {/* Blocker */}
      {classes && props.handleSubscribeClick && (
        <div className={classes.postThumbVodWrapper} onClick={props.handleSubscribeClick}>
          <img
            className={clsx({
              [classes.postVodThumb]: true,
              [classes.postVodThumbBlur]: true,
            })}
            src={thumbUrl}
            alt="header-img"
            width="100%"
            height="100%"
          />
          <PostLockOverlay post={post} classes={classes} />
        </div>
      )}
    </>
  ) : (
    <>
      {/* "scheduled" status */}
      {(isPreview || post.bcastMetadata.status === BcastStatus.scheduled) && (
        <div
          style={{
            width: '100%',
            height: '100%',
            backgroundColor: 'black',
            color: 'white',
            fontFamily: 'Montserrat, Helvetica, Arial',
            fontWeight: 600,
            fontSize: isPreview ? 'small' : 'normal',
            display: 'flex',
            overflow: 'hidden',
            zoom: 0.7,
          }}
        >
          <div
            style={{
              textAlign: 'center',
              padding: '2em',
              flexGrow: 1,
              position: 'relative',
              //display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <h1 style={{ color: 'red' }}>LIVE STREAM</h1>
            <h1>{post.name}</h1>
            <p style={{ whiteSpace: 'pre-line' }}>{post.description}</p>
            <Countdown renderer={scheduledCountdownRenderer} date={moment(post.bcastMetadata.time).toDate()} overtime />
            {post.bcastMetadata.allowQuestions && (
              <p>
                {' '}
                Do you want them to answer your question during the livestream? <br />
                <u>Click here!</u>{' '}
              </p>
            )}
          </div>
          {post.bcastMetadata.previewImageS3Key && (
            <div
              style={{
                padding: '2em',
                width: '50%',
              }}
            >
              <img
                alt="preview"
                src={urlHelpers.getMediaUrlFromS3Key(post.bcastMetadata.previewImageS3Key, 'img')}
                style={{
                  display: 'block',
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              />
            </div>
          )}
        </div>
      )}

      {!isPreview && (
        <>
          {/* "live" status */}
          {post.bcastMetadata.status === BcastStatus.live && liveCountdown > 0 && (
            <FilmCountdown number={liveCountdown} />
          )}
          {post.bcastMetadata.status === BcastStatus.live && liveCountdown <= 0 && (
            <IVSPlayer key="live" isLiveStream={true} playbackUrl={post.bcastMetadata.bcastUrl || ''} />
          )}

          {/* "finished" status */}
          {post.bcastMetadata.status === BcastStatus.finished && (
            <>
              {!post.vodToken && (
                <Box style={{ height: '100%' }}>
                  {/* Video Preview/Thumbnail (Before Click) */}
                  {!vodUrl && (
                    <div className={classes.postThumbVodWrapper}>
                      <img className={classes.postVodThumb} src={thumbUrl} />
                      <div className={props.globalClasses?.centerAbsolute}>
                        <Zoom in={!loadingVodToken} timeout={1000}>
                          <PlayArrowIcon className={classes.postVodPlayIcon} onClick={handlePlayVodClick} />
                        </Zoom>
                      </div>
                      {/* {post.vodMetadata && post.vodMetadata.durationInMs && (
                        <Chip
                          className={classes.videoDuration}
                          label={secondsToStr(post.vodMetadata.durationInMs / 1000)}
                        />
                      )} */}
                    </div>
                  )}
                </Box>
              )}
              {post.vodToken && !vodUrl && (
                <Box style={{ height: '100%' }}>
                  {/* Video Preview/Thumbnail (Before Click) */}
                  {!vodUrl && (
                    <div className={classes.postThumbVodWrapper}>
                      <img className={classes.postVodThumb} src={thumbUrl} />
                      <div className={props.globalClasses?.centerAbsolute}>
                        <Zoom in={!loadingVodToken} timeout={!vodUrl ? 1000 : 0}>
                          <PlayArrowIcon className={classes.postVodPlayIcon} onClick={handlePlayVodClick} />
                        </Zoom>
                      </div>
                    </div>
                  )}
                </Box>
              )}
              {vodUrl && post.vodToken && <IVSPlayer key="finished" isLiveStream={false} playbackUrl={vodUrl} />}
            </>
          )}

          {/* "error" status */}
          {post.bcastMetadata.status === BcastStatus.error && (
            <PlayerError text="The broadcast has failed" error="Cause: Internal server error" />
          )}
        </>
      )}
    </>
  );
};

export default BcastView;
