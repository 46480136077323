// Shows an error in place of the failed IVS playher component.
// Todo: perhaps to use it also for VOD files?

import React from 'react';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

interface Props {
  text: string;
  error: string;
}

const PlayerError: React.FC<Props> = (props: Props) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        width: '100%',
        height: '100%',
        backgroundColor: ' black',
        color: 'white',
        padding: '2rem',
      }}
    >
      <p>
        <ErrorOutlineIcon style={{}} />
      </p>
      <h3>{props.text}</h3>
      <br />
      <p style={{ fontSize: 'small' }}>{props.error}</p>
    </div>
  );
};

export default PlayerError;
