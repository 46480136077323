/*
  NOTE:
  App renders twice because of hooks.

  REASON: (https://github.com/facebook/react/issues/15074)
  It's an intentional feature of the StrictMode. This only happens in development, and helps find accidental side
  effects put into the render phase. We only do this for containers with Hooks because those are more likely to
  accidentally have side effects in the wrong place.
 */
// @ts-nocheck
import React, { Dispatch, Suspense, useContext, useEffect, useState } from 'react';
import { Auth, Hub } from 'aws-amplify';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { createBrowserHistory } from 'history';
import * as log from 'loglevel';
import ReactGA from 'react-ga';
import Backdrop from '@material-ui/core/Backdrop';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar/Snackbar';
import Alert from '@material-ui/lab/Alert/Alert';
import clsx from 'clsx';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import { SnackbarContent } from '@material-ui/core';
import { Avatar } from '@material-ui/core';
import { AppAction, appStore } from './core/stores/app';
import { AuthAction, authStore } from './core/stores/auth';
import userHelpers from './core/helpers/user';
import { completeSignIn } from './core/services/authSvc';
import withRoot from './theme/withRoot';
import Metadata from './core/misc/Metadata';
import AppFooter from './components/layouts/AppFooter';
import ScrollToTop from './core/misc/ScrollToTop';
import cacheHelpers from './core/helpers/cache';
import { baseUrl, siteDescription, siteTitle } from './config';
import NotFoundPage from './pages/NotFoundPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import AthletesOverviewPage from './pages/athletesOverviewPage/AthletesOverviewPage';
import AthletesPage from './pages/AthletesPage';
import AthleteProfilePage from './pages/athleteProfilePage/AthleteProfilePage';
import HomePage from './pages/homePage/HomePage';
import AuthPage from './pages/authPage/AuthPage';
import CheckoutPage from './pages/checkoutPage/CheckoutPage';
import UserJsonPage from './pages/UserJsonPage';
import BlogPage from './pages/BlogPage';
import TestPage from './pages/TestPage';
import PostPage from './pages/postPage/PostPage';
import MembershipsPage from './pages/membershipsPage/MembershipsPage';
import CreatePostPage from './pages/createPostPage/CreatePostPage';
import BcastForm from './components/elements/bcast/BcastForm';
import MeetingPage from './pages/MeetingPage';

import AthleteDashboardPage from './pages/athleteDashboardPage/AthleteDashboardPage';
import DashboardModeChecker from './core/misc/DashboardModeChecker';
import SettingsPage from './pages/settingsPage/SettingsPage';
import BecomeAthletePage from './pages/BecomeAthletePage';
import FanDashboardPage from './pages/fanDashboardPage/FanDashboardPage';
import FanPage from './pages/FanPage';
import AcademyPage from './pages/AcademyPage';
import AboutPage from './pages/aboutPage/AboutPage';
import AppHeader from './components/layouts/AppHeader';
import AthletesSideMenu, { athletesSideMenuWidth } from './components/layouts/AthletesSideMenu';
import BroadcastPage from './pages/BroadcastPage';
import {
  athleteChannelRoute,
  athleteCheckoutRoute,
  athletePaymentsRoute,
  athleteProfileEditRoute,
  athleteProfileRoute,
  becomeAthleteRoute,
  createPostRoute,
  bcastFormRoute,
  meetingPageRoute,
  loginJoinRoute,
  loginRoute,
  membershipsRoute,
  signupJoinRoute,
  signupRoute,
  userHomeRoute,
  userSettingsRoute,
  premiumInviteRoute,
  reviewDashboardRoute,
  pushNotificationsDashboardRoute,
  aboutRoute,
  athletesRoute,
  fansRoute,
  policiesRoute,
  getBaseRoute,
  academyRoute,
  athleteOverviewRoute,
  userJSONRoute,
  postViewerRoute,
  docsRoute,
  blogViewerRoute,
  forgotPasswordRoute,
  notFoundRoute,
  athleteNotFoundRoute,
  postNotFoundRoute,
  noDocFoundRoute,
  qwertyRoute,
  athleteProfileRouterExt,
  adminDashboardRoute,
} from './core/helpers/route';
import AthleteChannelPage from './pages/athleteChannelPage/AthleteChannelPage';
import AthleteProfileEditPage from './pages/athleteProfileEditPage/AthleteProfileEditPage';
import AthletePaymentsPage from './pages/athletePaymentsPage/AthletePaymentsPage';
import PoliciesPage from './pages/PoliciesPage';
import domHelpers from './core/helpers/dom';
import SuspensePreloader from './components/ui/SuspensePreloader';
import ReviewDashboard from './pages/adminDashboard/reviewerDashboard/ReviewerDashboard';
import PushNotificationsDashboard from './pages/adminDashboard/pushNotificationsDashboard/PushNotificationsDashboard';
import { getFilter } from './core/helpers/moderation';
import { getCurrenciesConfig } from './core/services/currencySvc';
import { subscribeToAthleteCreation, updateUser } from './core/services/userSvc';
import { User } from './core/graphql/types';
import { getCountryFromCode } from './core/helpers/misc';
import PremiumInvite from './pages/PremiumInvite';
import { useHistory, useLocation, withRouter } from 'react-router';
import DocsPage from './pages/DocsPage';
import { sendGaEvent } from './core/services/gaSvc';
import AdminDashboard from './pages/adminDashboard/AdminDashboard';
import FadeIn from './components/ui/FadeIn';
import { setLaunchInfo } from './core/services/installSvc';
import AppInstallBanner from './components/ui/AppInstallBanner';

import { ThemeProvider } from '@material-ui/core/styles';
import myTheme from './theme/theme';

export const moderationFilter = getFilter('es');

const drawerWidth = 240;

// disable boring notifications for 1) installing application and 2) HubSpot bot
// (for demonstration)
const disableAppInstaller = true;
const disableHubSpotConversations = true;

const history = createBrowserHistory();
history.listen((location) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});
let firebaseToken;

declare global {
  interface Window {
    AWS: any;
    FB: any;
    HubSpotConversations: {
      widget: any;
    };
  }
}

function App() {
  const location = useLocation();

  // bypass all peakz-web crap in the broadcaster app:
  if (location.pathname.startsWith('/broadcast/')) {
    return <BroadcastPage />;
  }

  const useWhiteBg = !['/', athletesRoute, academyRoute, fansRoute, '/policies', aboutRoute].includes(
    location.pathname,
  );
  const [toggleDark, settoggleDark] = useState(false);
  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      minHeight: '100vh',
      maxWidth: '100%',
      backgroundColor: toggleDark ? '#303030' : '#fff',
      color: toggleDark ? '#fff' : '#000',
      '& .MuiPaper-root': {
        backgroundColor: toggleDark ? '#424242' : useWhiteBg ? '#fff' : 'inherit',
        color: toggleDark ? '#fff' : '#424242',
      },
      '& .MuiInputBase-root': {
        borderRadius: toggleDark ? '2rem' : '2rem',
        color: toggleDark ? 'inherit' : 'inherit',
      },
      '& .MuiFormLabel-root': {
        color: toggleDark ? '#fff' : 'inherit',
      },
      '& .MuiFormControl-root': {
        color: toggleDark ? '#fff' : 'inherit',
      },
      '& .MuiButton-label': {
        color: toggleDark ? '#fff' : 'inherit',
      },
      '& .MuiIconButton-root': {
        color: toggleDark ? '#fff' : 'rgba(0, 0, 0, 0.87)',
      },
      '& .MuiFab-root': {
        backgroundColor: toggleDark ? '#303030' : '#e0e0e0',
      },
      // '& .MuiSvgIcon-root': {
      //   color: toggleDark ? '#fff' : 'rgba(0, 0, 0, 0.54)',
      // },
      '& .MuiSvgIcon-root': {
        color: '#f2a742;',
      },
      '& .MuiTypography-colorTextPrimary': {
        color: toggleDark ? '#fff' : '#000',
      },
      // '& footer': {
      //   backgroundColor: toggleDark ? '#424242;' : '#fff',
      //   color: toggleDark ? '#fff' : '#000',
      // },
    },
    content: {
      //display: 'flex',
      //flex: 1, // it makes content child to use full height in combination with root{display: 'flex', flexDirection: 'column'}
      flexGrow: 1,
    },
    contentHasSideMenu: {
      paddingLeft: drawerWidth,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
      },
      minHeight: `100vh - ${athletesSideMenuWidth}px`,
    },
    main: {
      flexGrow: 1,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
    },
  }));
  const theme = useTheme();
  const classes = useStyles();
  const isSmallScreen = domHelpers.isSmallScreen(useMediaQuery, theme);
  const isBelowIPadScreen = useMediaQuery(theme.breakpoints.down(1025));
  const [authState, authDispatch] = useContext(authStore);
  const [appState, appDispatch] = useContext(appStore);
  if (appState.user) {
    if (toggleDark !== (appState.user.themeId === 'dark')) {
      settoggleDark(appState.user.themeId === 'dark');
    }
  }
  const [waitForRedirection, setWaitForRedirection] = useState(true);
  const history = useHistory();

  //First assign (first load)
  let useHeaderAndFooter =
    ![reviewDashboardRoute, `/${premiumInviteRoute.split('/')[1]}`].includes(getBaseRoute(location.pathname)) &&
    !location.pathname.startsWith('/meeting/') &&
    // for demostration, better without header and futer when link a single post is in full screen
    !location.pathname.startsWith('/p/');

  const meta = {
    title: siteTitle,
    description: siteDescription,
    shareUrl: baseUrl,
    thumbnailUrl: `${baseUrl}/static/images/landing-header/swimmer-1440.jpg`,
    whatsappThumbnailUrl: `${baseUrl}/static/images/landing-header/swimmer-400x400.jpg`,
    //thumbnailUrl: 'https://challenge.peakz.com/static/images/landing-header/header-image-fina-lsmall.jpg',
    //whatsappThumbnailUrl: 'https://challenge.peakz.com/static/images/landing-header/header-400x400.jpg',
  };

  /**
   * Listen changes on the location to check if header/footer should be present.
   * It is advisable to use history.push() instead of raw linking from now on.
   */
  history.listen((location) => {
    useHeaderAndFooter =
      ![reviewDashboardRoute, `/${premiumInviteRoute.split('/')[1]}`].includes(getBaseRoute(location.pathname)) &&
      !location.pathname.startsWith('/meeting/');
  });

  useEffect(() => {
    if (!disableHubSpotConversations) {
      if (!waitForRedirection && useHeaderAndFooter) {
        if (window.HubSpotConversations && window.HubSpotConversations.widget) {
          window.HubSpotConversations.widget.load();

          /**
           * HubSpot Conversations SDK doesn't have any load events to notice when the widget
           * is available for css manipulation. 5000ms is an average estimated value working with
           * slow 3G throttling
           */
          setTimeout(() => {
            const hubspotIframe: HTMLElement | null = document.querySelector('body #hubspot-messages-iframe-container');
            if (hubspotIframe) {
              hubspotIframe.style.transition = 'margin 700ms';
              if (!appState.launchInfo?.isStandalone && !appState.installBannerDismissed && isBelowIPadScreen) {
                hubspotIframe.style.marginBottom = '90px';
              } else {
                hubspotIframe.style.marginBottom = '0px';
              }
            }
          }, 5000);
        }
      }
    }
  }, [waitForRedirection, useHeaderAndFooter]);

  useEffect(() => {
    setLaunchInfo(appDispatch);

    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      console.log(`'beforeinstallprompt' event was fired.`);
      appDispatch({
        type: 'setInstallPrompt',
        installPrompt: e,
      });
    });

    /**
     * Only work for Chrome, so our analytics will only reflect chrome installs
     */
    window.addEventListener('appinstalled', () => {
      console.log('PWA was installed');

      appDispatch({
        type: 'setInstallPrompt',
        installPrompt: undefined,
      });
      sendGaEvent('app', 'installed');
    });
  }, []);

  useEffect(() => {
    if (!authState.user) {
      const atheleteSignUpSubscription = subscribeToAthleteCreation((evt) => {
        const data = evt.value.data.onAthleteCreation;
        const user: User = data;
        appDispatch({
          type: 'showNotification',
          notificationTitle: `${user.name}, from ${getCountryFromCode(user.country)}, just signed up!`,
          notificationTxt: 'What are you waiting for?',
          notificationAvatar: user.profileImgS3Key ? userHelpers.getProfileImg(user) : undefined,
        });
      });

      return () => {
        if (atheleteSignUpSubscription) {
          atheleteSignUpSubscription.then((subscription) => {
            subscription.unsubscribe();
          });
        }
      };
    }
  }, [authState.user]);

  // Initialize App
  useEffect(() => {
    getFilter('all');
    // Init analytics
    ReactGA.pageview(window.location.pathname);

    // Set listener for auth events
    Hub.listen('auth', (data) => {
      const { payload } = data;

      log.debug(data);
      log.debug('App: Auth event', payload.event);

      // Used because of google SignIn only
      if (payload.event === 'signIn') {
        // EMPTY
      } else if (payload.event === 'customOAuthState') {
        // EMPTY
      } else if (payload.event === 'signIn_failure') {
      } else if (payload.event === 'signOut') {
        authDispatch({ type: 'setUser' }); // TODO: Use userSvc.logout
      }
    });

    // Listen for errors
    Hub.listen('apperror', async (data) => {
      const { payload } = data;
      try {
        const user = await Auth.currentAuthenticatedUser({
          bypassCache: false,
        });

        if (user) {
          payload.data.userName = user.username;
        }
      } catch (err) {}

      // Report error to logging API
      log.error(JSON.stringify(payload.data));

      // Check if it's network error
      if (payload.data && payload.data.message && payload.data.message.toLowerCase().indexOf('network error') >= 0) {
        appDispatch({
          type: 'showToast',
          toastTxt:
            'There seems to be a problem with the connection. Please check your internet connection and try again later.',
          toastSeverity: 'error',
        });
      }
    });

    // Custom channel. Events dispatched from index.js
    Hub.listen('pushNotifications', ({ payload }: { payload: { event: string; data: any } }) => {
      if (payload.event === 'newToken') {
        // Update the firebaseToken because at this point appState.user might not be set yet
        firebaseToken = payload.data;
        updateUserFirebaseToken(appDispatch, appState.user, firebaseToken);
      } else if (payload.event === 'newNotification') {
        appDispatch({
          type: 'showNotification',
          notificationTitle: payload.data.title,
          notificationTxt: payload.data.body,
          notificationAvatar: `${baseUrl}/static/images/logos/Icon_logo_orange.png`,
        });
      }
    });

    initializeApp(authDispatch, appDispatch).then(
      (value: any) => {
        if (value !== undefined && value.themeId) {
          settoggleDark(value.themeId === 'dark');
        }
      },
      (error: any) => {
        console.log(error);
      },
    );
  }, []);

  console.log(process.env);

  return (
    <ThemeProvider theme={myTheme}>
      <div className={classes.root}>
        {/* Metadata */}
        <Metadata
          title={meta.title}
          description={meta.description}
          shareUrl={meta.shareUrl}
          thumbnailUrl={meta.thumbnailUrl}
          whatsappThumbnailUrl={meta.whatsappThumbnailUrl}
        />
        <Router history={history}>
          <ScrollToTop />
          <DashboardModeChecker />

          {/* App Header */}
          <Suspense fallback={<SuspensePreloader />}>
            <div style={!useHeaderAndFooter || waitForRedirection ? { display: 'none' } : {}}>
              <AppHeader toggleDark={toggleDark} />
              {/* App install banner */}
              {!disableAppInstaller &&
                appState.initialized &&
                isBelowIPadScreen &&
                !appState.launchInfo?.isStandalone && (
                  <AppInstallBanner
                    theme={
                      !['/', athletesRoute, academyRoute, fansRoute, '/policies', aboutRoute].includes(
                        location.pathname,
                      )
                        ? 'light'
                        : 'dark'
                    }
                  />
                )}
            </div>

            {/* Drawer */}
            {appState.sideMenuVisible && <AthletesSideMenu />}

            {/* App Content */}
            {/* Content/Routes */}
            {authState.initialized && (
              <main
                className={clsx({
                  [classes.content]: true,
                  [classes.contentHasSideMenu]: appState.sideMenuVisible,
                })}
              >
                <Switch>
                  {/* Public routes */}
                  <Route
                    exact
                    path={aboutRoute}
                    render={() => {
                      return wrapRenderIntoRedirectNotice(<AboutPage />, setWaitForRedirection);
                    }}
                  />
                  <Route
                    exact
                    path={athletesRoute}
                    render={() => {
                      return wrapRenderIntoRedirectNotice(<AthletesPage />, setWaitForRedirection);
                    }}
                  />
                  <Route
                    exact
                    path={policiesRoute}
                    render={() => {
                      return wrapRenderIntoRedirectNotice(<PoliciesPage />, setWaitForRedirection);
                    }}
                  />
                  <Route
                    exact
                    path={fansRoute}
                    render={() => {
                      return wrapRenderIntoRedirectNotice(<FanPage />, setWaitForRedirection);
                    }}
                  />
                  <Route
                    exact
                    path={academyRoute}
                    render={() => {
                      return wrapRenderIntoRedirectNotice(<AcademyPage />, setWaitForRedirection);
                    }}
                  />
                  <Route
                    exact
                    path={athleteProfileRouterExt}
                    render={() => {
                      return wrapRenderIntoRedirectNotice(<AthleteProfilePage />, setWaitForRedirection);
                    }}
                  />
                  <Route
                    exact
                    path={athleteOverviewRoute}
                    render={() => {
                      return wrapRenderIntoRedirectNotice(<AthletesOverviewPage />, setWaitForRedirection);
                    }}
                  />
                  <Route
                    exact
                    path={userJSONRoute}
                    render={() => {
                      return wrapRenderIntoRedirectNotice(<UserJsonPage />, setWaitForRedirection);
                    }}
                  />
                  <Route
                    exact
                    path={premiumInviteRoute}
                    render={() => {
                      return wrapRenderIntoRedirectNotice(<PremiumInvite />, setWaitForRedirection);
                    }}
                  />
                  <Route
                    exact
                    path={postViewerRoute}
                    render={() => {
                      return wrapRenderIntoRedirectNotice(<PostPage />, setWaitForRedirection);
                    }}
                  />
                  <Route
                    exact
                    path={docsRoute}
                    render={() => {
                      return wrapRenderIntoRedirectNotice(<DocsPage />, setWaitForRedirection);
                    }}
                  />
                  <Route
                    exact
                    path={blogViewerRoute}
                    render={() => {
                      return wrapRenderIntoRedirectNotice(<BlogPage />, setWaitForRedirection);
                    }}
                  />
                  <Route
                    exact
                    path={forgotPasswordRoute}
                    render={() => {
                      return wrapRenderIntoRedirectNotice(<ForgotPasswordPage />, setWaitForRedirection);
                    }}
                  />
                  <Route
                    exact
                    path={notFoundRoute}
                    render={() => {
                      return wrapRenderIntoRedirectNotice(<NotFoundPage />, setWaitForRedirection);
                    }}
                  />
                  <Route
                    exact
                    path={athleteNotFoundRoute}
                    render={() => {
                      return wrapRenderIntoRedirectNotice(<NotFoundPage />, setWaitForRedirection);
                    }}
                  />
                  <Route
                    exact
                    path={postNotFoundRoute}
                    render={() => {
                      return wrapRenderIntoRedirectNotice(<NotFoundPage />, setWaitForRedirection);
                    }}
                  />
                  <Route
                    exact
                    path={noDocFoundRoute}
                    render={() => {
                      return wrapRenderIntoRedirectNotice(<NotFoundPage />, setWaitForRedirection);
                    }}
                  />
                  <Route
                    exact
                    path={qwertyRoute}
                    render={() => {
                      return wrapRenderIntoRedirectNotice(<TestPage />, setWaitForRedirection);
                    }}
                  />
                  <Route
                    exact
                    path="/"
                    render={() => {
                      if (userHelpers.isSignedUp(authState.user)) {
                        setWaitForRedirection(false);
                        return (
                          <Redirect
                            to={{
                              pathname: userHomeRoute,
                              search: window.location.search,
                            }}
                          />
                        );
                      }
                      return wrapRenderIntoRedirectNotice(<HomePage />, setWaitForRedirection);
                    }}
                  />
                  <Route
                    exact
                    path={[signupRoute, loginRoute, signupJoinRoute, loginJoinRoute]}
                    render={() => {
                      setWaitForRedirection(false);
                      if (!userHelpers.isSignedUp(authState.user)) {
                        return <AuthPage />;
                      }
                      const redirectPath = cacheHelpers.load(cacheHelpers.keys.authRedirectRoute) || userHomeRoute;
                      const redirectSearch =
                        window.location.search.length == 0
                          ? cacheHelpers.load(cacheHelpers.keys.authRedirectSearch) || window.location.search
                          : window.location.search + cacheHelpers.load(cacheHelpers.keys.authRedirectSearch);

                      // Clear the cached value to avoid an infinite loop of redirection
                      cacheHelpers.remove(cacheHelpers.keys.authRedirectRoute);
                      cacheHelpers.remove(cacheHelpers.keys.authRedirectSearch);
                      return (
                        <Redirect
                          to={{
                            pathname: redirectPath,
                            search: redirectSearch,
                          }}
                        />
                      );
                    }}
                  />
                  {/* Private routes */}
                  <PrivateRoute
                    setWaitForRedirection={setWaitForRedirection}
                    exact
                    path={`${athleteProfileEditRoute}/:sectionId?`}
                  >
                    <AthleteProfileEditPage />
                  </PrivateRoute>
                  <PrivateRoute setWaitForRedirection={setWaitForRedirection} exact path={createPostRoute}>
                    <CreatePostPage />
                  </PrivateRoute>
                  <Route
                    exact
                    path={bcastFormRoute}
                    render={() => {
                      return wrapRenderIntoRedirectNotice(<BcastForm />, setWaitForRedirection);
                    }}
                  />
                  <Route
                    exact
                    path={meetingPageRoute}
                    render={() => {
                      return wrapRenderIntoRedirectNotice(<MeetingPage />, setWaitForRedirection);
                    }}
                  />
                  <PrivateRoute
                    setWaitForRedirection={setWaitForRedirection}
                    exact
                    path={`${membershipsRoute}/:sectionId?`}
                  >
                    <MembershipsPage />
                  </PrivateRoute>
                  <PrivateRoute
                    setWaitForRedirection={setWaitForRedirection}
                    exact
                    path={`${becomeAthleteRoute}/:sectionId?`}
                  >
                    <BecomeAthletePage />
                  </PrivateRoute>
                  <PrivateRoute
                    setWaitForRedirection={setWaitForRedirection}
                    exact
                    path={`${athleteChannelRoute}/:sectionId?`}
                  >
                    <AthleteChannelPage />
                  </PrivateRoute>
                  <PrivateRoute
                    setWaitForRedirection={setWaitForRedirection}
                    exact
                    path={`${userSettingsRoute}/:sectionId?`}
                  >
                    <SettingsPage toggleDark={toggleDark} settoggleDark={settoggleDark} />
                  </PrivateRoute>
                  <PrivateRoute setWaitForRedirection={setWaitForRedirection} exact path={athletePaymentsRoute}>
                    <AthletePaymentsPage />
                  </PrivateRoute>
                  <PrivateRoute setWaitForRedirection={setWaitForRedirection} exact path={`${userHomeRoute}/json`}>
                    <UserJsonPage />
                  </PrivateRoute>
                  <Route
                    exact
                    path={userHomeRoute}
                    render={() => {
                      setWaitForRedirection(false);
                      if (userHelpers.isSignedUp(authState.user)) {
                        if (authState.isAthlete) {
                          return <AthleteDashboardPage />;
                        }
                        return <FanDashboardPage />;
                      }
                      return (
                        <Redirect
                          to={{
                            pathname: loginRoute,
                            search: window.location.search,
                          }}
                        />
                      );
                    }}
                  />
                  <PrivateRoute setWaitForRedirection={setWaitForRedirection} exact path={athleteCheckoutRoute}>
                    <CheckoutPage />
                  </PrivateRoute>
                  {/* Private Admin routes */}
                  <PrivateRoute setWaitForRedirection={setWaitForRedirection} exact path={adminDashboardRoute}>
                    <AdminDashboard />
                  </PrivateRoute>
                  <PrivateRoute setWaitForRedirection={setWaitForRedirection} exact path={reviewDashboardRoute}>
                    <ReviewDashboard />
                  </PrivateRoute>
                  <PrivateRoute
                    setWaitForRedirection={setWaitForRedirection}
                    exact
                    path={pushNotificationsDashboardRoute}
                  >
                    <PushNotificationsDashboard />
                  </PrivateRoute>
                  {/* Fallback to Athlete profile route */}
                  <Route
                    exact
                    path={athleteProfileRoute}
                    render={() => {
                      return wrapRenderIntoRedirectNotice(<AthleteProfilePage />, setWaitForRedirection);
                    }}
                  />
                </Switch>
              </main>
            )}
            {/* App Footer */}
            {authState.initialized && (
              <div style={!useHeaderAndFooter ? { display: 'none' } : {}}>
                <AppFooter toggleDark={toggleDark} />
              </div>
            )}
          </Suspense>
        </Router>

        {/* App Toast/Snackbar */}
        <Snackbar
          open={Boolean(appState.toastTxt)}
          anchorOrigin={{ vertical: isSmallScreen ? 'bottom' : 'top', horizontal: 'center' }}
          autoHideDuration={10000}
          onClose={() => {
            appDispatch({ type: 'hideToast' });
          }}
        >
          <Alert
            onClose={() => {
              appDispatch({ type: 'hideToast' });
            }}
            severity={appState.toastSeverity}
            action={appState.toastAction}
          >
            <Typography variant="body1" dangerouslySetInnerHTML={{ __html: appState.toastTxt || '' }}></Typography>
          </Alert>
        </Snackbar>

        {/* App notifications */}
        <Snackbar
          open={Boolean(appState.notificationTxt)}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          autoHideDuration={2500}
          onClose={() => {
            appDispatch({ type: 'hideNotification' });
          }}
        >
          <SnackbarContent
            message={
              <Box display="flex">
                {appState.notificationAvatar && (
                  <Box>
                    <Avatar src={appState.notificationAvatar} />
                  </Box>
                )}
                <Box pl={2} display="flex" flexDirection="column">
                  <Box>
                    <b>{appState.notificationTitle}</b>
                  </Box>
                  <Box>{appState.notificationTxt}</Box>
                </Box>
              </Box>
            }
          />
        </Snackbar>

        {/* App Backdrop */}
        <Backdrop className={classes.backdrop} open={Boolean(appState.backdropTxt)}>
          <Paper elevation={0}>
            <Box p={2}>
              <Grid container alignItems="center" justifyContent="center" spacing={3}>
                <Grid item>
                  <img src={`${baseUrl}/static/images/loaders/peakz-wave-10.gif`} alt="loading..." width={125} />
                </Grid>
                <Grid item>
                  <Typography variant="body1">{appState.backdropTxt}</Typography>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Backdrop>
      </div>
    </ThemeProvider>
  );
}

async function initializeApp(authDispatch: Dispatch<AuthAction>, appDispatch: Dispatch<AppAction>) {
  let loggedUser: User;

  try {
    const authUser = await Auth.currentAuthenticatedUser({
      bypassCache: true, // If set to true, this call will send a request to Cognito to get the latest user data
    });

    loggedUser = await completeSignIn(authUser, authDispatch, appDispatch);
    updateUserFirebaseToken(appDispatch, loggedUser, firebaseToken);
  } catch (err) {
    // Nothing
  }

  // Set the system currencies config
  const currenciesConfig = await getCurrenciesConfig();
  appDispatch({
    type: 'setCurrenciesConfig',
    currenciesConfig: currenciesConfig,
  });

  // Set the user currency in the appState. Priority: logged user ccy -> cached ccy -> browser ccy -> default ccy
  if (loggedUser && loggedUser.currency) {
    appDispatch({
      type: 'setUserCurrencyConfig',
      userCurrencyConfig: { currencyCode: loggedUser.currency, userDefined: true },
    });
  } else {
    const currencyConfig = cacheHelpers.loadUserCurrencyConfig();

    if (currencyConfig) {
      appDispatch({ type: 'setUserCurrencyConfig', userCurrencyConfig: currencyConfig });
    } else {
      const clientCurrency = currenciesConfig.clientCurrency;

      appDispatch({
        type: 'setUserCurrencyConfig',
        userCurrencyConfig: {
          currencyCode: (clientCurrency || currenciesConfig.defaultCurrency).toLowerCase(),
          userDefined: false,
        },
      });
    }
  }

  authDispatch({ type: 'initialized' });
  appDispatch({ type: 'initialized' });
  return loggedUser;
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ children, setWaitForRedirection, ...rest }: any) {
  const [authState] = useContext(authStore);

  return (
    <Route
      {...rest}
      render={() => {
        setWaitForRedirection(false);
        if (userHelpers.isSignedUp(authState.user)) {
          return children;
        }
        return (
          <Redirect
            to={{
              pathname: '/',
            }}
          />
        );
      }}
    />
  );
}

function wrapRenderIntoRedirectNotice(children: JSX.Element, setWaitForRedirection: any) {
  setWaitForRedirection(false);
  return <FadeIn timeout={500}>{children}</FadeIn>;
}

// Used keep the user firebaseToken up to date (in case there is a user already logged in)
function updateUserFirebaseToken(appDispatch: React.Dispatch<AppAction>, user?: User, token?: string) {
  if (user && token) {
    if (!user.firebaseTokens || user.firebaseTokens.indexOf(token) === -1) {
      updateUser(
        user.id,
        {
          firebaseTokens: user.firebaseTokens ? user.firebaseTokens.concat(token) : [token],
        },
        appDispatch,
        false,
      );
      console.log('user firebase token updated');
    }
  }
}

export default withRouter(withRoot(App));
