import { Box } from '@material-ui/core';
import React from 'react';
import config from '../../config';

const SuspensePreloader: React.FC = () => {
  return (
    <Box alignItems="center" justifyContent="center" position="absolute" width={1}>
      <Box style={{ width: '100%', height: '100vh' }} display="flex" justifyContent="center" alignItems="center">
        <img width="100px" height="100px" src={`${config.baseUrl}/static/images/logos/Icon_logo_orange.png`} alt="" />
      </Box>
    </Box>
  );
};

export default SuspensePreloader;
