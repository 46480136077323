import React, { useEffect, useState } from 'react';
import Spinner from './Spinner';
import CSS from 'csstype';
import { useMeetingSession } from '../providers/MeetingSessionProvider';
import { BcastStatus } from '../../API';
import FilmCountdown from './FilmCountdown';
import Config from '../utils/config';

const BcastInfo: React.FC = () => {
  const { bcastRecord, bcastStep, attendee } = useMeetingSession();

  const [lastBcastStatus, setLastBcastStatus] = useState<string | undefined>(undefined);
  const [filmCountdown, setFilmCountdown] = useState<number>(-1);

  useEffect(() => {
    if (lastBcastStatus === BcastStatus.scheduled && bcastRecord?.status === BcastStatus.live) {
      const showFilmCountdown = (number: number) => {
        setFilmCountdown(number);
        setTimeout(() => {
          if (number > 0) {
            showFilmCountdown(number - 1);
          }
          // interval is intentionally little longer than a second
          // just to slow down the host on broadcast start even more ;)
        }, 1250);
      };
      showFilmCountdown(Config.FILM_COUNTDOWN_SECONDS);
    }
    setLastBcastStatus(bcastRecord?.status);
  }, [bcastRecord?.status]);

  const boxStyle: CSS.Properties = {
    position: 'absolute',
    top: '1rem',
    left: '1rem',
    padding: '0.3rem 0.5rem',
    borderRadius: '0.15rem',
  };

  return (
    <>
      {bcastStep === 'preparing' && (
        <div style={{ ...boxStyle, backgroundColor: 'black', color: 'white' }}>
          <Spinner
            style={{
              display: 'inline-block',
              marginRight: '0.5rem',
            }}
          />
          Please wait until the broadcast is prepared...
        </div>
      )}
      {bcastStep === 'prepared' && attendee && (
        <div style={{ ...boxStyle, backgroundColor: 'black', color: 'white' }}>
          {(attendee.type === 'host' || attendee.type === 'moderator') && (
            <>
              Not live yet! Press <strong style={{ color: 'orange' }}>Start</strong> broadcast to go live
            </>
          )}
          {!(attendee.type === 'host' || attendee.type === 'moderator') && (
            <>Not live yet! Waiting for the host to start the broadcast.</>
          )}
        </div>
      )}
      {bcastStep === 'starting' && <></>}
      {bcastStep === 'started' && (
        <div
          style={{
            ...boxStyle,
            backgroundColor: 'red',
            color: 'white',
            textShadow: '0 0 3px black',
            fontWeight: 'bold',
            paddingLeft: '0.5rem',
            paddingRight: '0.5rem',
          }}
        >
          LIVE
        </div>
      )}
      {bcastStep === 'stopping' && <></>}
      {bcastStep === 'finished' && (
        <div style={{ ...boxStyle, backgroundColor: 'black', color: 'white' }}>The broadcast has ended</div>
      )}
      {bcastStep === 'error' && (
        <div style={{ ...boxStyle, backgroundColor: 'black', color: 'red' }}>The broadcast has failed</div>
      )}

      {filmCountdown > 0 && <FilmCountdown number={filmCountdown} />}
    </>
  );
};

export default BcastInfo;
