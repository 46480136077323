import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import Dialog from '@material-ui/core/Dialog/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import DialogTitle from '../ui/DialogTitle';

interface Props {
  open: boolean;
  onReport: (reason: string) => void;
  onClose: () => void;
}

const ReportPostDialog: React.FC<Props> = ({ open, onReport, onClose }: Props) => {
  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const [reason, setReason] = React.useState<string>('');
  const handleReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReason((event.target as HTMLInputElement).value);
  };
  const handleReportClick = () => {
    if (reason) {
      onReport(reason);
    }
  };
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      open={open}
      fullScreen={!isBigScreen}
      maxWidth="xs"
      fullWidth={true}
      aria-labelledby="form-dialog-title"
      onClose={handleClose}
    >
      <DialogTitle id="form-dialog-title" onClose={handleClose}>
        Report post
      </DialogTitle>
      <DialogContent>
        <FormControl component="fieldset">
          <RadioGroup aria-label="gender" name="gender1" value={reason} onChange={handleReasonChange}>
            <FormControlLabel
              value="authenticity"
              control={<Radio />}
              label="This is a misrepresentation/spam (does not match the description)"
            />
            <FormControlLabel
              value="pornography"
              control={<Radio />}
              label="This contains hate speech and/or incites violence"
            />
            <FormControlLabel
              value="bullying"
              control={<Radio />}
              label="This contains bullying/harassing behaviours and/or speech"
            />
            <FormControlLabel
              value="sexual"
              control={<Radio />}
              label="This contains explicit and or sexual images and language"
            />
            <FormControlLabel
              value="harmful"
              control={<Radio />}
              label="This glorifies dangerous and harmful behaviours and/or stunts"
            />
            <FormControlLabel value="copyright" control={<Radio />} label="This is a copyright infringement" />
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button color="primary" disabled={!reason} onClick={handleReportClick}>
          Report
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReportPostDialog;
