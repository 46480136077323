import React from 'react';
import { Box } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useTheme } from '@material-ui/styles';
import { makeStyles } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';
import domHelpers from '../../core/helpers/dom';
import CTAButton from '../ui/CTAButton';
import { letterSpacing, PeakzTheme } from '../../theme/theme';

const useStyles = makeStyles((theme: PeakzTheme) => ({
  root: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    '& a': {
      color: 'white',
      '&:active, &:focus, &:visited': {
        color: 'white',
      },
    },
  },
  overlayStyle: {
    width: '100%',
    boxShadow: 'rgba(0, 0, 0, .75) 0 0 0 1000px inset',
    padding: theme.typography.pxToRem(30),
    letterSpacing: letterSpacing.minimal,
    bottom: '0',
  },
  clickableOverlay: {
    boxShadow: 'rgba(0, 0, 0, .75) 0 0 0 1000px inset',
    height: '100%',
    width: '100%',
    display: 'flex',
    top: '0',
    left: '0',
    bottom: '0',
    right: '0',
    justifyContent: 'center',
    alignItems: 'center',
  },
  smallLetterStyle: {
    fontSize: theme.typography.pxToRem(14),
  },
}));

//Parent component must have an index state controlling the hovering and pass it like a boolean prop
const HoverableContainer = ({
  title,
  description,
  slug,
  img,
  hovered,
  link,
  buttonText,
  small,
}: {
  title: string;
  description: string;
  slug?: string;
  img: string;
  hovered: boolean;
  link: string;
  buttonText: string;
  small: boolean;
}) => {
  const classes = useStyles();
  const theme: PeakzTheme = useTheme();

  //Adjust for the iPAD Pro alike devices, basically transition between MD and SM
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 1445));
  const isSmallScreen = domHelpers.isSmallScreen(useMediaQuery, theme);
  isSmallScreen || isTablet ? (hovered = false) : '';

  return (
    <Box className={classes.root} width={1} height={1} style={{ backgroundImage: `url(${img})` }}>
      <Link to={link}>
        <Box
          position="absolute"
          style={
            hovered
              ? { display: 'none' }
              : small
              ? { minHeight: theme.typography.pxToRem(100) }
              : isTablet
              ? { minHeight: theme.typography.pxToRem(260) }
              : { minHeight: theme.typography.pxToRem(100) }
          }
          className={!hovered ? classes.overlayStyle : ''}
        >
          <Typography
            noWrap
            className={small ? classes.smallLetterStyle : ''}
            style={{ letterSpacing: letterSpacing.tiny }}
            variant="body1"
            dangerouslySetInnerHTML={{ __html: title }}
          ></Typography>
          <Typography style={{ color: 'rgba(63,146,141,255)' }}>
            <b>{slug}</b>
          </Typography>
          <Typography variant="body2" style={isTablet ? { wordBreak: 'normal' } : {}}>
            {small ? description.substring(0, 45) + '...' : description.substring(0, 105) + '...'}
          </Typography>
        </Box>
      </Link>
      <Box
        position="absolute"
        style={!hovered ? { display: 'none' } : {}}
        data-testid="clickableOverlayContainer"
        className={hovered ? classes.clickableOverlay : ''}
      >
        {!isSmallScreen && !isTablet && (
          <Link rel="noopener" to={link}>
            <CTAButton variant="contained" color="primary" size="large">
              {buttonText}
            </CTAButton>
          </Link>
        )}
      </Box>
    </Box>
  );
};

export default HoverableContainer;
