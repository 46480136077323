import React from 'react';
import CSS from 'csstype';

// screen share (local & remote)

interface Props {
  id: string;
  debug: boolean;
}

const ShareTile: React.FC<Props> = (props: Props) => {
  const objectStyle: CSS.Properties = {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'top',
    overflow: 'hidden',
  };

  return (
    <>
      {props.debug && <img id={props.id} src="https://www.w3schools.com/html/pic_trulli.jpg" style={objectStyle} />}
      {!props.debug && <video id={props.id} style={objectStyle} />}
    </>
  );
};

export default ShareTile;
