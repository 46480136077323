import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import DashboardItem from './DashboardItem';
import SpinnerBox from './SpinnerBox';

const useStyles = makeStyles((theme) => ({
  height100: {
    height: '100%',
  },
  dashboardItem: {
    backgroundColor: theme.palette.grey[100],
  },
}));

interface Props {
  count: string;
  label: string;
  labelPos?: 'down' | 'up';
  loading?: boolean;
}
const DashboardCounter: React.FC<Props> = ({ count, label, labelPos = 'down', loading }: Props) => {
  const classes = useStyles();

  return (
    <DashboardItem>
      {/* Scouts */}
      <Grid className={classes.height100} container alignItems="center" justifyContent="center">
        {labelPos === 'up' && (
          <Grid item xs={12}>
            <Box mb={2}>
              <Typography variant="body1" align="center">
                {label}
              </Typography>
            </Box>
          </Grid>
        )}
        <Grid item xs={12}>
          {loading && <SpinnerBox />}
          {!loading && (
            <Box pr={1}>
              <Typography variant="h3" align="center">
                {count.replace(String.fromCharCode(160), ' ')}
              </Typography>
            </Box>
          )}
        </Grid>
        {labelPos === 'down' && (
          <Grid item xs={12}>
            <Typography variant="body1" align="center">
              {label}
            </Typography>
          </Grid>
        )}
      </Grid>
    </DashboardItem>
  );
};

export default DashboardCounter;
