import React, { useState, useEffect } from 'react';
import { secondsToDateTime } from '../utils/ics';

interface Props {
  time: string | undefined;
}

const Timer: React.FC<Props> = (props: Props) => {
  const [distance, setDistance] = useState(0);
  const [display, setDisplay] = useState('');

  useEffect(() => {
    const timer = setInterval(() => {
      if (props.time) {
        setDistance(secondsToDateTime(props.time));
      }
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  });

  useEffect(() => {
    const d = Math.abs(distance);
    const days = Math.floor(d / (60 * 60 * 24));
    const hours = Math.floor((d % (60 * 60 * 24)) / (60 * 60));
    const minutes = Math.floor((d % (60 * 60)) / 60);
    const seconds = Math.floor(d % 60);
    setDisplay(
      (distance > 0 ? '- ' : '+ ') +
        (days > 0 ? days + 'd ' : '') +
        (days > 0 || hours > 0 ? hours + 'h ' : '') +
        (days === 0 ? minutes + 'm ' : '') +
        (days === 0 && hours === 0 ? seconds + 's ' : ''),
    );
  }, [distance]);

  function getDisplayColor() {
    // paint it red for the last 3 minutes before start
    return distance > 0 && distance < 5 * 60 ? 'red' : 'orange';
  }

  return (
    <div>
      <h2
        style={{
          color: getDisplayColor(),
          display: 'inline',
          margin: '-3rem 1em 0 0',
          float: 'right',
        }}
      >
        {display}
      </h2>
    </div>
  );
};

export default Timer;
