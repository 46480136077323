let awsconfig;
let awsVideoConfig;

if (process.env.REACT_APP_AWS_EXPORTS) {
  try {
    awsconfig = JSON.parse(process.env.REACT_APP_AWS_EXPORTS);
  } catch (err) {
    console.log(err);
  }
} else {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const mod = require('./aws-exports');
  awsconfig = mod.default;
}

if (process.env.REACT_APP_AWS_VIDEO_EXPORTS) {
  try {
    awsVideoConfig = JSON.parse(process.env.REACT_APP_AWS_VIDEO_EXPORTS);
  } catch (err) {
    console.error(err);
  }
} else {
  // (*) This file only exists locally (during develepment). On the cloud, REACT_APP_AWS_VIDEO_EXPORT must be set
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const modVideo = require('./aws-video-exports');
  awsVideoConfig = modVideo.default;
}

export const awsExports = {
  ...awsconfig,
  oauth: {
    ...awsconfig.oauth,
    redirectSignIn: process.env.REACT_APP_AWS_OAUTH_REDIRECT_SIGN_IN,
    redirectSignOut: process.env.REACT_APP_AWS_OAUTH_REDIRECT_SIGN_OUT,
  },
};
export const awsVideoExports = {
  ...awsVideoConfig,
};
export const environment = process.env.NODE_ENV || '';
export const baseUrl = process.env.REACT_APP_BASE_URL || '';
export const imgBaseUrl = process.env.REACT_APP_IMG_BASE_URL || '';
export const assetBaseUrl = process.env.REACT_APP_ASSETS_BASE_URL || '';
export const videoBaseUrl = process.env.REACT_APP_VIDEO_BASE_URL || '';
export const siteTitle = process.env.REACT_APP_SITE_TITLE || 'Peakz';
export const siteDescription =
  process.env.REACT_APP_SITE_DESCRIPTION ||
  'The athlete scoutfunding platform, where athletes get scouted and funded, fans rewarded and brands boosted.';
export const facebookAppId = process.env.REACT_APP_FACEBOOK_APP_ID || '';
export const gaId = process.env.REACT_APP_GA_ID || '';
export const gaPeakzComId = process.env.REACT_APP_GA_PEAKZ_COM_ID || '';
export const gaDebug = process.env.REACT_APP_GA_DEBUG === 'true';
export const i18nDebug = process.env.REACT_APP_I18N_DEBUG === 'true';
export const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY || '';
export const s3AccelerationEnabled = process.env.REACT_APP_S3_ACCELERATION_ENABLED === 'true';
export const contentfulToken = process.env.REACT_APP_CONTENTFUL_TOKEN || '';
export const contentfulSpaceID = process.env.REACT_APP_CONTENTFUL_SPACE_ID;
export const pwdMinLen = 8;
export const termsLink = 'https://peakz.io/en/challenge-terms-and-conditions/';
export const recaptchaSiteKeyV2 = process.env.REACT_APP_RECAPTCHA_SITE_KEY_V2 || '';
export const recaptchaSiteKeyV3 = process.env.REACT_APP_RECAPTCHA_SITE_KEY_V3 || '';
export const firebaseApiKey = process.env.REACT_APP_FIREBASE_API_KEY || '';
export const firebaseProjectId = process.env.REACT_APP_FIREBASE_PROJECT_ID || '';
export const firebaseMessagingSenderId = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || '';
export const firebaseAppId = process.env.REACT_APP_FIREBASE_APP_ID || '';
export const firebaseClientKey = process.env.REACT_APP_FIREBASE_CLIENT_KEY || '';
export const disableBroadcast = true;

export default {
  awsExports,
  awsVideoExports,
  baseUrl,
  imgBaseUrl,
  assetBaseUrl,
  videoBaseUrl,
  siteTitle,
  siteDescription,
  facebookAppId,
  gaId,
  gaPeakzComId,
  gaDebug,
  i18nDebug,
  stripePublicKey,
  pwdMinLen,
  termsLink,
  s3AccelerationEnabled,
  recaptchaSiteKeyV2,
  recaptchaSiteKeyV3,
  firebaseApiKey,
  firebaseProjectId,
  firebaseMessagingSenderId,
  firebaseAppId,
  firebaseClientKey,
  newHomeUrl: 'https://www.peakz.com',
};

/* test-deploy2/1 */
