import { AthleteSubscription } from '../graphql/types';

export const isSubscriptionExpired = (subscription: AthleteSubscription): boolean => {
  const now = new Date();
  const subscriptionExpirationDate = subscription.expiresAt ? new Date(subscription.expiresAt) : now;

  return subscriptionExpirationDate <= now;
};

export const isSubscriptionActive = (subscription: AthleteSubscription): boolean => {
  return subscription.status === 'active';
};

export default {
  isSubscriptionExpired,
};
