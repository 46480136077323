import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDropzone, FileRejection } from 'react-dropzone';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
  AudioDropZoneRoot: {
    height: '100%',
  },
  content: {
    height: '100%',
  },
  fileIcon: {
    fontSize: theme.typography.pxToRem(90),
  },
  selectButton: {
    height: theme.typography.pxToRem(50),
    paddingLeft: theme.typography.pxToRem(30),
    paddingRight: theme.typography.pxToRem(30),
  },
}));

interface Props {
  maxFileSizeBytes?: number;
  className?: any;
  disabled?: boolean;
  fontSize?: 'lg' | 'sm';
  hideButton?: boolean;
  onRejected: (rejectedFiles: FileRejection[]) => void;
  onChange: (files: any) => void;
}

const AudioDropZone: React.FC<Props> = ({
  maxFileSizeBytes,
  className,
  disabled,
  fontSize = 'lg',
  hideButton,
  onRejected,
  onChange,
}: Props) => {
  const classes = useStyles();

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    accept: 'audio/mp3,audio/ogg,audio/aac,audio/wav,audio/mpeg,audio/mpeg4,audio/m4a,audio/*',
    maxFiles: 1,
    multiple: false,
    noClick: true,
    noKeyboard: true,
    disabled: disabled,
    maxSize: maxFileSizeBytes || 2000000000,
    onDrop: async (acceptedFiles: any, fileRejections: any) => {
      if (fileRejections && fileRejections.length > 0) {
        onRejected(fileRejections);
      }
      // All validations passed
      onChange(acceptedFiles);
    },
  });
  const { ...rootProps } = getRootProps();

  return (
    <div className={`${classes.AudioDropZoneRoot} ${className}`} {...rootProps}>
      <Box p={2} width={1} height={1}>
        <input {...getInputProps()} />
        <Grid className={classes.content} container direction="column" alignItems="center" justifyContent="center">
          <Grid item>
            {!hideButton && <CloudUploadIcon className={classes.fileIcon} />}
            {hideButton && (
              <IconButton onClick={open}>
                <CloudUploadIcon className={classes.fileIcon} />
              </IconButton>
            )}
          </Grid>
          <Grid item>
            <Box mb={fontSize === 'lg' ? 4 : 2}>
              <Grid container justifyContent="center">
                <Grid item xs={12} sm={9}>
                  <Typography variant={fontSize == 'lg' ? 'h5' : 'body1'} align="center">
                    {isDragActive ? 'Drop the file here' : 'Drag and drop your file'}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          {!hideButton && (
            <Grid item>
              <Box mb={1}>
                {fontSize === 'lg' && (
                  <Button className={classes.selectButton} variant="contained" color="primary" onClick={open}>
                    <Typography variant="h6" align="center">
                      or choose a file
                    </Typography>
                  </Button>
                )}
                {fontSize !== 'lg' && (
                  <Button variant="contained" color="primary" onClick={open}>
                    or choose a file
                  </Button>
                )}
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
    </div>
  );
};

export default AudioDropZone;
