/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const isUserCustomer = /* GraphQL */ `
  query IsUserCustomer {
    isUserCustomer
  }
`;
export const listPaymentMethods = /* GraphQL */ `
  query ListPaymentMethods {
    listPaymentMethods {
      id
      type
      billing_details {
        address {
          city
          country
          line1
          line2
          postal_code
          state
        }
        email
        name
        phone
      }
      card {
        brand
        country
        exp_month
        exp_year
        last4
      }
      created
      customer
      is_default
    }
  }
`;
export const listInvoices = /* GraphQL */ `
  query ListInvoices {
    listInvoices {
      id
      number
      paid
      period_start
      period_end
      total
      currency
      invoice_pdf
      created
    }
  }
`;
export const getBalance = /* GraphQL */ `
  query GetBalance {
    getBalance {
      available {
        amount
        currency
      }
      pending {
        amount
        currency
      }
    }
  }
`;
export const getBalanceTransactions = /* GraphQL */ `
  query GetBalanceTransactions(
    $type: String!
    $startingAfter: String
    $limit: Int
  ) {
    getBalanceTransactions(
      type: $type
      startingAfter: $startingAfter
      limit: $limit
    ) {
      data {
        id
        amount
        available_on
        created
        currency
        description
        exchange_rate
        fee
        net
        reporting_category
        status
        type
      }
      has_more
    }
  }
`;
export const getPayouts = /* GraphQL */ `
  query GetPayouts {
    getPayouts {
      data {
        id
        amount
        arrival_date
        automatic
        created
        currency
        description
        source_type
        status
        type
      }
      has_more
    }
  }
`;
export const getPaymentsAccount = /* GraphQL */ `
  query GetPaymentsAccount {
    getPaymentsAccount {
      id
      business_profile {
        name
        product_description
        support_address {
          city
          country
          line1
          line2
          postal_code
          state
        }
        support_email
        support_phone
        support_url
        url
      }
      charges_enabled
      country
      created
      default_currency
      details_submitted
      email
      payouts_enabled
      requirements {
        current_deadline
        currently_due
        disabled_reason
        errors {
          code
          reason
          requirement
        }
        eventually_due
        past_due
        pending_verification
      }
    }
  }
`;
export const getPaymentsAccountLink = /* GraphQL */ `
  query GetPaymentsAccountLink($refreshUrl: String!, $returnUrl: String!) {
    getPaymentsAccountLink(refreshUrl: $refreshUrl, returnUrl: $returnUrl)
  }
`;
export const getTaxRateByCountry = /* GraphQL */ `
  query GetTaxRateByCountry($country: String!) {
    getTaxRateByCountry(country: $country) {
      id
      percentage
      displayName
      country
    }
  }
`;
export const getFullPost = /* GraphQL */ `
  query GetFullPost($postId: ID!) {
    getFullPost(postId: $postId) {
      id
      authorId
      name
      description
      needsSubscription
      type
      tierId
      tier {
        id
        userId
        name
        currency
        unitAmount
        discountType
        discount
        discountMonths
        couponId
        createdAt
        updatedAt
      }
      thumbS3Key
      comments
      vodId
      vodToken
      vodStatus
      vodMetadata {
        durationInMs
        orgWidth
        orgHeight
        hasPreview
      }
      bcastMetadata {
        previewImageS3Key
        time
        duration
        guests {
          id
          name
        }
        allowQuestions
        status
        bcastUrl
        recordingS3Key
      }
      likes
      liked
      reviewStatus
      feedId
      createdAt
    }
  }
`;
export const getCurrenciesConfig = /* GraphQL */ `
  query GetCurrenciesConfig {
    getCurrenciesConfig {
      defaultCurrency
      clientCurrency
      currencies {
        code
        symbol
        round
      }
      conversionsTable
    }
  }
`;
export const getViewerCurrency = /* GraphQL */ `
  query GetViewerCurrency {
    getViewerCurrency
  }
`;
export const listUsersByStripeAccountId = /* GraphQL */ `
  query ListUsersByStripeAccountId(
    $stripeAccountId: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsersByStripeAccountId(
      stripeAccountId: $stripeAccountId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        givenName
        familyName
        gender
        birthdate
        country
        sport
        profileImgS3Key
        coverImgS3Key
        introVideoS3Key
        introVideoThumbS3Key
        introVideoUrl
        mission
        instagramUrl
        facebookUrl
        youtubeUrl
        goals {
          id
          name
          level
          description
          videoUrl
          link
        }
        achievements {
          id
          name
          level
          description
          link
          videoUrl
          date
        }
        fundingGoals {
          id
          amount
          currency
          description
        }
        sponsors {
          id
          imgS3Key
        }
        emailValidated
        validationEmailSentAt
        potentialAthlete
        currency
        themeId
        role
        nickname
        stripePayoutsEnabled
        payoutCurrency
        stats {
          numUniqueViews
          numScouts
          paidByScouts
          numPosts
        }
        features {
          posts
        }
        stripeCustomerId
        ivsChannelArn
        createdAt
        updatedAt
        owner
        email
        stripeActionRequired
        stripeAccountId
        social {
          fbUserId
          fbAccessToken
          fbTargetPageId
          twitterRequestToken
          twitterRequestSecret
          twitterAccessToken
          twitterAccessSecret
          instagramUserId
        }
        firebaseTokens
      }
      nextToken
    }
  }
`;
export const listUsersByRole = /* GraphQL */ `
  query ListUsersByRole(
    $role: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsersByRole(
      role: $role
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        givenName
        familyName
        gender
        birthdate
        country
        sport
        profileImgS3Key
        coverImgS3Key
        introVideoS3Key
        introVideoThumbS3Key
        introVideoUrl
        mission
        instagramUrl
        facebookUrl
        youtubeUrl
        goals {
          id
          name
          level
          description
          videoUrl
          link
        }
        achievements {
          id
          name
          level
          description
          link
          videoUrl
          date
        }
        fundingGoals {
          id
          amount
          currency
          description
        }
        sponsors {
          id
          imgS3Key
        }
        emailValidated
        validationEmailSentAt
        potentialAthlete
        currency
        themeId
        role
        nickname
        stripePayoutsEnabled
        payoutCurrency
        stats {
          numUniqueViews
          numScouts
          paidByScouts
          numPosts
        }
        features {
          posts
        }
        stripeCustomerId
        ivsChannelArn
        createdAt
        updatedAt
        owner
        email
        stripeActionRequired
        stripeAccountId
        social {
          fbUserId
          fbAccessToken
          fbTargetPageId
          twitterRequestToken
          twitterRequestSecret
          twitterAccessToken
          twitterAccessSecret
          instagramUserId
        }
        firebaseTokens
      }
      nextToken
    }
  }
`;
export const listUsersByNickname = /* GraphQL */ `
  query ListUsersByNickname(
    $nickname: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsersByNickname(
      nickname: $nickname
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        givenName
        familyName
        gender
        birthdate
        country
        sport
        profileImgS3Key
        coverImgS3Key
        introVideoS3Key
        introVideoThumbS3Key
        introVideoUrl
        mission
        instagramUrl
        facebookUrl
        youtubeUrl
        goals {
          id
          name
          level
          description
          videoUrl
          link
        }
        achievements {
          id
          name
          level
          description
          link
          videoUrl
          date
        }
        fundingGoals {
          id
          amount
          currency
          description
        }
        sponsors {
          id
          imgS3Key
        }
        emailValidated
        validationEmailSentAt
        potentialAthlete
        currency
        themeId
        role
        nickname
        stripePayoutsEnabled
        payoutCurrency
        stats {
          numUniqueViews
          numScouts
          paidByScouts
          numPosts
        }
        features {
          posts
        }
        stripeCustomerId
        ivsChannelArn
        createdAt
        updatedAt
        owner
        email
        stripeActionRequired
        stripeAccountId
        social {
          fbUserId
          fbAccessToken
          fbTargetPageId
          twitterRequestToken
          twitterRequestSecret
          twitterAccessToken
          twitterAccessSecret
          instagramUserId
        }
        firebaseTokens
      }
      nextToken
    }
  }
`;
export const searchUsers = /* GraphQL */ `
  query SearchUsers(
    $filter: SearchableUserFilterInput
    $sort: SearchableUserSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchUsers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        name
        givenName
        familyName
        gender
        birthdate
        country
        sport
        profileImgS3Key
        coverImgS3Key
        introVideoS3Key
        introVideoThumbS3Key
        introVideoUrl
        mission
        instagramUrl
        facebookUrl
        youtubeUrl
        goals {
          id
          name
          level
          description
          videoUrl
          link
        }
        achievements {
          id
          name
          level
          description
          link
          videoUrl
          date
        }
        fundingGoals {
          id
          amount
          currency
          description
        }
        sponsors {
          id
          imgS3Key
        }
        emailValidated
        validationEmailSentAt
        potentialAthlete
        currency
        themeId
        role
        nickname
        stripePayoutsEnabled
        payoutCurrency
        stats {
          numUniqueViews
          numScouts
          paidByScouts
          numPosts
        }
        features {
          posts
        }
        stripeCustomerId
        ivsChannelArn
        createdAt
        updatedAt
        owner
        email
        stripeActionRequired
        stripeAccountId
        social {
          fbUserId
          fbAccessToken
          fbTargetPageId
          twitterRequestToken
          twitterRequestSecret
          twitterAccessToken
          twitterAccessSecret
          instagramUserId
        }
        firebaseTokens
      }
      nextToken
      total {
        value
        relation
      }
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      name
      givenName
      familyName
      gender
      birthdate
      country
      sport
      profileImgS3Key
      coverImgS3Key
      introVideoS3Key
      introVideoThumbS3Key
      introVideoUrl
      mission
      instagramUrl
      facebookUrl
      youtubeUrl
      goals {
        id
        name
        level
        description
        videoUrl
        link
      }
      achievements {
        id
        name
        level
        description
        link
        videoUrl
        date
      }
      fundingGoals {
        id
        amount
        currency
        description
      }
      sponsors {
        id
        imgS3Key
      }
      emailValidated
      validationEmailSentAt
      potentialAthlete
      currency
      themeId
      role
      nickname
      stripePayoutsEnabled
      payoutCurrency
      stats {
        numUniqueViews
        numScouts
        paidByScouts
        numPosts
      }
      features {
        posts
      }
      stripeCustomerId
      ivsChannelArn
      createdAt
      updatedAt
      owner
      email
      stripeActionRequired
      stripeAccountId
      social {
        fbUserId
        fbAccessToken
        fbTargetPageId
        twitterRequestToken
        twitterRequestSecret
        twitterAccessToken
        twitterAccessSecret
        instagramUserId
      }
      firebaseTokens
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $id: ID
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        givenName
        familyName
        gender
        birthdate
        country
        sport
        profileImgS3Key
        coverImgS3Key
        introVideoS3Key
        introVideoThumbS3Key
        introVideoUrl
        mission
        instagramUrl
        facebookUrl
        youtubeUrl
        goals {
          id
          name
          level
          description
          videoUrl
          link
        }
        achievements {
          id
          name
          level
          description
          link
          videoUrl
          date
        }
        fundingGoals {
          id
          amount
          currency
          description
        }
        sponsors {
          id
          imgS3Key
        }
        emailValidated
        validationEmailSentAt
        potentialAthlete
        currency
        themeId
        role
        nickname
        stripePayoutsEnabled
        payoutCurrency
        stats {
          numUniqueViews
          numScouts
          paidByScouts
          numPosts
        }
        features {
          posts
        }
        stripeCustomerId
        ivsChannelArn
        createdAt
        updatedAt
        owner
        email
        stripeActionRequired
        stripeAccountId
        social {
          fbUserId
          fbAccessToken
          fbTargetPageId
          twitterRequestToken
          twitterRequestSecret
          twitterAccessToken
          twitterAccessSecret
          instagramUserId
        }
        firebaseTokens
      }
      nextToken
    }
  }
`;
export const getPost = /* GraphQL */ `
  query GetPost($id: ID!) {
    getPost(id: $id) {
      id
      authorId
      name
      description
      needsSubscription
      type
      tierId
      vodId
      vodStatus
      vodMetadata {
        durationInMs
        orgWidth
        orgHeight
        hasPreview
      }
      bcastMetadata {
        previewImageS3Key
        time
        duration
        guests {
          id
          name
        }
        allowQuestions
        status
        bcastUrl
        recordingS3Key
      }
      thumbS3Key
      likes
      comments
      liked
      reviewStatus
      feedId
      createdAt
      updatedAt
      author {
        id
        name
        givenName
        familyName
        gender
        birthdate
        country
        sport
        profileImgS3Key
        coverImgS3Key
        introVideoS3Key
        introVideoThumbS3Key
        introVideoUrl
        mission
        instagramUrl
        facebookUrl
        youtubeUrl
        goals {
          id
          name
          level
          description
          videoUrl
          link
        }
        achievements {
          id
          name
          level
          description
          link
          videoUrl
          date
        }
        fundingGoals {
          id
          amount
          currency
          description
        }
        sponsors {
          id
          imgS3Key
        }
        emailValidated
        validationEmailSentAt
        potentialAthlete
        currency
        themeId
        role
        nickname
        stripePayoutsEnabled
        payoutCurrency
        stats {
          numUniqueViews
          numScouts
          paidByScouts
          numPosts
        }
        features {
          posts
        }
        stripeCustomerId
        ivsChannelArn
        createdAt
        updatedAt
        owner
        email
        stripeActionRequired
        stripeAccountId
        social {
          fbUserId
          fbAccessToken
          fbTargetPageId
          twitterRequestToken
          twitterRequestSecret
          twitterAccessToken
          twitterAccessSecret
          instagramUserId
        }
        firebaseTokens
      }
      tier {
        id
        userId
        name
        currency
        unitAmount
        discountType
        discount
        discountMonths
        couponId
        createdAt
        updatedAt
      }
    }
  }
`;
export const listPosts = /* GraphQL */ `
  query ListPosts(
    $id: ID
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPosts(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        authorId
        name
        description
        needsSubscription
        type
        tierId
        vodId
        vodStatus
        vodMetadata {
          durationInMs
          orgWidth
          orgHeight
          hasPreview
        }
        bcastMetadata {
          previewImageS3Key
          time
          duration
          allowQuestions
          status
          bcastUrl
          recordingS3Key
        }
        thumbS3Key
        likes
        comments
        liked
        reviewStatus
        feedId
        createdAt
        updatedAt
        author {
          id
          name
          givenName
          familyName
          gender
          birthdate
          country
          sport
          profileImgS3Key
          coverImgS3Key
          introVideoS3Key
          introVideoThumbS3Key
          introVideoUrl
          mission
          instagramUrl
          facebookUrl
          youtubeUrl
          emailValidated
          validationEmailSentAt
          potentialAthlete
          currency
          themeId
          role
          nickname
          stripePayoutsEnabled
          payoutCurrency
          stripeCustomerId
          ivsChannelArn
          createdAt
          updatedAt
          owner
          email
          stripeActionRequired
          stripeAccountId
          firebaseTokens
        }
        tier {
          id
          userId
          name
          currency
          unitAmount
          discountType
          discount
          discountMonths
          couponId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listPostsByAuthor = /* GraphQL */ `
  query ListPostsByAuthor(
    $authorId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPostsByAuthor(
      authorId: $authorId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        authorId
        name
        description
        needsSubscription
        type
        tierId
        vodId
        vodStatus
        vodMetadata {
          durationInMs
          orgWidth
          orgHeight
          hasPreview
        }
        bcastMetadata {
          previewImageS3Key
          time
          duration
          allowQuestions
          status
          bcastUrl
          recordingS3Key
        }
        thumbS3Key
        likes
        comments
        liked
        reviewStatus
        feedId
        createdAt
        updatedAt
        author {
          id
          name
          givenName
          familyName
          gender
          birthdate
          country
          sport
          profileImgS3Key
          coverImgS3Key
          introVideoS3Key
          introVideoThumbS3Key
          introVideoUrl
          mission
          instagramUrl
          facebookUrl
          youtubeUrl
          emailValidated
          validationEmailSentAt
          potentialAthlete
          currency
          themeId
          role
          nickname
          stripePayoutsEnabled
          payoutCurrency
          stripeCustomerId
          ivsChannelArn
          createdAt
          updatedAt
          owner
          email
          stripeActionRequired
          stripeAccountId
          firebaseTokens
        }
        tier {
          id
          userId
          name
          currency
          unitAmount
          discountType
          discount
          discountMonths
          couponId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listPostsByVodId = /* GraphQL */ `
  query ListPostsByVodId(
    $vodId: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPostsByVodId(
      vodId: $vodId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        authorId
        name
        description
        needsSubscription
        type
        tierId
        vodId
        vodStatus
        vodMetadata {
          durationInMs
          orgWidth
          orgHeight
          hasPreview
        }
        bcastMetadata {
          previewImageS3Key
          time
          duration
          allowQuestions
          status
          bcastUrl
          recordingS3Key
        }
        thumbS3Key
        likes
        comments
        liked
        reviewStatus
        feedId
        createdAt
        updatedAt
        author {
          id
          name
          givenName
          familyName
          gender
          birthdate
          country
          sport
          profileImgS3Key
          coverImgS3Key
          introVideoS3Key
          introVideoThumbS3Key
          introVideoUrl
          mission
          instagramUrl
          facebookUrl
          youtubeUrl
          emailValidated
          validationEmailSentAt
          potentialAthlete
          currency
          themeId
          role
          nickname
          stripePayoutsEnabled
          payoutCurrency
          stripeCustomerId
          ivsChannelArn
          createdAt
          updatedAt
          owner
          email
          stripeActionRequired
          stripeAccountId
          firebaseTokens
        }
        tier {
          id
          userId
          name
          currency
          unitAmount
          discountType
          discount
          discountMonths
          couponId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listPostsByFeedId = /* GraphQL */ `
  query ListPostsByFeedId(
    $feedId: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPostsByFeedId(
      feedId: $feedId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        authorId
        name
        description
        needsSubscription
        type
        tierId
        vodId
        vodStatus
        vodMetadata {
          durationInMs
          orgWidth
          orgHeight
          hasPreview
        }
        bcastMetadata {
          previewImageS3Key
          time
          duration
          allowQuestions
          status
          bcastUrl
          recordingS3Key
        }
        thumbS3Key
        likes
        comments
        liked
        reviewStatus
        feedId
        createdAt
        updatedAt
        author {
          id
          name
          givenName
          familyName
          gender
          birthdate
          country
          sport
          profileImgS3Key
          coverImgS3Key
          introVideoS3Key
          introVideoThumbS3Key
          introVideoUrl
          mission
          instagramUrl
          facebookUrl
          youtubeUrl
          emailValidated
          validationEmailSentAt
          potentialAthlete
          currency
          themeId
          role
          nickname
          stripePayoutsEnabled
          payoutCurrency
          stripeCustomerId
          ivsChannelArn
          createdAt
          updatedAt
          owner
          email
          stripeActionRequired
          stripeAccountId
          firebaseTokens
        }
        tier {
          id
          userId
          name
          currency
          unitAmount
          discountType
          discount
          discountMonths
          couponId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const searchPosts = /* GraphQL */ `
  query SearchPosts(
    $filter: SearchablePostFilterInput
    $sort: SearchablePostSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchPosts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        authorId
        name
        description
        needsSubscription
        type
        tierId
        vodId
        vodStatus
        vodMetadata {
          durationInMs
          orgWidth
          orgHeight
          hasPreview
        }
        bcastMetadata {
          previewImageS3Key
          time
          duration
          allowQuestions
          status
          bcastUrl
          recordingS3Key
        }
        thumbS3Key
        likes
        comments
        liked
        reviewStatus
        feedId
        createdAt
        updatedAt
        author {
          id
          name
          givenName
          familyName
          gender
          birthdate
          country
          sport
          profileImgS3Key
          coverImgS3Key
          introVideoS3Key
          introVideoThumbS3Key
          introVideoUrl
          mission
          instagramUrl
          facebookUrl
          youtubeUrl
          emailValidated
          validationEmailSentAt
          potentialAthlete
          currency
          themeId
          role
          nickname
          stripePayoutsEnabled
          payoutCurrency
          stripeCustomerId
          ivsChannelArn
          createdAt
          updatedAt
          owner
          email
          stripeActionRequired
          stripeAccountId
          firebaseTokens
        }
        tier {
          id
          userId
          name
          currency
          unitAmount
          discountType
          discount
          discountMonths
          couponId
          createdAt
          updatedAt
        }
      }
      nextToken
      total {
        value
        relation
      }
    }
  }
`;
export const getAthleteSubscription = /* GraphQL */ `
  query GetAthleteSubscription($subscriberId: ID!, $athleteId: ID!) {
    getAthleteSubscription(subscriberId: $subscriberId, athleteId: $athleteId) {
      subscriberId
      athleteId
      tierId
      currency
      unitAmount
      paidToAthlete
      status
      cancelAtPeriodEnd
      currentPeriodEnd
      expiresAt
      discountType
      discount
      discountRemainingMonths
      couponId
      taxRateId
      updatedAt
      createdAt
      stripeSubscriptionId
      stripePriceId
      subscriber {
        id
        name
        givenName
        familyName
        gender
        birthdate
        country
        sport
        profileImgS3Key
        coverImgS3Key
        introVideoS3Key
        introVideoThumbS3Key
        introVideoUrl
        mission
        instagramUrl
        facebookUrl
        youtubeUrl
        goals {
          id
          name
          level
          description
          videoUrl
          link
        }
        achievements {
          id
          name
          level
          description
          link
          videoUrl
          date
        }
        fundingGoals {
          id
          amount
          currency
          description
        }
        sponsors {
          id
          imgS3Key
        }
        emailValidated
        validationEmailSentAt
        potentialAthlete
        currency
        themeId
        role
        nickname
        stripePayoutsEnabled
        payoutCurrency
        stats {
          numUniqueViews
          numScouts
          paidByScouts
          numPosts
        }
        features {
          posts
        }
        stripeCustomerId
        ivsChannelArn
        createdAt
        updatedAt
        owner
        email
        stripeActionRequired
        stripeAccountId
        social {
          fbUserId
          fbAccessToken
          fbTargetPageId
          twitterRequestToken
          twitterRequestSecret
          twitterAccessToken
          twitterAccessSecret
          instagramUserId
        }
        firebaseTokens
      }
      athlete {
        id
        name
        givenName
        familyName
        gender
        birthdate
        country
        sport
        profileImgS3Key
        coverImgS3Key
        introVideoS3Key
        introVideoThumbS3Key
        introVideoUrl
        mission
        instagramUrl
        facebookUrl
        youtubeUrl
        goals {
          id
          name
          level
          description
          videoUrl
          link
        }
        achievements {
          id
          name
          level
          description
          link
          videoUrl
          date
        }
        fundingGoals {
          id
          amount
          currency
          description
        }
        sponsors {
          id
          imgS3Key
        }
        emailValidated
        validationEmailSentAt
        potentialAthlete
        currency
        themeId
        role
        nickname
        stripePayoutsEnabled
        payoutCurrency
        stats {
          numUniqueViews
          numScouts
          paidByScouts
          numPosts
        }
        features {
          posts
        }
        stripeCustomerId
        ivsChannelArn
        createdAt
        updatedAt
        owner
        email
        stripeActionRequired
        stripeAccountId
        social {
          fbUserId
          fbAccessToken
          fbTargetPageId
          twitterRequestToken
          twitterRequestSecret
          twitterAccessToken
          twitterAccessSecret
          instagramUserId
        }
        firebaseTokens
      }
      tier {
        id
        userId
        name
        currency
        unitAmount
        discountType
        discount
        discountMonths
        couponId
        createdAt
        updatedAt
      }
    }
  }
`;
export const listAthleteSubscriptions = /* GraphQL */ `
  query ListAthleteSubscriptions(
    $subscriberId: ID
    $athleteId: ModelIDKeyConditionInput
    $filter: ModelAthleteSubscriptionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAthleteSubscriptions(
      subscriberId: $subscriberId
      athleteId: $athleteId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        subscriberId
        athleteId
        tierId
        currency
        unitAmount
        paidToAthlete
        status
        cancelAtPeriodEnd
        currentPeriodEnd
        expiresAt
        discountType
        discount
        discountRemainingMonths
        couponId
        taxRateId
        updatedAt
        createdAt
        stripeSubscriptionId
        stripePriceId
        subscriber {
          id
          name
          givenName
          familyName
          gender
          birthdate
          country
          sport
          profileImgS3Key
          coverImgS3Key
          introVideoS3Key
          introVideoThumbS3Key
          introVideoUrl
          mission
          instagramUrl
          facebookUrl
          youtubeUrl
          emailValidated
          validationEmailSentAt
          potentialAthlete
          currency
          themeId
          role
          nickname
          stripePayoutsEnabled
          payoutCurrency
          stripeCustomerId
          ivsChannelArn
          createdAt
          updatedAt
          owner
          email
          stripeActionRequired
          stripeAccountId
          firebaseTokens
        }
        athlete {
          id
          name
          givenName
          familyName
          gender
          birthdate
          country
          sport
          profileImgS3Key
          coverImgS3Key
          introVideoS3Key
          introVideoThumbS3Key
          introVideoUrl
          mission
          instagramUrl
          facebookUrl
          youtubeUrl
          emailValidated
          validationEmailSentAt
          potentialAthlete
          currency
          themeId
          role
          nickname
          stripePayoutsEnabled
          payoutCurrency
          stripeCustomerId
          ivsChannelArn
          createdAt
          updatedAt
          owner
          email
          stripeActionRequired
          stripeAccountId
          firebaseTokens
        }
        tier {
          id
          userId
          name
          currency
          unitAmount
          discountType
          discount
          discountMonths
          couponId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listSubscriptionsBySubscriber = /* GraphQL */ `
  query ListSubscriptionsBySubscriber(
    $subscriberId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAthleteSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubscriptionsBySubscriber(
      subscriberId: $subscriberId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        subscriberId
        athleteId
        tierId
        currency
        unitAmount
        paidToAthlete
        status
        cancelAtPeriodEnd
        currentPeriodEnd
        expiresAt
        discountType
        discount
        discountRemainingMonths
        couponId
        taxRateId
        updatedAt
        createdAt
        stripeSubscriptionId
        stripePriceId
        subscriber {
          id
          name
          givenName
          familyName
          gender
          birthdate
          country
          sport
          profileImgS3Key
          coverImgS3Key
          introVideoS3Key
          introVideoThumbS3Key
          introVideoUrl
          mission
          instagramUrl
          facebookUrl
          youtubeUrl
          emailValidated
          validationEmailSentAt
          potentialAthlete
          currency
          themeId
          role
          nickname
          stripePayoutsEnabled
          payoutCurrency
          stripeCustomerId
          ivsChannelArn
          createdAt
          updatedAt
          owner
          email
          stripeActionRequired
          stripeAccountId
          firebaseTokens
        }
        athlete {
          id
          name
          givenName
          familyName
          gender
          birthdate
          country
          sport
          profileImgS3Key
          coverImgS3Key
          introVideoS3Key
          introVideoThumbS3Key
          introVideoUrl
          mission
          instagramUrl
          facebookUrl
          youtubeUrl
          emailValidated
          validationEmailSentAt
          potentialAthlete
          currency
          themeId
          role
          nickname
          stripePayoutsEnabled
          payoutCurrency
          stripeCustomerId
          ivsChannelArn
          createdAt
          updatedAt
          owner
          email
          stripeActionRequired
          stripeAccountId
          firebaseTokens
        }
        tier {
          id
          userId
          name
          currency
          unitAmount
          discountType
          discount
          discountMonths
          couponId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listSubscriptionsByAthlete = /* GraphQL */ `
  query ListSubscriptionsByAthlete(
    $athleteId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAthleteSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubscriptionsByAthlete(
      athleteId: $athleteId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        subscriberId
        athleteId
        tierId
        currency
        unitAmount
        paidToAthlete
        status
        cancelAtPeriodEnd
        currentPeriodEnd
        expiresAt
        discountType
        discount
        discountRemainingMonths
        couponId
        taxRateId
        updatedAt
        createdAt
        stripeSubscriptionId
        stripePriceId
        subscriber {
          id
          name
          givenName
          familyName
          gender
          birthdate
          country
          sport
          profileImgS3Key
          coverImgS3Key
          introVideoS3Key
          introVideoThumbS3Key
          introVideoUrl
          mission
          instagramUrl
          facebookUrl
          youtubeUrl
          emailValidated
          validationEmailSentAt
          potentialAthlete
          currency
          themeId
          role
          nickname
          stripePayoutsEnabled
          payoutCurrency
          stripeCustomerId
          ivsChannelArn
          createdAt
          updatedAt
          owner
          email
          stripeActionRequired
          stripeAccountId
          firebaseTokens
        }
        athlete {
          id
          name
          givenName
          familyName
          gender
          birthdate
          country
          sport
          profileImgS3Key
          coverImgS3Key
          introVideoS3Key
          introVideoThumbS3Key
          introVideoUrl
          mission
          instagramUrl
          facebookUrl
          youtubeUrl
          emailValidated
          validationEmailSentAt
          potentialAthlete
          currency
          themeId
          role
          nickname
          stripePayoutsEnabled
          payoutCurrency
          stripeCustomerId
          ivsChannelArn
          createdAt
          updatedAt
          owner
          email
          stripeActionRequired
          stripeAccountId
          firebaseTokens
        }
        tier {
          id
          userId
          name
          currency
          unitAmount
          discountType
          discount
          discountMonths
          couponId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listSubscriptionsByStripeSubscriptionId = /* GraphQL */ `
  query ListSubscriptionsByStripeSubscriptionId(
    $stripeSubscriptionId: String
    $sortDirection: ModelSortDirection
    $filter: ModelAthleteSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubscriptionsByStripeSubscriptionId(
      stripeSubscriptionId: $stripeSubscriptionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        subscriberId
        athleteId
        tierId
        currency
        unitAmount
        paidToAthlete
        status
        cancelAtPeriodEnd
        currentPeriodEnd
        expiresAt
        discountType
        discount
        discountRemainingMonths
        couponId
        taxRateId
        updatedAt
        createdAt
        stripeSubscriptionId
        stripePriceId
        subscriber {
          id
          name
          givenName
          familyName
          gender
          birthdate
          country
          sport
          profileImgS3Key
          coverImgS3Key
          introVideoS3Key
          introVideoThumbS3Key
          introVideoUrl
          mission
          instagramUrl
          facebookUrl
          youtubeUrl
          emailValidated
          validationEmailSentAt
          potentialAthlete
          currency
          themeId
          role
          nickname
          stripePayoutsEnabled
          payoutCurrency
          stripeCustomerId
          ivsChannelArn
          createdAt
          updatedAt
          owner
          email
          stripeActionRequired
          stripeAccountId
          firebaseTokens
        }
        athlete {
          id
          name
          givenName
          familyName
          gender
          birthdate
          country
          sport
          profileImgS3Key
          coverImgS3Key
          introVideoS3Key
          introVideoThumbS3Key
          introVideoUrl
          mission
          instagramUrl
          facebookUrl
          youtubeUrl
          emailValidated
          validationEmailSentAt
          potentialAthlete
          currency
          themeId
          role
          nickname
          stripePayoutsEnabled
          payoutCurrency
          stripeCustomerId
          ivsChannelArn
          createdAt
          updatedAt
          owner
          email
          stripeActionRequired
          stripeAccountId
          firebaseTokens
        }
        tier {
          id
          userId
          name
          currency
          unitAmount
          discountType
          discount
          discountMonths
          couponId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const searchAthleteSubscriptions = /* GraphQL */ `
  query SearchAthleteSubscriptions(
    $filter: SearchableAthleteSubscriptionFilterInput
    $sort: SearchableAthleteSubscriptionSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchAthleteSubscriptions(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        subscriberId
        athleteId
        tierId
        currency
        unitAmount
        paidToAthlete
        status
        cancelAtPeriodEnd
        currentPeriodEnd
        expiresAt
        discountType
        discount
        discountRemainingMonths
        couponId
        taxRateId
        updatedAt
        createdAt
        stripeSubscriptionId
        stripePriceId
        subscriber {
          id
          name
          givenName
          familyName
          gender
          birthdate
          country
          sport
          profileImgS3Key
          coverImgS3Key
          introVideoS3Key
          introVideoThumbS3Key
          introVideoUrl
          mission
          instagramUrl
          facebookUrl
          youtubeUrl
          emailValidated
          validationEmailSentAt
          potentialAthlete
          currency
          themeId
          role
          nickname
          stripePayoutsEnabled
          payoutCurrency
          stripeCustomerId
          ivsChannelArn
          createdAt
          updatedAt
          owner
          email
          stripeActionRequired
          stripeAccountId
          firebaseTokens
        }
        athlete {
          id
          name
          givenName
          familyName
          gender
          birthdate
          country
          sport
          profileImgS3Key
          coverImgS3Key
          introVideoS3Key
          introVideoThumbS3Key
          introVideoUrl
          mission
          instagramUrl
          facebookUrl
          youtubeUrl
          emailValidated
          validationEmailSentAt
          potentialAthlete
          currency
          themeId
          role
          nickname
          stripePayoutsEnabled
          payoutCurrency
          stripeCustomerId
          ivsChannelArn
          createdAt
          updatedAt
          owner
          email
          stripeActionRequired
          stripeAccountId
          firebaseTokens
        }
        tier {
          id
          userId
          name
          currency
          unitAmount
          discountType
          discount
          discountMonths
          couponId
          createdAt
          updatedAt
        }
      }
      nextToken
      total
    }
  }
`;
export const getTier = /* GraphQL */ `
  query GetTier($id: ID!) {
    getTier(id: $id) {
      id
      userId
      name
      currency
      unitAmount
      discountType
      discount
      discountMonths
      couponId
      createdAt
      updatedAt
    }
  }
`;
export const listTiers = /* GraphQL */ `
  query ListTiers(
    $id: ID
    $filter: ModelTierFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTiers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        userId
        name
        currency
        unitAmount
        discountType
        discount
        discountMonths
        couponId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listTiersByUserId = /* GraphQL */ `
  query ListTiersByUserId(
    $userId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTierFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTiersByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        name
        currency
        unitAmount
        discountType
        discount
        discountMonths
        couponId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFlashRequest = /* GraphQL */ `
  query GetFlashRequest(
    $userId: ID!
    $request: FlashRequestType!
    $targetId: String!
  ) {
    getFlashRequest(userId: $userId, request: $request, targetId: $targetId) {
      userId
      request
      targetId
      payload
      createdAt
      updatedAt
    }
  }
`;
export const listFlashRequests = /* GraphQL */ `
  query ListFlashRequests(
    $userId: ID
    $requestTargetId: ModelFlashRequestPrimaryCompositeKeyConditionInput
    $filter: ModelFlashRequestFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFlashRequests(
      userId: $userId
      requestTargetId: $requestTargetId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        userId
        request
        targetId
        payload
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLike = /* GraphQL */ `
  query GetLike(
    $userId: ID!
    $targetType: LikeTargetType!
    $targetId: String!
  ) {
    getLike(userId: $userId, targetType: $targetType, targetId: $targetId) {
      userId
      targetId
      targetType
      createdAt
      updatedAt
      user {
        id
        name
        givenName
        familyName
        gender
        birthdate
        country
        sport
        profileImgS3Key
        coverImgS3Key
        introVideoS3Key
        introVideoThumbS3Key
        introVideoUrl
        mission
        instagramUrl
        facebookUrl
        youtubeUrl
        goals {
          id
          name
          level
          description
          videoUrl
          link
        }
        achievements {
          id
          name
          level
          description
          link
          videoUrl
          date
        }
        fundingGoals {
          id
          amount
          currency
          description
        }
        sponsors {
          id
          imgS3Key
        }
        emailValidated
        validationEmailSentAt
        potentialAthlete
        currency
        themeId
        role
        nickname
        stripePayoutsEnabled
        payoutCurrency
        stats {
          numUniqueViews
          numScouts
          paidByScouts
          numPosts
        }
        features {
          posts
        }
        stripeCustomerId
        ivsChannelArn
        createdAt
        updatedAt
        owner
        email
        stripeActionRequired
        stripeAccountId
        social {
          fbUserId
          fbAccessToken
          fbTargetPageId
          twitterRequestToken
          twitterRequestSecret
          twitterAccessToken
          twitterAccessSecret
          instagramUserId
        }
        firebaseTokens
      }
    }
  }
`;
export const listLikes = /* GraphQL */ `
  query ListLikes(
    $userId: ID
    $targetTypeTargetId: ModelLikePrimaryCompositeKeyConditionInput
    $filter: ModelLikeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLikes(
      userId: $userId
      targetTypeTargetId: $targetTypeTargetId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        userId
        targetId
        targetType
        createdAt
        updatedAt
        user {
          id
          name
          givenName
          familyName
          gender
          birthdate
          country
          sport
          profileImgS3Key
          coverImgS3Key
          introVideoS3Key
          introVideoThumbS3Key
          introVideoUrl
          mission
          instagramUrl
          facebookUrl
          youtubeUrl
          emailValidated
          validationEmailSentAt
          potentialAthlete
          currency
          themeId
          role
          nickname
          stripePayoutsEnabled
          payoutCurrency
          stripeCustomerId
          ivsChannelArn
          createdAt
          updatedAt
          owner
          email
          stripeActionRequired
          stripeAccountId
          firebaseTokens
        }
      }
      nextToken
    }
  }
`;
export const listLikesByTarget = /* GraphQL */ `
  query ListLikesByTarget(
    $targetId: String
    $targetType: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLikeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLikesByTarget(
      targetId: $targetId
      targetType: $targetType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userId
        targetId
        targetType
        createdAt
        updatedAt
        user {
          id
          name
          givenName
          familyName
          gender
          birthdate
          country
          sport
          profileImgS3Key
          coverImgS3Key
          introVideoS3Key
          introVideoThumbS3Key
          introVideoUrl
          mission
          instagramUrl
          facebookUrl
          youtubeUrl
          emailValidated
          validationEmailSentAt
          potentialAthlete
          currency
          themeId
          role
          nickname
          stripePayoutsEnabled
          payoutCurrency
          stripeCustomerId
          ivsChannelArn
          createdAt
          updatedAt
          owner
          email
          stripeActionRequired
          stripeAccountId
          firebaseTokens
        }
      }
      nextToken
    }
  }
`;
export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      id
      authorId
      parentId
      parentType
      lastComment {
        id
        authorId
        parentId
        parentType
        lastComment {
          id
          authorId
          parentId
          parentType
          message
          likes
          comments
          liked
          createdAt
          updatedAt
        }
        message
        likes
        comments
        liked
        createdAt
        updatedAt
        author {
          id
          name
          givenName
          familyName
          gender
          birthdate
          country
          sport
          profileImgS3Key
          coverImgS3Key
          introVideoS3Key
          introVideoThumbS3Key
          introVideoUrl
          mission
          instagramUrl
          facebookUrl
          youtubeUrl
          emailValidated
          validationEmailSentAt
          potentialAthlete
          currency
          themeId
          role
          nickname
          stripePayoutsEnabled
          payoutCurrency
          stripeCustomerId
          ivsChannelArn
          createdAt
          updatedAt
          owner
          email
          stripeActionRequired
          stripeAccountId
          firebaseTokens
        }
      }
      message
      likes
      comments
      liked
      createdAt
      updatedAt
      author {
        id
        name
        givenName
        familyName
        gender
        birthdate
        country
        sport
        profileImgS3Key
        coverImgS3Key
        introVideoS3Key
        introVideoThumbS3Key
        introVideoUrl
        mission
        instagramUrl
        facebookUrl
        youtubeUrl
        goals {
          id
          name
          level
          description
          videoUrl
          link
        }
        achievements {
          id
          name
          level
          description
          link
          videoUrl
          date
        }
        fundingGoals {
          id
          amount
          currency
          description
        }
        sponsors {
          id
          imgS3Key
        }
        emailValidated
        validationEmailSentAt
        potentialAthlete
        currency
        themeId
        role
        nickname
        stripePayoutsEnabled
        payoutCurrency
        stats {
          numUniqueViews
          numScouts
          paidByScouts
          numPosts
        }
        features {
          posts
        }
        stripeCustomerId
        ivsChannelArn
        createdAt
        updatedAt
        owner
        email
        stripeActionRequired
        stripeAccountId
        social {
          fbUserId
          fbAccessToken
          fbTargetPageId
          twitterRequestToken
          twitterRequestSecret
          twitterAccessToken
          twitterAccessSecret
          instagramUserId
        }
        firebaseTokens
      }
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $id: ID
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listComments(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        authorId
        parentId
        parentType
        lastComment {
          id
          authorId
          parentId
          parentType
          message
          likes
          comments
          liked
          createdAt
          updatedAt
        }
        message
        likes
        comments
        liked
        createdAt
        updatedAt
        author {
          id
          name
          givenName
          familyName
          gender
          birthdate
          country
          sport
          profileImgS3Key
          coverImgS3Key
          introVideoS3Key
          introVideoThumbS3Key
          introVideoUrl
          mission
          instagramUrl
          facebookUrl
          youtubeUrl
          emailValidated
          validationEmailSentAt
          potentialAthlete
          currency
          themeId
          role
          nickname
          stripePayoutsEnabled
          payoutCurrency
          stripeCustomerId
          ivsChannelArn
          createdAt
          updatedAt
          owner
          email
          stripeActionRequired
          stripeAccountId
          firebaseTokens
        }
      }
      nextToken
    }
  }
`;
export const listCommentsByParent = /* GraphQL */ `
  query ListCommentsByParent(
    $parentId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommentsByParent(
      parentId: $parentId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        authorId
        parentId
        parentType
        lastComment {
          id
          authorId
          parentId
          parentType
          message
          likes
          comments
          liked
          createdAt
          updatedAt
        }
        message
        likes
        comments
        liked
        createdAt
        updatedAt
        author {
          id
          name
          givenName
          familyName
          gender
          birthdate
          country
          sport
          profileImgS3Key
          coverImgS3Key
          introVideoS3Key
          introVideoThumbS3Key
          introVideoUrl
          mission
          instagramUrl
          facebookUrl
          youtubeUrl
          emailValidated
          validationEmailSentAt
          potentialAthlete
          currency
          themeId
          role
          nickname
          stripePayoutsEnabled
          payoutCurrency
          stripeCustomerId
          ivsChannelArn
          createdAt
          updatedAt
          owner
          email
          stripeActionRequired
          stripeAccountId
          firebaseTokens
        }
      }
      nextToken
    }
  }
`;
export const getMigrations = /* GraphQL */ `
  query GetMigrations($name: ID!) {
    getMigrations(name: $name) {
      name
      createdAt
      updatedAt
    }
  }
`;
export const listMigrationss = /* GraphQL */ `
  query ListMigrationss(
    $name: ID
    $filter: ModelMigrationsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMigrationss(
      name: $name
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBcast = /* GraphQL */ `
  query GetBcast($id: ID!) {
    getBcast(id: $id) {
      id
      userId
      title
      description
      tierId
      needsSubscription
      thumbnailImageS3Key
      previewImageS3Key
      time
      duration
      guests {
        id
        name
      }
      allowQuestions
      status
      bcastUrl
      recordingS3Key
      prepareArgs
      createdAt
      updatedAt
    }
  }
`;
export const listBcasts = /* GraphQL */ `
  query ListBcasts(
    $id: ID
    $filter: ModelBcastFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listBcasts(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        userId
        title
        description
        tierId
        needsSubscription
        thumbnailImageS3Key
        previewImageS3Key
        time
        duration
        guests {
          id
          name
        }
        allowQuestions
        status
        bcastUrl
        recordingS3Key
        prepareArgs
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listBcastsByUserId = /* GraphQL */ `
  query ListBcastsByUserId(
    $userId: ID
    $time: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBcastFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBcastsByUserId(
      userId: $userId
      time: $time
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        title
        description
        tierId
        needsSubscription
        thumbnailImageS3Key
        previewImageS3Key
        time
        duration
        guests {
          id
          name
        }
        allowQuestions
        status
        bcastUrl
        recordingS3Key
        prepareArgs
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
