/* tslint:disable */
/* eslint-disable */

// ----------------------------------------------------------------------------------------
// --------------------------------------- Users ------------------------------------------
// ----------------------------------------------------------------------------------------

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      name
      givenName
      familyName
      role
      nickname
      gender
      birthdate
      country
      sport
      profileImgS3Key
      coverImgS3Key
      introVideoS3Key
      introVideoThumbS3Key
      introVideoUrl
      mission
      themeId
      instagramUrl
      facebookUrl
      youtubeUrl
      goals {
        id
        name
        level
        description
        link
        videoUrl
      }
      achievements {
        id
        name
        level
        description
        link
        videoUrl
        date
      }
      fundingGoals {
        id
        amount
        currency
        description
      }
      sponsors {
        id
        imgS3Key
      }
      emailValidated
      stripePayoutsEnabled
      payoutCurrency
      stats {
        numUniqueViews
        numScouts
        paidByScouts
        numPosts
      }
      social {
        twitterRequestToken
        twitterRequestSecret
        twitterAccessToken
        twitterAccessSecret
      }
      features {
        posts
      }
      createdAt
      updatedAt
    }
  }
`;

export const getUserFull = /* GraphQL */ `
  query GetFullUser($id: ID!) {
    getUser(id: $id) {
      id
      email
      name
      givenName
      familyName
      role
      nickname
      gender
      birthdate
      country
      sport
      profileImgS3Key
      coverImgS3Key
      introVideoS3Key
      introVideoThumbS3Key
      introVideoUrl
      mission
      themeId
      instagramUrl
      facebookUrl
      youtubeUrl
      goals {
        id
        name
        level
        description
        link
      }
      achievements {
        id
        name
        level
        description
        link
        videoUrl
        date
      }
      fundingGoals {
        id
        amount
        currency
        description
      }
      sponsors {
        id
        imgS3Key
      }
      emailValidated
      potentialAthlete
      currency
      validationEmailSentAt
      stripeAccountId
      social {
        twitterRequestToken
        twitterRequestSecret
        twitterAccessToken
        twitterAccessSecret
        fbTargetPageId
        fbAccessToken
        instagramUserId
      }
      stripePayoutsEnabled
      stripeActionRequired
      payoutCurrency
      firebaseTokens
      stats {
        numUniqueViews
        numScouts
        paidByScouts
        numPosts
      }
      features {
        posts
      }
      createdAt
      updatedAt
    }
  }
`;

export const getUserMin = /* GraphQL */ `
  query GetMinUser($id: ID!) {
    getUser(id: $id) {
      id
      nickname
      name
      givenName
      familyName
      role
      themeId
    }
  }
`;

export const listUserIdByNickname = /* GraphQL */ `
  query ListUserIdsFromNickname($nickname: String) {
    listUsersByNickname(nickname: $nickname, limit: 1) {
      items {
        id
      }
    }
  }
`;

export const getUserNickname = /* GraphQL */ `
  query GetUserNickname($id: ID!) {
    getUser(id: $id) {
      nickname
    }
  }
`;

export const listUsersByNickname = /* GraphQL */ `
  query ListUsersByNickname(
    $nickname: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsersByNickname(
      nickname: $nickname
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        givenName
        familyName
        role
        nickname
        gender
        birthdate
        country
        sport
        profileImgS3Key
        coverImgS3Key
        introVideoS3Key
        introVideoThumbS3Key
        introVideoUrl
        mission
        instagramUrl
        facebookUrl
        youtubeUrl
        goals {
          id
          name
          level
          description
          link
          videoUrl
        }
        achievements {
          id
          name
          level
          description
          link
          videoUrl
          date
        }
        fundingGoals {
          id
          amount
          currency
          description
        }
        sponsors {
          id
          imgS3Key
        }
        emailValidated
        validationEmailSentAt
        stripePayoutsEnabled
        payoutCurrency
        stats {
          numUniqueViews
          numScouts
          paidByScouts
          numPosts
        }
        features {
          posts
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listFullUsersByNickname = /* GraphQL */ `
  query ListFullUsersByNickname(
    $nickname: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsersByNickname(
      nickname: $nickname
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        givenName
        familyName
        role
        nickname
        gender
        birthdate
        country
        sport
        profileImgS3Key
        coverImgS3Key
        introVideoS3Key
        introVideoThumbS3Key
        introVideoUrl
        mission
        instagramUrl
        facebookUrl
        youtubeUrl
        goals {
          id
          name
          level
          description
          link
        }
        achievements {
          id
          name
          level
          description
          link
          videoUrl
          date
        }
        fundingGoals {
          id
          amount
          currency
          description
        }
        sponsors {
          id
          imgS3Key
        }
        emailValidated
        validationEmailSentAt
        stripeAccountId
        stripePayoutsEnabled
        stripeActionRequired
        payoutCurrency
        stats {
          numUniqueViews
          numScouts
          paidByScouts
          numPosts
        }
        features {
          posts
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const searchUsers = /* GraphQL */ `
  query SearchUsers(
    $filter: SearchableUserFilterInput
    $sort: SearchableUserSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchUsers(filter: $filter, sort: $sort, limit: $limit, nextToken: $nextToken, from: $from) {
      items {
        id
        name
        givenName
        familyName
        role
        nickname
        gender
        country
        sport
        profileImgS3Key
        stats {
          numUniqueViews
        }
      }
      total {
        value
      }
      nextToken
    }
  }
`;

// -----------------------------------------------------------------------------------------
// --------------------------------------- Athletes ---------------------------------------
// -----------------------------------------------------------------------------------------


// -----------------------------------------------------------------------------------------
// --------------------------------------- Posts ----------------------------------
// -----------------------------------------------------------------------------------------

export const listPostsByAuthor = /* GraphQL */ `
  query ListPostsByAuthor(
    $authorId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPostsByAuthor(
      authorId: $authorId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        authorId
        author {
          id
          nickname
          givenName
          familyName
          sport
          profileImgS3Key
          stripePayoutsEnabled
        }
        name
        description
        needsSubscription
        type
        tierId
        tier {
          id
          name
          currency
          unitAmount
          discountType
          discount
          discountMonths
        }
        vodId
        vodStatus
        vodMetadata {
          durationInMs
          orgWidth
          orgHeight
          hasPreview
        }
        bcastMetadata {
          previewImageS3Key
          time
          duration
          guests {
            id
            name
          }
          allowQuestions
          status
          bcastUrl
          recordingS3Key
        }
        thumbS3Key
        likes
        comments
        liked
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getPost = /* GraphQL */ `
  query GetPost($id: ID!) {
    getPost(id: $id) {
      id
      authorId
      author {
        id
        nickname
        givenName
        familyName
        sport
        profileImgS3Key
        stripePayoutsEnabled
        payoutCurrency
      }
      name
      description
      needsSubscription
      type
      tierId
      tier {
        userId
        name
        currency
        unitAmount
        discountType
        discount
        discountMonths
        createdAt
      }
      vodId
      vodStatus
      vodMetadata {
        durationInMs
        orgWidth
        orgHeight
        hasPreview
      }
      bcastMetadata {
        previewImageS3Key
        time
        duration
        guests {
          id
          name
        }
        allowQuestions
        status
        bcastUrl
        recordingS3Key
      }
    thumbS3Key
      likes
      createdAt
      updatedAt
    }
  }
`;

export const getFullPost = /* GraphQL */ `
  query GetFullPost($postId: ID!) {
    getFullPost(postId: $postId) {
      id
      authorId
      name
      description
      needsSubscription
      type
      tierId
      tier {
        id
        name
        currency
        unitAmount
        discountType
        discount
        discountMonths
      }
      thumbS3Key
      vodId
      vodStatus
      vodToken
      likes
      liked
      createdAt
    }
  }
`;

export const getPostVodToken = /* GraphQL */ `
  query GetFullPost($postId: ID!) {
    getFullPost(postId: $postId) {
      vodToken
    }
  }
`;

export const getAthleteSubscription = /* GraphQL */ `
  query GetAthleteSubscription($subscriberId: ID!, $athleteId: ID!) {
    getAthleteSubscription(subscriberId: $subscriberId, athleteId: $athleteId) {
      subscriberId
      athleteId
      status
      createdAt
    }
  }
`;

export const listSubscriptionsBySubscriber = /* GraphQL */ `
  query ListAthleteSubscriptionsBySubscriber(
    $subscriberId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAthleteSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubscriptionsBySubscriber(
      subscriberId: $subscriberId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        subscriberId
        athleteId
        athlete {
          id
          name
          nickname
          givenName
          profileImgS3Key
        }
        tier {
          id
          name
          discountType
          discount
          discountMonths
        }
        status
        cancelAtPeriodEnd
        currentPeriodEnd
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;

export const searchPosts = /* GraphQL */ `
  query SearchPosts(
    $filter: SearchablePostFilterInput
    $sort: SearchablePostSortInput
    $limit: Int
    $nextToken: String
  ) {
    searchPosts(filter: $filter, sort: $sort, limit: $limit, nextToken: $nextToken) {
      items {
        id
        authorId
        name
        description
        type
        tierId
        reviewStatus
        vodId
        vodStatus
        vodMetadata {
          durationInMs
          orgWidth
          orgHeight
          hasPreview
        }
        bcastMetadata {
          previewImageS3Key
          time
          duration
          guests {
            id
            name
          }
          allowQuestions
          status
          bcastUrl
          recordingS3Key
        }
        thumbS3Key
        createdAt
        updatedAt
        author {
          id
          nickname
          givenName
          familyName
          sport
          profileImgS3Key
          stripePayoutsEnabled
          payoutCurrency
        }
        tier {
          id
          name
          currency
          unitAmount
          discountType
          discount
          discountMonths
        }
        likes
        comments
        liked
      }
      total {
        value
      }
      nextToken
    }
  }
`;

export const listPostsByFeedId = /* GraphQL */ `
  query ListPostsByFeedId(
    $feedId: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPostsByFeedId(
      feedId: $feedId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        authorId
        name
        description
        needsSubscription
        type
        tierId
        reviewStatus
        vodId
        vodStatus
        vodMetadata {
          durationInMs
          orgWidth
          orgHeight
          hasPreview
        }
        bcastMetadata {
          previewImageS3Key
          time
          duration
          guests {
            id
            name
          }
          allowQuestions
          status
          bcastUrl
          recordingS3Key
        }
        thumbS3Key
        createdAt
        updatedAt
        author {
          id
          nickname
          givenName
          familyName
          sport
          profileImgS3Key
          stripePayoutsEnabled
          payoutCurrency
        }
        tier {
          id
          name
          currency
          unitAmount
          discountType
          discount
          discountMonths
        }
        likes
        liked
        comments
      }
      nextToken
    }
  }
`;

// -----------------------------------------------------------------------------------------
// --------------------------------------- FlashRequests ----------------------------------
// -----------------------------------------------------------------------------------------

export const getFlashRequest = /* GraphQL */ `
  query GetFlashRequest($userId: ID!, $request: FlashRequestType!, $targetId: String!) {
    getFlashRequest(userId: $userId, request: $request, targetId: $targetId) {
      userId
      request
      targetId
      createdAt
    }
  }
`;

// -----------------------------------------------------------------------------------------
// --------------------------------------- Likes -------------------------------------------
// -----------------------------------------------------------------------------------------

export const listLikesByTarget = /* GraphQL */ `
  query ListLikesByTarget(
    $targetId: String!
    $targetType: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLikeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLikesByTarget(
      targetId: $targetId
      targetType: $targetType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        user {
          id
          name
          givenName
          familyName
          nickname
          profileImgS3Key
          role
        }
      }
    }
  }
`;

// -----------------------------------------------------------------------------------------
// --------------------------------------- Stripe ------------------------------------------
// -----------------------------------------------------------------------------------------

export const listPaymentMethods = /* GraphQL */ `
  query ListPaymentMethods {
    listPaymentMethods {
      id
      type
      billing_details {
        address {
          city
          country
          line1
          line2
          postal_code
          state
        }
        name
        phone
      }
      card {
        brand
        country
        exp_month
        exp_year
        last4
      }
      is_default
      created
      customer
    }
  }
`;

export const isUserCustomer = /* GraphQL */ `
  query IsUserCustomer {
    isUserCustomer
  }
`;

export const listSubscriptions = /* GraphQL */ `
  query ListSubscriptions {
    listSubscriptions {
      id
      cancel_at_period_end
      canceled_at
      collection_method
      created
      current_period_end
      current_period_start
      days_until_due
      ended_at
      next_pending_invoice_item_invoice
      start_date
      status
      metadata {
        product_name
        athlete_id
      }
    }
  }
`;

export const listInvoices = /* GraphQL */ `
  query ListInvoices {
    listInvoices {
      id
      number
      paid
      period_start
      period_end
      total
      currency
      invoice_pdf
      created
    }
  }
`;

export const listTiersByUserId = /* GraphQL */ `
  query ListTiersByUserId(
    $userId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTierFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTiersByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        name
        currency
        unitAmount
        discountType
        discount
        discountMonths
        createdAt
      }
      nextToken
    }
  }
`;

export const listCommentsByParent = /* GraphQL */ `
  query ListCommentsByParent(
    $parentId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommentsByParent(
      parentId: $parentId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        message
        likes
        comments
        createdAt
        parentType
        liked
        lastComment {
          id
          message
          likes
          comments
          createdAt
          liked
          parentType
          author {
            id
            name
            nickname
            givenName
            familyName
            sport
            profileImgS3Key
          }
        }
        author {
          id
          name
          nickname
          givenName
          familyName
          sport
          profileImgS3Key
        }
      }
      nextToken
    }
  }
`;

export const getTier = /* GraphQL */ `
  query GetTier($id: ID!) {
    getTier(id: $id) {
      id
      userId
      name
      currency
      unitAmount
      discountType
      discount
      discountMonths
      createdAt
    }
  }
`;

export const getBalance = /* GraphQL */ `
  query getBalance {
    getBalance {
      available {
        amount
        currency
      }
      pending {
        amount
        currency
      }
    }
  }
`;

export const getBalanceTransactions = /* GraphQL */ `
  query GetBalanceTransactions($type: String!, $startingAfter: String, $limit: Int) {
    getBalanceTransactions(type: $type, startingAfter: $startingAfter, limit: $limit) {
      data {
        id
        amount
        available_on
        created
        currency
        description
        exchange_rate
        fee
        net
        reporting_category
        status
        type
      }
      has_more
    }
  }
`;

export const getPayouts = /* GraphQL */ `
  query GetPayouts {
    getPayouts {
      data {
        id
        amount
        arrival_date
        automatic
        created
        currency
        description
        source_type
        status
        type
      }
      has_more
    }
  }
`;

export const getPaymentsAccount = /* GraphQL */ `
  query GetPaymentsAccount {
    getPaymentsAccount {
      id
      business_profile {
        name
        product_description
        support_address {
          city
          country
          line1
          line2
          postal_code
          state
        }
        support_email
        support_phone
        support_url
        url
      }
      charges_enabled
      country
      default_currency
      details_submitted
      email
      payouts_enabled
      requirements {
        current_deadline
        currently_due
        disabled_reason
        errors {
          code
          reason
          requirement
        }
        eventually_due
        past_due
        pending_verification
      }
    }
  }
`;

export const getPaymentsAccountLink = /* GraphQL */ `
  query GetPaymentsAccountLink($refreshUrl: String!, $returnUrl: String!) {
    getPaymentsAccountLink(refreshUrl: $refreshUrl, returnUrl: $returnUrl)
  }
`;

export const getTaxRateByCountry = /* GraphQL */ `
  query GetTaxRateByCountry($country: String!) {
    getTaxRateByCountry(country: $country) {
      id
      percentage
      displayName
      country
    }
  }
`;

// -----------------------------------------------------------------------------------------
// --------------------------------------- Misc --------------------------------------------
// -----------------------------------------------------------------------------------------

export const getCurrenciesConfig = /* GraphQL */ `
  query GetCurrenciesConfig {
    getCurrenciesConfig {
      defaultCurrency
      clientCurrency
      currencies {
        code
        symbol
        round
      }
      conversionsTable
    }
  }
`;

export const getViewerCurrency = /* GraphQL */ `
  query GetViewerCurrency {
    getViewerCurrency
  }
`;
