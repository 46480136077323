import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import Dialog from '@material-ui/core/Dialog/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { TransitionProps } from '@material-ui/core/transitions';
import Slide from '@material-ui/core/Slide';

import DialogTitle from '../ui/DialogTitle';
import FormSubmitButton from '../ui/FormSubmitButton';

const useStyles = makeStyles(() =>
  createStyles({
    contentWrapper: {
      height: '100%',
    },
    content: {
      height: '100%',
    },
  }),
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

interface Props {
  open: boolean;
  title: string;
  description: string;
  okButtonText?: string;
  onClose: () => void;
}

const InfoDialog: React.FC<Props> = ({ open, title, description, okButtonText = 'Ok', onClose }: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      fullScreen={!isBigScreen}
      maxWidth="xs"
      fullWidth={true}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" onClose={handleClose}>
        {title}
      </DialogTitle>
      <DialogContent className={classes.contentWrapper}>
        <Grid className={classes.content} container direction="column" justifyContent="center">
          <Grid item>
            <Typography variant="body1">{description}</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <FormSubmitButton color="primary" onClick={handleClose}>
          {okButtonText}
        </FormSubmitButton>
      </DialogActions>
    </Dialog>
  );
};

export default InfoDialog;
