import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import ReplayIcon from '@material-ui/icons/Replay';

import { AthleteSubscription, Post } from '../../core/graphql/types';
import PostElement from './PostElement';
import FormSubmitButton from '../ui/FormSubmitButton';
import SpinnerBox from '../ui/SpinnerBox';
import { fetchPostsByFeedId } from '../../core/services/postsSvc';
import { authStore } from '../../core/stores/auth';

const useStyles = makeStyles(() => ({
  fanFeedRoot: {
    width: '100%', // material-ui: +16px
    margin: 0, // materiail-ui: -8px
    height: 'fit-content',
  },
}));

export interface Props {
  subscriptions?: AthleteSubscription[];
}

const FanFeed: React.FC<Props> = ({ subscriptions }: Props) => {
  const classes = useStyles();
  const [authState] = useContext(authStore);
  const [posts, setPosts] = useState<Post[]>([]);
  const [postsNextToken, setPostsNextToken] = useState<string | undefined>();
  const [loadingPosts, setLoadingPosts] = useState(false);
  const [athletesSubscribedTo, setAthletesSubscribedTo] = useState<{ [key: string]: boolean }>({});
  const pageSize = 8;

  const handleLoadMore = async (reset = false) => {
    setLoadingPosts(true);
    const res = await fetchPostsByFeedId('fansDashboard', pageSize, postsNextToken, true);
    setLoadingPosts(false);

    if (res) {
      if (reset) {
        // Reset state
        setPosts(res.posts);
        setPostsNextToken(res.nextToken);
      } else {
        setPosts((psts) => [...psts, ...(res || {}).posts]);
        setPostsNextToken(res.nextToken);
      }
    }
  };

  useEffect(() => {
    if (subscriptions !== undefined) {
      // Load posts
      handleLoadMore(true);

      const athletesHash: { [key: string]: boolean } = subscriptions.reduce((acc: { [key: string]: boolean }, cur) => {
        acc[cur.athleteId] = true;
        return acc;
      }, {});
      setAthletesSubscribedTo(athletesHash);
    }
  }, [subscriptions]);

  return (
    <div className={classes.fanFeedRoot}>
      <Grid container direction="column" spacing={2}>
        {/* Posts */}
        {posts.map((post) => (
          <Grid key={post.id} item>
            <PostElement
              showPreviewDownloaderButton={false}
              author={post.author}
              post={post}
              editPermission={false}
              viewPermission={
                !post.needsSubscription ||
                athletesSubscribedTo[post.authorId] ||
                authState.isAdmin ||
                authState.isModerator ||
                authState.isGuest
              }
              showAuthorAvatar={true}
            />
          </Grid>
        ))}

        {/* Load more button */}
        {!loadingPosts && postsNextToken && (
          <Grid item>
            <Box display="flex" justifyContent="center">
              <FormSubmitButton
                color="primary"
                endIcon={<ReplayIcon fontSize="large" />}
                onClick={handleLoadMore.bind(null, false)}
              >
                Load more
              </FormSubmitButton>
            </Box>
          </Grid>
        )}

        {/* Spinner */}
        {loadingPosts && (
          <Grid item>
            <SpinnerBox />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default FanFeed;
